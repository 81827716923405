import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import ForumIcon from '@material-ui/icons/Forum';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ErrorWarning,
  GridIconDelete,
  StyledFooterGrid,
  StyledGridPerguntas,
  StyledInputsGrid,
  StyledMttGridMain,
  StyledMttTextField,
  StyledMttTypographyIdentificacao,
  StyledTextFieldPergunta,
} from './styles';

import { useForm } from 'contexts/Form/formContext';
import { propsData } from './types';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import CircularDeleteButton from 'components/CircularDeleteButton/index';
import { IOptionMenuList } from 'components/IconButtonWithMenuList/types';
import SpeedDialWithMenuList from 'components/SpeedDialWithMenuList';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { GetEntities } from 'services/EntityService';
import { validateVarNames } from 'utils/String';
import ModalVariableAdvanced from './ModalVariableAdvanced';

export default function UserQuestions(props: Readonly<propsData>) {
  const { botName } = useFlow().state;
  const { state, dispatch } = useForm();
  const { dispatch: dispatchApp } = useApp();
  const { dataForm, formErrors } = state;

  const { index, showDelete, showTitle } = props;
  const variables = dataForm?.inputs?.variables ?? [];
  const variable = dataForm?.inputs?.variables[index];

  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);
  const [openAdvancedModal, setOpenAdvancedModal] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true

    async function load() {
      let allTypes = [
        { id: '@sys.any', name: 'qualquer-input' },
        { id: '@sys.email', name: 'input-de-email' },
        { id: '@sys.number', name: 'input-de-numero' },
        { id: '@sys.date-time', name: 'input-de-data' },
        { id: '@sys.url', name: 'input-de-url' },
      ];

      if (!botName) return;

      let result = await GetEntities({ bot_name: botName || '' }, dispatchApp);

      if (!result.Success || !result.Data) return;

      const entities = result.Data;
      allTypes = [
        ...allTypes,
        ...entities.map((entity) => ({
          id: entity.name,
          name: entity.name,
        })),
      ].sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        } else {
          return 0;
        }
      });

      allTypes = allTypes.map((type) => {
        if (!type.id.startsWith('@')) type.id = `@${type.id}`;
        return type;
      });

      if (isMounted) {
        setTypes(allTypes);
      }
    }
    load();

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName]);

  const load = useCallback(() => {
    if (dataForm && dataForm.inputs && !dataForm.inputs.variables.length) {
      dataForm.inputs = {
        variables: [
          {
            name: '',
            vartype: '',
            questions: ['', '', ''],
            required: false,
            defaultValue: '',
            isList: false,
            value: '',
          },
        ],
      };
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeInput =
    (key: 'value' | 'name' | 'vartype') =>
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      if (dataForm && variable) {
        const value = validateVarNames(e, key, true);

        if (dataForm.dataBlockly) {
          dataForm.dataBlockly.payload =
            dataForm.dataBlockly?.payload.replaceAll(
              `$${variable['name']}`,
              `$${value}`
            );
        }

        if (key === 'name' && !variable.isValueEdited) {
          variable['value'] = '$' + value;
        }
        variable[key] = value;

        dispatch({
          type: 'updateForm',
          data: { dataForm },
        });
      }
    };

  const handleQuestion = (value: string, index: number) => {
    if (variable && dataForm) {
      variable.questions[index] = value;
      dispatch({
        type: 'updateForm',
        data: { dataForm },
      });
    }
  };

  const handleRemoveVariable = (index: number) => {
    variables.splice(index, 1);
    dispatch({ type: 'updateForm', data: { dataForm } });
  };

  const options: IOptionMenuList[] = [
    {
      label: 'Avançado',
      iconName: 'Settings',
      action: () => {
        setOpenAdvancedModal(true);
      },
    },
    {
      label: 'Cancelar',
      iconName: 'Cancel',
      action: () => {
        setOpenAdvancedModal(false);
      },
    },
  ];

  const renderQuestions = () => {
    return (
      <StyledGridPerguntas showgrid={true}>
        {variable?.questions.map((perg, index) => {
          return (
            <StyledTextFieldPergunta
              key={index}
              onChange={(e) => handleQuestion(e.target.value, index)}
              value={perg}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ForumIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          );
        })}
      </StyledGridPerguntas>
    );
  };

  return (
    <StyledMttGridMain>
      {!!showTitle && <StyledMttTypographyIdentificacao />}
      <StyledFooterGrid>
        <StyledInputsGrid>
          <StyledMttTextField
            variant="outlined"
            label="Nome"
            value={variable?.name ?? ''}
            onChange={handleChangeInput('name')}
            fullWidth
          />

          <FormControl variant="outlined" fullWidth>
            <InputLabel id="groups-simple-select-outlined-label">
              Tipos
            </InputLabel>
            <ErrorWarning>
              <Select
                labelId="groups-simple-select-outlined-label"
                id="groups-simple-select-outlined"
                value={variable?.vartype ?? ''}
                onChange={handleChangeInput('vartype')}
                label="Tipos"
                fullWidth
                required
                error={
                  variable?.name !== '' &&
                  variable?.vartype === '' &&
                  !!formErrors['varType']
                }
              >
                <MenuItem value="">
                  <em>Selecione um Tipo</em>
                </MenuItem>
                {types.map((type, index) => (
                  <MenuItem
                    key={index}
                    value={type.id}
                    disabled={!variable?.name}
                  >
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
              {variable?.name && variable?.vartype === '' && (
                <FormHelperText error>{formErrors['varType']}</FormHelperText>
              )}
            </ErrorWarning>
          </FormControl>
          <SpeedDialWithMenuList iconName="MoreVert" options={options} />
          {!!showDelete && (
            <GridIconDelete key={`variable-${index}`}>
              <CircularDeleteButton
                handleDeleteItem={() => handleRemoveVariable(index)}
              />
            </GridIconDelete>
          )}
        </StyledInputsGrid>
        {renderQuestions()}
      </StyledFooterGrid>
      <ModalVariableAdvanced
        index={index}
        open={openAdvancedModal}
        handleCloseModal={() => setOpenAdvancedModal(false)}
      />
    </StyledMttGridMain>
  );
}
