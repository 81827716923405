import {
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from '@material-ui/core';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { Property } from 'contexts/Flow/types';
import { useForm } from 'contexts/Form/formContext';
import { useToast } from 'contexts/ToastContext';
import { useCallback, useEffect, useState } from 'react';
import { GetContentGroupList } from 'services/123FalaService';
import { ContentGroupListSchema } from 'services/123FalaService/types';
import useTranslator from 'utils/hooks/Translator';

export function AI123EntriesComponent({ entries }: Readonly<{ entries: Property[] }>) {
  const { state: stateFlow } = useFlow();
  const { state, updateForm } = useForm();
  const { dispatch: dispatchApp } = useApp();
  const { toastNotification: toast } = useToast();
  const { getTranslation } = useTranslator()
  const { dataForm } = state;

  const [contentGroups, setContentGroups] = useState<ContentGroupListSchema[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadContentGroups = useCallback(async (agent) => {
    try {
      setIsLoading(true);

      const resp = await GetContentGroupList(agent, dispatchApp);

      if (resp.StatusCode < 200 || resp.StatusCode >= 300) {
        toast({ 
          type: 'warning', 
          message: getTranslation('toast.error.contentGroupsNotFound') 
        });

        return
      };

      const data = resp.Data.data.sort((a, b) => {
        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      });

      setContentGroups(data);
    } catch (error) {
      toast({
        type: 'error',
        message: getTranslation('toast.error.error'),
      });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchApp]);

  useEffect(() => {
    loadContentGroups(stateFlow.botName ?? '');
  }, [loadContentGroups, stateFlow.botName]);

  const handleChangeContentGroup = (newValue: string) => {
    if (dataForm?.ai123Info) {
      const updatedAI123Info = { ...dataForm.ai123Info };

      const selectedContentGroup =
        contentGroups.find((contentGroup) => contentGroup.name === newValue)?.id || '';

      updatedAI123Info.contentGroupId = selectedContentGroup;

      updateForm({ ...dataForm, ai123Info: updatedAI123Info });
    }
  };

  const renderSelectEntry = () => {
    const selectedValue =
      contentGroups.filter((contentGroup) => contentGroup.id === dataForm?.ai123Info?.contentGroupId)[0]?.name || '';

    return (
      <FormControl 
        fullWidth 
        error={!!state.formErrors?.contentGroup}
      >
        <Select
          labelId='ai123-select-contentGroup'
          id='ai123-select-contentGroup'
          value={selectedValue}
          onChange={(e) =>
            handleChangeContentGroup(String(e.target.value))
          }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '240px'
              }
            }
          }}
        >
          {contentGroups.map((contentGroup) => (
            <MenuItem key={contentGroup.id} value={contentGroup.name}>
              {contentGroup.name}
            </MenuItem>
          ))}
        </Select>

        {!!state.formErrors?.contentGroup && (
          <FormHelperText>
            {state.formErrors.contentGroup}
          </FormHelperText>
        )}
      </FormControl>
    );
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress size={28} />
      ) : (
        renderSelectEntry()
      )}
    </>
  );
}
