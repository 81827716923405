import { Box, IconButton, Typography } from '@material-ui/core';
import { Delete, Description } from '@material-ui/icons';
import * as S from './style';
import { FileSelectedBoxProps } from './types';

export function FileSelectedBox({
    file,
    onDelete,
    successMessage = "File selected successfully",
}: FileSelectedBoxProps) {
    return (
        <S.DataSourceFileSelectedBox>
            <Description
                color='action'
                fontSize="large"
            />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ml: 1.5,
                }}
            >
                <Typography variant="subtitle1" noWrap>
                    {file.name?.length > 24
                        ? `${file.name.substring(0, 18)}...`
                        : file?.name ?? 'No file selected'}
                </Typography>

                <Typography
                    variant="caption"
                    style={{
                        fontWeight: 700,
                        color: '#666',
                    }}
                >
                    <span>{file.size}B</span>
                </Typography>
            </Box>

            <IconButton
                onClick={onDelete}
                size="small"
                style={{
                    position: 'absolute',
                    right: '8px',
                    top: '8px',
                }}
                aria-label="delete file"
            >
                <Delete />
            </IconButton>

            {file && (
                <Typography
                    style={{
                        position: 'absolute',
                        right: '16px',
                        bottom: '8px',
                        fontSize: 10,
                        color: 'green',
                    }}
                >
                    {successMessage}
                </Typography>
            )}
        </S.DataSourceFileSelectedBox>
    );
}
