import { createContext, useContext, useReducer } from "react";
import { initialState, reducerLibrary } from "./libraryReducer";
import { ILibraryContext, ILibraryProviderProps } from "./types";

const LibraryContext = createContext<ILibraryContext>({} as ILibraryContext);

export const LibraryProvider: React.FC<ILibraryProviderProps> = (
  props: ILibraryProviderProps
) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducerLibrary, initialState);

  return (
    <LibraryContext.Provider
      value={{
        state: state || initialState,
        dispatch,
      }}
    >
      {children}
    </LibraryContext.Provider>
  )
}

export function useLibrary(): ILibraryContext {
  const context = useContext(LibraryContext);
  return context;
}
