import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    // backgroundColor: '#49366f',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    border: '1px solid #353759',

    color: '#fff',
    '& .MuiTabs-root': {
      minHeight: 'auto',
    },
    '& .MuiTab-root': {
      minHeight: 'auto',
    },
  },
  appBar: {
    boxShadow: 'none',
    padding: 0,
  },
  customTab: {
    '& .MuiTabs-indicator': {
      // backgroundColor: '#fff',
      // height: '4px',
      // borderRadius: '2px',
    },
  },
}));
