import * as yup from 'yup';
import { Key } from '../services/AccessKeysService/types';
import { IResultError } from './ResultError';

interface AccessKeysErrors {
  duplicatedKey: string;
  requiredKey: string;
  requiredValue: string;
}
export interface IAccessKey {
  key?: string;
  value?: string;
  existingKeys: Key[];
  errors: AccessKeysErrors;
}

export const newAccessKeyValidation = async (
  key: string,
  value: string,
  existingKeys: Key[],
  error: AccessKeysErrors
) => {
  const keyResult = await keyValidator(key, error.requiredKey);

  if (keyResult.isValid) {
    keyResult.isValid = !existingKeys.some(
      (item) => item.key.trim().toLowerCase() === key.trim().toLowerCase()
    );
    if (!keyResult.isValid) {
      keyResult.errors = {
        ...keyResult.errors,
        key: error.duplicatedKey,
      };
    }
  }

  const valueResult = await valueValidator(value, error.requiredValue);
  const isValid = keyResult.isValid && valueResult.isValid;
  const errors = { ...keyResult.errors, ...valueResult.errors };
  return { isValid, errors };
};

const keyValidator = async (key: string, error: string) => {
  const schema = yup.object().shape({
    key: yup.string().required(error),
  });

  const promiseResult = new Promise<IResultError<IAccessKey>>((resolve) => {
    schema
      .validate({ key }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { key: '' } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { key: err.errors[0] } });
      });
  });
  return await promiseResult;
};

const valueValidator = async (value: string, error: string) => {
  const schema = yup.object().shape({
    value: yup.string().required(error),
  });

  const promiseResult = new Promise<IResultError<IAccessKey>>((resolve) => {
    schema
      .validate({ value }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { value: '' } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { value: err.errors[0] } });
      });
  });
  return await promiseResult;
};
