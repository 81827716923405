import { useApp } from "contexts/App/appContext";
import { useFintalkCloud } from "contexts/FintalkCloud/fintalkCloudContext";
import { useCallback, useEffect } from "react";
import { GetSupersetToken } from "services/SupersetService";
import { embedDashboard } from "@superset-ui/embedded-sdk";

export function SupersetModule() {

  const { dispatch: dispatchApp } = useApp();
  const { currentData } = useFintalkCloud();

  const getToken = async(agent: string, dispatch: any) => {
    const { Data } = await GetSupersetToken(
      agent,
      dispatch,
    );

    return Data
  }

  const loadParams = useCallback(async () => {
    if (currentData.agentName) {
      const data = await getToken(currentData.agentName, dispatchApp)

      if (data.token) {
        embedDashboard({
          id: data.dashboard_id,
          supersetDomain: "https://superset.run.fintalk.io",
          mountPoint: document.getElementById("superset-frame")!,
          fetchGuestToken: () => getToken(currentData.agentName, dispatchApp).then(d => { return d.token }),
          dashboardUiConfig: { 
            hideTitle: true,
            filters: {
                expanded: false,
            },
            urlParams: {
              force: true,
            }
          },
          iframeSandboxExtras: ['allow-top-navigation', 'allow-popups-to-escape-sandbox']
        });
      }
    }
  }, [currentData.agentName, dispatchApp]);

  useEffect(() => {
    loadParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData.agentName]);

  return (
    <div
      id="superset-frame"
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );
}