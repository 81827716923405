import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import { grey } from '@material-ui/core/colors';

import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';

import {
  Dialog,
  Divider,
  InputAdornment,
  Slide,
  TextField,
} from '@material-ui/core';

import { useFlow } from 'contexts/Flow/flowContext';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import './styles.css';
import {
  ContainerDialog,
  ContainerHeader,
  FieldContainer,
  TemplatesContainer,
  Button,
  ButtonsContainer,
  ModalTitle,
  Templates,
  Description,
  DescriptionInfo,
  ContainerDivider,
  Section,
} from './styles';
import { CircularProgress } from '@material-ui/core';
import { Block } from 'contexts/Library/types';
import {
  GetTemplates,
  RemoveTemplate,
  UpdateTemplate,
} from 'services/TemplateService';
import { DataFlow } from 'models/DataFlow';
import GetOffsetPositionFlowController from 'controllers/library/GetOffsetPositionFlowController';
import AddBlockToFlowController from 'controllers/library/AddBlockToFlowController';
import { formatDate } from 'utils/Date';
import { useApp } from 'contexts/App/appContext';
import useTranslator from 'utils/hooks/Translator';
import { ModalToPopProps } from 'views/Canvas/Builder/MainComponent/BuilderModals/types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      background: grey[100],
    },

    buttonUpload: {
      background: grey[600],
      color: '#FFFFFF',
      letterSpacing: 2,
      borderRadius: 8,
      '&:hover': {
        background: grey[700],
      },
    },
  })
);

export default function ModalStandardTemplates({
  handleClose,
}: ModalToPopProps) {
  const { dispatch: dispatchApp } = useApp();
  const { state, dispatch, mountNodeContent, toastNotification } = useFlow();
  const classes = useStyles();

  const options = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

  const [search, setSearch] = useState('');

  const [templates, setTemplates] = useState<Block[]>([]);
  const [load, setLoad] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { getTranslation } = useTranslator();

  const loadTemplates = useCallback(async () => {
    setLoad(true);
    const resultTemp = await GetTemplates(dispatchApp);
    if (resultTemp.Success) {
      setTemplates(resultTemp.Data.data);
    }
    setLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearch('');
    loadTemplates();
  }, [loadTemplates]);

  const handleModalTemplateUpload = () => {
    handleCloseModal();
    dispatch({ type: 'openModalTemplateUpload' });
  };

  function handleCloseModal() {
    setSelectedIndex(null);
    dispatch({ type: 'closeModalStandardTemplates' });
    handleClose();
  }

  const handleUpdateTemplate = (index: number) => {
    const documentHtml = document.getElementById('drawflow');
    const uploadInput = document.createElement('input');
    uploadInput.type = 'file';
    uploadInput.accept = 'application/json';
    uploadInput.onchange = async (e: any) => {
      const file: File = e.target.files?.item(0);
      const drawflow = JSON.parse(await file.text());

      if (drawflow.drawflow) {
        if (drawflow.drawflow.Home) {
          if (drawflow.drawflow.Home.data) {
            const templatesChanged = [...templates];
            templatesChanged[index].blocks = drawflow.drawflow.Home.data;
            const result = await UpdateTemplate(
              templatesChanged[index],
              dispatchApp
            );
            if (result.Success) {
              toastNotification(
                'success',
                getTranslation('toast.success.templateUpdate')
              );
              templatesChanged[index] = result.Data.data;
              setTemplates(templatesChanged);
            } else {
              toastNotification(
                'error',
                result.Message || getTranslation('toast.error.templateUpdate')
              );
            }
          }
        } else
          toastNotification(
            'error',
            getTranslation('toast.error.invalidTemplate')
          );
      } else
        toastNotification(
          'error',
          getTranslation('toast.error.invalidTemplate')
        );

      uploadInput.parentNode?.removeChild(uploadInput);
    };

    documentHtml?.appendChild(uploadInput);
    uploadInput.click();
    uploadInput.parentNode?.removeChild(uploadInput);
  };

  const handleSelectBlock = (index: number) => {
    setSelectedIndex(index);
  };

  const renderDetails = () => {
    if (selectedIndex === null) return <></>;
    const block = templates[selectedIndex];
    return (
      <>
        <strong> {block.name} </strong>
        <DescriptionInfo>
          <span>{getTranslation('modal.templates.description')}:</span>
          <strong>{block.description}</strong>
          <span>{getTranslation('modal.templates.createdBy')}</span>
          <strong>{block?.author || ''}</strong>
          <span>{getTranslation('modal.templates.createdAt')}</span>
          <strong>{formatDate(block?.created_at || '')}</strong>
        </DescriptionInfo>
      </>
    );
  };

  const handleRemoveBlock = async (index: number) => {
    if (load) return;
    setLoad(true);
    const templatesChanged = [...templates];
    const template_id = templatesChanged[index]._id || '-1';
    templatesChanged.splice(index, 1);
    const result = await RemoveTemplate(
      {
        template_id,
      },
      dispatchApp
    );
    if (result.Success) {
      toastNotification(
        'success',
        getTranslation('toast.success.templateDeleted')
      );
      loadTemplates();
    } else {
      toastNotification(
        'error',
        result.Message || getTranslation('toast.error.templateDeleted')
      );
    }
    setSelectedIndex(null);
    setLoad(false);
  };

  const isDisabled = () => {
    return selectedIndex === null;
  };

  const handleSendToFlow = async () => {
    if (state.editorFlow?.drawflow && selectedIndex != null) {
      const { editorFlow } = state;

      const offsetPosition = new GetOffsetPositionFlowController(
        editorFlow
      ).getLastPosition();
      const drawflowCurrent = { ...editorFlow.drawflow };
      const controller = new AddBlockToFlowController(
        drawflowCurrent,
        state.idGroup || '',
        offsetPosition
      );
      await controller.addBlock(templates[selectedIndex].blocks);
      const drawflow = controller.getDrawflow();

      editorFlow.clear();
      editorFlow.import(drawflow);
      const dataFlow: DataFlow = drawflow.drawflow.Home.data;
      mountNodeContent(dataFlow);
      handleCloseModal();
    }
  };

  const isSelected = (index: number) => {
    return selectedIndex === index;
  };

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      maxWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog}>
        <ContainerHeader>
          <ModalTitle style={{ fontWeight: 600 }}>
            {getTranslation('modal.templates.title')}
          </ModalTitle>
          <IconButton
            edge="start"
            color="default"
            onClick={handleCloseModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </ContainerHeader>
        <FieldContainer>
          <Autocomplete
            freeSolo
            options={options.map((option) => option)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={getTranslation('modal.templates.search')}
                variant="outlined"
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
                InputProps={{
                  type: 'search',
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
        </FieldContainer>
        <TemplatesContainer>
          <Templates>
            {load ? (
              <CircularProgress />
            ) : (
              templates
                .map((renderCardList, index) => ({
                  renderCardList,
                  index,
                }))
                .sort((a, b) =>
                  a.renderCardList.name.localeCompare(b.renderCardList.name)
                )
                .filter(
                  (item) =>
                    item.renderCardList.name
                      .toLowerCase()
                      .indexOf(search.toLowerCase()) > -1 || !search
                )
                .map((item, index) => (
                  <>
                    <Section
                      key={`CL${index}`}
                      onClick={() => handleSelectBlock(item.index)}
                      selected={isSelected(item.index)}
                    >
                      <span>{item.renderCardList.name}</span>
                      <div>
                        <IconButton
                          edge="start"
                          color="default"
                          aria-label="publish"
                          onClick={() => handleUpdateTemplate(item.index)}
                        >
                          <PublishIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="default"
                          aria-label="delete"
                          onClick={() => handleRemoveBlock(item.index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Section>
                    <ContainerDivider>
                      <Divider variant="fullWidth" />
                    </ContainerDivider>
                  </>
                ))
            )}
          </Templates>
          <Description>{renderDetails()}</Description>
        </TemplatesContainer>
        <ButtonsContainer>
          <Button
            variant="contained"
            className={classes.buttonUpload}
            onClick={handleModalTemplateUpload}
          >
            {getTranslation('modal.templates.uploadButton')}
          </Button>
          <Button
            style={{ marginLeft: 16, letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            disabled={isDisabled()}
            onClick={handleSendToFlow}
          >
            {getTranslation('modal.templates.sendToFlowButton')}
          </Button>
        </ButtonsContainer>
      </ContainerDialog>
    </Dialog>
  );
}
