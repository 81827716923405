import { ChangeEvent, useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';

import { CloseRounded } from '@material-ui/icons';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useToast } from 'contexts/ToastContext';
import { GetBotsGroups } from 'services/BotService';
import {
  CreateCampaign,
  UpdateCampaign,
} from 'services/CampaignService/CampaignsService';
import {
  ICampaign,
  ICampaignStatus,
  ISenderChannel,
  ITemplateVariable,
  emptyCampaign,
} from 'services/CampaignService/CampaignsService/types';
import { GetFilteredTemplates } from 'services/CampaignService/TemplatesService';
import {
  ITemplate,
  ITemplateBrokers,
  ITemplateChannels,
  emptyTemplate
} from 'services/CampaignService/TemplatesService/types';
import { GetGroup } from 'services/FlowService';
import useTranslator from 'utils/hooks/Translator';
import { handleTemplateFileUpload } from 'utils/uploadFile';
import {
  BoxContainer,
  HighlightedButton,
  NewCampaignContainer,
  NewCampaignFields,
  StyledGridButtons,
  StyledRightButtons,
  TextContainer,
} from '../../styles';
import * as S from '../styles';

interface Props {
  handleClose: () => void;
  currentCampaign: ICampaign;
}

interface ICampaignError {
  name: string;
  template: string;
  variable: string;
  group: string;
  block: string;
}

export function CreateOrEditCampaign({ handleClose, currentCampaign }: Props) {
  const { getTranslation } = useTranslator();
  const { dispatch: dispatchApp } = useApp();
  const { toastNotification } = useToast();
  const { currentData } = useFintalkCloud();
  const { agentName: botName } = currentData;
  const bot_name = botName || '';
  const [error, setError] = useState<ICampaignError>({
    name: '',
    template: '',
    variable: '',
    group: '',
    block: '',
  });
  const [groups, setGroups] = useState<string[]>([]);
  const [blocks, setBlocks] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState(currentCampaign || emptyCampaign);
  const [file, setFile] = useState<File>();
  const [importFile, setImportFile] = useState(false);

  const [listTemplates, setListTemplates] = useState<ITemplate[]>([]);

  const currentTemplate =
    listTemplates.find((template) => template.name === campaign.template) ||
    emptyTemplate;

  const templateBody =
    currentTemplate.payload_message.template?.components.find(
      (component) => component.type === 'BODY'
    )?.text;

  const templateMessage =
    currentTemplate.channel === 'whatsapp' || 'falaZap'
      ? templateBody
      : currentTemplate.payload_message.text;

  const handleCampaignColumnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    setCampaign((prev) => ({
      ...prev,
      sender: {
        column: input,
        channel: prev.sender.channel || 'email',
      },
    }));
  };

  async function getGroups() {
    const response = await GetBotsGroups({ bot_name }, dispatchApp);

    if (response.Success && response.Data) {
      const flows = response.Data.data;

      const principal = flows.find((group) => group === 'principal');

      if (principal) {
        const updatedGroups = [
          principal,
          ...flows
            .filter((group) => group !== 'principal')
            .map((group) => group)
            .sort(),
        ];
        setGroups(updatedGroups);
      }
    }
  }

  async function loadTemplates() {
    if (campaign.template_channel) {
      if (campaign.template_data?.broker) {
        try {
          setLoading(true);

          const response = await GetFilteredTemplates({
            bot_name,
            channel: campaign.template_channel,
            broker: campaign.template_data.broker
          },
            dispatchApp
          )

          if (response.Success && response.Data?.data) {
            const templates = response.Data.data;

            setListTemplates([...templates])
          } else {
            toastNotification({
              type: 'warning',
              message: getTranslation('toast.error.searchTemplates'),
            });

            setListTemplates([])
          }
        } catch (error: any) {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.searchTemplates'),
          });

          setListTemplates([])
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);

          const response = await GetFilteredTemplates({
            bot_name,
            channel: campaign.template_channel
          },
            dispatchApp
          )

          if (response.Success && response.Data?.data) {
            const templates = response.Data.data;

            setListTemplates([...templates])
          } else {
            toastNotification({
              type: 'warning',
              message: getTranslation('toast.error.searchTemplates'),
            });

            setListTemplates([])
          }

        } catch (error: any) {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.searchTemplates'),
          });

          setListTemplates([])
        } finally {
          setLoading(false);
        }
      }
    }
  }

  useEffect(() => {
    getGroups();
    loadTemplates();

    setCampaign((prev) => {
      const templateData = {
        bot: {
          bot_name,
          group: prev?.template_data?.bot?.group || '',
          block: prev?.template_data?.bot?.block || '',
        },
        broker: prev?.template_data?.broker || '' as ITemplateBrokers,
      };

      return {
        ...prev,
        template_data: {
          ...templateData,
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (templateMessage) {
      const campaignVariables: ITemplateVariable[] = [];
      const regexp = /{{\d?}}/g;
      const foundVariables = templateMessage.match(regexp);
      const currCampaignVariables = campaign.variables || [];

      const externalVariables = campaign.variables?.filter(
        (variable) => !foundVariables?.includes(variable.variable)
      );

      if (foundVariables) {
        foundVariables?.forEach((variable, index) => {
          campaignVariables.push({
            variable,
            column: currCampaignVariables[index]?.column || '',
          });
        });

        externalVariables?.forEach((variable) =>
          campaignVariables.push(variable)
        );

        setCampaign((prev) => ({
          ...prev,
          variables: campaignVariables,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate])

  useEffect(() => {
    if (importFile) {
      const documentHtml = document.getElementById('drawflow');
      const uploadInput = document.createElement('input');
      uploadInput.type = 'file';
      uploadInput.onchange = async (e: any) => {
        const file = e.target.files?.item(0);
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          const reader = new FileReader();
          reader.readAsText(file, 'UTF-8');
          setFile(file);
        } else {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.invalidFileFormat'),
          });
        }
      };

      documentHtml?.appendChild(uploadInput);
      uploadInput.click();
      uploadInput.parentNode?.removeChild(uploadInput);
      setImportFile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFile]);

  useEffect(() => {
    const template = getCampaignTemplate()

    if (template?.payload_message.template?.components?.[0].type === 'HEADER') {
      if (template?.payload_message.template?.components?.[0].format !== 'IMAGE') {
        setCampaign((prev) => ({
          ...prev,
          template_data: {
            ...prev.template_data,
            header: {
              ...template?.payload_message?.template?.components[0] ?? prev.template_data.header
            }
          }
        }))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTemplates, campaign.template])

  function getCampaignTemplate(templateName?: string): ITemplate | null {
    let template;

    if (templateName) {
      template = listTemplates.find((template) => template.name === templateName)
    } else {
      template = listTemplates.find((template) => template.name === campaign.template)
    }

    return template ?? null
  }

  async function handleCreateCampaign() {
    try {
      setLoading(true);

      const template = getCampaignTemplate()

      if (template?.payload_message.template?.components[0].format === 'IMAGE') {
        if (file) {
          const fileUpload = await handleTemplateFileUpload(file, bot_name);

          if (fileUpload?.data?.url) {
            const updatedCampaign = {
              ...campaign,
              template_data: {
                ...campaign.template_data,
                header: {
                  ...campaign.template_data.header,
                  text: String(fileUpload.data.url),
                }
              }
            };

            const response = await CreateCampaign(updatedCampaign, dispatchApp);

            if (response.Success) {
              toastNotification({
                type: 'success',
                message: getTranslation('toast.success.campaignCreated'),
              });
              handleClose();
            } else {
              toastNotification({
                type: 'error',
                message: getTranslation('toast.error.campaignCreated'),
              });
            }
          } else {
            toastNotification({
              type: 'error',
              message: getTranslation('toast.error.fileUpload'),
            });

            return
          }
        } else {
          toastNotification({
            type: 'warning',
            message: 'Insira a imagem do template no header da campanha.',
          });

          return
        }
      } else {
        const response = await CreateCampaign(campaign, dispatchApp);

        if (response.Success) {
          toastNotification({
            type: 'success',
            message: getTranslation('toast.success.campaignCreated'),
          });
          handleClose();
        } else {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.campaignCreated'),
          });

          return
        }
      }
    } catch (error) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.campaignCreated'),
      });

      return
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateCampaign() {
    try {
      setLoading(true);

      if (file && campaign.template_channel === 'whatsapp') {
        const fileUpload = await handleTemplateFileUpload(file, bot_name);

        if (fileUpload?.data?.url) {
          const updatedCampaign = {
            ...campaign,
            template_data: {
              ...campaign.template_data,
              header: {
                ...campaign.template_data.header,
                text: String(fileUpload.data.url),
              }
            }
          };

          const response = await UpdateCampaign(updatedCampaign, dispatchApp);

          if (response.Success) {
            toastNotification({
              type: 'success',
              message: getTranslation('toast.success.campaignUpdated'),
            });
            handleClose();
          } else {
            toastNotification({
              type: 'error',
              message: getTranslation('toast.error.campaignUpdated'),
            });

            return
          }
        } else {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.fileUpload'),
          });

          return
        }
      } else {
        const response = await UpdateCampaign(campaign, dispatchApp);

        if (response.Success) {
          toastNotification({
            type: 'success',
            message: getTranslation('toast.success.campaignUpdated'),
          });

          handleClose();
        } else {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.campaignUpdated'),
          });

          return
        }
      }
    } catch (error) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.campaignUpdated'),
      });
    } finally {
      setLoading(false);
    }
  }

  function handleChangeColumn(index: number, newColumn: string) {
    const newVariables = campaign.variables;

    if (newVariables) {
      newVariables[index].column = newColumn;

      setCampaign((prev) => ({
        ...prev,
        variables: newVariables,
      }));
    }
  }

  async function handleChangeChannel(channel: ITemplateChannels) {
    if (channel !== 'whatsapp') {
      try {
        setLoading(true);

        const response = await GetFilteredTemplates({ bot_name, channel }, dispatchApp)

        if (response.Success && response.Data?.data) {
          const templates = response.Data.data;

          setListTemplates([...templates])
        } else {
          toastNotification({
            type: 'warning',
            message: 'Não foi encontrado templates nessas condições',
          });

          setListTemplates([])
        }

      } catch (error: any) {
        toastNotification({
          type: 'error',
          message: getTranslation('toast.error.searchTemplates'),
        });
      } finally {
        setLoading(false);
      }
    }

    setCampaign((prev) => ({
      ...prev,
      template_channel: channel,
      template_data: {
        ...prev.template_data,
        broker: '',
      }
    }))
  }

  function handleChangeTemplate(templateName: string) {
    const template = getCampaignTemplate(templateName)

    const templateMessage = template?.channel === ('whatsapp' || 'falazap') ? (
      template.payload_message.template?.components.find((component) => component.type === 'BODY')?.text
    ) : (
      currentTemplate?.payload_message?.text
    )

    const templateHeader = template?.payload_message?.template?.components?.[0].type === 'HEADER' && (
      template?.payload_message?.template?.components?.[0]
    )

    let campaignVariables: ITemplateVariable[] = [];

    if (templateMessage) {
      const regexp = /{{\d?}}/g;
      const foundVariables = templateMessage.match(regexp);

      foundVariables?.forEach((variable) => {
        campaignVariables.push({
          variable,
          column: '',
        })
      })
    };

    setCampaign((prev) => {
      if (templateHeader) {
        return {
          ...prev,
          template: templateName,
          template_data: {
            ...prev.template_data,
            header: {
              ...templateHeader,
            },
          },
          variables: campaignVariables,
        };
      } else {
        return {
          ...prev,
          template: templateName,
          template_data: {
            bot: prev.template_data.bot,
            broker: prev.template_data.broker,
          },
          variables: campaignVariables,
        };
      }
    });
  }

  async function handleChangeGroup(groupName: string) {
    const blocks: string[] = [];

    const response = await GetGroup({ bot_name, groupName }, dispatchApp);

    if (response.Success) {
      const group = response.Data.data;

      const keys = Object.keys(group.blocks.drawflow.Home.data);

      for (const key in keys) {
        const block = group.blocks.drawflow.Home.data[Number(keys[key])];
        blocks.push(block.data.name);
      }
    }
    setBlocks(blocks.sort());

    setCampaign((prev) => ({
      ...prev,
      template_data: {
        ...prev.template_data,
        bot: {
          ...prev.template_data.bot,
          group: groupName,
        },
      }
    }));
  }

  function handleChangeBlock(block: string) {
    setCampaign((prev) => ({
      ...prev,
      template_data: {
        ...prev.template_data,
        bot: {
          ...prev.template_data.bot,
          block
        }
      }
    })
    )
  }

  async function handleChangeTemplateBroker(broker: ITemplateBrokers) {
    try {
      setLoading(true);

      const response = await GetFilteredTemplates({ bot_name, channel: campaign.template_channel, broker }, dispatchApp)

      if (response.Success && response.Data?.data) {
        const templates = response.Data.data;

        setListTemplates([...templates])
      } else {
        toastNotification({
          type: 'warning',
          message: 'Não foi encontrado templates nessas condições',
        });

        setListTemplates([])
      }
    } catch (error: any) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.searchTemplates'),
      });
    } finally {
      setLoading(false);
    }

    setCampaign((prev) => ({
      ...prev,
      template_data: {
        ...prev.template_data,
        broker,
      }
    })
    )
  }

  function validateCampaign(campaign: ICampaign) {
    const errors: ICampaignError = {
      name: '',
      template: '',
      variable: '',
      group: '',
      block: '',
    };

    if (!campaign.name) {
      errors.name = getTranslation('validations.required', {
        field: getTranslation('name'),
      })
    }

    if (!campaign.template) {
      errors.template = getTranslation('validations.required', {
        field: 'Template',
      })
    }

    if (!campaign.template_data.bot.group) {
      errors.group = getTranslation('validations.required', {
        field: getTranslation('group'),
      })
    }

    if (!campaign.template_data.bot.block) {
      errors.block = getTranslation('validations.required', {
        field: getTranslation('block'),
      })
    }

    campaign.variables?.forEach((variable, index) => {
      if (variable.column === '') {
        errors.variable = getTranslation('validations.requiredValue')
      }
    })

    setError(errors);

    if (
      !errors.name &&
      !errors.template &&
      !errors.group &&
      !errors.block &&
      !errors.variable
    ) {
      if (!campaign.sender.column || !campaign.sender.channel) {
        toastNotification({
          type: 'warning',
          message: 'O campo de envio é obrigatório',
        });
  
        return
      }

      campaign._id ? handleUpdateCampaign() : handleCreateCampaign();
    }
  }

  return (
    <NewCampaignContainer>
      <NewCampaignFields>
        <BoxContainer>
          <TextContainer style={{ maxWidth: 'fit-content' }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={campaign.status ?? ''}
                onChange={(e) =>
                  setCampaign((prev) => ({
                    ...prev,
                    status: e.target.value as ICampaignStatus,
                  }))
                }
                label="Status"
              >
                <MenuItem value={'active'}>{getTranslation('active')}</MenuItem>
                <MenuItem value={'inactive'}>
                  {getTranslation('inactive')}
                </MenuItem>
              </Select>
            </FormControl>
          </TextContainer>
        </BoxContainer>

        <BoxContainer>
          <TextContainer>
            <TextField
              variant="outlined"
              label={getTranslation('name')}
              fullWidth
              value={campaign.name}
              onChange={(e) =>
                setCampaign((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              helperText={error.name}
              error={!!error.name}
            />
          </TextContainer>
        </BoxContainer>

        <BoxContainer>
          <TextContainer>
            <TextField
              variant="outlined"
              label={getTranslation('description')}
              fullWidth
              value={campaign.description ?? ''}
              onChange={(e) =>
                setCampaign((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </TextContainer>
        </BoxContainer>

        <BoxContainer>
          <TextContainer>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                {getTranslation('channel')}
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={campaign.template_channel ?? ''}
                onChange={(e) =>
                  handleChangeChannel(e.target.value as ITemplateChannels)
                }
                label={getTranslation('channel')}
              >
                <MenuItem value={'whatsapp'}>Whatsapp</MenuItem>
                <MenuItem value={'falazap'}>Falazap</MenuItem>
                <MenuItem value={'sms'}>SMS</MenuItem>
                <MenuItem disabled value={'email'}>
                  E-mail
                </MenuItem>
                <MenuItem disabled value={'ura'}>
                  URA
                </MenuItem>
              </Select>
            </FormControl>
          </TextContainer>
        </BoxContainer>

        {campaign.template_channel === 'whatsapp' && (
          <BoxContainer>
            <TextContainer>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  {getTranslation('broker')}
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={campaign.template_data?.broker ?? ''}
                  onChange={(e) =>
                    handleChangeTemplateBroker(
                      e.target.value as ITemplateBrokers
                    )
                  }
                  label={getTranslation('broker')}
                  defaultValue={campaign.template_data?.broker ?? 'Meta'}
                >
                  <MenuItem value={'Meta'}>Meta</MenuItem>
                  <MenuItem value={'InfoBip'}>InfoBip</MenuItem>
                  <MenuItem value={'Blip'}>Blip</MenuItem>
                  <MenuItem value={'Twillio'}>Twillio</MenuItem>
                  <MenuItem value={'Interaxa'}>Interaxa</MenuItem>
                  <MenuItem value={'Zenvia'}>Zenvia</MenuItem>
                </Select>
              </FormControl>
            </TextContainer>
          </BoxContainer>
        )}

        {(campaign.template_channel === 'whatsapp' || campaign.template_channel === 'falazap') && (
          <BoxContainer style={{ alignItems: 'start' }}>
            <TextContainer>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label" shrink>
                  {getTranslation('group')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={campaign.template_data?.bot?.group ?? ''}
                  onChange={(e) => {
                    handleChangeGroup(e.target.value as string);
                  }}
                  label={getTranslation('group')}
                >
                  {groups.map((group) => (
                    <MenuItem key={group} value={group ?? ''}>
                      {group}
                    </MenuItem>
                  ))}
                </Select>

                {error.group && (
                  <p
                    className='MuiFormHelperText-root MuiFormHelperText-contained Mui-error'
                  >
                    {error.group}
                  </p>
                )}
              </FormControl>
            </TextContainer>

            <TextContainer>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label" shrink>
                  {getTranslation('block')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={campaign.template_data?.bot?.block ?? ''}
                  onChange={(e) =>
                    handleChangeBlock(e.target.value as string)
                  }
                  label={getTranslation('block')}
                >
                  {blocks.length > 0 ? (
                    blocks.map((block) => (
                      <MenuItem key={block} value={block}>
                        {block}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={campaign.template_data?.bot?.block ?? ''}>
                      {campaign.template_data?.bot?.block}
                    </MenuItem>
                  )}
                </Select>

                {error.block && (
                  <p
                    className='MuiFormHelperText-root MuiFormHelperText-contained Mui-error'
                  >
                    {error.block}
                  </p>
                )}
              </FormControl>
            </TextContainer>
          </BoxContainer>
        )}

        <BoxContainer>
          <TextContainer>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="template-id">Template</InputLabel>
              <Select
                labelId="template-id"
                id="demo-simple-select-outlined"
                value={campaign.template ?? ''}
                onChange={(e) => handleChangeTemplate(String(e.target.value))}
                label="Template"
                error={!!error.template}
              >
                {listTemplates.length > 0 ? (
                  listTemplates.map((template) => (
                    <MenuItem key={template._id} value={template.name}>
                      {template.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={campaign.template}>
                    {campaign.template}
                  </MenuItem>
                )}
              </Select>
              <FormHelperText id="template-id" error={!!error.template}>
                {error.template}
              </FormHelperText>
            </FormControl>
          </TextContainer>
        </BoxContainer>

        {campaign?.template_data?.header?.text && (
          <Container disableGutters={true}>
            <Typography
              variant="h6"
              children={getTranslation('header')}
              style={{ marginLeft: '8px' }}
            />

            <BoxContainer>
              <TextContainer style={{ width: 'fit-content' }}>
                <FormControl variant="outlined">
                  <Select
                    value={campaign.template_data?.header?.format ?? 'IMAGE'}
                    disabled
                  >
                    <MenuItem value={'NONE'}>
                      {getTranslation('none')}
                    </MenuItem>
                    <MenuItem value={'TEXT'}>
                      {getTranslation('text')}
                    </MenuItem>
                    <MenuItem
                      value={'IMAGE'}
                      hidden
                    >
                      {getTranslation('image')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </TextContainer>

              {campaign.template_data?.header?.format === 'TEXT' && (
                <TextContainer>
                  <OutlinedInput
                    fullWidth
                    value={campaign.template_data?.header?.text ?? ''}
                    endAdornment={
                      <InputAdornment position="end">
                        {campaign.template_data?.header?.text?.length || '0'}/60
                      </InputAdornment>
                    }
                    disabled
                  />
                </TextContainer>
              )}

              {campaign.template_data?.header?.format === 'IMAGE' && (
                <>
                  <TextContainer>
                    <OutlinedInput
                      fullWidth
                      value={file?.name ?? ''}
                      onChange={(e) =>
                        setCampaign((prev) => ({
                          ...prev,
                          template_data: {
                            ...prev.template_data,
                            header: {
                              ...prev.template_data.header,
                              text: e.target.value,
                            },
                          },
                        }))
                      }
                      disabled
                    />
                  </TextContainer>

                  <TextContainer>
                    <HighlightedButton
                      height={53}
                      fullWidth
                      onClick={() => setImportFile(true)}
                    >
                      {getTranslation('loadFile')}
                    </HighlightedButton>
                  </TextContainer>
                </>
              )}
            </BoxContainer>
          </Container>
        )}

        <BoxContainer>
          <TextContainer>
            <TextField
              variant="outlined"
              fullWidth
              value={templateMessage}
              disabled
            />
          </TextContainer>
        </BoxContainer>

        <Container disableGutters={true}>
          <Typography
            variant="h6"
            children={getTranslation('modal.campaigns.template.senderField')}
            style={{ marginLeft: '8px' }}
          />

          <BoxContainer style={{ marginTop: '4px' }}>
            <TextContainer>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="sender-id">
                  {getTranslation('modal.campaigns.template.senderField')}
                </InputLabel>
                <Select
                  labelId="sender-id"
                  id="demo-simple-select-outlined"
                  value={campaign.sender.channel}
                  onChange={(e) =>
                    setCampaign((prev) => ({
                      ...prev,
                      sender: {
                        channel: e.target.value as ISenderChannel,
                        column: campaign.sender.column || '',
                      },
                    }))
                  }
                  label={getTranslation('modal.campaigns.template.senderField')}
                >
                  <MenuItem value={'phone'}>{getTranslation('phone')}</MenuItem>
                  <MenuItem value={'email'}>Email</MenuItem>
                  <MenuItem value={'device_id'}>
                    {getTranslation('deviceID')}
                  </MenuItem>
                </Select>
              </FormControl>
            </TextContainer>

            <TextContainer>
              <TextField
                variant="outlined"
                label="Nome do campo"
                InputLabelProps={{ shrink: true, }}
                fullWidth
                value={campaign.sender.column}
                onChange={handleCampaignColumnChange}
              />
            </TextContainer>
          </BoxContainer>
        </Container>

        {campaign.variables && campaign.variables.length > 0 && (
          <S.BoxVariableAndColumnContainer>
            <S.BoxVariableAndColumn>
              <BoxContainer>
                <Typography
                  variant="h6"
                  children={getTranslation(
                    'modal.campaigns.campaign.templateVariables'
                  )}
                />
              </BoxContainer>

              {campaign.variables?.map((variable, index) => (
                <TextContainer key={`${variable.variable}-${index}`} paddingx='0px'>
                  <TextField
                    key={`${variable.variable}-${index}`}
                    variant="outlined"
                    fullWidth
                    value={variable.variable}
                  />
                </TextContainer>
              ))}
            </S.BoxVariableAndColumn>

            <S.BoxVariableAndColumn>
              <BoxContainer>
                <Typography
                  variant="h6"
                  children={getTranslation('fieldName')}
                />
              </BoxContainer>

              {campaign.variables?.map((variable, index) => (
                <TextContainer key={`${variable.variable}-${index}-column`} paddingx='0px'>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={variable.column}
                    onChange={(e) => handleChangeColumn(index, e.target.value)}
                    error={!!error.variable}
                    helperText={!variable.column && error.variable}
                    FormHelperTextProps={{
                      style: {
                        position: 'absolute',
                        bottom: '-20px',
                      }
                    }}
                  />
                </TextContainer>
              ))}
            </S.BoxVariableAndColumn>
          </S.BoxVariableAndColumnContainer>
        )}
      </NewCampaignFields>

      <StyledGridButtons>
        <StyledRightButtons>
          <Button
            disabled={loading}
            variant="contained"
            color="default"
            startIcon={<CloseRounded />}
            onClick={() => handleClose()}
          >
            {getTranslation('footerButtons.discardAndExit')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CheckIcon />}
            onClick={() => validateCampaign(campaign)}
            disabled={loading}
          >
            {getTranslation('footerButtons.saveAndExit')}
            {loading && <CircularProgress color="inherit" size={18} />}
          </Button>
        </StyledRightButtons>
      </StyledGridButtons>
    </NewCampaignContainer>
  );
}
