import * as yup from 'yup';
import { Block } from '../contexts/Library/types';
import { IResultError } from './ResultError';

export interface ITemplate {
  name: string;
  description: string;
  blocks: string;
}

export const newTemplateValidation = async (
  newTemplate: Block,
  list: Block[]
) => {
  const nameResult = await nameValidator(newTemplate.name);
  if (nameResult.isValid) {
    nameResult.isValid = !list.some(
      (item) =>
        item.name.trim().toLowerCase() === newTemplate.name.trim().toLowerCase()
    );
    if (!nameResult.isValid) {
      nameResult.errors = {
        ...nameResult.errors,
        name: 'Já existe um template com esse nome.',
      };
    }
  }

  const blocksResult: IResultError<ITemplate> = !!Object.keys(
    newTemplate.blocks
  ).length
    ? { isValid: true, errors: { blocks: '' } }
    : { isValid: false, errors: { blocks: '*Arquivo obrigatório.' } };
  const isValid = nameResult.isValid && blocksResult.isValid;
  const errors = { ...nameResult.errors, ...blocksResult.errors };
  return { isValid, errors };
};
const nameValidator = async (name: string) => {
  const schema = yup.object().shape({
    name: yup.string().required('*Nome obrigatório.'),
  });

  const promiseResult = new Promise<IResultError<ITemplate>>((resolve) => {
    schema
      .validate({ name }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { name: '' } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { name: err.errors[0] } });
      });
  });
  return await promiseResult;
};
