import { createStyles, makeStyles, Modal, Popover } from '@material-ui/core';
import styled from 'styled-components';


export const FlagIcon = styled.button<{ color: string }>`
  background: ${(props) => (props.color ? props.color : '#06fb00')};
  border: 1px solid #fff;
  border-radius: 50%;
  width: 17px !important;
  height: 17px !important;

  :hover {
    cursor: pointer;
  }
`;

export const PopoverContainer = styled(Popover)`
  margin-top: 10px;
  margin-left: 5px;
  z-index: 3;
  max-width: 150px;

  padding: 4px;
`;

export const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  width: 100%;
  height: 26px;

  margin-top: 4px;

  .MuiOutlinedInput-root {
		margin-left: 8px;
    max-height: 26px;
  }
`;

export const Flag = styled.button<{ color: string }>`
  background: ${(props) => (props.color ? props.color : '#06fb00')};
  border: 1px solid #c0c0c0;
  border-radius: 50%;
  min-width: 14px !important;
  height: 14px !important;

  :hover {
    cursor: pointer;
  }
`;

export const ModalColorPickerContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 12px;

  input {
    background: #26274c;
    color: #fff;
    box-shadow: none !important;
    border: 1px solid #353759 !important;
  }

  label {
    color: #fff !important;
  }

  .flexbox-fix {
    border-top: 1px solid #353759 !important;
  }
`

export const FlagFooterButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
`

export const useStyles = makeStyles(() =>
  createStyles({
    flagDeleteButton: {
      height: '100%',
      minWidth: '32px',
      padding: 0,
      marginLeft: '12px',

      '&.Mui-disabled': {
        border: 'none !important',
        color: '#000 !important',
        opacity: '0.5 !important'
      },
    },
  })
);