import clsx from 'clsx';
import { useState } from 'react';

import { TextField } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useAirTalk } from 'contexts/AirTalkContext';
import useTranslator from 'utils/hooks/Translator';
import Dictaphone from './Dictaphone';
import { useStyles } from './styles';

export default function AirTalkSidebar({
  handleClose,
}: {
  handleClose: () => void;
}) {
  const classes = useStyles();
  const [userPrompt, setUserPrompt] = useState('');
  const { loading } = useAirTalk();
  const { getTranslation } = useTranslator();

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: true,
      })}
      classes={{
        paper: clsx({
          [loading ? classes.drawerOpenLoading : classes.drawerOpen]: true,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          color: loading ? '#8C95FF' : '#000',
					gap: 8
        }}
      >
        {loading ? (
          <>
            <video
              controls={false}
              autoPlay={true}
              style={{ width: 156, alignSelf: 'center' }}
              loop={true}
            >
              <source
                src="https://cdn.dribbble.com/users/556104/screenshots/16811521/media/9661f3419e8ff1a4fecdbf8a585750ec.mp4"
                type="video/mp4"
              ></source>
            </video>
            <h3>Creating...</h3>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              flexWrap: 'wrap',
              gap: '8px',
              padding: '4px',
            }}
          >
            {getTranslation('airtalkSideBar.title')}
            <TextField
              multiline
              minRows={'6'}
              variant="outlined"
              fullWidth
              value={userPrompt}
              onChange={(e) => setUserPrompt(e.target.value)}
            />
            <Dictaphone setUserPrompt={setUserPrompt} userPrompt={userPrompt} />
          </div>
        )}
      </div>
    </Drawer>
  );
}
