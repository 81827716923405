export function N8N() {
  return (
    <iframe
      title="n8n"
      src="https://n8n-prd.fintalk.io/"
      width="100%"
      height="100%"
    ></iframe>
  );
}
