import { fixInputsAndOutputs } from 'components/Flow/utils/NodeContainer';
import { useFlow } from 'contexts/Flow/flowContext';
import { DataFlowObject } from 'contexts/Flow/types';
import { useToast } from 'contexts/ToastContext';
import { DataFlow } from 'models/DataFlow';
import { createContext, useCallback, useContext, useState } from 'react';
import GptToDrawflowFormatter from 'utils/gptToDrawflowFormatter';
import useTranslator from 'utils/hooks/Translator';
import { AirTalkContextState, Props } from './types';

const AirTalkContext = createContext<AirTalkContextState>(
  {} as AirTalkContextState
);

const apiHubURL = process.env.REACT_APP_API_HUB_RUN_URL

export function AirTalkContextProvider({ children }: Props) {
  const { state, mountNodeContent, goToNodePosition } = useFlow();
  const [loading, setLoading] = useState(false);
  const {  toastNotification: toast } = useToast();
  const { getTranslation } = useTranslator();

  const handleChangeFlow = useCallback(
    (newNodes) => {
      if (!state.editorFlow) return;
      const { updatedEditorFlow, firstNodeOfNewNodesId } =
        GptToDrawflowFormatter(state.editorFlow, newNodes);

      const drawflow = updatedEditorFlow.drawflow.drawflow as DataFlowObject;

      try {
        state.editorFlow?.import({ drawflow });
      } catch (e) {
        const fixedDrawflow = fixInputsAndOutputs(drawflow);

        try {
          state.editorFlow?.import(fixedDrawflow);
        } catch (e) {
          console.log(e);
        }
      }

      const dataFlow: DataFlow = state?.editorFlow?.drawflow.drawflow.Home.data;
      mountNodeContent(dataFlow);
      goToNodePosition(firstNodeOfNewNodesId);
    },
    [goToNodePosition, mountNodeContent, state.editorFlow]
  );

  const generateText = useCallback(
    async (promptToUse: string, sendToFlow = false): Promise<string> => {
      if (!promptToUse) return '';
      
      try {
        setLoading(true);

        const resp = await fetch(
          `${apiHubURL}/generate-text?text=${promptToUse}`
        );

        const data = await resp.json();

        if (!resp.ok) {
          throw new Error('');
        }

        if (sendToFlow) handleChangeFlow(JSON.parse(data.generatedText));

        setLoading(false);

        return data.generatedText;
      } catch (error) {
        console.log(error)
        
        toast({
          type: 'warning',
          message: getTranslation('toast.error.error'),
        })

        return '';
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChangeFlow]
  );

  return (
    <AirTalkContext.Provider
      value={{
        loading,
        generateText,
      }}
    >
      {children}
    </AirTalkContext.Provider>
  );
}

export function useAirTalk() {
  const context = useContext(AirTalkContext);
  return context;
}
