import { createRef, useCallback, useEffect, useRef, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { useForm } from 'contexts/Form/formContext';
import { GetEntities } from 'services/EntityService';
import {
  convertRemoteVariable,
  createUserSentenceFromSentence,
  replaceSpanToNotation,
} from 'utils/Sentences';

import DeleteIcon from '@material-ui/icons/Delete';

import MttButton from 'components/Material/MttButton/MttButton';
import { OutsideClick } from 'components/OutsideClick';

import {
  Container,
  SentenceInput,
  StyledContainerFrases,
  StyledFrasesUsuario,
  StyledItemFrases,
  StyledMttTypographyFrase,
  StyleSentenceRow,
} from './styles';
import { ISentencesProps } from './types';
import { SentenceParameters } from './SentenceParameters';
import { ModalSearchVariables } from './ModalSearchVariables';
import { IconButton } from '@material-ui/core';
import useTranslator from 'utils/hooks/Translator';
import { usePermissions } from 'contexts/Permissions/permissionsContext';

let allEntities = [
  { id: '@sys.any', name: 'qualquer-input' },
  { id: '@sys.email', name: 'input-de-email' },
  { id: '@sys.number', name: 'input-de-numero' },
  { id: '@sys.date-time', name: 'input-de-data' },
  { id: '@sys.url', name: 'input-de-url' },
];

export default function Sentences(props: ISentencesProps) {
  const { title, bot_name, numberOfUserSentences } = props;
  const { state, dispatch } = useForm();
  const { state: stateFlow } = useFlow();
  const { hasPermissionToAction } = usePermissions();

  const { dataForm } = state;
  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && !dataForm.inputs) {
      const userSentences = !!numberOfUserSentences
        ? [...Array(numberOfUserSentences).fill('')]
        : ['', '', ''];

      dataForm.inputs = {
        userSentences,
        variables: [],
      };

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const regExpEspaceBeforeSpan = /(\s|&nbsp;)<\/span>/;

  function handleChangeSentence(editedPhrase: string, sentenceIndex: number) {
    if (regExpEspaceBeforeSpan.test(editedPhrase)) {
      changeSentenceWithBlanckSpace(editedPhrase, sentenceIndex);
    } else {
      changeSentence(editedPhrase, sentenceIndex);
    }
  }

  function changeSentence(editedPhrase: string, sentenceIndex: number) {
    editedPhrase = editedPhrase.replace(regExpEspaceBeforeSpan, '</span> ');

    const phrase = replaceSpanToNotation(
      editedPhrase,
      dataForm?.inputs?.variables ?? []
    );

    updateDataFormUserSentence(phrase, sentenceIndex);
  }

  function changeSentenceWithBlanckSpace(
    editedPhrase: string,
    sentenceIndex: number
  ) {
    editedPhrase = editedPhrase.replace(
      regExpEspaceBeforeSpan,
      '</span>&nbsp;'
    );
    const phrase = replaceSpanToNotation(
      editedPhrase,
      dataForm?.inputs?.variables ?? []
    );

    updateDataFormUserSentence(phrase, sentenceIndex);
  }

  function updateDataFormUserSentence(
    updatedSentence: string,
    sentenceIndex: number
  ) {
    if (!!dataForm && dataForm.inputs?.userSentences) {
      const phrases =
        dataForm.inputs?.userSentences.map((sentence, i) =>
          i === sentenceIndex ? updatedSentence : sentence
        ) || [];

      dataForm.inputs.userSentences = phrases;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }

  const handleDeleteFrase = (index: number) => {
    if (!!dataForm && dataForm.inputs) {
      const inputPhrases =
        dataForm.inputs.userSentences?.filter((_, i) => i !== index) || [];
      dataForm.inputs.userSentences = inputPhrases;
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleAddSentences = () => {
    if (dataForm) {
      if (dataForm.inputs?.userSentences) {
        dataForm.inputs.userSentences.push('');
      }

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const [caretPositionTimeout, setCaretPositionTimeout] =
    useState<NodeJS.Timeout>();
  const [showActionsParameters, setShowActionsParameters] = useState<boolean[]>(
    []
  );

  function removeParameterFromSentenceIndex(sentence: string, index: number) {
    const userSentences = dataForm?.inputs?.userSentences ?? [];

    const clone = [...userSentences];
    clone[index] = sentence;

    if (!!dataForm?.inputs) {
      dataForm.inputs.userSentences = clone;
      dataForm.inputs.variables = dataForm.inputs.variables.splice(index, 1);

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }

  const { dispatch: dispatchApp } = useApp();
  const { botName } = stateFlow;
  const [selectedSentenceIndex, setSelectedSentenceIndex] = useState<number>();
  const [showModalVariables, setShowModalVariables] = useState(false);
  const [definedPosition, setDefinedPosition] = useState(false);
  const [selectedText, setSelectedText] = useState<string>();
  const [remoteVariables, setRemoteVariables] = useState<
    { id: string; name: string }[]
  >([]);

  function onGetSelection(event: any) {
    event.preventDefault();
    let text = '';

    const selection = window?.getSelection();

    const tagName = selection?.anchorNode?.parentElement?.tagName;
    const focusTagName = selection?.focusNode?.parentElement?.tagName;

    text = selection?.toString().trim() ?? '';
    const isValidOpen = !!text && !showModalVariables;
    const isEqualsSpan =
      !!selection?.focusNode?.parentNode &&
      selection?.anchorNode?.parentNode?.isEqualNode(
        selection?.focusNode?.parentNode
      );
    const isValidSelection =
      tagName !== 'SPAN' ||
      (tagName === 'SPAN' && focusTagName === 'SPAN' && !isEqualsSpan);

    if (isValidOpen && isValidSelection) {
      setShowModalVariables(true);
      setDefinedPosition(false);
      setSelectedText(text);
    }
  }

  const wrapperRef = createRef<any>();

  useEffect(() => {
    const wrapperCurrent = wrapperRef.current;
    wrapperCurrent?.addEventListener('mouseup', onGetSelection);

    return () => {
      wrapperCurrent?.removeEventListener('mouseup', onGetSelection);
    };
  });

  function onSelectVariableType(variableType: string) {
    const isValidSelectedSentence =
      typeof selectedSentenceIndex !== 'undefined' && !!selectedText;

    if (isValidSelectedSentence) {
      const userSentences = dataForm?.inputs?.userSentences ?? [];
      const sentence = userSentences[selectedSentenceIndex];
      const userSentence = createUserSentenceFromSentence(
        sentence,
        dataForm?.inputs?.variables ?? []
      );

      let cleanSentence = sentence;

      const oldParameters: any[] = [];

      userSentence.parameters.forEach((parameter) => {
        oldParameters.push(parameter);

        const { entity, name, resolvedValue } = parameter;
        const regexpPhrase = `[${resolvedValue}](${name}:${entity})`;
        cleanSentence = cleanSentence.replace(regexpPhrase, resolvedValue);
      });

      const newParameters = oldParameters.filter(
        (parameter) => !selectedText?.includes(parameter.resolvedValue)
      );

      const extractedVariableIndex = getExtractedVariableIndex(variableType);
      const variables = dataForm?.inputs?.variables ?? [];
      const variable = variables[extractedVariableIndex];

      if (!!variable)
        newParameters.push({
          name: variable.name,
          entity: variable.vartype,
          resolvedValue: selectedText,
        });

      newParameters.forEach(({ entity, name, resolvedValue }) => {
        cleanSentence = cleanSentence.replace(
          resolvedValue,
          `[${resolvedValue}](${name}:${entity})`
        );
      });
      if (!!dataForm && !!dataForm?.inputs) {
        if (!Array.isArray(dataForm.inputs.userSentences)) {
          dataForm.inputs.userSentences = [cleanSentence];
        } else {
          dataForm.inputs.userSentences[selectedSentenceIndex] = cleanSentence;
        }

        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  }

  function getExtractedVariableIndex(variableType: string) {
    let extractedVariableIndex = -1;

    const extractedVariables = dataForm?.inputs?.variables ?? [];
    const isExtractedVariableIncludesVariable = extractedVariables.some(
      (variable) => variable.vartype.includes(variableType)
    );

    if (isExtractedVariableIncludesVariable) {
      extractedVariableIndex = extractedVariables.findIndex((variable) =>
        variable.vartype.includes(variableType)
      );
    } else {
      const remoteVariable = remoteVariables.find(
        (variable) => variable.name === variableType
      );

      const extractedVariable = extractedVariables.find((variable) =>
        variable.vartype.includes(remoteVariable?.id ?? '')
      );

      if (!!extractedVariable) {
        extractedVariableIndex = extractedVariables.findIndex(
          (variable) => variable.vartype === remoteVariable?.id
        );
      } else if (!!remoteVariable) {
        const variable = convertRemoteVariable(remoteVariable);
        const currentVartype = variable.id;

        extractedVariables.push({
          name: variable.name,
          vartype: currentVartype,
          value: '$' + variable.name,
          questions: ['', '', ''],
          isList: false,
          required: false,
          defaultValue: '',
        });

        extractedVariableIndex = extractedVariables.length - 1;
      }
    }

    if (!!dataForm && !!dataForm?.inputs) {
      dataForm.inputs.variables = extractedVariables;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }

    return extractedVariableIndex;
  }

  async function remoteGetEntities() {
    let currBot = botName || bot_name;

    if (currBot) {
      let result = await GetEntities({ bot_name: currBot || '' }, dispatchApp);
      if (result.Success) {
        if (!!result.Data) {
          const entities = result.Data;
          const cloneEntities = [
            ...allEntities,
            ...entities.map((entity) => ({
              id: entity.name,
              name: entity.name,
            })),
          ].sort(function (a, b) {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          setRemoteVariables(cloneEntities);
        }
      }
    } else {
      setRemoteVariables(allEntities);
    }
  }

  useEffect(() => {
    remoteGetEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName]);

  type CaretPositionChildIndex = {
    position: number;
    childNodeIndex: number | undefined;
    element: ParentNode | null | undefined;
  };

  function getCaretPositionAndChildNodeIndex(
    editableDiv: Node
  ): CaretPositionChildIndex {
    let selection: Selection | null;
    let range: Range;

    let position = 0;
    let childNodeIndex: number | undefined;
    let element: ParentNode | null | undefined;

    if (window.getSelection) {
      selection = window.getSelection();
      if (selection && selection.rangeCount) {
        range = selection.getRangeAt(0);

        childNodeIndex = Array.from(editableDiv.childNodes).findIndex(
          (childNode) =>
            childNode.nodeValue === range.commonAncestorContainer.nodeValue
        );

        if (childNodeIndex === -1) {
          childNodeIndex = findIndexFromElement(
            editableDiv.childNodes,
            range.commonAncestorContainer.parentNode
          );

          element = range.commonAncestorContainer.parentNode;
        }
        position = range.endOffset;
      }
    }

    return {
      position,
      childNodeIndex,
      element,
    };
  }

  function findIndexFromElement(
    array: NodeListOf<ChildNode>,
    element: ParentNode | null
  ) {
    return Array.prototype.findIndex.call(array, (item) => {
      return item === element;
    });
  }

  const contentEditableRef = useRef<HTMLDivElement[]>(
    []
  ) as React.MutableRefObject<HTMLDivElement[]>;

  function setCaretPosition(
    { position, childNodeIndex, element }: CaretPositionChildIndex,
    content: HTMLElement | null
  ) {
    try {
      const range = document.createRange();
      const selection = window.getSelection();

      const hasSelectionAndContent = selection && content;
      const isValidChildNodeIndex =
        typeof childNodeIndex === 'number' && childNodeIndex >= 0;
      const isFirstContent = childNodeIndex === -1 || !isValidChildNodeIndex;

      if (hasSelectionAndContent) {
        let currentContent = content;

        if (isValidChildNodeIndex) {
          if (!!element) {
            const nodes = content.querySelectorAll('span');
            const index = Array.from(nodes).findIndex((node) => {
              return node.innerText === element.textContent;
            });

            if (!!nodes[index] && nodes[index].childNodes) {
              range.setStart(nodes[index].childNodes[0], position);
              currentContent = nodes[index];
            }
          } else {
            const childNode = content.childNodes[childNodeIndex];

            range.setStart(childNode, position);
          }
        } else if (isFirstContent) {
          range.setStart(content.childNodes[0], position);
        }

        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        console.log(selection);
        currentContent.focus();
      }
    } catch (error: any) {
      console.log('setCaret', error.message);
    }
  }

  return (
    <Container>
      <StyledFrasesUsuario>
        <StyledMttTypographyFrase>
          {title || getTranslation('userEntryPhrases')}
        </StyledMttTypographyFrase>
        {hasPermissionToAction({
          company: stateFlow.companyName || '',
          agent: stateFlow.botName || '',
          action: ['flow:write'],
        }) && (
          <MttButton
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddSentences}
          >
            {getTranslation('addNewPhrase')}
          </MttButton>
        )}
      </StyledFrasesUsuario>
      <StyledContainerFrases id="multipleSentences" ref={wrapperRef}>
        {dataForm?.inputs?.userSentences?.map((sentence, sentenceIndex) => {
          const userSentence = createUserSentenceFromSentence(
            sentence,
            dataForm?.inputs?.variables ?? []
          );

          return (
            <StyleSentenceRow>
              <StyledItemFrases
                key={sentenceIndex}
                onFocus={() => {
                  setShowActionsParameters([]);
                  const clone = [];
                  clone[sentenceIndex] = true;
                  setShowActionsParameters(clone);
                }}
              >
                <FormatQuoteIcon className="FormatQuoteIcon" />
                <SentenceInput
                  data-type="sentence"
                  data-focused={!!showActionsParameters[sentenceIndex]}
                  dangerouslySetInnerHTML={{
                    __html: userSentence.replacedPhrase,
                  }}
                  contentEditable={hasPermissionToAction({
                    company: stateFlow.companyName || '',
                    agent: stateFlow.botName || '',
                    action: ['flow:write'],
                  })}
                  onClick={() => setSelectedSentenceIndex(sentenceIndex)}
                  ref={(el) => {
                    if (!!el) {
                      contentEditableRef.current[sentenceIndex] = el;
                    }
                  }}
                  onPaste={(e: any) => {
                    e.preventDefault();
                    let phrase = (e.originalEvent || e).clipboardData.getData(
                      'text'
                    );
                    phrase = phrase.replace(/(<.+?(?=>)>)/g, '');
                    updateDataFormUserSentence(phrase, sentenceIndex);
                  }}
                  onInput={(event) => {
                    if (caretPositionTimeout) {
                      clearTimeout(caretPositionTimeout);
                    }

                    const currentTarget = event.currentTarget;
                    let { innerHTML: editedPhrase } = currentTarget;

                    let caretPosition =
                      getCaretPositionAndChildNodeIndex(currentTarget);

                    const content = contentEditableRef.current[sentenceIndex];

                    if (regExpEspaceBeforeSpan.test(editedPhrase)) {
                      handleChangeSentence(editedPhrase, sentenceIndex);
                      setCaretPosition(caretPosition, content);
                    } else {
                      const timeout = setTimeout(() => {
                        handleChangeSentence(editedPhrase, sentenceIndex);
                        setCaretPosition(caretPosition, content);
                      }, 500);
                      setCaretPositionTimeout(timeout);
                    }
                  }}
                />
                {hasPermissionToAction({
                  company: stateFlow.companyName || '',
                  agent: stateFlow.botName || '',
                  action: ['flow:write'],
                }) && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteFrase(sentenceIndex)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </StyledItemFrases>

              {showActionsParameters[sentenceIndex] && (
                <SentenceParameters
                  sentence={userSentence}
                  sentenceIndex={sentenceIndex}
                  removeParameterFromIndex={removeParameterFromSentenceIndex}
                />
              )}
            </StyleSentenceRow>
          );
        })}
      </StyledContainerFrases>

      {!props.disabledVariables && (
        <OutsideClick
          callback={() => {
            if (showModalVariables) {
              setShowModalVariables(false);
            }
          }}
        >
          <ModalSearchVariables
            showModalVariables={showModalVariables}
            setShowModalVariables={setShowModalVariables}
            definedPosition={definedPosition}
            setDefinedPosition={setDefinedPosition}
            onClose={onSelectVariableType}
            remoteVariables={remoteVariables}
          />
        </OutsideClick>
      )}
    </Container>
  );
}
