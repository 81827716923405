import { useMemo } from 'react';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { Actions } from 'contexts/Permissions/types';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';

export interface HasAccessProps {
  children: React.ReactChild;
  action: Actions[];
}

const WithHasAccess = ({ children, action }: HasAccessProps) => {
  const { currentData } = useFintalkCloud();
  const { companyName, agentName } = currentData;
  const { hasPermissionToAction, isCompanyAdmin, isRoot } = usePermissions();
  // const { botName: agent, companyName: company } = useFlow().state;

  const verifyAccess = useMemo(() => {
    if (isRoot()) return true;
    if (companyName && isCompanyAdmin(companyName)) return true;
    if (companyName && agentName)
      return hasPermissionToAction({
        company: companyName,
        agent: agentName,
        action,
      });
  }, [
    action,
    agentName,
    companyName,
    hasPermissionToAction,
    isCompanyAdmin,
    isRoot,
  ]);

  return !!verifyAccess ? <>{children}</> : null;
};

export default WithHasAccess;
