import { Popover } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { SidebarOption } from 'contexts/FintalkCloud/types';
import React, { useState } from 'react';
import { GridListMenuChild } from 'views/Canvas/Builder/MainComponent/styles';
import { useStyles } from './styles';

type PopOverChildControl = {
  anchorEl: HTMLElement | null;
  clickedElementName: string;
};

export default function FintalkCloudSidebar() {
  const {
    currentSidebar,
    currentData,
    setBuilderElementToOpen,
    setCurrentData,
    setCampaignElementToOpen,
    setCrmElementToOpen,
  } = useFintalkCloud();
  const classes = useStyles();
  const theme = useTheme();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [popOverChildControl, setPopOverChildControl] =
    useState<PopOverChildControl>({
      anchorEl: null,
      clickedElementName: '',
    });

  const handleDrawerOpen = () => {
    setSidebarOpen(true);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const handlePopoverClose = (event?: any) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setPopOverChildControl({ anchorEl: null, clickedElementName: '' });
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    item: SidebarOption
  ) => {
    const { name, builderElementToOpen, action, campaignElement, crmElement } =
      item;

    if (action) {
      setCurrentData((prev) => ({ ...prev, [action]: true }));
    }

    if (builderElementToOpen || name === 'Fluxos') {
      setBuilderElementToOpen(builderElementToOpen);
      handlePopoverClose();
      return;
    }

    if (campaignElement) {
      setCampaignElementToOpen(campaignElement);
      return;
    }

    if (crmElement) {
      setCrmElementToOpen(crmElement);
      return;
    }

    if (!popOverChildControl.anchorEl)
      setPopOverChildControl({
        anchorEl: event.currentTarget,
        clickedElementName: name || '',
      });
  };

  const shouldDisableItem = (item: SidebarOption) => {
    if (item.needsAgentName && item.needsEdition)
      return !currentData.agentName || !currentData.isEditing;

    if (item.needsAgentName) return !currentData.agentName;

    return true;
  };

  return currentSidebar.length > 0 ? (
    <div className={classes.root}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: sidebarOpen,
          [classes.drawerClose]: !sidebarOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {!sidebarOpen ? (
            <IconButton
              color="inherit"
              aria-label="sidebarOpen drawer"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon htmlColor="#fff" />
              ) : (
                <ChevronLeftIcon htmlColor="#fff" />
              )}
            </IconButton>
          )}
        </div>
        <Divider />
        <List>
          {currentSidebar.map((item, index) => (
            <ListItem
              button
              key={item.name}
              onClick={(e) => handleListItemClick(e, item)}
              disabled={shouldDisableItem(item)}
              classes={{ root: classes.sidebarListItem }}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />

              {item.children && (
                <Popover
                  id={item.name}
                  open={item.name === popOverChildControl.clickedElementName}
                  anchorEl={popOverChildControl.anchorEl}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  style={{ zIndex: 9999 }}
                >
                  <div>
                    {item.children.map((opt, index: number) => (
                      <GridListMenuChild
                        key={index}
                        onClick={(e) => handleListItemClick(e, opt)}
                      >
                        <ListItem
                          button
                          key={index}
                          disabled={shouldDisableItem(opt)}
                        >
                          <ListItemIcon>{opt.icon}</ListItemIcon>
                          <ListItemText primary={opt.name} />
                        </ListItem>
                        <Divider variant="middle" />
                      </GridListMenuChild>
                    ))}
                  </div>
                </Popover>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  ) : null;
}
