import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const MultipleChoiceIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size || '20px'}
      height={size || '20px'}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill={color || '#424242'}
    >
      <g>
        <g>
          <path
            d="M508.74,215.072l-119.467-93.867c-2.569-2.014-6.042-2.389-9.003-0.964c-2.935,1.425-4.804,4.403-4.804,7.671v51.635
			c-24.695,2.526-49.997,15.983-76.8,40.866v-83.968h51.2c3.268,0,6.246-1.869,7.671-4.804c1.425-2.935,1.05-6.434-0.964-9.003
			L262.707,3.172c-3.234-4.113-10.18-4.113-13.414,0l-93.867,119.467c-2.014,2.569-2.389,6.067-0.964,9.003
			c1.425,2.935,4.403,4.804,7.672,4.804h51.2v83.968c-26.803-24.883-52.104-38.34-76.8-40.866v-51.635
			c0-3.268-1.869-6.246-4.796-7.671c-2.944-1.425-6.434-1.05-9.011,0.964L3.26,215.072C1.203,216.685,0,219.159,0,221.779
			c0,2.62,1.203,5.094,3.26,6.707l119.467,93.867c2.577,2.022,6.067,2.398,9.011,0.964c2.927-1.425,4.796-4.403,4.796-7.672v-50.475
			c41.566,7.1,76.8,64.41,76.8,110.208v128c0,4.71,3.814,8.533,8.533,8.533h68.267c4.719,0,8.533-3.823,8.533-8.533v-128
			c0-45.798,35.234-103.108,76.8-110.208v50.475c0,3.268,1.869,6.246,4.804,7.672c2.961,1.434,6.434,1.058,9.003-0.964
			l119.467-93.867c2.057-1.613,3.26-4.087,3.26-6.707C512,219.159,510.797,216.685,508.74,215.072z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default MultipleChoiceIcon;
