import CloseIcon from '@material-ui/icons/Close';

import { useState } from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import { Edit } from '@material-ui/icons';
import {
  CustomPaper,
  HoverableRow,
} from 'components/ModalManageCompanies/styles';
import { useApp } from 'contexts/App/appContext';
import { useCompanies } from 'contexts/Company';
import { CompanyGroup, emptyGroup } from 'contexts/Company/types';
import { useToast } from 'contexts/ToastContext';
import { GetGroup } from 'services/CompaniesService/Groups';
import useTranslator from 'utils/hooks/Translator';
import { ManageGroup } from './ManageGroup';
import { FloatingIconButton, GrupHeader, TableContainer } from './styles';

export function ProjectGroups() {
  const { toastNotification } = useToast();
  const { dispatch: dispatchApp } = useApp();
  const { state, dispatch } = useCompanies();
  const { company, project } = state;
  const companyName = company.name;
  const { getTranslation } = useTranslator();
  const [editGroup, setEditGroup] = useState(false);
  const groups = project.groups;

  async function handleEditGroup(groupName: string) {
    const result = await GetGroup(
      { groupName, companyName, projectName: project.name },
      dispatchApp
    );
    if (result.Success) {
      dispatch({
        type: 'setGroup',
        data: { group: result.Data as unknown as CompanyGroup },
      });

      setEditGroup(true);
    } else {
      toastNotification({
        type: 'error',
        message: getTranslation('modal.manageCompany.project.group.fetchError'),
      });
    }
  }

  async function handleRemoveGroup(groupName: string) {
    const cloneGroups = groups;
    if (!cloneGroups) return;

    const fixedGroups = cloneGroups.filter((group) => group !== groupName);
    dispatch({
      type: 'setProject',
      data: { project: { ...project, groups: fixedGroups } },
    });
  }
  function handleCloseManageGroup() {
    setEditGroup(false);
    dispatch({ type: 'setGroup', data: { group: emptyGroup } });
  }

  return (
    <CustomPaper addminheight>
      <GrupHeader>
        <h6>
          {getTranslation(
            'modal.manageCompany.project.group.manageProjectGroups'
          )}
        </h6>
        <FloatingIconButton
          disabled={!project._id}
          onClick={() => setEditGroup(true)}
        >
          <AddIcon />
        </FloatingIconButton>
      </GrupHeader>
      {company.projects && company.projects.length > 0 ? (
        <TableContainer>
          <Table size="small" aria-label="Lista de agents">
            <TableHead>
              <TableRow>
                <TableCell>{getTranslation('name')}</TableCell>
                <TableCell align="right">{getTranslation('edit')}</TableCell>
                <TableCell align="right">{getTranslation('delete')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups?.sort().map((group) => (
                <HoverableRow style={{ width: '100%', height: '80px' }} key={group}>
                  <TableCell>{group}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="adiciona um grupo"
                      onClick={() => handleEditGroup(group)}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="edita um grupo"
                      onClick={() => handleRemoveGroup(group)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </TableCell>
                </HoverableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <h6>{getTranslation('modal.manageCompany.project.group.notFound')}</h6>
      )}
      
      {editGroup && (
        <ManageGroup
          editGroup={editGroup}
          handleClose={handleCloseManageGroup}
        />
      )}
    </CustomPaper>
  );
}
