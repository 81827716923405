import React, { ChangeEvent, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { grey } from '@material-ui/core/colors';

import { useFlow } from 'contexts/Flow/flowContext';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import {
  ContainerDialog,
  ContainerHeader,
  FieldContainer,
  SelectFileContainer,
  Button,
  ButtonContainer,
  ModalTitle,
} from './styles';

import { Dialog, Slide, TextField } from '@material-ui/core';
import { Block } from 'contexts/Library/types';
import { GetTemplates, SaveTemplate } from 'services/TemplateService';
import { ITemplate, newTemplateValidation } from 'models/NewTemplate';
import { useApp } from 'contexts/App/appContext';
import useTranslator from 'utils/hooks/Translator';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      background: grey[100],
    },

    input: {
      background: '#FFFFFF',
    },

    buttonSelectFile: {
      background: grey[600],
      color: '#FFFFFF',
      letterSpacing: 2,
      borderRadius: 8,
      '&:hover': {
        background: grey[700],
      },
    },
  })
);

export default function ModalTemplateUpload() {
  const { dispatch: dispatchApp } = useApp();
  const { state, dispatch, toastNotification } = useFlow();
  const classes = useStyles();
  const { getTranslation } = useTranslator();

  const [load, setLoad] = useState(false);
  const [newTemplate, setNewTemplate] = useState<Block>({
    name: '',
    description: '',
    blocks: {},
  });
  const [errors, setErrors] = useState<Partial<ITemplate>>({
    name: '',
    blocks: '',
  });
  const [fileName, setFileName] = useState('');
  const [openEditTemplate, setOpenEditTemplate] = useState(false);

  function handleClose() {
    setOpenEditTemplate(false);
    setNewTemplate({
      name: '',
      description: '',
      blocks: {},
    });
    dispatch({ type: 'closeModalTemplateUpload' });
    dispatch({ type: 'openModalStandardTemplates' });
  }

  const handleSelectFile = () => {
    const documentHtml = document.getElementById('drawflow');
    const uploadInput = document.createElement('input');
    uploadInput.type = 'file';
    uploadInput.accept = 'application/json';
    uploadInput.onchange = async (e: any) => {
      const file: File = e.target.files?.item(0);
      const drawflow = JSON.parse(await file.text());
      if (drawflow.drawflow) {
        if (drawflow.drawflow.Home) {
          if (drawflow.drawflow.Home.data) {
            setNewTemplate((old) => ({
              ...old,
              blocks: drawflow.drawflow.Home.data,
            }));
          }
          uploadInput.parentNode?.removeChild(uploadInput);
          setOpenEditTemplate(true);
          setFileName(file.name);
        } else
          toastNotification(
            'error',
            getTranslation('toast.error.invalidTemplate')
          );
      } else
        toastNotification(
          'error',
          getTranslation('toast.error.invalidTemplate')
        );
    };
    documentHtml?.appendChild(uploadInput);
    uploadInput.click();
    uploadInput.parentNode?.removeChild(uploadInput);
  };

  const handleChangeInput =
    (key: keyof Block) => (e: ChangeEvent<HTMLInputElement>) => {
      setErrors((old) => ({ ...old, [key]: '' }));
      setNewTemplate((old) => ({ ...old, [key]: e.target.value }));
    };

  const handleRemoveFile = () => {
    setNewTemplate((old) => ({ ...old, blocks: {} }));
    setOpenEditTemplate(false);
  };

  const handleCreateTemplate = async () => {
    if (load) return;

    let blockToSaveList: Block[] = [];
    const resultTemp = await GetTemplates(dispatchApp);
    if (resultTemp.Success) {
      blockToSaveList = resultTemp.Data.data;
    }

    const { isValid, errors } = await newTemplateValidation(
      newTemplate,
      blockToSaveList
    );
    setErrors(errors);
    if (!isValid) return;
    setLoad(true);

    const result = await SaveTemplate(newTemplate, dispatchApp);

    if (result.Success) {
      toastNotification('success', getTranslation('toast.success.templateUpdate'));
      handleClose();
    } else {
      toastNotification(
        'error',
        result.Message || getTranslation('toast.error.templateUpdate')
      );
    }

    setLoad(false);
  };

  return (
    <Dialog
      open={state.openModalTemplateUpload}
      TransitionComponent={Transition}
      maxWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog}>
        <ContainerHeader>
          <ModalTitle>
            {openEditTemplate ? (
              <strong>{getTranslation('modal.templates.edit')}</strong>
            ) : (
              <strong>{getTranslation('modal.templates.upload')}</strong>
            )}
          </ModalTitle>
          <IconButton
            edge="start"
            color="default"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </ContainerHeader>
        <FieldContainer>
          <TextField
            placeholder={getTranslation('modal.templates.name')}
            onChange={handleChangeInput('name')}
            value={newTemplate.name}
            className={classes.input}
            fullWidth
            error={!!errors.name}
            helperText={errors.name || ''}
          />
        </FieldContainer>
        <FieldContainer>
          <TextField
            placeholder={getTranslation('description')}
            onChange={handleChangeInput('description')}
            value={newTemplate.description}
            className={classes.input}
            multiline
            minRows={3}
            maxRows={3}
            fullWidth
          />
        </FieldContainer>
        <SelectFileContainer>
          {openEditTemplate ? (
            <>
              <strong>{fileName}</strong>
              <IconButton
                edge="start"
                onClick={handleRemoveFile}
                aria-label="close"
                style={{ color: 'red' }}
              >
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <Button
              className={classes.buttonSelectFile}
              onClick={handleSelectFile}
              variant="contained"
              disableElevation
            >
              {getTranslation('modal.templates.selectFile')}
            </Button>
          )}
        </SelectFileContainer>
        <ButtonContainer>
          <Button
            style={{ letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            onClick={handleCreateTemplate}
          >
            {getTranslation('save')}
            {load && <CircularProgress color="inherit" size={18} />}
          </Button>
        </ButtonContainer>
      </ContainerDialog>
    </Dialog>
  );
}
