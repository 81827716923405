import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const LogicIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size || '20px'}
      height={size || '20px'}
      viewBox="0 0 20 20"
      xmlSpace="preserve"
      fill={color || '#424242'}
    >
      <g>
        <g id="Layer_1_89_">
          <path
            d="M16,14.184v-1.851c0-1.93-1.57-3.5-3.5-3.5c-0.827,0-1.5-0.673-1.5-1.5V5.816
			C12.161,5.401,13,4.302,13,3c0-1.654-1.346-3-3-3S7,1.346,7,3c0,1.302,0.839,2.401,2,2.815v1.518c0,0.827-0.673,1.5-1.5,1.5
			c-1.93,0-3.5,1.57-3.5,3.5v1.851C2.839,14.599,2,15.698,2,17c0,1.654,1.346,3,3,3s3-1.346,3-3c0-1.302-0.839-2.401-2-2.816v-1.851
			c0-0.827,0.673-1.5,1.5-1.5c0.979,0,1.864-0.407,2.5-1.058c0.636,0.651,1.521,1.058,2.5,1.058c0.827,0,1.5,0.673,1.5,1.5v1.851
			c-1.161,0.415-2,1.514-2,2.816c0,1.654,1.346,3,3,3s3-1.346,3-3C18,15.698,17.161,14.599,16,14.184z M5,18c-0.552,0-1-0.449-1-1
			s0.448-1,1-1s1,0.449,1,1S5.552,18,5,18z M10,2c0.552,0,1,0.449,1,1s-0.448,1-1,1S9,3.551,9,3S9.448,2,10,2z M15,18
			c-0.552,0-1-0.449-1-1s0.448-1,1-1s1,0.449,1,1S15.552,18,15,18z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default LogicIcon;
