import { FunctionComponent } from 'react';
import { useToast } from 'contexts/ToastContext';

import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const Toast: FunctionComponent = () => {
  const { hideToast, visible, data } = useToast();
  const { message, type, timeout } = data;

  function handleCloseAlert(event?: React.SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    hideToast();
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={visible}
      autoHideDuration={timeout}
      onClose={handleCloseAlert}
    >
      <Alert onClose={handleCloseAlert} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
