import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close } from '@material-ui/icons';
import { useToast } from 'contexts/ToastContext';
import React, { useCallback, useEffect, useState } from 'react';
import useTranslator from 'utils/hooks/Translator';
import * as S from './style';
import { ModalActionConfirmProps } from './types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
});

export function ModalActionConfirm({ 
  setIsOpen,
  title = '', 
  subtitle = '',
  confirmActionInput = true,
  confirmActionText = '',
  confirmActionRequest,
}: ModalActionConfirmProps) {
  const classes = S.useStyles();
  const { getTranslation } = useTranslator();
  const { toastNotification: toast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmActionInputData, setConfirmActionInputData] = useState<string>('');
  const [isButtonSubmitDisabled, setIsButtonSubmitDisabled] = useState<boolean>(true);

  useEffect(() => {
    setIsButtonSubmitDisabled(confirmActionInputData !== confirmActionText);
  }, [confirmActionInputData, confirmActionText]);

  const validateActionConfirm = (): boolean => {
    if (confirmActionInputData !== confirmActionText) {
      toast({
        type: 'warning',
        message: getTranslation('validations.invalidValue')
      });
      return false;
    }
    return true;
  };

  const handleChangeActionInput = (value: string) => {
    setConfirmActionInputData(value);
  };

  const handleConfirmActionSubmit = useCallback(async () => {
    if (confirmActionRequest && validateActionConfirm()) {
      try {
        setIsLoading(true);
        await confirmActionRequest();
      } catch (error) {
        toast({
          type: 'error',
          message: getTranslation('error'),
        });
      } finally {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmActionRequest, validateActionConfirm]);

  return (
    <Dialog
      open
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth={true}
      TransitionComponent={Transition}
      PaperProps={{ classes: { root: classes.dialog } }}
    >
      <DialogTitle 
        disableTypography
        style={{
          display: 'flex'
        }}
      >
        <Typography variant='h5' className={classes.title}>
          {title}
        </Typography>

        <IconButton 
          onClick={() => setIsOpen(false)}
          size='small'
          style={{ 
            height: 'fit-content', 
            padding: 8,
            marginLeft: 'auto',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <Divider />

      {isLoading ? (
        <Box
          component='div'
          style={{
            width: '100%',
            height: 200,
            padding: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" size={56} />
        </Box>
      ) : (
        <> 
          <DialogContent>
            <DialogContentText className={classes.subtitle}>
              {subtitle}
            </DialogContentText>
          </DialogContent>
      
          <DialogActions className={classes.dialogActions}>
            {confirmActionInput && (
              <FormControl>
                <S.ConfirmActionInput 
                  onChange={(event) => handleChangeActionInput(event.target.value)}
                />
              </FormControl>
            )}
    
            <Button
              variant='contained'
              size='large'
              className={classes.actionButton}
              onClick={() => handleConfirmActionSubmit()}
              disabled={isLoading || isButtonSubmitDisabled}
            >
              {getTranslation('delete')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
