import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const TextContainer = styled(MttGrid)`
  width: 100%;
  height: 54px;
  padding: 4px;

  max-width: 300px;

  .MuiFormControl-root {
    width: 100%;
  }
`;

export const ContainerOptionHeader = styled.div`
  display: flex;
  align-items: center;

  padding: 8px 4px;
`;

export const OptionIndexAndTip = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Container = styled(MttGrid)`
  width: calc(100% - 32px);
  min-height: 48px;

  padding: 8px;
  border-radius: 4px;

  border: 1px solid #0000001f;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;

  margin-top: 8px;
`;
