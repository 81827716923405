import React, { useCallback, useEffect, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';

import {
  AppBar,
  Box,
  CircularProgress,
  Collapse,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { subHours } from 'date-fns';
import { ContainerDialog, FieldContainer, useStyles } from './styles';

import { useFlow } from 'contexts/Flow/flowContext';
import { GetPublishBackup } from 'services/BotService';
import { useApp } from 'contexts/App/appContext';
import { BotToStringifyResponse } from 'services/BotService/types';
import { formatDateHour } from 'utils/Date';
import { Key } from 'services/AccessKeysService/types';
import { IEntity } from 'models/Entity';
import { IExportGroup } from 'utils/CreateBotObject';
import {
  updatePrincipalGroup,
  removeNonExistingGroups,
  createAndUpdateGroups,
  updateEntities,
  updateKeys,
} from 'utils/ImportBot/ImportBotService';
import ModalConfirmRestore from './ModalConfirmRestore';
import getTranslator from 'utils/hooks/Translator';
import { ModalToPopProps } from '../../BuilderModals/types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalRestoreBot(props: ModalToPopProps) {
  const classes = useStyles();
  const { state, dispatch, toastNotification, loadFlow } = useFlow();
  const { groupsNames, botName } = state;
  const { dispatch: dispatchApp } = useApp();

  const { handleClose } = props;
  const [openModalConfirmRestore, setOpenModalConfirmRestore] = useState(false);
  const [currentBot, setCurrentBot] = useState<BotToStringifyResponse>({
    bot: { entities: [], accessKeys: [], groups: [] },
    bot_name: '',
    saved_at: '',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [publishHistory, setPublishHistory] = useState<
    BotToStringifyResponse[]
  >([]);
  const [publishHistoryTotal, setPublishHistoryTotal] = useState(0);
  const [selectedCollapseBot, setSelectedCollapseBot] = useState(-1);
  const [selectedCollapseGroup, setSelectedCollapseGroup] = useState(-1);
  const [selectedCollapseEntity, setSelectedCollapseEntity] = useState(-1);
  const [selectedCollapseAccessKey, setSelectedCollapseAccessKey] =
    useState(-1);

  const [loading, setLoading] = useState(false);
  const { getTranslation } = getTranslator();

  const load = useCallback(async () => {
    if (botName) {
      setLoading(true);

      const backupResponse = await GetPublishBackup(
        botName,
        dispatchApp,
        String(page * rowsPerPage),
        String(rowsPerPage)
      );
      if (backupResponse.Success) {
        setPublishHistory(backupResponse.Data.data);
        setPublishHistoryTotal(backupResponse.Data.pagination.total);
      }

      setLoading(false);
    }
  }, [botName, dispatchApp, page, rowsPerPage]);

  useEffect(() => {
    load();
  }, [botName, dispatchApp, load]);

  const handleChangeRowsPerPage = (e: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleImportBot = async (bot: BotToStringifyResponse) => {
    const groups: IExportGroup[] = bot.bot.groups;
    const entities: IEntity[] = bot.bot.entities;
    const importKeys: Key[] = bot.bot.accessKeys;

    let requestErrors = [];

    dispatch({ type: 'updateForm', data: { loading: true } });

    const resultUpdatePrincipal = await updatePrincipalGroup(
      botName || '',
      groups,
      dispatchApp
    );

    const resultRemoveGroups = await removeNonExistingGroups(
      botName || '',
      groups,
      groupsNames,
      dispatchApp
    );

    const resultCreateAndUpdateGroups = await createAndUpdateGroups(
      botName || '',
      groups,
      groupsNames,
      dispatchApp
    );

    const resultUpdateEntities = await updateEntities(
      botName || '',
      entities,
      dispatchApp
    );

    const resultUpdateKeys = await updateKeys(
      botName || '',
      importKeys,
      dispatchApp
    );

    requestErrors.push(
      resultUpdatePrincipal[0],
      resultRemoveGroups[0],
      resultCreateAndUpdateGroups[0],
      resultUpdateEntities[0],
      resultUpdateKeys[0]
    );

    const updatedGroupsNames = groups.map((group) => group.groupName);

    dispatch({
      type: 'updateForm',
      data: { groupsNames: updatedGroupsNames },
    });

    dispatch({ type: 'updateForm', data: { loading: false } });

    requestErrors = requestErrors
      .filter((error) => (error !== undefined && error !== ''))

    if (requestErrors.length > 0) {
      toastNotification('error', getTranslation('toast.error.agentRestore'));
    } else {
      toastNotification(
        'success',
        getTranslation('toast.success.agentRestore')
      );

      setOpenModalConfirmRestore(false);

      await loadFlow(state.groupsNames[0], botName || '');
      dispatch({ type: 'setIdGroup', data: { idGroup: state.groupsNames[0] } });
    }
  };

  const convertDate = (date: string) => {
    const toDate = new Date(date);
    const convertedDate = subHours(toDate, 3);
    return formatDateHour(convertedDate);
  };

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      onClose={handleClose}
    >
      <ContainerDialog>
        <AppBar>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">Versões anteriores: {botName}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {loading ? (
          <Grid
            style={{
              padding: 24,
            }}
          >
            <CircularProgress color="inherit" size={18} />
          </Grid>
        ) : (
          <FieldContainer>
            <Table style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Bot</TableCell>
                  <TableCell align="right">Data de Publicação</TableCell>
                  <TableCell align="center">Restaurar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {publishHistory?.map((bot, index) => (
                  <React.Fragment key={index}>
                    <TableRow hover>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            selectedCollapseBot !== index
                              ? setSelectedCollapseBot(index)
                              : setSelectedCollapseBot(-1);
                          }}
                        >
                          {index === selectedCollapseBot ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="key">
                        {bot.bot_name}
                      </TableCell>
                      <TableCell component="th" scope="key" align="right">
                        {convertDate(bot.saved_at)}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Restaurar para versão">
                          <IconButton
                            edge="end"
                            color="default"
                            onClick={() => {
                              setCurrentBot(bot);
                              setOpenModalConfirmRestore(true);
                            }}
                          >
                            <RestoreIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={index === selectedCollapseBot}
                          key={index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow hover>
                                  <TableCell
                                    onClick={() => {
                                      selectedCollapseGroup !== index
                                        ? setSelectedCollapseGroup(index)
                                        : setSelectedCollapseGroup(-1);
                                    }}
                                  >
                                    Grupos
                                    <Collapse
                                      in={index === selectedCollapseGroup}
                                    >
                                      <TableRow key={0}>
                                        <TableCell component="th" scope="row">
                                          {bot.bot.groups[0].groupName}
                                        </TableCell>
                                      </TableRow>
                                      {bot.bot.groups
                                        .filter(
                                          (g) => g.groupName !== 'principal'
                                        )
                                        .map((g) => g.groupName)
                                        .sort()
                                        .map((group, groupIndex) => (
                                          <TableRow key={groupIndex}>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {group}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                                <TableRow hover>
                                  <TableCell
                                    onClick={() => {
                                      selectedCollapseEntity !== index
                                        ? setSelectedCollapseEntity(index)
                                        : setSelectedCollapseEntity(-1);
                                    }}
                                  >
                                    Variáveis
                                    <Collapse
                                      in={index === selectedCollapseEntity}
                                    >
                                      {bot.bot.entities.map(
                                        (entity, entityIndex) => (
                                          <TableRow key={entityIndex}>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              width="100%"
                                            >
                                              {entity.name}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                                <TableRow hover>
                                  <TableCell
                                    onClick={() => {
                                      selectedCollapseAccessKey !== index
                                        ? setSelectedCollapseAccessKey(index)
                                        : setSelectedCollapseAccessKey(-1);
                                    }}
                                  >
                                    Chaves de Acesso
                                    <Collapse
                                      in={index === selectedCollapseAccessKey}
                                    >
                                      {Object.entries(bot.bot.accessKeys).map(
                                        (accessKey, accessKeyIndex) => (
                                          <TableRow key={accessKeyIndex}>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {accessKey}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    count={publishHistoryTotal}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={'Linhas por página'}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </FieldContainer>
        )}
      </ContainerDialog>
      <ModalConfirmRestore
        open={openModalConfirmRestore}
        handleRestore={() => handleImportBot(currentBot)}
        handleClose={() => setOpenModalConfirmRestore(false)}
      />
    </Dialog>
  );
}
