import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IGetTemplateResponse,
  IRemoveTemplateRequest,
  IRemoveTemplateResponse,
  ISaveTemplateRequest,
  ISaveTemplateResponse,
  IUpdateTemplateRequest,
  IUpdateTemplateResponse,
} from './types';

export const GetTemplates = async (
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetTemplateResponse>> => {
  const action = `templates`;
  return json<IGetTemplateResponse>('GET', action, dispatch);
};

export const SaveTemplate = async (
  request: ISaveTemplateRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ISaveTemplateResponse>> => {
  const action = `templates`;
  return json<ISaveTemplateResponse>('POST', action, dispatch, request);
};

export const UpdateTemplate = async (
  request: IUpdateTemplateRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IUpdateTemplateResponse>> => {
  const action = `templates/${request._id}`;
  return json<IUpdateTemplateResponse>('PATCH', action, dispatch, request);
};

export const RemoveTemplate = async (
  request: IRemoveTemplateRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IRemoveTemplateResponse>> => {
  const action = `templates/${request.template_id}`;
  return json<IRemoveTemplateResponse>('DELETE', action, dispatch);
};
