import { IFlowContextState } from '../contexts/Flow/types';

export const isEmptyFlow = (state: IFlowContextState) => {
  const { Home } = state?.editorFlow?.drawflow.drawflow ?? {
    Home: { data: {} },
  };
  return (
    Home.data &&
    Object.keys(Home.data).length === 0 &&
    Object.getPrototypeOf(Home.data) === Object.prototype
  );
};

export const isValidPrincipalGroup = (data: any[]) => {
  const principalGroup = data.filter((item: any) => {
    return (
      item.data.intentType === 8 ||
      item.data.intentType === 9 ||
      item.data.intentType === 10
    );
  });

  return principalGroup.length === 3;
};
