import styled from 'styled-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import MttGrid from '../Material/MttGrid/MttGrid';
import MttButton from '../Material/MttButton/MttButton';

import { Grid, Typography } from '@material-ui/core';

export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  overflow: hidden;
  min-height: 400px;

  .MuiAppBar-colorPrimary {
    background-color: #0e103b;
    position: absolute;
  }
`;

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #fff;
`;

export const FieldContainer = styled(Grid)`
  width: 100%;
  margin-top: 10px;
  display: flex;

  align-items: center;

  .MuiInputBase-input {
    outline: 0;
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }

  .MuiIconButton-root {
    padding: 8px;
  }

  .tableHeadCell-Button {
    width: 40px;
    text-align: right;
  }
`;

export const ButtonContainer = styled(Grid)`
  padding: 16px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(MttButton)`
  height: 50px;
`;

export const LineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const NewKeyContainer = styled.div`
  margin-top: 40px;
  width: 100%;

  .MuiInputBase-input {
    outline: 0;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }
`;

export const TextContainer = styled.div`
  height: 64px;

  margin-right: 10px;
  width: 100%;
`;

export const AutoCompleteWrap = styled.div`
  width: 100%;
  padding: 8px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .MuiFormControl-root {
    background: #fff;
  }

  input {
    height: auto;
  }

  button {
    width: auto;
    height: auto;
  }
`;

export const AccordionContainer = styled.div`
  max-height: 55vh;
  overflow-y: auto;
  padding: 16px;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      background: '#F2F2F2',
    },
    dialog80vw: {
      width: '80vw',
      background: '#F2F2F2',
    },
    input: {
      background: '#FFFFFF',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);
