import AceEditor from 'react-ace';
import { js_beautify } from 'js-beautify';
import { IconButton, Typography, withStyles } from '@material-ui/core';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { FormatCodeButton, styles } from '../styles';
import { DialogTitleProps } from 'components/TextEditor';
import useTranslator from 'utils/hooks/Translator';
import { getTranslationKeys } from 'utils/i18n/types';

const DialogFooter = withStyles(styles)((props: DialogTitleProps) => {
  const { classes, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.footer}
      {...other}
    ></MuiDialogTitle>
  );
});

export function JsEditor({
  title,
  code,
  handleChange,
  setJsToEdit,
  setShowJsEditor,
  readOnly,
}: {
  title: string;
  code: string;
  handleChange: any;
  setJsToEdit: React.Dispatch<React.SetStateAction<string>>;
  setShowJsEditor: React.Dispatch<React.SetStateAction<boolean>>;
  readOnly?: boolean;
}) {
  const { getTranslation } = useTranslator();

  return (
    <div style={{ marginTop: 16 }}>
      <Typography variant="subtitle2">
        {getTranslation(title as getTranslationKeys)}
      </Typography>

      <AceEditor
        mode="javascript"
        theme="dracula"
        name="editor"
        width={'100%'}
        height="200px"
        value={code}
        readOnly={readOnly}
        onChange={(e) => handleChange(e)}
      />
      
      <DialogFooter id="customized-dialog-title">
        <IconButton
          onClick={() => {
            setJsToEdit(code || '');
            setShowJsEditor(true);
          }}
        >
          <SettingsOverscanIcon htmlColor="#fff" />
        </IconButton>
        <FormatCodeButton
          onClick={() => {
            handleChange(
              js_beautify(code || '', {
                indent_size: 2,
              })
            );
          }}
        >
          {getTranslation('formatCode')}
        </FormatCodeButton>
      </DialogFooter>
    </div>
  );
}
