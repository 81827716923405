import React, { useCallback, useEffect } from 'react';
import { useForm } from 'contexts/Form/formContext';
import { Variable } from 'models/DataFlow';
import MttButton from 'components/Material/MttButton/MttButton';
import { StyledMttTypographyIdentificacao } from '../UserQuestions/styles';
import AddIcon from '@material-ui/icons/Add';
import * as S from './styles';
import { MultiVarsProps } from './types';
import UserQuestions from '../UserQuestions';

const { VariablesLine, MultiVariablesContainer } = S.styles;

export default function MultiVarsQuestions(props: MultiVarsProps) {
  const { showQuestions, showDelete, indexsRequired } = props;
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const variables = dataForm?.inputs?.variables || [];

  const load = useCallback(() => {
    if (dataForm && !dataForm?.inputs) {
      dataForm.inputs = {
        variables: [
          {
            name: '',
            questions: ['', '', ''],
            required: true,
            vartype: '',
            defaultValue: '',
            isList: false,
            value: '',
          },
        ],
      };
      dispatch({ type: 'updateForm', data: { dataForm } });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const renderVars = (variable: Variable, index: number) => {
    const canRemove = !indexsRequired?.includes(index);
    return (
      <VariablesLine key={`variable-${index}`}>
        <UserQuestions
          index={index}
          showDelete={showDelete && canRemove}
          showQuestions={showQuestions}
        />
      </VariablesLine>
    );
  };

  const handleAddVariable = () => {
    variables.push({
      name: '',
      vartype: '',
      value: '',
      required: true,
      questions: ['', '', ''],
      defaultValue: '',
      isList: false,
    });

    dispatch({ type: 'updateForm', data: { dataForm } });
  };

  return (
    <>
      <S.StyledTitleGrid>
        <StyledMttTypographyIdentificacao style={{ fontWeight: 600 }} />
        <MttButton
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddVariable}
        >
          adicionar variáveis
        </MttButton>
      </S.StyledTitleGrid>
      <MultiVariablesContainer>
        {variables.map(renderVars)}
      </MultiVariablesContainer>
    </>
  );
}
