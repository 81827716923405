import React, { useEffect } from 'react';

import { Dialog, Typography } from '@material-ui/core';
import { useApp } from 'contexts/App/appContext';

import * as S from './styles';
import useTranslator from 'utils/hooks/Translator';

export default function ModalUsedTab() {
  const { state, dispatch } = useApp();
  const { renderModalUsedTab } = state;
  const { getTranslation } = useTranslator();

  useEffect(() => {
    if (renderModalUsedTab) {
      dispatch({
        type: 'openModalUsedTab',
        data: { renderModalUsedTab: true },
      });
    }
  }, [dispatch, renderModalUsedTab]);

  return (
    <Dialog open={true}>
      <S.Container>
        <S.ModalTitle>{getTranslation('modal.usedTab.title')}</S.ModalTitle>
        <Typography>{getTranslation('modal.usedTab.subtitle')}</Typography>
      </S.Container>
    </Dialog>
  );
}
