import { Checkbox, FormControlLabel } from '@material-ui/core';

type Props = {
  color: 'default' | 'primary' | 'secondary';
  label: string;
  name: string;
  value?: boolean;
  setValue: (value: boolean, name?: string) => void;
};

export function InputCheckboxWithLabel({
  color,
  label,
  name,
  value,
  setValue,
}: Props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={(event) => setValue(event.target.checked, name)}
          name={name}
          color={color}
        />
      }
      label={label}
    />
  );
}

InputCheckboxWithLabel.defaultProps = {
  color: 'default',
  name: 'input-checkbox-with-label',
};
