import React, { useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { ModalRestorerProps } from './types';

import { Dialog, Slide } from '@material-ui/core';

import {
  ContainerDialog,
  ContainerHeader,
  Button,
  ButtonContainer,
  ModalTitle,
  FieldContainer,
  useStyles
} from './styles';
import { CircularProgress } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalConfirmRestore(props: ModalRestorerProps) {
  const classes = useStyles();
  const { handleClose, open, handleRestore } = props;
  const [load /*setLoad*/] = useState(false);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      onClose={handleClose}
    >
      <ContainerDialog className={classes.dialog}>
        <ContainerHeader>
          <ModalTitle style={{ fontWeight: 600, fontSize: '1.5rem' }}>
            Atenção!
          </ModalTitle>
        </ContainerHeader>
        <FieldContainer>
          <p>
            Ao continuar, o estado atual do bot será substituido pela versão anterior escolhida, deseja continuar?
          </p>
        </FieldContainer>

        <ButtonContainer>
          <Button
            style={{ letterSpacing: 2, borderRadius: 8, backgroundColor: '#DC1414', color: '#fff' }}
            variant="contained"
            onClick={handleClose}
          >
            Fechar
            {load && <CircularProgress color="inherit" size={18} />}
          </Button>

          <Button
            style={{ letterSpacing: 2, borderRadius: 8, backgroundColor: '#34AC26', color: '#fff' }}
            variant="contained"
            onClick={handleRestore}
          >
            Restaurar
            {load && <CircularProgress color="inherit" size={18} />}
          </Button>
        </ButtonContainer>
      </ContainerDialog>
    </Dialog>
  );
}
