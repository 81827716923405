import React from 'react';
import ABTestContent from 'components/inputs/Material/ABTestContent';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Info from 'components/inputs/Material/Info/index';
import { FormFieldsContainer } from '../Shared/styles';

export default function ABTest() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <ABTestContent />
      </FormFieldsContainer>
      <FooterButtons showLogicButton={false}/>
    </>
  );
}
