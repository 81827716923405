import { addMinutes, isPast } from 'date-fns';

const config = {
  NAMESPACE: 'llego',
};

const expire = 'expire_';
const namespace = config.NAMESPACE ? `${config.NAMESPACE}_` : '';
const keyName = (key: string, prefix = '') => `${prefix}${namespace}${key}`;

const getExpirationDate = (key: string) => {
  const exp = localStorage.getItem(keyName(key, expire));
  return new Date(exp || Number.MIN_VALUE);
};

const remove = (key: string) => {
  localStorage.removeItem(keyName(key, expire));
  localStorage.removeItem(keyName(key));
};

function set(key: string, data: object, expireMinutes = 1430) {
  const expireDate = addMinutes(new Date(), expireMinutes);
  localStorage.setItem(keyName(key, expire), expireDate.toISOString());
  localStorage.setItem(keyName(key), JSON.stringify(data));
}

function get(key: string) {
  const expiration = getExpirationDate(key);
  const value = localStorage.getItem(keyName(key));

  if (!value || isPast(expiration)) {
    remove(key);
    return undefined;
  }

  return JSON.parse(value);
}

export const localStore = { set, get, remove };
