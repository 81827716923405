import styled from 'styled-components';
import MttGrid from '../Material/MttGrid/MttGrid';

export const ContainerDialog = styled(MttGrid)<{ colorheader?: string }>`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  .MuiAppBar-colorPrimary {
    background-color: ${(props) =>
      props.colorheader ? `#${props.colorheader}` : '#0e103b'};
    position: absolute;
  }
`;
