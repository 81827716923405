import { createStyles, InputBase, InputLabel, makeStyles, Theme, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const AddContentGroupInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    color: #222;
    font-weight: bold;
    font-size: 20px;
  }
`;

export const ConfirmActionInput = withStyles((theme: Theme) => (
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 12,
      border: '2px solid #222',
      padding: '10px 26px 10px 12px',
      boxShadow: '0 2px 4px 1px rgba(0,0,0, 0.2)',

      '&:focus': {
        borderRadius: 12,
        boxShadow: '0 2px 4px 1px rgba(0,0,0, 0.6)',
      },
    },
  })
))(InputBase);

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 12,
    padding: '8px 16px',
  },
  title: {
    color: '#FF0000',
    fontWeight: 700,
    lineHeight: 1.25,
    letterSpacing: 0.1
  },
  subtitle: {
    marginTop: 8,
    lineHeight: 1.25,
    letterSpacing: 0.25,
    color: '#222',
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 24px',
  },
  actionButton: {
    fontWeight: 'bold',
    backgroundColor: '#FF0000',
    color: '#FFF',
    borderRadius: 12,

    '.Mui-disabled': {
      border: 0,
    }
  },
}));
