import { CompanyContextState, CompanyContextActions } from './types';

export function companyReducer(
  state: CompanyContextState,
  action: CompanyContextActions
): CompanyContextState {
  switch (action.type) {
    case 'setCompany':
      return functionsReducer[action.type](state, action?.data);
    case 'setCompanyCopy':
      return functionsReducer[action.type](state, action?.data);
    case 'setGroup':
      return functionsReducer[action.type](state, action?.data);
    case 'updateActions':
      return functionsReducer[action.type](state, action?.data);
    case 'setProject':
      return functionsReducer[action.type](state, action?.data);
    case 'setProjectCopy':
      return functionsReducer[action.type](state, action?.data);

    default:
      return state;
  }
}

const functionsReducer = {
  setCompany: (
    state: CompanyContextState,
    data?: Partial<CompanyContextState>
  ) => {
    return { ...state, company: { ...state.company, ...data?.company } };
  },
  setCompanyCopy: (
    state: CompanyContextState,
    data?: Partial<CompanyContextState>
  ) => {
    return {
      ...state,
      companyCopy: { ...state.companyCopy, ...data?.companyCopy },
    };
  },

  setGroup: (
    state: CompanyContextState,
    data?: Partial<CompanyContextState>
  ) => {
    return { ...state, group: { ...state.group, ...data?.group } };
  },

  setProject: (
    state: CompanyContextState,
    data?: Partial<CompanyContextState>
  ) => {
    return { ...state, project: { ...state.project, ...data?.project } };
  },
  setProjectCopy: (
    state: CompanyContextState,
    data?: Partial<CompanyContextState>
  ) => {
    return { ...state, projectCopy: { ...state.projectCopy, ...data?.projectCopy } };
  },

  updateActions: (
    state: CompanyContextState,
    data?: Partial<CompanyContextState>
  ) => {
    const actions = data?.actions || [];
    return { ...state, group: { ...state.group, actions } };
  },
};
