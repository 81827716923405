import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const ContainerDialog = styled(MttGrid)`
  width: 100%;
  height: 100%;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const ContainerBody = styled.div`
  padding: 0 16px;
  
  p{
    margin-bottom: 12px;
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
`;