import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const SchemaIcon: React.FC<ISVGProps> = (props) => {
  const { size } = props;

  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5C5 3.89543 5.89543 3 7 3C8.10457 3 9 3.89543 9 5C9 6.10457 8.10457 7 7 7C6.46957 7 5.96086 6.78929 5.58579 6.41421C5.21071 6.03914 5 5.53043 5 5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5C15 3.89543 15.8954 3 17 3C18.1046 3 19 3.89543 19 5C19 6.10457 18.1046 7 17 7C15.8954 7 15 6.10457 15 5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C11.4696 21 10.9609 20.7893 10.5858 20.4142C10.2107 20.0391 10 19.5304 10 19Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50002 7C7.50002 6.72386 7.27616 6.5 7.00002 6.5C6.72388 6.5 6.50002 6.72386 6.50002 7H7.50002ZM7.00002 10.03L7.50002 10.0321V10.03H7.00002ZM7.45302 11.479L7.86555 11.1964L7.86281 11.1925L7.45302 11.479ZM9.28602 12.454L9.28229 12.954H9.28602V12.454ZM14.714 12.454V12.954C14.719 12.954 14.7241 12.9539 14.7291 12.9538L14.714 12.454ZM17 10.03H16.5C16.5 10.0347 16.5001 10.0395 16.5002 10.0442L17 10.03ZM17.5 7C17.5 6.72386 17.2762 6.5 17 6.5C16.7239 6.5 16.5 6.72386 16.5 7H17.5ZM11.5 17C11.5 17.2761 11.7239 17.5 12 17.5C12.2762 17.5 12.5 17.2761 12.5 17H11.5ZM12.5 12.455C12.5 12.1789 12.2762 11.955 12 11.955C11.7239 11.955 11.5 12.1789 11.5 12.455H12.5ZM6.50002 7V10.03H7.50002V7H6.50002ZM6.50003 10.0279C6.49748 10.6493 6.68722 11.2562 7.04323 11.7655L7.86281 11.1925C7.62504 10.8524 7.49831 10.447 7.50002 10.0321L6.50003 10.0279ZM7.04051 11.7615C7.54762 12.5019 8.38492 12.9473 9.28229 12.954L9.28975 11.954C8.71965 11.9498 8.1877 11.6668 7.86553 11.1965L7.04051 11.7615ZM9.28602 12.954H14.714V11.954H9.28602V12.954ZM14.7291 12.9538C16.3049 12.9063 17.5447 11.5917 17.4998 10.0158L16.5002 10.0442C16.5294 11.0687 15.7234 11.9233 14.699 11.9542L14.7291 12.9538ZM17.5 10.03V7H16.5V10.03H17.5ZM12.5 17V12.455H11.5V17H12.5Z"
      />
    </svg>
  );
};

export default SchemaIcon;
