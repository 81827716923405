import { createStyles, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import MttButton from 'components/Material/MttButton/MttButton';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttTypography from '../Material/MttTypography/MttTypography';
import Typography from '../Typography';
const drawerWidth = 300;

export const ModalButton = styled(MttButton)`
  width: 325px;
  height: 51px;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
`;

export const StyledButton = styled.div`
  .MuiButton-label {
    display: block;
    padding: 8px;
    line-height: normal;
  }

  .MuiButton-startIcon {
    margin: 0;
    margin-bottom: 10px;
  }
`;

export const StyledButtonSend = styled.div`
  .MuiButton-label {
    display: block;
    padding: 8px;
    line-height: normal;
  }

  .MuiButton-startIcon {
    margin: 0;
    transform: rotateY(180deg);
  }
`;

export const DialogContent = styled(MttGrid)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 8px;

  .defaultButtonStyle {
    width: 110px;
    height: 99px;
    border-radius: 8px;
    padding: 0;
    margin: 4px;
    text-transform: none;
    font-weight: bold;
    font-size: 16px;
  }
`;

export const ContainerDivider = styled.div`
  margin: 4px;
`;

export const TitleTypography = styled(MttTypography).attrs({
  children: 'Que tipo de bloco deseja criar?',
  variant: 'h6',
  color: 'primary',
})``;

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  margin-left: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #0e103b;
`;

export const CloseButtonGrid = styled(MttGrid)`
  position: absolute;
  top: 21px;
  right: 21px;

  justify-content: space-around;
  color: #707070;
  display: flex;
  cursor: pointer;
`;

export const GridFluxoInicial = styled(MttGrid).attrs({
  className: 'greenButtonMaterial',
})``;

export const GridButtonAzulClaro = styled(MttGrid).attrs({
  className: 'lightBlueButtonMaterial',
})``;

export const GridButtonAzulEscuro = styled(MttGrid).attrs({
  className: 'darkBlueButtonMaterial',
})``;

export const GridButtonLaranja = styled(MttGrid).attrs({
  className: 'orangeButtonMaterial',
})``;

export const StyledMttButton = styled(MttButton).attrs({
  className: 'defaultButtonStyle',
})``;

export const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      color: '#424242 !important',
      '& svg': {
        marginRight: '20px',
      },
    },

    listSvg: {
      marginRight: '20px',
    },

    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      position: 'absolute',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginTop: '165px',
      marginLeft: '54px',
      zIndex: 'auto',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    accordionSummary: {
      minHeight: '24px !important',
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
      },
    },
    accordion: {
      boxShadow: 'none',
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
      },
    },
    accordionContent: {
      padding: '0 0 8px 0',
      maxHeight: '276px',
      overflowY: 'scroll',
    },
  })
);
