/* eslint-disable */
import * as Blockly from 'blockly/core';
import { getArrayVarsBlockly, getArrayVariablesBlockly } from './logic';
class CustomCategory extends Blockly.ToolboxCategory {
    /**
     * Constructor for a custom category.
     * @override
     */
    constructor(categoryDef, toolbox, opt_parent) {
        super(categoryDef, toolbox, opt_parent);
    }
}

/* @override 
fucntion addColourBorder_(colour) {
    this.rowDiv_.style.backgroundColor = colour;
}
 @override 
function setSelected(isSelected) {
    // We do not store the label span on the category, so use getElementsByClassName.
    var labelDom = this.rowDiv_.getElementsByClassName('blocklyTreeLabel')[0];
    if (isSelected) {
        // Change the background color of the div to white.
        this.rowDiv_.style.backgroundColor = 'white';
        // Set the colour of the text to the colour of the category.
        labelDom.style.color = this.colour_;
    } else {
        // Set the background back to the original colour.
        this.rowDiv_.style.backgroundColor = this.colour_;
        // Set the text back to white.
        labelDom.style.color = 'white';
    }
}
*/
Blockly.registry.register(
    Blockly.registry.Type.TOOLBOX_ITEM,
    Blockly.ToolboxCategory.registrationName,
    CustomCategory,
    true
);

Blockly.Blocks['tamanho_text'] = {
    init: function() {
        this.appendValueInput('VALUE').setCheck('String').appendField('TAMANHO DO');
        this.setOutput(true, 'Number');
        this.setColour(160);
        this.setTooltip('Returns number of letters in the provided text.');
        this.setHelpUrl('http://www.w3schools.com/jsref/jsref_length_string.asp');
    },
};

Blockly.Blocks['jscode'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['JS_CODE'])
            .appendField(
                new Blockly.FieldMultilineInput('digite aqui\no código JS'),
                'CODE'
            );
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(90);
        this.setTooltip('Coloque uma linha de código JavaScript válido');
    },
};

Blockly.Blocks['comment'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['COMMENT'])
            .appendField(
                new Blockly.FieldMultilineInput(Blockly.Msg['COMMENT_FIELD']),
                'TEXT'
            );
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(330);
        this.setTooltip(Blockly.Msg['COMMENT_TOOLTIP']);
    },
};

Blockly.Blocks['conv_date'] = {
    init: function() {
        this.appendValueInput('INPUT')
            .setCheck(null)
            .appendField(Blockly.Msg['CONV_DATE']);
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip(Blockly.Msg['CONV_DATE_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['conv_time'] = {
    init: function() {
        this.appendValueInput('INPUT')
            .setCheck(null)
            .appendField(Blockly.Msg['CONV_HOUR'])
            .appendField(
                new Blockly.FieldDropdown([
                    ['hh:mm', 'false'],
                    ['hh:mm:ss', 'true'],
                ]),
                'second'
            );
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip(Blockly.Msg['CONV_HOUR_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['date'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['DATE_NOW']);
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip(Blockly.Msg['DATE_NOW_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['trigger'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['BLOCK_CALL_TRIGGER']);
        this.appendValueInput('name')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['NAME_OF_THE_RULE']);
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['START'])
            .appendField(new Blockly.FieldNumber(1, 1, 31), 'begin_day')
            .appendField('/')
            .appendField(new Blockly.FieldNumber(1, 1, 12), 'begin_month')
            .appendField('/')
            .appendField(new Blockly.FieldNumber(2022, 1, 2050), 'begin_year');
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['END'])
            .appendField(new Blockly.FieldNumber(1, 1, 31), 'end_day')
            .appendField('/')
            .appendField(new Blockly.FieldNumber(1, 1, 12), 'end_month')
            .appendField('/')
            .appendField(new Blockly.FieldNumber(2022, 1, 2050), 'end_year');
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['REPEAT_EVERY'])
            .appendField(new Blockly.FieldNumber(1, 1, 500), 'rep_unit')
            .appendField(
                new Blockly.FieldDropdown([
                    [Blockly.Msg['DAY'], 'day'],
                    [Blockly.Msg['WEEK'], 'week'],
                    [Blockly.Msg['MONTH'], 'month'],
                    [Blockly.Msg['YEAR'], 'year'],
                    [Blockly.Msg['HOUR'], 'hour'],
                ]),
                'rep_scale'
            );
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['DAYS_OF_THE_WEEK']);
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['MONDAY'])
            .appendField(new Blockly.FieldCheckbox('TRUE'), 'd1')
            .appendField(Blockly.Msg['TUESDAY'])
            .appendField(new Blockly.FieldCheckbox('TRUE'), 'd1')
            .appendField(Blockly.Msg['WEDNESDAY'])
            .appendField(new Blockly.FieldCheckbox('TRUE'), 'd1')
            .appendField(Blockly.Msg['THURSDAY'])
            .appendField(new Blockly.FieldCheckbox('TRUE'), 'd1')
            .appendField(Blockly.Msg['FRIDAY'])
            .appendField(new Blockly.FieldCheckbox('TRUE'), 'd1')
            .appendField(Blockly.Msg['SATURDAY'])
            .appendField(new Blockly.FieldCheckbox('TRUE'), 'd1')
            .appendField(Blockly.Msg['SUNDAY'])
            .appendField(new Blockly.FieldCheckbox('TRUE'), 'd1');
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(Blockly.Msg['DEFINE_BLOCK']);
        this.appendValueInput('block')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['CALL_BLOCK']);
        this.appendValueInput('group')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['OF_GROUP']);
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip(Blockly.Msg['BLOCK_CALL_TRIGGER_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['jscode_inline'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['JS_CODE'])
            .appendField(
                new Blockly.FieldTextInput(Blockly.Msg['JS_CODE_INNER_TEXT']),
                'CODE'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['JS_CODE_INLINE_TOOLTIP']);
    },
};

Blockly.Blocks['jscodemultiline'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['JS_CODE'])
            .appendField(
                new Blockly.FieldMultilineInput(Blockly.Msg['JS_CODE_INNER_TEXT']),
                'CODE'
            );
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['JS_CODE_MULTILINE_TOOLTIP']);
    },
};

Blockly.Blocks['jump_to_group'] = {
    init: function() {
        this.appendValueInput('BLOCO')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['JUMP_TO_BLOCK']);
        this.appendValueInput('GRUPO')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['OF_GROUP']);

        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(45);
        this.setTooltip(Blockly.Msg['JUMP_TO_BLOCK_OF_GROUP_TOOLTIP']);
        this.setHelpUrl('Coloque um nome de Grupo e Bloco válido');
    },
};

Blockly.Blocks['jump_to'] = {
    init: function() {
        this.appendValueInput('BLOCO')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['JUMP_TO_BLOCK']);
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(45);
        this.setTooltip(Blockly.Msg['JUMP_TO_BLOCK_TOOLTIP']);
        this.setHelpUrl('Coloque um nome de Bloco válido');
    },
};

Blockly.Blocks['connector'] = {
    init: function() {
        // Remove all 'a' characters from the text input's value.
        //var validator = function(newValue) {
        //    return newValue.substring(0, 14);
        //};
        this.appendDummyInput()
            .appendField(Blockly.Msg['JUMP_TO_CONNECTOR'])
            .appendField(new Blockly.FieldTextInput(''), 'TEXT');

        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(45);
        this.setTooltip(Blockly.Msg['JUMP_TO_CONNECTOR_TOOLTIP']);
    },
};

Blockly.Blocks['console_print'] = {
    /**
     * Block for print on the console statement.
     * @this {Blockly.Block}
     */
    init: function() {
        this.jsonInit({
            message0: Blockly.Msg['CONSOLE_PRINT_TITLE'],
            args0: [{
                type: 'input_value',
                name: 'TEXT',
            }, ],
            previousStatement: null,
            nextStatement: null,
            style: 'text_blocks',
            tooltip: Blockly.Msg['CONSOLE_PRINT_TOOLTIP'],
            helpUrl: Blockly.Msg['CONSOLE_PRINT_HELPURL'],
        });
    },
};

Blockly.Blocks['getphone'] = {
    init: function() {
        this.appendDummyInput().appendField('Funcão GetPhone()');
        this.setOutput(true, 'String');
        this.setColour(270);
        this.setTooltip(
            'Funcão GetPhone(): # de telefone se WhatsApp ou FALSE senão for WhatsApp'
        );
        this.setHelpUrl('# de telefone ou FALSE');
    },
};

Blockly.Blocks['clearvars'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['CLEAR_VARS']);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(270);
        this.setTooltip(Blockly.Msg['CLEAR_VARS_TOOLTIP']);
        this.setHelpUrl('# de telefone ou FALSE');
    },
};

Blockly.Blocks['msg'] = {
    init: function() {
        this.appendValueInput('MSG')
            // .setCheck('String')
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['MSG']),
                'MSG'
            );
        this.appendDummyInput().appendField(
            new Blockly.FieldDropdown([
                [Blockly.Msg['TEXT'], 'text'],
                [Blockly.Msg['IMAGE'], 'image'],
                [Blockly.Msg['TTS'], 'tts'],
                [Blockly.Msg['DOCUMENT'], 'document'],
                [Blockly.Msg['VIDEO'], 'video'],
                [Blockly.Msg['BUTTONS'], 'chips'],
                [Blockly.Msg['CAROUSEL'], 'carousel'],
                [Blockly.Msg['LIST'], 'list'],
                [Blockly.Msg['PAYLOAD'], 'payload'],
            ]),
            'TIPO'
        );
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(45);
        this.setTooltip(Blockly.Msg['MSG_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['audio_msg'] = {
    init: function() {
        this.appendValueInput('MSG')
            .setCheck('String')
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['MSG_AUDIO']),
                'MSG'
            );
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(45);
        this.setTooltip(Blockly.Msg['MSG_AUDIO_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['audio_out'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['MSG_AUDIO_VOICE_TITLE']);
        this.appendValueInput("text")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['MSG_AUDIO_VOICE_TEXT']);
        this.appendValueInput("voice")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['MSG_AUDIO_VOICE']);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip(Blockly.Msg['MSG_AUDIO_VOICE_TOOLTIP']);
        this.setHelpUrl("");
    }
};

Blockly.Blocks['wait'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['WAIT'])
            .appendField(new Blockly.FieldNumber(1, 0, 10), "seconds")
            .appendField(Blockly.Msg['WAIT_SECONDS']);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(20);
        this.setTooltip(Blockly.Msg['WAIT_TOOLTIP']);
        this.setHelpUrl("");
    }
};

Blockly.Blocks['workflow_push'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['WORKFLOW_TITLE']);
        this.appendDummyInput()
            .appendField(Blockly.Msg['WORKFLOW_PROVIDER'])
            .appendField(new Blockly.FieldDropdown([
								["FintalkFlow", "fintalk"],
								["Zapier", "zapier"],
								["SalesForce", "salesforce"],
								["ServiceNow", "servicenow"],
								["Pipefy", "pipefy"]
            ]), "type");
        this.appendValueInput("id")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['WORKFLOW_IDENTIFIER']);
        this.appendValueInput("json")
            .setCheck("Array")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['WORKFLOW_JSON']);
        this.setOutput(true, null);
        this.setColour(120);
        this.setTooltip(Blockly.Msg['WORKFLOW_TOOLTIP']);
        this.setHelpUrl("");
    }
};

Blockly.Blocks['llm_basic'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("LLM Basic");
        this.appendDummyInput("")
            .appendField(new Blockly.FieldDropdown([
                ["ChatGPT", "chatgpt"],
                ["Bard", "bard"],
                ["Character.ai", "characterai"],
                ["YouChat", "youchat"]
            ]), "provider");
        this.appendValueInput("prompt")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Pergunta");
        this.appendValueInput("maxtokens")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Max Tokens");
        this.appendValueInput("apikey")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Apikey");
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(116);
        this.setTooltip('LLM_BASIC_TOOLTIP');
        this.setHelpUrl("");
    }
};

Blockly.Blocks['llm_advanced'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("LLM Advanced - GPT Completion API");
        this.appendValueInput("prompt")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Prompt");
        this.appendValueInput("apikey")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Apikey");
        this.appendValueInput("temperature")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Temperature");
        this.appendValueInput("maxtokens")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Máximo de Tokens");
				this.appendValueInput("stop_caracter")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Pausa em:");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Model")
            .appendField(new Blockly.FieldDropdown([
                ["text-davinci-002", "text-davinci-002"],
                ["curie", "curie"],
                ["babbage", "babbage"],
                ["ada", "ada"],
                ["davinci", "davinci"]
            ]), "Modelo");
        this.appendValueInput("top_p")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("top_p");
        this.appendValueInput("presence_penalty")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("presence_penalty");
        this.appendValueInput("frequency_penalty")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("frequency_penalty");
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(116);
        this.setTooltip('LLM_ADVANCED_TOOLTIP');
        this.setHelpUrl("");
    }
};

Blockly.Blocks['llm_falagpt'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("LLM Fintalk - FalaGPT");
        this.appendValueInput("prompt")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Pergunta/Question");
        this.appendValueInput("document")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Document");
        this.appendValueInput("stop_caracter")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Pausa em:");
        this.appendValueInput("vars")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("vars");
        this.appendValueInput("apikey")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Apikey");
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(116);
        this.setTooltip('LLM_FALAZAP_TOOLTIP');
        this.setHelpUrl("");
    }
};

Blockly.Blocks['translate'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['TRANSLATE_TITLE']);
        this.appendValueInput("source")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TRANSLATE_SOURCE']);
        this.appendValueInput("target")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TRANSLATE_TARGET']);
        this.appendValueInput("text")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TRANSLATE_TEXT']);
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip(Blockly.Msg['TRANSLATE_TOOLTIP']);
        this.setHelpUrl("");
    }
};


Blockly.Blocks['dupla_json'] = {
    init: function() {
        this.appendValueInput('NOME')
            .setCheck('String')
            .appendField(
                new Blockly.FieldLabelSerializable('Dupla JSON  nome'),
                'NOME'
            );
        this.appendValueInput('VALOR')
            //.setCheck('String')
            .appendField(new Blockly.FieldLabelSerializable('valor'), 'VALOR');
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(90);
        this.setTooltip('retorna dupla "NOME":"VALOR"');
        this.setHelpUrl('');
    },
};

Blockly.Blocks['json_create_with'] = {
    /**
     * Block for creating a list with any number of elements of any type.
     * @this {Blockly.Block}
     */
    init: function() {
        this.setHelpUrl('');
        this.setStyle('list_blocks');
        this.itemCount_ = 3;
        this.updateShape_();
        this.setOutput(true, 'Array');
        this.setMutator(new Blockly.Mutator(['json_create_with_item']));
        this.setTooltip('Bloco JSON de duplas Nome:Valor');
    },
    /**
     * Create XML to represent list inputs.
     * @return {!Element} XML storage element.
     * @this {Blockly.Block}
     */
    mutationToDom: function() {
        var container = Blockly.utils.xml.createElement('mutation');
        container.setAttribute('items', this.itemCount_);
        return container;
    },
    /**
     * Parse XML to restore the list inputs.
     * @param {!Element} xmlElement XML storage element.
     * @this {Blockly.Block}
     */
    domToMutation: function(xmlElement) {
        this.itemCount_ = parseInt(xmlElement.getAttribute('items'), 10);
        this.updateShape_();
    },
    /**
     * Populate the mutator's dialog with this block's components.
     * @param {!Blockly.Workspace} workspace Mutator's workspace.
     * @return {!Blockly.Block} Root block in mutator.
     * @this {Blockly.Block}
     */
    decompose: function(workspace) {
        var containerBlock = workspace.newBlock('json_create_with_container');
        containerBlock.initSvg();
        var connection = containerBlock.getInput('STACK').connection;
        for (var i = 0; i < this.itemCount_; i++) {
            var itemBlock = workspace.newBlock('json_create_with_item');
            itemBlock.initSvg();
            connection.connect(itemBlock.previousConnection);
            connection = itemBlock.nextConnection;
        }
        return containerBlock;
    },
    /**
     * Reconfigure this block based on the mutator dialog's components.
     * @param {!Blockly.Block} containerBlock Root block in mutator.
     * @this {Blockly.Block}
     */
    compose: function(containerBlock) {
        var itemBlock = containerBlock.getInputTargetBlock('STACK');
        // Count number of inputs.
        var connections = [];
        while (itemBlock && !itemBlock.isInsertionMarker()) {
            connections.push(itemBlock.valueConnection_);
            itemBlock =
                itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
        }
        // Disconnect any children that don't belong.
        for (var i = 0; i < this.itemCount_; i++) {
            var connection = this.getInput('ADD' + i).connection.targetConnection;
            if (connection && connections.indexOf(connection) == -1) {
                connection.disconnect();
            }
        }
        this.itemCount_ = connections.length;
        this.updateShape_();
        // Reconnect any child blocks.
        for (var i = 0; i < this.itemCount_; i++) {
            Blockly.Mutator.reconnect(connections[i], this, 'ADD' + i);
        }
    },
    /**
     * Store pointers to any connected child blocks.
     * @param {!Blockly.Block} containerBlock Root block in mutator.
     * @this {Blockly.Block}
     */
    saveConnections: function(containerBlock) {
        var itemBlock = containerBlock.getInputTargetBlock('STACK');
        var i = 0;
        while (itemBlock) {
            var input = this.getInput('ADD' + i);
            itemBlock.valueConnection_ = input && input.connection.targetConnection;
            i++;
            itemBlock =
                itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
        }
    },
    /**
     * Modify this block to have the correct number of inputs.
     * @private
     * @this {Blockly.Block}
     */
    updateShape_: function() {
        if (this.itemCount_ && this.getInput('EMPTY')) {
            this.removeInput('EMPTY');
        } else if (!this.itemCount_ && !this.getInput('EMPTY')) {
            this.appendDummyInput('EMPTY').appendField('Bloco JSON {Nome:Valor}');
        }
        // Add new inputs.
        for (var i = 0; i < this.itemCount_; i++) {
            if (!this.getInput('ADD' + i)) {
                var input = this.appendValueInput('ADD' + i).setAlign(
                    Blockly.ALIGN_RIGHT
                );
                if (i == 0) {
                    input.appendField('Bloco JSON {Nome:Valor}');
                }
            }
        }
        // Remove deleted inputs.
        while (this.getInput('ADD' + i)) {
            this.removeInput('ADD' + i);
            i++;
        }
    },
};

Blockly.Blocks['json_create_with_container'] = {
    /**
     * Mutator block for list container.
     * @this {Blockly.Block}
     */
    init: function() {
        this.setStyle('list_blocks');
        this.appendDummyInput().appendField('Bloco JSON');
        this.appendStatementInput('STACK');
        this.setTooltip('Bloco JSON');
        this.contextMenu = false;
    },
};

Blockly.Blocks['json_create_with_item'] = {
    /**
     * Mutator block for adding items.
     * @this {Blockly.Block}
     */
    init: function() {
        this.setStyle('list_blocks');
        this.appendDummyInput().appendField('dupla Nome:Valor');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setTooltip('Coloque aqui duplas Nome:Valor');
        this.contextMenu = false;
    },
};

Blockly.Blocks['json'] = {
    /**
     * Mutator block for list container.
     * @this {Blockly.Block}
     */
    init: function() {
        this.setStyle('list_blocks');
        this.appendDummyInput().appendField('Coloque aqui duplas Nome:Valor');
        this.appendStatementInput('STACK');
        this.setTooltip('Coloque aqui duplas Nome:Valor');
        this.contextMenu = false;
    },
};

Blockly.Blocks['var_user'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['AMBIENT_VARS_USER'])
            .appendField(
                new Blockly.FieldDropdown([
                    ['id', 'id'],
                    ['name', 'name'],
                    ['nickname', 'nickname'],
                    ['email', 'email'],
                    ['phoneNumber', 'phoneNumber'],
                    ['cpf', 'cpf'],
                    ['birthdate', 'birthDate'],
                    ['address', 'address'],
                    ['address.street', 'address.street'],
                    ['address.number', 'address.number'],
                    ['address.neighborhood', 'address.neighborhood'],
                    ['address.city', 'address.city'],
                    ['address.state', 'address.state'],
                    ['address.country', 'address.country'],
                    ['address.zipCode', 'address.zipCode'],
                    ['address.location.lat', 'address.location.lat'],
                    ['address.location.long', 'address.location.long'],
                    ['gender', 'gender'],
                    ['appClient', 'appClient'],
                    ['optin', 'optin'],
                    ['input', 'input'],
                    ['cooldown', 'cooldown'],
                    ['isAudioOutputEnabled', 'isAudioOutputEnabled'],
                ]),
                'TIPO'
            );
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(15);
        this.setTooltip(Blockly.Msg['AMBIENT_VARS_USER_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['set_var_user'] = {
    init: function() {
        this.appendValueInput('VALOR')
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['AMBIENT_VARS_USER']),
                'USER'
            )
            .appendField(
                new Blockly.FieldDropdown([
                    ['name', 'name'],
                    ['nickname', 'nickname'],
                    ['email', 'email'],
                    ['phoneNumber', 'phoneNumber'],
                    ['cpf', 'cpf'],
                    ['birthdate', 'birthDate'],
                    ['address', 'address'],
                    ['address.street', 'address.street'],
                    ['address.number', 'address.number'],
                    ['address.neighborhood', 'address.neighborhood'],
                    ['address.city', 'address.city'],
                    ['address.state', 'address.state'],
                    ['address.country', 'address.country'],
                    ['address.zipCode', 'address.zipCode'],
                    ['address.location.lat', 'address.location.lat'],
                    ['address.location.long', 'address.location.long'],
                    ['gender', 'gender'],
                    ['appClient', 'appClient'],
                    ['optin', 'optin'],
                    ['input', 'input'],
                    ['cooldown', 'cooldown'],
                    ['isAudioOutputEnabled', 'isAudioOutputEnabled'],
                ]),
                'TIPO'
            )
            .setCheck(null);
        //.appendField(" = ");
        //this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(225);
        this.setTooltip(Blockly.Msg['AMBIENT_VARS_USER_VALUE_TOOLTIP']);
        this.setHelpUrl('');
    },
};
Blockly.Blocks['get_vars'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['GLOBAL_VAR'])
            .appendField(new Blockly.FieldDropdown(getArrayVarsBlockly()), 'TIPO');
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(15);
        this.setTooltip(Blockly.Msg['GET_VAR_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['set_vars'] = {
    init: function() {
        this.appendValueInput('VALOR')
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['GLOBAL_VAR']),
                'USER'
            )
            .appendField(new Blockly.FieldDropdown(getArrayVarsBlockly()), 'TIPO');

        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(225);
        this.setTooltip(Blockly.Msg['SET_VAR_TOOLTIP']);
        this.setHelpUrl('');
    },
};
Blockly.Blocks['get_variables'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['VARIABLE'])
            .appendField(
                new Blockly.FieldDropdown(getArrayVariablesBlockly()),
                'TIPO'
            );
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(15);
        this.setTooltip(Blockly.Msg['VARIABLE_TOOLTIP']);
        this.setHelpUrl('');
    },
};

//TODO Ajustar blocos de Banco de Dados de CLIENTES
// __________________________________________________________________
// 5 funcoes de banco de dados
// new_user_data  (cria um novo usuario passando um userid ou nulo se quiser deixar a tarefa
//    do novo userid ser criado pelo banco - e recebe o user id criado)
// get_user_data  (que recebe o user id e o campo e retorna o valor)
// set_user_data  (que recebe o user id e o campo e grava o valor)
// search_data  (que recebe o campo e um texto para fazer match e retorna um array com os userids que deram match)
// get_user_data_json  (que recebe o user id e retorna todos os campos num json)

Blockly.Blocks['get_user_data'] = {
    // TODO  atenção : criar o populate_array_customer_database_fields()
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['DB_READ_FIELD']);
        this.appendValueInput('companyid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('CompanyID');
        this.appendValueInput('userid')
            .setCheck(null)
            .appendField(Blockly.Msg['FIELD'])
            .appendField(
                new Blockly.FieldDropdown([
                    [Blockly.Msg['NAME'], 'name'],
                    [Blockly.Msg['NICKNAME'], 'nickname'],
                    [Blockly.Msg['CPF'], 'cpf'],
                    [Blockly.Msg['CEL'], 'celular'],
                    [Blockly.Msg['EMAIL'], 'email'],
                    [Blockly.Msg['ADDRESS'], 'address'],
                    [Blockly.Msg['NEIGHBORHOOD'], 'bairro'],
                    [Blockly.Msg['CITY'], 'city'],
                    [Blockly.Msg['STATE'], 'state'],
                    [Blockly.Msg['CEP'], 'zip'],
                    [Blockly.Msg['COMPANY'], 'Company'],
                    [Blockly.Msg['CNPJ'], 'cnpj'],
                ]),
                'field'
            )
            /*.appendField(
                      new Blockly.FieldDropdown(getArrayCustomerDataBase()),
                      "field"
                  )
                  */
            .appendField(Blockly.Msg['OF_USERID']);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip(Blockly.Msg['DB_READ_FIELD_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['set_user_data'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['DB_SAVE_FIELD']);
        this.appendValueInput('companyid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('CompanyID');
        this.appendValueInput('userid')
            .setCheck(null)
            .appendField(Blockly.Msg['FIELD'])
            .appendField(
                new Blockly.FieldDropdown([
                    [Blockly.Msg['NAME'], 'name'],
                    [Blockly.Msg['NICKNAME'], 'nickname'],
                    [Blockly.Msg['CPF'], 'cpf'],
                    [Blockly.Msg['CEL'], 'celular'],
                    [Blockly.Msg['EMAIL'], 'email'],
                    [Blockly.Msg['ADDRESS'], 'address'],
                    [Blockly.Msg['NEIGHBORHOOD'], 'bairro'],
                    [Blockly.Msg['CITY'], 'city'],
                    [Blockly.Msg['STATE'], 'state'],
                    [Blockly.Msg['CEP'], 'zip'],
                    [Blockly.Msg['COMPANY'], 'Company'],
                    [Blockly.Msg['CNPJ'], 'cnpj'],
                ]),
                'field'
            )
            /*.appendField(
                      new Blockly.FieldDropdown(getArrayCustomerDataBase()),
                      "field"
                  )
                  */
            .appendField(Blockly.Msg['OF_USERID']);
        this.appendValueInput('input_value')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['VALUE_ASSIGNED']);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip(Blockly.Msg['DB_SAVE_FIELD_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['new_user_data'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['DB_READ_CREATE_USER']);
        this.appendValueInput('companyid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('CompanyID');
        this.appendValueInput('userid')
            .setCheck(null)
            .appendField(Blockly.Msg['INSERT_USERID']);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip(Blockly.Msg['DB_READ_CREATE_USER_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['search_data'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['DB_SEARCH_FIELD']);
        this.appendValueInput('companyid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('CompanyID');
        this.appendValueInput('text_search')
            .setCheck(null)
            .appendField(Blockly.Msg['RETURNS_USERID'])
            .appendField(
                new Blockly.FieldDropdown([
                    [Blockly.Msg['NAME'], 'name'],
                    [Blockly.Msg['NICKNAME'], 'nickname'],
                    [Blockly.Msg['CPF'], 'cpf'],
                    [Blockly.Msg['CEL'], 'celular'],
                    [Blockly.Msg['EMAIL'], 'email'],
                    [Blockly.Msg['ADDRESS'], 'address'],
                    [Blockly.Msg['NEIGHBORHOOD'], 'bairro'],
                    [Blockly.Msg['CITY'], 'city'],
                    [Blockly.Msg['STATE'], 'state'],
                    [Blockly.Msg['CEP'], 'zip'],
                    [Blockly.Msg['COMPANY'], 'Company'],
                    [Blockly.Msg['CNPJ'], 'cnpj'],
                ]),
                'field'
            )
            /*.appendField(
                      new Blockly.FieldDropdown(getArrayCustomerDataBase()),
                      "field"
                  )
                  */
            .appendField(Blockly.Msg['HAS_TEXT']);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip(Blockly.Msg['DB_SEARCH_FIELD_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['get_user_data_json'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['DB_ALL_FIELDS']);
        this.appendValueInput('companyid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('CompanyID');
        this.appendValueInput('user_id')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['RETURNS_JSON_USERID']);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip(Blockly.Msg['DB_ALL_FIELDS_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['functions'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['FUNCTION'])
            .appendField(
                new Blockly.FieldDropdown([
                    [Blockly.Msg['DISTANCE'], 'distancia'],
                ]),
                'func'
            );
        this.appendValueInput('var1')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(`${Blockly.Msg['LATITUDE']} #1`);
        this.appendValueInput('var2')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(`${Blockly.Msg['LONGITUDE']} #1`);
        this.appendValueInput('var3')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(`${Blockly.Msg['LATITUDE']} #2`);
        this.appendValueInput('var4')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(`${Blockly.Msg['LONGITUDE']} #2`);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip('Verifique documentação');
        this.setHelpUrl('');
    },
};

Blockly.Blocks['convert'] = {
    init: function() {
        this.appendDummyInput().appendField('Converter a variável ');
        this.appendValueInput('VAR')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(' para ');
        this.appendDummyInput().appendField(
            new Blockly.FieldDropdown([
                ['R$ Real sem decimal', 'REALINT'],
                ['R$ Real com 2 decimais', 'REALDEC'],
                ['Data Brasil', 'DATE'],
            ]),
            'OPTION'
        );
        /*
            this.appendValueInput("var2")
                .setCheck(null)
                .setAlign(Blockly.ALIGN_RIGHT)
                .appendField("variável #2");
            this.appendValueInput("var3")
                .setCheck(null)
                .setAlign(Blockly.ALIGN_RIGHT)
                .appendField("variável #3");
            this.appendValueInput("var4")
                .setCheck(null)
                .setAlign(Blockly.ALIGN_RIGHT)
                .appendField("variável #4");
                */
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(180);
        this.setTooltip('Converte texto para seleção escolhida');
        this.setHelpUrl('');
    },
};

Blockly.Blocks['create_chart'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['CREATE_CHART']);
        this.appendValueInput('payload')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('Json');
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(0);
        this.setTooltip(Blockly.Msg['CREATE_CHART_TOOLTIP']);
        this.setHelpUrl('https://quickchart.io/gallery/');
    },
};

Blockly.Blocks['create_table'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['CREATE_TABLE']);
        this.appendValueInput('payload')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('Json');
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(0);
        this.setTooltip(Blockly.Msg['CREATE_TABLE_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['isvalidtext'] = {
    init: function() {
        this.appendValueInput('ISVALIDTEXT')
            .setCheck(null)
            .appendField(
                new Blockly.FieldLabelSerializable('IsValidText() '),
                'CHECK'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(75);
        this.setTooltip(Blockly.Msg['IS_VALID_TEXT_TOOLTIP']);
        this.setHelpUrl('');
    },
};
Blockly.Blocks['isvaliddate'] = {
    init: function() {
        this.appendValueInput('ISVALIDDATE')
            .setCheck(null)
            .appendField(
                new Blockly.FieldLabelSerializable('IsValidDate() '),
                'CHECK'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(75);
        this.setTooltip(Blockly.Msg['IS_VALID_DATE_TOOLTIP']);
        this.setHelpUrl('');
    },
};
Blockly.Blocks['isvalidcpf'] = {
    init: function() {
        this.appendValueInput('ISVALIDCPF')
            .setCheck(null)
            .appendField(
                new Blockly.FieldLabelSerializable('IsValidCPF() '),
                'CHECK'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(75);
        this.setTooltip(Blockly.Msg['IS_VALID_CPF_TOOLTIP']);
        this.setHelpUrl('');
    },
};
Blockly.Blocks['isvalidcnpj'] = {
    init: function() {
        this.appendValueInput('ISVALIDCNPJ')
            .setCheck(null)
            .appendField(
                new Blockly.FieldLabelSerializable('IsValidCNPJ() '),
                'CHECK'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(75);
        this.setTooltip(Blockly.Msg['IS_VALID_CNPJ_TOOLTIP']);
        this.setHelpUrl('');
    },
};
Blockly.Blocks['isvalidcep'] = {
    init: function() {
        this.appendValueInput('ISVALIDCEP')
            .setCheck(null)
            .appendField(
                new Blockly.FieldLabelSerializable('IsValidCEP() '),
                'CHECK'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(75);
        this.setTooltip(Blockly.Msg['IS_VALID_CEP_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['ToInt'] = {
    init: function() {
        this.appendValueInput('TOINT')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['TO_INT']),
                'NUM'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(225);
        this.setTooltip(Blockly.Msg['TO_INT_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['ToDec'] = {
    init: function() {
        this.appendValueInput('TODEC')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['TO_DEC']),
                'NUM'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(225);
        this.setTooltip(Blockly.Msg['TO_DEC_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['getcities'] = {
    init: function() {
        this.appendValueInput('GETCITIES')
            .setCheck(null)
            .appendField(new Blockly.FieldLabelSerializable('getcities() '), 'CHECK');
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(75);
        this.setTooltip(
            'Retorna NULL se não encontrou a cidade ou retorna um OBJETO GetCities se for válido (.name .state)'
        );
        this.setHelpUrl('');
    },
};
Blockly.Blocks['sendsmscode'] = {
    init: function() {
        this.appendDummyInput().appendField('Função TwoFactorAuth');
        this.appendValueInput('INPUTNUM')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(new Blockly.FieldLabelSerializable('# Telefone'), 'INPUT');
        this.appendValueInput('INPUTMSG')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(new Blockly.FieldLabelSerializable('Mensagem'), 'MSG');
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(0);
        this.setTooltip(
            'Recebe um numero de telefone (string tipo +551198555555 ou 551198555555) e retorna OBJETO TFA com código enviado e o telefone (.code .phoneNumber)'
        );
        this.setHelpUrl('');
    },
};

Blockly.Blocks['sendsms'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['SEND_SMS']);
        this.appendValueInput('INPUTNUM')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['PHONE'], 'INPUT')
            );
        this.appendValueInput('INPUTMSG')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['MESSAGE']),
                'MSG'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(0);
        this.setTooltip(Blockly.Msg['SEND_SMS_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['free_text'] = {
    init: function() {
        this.appendValueInput('TXT')
            .setCheck(null)
            .appendField(new Blockly.FieldTextInput('texto'), 'TEXTO');
        this.setOutput(true, null);
        this.setColour(345);
        this.setTooltip('texto livre');
        this.setHelpUrl('');
    },
};

Blockly.Blocks['ocr'] = {
    init: function() {
        this.appendDummyInput()
            .appendField('Tipo de OCR')
            .appendField(
                new Blockly.FieldDropdown([
                    ['texto', 'TEXT'],
                    ['boleto', 'BOLETO'],
                    ['codigo de barra', 'BARCODE'],
                    ['QR code', 'QRCODE'],
                ]),
                'OCR_TYPE'
            );
        this.appendValueInput('URL')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('url');
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip('OCR de texto livre ou boleto');
        this.setHelpUrl('');
    },
};

Blockly.Blocks['livechat'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['PROVIDER'])
            .appendField(new Blockly.FieldDropdown([
                ["Fintalk Desk", "QUOTI"],
                ["Salesforce", "SALESFORCE"],
                ["Twillio", "TWILLIO"],
                ["ZenDesk", "ZENDESK"],
                ["ServiceNow", "SERVICENOW"],
                ["Rocketchat", "ROCKET"],
                ["Freshdesk", "FRESHDESK"]
            ]), "LIVECHAT_TYPE");
        this.appendValueInput("SECTOR")
            .setCheck("String")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['SECTOR']);
        this.appendValueInput("RETURNGROUP")
            .setCheck("String")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['RETURN_GROUP']);
        this.appendValueInput("RETURNBLOCK")
            .setCheck("String")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['RETURN_BLOCK']);
        this.appendValueInput("TICKET")
            .setCheck("Boolean")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('Ticket (async)');
        this.appendValueInput('JSON_ATTRIBUTES')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['TASK_ATTRIBUTES_ONLY_TWILLO']),
                'JsonAttrs'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(60);
        this.setTooltip(Blockly.Msg['PROVIDER_TOOLTIP']);
        this.setHelpUrl("");
    },
};
Blockly.Blocks['pwa'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['CALL_TO_PWA']);
        this.appendValueInput('INPUTPWA1')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['NAME_HTML']),
                'PWA1'
            );
        this.appendValueInput('INPUTPWA2')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['NAME_BLOCK']),
                'PWA2'
            );
        this.appendValueInput('INPUTPWA3')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable(Blockly.Msg['PAYLOAD_JSON']),
                'PWA3'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(210);
        this.setTooltip(Blockly.Msg['CALL_TO_PWA_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['JSONstringify'] = {
    init: function() {
        this.appendValueInput('JSONSTRINGIFY')
            .setCheck(null)
            .appendField(
                new Blockly.FieldLabelSerializable('JSON.stringify() '),
                'CHECK'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(180);
        this.setTooltip(Blockly.Msg['JSON_STRINGIFY_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['JSONparse'] = {
    init: function() {
        this.appendValueInput('JSONPARSE')
            .setCheck(null)
            .appendField(
                new Blockly.FieldLabelSerializable('JSON.parse() '),
                'CHECK'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(180);
        this.setTooltip(Blockly.Msg['JSON_PARSE_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['crm_read_field_to_var'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['CRM_COMPANY'])
            .appendField(new Blockly.FieldDropdown([
                ["CRM_id", "CRM_id"],
                ["corp_id", "corp_id"],
                ["project_id", "project_id"],
                ["company_name", "company_name"],
                ["company_fantasy_name", "company_fantasy_name"],
                ["company_id", "company_id"],
                ["contact_name", "contact_name"],
                ["contact_nickname", "contact_nickname"],
                ["email", "email"],
                ["CNPJ", "CNPJ"],
                ["CPF", "CPF"],
                ["federal_id", "federal_id"],
                ["classification", "classification"],
                ["tag", "tag"],
                ["phone_number", "phone_number"],
                ["phone_number_last_contact_date", "phone_number_last_contact_date"],
                ["whatsapp_number", "whatsapp_number"],
                ["birth_date", "birth_date"],
                ["date_of_creation", "date_of_creation"],
                ["date_of_last_update", "date_of_last_update"],
                ["date_of_last_contact", "date_of_last_contact"],
                ["date_of_last_contact_with_whatsapp", "date_of_last_contact_with_whatsapp"],
                ["date_of_last_contact_with_email", "date_of_last_contact_with_email"],
                ["date_of_last_contact_with_phone", "date_of_last_contact_with_phone"],
                ["date_of_last_contact_with_sms", "date_of_last_contact_with_sms"],
                ["address_street", "address_street"],
                ["address_number", "address_number"],
                ["address_complement", "address_complement"],
                ["address_neighborhood", "address_neighborhood"],
                ["address_city", "address_city"],
                ["address_state", "address_state"],
                ["address_country", "address_country"],
                ["address_zipcode", "address_zipcode"],
                ["address_location_lat", "address_location_lat"],
                ["address_location_long", "address_location_long"],
                ["gender", "gender"],
                ["app_client_whatsapp_id", "app_client_whatsapp_id"],
                ["app_client_telegram_id", "app_client_telegram_id"],
                ["app_client_twitter_id", "app_client_twitter_id"],
                ["app_client_facebook_id", "app_client_facebook_id"],
                ["app_client_instagram_id", "app_client_instagram_id"],
                ["app_client_falazap_name", "app_client_falazap_name"],
                ["app_client_falazap_id", "app_client_falazap_id"],
                ["optin_ok", "optin_ok"],
                ["optin_date", "optin_date"],
                ["optin_channel", "optin_channel"],
                ["optin_text", "optin_text"],
                ["optin_channels", "optin_channels"],
                ["requested_exclusion_date", "requested_exclusion_date"],
                ["exclusion_date", "exclusion_date"],
                ["cooldown_date", "cooldown_date"],
                ["not_enabled", "not_enabled"],
                ["LGPD_accepted", "LGPD_accepted"],
                ["is_audio_enabled", "is_audio_enabled"],
                ["prefer_audio_enabled", "prefer_audio_enabled"],
                ["json_data", "json_data"],
                ["config_max_fields", "config_max_fields"],
                ["config_used_fields", "config_used_fields"],
                ["config_next_available", "config_next_available"],
                ["field_1", "field_1"],
                ["field_2", "field_2"],
                ["field_3", "field_3"],
                ["field_4", "field_4"],
                ["field_5", "field_5"],
                ["field_6", "field_6"],
                ["field_7", "field_7"],
                ["field_8", "field_8"],
                ["field_9", "field_9"],
                ["field_10", "field_10"],
                ["field_11", "field_11"],
                ["field_12", "field_12"],
                ["field_13", "field_13"],
                ["field_14", "field_14"],
                ["field_15", "field_15"],
                ["field_16", "field_16"],
                ["field_17", "field_17"],
                ["field_18", "field_18"],
                ["field_19", "field_19"],
                ["field_20", "field_20"]
            ]), "CAMPO");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['crm_write_var_to_field'] = {
    init: function() {
        this.appendValueInput("CAMPO")
            .setCheck(null)
            .appendField(Blockly.Msg['CRM_COMPANY'])
            .appendField(new Blockly.FieldDropdown([
                ["company_name", "company_name"],
                ["company_fantasy_name", "company_fantasy_name"],
                ["company_id", "company_id"],
                ["contact_name", "contact_name"],
                ["contact_nickname", "contact_nickname"],
                ["email", "email"],
                ["CNPJ", "CNPJ"],
                ["CPF", "CPF"],
                ["federal_id", "federal_id"],
                ["classification", "classification"],
                ["tag", "tag"],
                ["phone_number", "phone_number"],
                ["phone_number_last_contact_date", "phone_number_last_contact_date"],
                ["whatsapp_number", "whatsapp_number"],
                ["birth_date", "birth_date"],
                ["date_of_last_update", "date_of_last_update"],
                ["date_of_last_contact", "date_of_last_contact"],
                ["date_of_last_contact_with_whatsapp", "date_of_last_contact_with_whatsapp"],
                ["date_of_last_contact_with_email", "date_of_last_contact_with_email"],
                ["date_of_last_contact_with_phone", "date_of_last_contact_with_phone"],
                ["date_of_last_contact_with_sms", "date_of_last_contact_with_sms"],
                ["address_street", "address_street"],
                ["address_number", "address_number"],
                ["address_complement", "address_complement"],
                ["address_neighborhood", "address_neighborhood"],
                ["address_city", "address_city"],
                ["address_state", "address_state"],
                ["address_country", "address_country"],
                ["address_zipcode", "address_zipcode"],
                ["address_location_lat", "address_location_lat"],
                ["address_location_long", "address_location_long"],
                ["gender", "gender"],
                ["app_client_whatsapp_id", "app_client_whatsapp_id"],
                ["app_client_telegram_id", "app_client_telegram_id"],
                ["app_client_twitter_id", "app_client_twitter_id"],
                ["app_client_facebook_id", "app_client_facebook_id"],
                ["app_client_instagram_id", "app_client_instagram_id"],
                ["app_client_falazap_name", "app_client_falazap_name"],
                ["app_client_falazap_id", "app_client_falazap_id"],
                ["optin_ok", "optin_ok"],
                ["optin_date", "optin_date"],
                ["optin_channel", "optin_channel"],
                ["optin_text", "optin_text"],
                ["optin_channels", "optin_channels"],
                ["requested_exclusion_date", "requested_exclusion_date"],
                ["exclusion_date", "exclusion_date"],
                ["cooldown_date", "cooldown_date"],
                ["not_enabled", "not_enabled"],
                ["LGPD_accepted", "LGPD_accepted"],
                ["is_audio_enabled", "is_audio_enabled"],
                ["prefer_audio_enabled", "prefer_audio_enabled"],
                ["json_data", "json_data"],
                ["field_1", "field_1"],
                ["field_2", "field_2"],
                ["field_3", "field_3"],
                ["field_4", "field_4"],
                ["field_5", "field_5"],
                ["field_6", "field_6"],
                ["field_7", "field_7"],
                ["field_8", "field_8"],
                ["field_9", "field_9"],
                ["field_10", "field_10"],
                ["field_11", "field_11"],
                ["field_12", "field_12"],
                ["field_13", "field_13"],
                ["field_14", "field_14"],
                ["field_15", "field_15"],
                ["field_16", "field_16"],
                ["field_17", "field_17"],
                ["field_18", "field_18"],
                ["field_19", "field_19"],
                ["field_20", "field_20"]
            ]), "campo")
            .appendField("=");
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['crm_get_one'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['CRM_SEARCH_COMPANY_DATA']);
        this.appendValueInput("valor")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(new Blockly.FieldDropdown([
                ["corp_id", "corp_id"],
                ["CNPJ", "CNPJ"],
                ["CPF", "CPF"],
                ["federal_id", "federal_id"],
                ["crm_id", "crm_id"]
            ]), "campo")
            .appendField(new Blockly.FieldDropdown([
                ["=", "eq"],
                [">", "gt"],
                [">=", "gte"],
                ["<", "lt"],
                ["<=", "lte"],
                [Blockly.Msg['CRM_SEARCH_COMPANY_DATA_CONTAIN'], "in"]
            ]), "operator");
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip(Blockly.Msg['CRM_SEARCH_COMPANY_DATA_TOOLTIP']);
        this.setHelpUrl("");
    }
};
Blockly.Blocks['crm_update_one'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['CRM_SAVE_COMPANY_DATA']);
        this.appendValueInput("valor")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(new Blockly.FieldDropdown([
                ["corp_id", "corp_id"],
                ["CNPJ", "CNPJ"],
                ["CPF", "CPF"],
                ["federal_id", "federal_id"],
                ["crm_id", "crm_id"]
            ]), "campo");
        this.appendValueInput("input_value")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['CRM_SAVE_COMPANY_DATA']);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip(Blockly.Msg['CRM_SAVE_COMPANY_DATA']);
        this.setHelpUrl("");
    }
};

Blockly.Blocks['crm_new_one'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['CRM_NEW_COMPANY_DATA']);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip(Blockly.Msg['CRM_NEW_COMPANY_DATA']);
        this.setHelpUrl(Blockly.Msg['CRM_NEW_COMPANY_DATA']);
    }
};

Blockly.Blocks['crm_delete_one'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['CRM_ERASE_COMPANY_DATA']);
        this.appendValueInput("val.")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(new Blockly.FieldDropdown([
                ["corp_id", "corp_id"],
                ["CNPJ", "CNPJ"],
                ["CPF", "CPF"],
                ["federal_id", "federal_id"],
                ["crm_id", "crm_id"]
            ]), "campo");
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(0);
        this.setTooltip(Blockly.Msg['CRM_ERASE_COMPANY_DATA']);
        this.setHelpUrl(Blockly.Msg['CRM_ERASE_COMPANY_DATA']);
    }
};


//API HTTP
Blockly.Blocks['request'] = {
    init: function() {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['HTTP_REQUEST'])
            .appendField(
                new Blockly.FieldDropdown([
                    ['GET', 'GET'],
                    ['POST', 'POST'],
                    ['PUT', 'PUT'],
                    ['OPTIONS', 'OPTIONS'],
                    ['DELETE', 'DELETE'],
                    ['PATCH', 'PATCH'],
                ]),
                'INPUTREQ1'
            );
        this.appendValueInput('REQ2')
            .setCheck('String')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(new Blockly.FieldLabelSerializable('URL'), 'INPUTREQ2');
        this.appendValueInput('REQ3')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable('Body (JSON) opcional'),
                'INPUTREQ3'
            );
        this.appendValueInput('REQ4')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable('Extras (JSON) opcional'),
                'INPUTREQ4'
            );
        this.appendValueInput('REQ5')
            .setCheck('Boolean')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable(
                    Blockly.Msg['HTTP_REQUEST_SUPPRESS_ERRORS']
                ),
                'INPUTREQ5'
            );
        this.appendValueInput('REQ6')
            .setCheck('Boolean')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldLabelSerializable(
                    Blockly.Msg['HTTP_REQUEST_ENABLE_ERROR_RETURN']
                ),
                'INPUTREQ6'
            );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(0);
        this.setColour(0);
        this.setTooltip(Blockly.Msg['HTTP_REQUEST_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['debug_flag'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['DEBUG_FLAG'])
            .appendField(
                new Blockly.FieldDropdown([
                    [Blockly.Msg['DEBUG_FLAG_OFF'], 'false'],
                    [Blockly.Msg['DEBUG_FLAG_ON'], 'true'],
                ]),
                'DEBUG'
            );
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip(Blockly.Msg['DEBUG_FLAG_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['debug_console_log'] = {
    init: function() {
        this.appendValueInput("print")
            .setCheck(null)
            .appendField(Blockly.Msg['DEBUG_CONSOLE_LOG']);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(45);
        this.setTooltip(Blockly.Msg['DEBUG_CONSOLE_LOG_TOOLTIP']);
        this.setHelpUrl(Blockly.Msg['DEBUG_CONSOLE_LOG_HELP']);
    }
};

Blockly.Blocks['debug_console_log_var'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['DEBUG_CONSOLE_LOG_VAR']);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(45);
        this.setTooltip(Blockly.Msg['DEBUG_CONSOLE_LOG_VAR_TOOLTIP']);
        this.setHelpUrl(Blockly.Msg['DEBUG_CONSOLE_LOG_VAR_HELP']);
    }
};

Blockly.Blocks['array'] = {
    init: function() {
        this.appendDummyInput()
            .appendField('Array: [\n\n]')
            .appendField(
                new Blockly.FieldMultilineInput(Blockly.Msg['ARRAY_TEXT']),
                'CODE'
            );
        this.setOutput(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['ARRAY_TOOLTIP']);
    },
};

Blockly.Blocks['json'] = {
    init: function() {
        this.appendDummyInput()
            .appendField('JSON: {\n\n}')
            .appendField(
                new Blockly.FieldMultilineInput(Blockly.Msg['JSON_TEXT']),
                'CODE'
            );
        this.setOutput(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['JSON_TOOLTIP']);
    },
};

Blockly.Blocks['googlesheetnewtab'] = {
    init: function() {
        this.appendValueInput('googlesheetid')
            .setCheck(null)
            .appendField(Blockly.Msg['ADD_LINE_GOOGLE_SHEET']);
        this.setOutput(true, null);
        this.setInputsInline(false);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['ADD_LINE_GOOGLE_SHEET_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['googlesheetgetrowbynumber'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['READ_LINE_GOOGLE_SHEET']);
        this.appendValueInput('googlesheetid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('googlesheetID');
        this.appendValueInput('tabindex')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TAB']);
        this.appendValueInput('row')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['LINE']);
        this.appendDummyInput().appendField(Blockly.Msg['TAB_LINE_STARTS_AT_0']);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['READ_LINE_GOOGLE_SHEET_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['googlesheetgetcellbynumber'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['READ_CEL_GOOGLE_SHEET']);
        this.appendValueInput('googlesheetid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('googlesheetID');
        this.appendValueInput('tabindex')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TAB']);
        this.appendValueInput('row')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['LINE']);
        this.appendValueInput('col')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['COLUMN']);
        this.appendDummyInput().appendField(
            Blockly.Msg['TAB_LINE_COLUMN_STARTS_AT_0']
        );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['READ_CEL_GOOGLE_SHEET_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['googlesheetgetcellbya1'] = {
    init: function() {
        this.appendDummyInput().appendField(
            Blockly.Msg['READ_CEL_GOOGLE_SHEET_A1']
        );
        this.appendValueInput('googlesheetid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('googlesheetID');
        this.appendValueInput('tabindex')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TAB']);
        this.appendValueInput('a1')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['ADDRESS_ON_FORMAT_A1']);
        this.appendDummyInput().appendField(Blockly.Msg['TAB_STARTS_AT_0']);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['READ_CEL_GOOGLE_SHEET_A1_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['googlesheetgetrange'] = {
    init: function() {
        this.appendDummyInput().appendField(
            Blockly.Msg['READ_REGION_GOOGLE_SHEET']
        );
        this.appendValueInput('googlesheetid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('googlesheetID');
        this.appendValueInput('tabindex')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TAB']);
        this.appendValueInput('rowini')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['START_LINE']);
        this.appendValueInput('rowend')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['END_LINE']);
        this.appendValueInput('colini')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['START_COLUMN']);
        this.appendValueInput('colend')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['END_COLUMN']);
        this.appendDummyInput().appendField(
            Blockly.Msg['TAB_LINE_COLUMN_STARTS_AT_0']
        );
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['READ_REGION_GOOGLE_SHEET_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['googlesheetgettable'] = {
    init: function() {
        this.appendDummyInput().appendField(
            Blockly.Msg['READ_REGION_GOOGLE_SHEET_AS_TABLE']
        );
        this.appendValueInput('googlesheetid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('googlesheetID');
        this.appendValueInput('tabindex')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TAB']);
        this.appendValueInput('rowini')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['START_LINE']);
        this.appendValueInput('rowend')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['END_LINE']);
        this.appendValueInput('colini')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['START_COLUMN']);
        this.appendValueInput('colend')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['END_COLUMN']);
        this.appendDummyInput().appendField(
            Blockly.Msg['TAB_LINE_COLUMN_STARTS_AT_0']
        );
        this.setInputsInline(false);
        this.setOutput(true, 'Number');
        this.setColour(90);
        this.setTooltip(Blockly.Msg['READ_REGION_GOOGLE_SHEET_AS_TABLE_TOOLTIP']);
        this.setHelpUrl('');
    },
};
Blockly.Blocks['googlesheetgettableA1'] = {
    init: function() {
        this.appendDummyInput().appendField(
            Blockly.Msg['READ_REGION_GOOGLE_SHEET_AS_TABLE_A1']
        );
        this.appendValueInput('googlesheetid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('googlesheetID');
        this.appendValueInput('tabindex')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TAB']);
        this.appendValueInput('range')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['RANGE_ON_FORMAT_A1:B3']);
        this.appendDummyInput().appendField(Blockly.Msg['USE_RANGE_FORMAT_A1_Z9']);
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(90);
        this.setTooltip(
            Blockly.Msg['READ_REGION_GOOGLE_SHEET_AS_TABLE_A1_TOOLTIP']
        );
        this.setHelpUrl('');
    },
};

Blockly.Blocks['googlesheetsetcellbynumber'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['SAVE_CEL_GOOGLE_SHEET']);
        this.appendValueInput('googlesheetid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('googlesheetID');
        this.appendValueInput('tabindex')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TAB']);
        this.appendValueInput('row')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['LINE']);
        this.appendValueInput('col')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['COLUMN']);
        this.appendValueInput('valor')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['VALUE_FOR_CELL']);
        this.appendDummyInput().appendField(
            Blockly.Msg['TAB_LINE_COLUMN_STARTS_AT_0']
        );
        this.setInputsInline(false);
        this.setOutput(true, null);
        //this.setPreviousStatement(true, null);
        //this.setNextStatement(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['SAVE_CEL_GOOGLE_SHEET_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['googlesheetsetheaderrow'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['ADD_HEADER_GOOGLE_SHEET']);
        this.appendValueInput('googlesheetid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('googlesheetID');
        this.appendValueInput('tabindex')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TAB']);
        this.appendValueInput('lista')
            .setCheck('Array')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['LIST_WITH_TITLES_OF_COLUMNS']);
        this.setOutput(true, null);
        //this.setPreviousStatement(true, null);
        //this.setNextStatement(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['ADD_HEADER_GOOGLE_SHEET_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['googlesheetaddrow'] = {
    init: function() {
        this.appendDummyInput().appendField(Blockly.Msg['ADD_LINE_GOOGLE_SHEET']);
        this.appendValueInput('googlesheetid')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('googlesheetID');
        this.appendValueInput('tabindex')
            .setCheck('Number')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['TAB']);
        this.appendValueInput('lista')
            .setCheck('Array')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(Blockly.Msg['LIST_WITH_CELLS']);
        this.setOutput(true, null);
        //this.setPreviousStatement(true, null);
        //this.setNextStatement(true, null);
        this.setColour(90);
        this.setTooltip(Blockly.Msg['ADD_LINE_GOOGLE_SHEET_TOOLTIP']);
        this.setHelpUrl('');
    },
};

Blockly.Blocks['mongoaddrow'] = {
    init: function() {
        this.appendDummyInput().appendField('Adiciona linha no MongoDB');
        this.appendValueInput('botname')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('Nome do Bot');
        this.appendValueInput('tablename')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('Nome da tabela');
        this.appendValueInput('username')
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('Nome do usuário');
        this.appendValueInput('datajson')
            //.setCheck('Array')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField('JSON');
        this.setOutput(true, null);
        //this.setPreviousStatement(true, null);
        //this.setNextStatement(true, null);
        this.setColour(0);
        this.setTooltip('Salva um JSON no banco de dados MongoDB');
        this.setHelpUrl('');
    },
};