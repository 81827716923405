import { shade } from 'polished';
import styled from 'styled-components';

export const OutputContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    margin: 8px;
  }

  .MuiOutlinedInput-input {
    background: #fff;
  }

  .MuiIconButton-root {
    background: #0e103b;

    &:hover {
      background: ${shade(0.3, "#0e103b")};
    }
  }
`;
