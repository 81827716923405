import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { useFlow } from 'contexts/Flow/flowContext';

const ToastNotification = () => {
  const { state, dispatch } = useFlow();
  const { type, message, show, duration } = state.toastNotification;

  const localDuration = duration
    ? duration
    : type === 'error'
    ? null
    : duration || 4000;

  function handleCloseAlert(event?: React.SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({
      type: 'updateForm',
      data: {
        toastNotification: {
          ...state.toastNotification,
          message: '',
          show: false,
        },
      },
    });
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={show}
      autoHideDuration={localDuration}
      onClose={handleCloseAlert}
    >
      <Alert onClose={handleCloseAlert} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastNotification;
