import { useApp } from 'contexts/App/appContext';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useToast } from 'contexts/ToastContext';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CognitoLogin } from 'services/AuthService';

export function OAuthRedirect() {
  const { generatePermissions } = usePermissions();
  const { toastNotification } = useToast();
  const { dispatch } = useApp();
  const history = useHistory();

  useEffect(() => {
    async function load() {
      if (window.location.href.includes('token')) {
        const url = window.location.href;
        const tokenRegex = /(?<=\btoken=)[^&]+/;
        const match = url.match(tokenRegex);

        if (match) {
          const token = match[0];
          await handleCognitoLogin(token);
        } else {
          console.log('Token não encontrado');
        }
      }
    }

    load();
		// eslint-disable-next-line
  }, []);

  async function handleCognitoLogin(token: string) {
    try {
      if (token) {
        const responseCognitoLogin = await CognitoLogin({ token });
        if (
          !!responseCognitoLogin.token &&
          !!responseCognitoLogin.refresh_token
        ) {
          localStorage.setItem('token', responseCognitoLogin.token);
          localStorage.setItem(
            'refresh_token',
            responseCognitoLogin.refresh_token
          );
          dispatch({ type: 'updateApp', data: { signed: true } });
          generatePermissions(responseCognitoLogin.token);
          history.push('/');
        } else {
          throw new Error(responseCognitoLogin.detail.message ?? '');
        }
      }
    } catch (err) {
      history.push('/login');
      const error = err as Error;
      toastNotification({ type: 'error', message: error.message as string });
    }
  }

  return null;
}
