import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Sentences from 'components/inputs/Material/Sentences/index';
import Info from 'components/inputs/Material/Info/index';
import MessageForUser from 'components/inputs/Material/MessageForUser/index';
import { FormFieldsContainer } from '../Shared/styles';
import { Grid } from '@material-ui/core';
import VariableIndentificationTextUser from 'components/inputs/Material/VariableIndentificationTextUser';

export default function InitialFlow() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <Sentences  />
        <MessageForUser />

        <Grid style={{ padding: '0 12px 0 20px' }}>
          <VariableIndentificationTextUser
            index={0}
            showQuestions={true}
            showTitle={true}
          />
        </Grid>
        {/* <MultiVars
          showDelete={true}
          showQuestions={true}
          indexsRequired={[0]}
        /> */}
      </FormFieldsContainer>
      <FooterButtons />
    </>
  );
}
