import React from 'react';

import AirTalkSidebar from './AirTalkSidebar';
import { AirTalkContextProvider } from 'contexts/AirTalkContext';

type Props = {
  closeAirTalk: () => void;
};

const AirTalkPrompt: React.FC<Props> = ({ closeAirTalk }) => {
  return (
    <AirTalkContextProvider>
      <AirTalkSidebar handleClose={closeAirTalk} />
    </AirTalkContextProvider>
  );
};

export default AirTalkPrompt;
