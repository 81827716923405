import React, { useCallback, useEffect } from 'react';
import { useForm } from 'contexts/Form/formContext';
import { SendUser } from 'models/DataFlow';

import useTranslator from 'utils/hooks/Translator';
import * as S from './styles';

export default function ToHtmlFields() {
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && !dataForm.sendUser) {
      dataForm.sendUser = {
        message: '',
        url: '',
      };
    }
  }, [dataForm]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeInput = (
    key: keyof SendUser,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (dataForm) {
      const value = e.target.value;

      dataForm.sendUser = dataForm.sendUser
        ? dataForm.sendUser
        : { message: '', url: '' };

      dataForm.sendUser[key] = value;
      dispatch({
        type: 'updateForm',
        data: { dataForm: { ...dataForm } },
      });
    }
  };

  return (
    <S.Container>
      <S.HeaderContent>
        <S.HeaderOptions>
          <S.TitleTypography>URL</S.TitleTypography>
          <S.TextContainer>
            <S.StyledMttTextField
              variant="outlined"
              label="URL"
              maxRows={5}
              fullWidth
              value={dataForm?.sendUser?.url || ''}
              onChange={(e) => handleChangeInput('url', e)}
              disabled={!!dataForm?.sendUser?.message}
            />
          </S.TextContainer>

          <S.MessageInputContainer>
            <S.TitleTypography>{getTranslation('htmlCode')}</S.TitleTypography>
            <S.TextContainer>
              <S.StyledMttTextField
                variant="outlined"
                label={getTranslation('parameters')}
                fullWidth
                value={dataForm?.sendUser?.message || ''}
                onChange={(e) => handleChangeInput('message', e)}
                rows={5}
                maxRows={5}
                multiline
                disabled={!!dataForm?.sendUser?.url}
              />
            </S.TextContainer>
          </S.MessageInputContainer>
        </S.HeaderOptions>
      </S.HeaderContent>
    </S.Container>
  );
}
