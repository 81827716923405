import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
	
  .MuiIconButton-root {
		justify-content: flex-start;
    display: flex;
    align-items: center;
		:hover {
			background-color: #fff;
		}
  }

  .MuiTypography-subtitle1, p {
    margin-left: 12px;
  }
`;
