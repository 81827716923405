import EIntentType from 'enums/EIntentType';
import { DataForm, Output } from 'models/DataFlow';
import DataFormModel from 'models/DataFormModel';
import { slugify } from 'utils/String';
import IntentController from './IntentController';
export default class CreateIntentController extends IntentController {
  createIntent(dataForm: DataForm) {
    const dataFormModel = new DataFormModel(dataForm);

    if (dataFormModel.isParent()) {
      this.createIntentParent(dataFormModel);
    } else {
      this.createIntentConventional(dataFormModel);
    }
  }

  createIntentParent(dataFormModel: DataFormModel) {
    dataFormModel.outputs = [
      ...dataFormModel.getOutputs(),
      ...[{ title: 'Outros' }],
    ];
    const amountOutput = dataFormModel.getOutputAmount();
    const dataForm = dataFormModel.toDataForm();

    let position = this.defineNodePosition();
    if (!position) position = { x: 0, y: 0 };

    const parentId = this.addNode(amountOutput, dataForm, position);

    const outputs = dataFormModel.outputs;
    const outputsUpdated = this.createIntentChildren(
      outputs,
      dataFormModel,
      parentId,
      position
    );
    const { drawflow } = this.editorFlow.drawflow;
    drawflow.Home.data[parentId].data.outputs = outputsUpdated;
    this.editorFlow.nodeId = parentId;
  }

  createIntentChildren(
    outputs: Output[],
    dataForm: DataFormModel,
    parentId: number,
    parentPosition: { x: number; y: number }
  ) {
    const outputResult = [...outputs];

    for (let index = 0; index < outputResult.length; index++) {
      const output = outputResult[index];
      const title = output.title;
      const description = output.description || '';
      const intentType =
        title === 'Outros' ? EIntentType.Others : dataForm.getChildType();
      const groupId = dataForm.groupId;
      const name = dataForm.name + '-' + slugify(title.toLowerCase().trim());
      const dataFormChild = new DataFormModel({
        parentId,
        name,
        description,
        intentType,
        groupId,
        inputs: { userSentences: ['', '', ''], variables: [] },
        dataBlockly: {
          lastVersion: 1,
          payload: '// #PARSER#CONNECTOR#Saída#\n',
          xml: '<xml xmlns="https://developers.google.com/blockly/xml"><block type="connector" id="cAsx5InyFQ]_.i,bM.[~" x="150" y="150"><field name="TEXT">Saída</field></block></xml>',
          compiledPayload: '',
        },
      });
      output.nameIntent = name;
      output.outputid = this.createIntentChild(
        dataFormChild,
        parentId,
        index,
        parentPosition
      ).toString();
    }
    return outputResult;
  }

  createIntentConventional(dataFormModel: DataFormModel) {
    let amountOutput = dataFormModel.getOutputAmount();
    if (
      amountOutput === 0 &&
      !dataFormModel.hasMultipleOutputs() &&
      !dataFormModel.isFlexBlockExit() &&
      !dataFormModel.isFlexBlock()
    ) {
      dataFormModel.outputs = [{ title: 'Saída' }];
      amountOutput = 1;

      if (dataFormModel.dataBlockly)
        dataFormModel.dataBlockly.payload = '// #PARSER#CONNECTOR#Saída#\n';
    }

    if (
      dataFormModel.intentType === EIntentType.SendDocument ||
      dataFormModel.intentType === EIntentType.SimpleQuestion
    ) {
      dataFormModel.outputs?.push({ title: 'Outros' });
      amountOutput++;
    }

    const dataForm = dataFormModel.toDataForm();

    let position = this.defineNodePosition();
    if (!position) position = { x: 0, y: 0 };
    const nodeId = this.addNode(amountOutput, dataForm, position);

    this.editorFlow.nodeId = nodeId;
  }

  goToNode(posX: number, posY: number, offsetX: number, offsetY: number) {
    const newX = offsetX / 2 + posX * 0.1;
    const newY = offsetY / 2 + posY * 0.1;
    this.handleZoomAll();

    if (this.editorFlow) {
      this.editorFlow.canvas_x = -newX;
      this.editorFlow.canvas_y = -newY;
      if (this.editorFlow.precanvas) {
        this.editorFlow.precanvas.style.transform = `translate(${this.editorFlow.canvas_x}px, 
          ${this.editorFlow.canvas_y}px) 
          scale(${this.editorFlow.zoom}) `;
      }
    }
  }

  handleZoomAll = () => {
    if (this.editorFlow) {
      if (this.editorFlow.zoom < 0.6) {
        this.editorFlow.zoom_in();
        this.handleZoomAll();
      } else if (this.editorFlow.zoom > 0.7) {
        this.editorFlow.zoom_out();
        this.handleZoomAll();
      }
    }
  };

  defineNodePosition = () => {
    if (
      this.editorFlow &&
      this.editorFlow.precanvas &&
      this.editorFlow.pos_x &&
      this.editorFlow.pos_y
    ) {
      const position = {
        x:
          this.editorFlow.pos_x +
          this.editorFlow.pos_x * Math.random() +
          this.editorFlow.precanvas.clientWidth /
            (this.editorFlow.precanvas.clientWidth * this.editorFlow.zoom) -
          this.editorFlow.precanvas.getBoundingClientRect().x *
            (this.editorFlow.precanvas.clientWidth /
              (this.editorFlow.precanvas.clientWidth * this.editorFlow.zoom)),
        y:
          this.editorFlow.pos_y +
          this.editorFlow.pos_y * Math.random() +
          this.editorFlow.precanvas.clientHeight /
            (this.editorFlow.precanvas.clientHeight * this.editorFlow.zoom) -
          this.editorFlow.precanvas.getBoundingClientRect().y *
            (this.editorFlow.precanvas.clientHeight /
              (this.editorFlow.precanvas.clientHeight * this.editorFlow.zoom)),
      };
      return position;
    }
  };
}
