import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const StyledGridButtons = styled(MttGrid).attrs({
  className: 'grayButtonMaterial',
})`
  margin: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiButton-containedPrimary {
    height: 56px;
  }
`;

export const StyledRightButtons = styled(MttGrid)`
  width: 400px;

  display: flex;
  justify-content: space-between;
`;
