import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const ContentLineEntity = styled(MttGrid)`
  width: 100%;
  height: 56px;

  border-bottom: 2px solid #cfcfcf;

  background-color: #f9f9f9;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f2f2f2;
  }

  > div {
    display: flex;
  }
`;

export const GridNameEntity = styled(MttGrid)`
  width: 300px;
  margin-left: 8px;
`;

export const GridTypeEntity = styled(MttGrid)`
  color: #aeaeae;
`;

export const TrashIcon = styled.div`
  color: #aeaeae;
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
  width: 100%;
`;
