import { IAppContextActions } from 'contexts/App/types';
import { json } from 'services/RequestService';
import { IBaseResponse } from 'services/RequestService/Response';
import {
  IGetAllGroupsResponse,
  IGetGroupRequest,
  IGetGroupResponse,
  IUpdateGroupRequest,
} from './types';

const baseUrl = 'groups';
const companyUrl = 'company';

export async function GetGroup(
  { groupName, companyName, projectName }: IGetGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetGroupResponse>> {
  const action = `${companyUrl}/${companyName}/project/${projectName}/${baseUrl}/${groupName}`;
  return await json('GET', action, dispatch);
}

export async function GetAllGroups(
  companyName: string,
  project: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetAllGroupsResponse>> {
  const action = `${companyUrl}/${companyName}/project/${project}/${baseUrl}`;
  return await json<IGetAllGroupsResponse>('GET', action, dispatch);
}

export async function CreateGroup(
  request: IUpdateGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IUpdateGroupRequest>> {
  const action = `${companyUrl}/${request.company}/project/${request.project}/${baseUrl}`;
  return await json<IUpdateGroupRequest>('POST', action, dispatch, request);
}

export async function UpdateGroup(
  request: IUpdateGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IUpdateGroupRequest>> {
  const action = `${companyUrl}/${request.company}/${baseUrl}/${request._id}`;
  return await json<IUpdateGroupRequest>('PATCH', action, dispatch, request);
}

export async function DeleteGroup(
  { groupName, companyName }: IGetGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IUpdateGroupRequest>> {
  const action = `${companyUrl}/${companyName}/${baseUrl}/${groupName}`;
  return await json<IUpdateGroupRequest>('DELETE', action, dispatch);
}
