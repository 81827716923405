import Drawflow from 'drawflow';
import { DataFlow } from 'models/DataFlow';

export default class GetBlockOnFlowController {
  protected editorFlow: Drawflow;

  constructor(editorFlow: Drawflow) {
    this.editorFlow = editorFlow;
  }

  getBlock(ids: number[]) {
    const data = this.editorFlow.drawflow.drawflow.Home.data;
    const dataResult: DataFlow = {};
    for (const id of ids) {
      const node = data[id];
      if (node) {
        dataResult[id] = { ...node };
      }
    }
    return dataResult;
  }
}
