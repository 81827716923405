import styled from 'styled-components';

const styles = {
  Container: styled.div`
  `,
  VariablesBtn: styled.button`
    background: #0e103b;
    border: none;
    border-radius: 3rem;
    color: white;
    height: 1rem;
    padding: 1rem;
  `,
  VariablesLine: styled.div`
    align-items: center;
    display: flex;
  `,
  MultiVariablesContainer: styled.div`
    height: 30vh;
    overflow-y: scroll;
  `,
}

export default styles;
