import React from 'react';

import Button from '@material-ui/core/Button/Button';

import { IButtomPropsV4 } from './types';

const MttButton: React.FC<IButtomPropsV4> = (props: IButtomPropsV4) => {
  const { children } = props;

  return <Button {...props}> {children} </Button>;
};

export default MttButton;
