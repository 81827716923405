import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttTextField from 'components/Material/MttTextField/MttTextFIeld';
import MttTypography from 'components/Material/MttTypography/MttTypography';

export const Container = styled(MttGrid)`
  margin: 16px;
`;

export const HeaderContent = styled(MttGrid)`
  width: calc(100% - 32px);
  min-height: 128px;

  display: flex;
  background-color: #f2f2f2;
  padding: 16px;

  margin-top: 8px;
  border-radius: 4px;

  .MuiInputBase-input {
    background-color: #ffffff;
  }
`;

export const HeaderOptions = styled(MttGrid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  margin-top: 16px;
`;

export const MessageInputContainer = styled(MttGrid)`
  margin-top: 16px;
  .MuiOutlinedInput-multiline {
    background: #ffffff;
    display: flex;
    padding-bottom: 4px;
    height: 100px;
    margin-bottom: 16px;
  }
`;

export const StyledMttTextField = styled(MttTextField)``;

export const TitleTypography = styled(MttTypography).attrs({
  variant: 'h6',
  color: 'primary',
})``;
