import styled from 'styled-components';

const styles = {
  Container: styled.div`
  `,
  FirstLine: styled.div`
    display: flex;
  `,
  DescriptionInput: styled.input`
    
    input {
      width: 80vw;
    }
  `
}

export default styles;
