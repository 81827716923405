import { useState, useEffect } from 'react';

import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TableContainer,
  Paper,
  Button,
  CircularProgress,
  TextField,
  AppBar,
  Toolbar,
} from '@material-ui/core';

import {
  ButtonContainer,
  ModalTitle,
  useStyles,
  AutoCompleteWrap,
  FieldContainer,
} from '../styles';

import CloseIcon from '@material-ui/icons/Close';
import { IBot, IOrganization } from 'services/OrganizationServices/types';
import { UpdateOrganization } from 'services/OrganizationServices';
import { PermissionTableRow } from './PermissionTableRow';
import {
  FunctionPermission,
  IPermission,
  IPermissionFunction,
} from 'services/PermissionServices/types';

import { useApp } from 'contexts/App/appContext';
import { Autocomplete } from '@material-ui/lab';
import { useFlow } from 'contexts/Flow/flowContext';
import useTranslator from 'utils/hooks/Translator';
import { Container, ContainerDialog } from './styles';

type Props = {
  organization: IOrganization | undefined;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  userEmail: string;
  setUserEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const defaultFunctions: IPermissionFunction = {
  secrets: false,
  publish: false,
  edit: false,
  libraries: false,
  entities: false,
  conversations: false,
};

export function ModalPermissionEdit({
  show,
  setShow,
  organization,
  userEmail,
  setUserEmail,
}: Props) {
  const { toastNotification } = useFlow();
  const { dispatch: dispatchApp } = useApp();
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [userBots, setUserBots] = useState<string[]>([]);
  const [organizationBots, setOrganizationsBots] = useState<IBot[]>([]);
  const { getTranslation } = useTranslator();

  useEffect(() => {
    const userPermissions = organization?.users.find(
      (user) => user.email === userEmail
    );
    const permissions = userPermissions?.permissions ?? [];
    const currentUserBots = permissions.map((permission) => permission.bot);

    setPermissions(permissions);
    setUserBots(currentUserBots);
  }, [organization?.users, userEmail]);

  useEffect(() => {
    setOrganizationsBots(organization?.bots ?? []);
  }, [organization?.bots]);

  function getDifferentBots() {
    const organizationBotsStr = organizationBots.map(
      (organizationBot) => organizationBot.name
    );

    const differentBots = organizationBotsStr
      .filter((x) => !userBots.includes(x))
      .concat(userBots.filter((x) => !organizationBotsStr.includes(x)));

    return differentBots.sort() ?? [];
  }

  function handleClose() {
    setShow(false);
  }

  const [selectedBot, setSelectedBot] = useState<string>();

  function addBotInUser() {
    if (selectedBot) {
      setUserBots((prev) => [...prev, selectedBot]);
      updateUserPermissions(selectedBot);
    }

    setSelectedBot(undefined);
  }

  function hasBotInPermissions(botName: string) {
    return permissions.some((permission) => permission.bot === botName);
  }

  function updateUserPermissions(selectedBot: string) {
    const clonePermissions = [...permissions];

    if (!hasBotInPermissions(selectedBot)) {
      clonePermissions.push({
        bot: selectedBot,
        functions: Object.assign({}, defaultFunctions),
      });
    }

    setPermissions(clonePermissions);
  }

  function handleChangePermission(
    permission: FunctionPermission,
    permissionIndex: number,
    checked: boolean
  ) {
    const clonePermissions = [...permissions];
    if (clonePermissions[permissionIndex].functions === null) {
      clonePermissions[permissionIndex].functions = Object.assign(
        {},
        defaultFunctions
      );
    }

    clonePermissions[permissionIndex].functions[permission] = checked;
    setPermissions(clonePermissions);
  }

  function removeBot(botName: string) {
    if (hasBotInPermissions(botName)) {
      const clonePermissions = [...permissions];
      const botIndex = clonePermissions.findIndex(
        (permission: IPermission) => permission.bot === botName
      );

      clonePermissions.splice(botIndex, 1);
      setPermissions(clonePermissions);

      const cloneUserBots = [...userBots];
      const userBotIndex = cloneUserBots.findIndex(
        (userBot) => userBot === botName
      );
      cloneUserBots.splice(userBotIndex, 1);
      setUserBots(cloneUserBots);
    }
  }

  const [fetching, setFetching] = useState(false);

  async function handleSave() {
    setFetching(true);

    const request = getRequestOrganization();
    const { success, message } = await remoteSaveOrganization(
      request
    ).finally();

    if (success) onSuccess();
    else onError(message);

    setFetching(false);
  }

  function getRequestOrganization() {
    const cloneOrganization = Object.assign({}, organization);
    const userPermissionIndex = cloneOrganization.users.findIndex(
      (user) => user.email === userEmail
    );

    if (userPermissionIndex >= 0) {
      cloneOrganization.users[userPermissionIndex].permissions = permissions;
    }

    return {
      organization: cloneOrganization,
    };
  }

  async function remoteSaveOrganization(request: any) {
    request._id = organization?._id;

    const response = await UpdateOrganization(request, dispatchApp);
    return {
      success: response.Success,
      message: response.Message,
    };
  }

  function onSuccess() {
    toastNotification('success', getTranslation('toast.success.update'));
    setUserEmail('');
    setShow(false);
  }

  function onError(message?: string) {
    toastNotification('error', message ?? getTranslation('toast.error.update'));
  }

  const classes = useStyles();

  if (!show) return <></>;

  return (
    <Dialog
      className="ModalPermissions"
      open={true}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog} style={{ minHeight: 'none' }}>
        <AppBar>
          <Toolbar className={classes.toolbar}>
            <ModalTitle style={{ fontWeight: 600 }}>
              {getTranslation('modal.managePermissions.editPermissions')}{' '}
              {userEmail}
            </ModalTitle>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Container>
          <AutoCompleteWrap>
            <Autocomplete
              value={selectedBot ?? ''}
              options={getDifferentBots()}
              style={{ maxWidth: '256px' }}
              noOptionsText={getTranslation('noResults')}
              renderOption={(option) => <div>{option}</div>}
              onChange={({ target }: any) => {
                setSelectedBot(target.textContent);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={getTranslation('agent')}
                  placeholder={getTranslation('selectOne')}
                />
              )}
            />
            <Button
              style={{ letterSpacing: 2, borderRadius: 8 }}
              variant="contained"
              color="primary"
              disabled={!selectedBot}
              onClick={() => {
                addBotInUser();
              }}
            >
              {getTranslation('modal.editOrganization.addAgent')}
            </Button>
          </AutoCompleteWrap>
          <div
            style={{
              maxHeight: '55vh',
              overflowY: 'auto',
            }}
          >
            <FieldContainer>
              <TableContainer component={Paper}>
                <Table size="small" style={{ width: '100%' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{getTranslation('delete')}</TableCell>
                      <TableCell>{getTranslation('agent')}</TableCell>
                      <TableCell>{getTranslation('functions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissions?.map((permission, permissionIndex) => (
                      <PermissionTableRow
                        key={`${permissionIndex}-${permission.bot}`}
                        permission={{
                          ...permission,
                          functions: !!permission.functions
                            ? permission.functions
                            : defaultFunctions,
                        }}
                        index={permissionIndex}
                        handleChangePermission={handleChangePermission}
                        handleRemoveBot={removeBot}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </FieldContainer>
          </div>
          <ButtonContainer>
            <Button
              style={{ letterSpacing: 2, borderRadius: 8 }}
              variant="contained"
              color="primary"
              onClick={() => handleSave().finally()}
            >
              {getTranslation('save')}
              {fetching && <CircularProgress size={20} color="inherit" />}
            </Button>
          </ButtonContainer>
        </Container>
      </ContainerDialog>
    </Dialog>
  );
}
