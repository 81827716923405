import React from "react";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/webpack-resolver";

import { TransitionProps } from "@material-ui/core/transitions";
import { createStyles, Dialog,  IconButton,  Slide, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { ICodeEditorProps } from "./types";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CodeEditor = (props:ICodeEditorProps) => {
  const {showEditor, code, handleClose} = props
  return (
    <Dialog
      open={showEditor}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
      aria-describedby="alert-dialog-slide-description"
      aria-labelledby="customized-dialog-title"
      style={{zIndex:99999}}
    >
      <DialogTitle id="customized-dialog-title" 
       onClose={handleClose}
      >
          Código Javascript
        </DialogTitle>
      <AceEditor
        mode="javascript"
        theme="dracula"
        name="editor"
        height={"80vh"}
        width={"100%"}
        value={code}
        readOnly={true}
      />
    </Dialog>
  );
};

export default CodeEditor;
