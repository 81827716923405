import React, { useEffect, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Dialog,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { useFlow } from 'contexts/Flow/flowContext';
import { GetOrganizations } from 'services/OrganizationServices';

import EditIcon from '@material-ui/icons/Edit';

import {
  IOrganization,
  IUserPermissions,
} from 'services/OrganizationServices/types';

import * as S from './styles';

import { ModalPermissionEdit } from './ModalPermissionEdit';
import { useApp } from 'contexts/App/appContext';
import useTranslator from 'utils/hooks/Translator';

interface OrganizationShow {
  users: string[];
  bots: string[];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalManagePermission() {
  const { state, dispatch } = useFlow();
  const { dispatch: dispatchApp } = useApp();
  const classes = S.useStyles();
  const { getTranslation } = useTranslator();
  const [organizations, setOrganizations] = useState<IOrganization[]>();
  const [currentOrganizations, setCurrentOrganizations] = useState<
    IOrganization[]
  >([]);

  const [currentOrganization, setCurrentOrganization] =
    useState<IOrganization>();

  const [showModalEditOrganization, setShowModalEditOrganization] =
    useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState<string>();

  const getOrganizations = async () => {
    const response = await GetOrganizations(dispatchApp);
    if (response.Success) {
      const orgs = response.Data.data;
      let visualOrgs: OrganizationShow[] = [];
      Object.assign(visualOrgs, orgs);
      setOrganizations(orgs);
    }
  };

  useEffect(() => {
    if (state.openModalManagePermission) {
      getOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.openModalManagePermission]);

  useEffect(() => {
    setCurrentOrganizations(organizations ?? []);
  }, [organizations]);

  function handleClose() {
    setCurrentOrganization(undefined);
    dispatch({ type: 'closeModalManagePermission' });
  }

  function openEditOrganization(
    user: IUserPermissions,
    organization: IOrganization | undefined
  ) {
    if (!!organization) setCurrentOrganization(organization);

    setCurrentUserEmail(user.email);
    setShowModalEditOrganization(true);
  }

  if (!state.openModalManagePermission) return <></>;

  return (
    <>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <S.ContainerDialog className={classes.dialog}>
          <AppBar>
            <Toolbar className={classes.toolbar}>
              <S.ModalTitle style={{ fontWeight: 600 }}>
                {getTranslation('modal.managePermissions.title')}
              </S.ModalTitle>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <S.AccordionContainer>
            {currentOrganizations?.map((organization, index) => (
              <Accordion TransitionProps={{ unmountOnExit: true }} key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{organization.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table size="small" style={{ width: '100%' }}>
                    <TableBody>
                      {organization.users?.map((user, index) => (
                        <TableRow key={index}>
                          <TableCell
                            component="th"
                            scope="key"
                            style={{ width: '100%' }}
                          >
                            {user.email}
                          </TableCell>
                          <TableCell className="tableCell-Button">
                            <IconButton
                              edge="end"
                              color="default"
                              onClick={() => {
                                openEditOrganization(user, organization);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            ))}
          </S.AccordionContainer>
        </S.ContainerDialog>
      </Dialog>

      {!!currentUserEmail && currentUserEmail !== '' && (
        <ModalPermissionEdit
          organization={currentOrganization}
          show={showModalEditOrganization}
          setShow={setShowModalEditOrganization}
          userEmail={currentUserEmail}
          setUserEmail={setCurrentUserEmail}
        />
      )}
    </>
  );
}
