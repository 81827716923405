import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { ContainerLoading } from './styles';
import { LoadWrapperProps } from './types';

const LoadWrapper = (props: LoadWrapperProps) => {
  const { loading } = props;
  if (!loading) return <></>;

  return (
    <ContainerLoading>
      <CircularProgress />
    </ContainerLoading>
  );
};

export default LoadWrapper;
