import { Typography, TextField } from '@material-ui/core';
import { ISendUser } from 'contexts/Flow/types';
import { useForm } from 'contexts/Form/formContext';
import DataFormModel from 'models/DataFormModel';
import { useEffect, useState } from 'react';
import useTranslator from 'utils/hooks/Translator';
import { getTranslationKeys } from 'utils/i18n/types';

export function SendUser({ sendUser }: { sendUser: ISendUser }) {
  const { state, updateForm } = useForm();
  const { getTranslation } = useTranslator();
  const { dataForm } = state;
  const [dataFormModel, setDataFormModel] = useState<DataFormModel | null>(
    null
  );
  const { items } = sendUser;

  useEffect(() => {
    if (!dataForm) return;

    const model = new DataFormModel(dataForm);
    setDataFormModel(model);
  }, [dataForm]);

  const handleChangeSendUserMessage = (value: string, index: number) => {
    if (!dataForm || !dataFormModel) return;
    if (
      dataFormModel.isAwaitUserIput() &&
      dataForm.inputs &&
      dataForm.inputs.variables[0]
    ) {
      dataForm.inputs.variables[0].questions[index] = value;
    } else if (dataForm.sendUser) dataForm.sendUser.message = value;

    updateForm(dataForm);
  };

  return (
    <>
      {items.map((item, index) => {
        const { component, label, maxLength, required } = item;
        const multiline = component === 'multiline';
        const firstPosQuestion = dataForm?.inputs?.variables[0];

        const value = dataFormModel?.isAwaitUserIput()
          ? firstPosQuestion?.questions[index] ?? ''
          : dataForm?.sendUser?.message ?? '';

        return (
          <div key={index} style={{ marginTop: 16 }}>
            <Typography variant="subtitle2">{getTranslation(label as getTranslationKeys)}</Typography>
            <TextField
              required={required}
              inputProps={{ maxLength }}
              fullWidth
              multiline={multiline}
              minRows={multiline ? 3 : 1}
              variant={multiline ? 'outlined' : 'standard'}
              value={value}
              onChange={(e) =>
                handleChangeSendUserMessage(e.target.value, index)
              }
            />
          </div>
        );
      })}
    </>
  );
}
