export function getArrayConnectors(text: string) {
    let arrayConnectors: string[] = [];
    let n, lineArray;

    const lines = text.split(/\r?\n/);

    lines.forEach((line) => {
        n = line.search('[// #PARSER#]');

        if (n > -1) {
            line = line.substring(n, line.length);
            lineArray = line.split('#');

            if (lineArray[2] === 'CONNECTOR') {
                arrayConnectors.push(lineArray[3]);
            }
        }
    });

    return arrayConnectors;
}