import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'contexts/Form/formContext';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import * as S from './styles';
import { ISentencesProps } from './types';
import MttButton from 'components/Material/MttButton/MttButton';

export default function RulesBlockOutputsSentences(props: ISentencesProps) {
  const { index, choice, showRemoveButton } = props;
  const { state, dispatch } = useForm();
  const { dataForm, formErrors } = state;
  const [inputValue, setInputValue] = useState<string>('');
  const [values /*,setValues*/] = useState(choice.userSentences || ['']);

  const [prefixVars, setPrefixVars] = useState(
    choice?.rules?.map((rule) => rule.variable.substring(0, 5)) || ['vars.']
  );
  const [varValues, setVarValues] = useState(
    choice?.rules?.map((rule) =>
      rule.variable.substring(5, rule.variable.length)
    ) || ['resposta']
  );

  const load = useCallback(() => {
    if (dataForm && choice) {
      if (!choice.userSentences) {
        choice.userSentences = [''];
      }

      if (!choice.rules) {
        choice.rules = [{ operator: '', value: '', variable: '' }];
      }

      if (!!choice.rulesConditionals) {
        choice.rulesConditionals = [];
      }

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choice, index]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeTitle = (newTitle: string) => {
    if (dataForm && dataForm.outputs && dataForm.outputs[index]) {
      if (choice.title !== 'Outros') {
        values[0] = newTitle;
        choice.title = newTitle;
        dataForm.outputs[index] = choice;

        if (dataForm) {
          dispatch({
            type: 'updateForm',
            data: {
              dataForm: {
                ...dataForm,
              },
            },
          });
        }
      }
    }
  };

  const handleChangeField = (
    field: 'variable' | 'operator' | 'value',
    ruleIndex: number,
    newValue: string
  ) => {
    if (
      dataForm &&
      dataForm.outputs &&
      choice.rules &&
      choice.rules[ruleIndex]
    ) {
      choice.rules[ruleIndex][field] = newValue;
      dataForm.outputs[index] = choice;

      if (dataForm) {
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  };

  const handleChangeConditional = (
    conditionalIndex: number,
    newValue: 'and' | 'or'
  ) => {
    if (
      dataForm &&
      dataForm.outputs &&
      choice.rulesConditionals &&
      choice.rulesConditionals[conditionalIndex]
    ) {
      choice.rulesConditionals[conditionalIndex].value = newValue;
      dataForm.outputs[index] = choice;

      if (dataForm) {
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  };

  const handleDeleteOption = () => {
    if (!!dataForm && dataForm.outputs) {
      dataForm.outputs.splice(
        dataForm.outputs.findIndex((c) => c.title === choice.title),
        1
      );

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleDeleteRule = (ruleIndex: number) => {
    if (!!dataForm && !!choice && choice.rules) {
      choice.rules.splice(ruleIndex, 1);

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleInsertValue = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (choice.title !== 'Outros') {
      if (event.keyCode === 13 || event.keyCode === 9) {
        if (inputValue !== '') {
          values.push(inputValue);

          if (!!dataForm && dataForm.outputs) {
            dataForm.outputs[index].userSentences = values;
            dispatch({
              type: 'updateForm',
              data: {
                dataForm: {
                  ...dataForm,
                },
              },
            });
          }
        }
        setInputValue('');
      }
    }
  };

  const handleAddChoice = () => {
    if (choice && choice.rules)
      choice.rules.push({ operator: '', value: '', variable: '' });

    if (!!choice.rules && choice?.rules.length > 1) {
      choice?.rulesConditionals?.push({ value: 'and' });
    }

    if (dataForm && dataForm.outputs && dataForm.outputs[index]) {
      dataForm.outputs[index] = choice;

      if (dataForm) {
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  };

  const handleChangeVarsPrefix = (
    prefix: boolean,
    varIndex: number,
    newValue: string
  ) => {
    let newVarValue = '';

    if (prefix) {
      const currVarAfterPrefix = varValues[varIndex];
      newVarValue = newValue + currVarAfterPrefix;

      const currVars = prefixVars;
      currVars[varIndex] = newValue;

      setPrefixVars(currVars);
    } else {
      const currentPrefix = prefixVars[varIndex];
      newVarValue = currentPrefix + newValue;
      varValues[varIndex] = newValue;

      const currValues = varValues;
      currValues[varIndex] = newValue;

      setVarValues(currValues);
    }

    if (
      dataForm &&
      dataForm.outputs &&
      dataForm.outputs[index] &&
      choice.rules
    ) {
      choice.rules[varIndex].variable = newVarValue;
      dataForm.outputs[index] = choice;

      if (dataForm) {
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  };

  const showRemoveRuleButton =
    !!choice && !!choice.rules ? choice.rules.length > 1 : false;

  return (
    <S.Container>
      {choice.title !== 'Outros' && showRemoveButton ? (
        <IconButton
          onClick={() => handleDeleteOption()}
          style={{ alignSelf: 'flex-end' }}
        >
          <DeleteIcon style={{ alignSelf: 'center' }} htmlColor="#0e1039" />
        </IconButton>
      ) : (
        <></>
      )}
      <S.ContainerOptionHeader>
        <S.OptionIndexAndTip>
          <S.RulesRow>
            <S.Rules>
              <S.TextContainer>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={choice.title}
                  onChange={(e) => handleChangeTitle(e.target.value)}
                  label="Título da Saída"
                  helperText={formErrors[`outputs[${index}].title`]}
                  error={!!formErrors[`outputs[${index}].title`]}
                />
              </S.TextContainer>
            </S.Rules>
            <S.ContainerButton>
              <MttButton
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddChoice}
              >
                adicionar regra
              </MttButton>
            </S.ContainerButton>
          </S.RulesRow>
        </S.OptionIndexAndTip>
      </S.ContainerOptionHeader>
      <S.ContainerOptionContent>
        <S.RulesContainer>
          {choice?.rules?.map((rule, index) => (
            <div key={index}>
              <S.RulesRow>
                <S.Rules>
                  <S.TextContainer>
                    <Select
                      value={prefixVars[index] || 'vars.'}
                      // style={{ maxWidth: 'none' }}
                      fullWidth
                      variant="outlined"
                      onChange={(e) =>
                        handleChangeVarsPrefix(
                          true,
                          index,
                          String(e.target.value)
                        )
                      }
                    >
                      <MenuItem value={'vars.'}>vars.</MenuItem>
                      <MenuItem value={'user.'}>user.</MenuItem>
                    </Select>
                  </S.TextContainer>
                  <S.TextContainer>
                    {prefixVars[index] === 'vars.' ? (
                      <TextField
                        value={varValues[index]}
                        variant="outlined"
                        onChange={(e) =>
                          handleChangeVarsPrefix(false, index, e.target.value)
                        }
                      />
                    ) : (
                      <Select
                        value={varValues[index]}
                        variant="outlined"
                        fullWidth
                        onChange={(e) =>
                          handleChangeVarsPrefix(
                            false,
                            index,
                            String(e.target.value)
                          )
                        }
                      >
                        <MenuItem value={'name'}>name</MenuItem>
                        <MenuItem value={'nickname'}>nickname</MenuItem>
                        <MenuItem value={'email'}>email</MenuItem>
                        <MenuItem value={'phoneNumber'}>phoneNumber</MenuItem>
                        <MenuItem value={'cpf'}>cpf</MenuItem>
                        <MenuItem value={'birthDate'}>birthDate</MenuItem>
                        <MenuItem value={'address.street'}>
                          address.street
                        </MenuItem>
                        <MenuItem value={'address.number'}>
                          address.number
                        </MenuItem>
                        <MenuItem value={'address.neighborhood'}>
                          address.neighborhood
                        </MenuItem>
                        <MenuItem value={'address.city'}>address.city</MenuItem>
                        <MenuItem value={'address.state'}>
                          address.state
                        </MenuItem>
                        <MenuItem value={'address.country'}>
                          address.country
                        </MenuItem>
                        <MenuItem value={'address.zipCode'}>
                          address.zipCode
                        </MenuItem>
                        <MenuItem value={'userData1'}>userData1</MenuItem>
                        <MenuItem value={'userData2'}>userData2</MenuItem>
                        <MenuItem value={'userData3'}>userData3</MenuItem>
                        <MenuItem value={'userData4'}>userData4</MenuItem>
                        <MenuItem value={'userData5'}>userData5</MenuItem>
                      </Select>
                    )}
                  </S.TextContainer>
                  <S.TextContainer>
                    <FormControl variant="outlined">
                      <InputLabel>Regra de validação</InputLabel>
                      <Select
                        value={rule.operator}
                        variant="outlined"
                        label="Regra de validação"
                        fullWidth
                        onChange={(e) =>
                          handleChangeField(
                            'operator',
                            index,
                            String(e.target.value)
                          )
                        }
                      >
                        <MenuItem value={'equals'}>
                          Correspondência Exata
                        </MenuItem>
                        <MenuItem value={'contains'}>Contém</MenuItem>
                        <MenuItem value={'isNotNull'}>Não é nulo</MenuItem>
                        <MenuItem value={'isNull'}>É nulo</MenuItem>
                      </Select>
                    </FormControl>
                  </S.TextContainer>
                  <S.TextContainer style={{ maxWidth: '40%' }}>
                    <TextField
                      value={rule.value}
                      onChange={(e) =>
                        handleChangeField('value', index, e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                      placeholder={`Valor (opcional)`}
                      onKeyDown={(event) => handleInsertValue(event)}
                      disabled={rule.operator === 'isNull'}
                    />
                  </S.TextContainer>
                </S.Rules>
                {showRemoveRuleButton && (
                  <Tooltip title="Remover regra">
                    <IconButton
                      onClick={() => handleDeleteRule(index)}
                      style={{ justifySelf: 'flex-end' }}
                    >
                      <HighlightOffIcon
                        style={{ alignSelf: 'center' }}
                        htmlColor="#0e1039"
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </S.RulesRow>
              {!!choice.rules &&
                !!choice?.rules[index + 1] &&
                !!choice.rulesConditionals &&
                !!choice.rulesConditionals[index] && (
                  <S.ConditionalContainer>
                    <FormControl variant="outlined">
                      <InputLabel>Condição</InputLabel>
                      <Select
                        value={choice.rulesConditionals[index].value || 'and'}
                        variant="outlined"
                        fullWidth
                        label="Condição"
                        onChange={(e) =>
                          handleChangeConditional(
                            index,
                            e.target.value as 'and' | 'or'
                          )
                        }
                      >
                        <MenuItem value={'and'}>E</MenuItem>
                        <MenuItem value={'or'}>OU</MenuItem>
                      </Select>
                    </FormControl>
                  </S.ConditionalContainer>
                )}
            </div>
          ))}
        </S.RulesContainer>
      </S.ContainerOptionContent>
    </S.Container>
  );
}
