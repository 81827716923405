import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const styles = {
  Container: styled.div``,
  VariablesBtn: styled.button`
    background: #0e103b;
    border: none;
    border-radius: 3rem;
    color: white;
    height: 1rem;
    padding: 1rem;
  `,
  VariablesLine: styled.div`
    align-items: center;
    display: flex;
  `,
  MultiVariablesContainer: styled.div`
    height: 25vh;
    overflow-y: scroll;
    margin: 16px;
  `,
};

export const StyledTitleGrid = styled(MttGrid)`
  display: flex;
  justify-content: space-between;
  padding: 16px 42px 8px 42px;
`;
