// This file was automatically generated.  Do not modify.

const Blockly = require('blockly');

Blockly.Msg['ADD_COMMENT'] = 'Adicionar comentário';
Blockly.Msg['CANNOT_DELETE_VARIABLE_PROCEDURE'] =
    "Não se pode deletar a variável '%1' porque é parte da definição da função '%2'";
Blockly.Msg['CHANGE_VALUE_TITLE'] = 'Mudar valor:';
Blockly.Msg['CLEAN_UP'] = 'Limpar blocos';
Blockly.Msg['COLLAPSED_WARNINGS_WARNING'] =
    'Bloqueios recolhidos contêm avisos.';
Blockly.Msg['COLLAPSE_ALL'] = 'Colapsar Blocos';
Blockly.Msg['COLLAPSE_BLOCK'] = 'Colapsar Bloco';
Blockly.Msg['COLOUR_BLEND_COLOUR1'] = 'cor 1';
Blockly.Msg['COLOUR_BLEND_COLOUR2'] = 'cor 2';
Blockly.Msg['COLOUR_BLEND_HELPURL'] =
    'https://meyerweb.com/eric/tools/color-blend/#:::rgbp'; // untranslated
Blockly.Msg['COLOUR_BLEND_RATIO'] = 'proporção';
Blockly.Msg['COLOUR_BLEND_TITLE'] = 'misturar';
Blockly.Msg['COLOUR_BLEND_TOOLTIP'] =
    'Mistura duas cores em uma dada proporção (0,0 - 1,0).';
Blockly.Msg['COLOUR_PICKER_HELPURL'] = 'https://pt.wikipedia.org/wiki/Cor';
Blockly.Msg['COLOUR_PICKER_TOOLTIP'] = 'Escolher uma cor da palheta de cores.';
Blockly.Msg['COLOUR_RANDOM_HELPURL'] = 'http://randomcolour.com'; // untranslated
Blockly.Msg['COLOUR_RANDOM_TITLE'] = 'cor aleatória';
Blockly.Msg['COLOUR_RANDOM_TOOLTIP'] = 'Escolher cor de forma aleatória.';
Blockly.Msg['COLOUR_RGB_BLUE'] = 'azul';
Blockly.Msg['COLOUR_RGB_GREEN'] = 'verde';
Blockly.Msg['COLOUR_RGB_HELPURL'] =
    'https://www.december.com/html/spec/colorpercompact.html'; // untranslated
Blockly.Msg['COLOUR_RGB_RED'] = 'vermelho';
Blockly.Msg['COLOUR_RGB_TITLE'] = 'colorir com';
Blockly.Msg['COLOUR_RGB_TOOLTIP'] =
    'Criar uma cor com a quantidade especificada de vermelho, verde e azul. Todos os valores devem estar entre 0 e 100.';
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_HELPURL'] =
    'https://github.com/google/blockly/wiki/Loops#loop-termination-blocks'; // untranslated
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_OPERATOR_BREAK'] = 'encerra o laço';
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_OPERATOR_CONTINUE'] =
    'continua com a próxima iteração do laço';
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_TOOLTIP_BREAK'] = 'Encerra o laço.';
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_TOOLTIP_CONTINUE'] =
    'Ignora o resto deste laço, e continua com a próxima iteração.';
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_WARNING'] =
    'Atenção: Este bloco só pode ser usado dentro de um laço.';
Blockly.Msg['CONTROLS_FOREACH_HELPURL'] =
    'https://github.com/google/blockly/wiki/Loops#for-each'; // untranslated
Blockly.Msg['CONTROLS_FOREACH_TITLE'] = 'para cada item %1 na lista %2';
Blockly.Msg['CONTROLS_FOREACH_TOOLTIP'] =
    "Para cada item em uma lista, atribua o item à variável '%1' e então realize algumas instruções.";
Blockly.Msg['CONTROLS_FOR_HELPURL'] =
    'https://github.com/google/blockly/wiki/Loops#count-with'; // untranslated
Blockly.Msg['CONTROLS_FOR_TITLE'] = 'contar com %1 de %2 até %3 por %4';
Blockly.Msg['CONTROLS_FOR_TOOLTIP'] =
    "Faça com que a variável '%1' assuma os valores do número inicial ao número final, contando de acordo com o intervalo especificado e execute os blocos especificados.";
Blockly.Msg['CONTROLS_IF_ELSEIF_TOOLTIP'] =
    'Acrescente uma condição para o bloco se.';
Blockly.Msg['CONTROLS_IF_ELSE_TOOLTIP'] =
    'Acrescente uma condição final para o bloco se.';
Blockly.Msg['CONTROLS_IF_HELPURL'] =
    'https://github.com/google/blockly/wiki/IfElse'; // untranslated
Blockly.Msg['CONTROLS_IF_IF_TOOLTIP'] =
    'Acrescente, remova ou reordene seções para reconfigurar este bloco.';
Blockly.Msg['CONTROLS_IF_MSG_ELSE'] = 'senão';
Blockly.Msg['CONTROLS_IF_MSG_ELSEIF'] = 'senão se';
Blockly.Msg['CONTROLS_IF_MSG_IF'] = 'se';
Blockly.Msg['CONTROLS_IF_TOOLTIP_1'] =
    'Se um valor for verdadeiro, então realize algumas instruções.';
Blockly.Msg['CONTROLS_IF_TOOLTIP_2'] =
    'Se um valor for verdadeiro, então realize o primeiro bloco de instruções. Senão, realize o segundo bloco de instruções.';
Blockly.Msg['CONTROLS_IF_TOOLTIP_3'] =
    'Se o primeiro valor é verdadeiro, então realize o primeiro bloco de instruções.  Senão, se o segundo valor é verdadeiro, realize o segundo bloco de instruções.';
Blockly.Msg['CONTROLS_IF_TOOLTIP_4'] =
    'Se o primeiro valor for verdadeiro, então realize o primeiro bloco de instruções. Senão, se o segundo valor é verdadeiro, realize o segundo bloco de instruções. Se nenhum dos blocos for verdadeiro, realize o último bloco de instruções.';
Blockly.Msg['CONTROLS_REPEAT_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Estrutura_de_repeti%C3%A7%C3%A3o#Repeti.C3.A7.C3.A3o_com_vari.C3.A1vel_de_controle';
Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'] = 'faça';
Blockly.Msg['CONTROLS_REPEAT_TITLE'] = 'repita %1 vezes';
Blockly.Msg['CONTROLS_REPEAT_TOOLTIP'] =
    'Faça algumas instruções várias vezes.';
Blockly.Msg['CONTROLS_WHILEUNTIL_HELPURL'] =
    'https://github.com/google/blockly/wiki/Loops#repeat'; // untranslated
Blockly.Msg['CONTROLS_WHILEUNTIL_OPERATOR_UNTIL'] = 'repita até';
Blockly.Msg['CONTROLS_WHILEUNTIL_OPERATOR_WHILE'] = 'repita enquanto';
Blockly.Msg['CONTROLS_WHILEUNTIL_TOOLTIP_UNTIL'] =
    'Enquanto um valor for falso, então faça algumas instruções.';
Blockly.Msg['CONTROLS_WHILEUNTIL_TOOLTIP_WHILE'] =
    'Enquanto um valor for verdadeiro, então faça algumas instruções.';
Blockly.Msg['DEBUG_FLAG'] = 'Modo Debug: ';
Blockly.Msg['DEBUG_FLAG_OFF'] = 'Desligado';
Blockly.Msg['DEBUG_FLAG_ON'] = 'Ligado';
Blockly.Msg['DEBUG_FLAG_TOOLTIP'] = 'Liga ou Desliga o modo de depuração.';
Blockly.Msg['DEBUG_CONSOLE_LOG'] = 'Imprime no log da console o texto: ';
Blockly.Msg['DEBUG_CONSOLE_LOG_HELP'] =
    'Imprime no log da console o texto designado';
Blockly.Msg['DEBUG_CONSOLE_LOG_TOOLTIP'] =
    'Insere o texto na console de depuração.';
Blockly.Msg['DEBUG_CONSOLE_LOG_VAR'] =
    'Imprime no log da console todas as variáveis VARS.';
Blockly.Msg['DEBUG_CONSOLE_LOG_VAR_HELP'] =
    'Imprime no log da console  todas as variáveis VARS.';
Blockly.Msg['DEBUG_CONSOLE_LOG_VAR_TOOLTIP'] =
    'Lista todas as variáveis VARS. na console de depuração.';
Blockly.Msg['DELETE_ALL_BLOCKS'] = 'Deletar todos os blocos %1?';
Blockly.Msg['DELETE_BLOCK'] = 'Deletar bloco';
Blockly.Msg['DELETE_VARIABLE'] = "Deletar a variável '%1'";
Blockly.Msg['DELETE_VARIABLE_CONFIRMATION'] =
    "Deletar %1 usos da variável '%2'?";
Blockly.Msg['DELETE_X_BLOCKS'] = 'Deletar %1 blocos';
Blockly.Msg['DISABLE_BLOCK'] = 'Desabilitar bloco';
Blockly.Msg['DUPLICATE_BLOCK'] = 'Duplicar';
Blockly.Msg['DUPLICATE_COMMENT'] = 'Duplicar comentário';
Blockly.Msg['ENABLE_BLOCK'] = 'Habilitar bloco';
Blockly.Msg['EXPAND_ALL'] = 'Expandir blocos';
Blockly.Msg['EXPAND_BLOCK'] = 'Expandir bloco';
Blockly.Msg['EXTERNAL_INPUTS'] = 'Entradas externas';
Blockly.Msg['HELP'] = 'Ajuda';
Blockly.Msg['INLINE_INPUTS'] = 'Entradas incorporadas';
Blockly.Msg['IOS_CANCEL'] = 'Cancelar';
Blockly.Msg['IOS_ERROR'] = 'Erro';
Blockly.Msg['IOS_OK'] = 'Ok';
Blockly.Msg['IOS_PROCEDURES_ADD_INPUT'] = '+ Adicionar entrada';
Blockly.Msg['IOS_PROCEDURES_ALLOW_STATEMENTS'] = 'Permitir declarações';
Blockly.Msg['IOS_PROCEDURES_DUPLICATE_INPUTS_ERROR'] =
    'Essa função tem entradas duplicadas.';
Blockly.Msg['IOS_PROCEDURES_INPUTS'] = 'ENTRADAS';
Blockly.Msg['IOS_VARIABLES_ADD_BUTTON'] = 'Adicionar';
Blockly.Msg['IOS_VARIABLES_ADD_VARIABLE'] = '+ Adicionar variável';
Blockly.Msg['IOS_VARIABLES_DELETE_BUTTON'] = 'Deletar';
Blockly.Msg['IOS_VARIABLES_EMPTY_NAME_ERROR'] =
    'Você não pode usar um nome de variável vazio.';
Blockly.Msg['IOS_VARIABLES_RENAME_BUTTON'] = 'Renomear';
Blockly.Msg['IOS_VARIABLES_VARIABLE_NAME'] = 'Nome variavel';
Blockly.Msg['LISTS_CREATE_EMPTY_HELPURL'] =
    'https://github.com/google/blockly/wiki/Lists#create-empty-list';
Blockly.Msg['LISTS_CREATE_EMPTY_TITLE'] = 'criar lista vazia';
Blockly.Msg['LISTS_CREATE_EMPTY_TOOLTIP'] =
    'Retorna uma lista, de tamanho 0, contendo nenhum registro';
Blockly.Msg['LISTS_CREATE_WITH_CONTAINER_TITLE_ADD'] = 'lista';
Blockly.Msg['LISTS_CREATE_WITH_CONTAINER_TOOLTIP'] =
    'Acrescenta, remove ou reordena seções para reconfigurar este bloco de lista.';
Blockly.Msg['LISTS_CREATE_WITH_HELPURL'] =
    'https://github.com/google/blockly/wiki/Lists#create-list-with';
Blockly.Msg['LISTS_CREATE_WITH_INPUT_WITH'] = 'criar lista com';
Blockly.Msg['LISTS_CREATE_WITH_ITEM_TOOLTIP'] = 'Acrescenta um item à lista.';
Blockly.Msg['LISTS_CREATE_WITH_TOOLTIP'] =
    'Cria uma lista com a quantidade de itens informada.';
Blockly.Msg['LISTS_GET_INDEX_FIRST'] = 'primeiro';
Blockly.Msg['LISTS_GET_INDEX_FROM_END'] = 'nº a partir do final';
Blockly.Msg['LISTS_GET_INDEX_FROM_START'] = 'nº';
Blockly.Msg['LISTS_GET_INDEX_GET'] = 'obter';
Blockly.Msg['LISTS_GET_INDEX_GET_REMOVE'] = 'obter e remover';
Blockly.Msg['LISTS_GET_INDEX_LAST'] = 'último';
Blockly.Msg['LISTS_GET_INDEX_RANDOM'] = 'aleatório';
Blockly.Msg['LISTS_GET_INDEX_REMOVE'] = 'remover';
Blockly.Msg['LISTS_GET_INDEX_TAIL'] = ''; // untranslated
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_FIRST'] =
    'Retorna o primeiro item em uma lista.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_FROM'] =
    'Retorna o item da lista na posição especificada.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_LAST'] =
    'Retorna o último item em uma lista.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_RANDOM'] =
    'Retorna um item aleatório de uma lista.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_FIRST'] =
    'Remove e retorna o primeiro item de uma lista.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_FROM'] =
    'Remove e retorna o item na posição especificada em uma lista.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_LAST'] =
    'Remove e retorna o último item de uma lista.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_RANDOM'] =
    'Remove e retorna um item aleatório de uma lista.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_REMOVE_FIRST'] =
    'Remove o primeiro item de uma lista.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_REMOVE_FROM'] =
    'Remove o item na posição especificada em uma lista.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_REMOVE_LAST'] =
    'Remove o último item de uma lista.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_REMOVE_RANDOM'] =
    'Remove um item aleatório de uma lista.';
Blockly.Msg['LISTS_GET_SUBLIST_END_FROM_END'] = 'até nº a partir do final';
Blockly.Msg['LISTS_GET_SUBLIST_END_FROM_START'] = 'até nº';
Blockly.Msg['LISTS_GET_SUBLIST_END_LAST'] = 'até último';
Blockly.Msg['LISTS_GET_SUBLIST_HELPURL'] =
    'https://github.com/google/blockly/wiki/Lists#getting-a-sublist';
Blockly.Msg['LISTS_GET_SUBLIST_START_FIRST'] =
    'obtém sublista a partir do primeiro';
Blockly.Msg['LISTS_GET_SUBLIST_START_FROM_END'] =
    'obtém sublista de nº a partir do final';
Blockly.Msg['LISTS_GET_SUBLIST_START_FROM_START'] = 'obtém sublista de nº';
Blockly.Msg['LISTS_GET_SUBLIST_TAIL'] = ''; // untranslated
Blockly.Msg['LISTS_GET_SUBLIST_TOOLTIP'] =
    'Cria uma cópia da porção especificada de uma lista.';
Blockly.Msg['LISTS_INDEX_FROM_END_TOOLTIP'] = '%1 é o último item.';
Blockly.Msg['LISTS_INDEX_FROM_START_TOOLTIP'] = '%1 é o primeiro item.';
Blockly.Msg['LISTS_INDEX_OF_FIRST'] = 'encontre a primeira ocorrência do item';
Blockly.Msg['LISTS_INDEX_OF_HELPURL'] =
    'https://github.com/google/blockly/wiki/Lists#getting-items-from-a-list'; // untranslated
Blockly.Msg['LISTS_INDEX_OF_LAST'] = 'encontre a última ocorrência do item';
Blockly.Msg['LISTS_INDEX_OF_TOOLTIP'] =
    'Retorna o índice da primeira/última ocorrência do item na lista.  Retorna %1 se o item não for encontrado.';
Blockly.Msg['LISTS_INLIST'] = 'na lista';
Blockly.Msg['LISTS_ISEMPTY_HELPURL'] =
    'https://github.com/google/blockly/wiki/Lists#is-empty';
Blockly.Msg['LISTS_ISEMPTY_TITLE'] = '%1 é vazia';
Blockly.Msg['LISTS_ISEMPTY_TOOLTIP'] =
    'Retorna ao verdadeiro se a lista estiver vazia.';
Blockly.Msg['LISTS_LENGTH_HELPURL'] =
    'https://github.com/google/blockly/wiki/Lists#length-of';
Blockly.Msg['LISTS_LENGTH_TITLE'] = 'tamanho de %1';
Blockly.Msg['LISTS_LENGTH_TOOLTIP'] = 'Retorna o tamanho de uma lista.';
Blockly.Msg['LISTS_REPEAT_HELPURL'] =
    'https://github.com/google/blockly/wiki/Lists#create-list-with';
Blockly.Msg['LISTS_REPEAT_TITLE'] = 'criar lista com item %1 repetido %2 vezes';
Blockly.Msg['LISTS_REPEAT_TOOLTIP'] =
    'Cria uma lista consistindo no valor informado repetido o número de vezes especificado.';
Blockly.Msg['LISTS_REVERSE_HELPURL'] =
    'https://github.com/google/blockly/wiki/Listas#invertendo-uma-lista';
Blockly.Msg['LISTS_REVERSE_MESSAGE0'] = 'inverter %1';
Blockly.Msg['LISTS_REVERSE_TOOLTIP'] = 'Inverter uma cópia da lista.';
Blockly.Msg['LISTS_SET_INDEX_HELPURL'] =
    'https://github.com/google/blockly/wiki/Lists#in-list--set'; // untranslated
Blockly.Msg['LISTS_SET_INDEX_INPUT_TO'] = 'como';
Blockly.Msg['LISTS_SET_INDEX_INSERT'] = 'inserir em';
Blockly.Msg['LISTS_SET_INDEX_SET'] = 'definir';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_FIRST'] =
    'Insere o item no início de uma lista.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_FROM'] =
    'Insere o item na posição especificada em uma lista.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_LAST'] =
    'Insere o item no final de uma lista.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_RANDOM'] =
    'Insere o item em uma posição qualquer de uma lista.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_FIRST'] =
    'Define o primeiro item de uma lista.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_FROM'] =
    'Define o item da posição especificada de uma lista.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_LAST'] =
    'Define o último item de uma lista.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_RANDOM'] =
    'Define um item aleatório de uma lista.';
Blockly.Msg['LISTS_SORT_HELPURL'] =
    'https://github.com/google/blockly/wiki/Lists#sorting-a-list';
Blockly.Msg['LISTS_SORT_ORDER_ASCENDING'] = 'ascendente';
Blockly.Msg['LISTS_SORT_ORDER_DESCENDING'] = 'descendente';
Blockly.Msg['LISTS_SORT_TITLE'] = 'ordenar %1 %2 %3';
Blockly.Msg['LISTS_SORT_TOOLTIP'] = 'Ordenar uma cópia de uma lista.';
Blockly.Msg['LISTS_SORT_TYPE_IGNORECASE'] =
    'alfabético, ignorar maiúscula/minúscula';
Blockly.Msg['LISTS_SORT_TYPE_NUMERIC'] = 'numérico';
Blockly.Msg['LISTS_SORT_TYPE_TEXT'] = 'alfabético';
Blockly.Msg['LISTS_SPLIT_HELPURL'] =
    'https://github.com/google/blockly/wiki/Lists#splitting-strings-and-joining-lists';
Blockly.Msg['LISTS_SPLIT_LIST_FROM_TEXT'] = 'Fazer uma lista a partir do texto';
Blockly.Msg['LISTS_SPLIT_TEXT_FROM_LIST'] = 'fazer um texto a partir da lista';
Blockly.Msg['LISTS_SPLIT_TOOLTIP_JOIN'] =
    'Juntar uma lista de textos em um único texto, separado por um delimitador.';
Blockly.Msg['LISTS_SPLIT_TOOLTIP_SPLIT'] =
    'Dividir o texto em uma lista de textos, separando-o em cada delimitador.';
Blockly.Msg['LISTS_SPLIT_WITH_DELIMITER'] = 'com delimitador';
Blockly.Msg['LOGIC_BOOLEAN_FALSE'] = 'falso';
Blockly.Msg['LOGIC_BOOLEAN_HELPURL'] =
    'https://github.com/google/blockly/wiki/Logic#values'; // untranslated
Blockly.Msg['LOGIC_BOOLEAN_TOOLTIP'] = 'Retorna verdadeiro ou falso.';
Blockly.Msg['LOGIC_BOOLEAN_TRUE'] = 'verdadeiro';
Blockly.Msg['LOGIC_COMPARE_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Inequa%C3%A7%C3%A3o';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_EQ'] =
    'Retorna verdadeiro se ambas as entradas forem iguais.';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_GT'] =
    'Retorna verdadeiro se a primeira entrada for maior que a segunda entrada.';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_GTE'] =
    'Retorna verdadeiro se a primeira entrada for maior ou igual à segunda entrada.';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_LT'] =
    'Retorna verdadeiro se a primeira entrada for menor que a segunda entrada.';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_LTE'] =
    'Retorna verdadeiro se a primeira entrada for menor ou igual à segunda entrada.';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_NEQ'] =
    'Retorna verdadeiro se ambas as entradas forem diferentes.';
Blockly.Msg['LOGIC_NEGATE_HELPURL'] =
    'https://github.com/google/blockly/wiki/Logic#not'; // untranslated
Blockly.Msg['LOGIC_NEGATE_TITLE'] = 'não %1';
Blockly.Msg['LOGIC_NEGATE_TOOLTIP'] =
    'Retorna verdadeiro se a entrada for falsa.  Retorna falsa se a entrada for verdadeira.';
Blockly.Msg['LOGIC_NULL'] = 'nulo';
Blockly.Msg['LOGIC_NULL_HELPURL'] =
    'https://en.wikipedia.org/wiki/Nullable_type'; // untranslated
Blockly.Msg['LOGIC_NULL_TOOLTIP'] = 'Retorna nulo.';
Blockly.Msg['LOGIC_OPERATION_AND'] = 'e';
Blockly.Msg['LOGIC_OPERATION_HELPURL'] =
    'https://github.com/google/blockly/wiki/Logic#logical-operations'; // untranslated
Blockly.Msg['LOGIC_OPERATION_OR'] = 'ou';
Blockly.Msg['LOGIC_OPERATION_TOOLTIP_AND'] =
    'Retorna verdadeiro se ambas as entradas forem verdadeiras.';
Blockly.Msg['LOGIC_OPERATION_TOOLTIP_OR'] =
    'Retorna verdadeiro se uma das estradas for verdadeira.';
Blockly.Msg['LOGIC_TERNARY_CONDITION'] = 'teste';
Blockly.Msg['LOGIC_TERNARY_HELPURL'] = 'https://en.wikipedia.org/wiki/%3F:'; // untranslated
Blockly.Msg['LOGIC_TERNARY_IF_FALSE'] = 'se falso';
Blockly.Msg['LOGIC_TERNARY_IF_TRUE'] = 'se verdadeiro';
Blockly.Msg['LOGIC_TERNARY_TOOLTIP'] =
    'Avalia a condição em "teste". Se a condição for verdadeira retorna o valor "se verdadeiro", senão retorna o valor "se falso".';
Blockly.Msg['LLM_BASIC_TOOTIP'] = 'OpenAI https://beta.openai.com/docs/api-reference/completions/create';
Blockly.Msg['LLM_ADVANCED_TOOTIP'] = 'OpenAI https://beta.openai.com/docs/api-reference/completions/create';
Blockly.Msg['LLM_FALAGPT_TOOTIP'] = 'Verifique a documentação técnica do FalaGPT - Fintalk Large Language Model';
Blockly.Msg['MATH_ADDITION_SYMBOL'] = '+'; // untranslated
Blockly.Msg['MATH_ARITHMETIC_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Aritm%C3%A9tica';
Blockly.Msg['MATH_ARITHMETIC_TOOLTIP_ADD'] = 'Retorna a soma dos dois números.';
Blockly.Msg['MATH_ARITHMETIC_TOOLTIP_DIVIDE'] =
    'Retorna o quociente da divisão dos dois números.';
Blockly.Msg['MATH_ARITHMETIC_TOOLTIP_MINUS'] =
    'Retorna a diferença entre os dois números.';
Blockly.Msg['MATH_ARITHMETIC_TOOLTIP_MULTIPLY'] =
    'Retorna o produto dos dois números.';
Blockly.Msg['MATH_ARITHMETIC_TOOLTIP_POWER'] =
    'Retorna o primeiro número elevado à potência do segundo número.';
Blockly.Msg['MATH_ATAN2_HELPURL'] = 'https://en.wikipedia.org/wiki/Atan2';
Blockly.Msg['MATH_ATAN2_TITLE'] = 'atan2 de X:%1 Y:%2';
Blockly.Msg['MATH_ATAN2_TOOLTIP'] =
    'Retorne o arco tangente do ponto (X, Y) em graus de -180 a 180.';
Blockly.Msg['MATH_CHANGE_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Adi%C3%A7%C3%A3o';
Blockly.Msg['MATH_CHANGE_TITLE'] = 'alterar %1 por %2';
Blockly.Msg['MATH_CHANGE_TOOLTIP'] = 'Soma um número à variável "%1".';
Blockly.Msg['MATH_CONSTANT_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Anexo:Lista_de_constantes_matem%C3%A1ticas';
Blockly.Msg['MATH_CONSTANT_TOOLTIP'] =
    'Retorna uma das constantes comuns: π (3.141…), e (2.718…), φ (1.618…), sqrt(2) (1.414…), sqrt(½) (0.707…), ou ∞ (infinito).';
Blockly.Msg['MATH_CONSTRAIN_HELPURL'] =
    'https://en.wikipedia.org/wiki/Clamping_(graphics)'; // untranslated
Blockly.Msg['MATH_CONSTRAIN_TITLE'] = 'restringe %1 inferior %2 superior %3';
Blockly.Msg['MATH_CONSTRAIN_TOOLTIP'] =
    'Restringe um número entre os limites especificados (inclusivo).';
Blockly.Msg['MATH_DIVISION_SYMBOL'] = '÷'; // untranslated
Blockly.Msg['MATH_IS_DIVISIBLE_BY'] = 'é divisível por';
Blockly.Msg['MATH_IS_EVEN'] = 'é par';
Blockly.Msg['MATH_IS_NEGATIVE'] = 'é negativo';
Blockly.Msg['MATH_IS_ODD'] = 'é ímpar';
Blockly.Msg['MATH_IS_POSITIVE'] = 'é positivo';
Blockly.Msg['MATH_IS_PRIME'] = 'é primo';
Blockly.Msg['MATH_IS_TOOLTIP'] =
    'Verifica se um número é par, ímpar, inteiro, positivo, negativo, ou se é divisível por outro número.  Retorna verdadeiro ou falso.';
Blockly.Msg['MATH_IS_WHOLE'] = 'é inteiro';
Blockly.Msg['MATH_MODULO_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Opera%C3%A7%C3%A3o_m%C3%B3dulo';
Blockly.Msg['MATH_MODULO_TITLE'] = 'resto da divisão de %1 ÷ %2';
Blockly.Msg['MATH_MODULO_TOOLTIP'] =
    'Retorna o resto da divisão de dois números.';
Blockly.Msg['MATH_MULTIPLICATION_SYMBOL'] = '×'; // untranslated
Blockly.Msg['MATH_NUMBER_HELPURL'] =
    'https://pt.wikipedia.org/wiki/N%C3%BAmero';
Blockly.Msg['MATH_NUMBER_TOOLTIP'] = 'Um número.';
Blockly.Msg['MATH_ONLIST_HELPURL'] = ''; // untranslated
Blockly.Msg['MATH_ONLIST_OPERATOR_AVERAGE'] = 'média da lista';
Blockly.Msg['MATH_ONLIST_OPERATOR_MAX'] = 'maior da lista';
Blockly.Msg['MATH_ONLIST_OPERATOR_MEDIAN'] = 'mediana da lista';
Blockly.Msg['MATH_ONLIST_OPERATOR_MIN'] = 'menor da lista';
Blockly.Msg['MATH_ONLIST_OPERATOR_MODE'] = 'moda da lista';
Blockly.Msg['MATH_ONLIST_OPERATOR_RANDOM'] = 'item aleatório da lista';
Blockly.Msg['MATH_ONLIST_OPERATOR_STD_DEV'] = 'desvio padrão da lista';
Blockly.Msg['MATH_ONLIST_OPERATOR_SUM'] = 'soma de uma lista';
Blockly.Msg['MATH_ONLIST_TOOLTIP_AVERAGE'] =
    'Retorna a média aritmética dos números da lista.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_MAX'] = 'Retorna o maior número da lista.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_MEDIAN'] =
    'Retorna a mediana dos números da lista.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_MIN'] = 'Retorna o menor número da lista.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_MODE'] =
    'Retorna uma lista do(s) item(ns) mais comum(ns) da lista.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_RANDOM'] =
    'Retorna um elemento aleatório da lista.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_STD_DEV'] =
    'Retorna o desvio padrão dos números da lista.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_SUM'] =
    'Retorna a soma de todos os números na lista.';
Blockly.Msg['MATH_POWER_SYMBOL'] = '^'; // untranslated
Blockly.Msg['MATH_RANDOM_FLOAT_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Gerador_de_n%C3%BAmeros_pseudoaleat%C3%B3rios';
Blockly.Msg['MATH_RANDOM_FLOAT_TITLE_RANDOM'] = 'fração aleatória';
Blockly.Msg['MATH_RANDOM_FLOAT_TOOLTIP'] =
    'Retorna uma fração aleatória entre 0.0 (inclusivo) e 1.0 (exclusivo).';
Blockly.Msg['MATH_RANDOM_INT_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Gerador_de_n%C3%BAmeros_pseudoaleat%C3%B3rios';
Blockly.Msg['MATH_RANDOM_INT_TITLE'] = 'inteiro aleatório entre %1 e %2';
Blockly.Msg['MATH_RANDOM_INT_TOOLTIP'] =
    'Retorna um número inteiro entre os dois limites informados, inclusivo.';
Blockly.Msg['MATH_ROUND_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Arredondamento';
Blockly.Msg['MATH_ROUND_OPERATOR_ROUND'] = 'arredonda';
Blockly.Msg['MATH_ROUND_OPERATOR_ROUNDDOWN'] = 'arredonda para baixo';
Blockly.Msg['MATH_ROUND_OPERATOR_ROUNDUP'] = 'arredonda para cima';
Blockly.Msg['MATH_ROUND_TOOLTIP'] =
    'Arredonda um número para cima ou para baixo.';
Blockly.Msg['MATH_SINGLE_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Raiz_quadrada';
Blockly.Msg['MATH_SINGLE_OP_ABSOLUTE'] = 'absoluto';
Blockly.Msg['MATH_SINGLE_OP_ROOT'] = 'raiz quadrada';
Blockly.Msg['MATH_SINGLE_TOOLTIP_ABS'] =
    'Retorna o valor absoluto de um número.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_EXP'] =
    'Retorna o número e elevado à potência de um número.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_LN'] =
    'Retorna o logaritmo natural de um número.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_LOG10'] =
    'Retorna o logaritmo em base 10 de um número.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_NEG'] = 'Retorna o oposto de um número.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_POW10'] =
    'Retorna 10 elevado à potência de um número.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_ROOT'] =
    'Retorna a raiz quadrada de um número.';
Blockly.Msg['MATH_SUBTRACTION_SYMBOL'] = '-'; // untranslated
Blockly.Msg['MATH_TRIG_ACOS'] = 'acos'; // untranslated
Blockly.Msg['MATH_TRIG_ASIN'] = 'asin'; // untranslated
Blockly.Msg['MATH_TRIG_ATAN'] = 'atan'; // untranslated
Blockly.Msg['MATH_TRIG_COS'] = 'cos'; // untranslated
Blockly.Msg['MATH_TRIG_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Fun%C3%A7%C3%A3o_trigonom%C3%A9trica';
Blockly.Msg['MATH_TRIG_SIN'] = 'sin'; // untranslated
Blockly.Msg['MATH_TRIG_TAN'] = 'tan'; // untranslated
Blockly.Msg['MATH_TRIG_TOOLTIP_ACOS'] = 'Retorna o arco cosseno de um número.';
Blockly.Msg['MATH_TRIG_TOOLTIP_ASIN'] = 'Retorna o arco seno de um número.';
Blockly.Msg['MATH_TRIG_TOOLTIP_ATAN'] = 'Retorna o arco tangente de um número.';
Blockly.Msg['MATH_TRIG_TOOLTIP_COS'] =
    'Retorna o cosseno de um grau (não radiano).';
Blockly.Msg['MATH_TRIG_TOOLTIP_SIN'] =
    'Retorna o seno de um grau (não radiano).';
Blockly.Msg['MATH_TRIG_TOOLTIP_TAN'] =
    'Retorna a tangente de um grau (não radiano).';
Blockly.Msg['NEW_COLOUR_VARIABLE'] = 'Criar variável colorida...';
Blockly.Msg['NEW_NUMBER_VARIABLE'] = 'Criar variável numérica...';
Blockly.Msg['NEW_STRING_VARIABLE'] = 'Criar variável de segmentos de texto...';
Blockly.Msg['NEW_VARIABLE'] = 'Criar variável...';
Blockly.Msg['NEW_VARIABLE_TITLE'] = 'Nome da nova variável:';
Blockly.Msg['NEW_VARIABLE_TYPE_TITLE'] = 'Tipo da nova variável:';
Blockly.Msg['ORDINAL_NUMBER_SUFFIX'] = ''; // untranslated
Blockly.Msg['PROCEDURES_ALLOW_STATEMENTS'] = 'permitir declarações';
Blockly.Msg['PROCEDURES_BEFORE_PARAMS'] = 'com:';
Blockly.Msg['PROCEDURES_CALLNORETURN_HELPURL'] =
    'https://pt.wikipedia.org/wiki/M%C3%A9todo_(programa%C3%A7%C3%A3o)';
Blockly.Msg['PROCEDURES_CALLNORETURN_TOOLTIP'] =
    'Executa a função definida pelo usuário "%1".';
Blockly.Msg['PROCEDURES_CALLRETURN_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Sub-rotina';
Blockly.Msg['PROCEDURES_CALLRETURN_TOOLTIP'] =
    'Executa a função definida pelo usuário "%1" e usa seu retorno.';
Blockly.Msg['PROCEDURES_CALL_BEFORE_PARAMS'] = 'com:';
Blockly.Msg['PROCEDURES_CREATE_DO'] = 'Criar "%1"';
Blockly.Msg['PROCEDURES_DEFNORETURN_COMMENT'] = 'Descreva esta função...';
Blockly.Msg['PROCEDURES_DEFNORETURN_DO'] = ''; // untranslated
Blockly.Msg['PROCEDURES_DEFNORETURN_HELPURL'] =
    'https://pt.wikipedia.org/wiki/M%C3%A9todo_(programa%C3%A7%C3%A3o)';
Blockly.Msg['PROCEDURES_DEFNORETURN_PROCEDURE'] = 'faça algo';
Blockly.Msg['PROCEDURES_DEFNORETURN_TITLE'] = 'para';
Blockly.Msg['PROCEDURES_DEFNORETURN_TOOLTIP'] =
    'Cria uma função que não tem retorno.';
Blockly.Msg['PROCEDURES_DEFRETURN_HELPURL'] =
    'https://pt.wikipedia.org/wiki/M%C3%A9todo_(programa%C3%A7%C3%A3o)';
Blockly.Msg['PROCEDURES_DEFRETURN_RETURN'] = 'retorna';
Blockly.Msg['PROCEDURES_DEFRETURN_TOOLTIP'] =
    'Cria uma função que possui um valor de retorno.';
Blockly.Msg['PROCEDURES_DEF_DUPLICATE_WARNING'] =
    'Atenção: Esta função tem parâmetros duplicados.';
Blockly.Msg['PROCEDURES_HIGHLIGHT_DEF'] = 'Destacar definição da função';
Blockly.Msg['PROCEDURES_IFRETURN_HELPURL'] =
    'http://c2.com/cgi/wiki?GuardClause';
Blockly.Msg['PROCEDURES_IFRETURN_TOOLTIP'] =
    'Se um valor é verdadeiro, então retorna um valor.';
Blockly.Msg['PROCEDURES_IFRETURN_WARNING'] =
    'Atenção: Este bloco só pode ser utilizado dentro da definição de uma função.';
Blockly.Msg['PROCEDURES_MUTATORARG_TITLE'] = 'nome da entrada:';
Blockly.Msg['PROCEDURES_MUTATORARG_TOOLTIP'] =
    'Adiciona uma entrada para esta função';
Blockly.Msg['PROCEDURES_MUTATORCONTAINER_TITLE'] = 'entradas';
Blockly.Msg['PROCEDURES_MUTATORCONTAINER_TOOLTIP'] =
    'Adiciona, remove, ou reordena as entradas para esta função.';
Blockly.Msg['REDO'] = 'Refazer';
Blockly.Msg['REMOVE_COMMENT'] = 'Remover comentário';
Blockly.Msg['RENAME_VARIABLE'] = 'Renomear variável...';
Blockly.Msg['RENAME_VARIABLE_TITLE'] = "Renomear todas as variáveis '%1' para:";
Blockly.Msg['TEXTOSEMASPAS_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#text-modification'; // untranslated
Blockly.Msg['TEXTOSEMASPAS_APPEND_TITLE'] = 'Texto %1 sem aspas';
Blockly.Msg['TEXTOSEMASPAS_APPEND_TOOLTIP'] =
    'Acrescentar um pedaço de texto sem aspas à variável "%1".';
Blockly.Msg['TEXT_APPEND_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#text-modification'; // untranslated
Blockly.Msg['TEXT_APPEND_TITLE'] = 'para %1 anexar texto %2';
Blockly.Msg['TEXT_APPEND_TOOLTIP'] =
    'Acrescentar um pedaço de texto à variável "%1".';
Blockly.Msg['TEXT_CHANGECASE_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#adjusting-text-case';
Blockly.Msg['TEXT_CHANGECASE_OPERATOR_LOWERCASE'] = 'para minúsculas';
Blockly.Msg['TEXT_CHANGECASE_OPERATOR_TITLECASE'] = 'para Nomes Próprios';
Blockly.Msg['TEXT_CHANGECASE_OPERATOR_UPPERCASE'] = 'para MAIÚSCULAS';
Blockly.Msg['TEXT_CHANGECASE_TOOLTIP'] =
    'Retorna uma cópia do texto em um formato diferente.';
Blockly.Msg['TEXT_CHARAT_FIRST'] = 'obter primeira letra';
Blockly.Msg['TEXT_CHARAT_FROM_END'] = 'obter letra # a partir do final';
Blockly.Msg['TEXT_CHARAT_FROM_START'] = 'obter letra nº';
Blockly.Msg['TEXT_CHARAT_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#extracting-text'; // untranslated
Blockly.Msg['TEXT_CHARAT_LAST'] = 'obter última letra';
Blockly.Msg['TEXT_CHARAT_RANDOM'] = 'obter letra aleatória';
Blockly.Msg['TEXT_CHARAT_TAIL'] = ''; // untranslated
Blockly.Msg['TEXT_CHARAT_TITLE'] = 'no texto %1 %2';
Blockly.Msg['TEXT_CHARAT_TOOLTIP'] = 'Retorna a letra na posição especificada.';
Blockly.Msg['TEXT_COUNT_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#counting-substrings';
Blockly.Msg['TEXT_COUNT_MESSAGE0'] = 'Contar %1 em %2';
Blockly.Msg['TEXT_COUNT_TOOLTIP'] =
    'Calcule quantas vezes algum texto aparece centro de algum outro texto.';
Blockly.Msg['TEXT_CREATE_JOIN_ITEM_TOOLTIP'] = 'Acrescentar um item ao texto.';
Blockly.Msg['TEXT_CREATE_JOIN_TITLE_JOIN'] = 'unir';
Blockly.Msg['TEXT_CREATE_JOIN_TOOLTIP'] =
    'Acrescenta, remove ou reordena seções para reconfigurar este bloco de texto.';
Blockly.Msg['TEXT_GET_SUBSTRING_END_FROM_END'] =
    'até letra nº a partir do final';
Blockly.Msg['TEXT_GET_SUBSTRING_END_FROM_START'] = 'até letra nº';
Blockly.Msg['TEXT_GET_SUBSTRING_END_LAST'] = 'até última letra';
Blockly.Msg['TEXT_GET_SUBSTRING_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#extracting-a-region-of-text';
Blockly.Msg['TEXT_GET_SUBSTRING_INPUT_IN_TEXT'] = 'no texto';
Blockly.Msg['TEXT_GET_SUBSTRING_START_FIRST'] =
    'obter trecho de primeira letra';
Blockly.Msg['TEXT_GET_SUBSTRING_START_FROM_END'] =
    'obter trecho de letra nº a partir do final';
Blockly.Msg['TEXT_GET_SUBSTRING_START_FROM_START'] = 'obter trecho de letra nº';
Blockly.Msg['TEXT_GET_SUBSTRING_TAIL'] = ''; // untranslated
Blockly.Msg['TEXT_GET_SUBSTRING_TOOLTIP'] =
    'Retorna o trecho de texto especificado.';
Blockly.Msg['TEXT_INDEXOF_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#finding-text'; // untranslated
Blockly.Msg['TEXT_INDEXOF_OPERATOR_FIRST'] =
    'encontre a primeira ocorrência do item';
Blockly.Msg['TEXT_INDEXOF_OPERATOR_LAST'] =
    'encontre a última ocorrência do texto';
Blockly.Msg['TEXT_INDEXOF_TITLE'] = 'no texto %1 %2 %3';
Blockly.Msg['TEXT_INDEXOF_TOOLTIP'] =
    'Retorna a posição da primeira/última ocorrência do primeiro texto no segundo texto.  Retorna %1 se o texto não for encontrado.';
Blockly.Msg['TEXT_ISEMPTY_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#checking-for-empty-text'; // untranslated
Blockly.Msg['TEXT_ISEMPTY_TITLE'] = '%1 é vazio';
Blockly.Msg['TEXT_ISEMPTY_TOOLTIP'] =
    'Retorna verdadeiro se o texto fornecido for vazio.';
Blockly.Msg['TEXT_JOIN_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#text-creation'; // untranslated
Blockly.Msg['TEXT_JOIN_TITLE_CREATEWITH'] = 'criar texto com';
Blockly.Msg['TEXT_JOIN_TOOLTIP'] =
    'Criar um pedaço de texto juntando qualquer número de itens.';
Blockly.Msg['TEXT_LENGTH_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#text-modification'; // untranslated
Blockly.Msg['TEXT_LENGTH_TITLE'] = 'tamanho de %1';
Blockly.Msg['TEXT_LENGTH_TOOLTIP'] =
    'Retorna o número de letras (incluindo espaços) no texto fornecido.';
Blockly.Msg['TEXT_PRINT_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#printing-text';
Blockly.Msg['TEXT_PRINT_TITLE'] = 'imprime %1';
Blockly.Msg['TEXT_PRINT_TOOLTIP'] =
    'Imprime o texto, número ou valor especificado.';
Blockly.Msg['CONSOLE_PRINT_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#printing-text';
Blockly.Msg['CONSOLE_PRINT_TITLE'] = 'imprime na console de debug: %1';
Blockly.Msg['CONSOLE_PRINT_TOOLTIP'] =
    'Coloca na console de debug o texto, número ou valor especificado.';
Blockly.Msg['TEXT_PROMPT_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#getting-input-from-the-user';
Blockly.Msg['TEXT_PROMPT_TOOLTIP_NUMBER'] = 'Pede ao usuário um número.';
Blockly.Msg['TEXT_PROMPT_TOOLTIP_TEXT'] = 'Pede ao usuário um texto.';
Blockly.Msg['TEXT_PROMPT_TYPE_NUMBER'] = 'Pede um número com uma mensagem';
Blockly.Msg['TEXT_PROMPT_TYPE_TEXT'] = 'Pede um texto com uma mensagem';
Blockly.Msg['TEXT_REPLACE_HELPURL'] =
    'https://github.com/google/blockly/wiki/Texto#substituindo-substrings';
Blockly.Msg['TEXT_REPLACE_MESSAGE0'] = 'substituir %1 por %2 em %3';
Blockly.Msg['TEXT_REPLACE_TOOLTIP'] =
    'Substitua todas as ocorrências de algum texto dentro de algum outro texto.';
Blockly.Msg['TEXT_REVERSE_HELPURL'] =
    'https://github.com/google/blockly/wiki/Texto#invertendo-texto';
Blockly.Msg['TEXT_REVERSE_MESSAGE0'] = 'inverter %1';
Blockly.Msg['TEXT_REVERSE_TOOLTIP'] =
    'Inverter a ordem dos caracteres no texto.';
Blockly.Msg['TEXT_TEXT_HELPURL'] =
    'https://pt.wikipedia.org/wiki/Cadeia_de_caracteres';
Blockly.Msg['TEXT_TEXT_TOOLTIP'] = 'Uma letra, palavra ou linha de texto.';
Blockly.Msg['TEXT_TRIM_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#trimming-removing-spaces';
Blockly.Msg['TEXT_TRIM_OPERATOR_BOTH'] = 'remover espaços de ambos os lados de';
Blockly.Msg['TEXT_TRIM_OPERATOR_LEFT'] = 'remover espaços à esquerda de';
Blockly.Msg['TEXT_TRIM_OPERATOR_RIGHT'] = 'remover espaços à direita de';
Blockly.Msg['TEXT_TRIM_TOOLTIP'] =
    'Retorna uma cópia do texto com os espaços removidos de uma ou ambas extremidades.';
Blockly.Msg['TEXT_TOJS_HELPURL'] =
    'https://github.com/google/blockly/wiki/Text#printing-text';
Blockly.Msg['TEXT_TOJS_TITLE'] = 'JavaScript %1';
Blockly.Msg['TEXT_TOJS_TOOLTIP'] =
    'ATENÇÃO: Salve o texto como código RAW, sem crítica - as is.';
Blockly.Msg['TODAY'] = 'Hoje';
Blockly.Msg['UNDO'] = 'Desfazer';
Blockly.Msg['UNNAMED_KEY'] = 'Sem título';
Blockly.Msg['VARIABLES_DEFAULT_NAME'] = 'item';
Blockly.Msg['VARIABLES_GET_CREATE_SET'] = 'Criar "definir %1"';
Blockly.Msg['VARIABLES_GET_HELPURL'] =
    'https://github.com/google/blockly/wiki/Variables#get';
Blockly.Msg['VARIABLES_GET_TOOLTIP'] = 'Retorna o valor desta variável.';
Blockly.Msg['VARIABLES_SET'] = 'definir %1 para %2';
Blockly.Msg['VARIABLES_SET_CREATE_GET'] = 'Criar "obter %1"';
Blockly.Msg['VARIABLES_SET_HELPURL'] =
    'https://github.com/google/blockly/wiki/Variables#set';
Blockly.Msg['VARIABLES_SET_TOOLTIP'] =
    'Define esta variável para o valor da entrada.';
Blockly.Msg['VARIABLE_ALREADY_EXISTS'] = "A variável chamada '%1' já existe.";
Blockly.Msg['VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE'] =
    "Já existe uma variável chamada '%1' para outra do tipo: '%2'.";
Blockly.Msg['WORKSPACE_ARIA_LABEL'] = 'Espaço de trabalho do Blockly';
Blockly.Msg['WORKSPACE_COMMENT_DEFAULT_TEXT'] = 'Diz algo...';
Blockly.Msg['CONTROLS_FOREACH_INPUT_DO'] =
    Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'];
Blockly.Msg['CONTROLS_FOR_INPUT_DO'] = Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'];
Blockly.Msg['CONTROLS_IF_ELSEIF_TITLE_ELSEIF'] =
    Blockly.Msg['CONTROLS_IF_MSG_ELSEIF'];
Blockly.Msg['CONTROLS_IF_ELSE_TITLE_ELSE'] =
    Blockly.Msg['CONTROLS_IF_MSG_ELSE'];
Blockly.Msg['CONTROLS_IF_IF_TITLE_IF'] = Blockly.Msg['CONTROLS_IF_MSG_IF'];
Blockly.Msg['CONTROLS_IF_MSG_THEN'] = Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'];
Blockly.Msg['CONTROLS_WHILEUNTIL_INPUT_DO'] =
    Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'];
Blockly.Msg['LISTS_CREATE_WITH_ITEM_TITLE'] =
    Blockly.Msg['VARIABLES_DEFAULT_NAME'];
Blockly.Msg['LISTS_GET_INDEX_HELPURL'] = Blockly.Msg['LISTS_INDEX_OF_HELPURL'];
Blockly.Msg['LISTS_GET_INDEX_INPUT_IN_LIST'] = Blockly.Msg['LISTS_INLIST'];
Blockly.Msg['LISTS_GET_SUBLIST_INPUT_IN_LIST'] = Blockly.Msg['LISTS_INLIST'];
Blockly.Msg['LISTS_INDEX_OF_INPUT_IN_LIST'] = Blockly.Msg['LISTS_INLIST'];
Blockly.Msg['LISTS_SET_INDEX_INPUT_IN_LIST'] = Blockly.Msg['LISTS_INLIST'];
Blockly.Msg['MATH_CHANGE_TITLE_ITEM'] = Blockly.Msg['VARIABLES_DEFAULT_NAME'];
Blockly.Msg['PROCEDURES_DEFRETURN_COMMENT'] =
    Blockly.Msg['PROCEDURES_DEFNORETURN_COMMENT'];
Blockly.Msg['PROCEDURES_DEFRETURN_DO'] =
    Blockly.Msg['PROCEDURES_DEFNORETURN_DO'];
Blockly.Msg['PROCEDURES_DEFRETURN_PROCEDURE'] =
    Blockly.Msg['PROCEDURES_DEFNORETURN_PROCEDURE'];
Blockly.Msg['PROCEDURES_DEFRETURN_TITLE'] =
    Blockly.Msg['PROCEDURES_DEFNORETURN_TITLE'];
Blockly.Msg['TEXT_APPEND_VARIABLE'] = Blockly.Msg['VARIABLES_DEFAULT_NAME'];
Blockly.Msg['TEXT_CREATE_JOIN_ITEM_TITLE_ITEM'] =
    Blockly.Msg['VARIABLES_DEFAULT_NAME'];

Blockly.Msg['MATH_HUE'] = '230';
Blockly.Msg['LOOPS_HUE'] = '120';
Blockly.Msg['LISTS_HUE'] = '260';
Blockly.Msg['LOGIC_HUE'] = '210';
Blockly.Msg['VARIABLES_HUE'] = '330';
Blockly.Msg['TEXTS_HUE'] = '160';
Blockly.Msg['PROCEDURES_HUE'] = '290';
Blockly.Msg['COLOUR_HUE'] = '20';
Blockly.Msg['VARIABLES_DYNAMIC_HUE'] = '310';

Blockly.Msg['JS_CODE'] = 'Código JavaScript';
Blockly.Msg['JS_CODE_INNER_TEXT'] = 'Digite aqui o código JavaScript';
Blockly.Msg['JS_CODE_INLINE_TOOLTIP'] =
    'Coloque uma linha de código JavaScript válido';
Blockly.Msg['JS_CODE_MULTILINE_TOOLTIP'] =
    'Coloque aqui seu código JavaScript válido - multilinhas';
Blockly.Msg['JUMP_TO_BLOCK'] = 'Pular para o bloco';
Blockly.Msg['OF_GROUP'] = 'do grupo';
Blockly.Msg['JUMP_TO_BLOCK_OF_GROUP_TOOLTIP'] =
    'Pular para um outro Bloco/Grupo.  Coloque um Bloco que exista caso contrario a lógica vai cessar.';
Blockly.Msg['JUMP_TO_BLOCK_TOOLTIP'] =
    'Pular para um outro Bloco.  Coloque um Bloco que exista caso contrario a lógica vai cessar.';
Blockly.Msg['JUMP_TO_CONNECTOR'] = 'Pular para o conector:';
Blockly.Msg['JUMP_TO_CONNECTOR_TOOLTIP'] =
    'Coloque aqui o nome CURTO do connector';
Blockly.Msg['COMMENT'] = 'Comentário';
Blockly.Msg['COMMENT_FIELD'] = 'Digite aqui\nseu comentário';
Blockly.Msg['COMMENT_TOOLTIP'] = 'Coloque o comentário que desejar';
Blockly.Msg['TEXT'] = 'Texto';
Blockly.Msg['IMAGE'] = 'Imagem';
Blockly.Msg['TTS'] = 'Audio';
Blockly.Msg['DOCUMENT'] = 'Documento';
Blockly.Msg['VIDEO'] = 'Vídeo';
Blockly.Msg['BUTTONS'] = 'Botões';
Blockly.Msg['CAROUSEL'] = 'Carrossel';
Blockly.Msg['LIST'] = 'Lista';
Blockly.Msg['PAYLOAD'] = 'Payload';

Blockly.Msg['NAME'] = 'Nome';
Blockly.Msg['NICKNAME'] = 'Apelido';
Blockly.Msg['CPF'] = 'CPF';
Blockly.Msg['CEL'] = 'Celular';
Blockly.Msg['EMAIL'] = 'Email';
Blockly.Msg['ADDRESS'] = 'Endereço';
Blockly.Msg['NEIGHBORHOOD'] = 'Bairro';
Blockly.Msg['CITY'] = 'Cidade';
Blockly.Msg['STATE'] = 'Estado';
Blockly.Msg['CEP'] = 'CEP';
Blockly.Msg['COMPANY'] = 'Empresa';
Blockly.Msg['CNPJ'] = 'CNPJ';

Blockly.Msg['MSG'] = 'Mensagem';
Blockly.Msg['MSG_TOOLTIP'] = 'Envia mensagem para o usuário';
Blockly.Msg['MSG_AUDIO'] = 'Mensagem Texto para de Audio';
Blockly.Msg['MSG_AUDIO_TOOLTIP'] = 'Envia audio para o usuário';
Blockly.Msg['MSG_AUDIO_VOICE_TITLE'] = 'Envia mensagem de voz';
Blockly.Msg['MSG_AUDIO_VOICE'] = 'Tipo de voz';
Blockly.Msg['MSG_AUDIO_VOICE_TOOLTIP'] = 'Envia mensagem de voz para o usuário';
Blockly.Msg['MSG_AUDIO_VOICE_TEXT'] = 'Mensagem';
Blockly.Msg['CLEAR_VARS'] = 'Apaga TODAS as variáveis vars. ClearVars()';
Blockly.Msg['CLEAR_VARS_TOOLTIP'] =
    'ATENÇÃO: a funcão ClearVars() apaga todas as variaveis globais VARS de TODO o agente';
Blockly.Msg['AMBIENT_VARS_USER'] = 'Variável de ambiente user.';
Blockly.Msg['AMBIENT_VARS_USER_TOOLTIP'] =
    'Variáveis do ambiente do Agente do usuário';
Blockly.Msg['AMBIENT_VARS_USER_VALUE_TOOLTIP'] =
    'Atribuir valor às variáveis do ambiente do Bot do usuário';
Blockly.Msg['GLOBAL_VAR'] = 'Variável global';
Blockly.Msg['GET_VAR_TOOLTIP'] = 'Recupera o valor da variável global VARS';
Blockly.Msg['SET_VAR_TOOLTIP'] = 'Atribui valor a uma variável global VARS';
Blockly.Msg['VARIABLE'] = 'Variável';
Blockly.Msg['VARIABLE_TOOLTIP'] =
    'Recupera o valor da variável do dialogo do bloco';
Blockly.Msg['CREATE_TABLE'] = 'Gerador de Tabela';
Blockly.Msg['CREATE_TABLE_TOOLTIP'] =
    'Retorna a URL da tabela.  Veja documentação.';
Blockly.Msg['CREATE_CHART'] = 'Gerador de Gráfico';
Blockly.Msg['CREATE_CHART_TOOLTIP'] =
    'Retorna a URL do gráfico.  Veja documentação aqui:  https://quickchart.io/gallery/';
Blockly.Msg['IS_VALID_TEXT_TOOLTIP'] =
    'Retorna TRUE se for um string válido ou FALSE se não for, ou se for null';
Blockly.Msg['IS_VALID_DATE_TOOLTIP'] =
    'Retorna NULL se a data não for válida ou milis se for uma data válida';
Blockly.Msg['IS_VALID_CPF_TOOLTIP'] =
    'Retorna NULL se o CPF não for válido, e retorna o CPF sem caracteres especiais caso CPF seja válido';
Blockly.Msg['IS_VALID_CNPJ_TOOLTIP'] =
    'Retorna NULL se o CNPJ não for válido, e retorna o CNPJ sem caracteres especiais caso CNPJ seja válido';
Blockly.Msg['IS_VALID_CEP_TOOLTIP'] =
    'Retorna NULL se o CEP não for válido, e retorna um OBJETO CEP se for válido (.cep .uf .cidade .bairro .logradouro)';
Blockly.Msg['JSON_STRINGIFY_TOOLTIP'] =
    'Transforma JSON OBJ em texto. Retorna um STRING';
Blockly.Msg['JSON_PARSE_TOOLTIP'] =
    'Transforma JSON TEXTO em OBJ. Retorna um OBJETO';
Blockly.Msg['HTTP_REQUEST'] = 'Chamada a Requisição HTTP: Request';
Blockly.Msg['HTTP_REQUEST_TOOLTIP'] =
    'Retorna o objeto BODY da resposta se o retorno for 200 ou retorna false caso um erro ocorra. Aqui você precisa ler a documentação. É muito específico ;-)';
Blockly.Msg['HTTP_REQUEST_SUPPRESS_ERRORS'] =
    'Suprimir erros em ambiente DEV (TRUE/FALSE)';
Blockly.Msg['HTTP_REQUEST_ENABLE_ERROR_RETURN'] =
    'Habilitar retorno de erro (TRUE/FALSE)';
Blockly.Msg['ARRAY_TEXT'] = 'coloque\narray aqui';
Blockly.Msg['ARRAY_TOOLTIP'] = "Coloque aqui as linhas do Array sem o '[ ]'";
Blockly.Msg['JSON_TEXT'] = 'coloque\nJSON aqui';
Blockly.Msg['JSON_TOOLTIP'] =
    "Coloque aqui as linhas do Objeto JSON sem o '{ }'";
Blockly.Msg['TO_INT'] = 'Número inteiro de';
Blockly.Msg['TO_DEC'] = 'Número decimal de';
Blockly.Msg['TO_INT_TOOLTIP'] =
    'Retorna o número inteiro de um número ou uma string.';
Blockly.Msg['TO_DEC_TOOLTIP'] = 'Retorna o número decimal de um string.';
Blockly.Msg['TRANSLATE_TITLE'] = 'Traduz texto';
Blockly.Msg['TRANSLATE_SOURCE'] = 'Idioma de origem. ex: pt-br';
Blockly.Msg['TRANSLATE_TARGET'] = 'Idioma de destino. ex: en';
Blockly.Msg['TRANSLATE_TEXT'] = 'Texto a ser traduzido';
Blockly.Msg['TRANSLATE_TOOLTIP'] =
    'Traduz um texto de um idioma para outro idioma';
Blockly.Msg['MULTILINE_TEXT'] = 'Texto múltiplas linhas:';
Blockly.Msg['CONV_DATE'] = 'Converte data (D/M/A)';
Blockly.Msg['CONV_DATE_TOOLTIP'] = 'Coloque a data que deseja converter';
Blockly.Msg['CONV_HOUR'] = 'Converte para hora';
Blockly.Msg['CONV_HOUR_TOOLTIP'] = 'Coloque a hora que deseja converter';
Blockly.Msg['DATE_NOW'] = 'Data / Hora agora';
Blockly.Msg['DATE_NOW_TOOLTIP'] = 'Data / Hora atuais';
Blockly.Msg['SEND_SMS'] = 'Envia SMS';
Blockly.Msg['PHONE'] = '# Telefone';
Blockly.Msg['MESSAGE'] = 'Mensagem';
Blockly.Msg['SEND_SMS_TOOLTIP'] =
    'Envia uma mensagem SMS para um telefone (string tipo +551198555555 ou 551198555555)';
Blockly.Msg['ADD_LINE_GOOGLE_SHEET'] = 'Adiciona linha no GSheet';
Blockly.Msg['ADD_LINE_GOOGLE_SHEET_TOOLTIP'] =
    'Coloque o googlesheetid e a lista (array) com as células. Não esqueça de compartilhar a planilha com o email do LLego';
Blockly.Msg['ADD_HEADER_GOOGLE_SHEET'] = 'Grava cabeçalho no GSheet';
Blockly.Msg['ADD_HEADER_GOOGLE_SHEET_TOOLTIP'] =
    'Coloque o googlesheetid e a lista (array) com os titulos das colunas. Não esqueça de compartilhar a planilha com o email do LLego';
Blockly.Msg['READ_LINE_GOOGLE_SHEET'] = 'Lê linha do GSheet';
Blockly.Msg['READ_LINE_GOOGLE_SHEET_TOOLTIP'] =
    'Retorna uma lista com as celulas.  Não esqueça de compartilhar a planilha com o email do LLego';
Blockly.Msg['READ_CEL_GOOGLE_SHEET'] = 'Lê celula no GSheet formato número';
Blockly.Msg['READ_CEL_GOOGLE_SHEET_TOOLTIP'] =
    'Retorna uma lista com as celulas.  Não esqueça de compartilhar a planilha com o email do LLego';
Blockly.Msg['SAVE_CEL_GOOGLE_SHEET'] = 'Salva celula no GSheet formato número';
Blockly.Msg['SAVE_CEL_GOOGLE_SHEET_TOOLTIP'] =
    'Não esqueça de compartilhar a planilha com o email do LLego';
Blockly.Msg['READ_CEL_GOOGLE_SHEET_A1'] = 'Lê celula no GSheet formato A1';
Blockly.Msg['READ_CEL_GOOGLE_SHEET_A1_TOOLTIP'] =
    'Retorna uma lista com as celulas.  Não esqueça de compartilhar a planilha com o email do LLego';
Blockly.Msg['READ_REGION_GOOGLE_SHEET'] = 'Lê região no GSheet';
Blockly.Msg['READ_REGION_GOOGLE_SHEET_TOOLTIP'] =
    'Retorna JSON com as celulas.  Não esqueça de compartilhar a planilha com o email do LLego';
Blockly.Msg['READ_REGION_GOOGLE_SHEET_AS_TABLE'] =
    'Lê região como uma tabela no GSheet';
Blockly.Msg['READ_REGION_GOOGLE_SHEET_AS_TABLE_TOOLTIP'] =
    'Retorna JSON com as celulas, onde a primeira linha é o NOME da coluna no JSON. Não esqueça de compartilhar a planilha com o email do LLego';
Blockly.Msg['READ_REGION_GOOGLE_SHEET_AS_TABLE_A1'] =
    'Lê região como uma tabela no GSheet A1';
Blockly.Msg['READ_REGION_GOOGLE_SHEET_AS_TABLE_A1_TOOLTIP'] =
    'Retorna JSON com as celulas, onde a primeira linha é o NOME da coluna no JSON. Não esqueça de compartilhar a planilha com o email do LLego';

Blockly.Msg['TAB'] = 'aba #';
Blockly.Msg['LINE'] = 'linha #';
Blockly.Msg['COLUMN'] = 'coluna #';
Blockly.Msg['START_LINE'] = 'linha inicial #';
Blockly.Msg['END_LINE'] = 'linha final #';
Blockly.Msg['START_COLUMN'] = 'coluna inicial #';
Blockly.Msg['END_COLUMN'] = 'coluna final #';
Blockly.Msg['TAB_LINE_COLUMN_STARTS_AT_0'] = 'Aba, linha e coluna começam em 0';
Blockly.Msg['TAB_STARTS_AT_0'] = 'Aba começa em 0';
Blockly.Msg['LIST_WITH_CELLS'] = 'lista com células';
Blockly.Msg['LIST_WITH_TITLES_OF_COLUMNS'] = 'lista com titulos das colunas';
Blockly.Msg['TAB_LINE_STARTS_AT_0'] = 'aba e linha começam em 0';
Blockly.Msg['USE_RANGE_FORMAT_A1_Z9'] = 'usar o range no formato A1:Z9';
Blockly.Msg['ADDRESS_ON_FORMAT_A1'] = 'endereço no formato A1';
Blockly.Msg['VALUE_FOR_CELL'] = 'valor a ser gravado na celula';
Blockly.Msg['RANGE_ON_FORMAT_A1:B3'] = 'Range no formato A1:B3';

Blockly.Msg['FIELD'] = 'Campo';
Blockly.Msg['OF_USERID'] = 'do UserID';
Blockly.Msg['VALUE_ASSIGNED'] = 'Valor a ser atribuído';
Blockly.Msg['RETURNS_USERID'] = 'Retorna UserId se campo';
Blockly.Msg['HAS_TEXT'] = 'Contiver o texto';
Blockly.Msg['RETURNS_JSON_USERID'] = 'Retorna Json do UserId';
Blockly.Msg['INSERT_USERID'] = 'Coloque o UserId ou null';

Blockly.Msg['DB_READ_FIELD'] = 'Banco de Dados - Lê campo';
Blockly.Msg['DB_READ_FIELD_TOOLTIP'] =
    'Retorna o campo do UserId indicado, se UserId não existir retorna null';
Blockly.Msg['DB_SAVE_FIELD'] = 'Banco de Dados - Salva campo';
Blockly.Msg['DB_SAVE_FIELD_TOOLTIP'] = 'Atribui ao campo escolhido um valor';
Blockly.Msg['DB_SEARCH_FIELD'] = 'Banco de Dados Usuários - Pesquisa campo';
Blockly.Msg['DB_SEARCH_FIELD_TOOLTIP'] =
    'Pesquisa e retorna o array dos userids que contiverem o valor';
Blockly.Msg['DB_ALL_FIELDS'] = 'Banco de Dados - Todos os campos';
Blockly.Msg['DB_ALL_FIELDS_TOOLTIP'] =
    'Retorna todos os campos do userid definido ou null se não existir';
Blockly.Msg['DB_READ_CREATE_USER'] = 'Banco de Dados - Cria user';
Blockly.Msg['DB_READ_CREATE_USER_TOOLTIP'] =
    'Você pode criar um userid ou null se quiser que o banco crie para voce. o retorno é o userid criado';

Blockly.Msg['CALL_TO_PWA'] = 'Chamada a PWA';
Blockly.Msg['CALL_TO_PWA_TOOLTIP'] =
    'Aqui você precisa ler a documentação. É muito específico ;-)';
Blockly.Msg['NAME_HTML'] = 'Nome HTML';
Blockly.Msg['NAME_BLOCK'] = 'Nome de Bloco de Retorno';
Blockly.Msg['PAYLOAD_JSON'] = 'Payload no formato JSON - opcional';

Blockly.Msg['BLOCK_JSON'] = 'Bloco JSON';
Blockly.Msg['DOUBLE_JSON'] = 'Dupla JSON';
Blockly.Msg['VALUE'] = 'VALOR';

Blockly.Msg['CRM_COMPANY'] = 'CRM Empresa: Campo';
Blockly.Msg['CRM_SEARCH_COMPANY_DATA'] = 'CRM - Busca dados da empresa';
Blockly.Msg['CRM_SEARCH_COMPANY_DATA_CONTAIN'] = 'Contém';
Blockly.Msg['CRM_SEARCH_COMPANY_DATA_TOOLTIP'] = 'Busca os dados da empresa';
Blockly.Msg['CRM_SAVE_COMPANY_DATA'] = 'CRM - Atualiza dados da empresa';
Blockly.Msg['CRM_NEW_COMPANY_DATA'] = 'CRM -Insere novo dado da empresa';
Blockly.Msg['CRM_ERASE_COMPANY_DATA'] = 'CRM - APAGA dados da empresa';

Blockly.Msg['BLOCK_CALL_TRIGGER'] = 'Gatilho de chamada de bloco';
Blockly.Msg['BLOCK_CALL_TRIGGER_TOOLTIP'] =
    'Verifique a documentação para uso do gatilho';
Blockly.Msg['NAME_OF_THE_RULE'] = 'Nome da regra';
Blockly.Msg['START'] = 'Início';
Blockly.Msg['END'] = 'Fim';
Blockly.Msg['REPEAT_EVERY'] = 'Repetir a cada';
Blockly.Msg['DAY'] = 'dia';
Blockly.Msg['WEEK'] = 'semana';
Blockly.Msg['MONTH'] = 'mês';
Blockly.Msg['YEAR'] = 'ano';
Blockly.Msg['HOUR'] = 'hora';
Blockly.Msg['DAYS_OF_WEEK'] = 'Dias da semana';
Blockly.Msg['SUNDAY'] = 'Domingo';
Blockly.Msg['MONDAY'] = 'Segunda';
Blockly.Msg['TUESDAY'] = 'Terça';
Blockly.Msg['WEDNESDAY'] = 'Quarta';
Blockly.Msg['THURSDAY'] = 'Quinta';
Blockly.Msg['FRIDAY'] = 'Sexta';
Blockly.Msg['SATURDAY'] = 'Sábado';
Blockly.Msg['DEFINE_BLOCK'] = 'Defina o bloco a ser ativado no gatilho';
Blockly.Msg['CALL_BLOCK'] = 'Chamar o bloco';

Blockly.Msg['FUNCTION'] = 'Função';
Blockly.Msg['DISTANCE'] = 'Distancia entre 2 pontos (lat/long)';
Blockly.Msg['VARIABLE'] = 'variável';

Blockly.Msg['PROVIDER'] = 'PROVIDER';
Blockly.Msg['PROVIDER_TOOLTIP'] =
    'Conecta ao setor definido do chat humano e ao retornar acessa o grupo/bloco de retorno';
Blockly.Msg['SECTOR'] = 'Setor';
Blockly.Msg['RETURN_GROUP'] = 'Grupo de Retorno';
Blockly.Msg['RETURN_BLOCK'] = 'Bloco de Retorno';
Blockly.Msg['LATITUDE'] = 'Latitude';
Blockly.Msg['LONGITUDE'] = 'Longitude';
Blockly.Msg['TASK_ATTRIBUTES_ONLY_TWILLO'] =
    'Atributos de Tarefa';
Blockly.Msg['WAIT'] = 'Aguarda';
Blockly.Msg['WAIT_SECONDS'] = '(# de segundos)';
Blockly.Msg['WAIT_TOOLTIP'] = 'Aguarda entre 0 e 10 segundos';
Blockly.Msg['WORKFLOW_TITLE'] = 'Conector de fluxo';
Blockly.Msg['WORKFLOW_PROVIDER'] = "Provedor do workflow";
Blockly.Msg['WORKFLOW_IDENTIFIER'] = "Identificador do Node";
Blockly.Msg['WORKFLOW_JSON'] = "JSON do Node";
Blockly.Msg['WORKFLOW_TOOLTIP'] = "Conecta a um workflow identificado passando o JSON dos dados";