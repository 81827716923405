import EIntentType from 'enums/EIntentType';
import { useStyles } from '../styles';
import { ListItem, ListItemIcon } from '@material-ui/core';

type ModalOptionsItemProps = {
  intentType: EIntentType;
  icon: JSX.Element;
  name: string;
  handleCreateIntent: (intentType: EIntentType) => void;
};

export function ModalOptionsItem({
  icon,
  name,
  intentType,
  handleCreateIntent,
}: ModalOptionsItemProps) {
  const classes = useStyles();

  return (
    <ListItem button onClick={() => handleCreateIntent(intentType)}>
      <ListItemIcon className={classes.listItem}>
        {icon}
        {name}
      </ListItemIcon>
    </ListItem>
  );
}
