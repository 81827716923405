import React from "react";
import { useForm } from "contexts/Form/formContext";
import ModalLogic from "../../ModalLogic";

import styles from "./styles";

const { Container, Btn } = styles;

export default function LogicEditor() {
  const { state, dispatch } = useForm();

  const handleOpenModalLogicEditor = () => {
    dispatch({
      type: "updateForm",
      data: { ...state, openModalLogicEditor: true },
    });
  };
  return (
    <Container>
      <div>Ícone</div>
      <Btn onClick={handleOpenModalLogicEditor}>Editar lógica</Btn>
      <textarea disabled></textarea>
      <ModalLogic />
    </Container>
  );
}
