import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { reducerApp, initialState } from './appReducers';
import { IAppContext, IAppProviderProps } from './types';

export const AppContext = createContext<IAppContext>({} as IAppContext);

export const AppProvider: React.FC<IAppProviderProps> = (
  props: IAppProviderProps
) => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducerApp, initialState);

  useEffect(() => {
    async function load() {
      const token = localStorage.getItem('token');
      const channel = new BroadcastChannel('tab');

      window.onbeforeunload = () => {
        channel.postMessage('reload-tab');
      };

      channel.postMessage('another-tab');

      channel.addEventListener('message', (msg) => {
        if (msg.data === 'another-tab') {
          channel.postMessage('tab already open');
        } else if (msg.data === 'tab already open') {
          dispatch({
            type: 'openModalUsedTab',
            data: { renderModalUsedTab: true },
          });
        } else if (msg.data === 'reload-tab' && state.renderModalUsedTab) {
          dispatch({
            type: 'openModalUsedTab',
            data: { renderModalUsedTab: false },
          });
        }
      });

      let data = { signed: false, load: false };
      if (!!token) data = { signed: true, load: false };

      dispatch({ type: 'updateApp', data });
    }
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider
      value={{
        state: state || initialState,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useApp(): IAppContext {
  const context = useContext(AppContext);

  return context;
}
