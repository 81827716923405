import React from 'react';

import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import * as Icons from '@material-ui/icons';

const DefaultIcon = Icons.HelpOutline;

export type TKeyIcon = keyof typeof Icons;
interface IIcons {
  [key: string]: OverridableComponent<
    SvgIconTypeMap<Record<string, unknown>, 'svg'>
  >;
}

export function Icon(
  iconName: TKeyIcon
): OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> {
  const TypedIcons: IIcons = Icons;
  const SelectedIcon = TypedIcons[iconName];
  return SelectedIcon || DefaultIcon;
}

export function getIcon(iconName: TKeyIcon): JSX.Element {
  const TypedIcons: IIcons = Icons;
  const SelectedIcon = TypedIcons[iconName];
  return SelectedIcon ? (
    <SelectedIcon fontSize="inherit" color="inherit" />
  ) : (
    <DefaultIcon fontSize="inherit" color="inherit" />
  );
}
