import React, { useCallback, useEffect } from 'react';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { useForm } from 'contexts/Form/formContext';
import { Linked } from 'models/DataFlow';
import DataFormModel from 'models/DataFormModel';
import { GetGroup } from 'services/FlowService';
import useTranslator from 'utils/hooks/Translator';
import { TSelectOption } from 'components/Material/MttSelect/types';
import MttSelectField from 'components/Material/MttSelectField/MttSelectField';
import { TSelectChangeEvent } from 'components/Material/MttSelectField/types';
import { Container, Content, StyledMttTypography } from './styles';

export default function ToNewIntention() {
  const { groupsNames, botName } = useFlow().state;
  const { state, dispatch } = useForm();
  const { dataForm, formErrors, intents } = state;
  const { dispatch: dispatchApp } = useApp();
  const { getTranslation } = useTranslator();

  const getIntentsByGroupId = useCallback(
    async (groupId: string) => {
      if (groupId) {
        const result = await GetGroup(
          {
            bot_name: botName || '',
            groupName: groupId,
          },
          dispatchApp
        );

        if (result.Success && !!result.Data.data.blocks.drawflow) {
          const data = result.Data.data.blocks.drawflow.Home.data;
          const keys = Object.keys(data);
          return keys
            .filter((key) => {
              const dataModel = new DataFormModel(data[Number(key)].data);
              return !dataModel.isChild() && !dataModel.isOthers();
            })
            .map((key) => {
              const { name } = data[Number(key)].data;
              return {
                id: name,
                name,
              };
            });
        }
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [botName]
  );

  const load = useCallback(async () => {
    if (!!dataForm && !dataForm?.linked) {
      dataForm.linked = {
        groupId: '',
        intentId: '',
      };
      dispatch({ type: 'updateForm', data: { dataForm } });
    }
  }, [dataForm, dispatch]);

  const getIntents = async () => {
    const intents = await getIntentsByGroupId(dataForm?.linked?.groupId || '');
    dispatch({ type: 'updateForm', data: { intents } });
  };

  useEffect(() => {
    getIntents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeSelect =
    (key: keyof Linked) => async (e: TSelectChangeEvent) => {
      if (dataForm?.linked) {
        dataForm.linked[key] = String(e.target.value);
        delete state.formErrors[`linked.${key}`];
        if (key === 'groupId') {
          state.intents = await getIntentsByGroupId(dataForm.linked[key]);
        }
        dispatch({ type: 'updateForm', data: state });
      }
    };

  return (
    <Container>
      <StyledMttTypography variant="h6" color="primary">
       {getTranslation('jumpToAnotherBlock')}
      </StyledMttTypography>
      <Content>
        <MttSelectField
          name="groupId"
          label={getTranslation('group')}
          value={dataForm?.linked?.groupId}
          options={groupsNames.map((group) => {
            return { id: group, name: group } as TSelectOption;
          })}
          error={!!formErrors['linked.groupId']}
          errorMessage={formErrors['linked.groupId']}
          onChange={handleChangeSelect('groupId')}
          placeholder={getTranslation('selectOne')}
        />
        <MttSelectField
          name="intentId"
          label={getTranslation('block')}
          value={dataForm?.linked?.intentId}
          options={intents}
          error={!!formErrors['linked.intentId']}
          errorMessage={formErrors['linked.intentId']}
          onChange={handleChangeSelect('intentId')}
          placeholder={getTranslation('selectOne')}
        />
      </Content>
    </Container>
  );
}
