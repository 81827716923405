import { DataFlow } from 'models/DataFlow';

export default class GetIntentsByProperties {
  getIntents(blocks: DataFlow, search: string) {
    return  Object.keys(blocks).filter(
      (key) => {
        const questions = blocks[Number(key)].data.inputs?.variables[0]?.questions.map(question => question.toLocaleLowerCase())
        
        if (blocks[Number(key)].data.name.toLocaleLowerCase().indexOf(search) > -1) {
          return true
        }
        if (blocks[Number(key)].data.description.toLocaleLowerCase().indexOf(search) > -1) {
          return true
        }
        if (blocks[Number(key)].data.sendUser?.message?.toLocaleLowerCase().includes(search)) {
          return true
        }
        if (questions?.includes(search)) {
          return true
        }
      

        return false
      }
    );
  }
}
