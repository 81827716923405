import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useForm } from 'contexts/Form/formContext';
import CloseIcon from '@material-ui/icons/CloseRounded';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import * as S from './styles';
import { ISentencesProps } from './types';
import { Autocomplete } from '@material-ui/lab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useTranslator from 'utils/hooks/Translator';
interface IIdNameEntity {
  id: string;
  name: string;
}

export default function OptionsBlockOutputsSentences(props: Readonly<ISentencesProps>) {
  const {
    index,
    choice,
    showRemoveButton,
    isFalaGptBlock,
    // falaGPTContextsNames,
    entities,
  } = props;
  const { state, dispatch } = useForm();
  const { dataForm, formErrors } = state;
  const [inputValue, setInputValue] = useState<string>('');
  const values = choice.userSentences ?? [''];
  const [showInfo, setShowInfo] = useState(false);
  const [types, setTypes] = useState<IIdNameEntity[]>([]);
  const { getTranslation } = useTranslator();
  const isChatGptOutput = choice.title === 'AI123';

  function compareToSortNames(a: IIdNameEntity, b: IIdNameEntity): 1 | -1 | 0 {
    if (a.name > b.name) return 1;
    if (b.name > a.name) return -1;
    return 0;
  }

  const load = useCallback(async () => {
    if (dataForm && choice && choice.title !== 'Outros') {
      if (!choice.userSentences) {
        choice.userSentences = [''];
      }

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
    let allTypes: IIdNameEntity[] = [];

    if (entities) {
      allTypes = [
        ...allTypes,
        ...entities.map((entity) => ({
          id: entity.name,
          name: entity.name,
        })),
      ].sort(compareToSortNames);

      allTypes.forEach((type) => {
        if (!type.id.startsWith('@')) {
          type.id = '@' + type.id;
        }
      });
    }

    setTypes(allTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities, choice, index]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeTitle = (newTitle: string) => {
    if (dataForm?.outputs?.[index]) {
      if (choice.title !== 'Outros') {
        values[0] = newTitle;
        if (choice.userSentences) choice.userSentences[0] = newTitle;
        choice.title = newTitle;
        dataForm.outputs[index] = choice;

        if (dataForm) {
          dispatch({
            type: 'updateForm',
            data: {
              dataForm: {
                ...dataForm,
              },
            },
          });
        }
      }
    }
  };

  const handleChangeGPTContext = (newContext: string) => {
    if (dataForm?.outputs?.[index]) {
      if (choice.title !== 'Outros') {
        choice.falaGPTContext = newContext;
        dataForm.outputs[index] = choice;

        if (dataForm) {
          dispatch({
            type: 'updateForm',
            data: {
              dataForm: {
                ...dataForm,
              },
            },
          });
        }
      }
    }
  };

  const handleChangeChip = (checked: boolean) => {
    if (dataForm?.outputs?.[index]) {
      choice.isChip = checked;
      dataForm.outputs[index].isChip = checked;

      if (dataForm) {
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  };

  const handleDeleteOption = () => {
    if (!!dataForm && dataForm.outputs) {
      dataForm.outputs.splice(
        dataForm.outputs.findIndex((c) => c.title === choice.title),
        1
      );

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleDeleteSynonym = (phraseIndex: number) => {
    if (!!dataForm && dataForm.outputs) {
      values.splice(phraseIndex, 1);
      dataForm.outputs[index].userSentences = values;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleInsertValue = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (choice.title !== 'Outros') {
      if (event.keyCode === 13 || event.keyCode === 9) {
        if (inputValue !== '') {
          values.push(inputValue);

          if (!!dataForm && dataForm.outputs) {
            dataForm.outputs[index].userSentences = values;
            dispatch({
              type: 'updateForm',
              data: {
                dataForm: {
                  ...dataForm,
                },
              },
            });
          }
        }
        setInputValue('');
        const input = event.currentTarget.children[0]
          .children[0] as HTMLDivElement;
        input.blur();
        input.focus();
      }
    }
  };

  const handleShowSynonyms = (item: string, index: number) => {
    if (item !== '') {
      const isVariable = item.startsWith('@');
      return isVariable
        ? returnVariables(item, index)
        : returnRegulars(item, index);
    } else return <></>;
  };

  const returnVariables = (item: string, index: number) => {
    return (
      <S.GridSynonymVariable key={index}>
        <Tooltip
          title={
            entities
              .find((t) => `@${t.name}` === item)
              ?.values.map((v) => v.synonyms)
              .map((s) => s)
              .join(',') ?? ''
          }
        >
          <VisibilityIcon fontSize={'small'} />
        </Tooltip>
        {item}
        {index !== 0 && (
          <IconButton onClick={() => handleDeleteSynonym(index)} size="small">
            <CloseIcon />
          </IconButton>
        )}
      </S.GridSynonymVariable>
    );
  };

  const returnRegulars = (item: string, index: number) => {
    return (
      <S.GridSynonymRegular key={index}>
        {item}
        {index !== 0 && (
          <IconButton onClick={() => handleDeleteSynonym(index)} size="small">
            <CloseIcon />
          </IconButton>
        )}
      </S.GridSynonymRegular>
    );
  };

  const addSynonymByVariable = (event: ChangeEvent<{}>, varName: string) => {
    if (!!varName) {
      values.push(varName);

      if (!!dataForm && dataForm.outputs) {
        dataForm.outputs[index].userSentences = values;
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  };

  return (
    <S.Container>
      <S.ContainerOptionHeader>
        <S.OptionIndexAndTip>
          <Typography variant="h6" color="primary">
            {isChatGptOutput ? (
              <>Fala GPT</>
            ) : (
              <>
                {getTranslation('option')} #{' '}
                {isFalaGptBlock ? index : index + 1}
              </>
            )}
          </Typography>
          {!isChatGptOutput && (
            <>
              <IconButton onClick={() => setShowInfo(!showInfo)}>
                <Tooltip title={getTranslation('hint')} placement="right">
                  <InfoIcon htmlColor="#0e1039" />
                </Tooltip>
              </IconButton>

              <FormControlLabel
                style={{ marginLeft: '35%' }}
                control={
                  <Checkbox
                    checked={choice.isChip}
                    onChange={(e) => handleChangeChip(e.target.checked)}
                    color="primary"
                  />
                }
                label={getTranslation('showAsButton')}
              />
            </>
          )}
        </S.OptionIndexAndTip>
        {choice.title !== 'Outros' && !isChatGptOutput && showRemoveButton && (
          <IconButton onClick={() => handleDeleteOption()}>
            <DeleteIcon style={{ alignSelf: 'center' }} htmlColor="#0e1039" />
          </IconButton>
        )}
      </S.ContainerOptionHeader>
      {isChatGptOutput ? (
        <></>
      ) : index === 0 ? (
        <S.ContainerOptionHeader>
          <S.HeaderSpan>
            {getTranslation('variables.hintWords')} <strong>TAB</strong>{' '}
            {getTranslation('or')} <strong>ENTER</strong>.
          </S.HeaderSpan>
        </S.ContainerOptionHeader>
      ) : (
        <S.ContainerOptionHeader>
          {showInfo && (
            <S.HeaderSpan>
              {getTranslation('variables.hintWords')} <strong>TAB</strong>{' '}
              {getTranslation('or')} <strong>ENTER</strong>.
            </S.HeaderSpan>
          )}
        </S.ContainerOptionHeader>
      )}

      <S.ContainerOptionContent>
        <S.GridDefinition>
          <TextField
            value={choice.title}
            onChange={(e) => handleChangeTitle(e.target.value)}
            label={getTranslation('outputTitle')}
            helperText={formErrors[`outputs[${index}].title`]}
            error={!!formErrors[`outputs[${index}].title`]}
            disabled={isChatGptOutput}
          />
        </S.GridDefinition>
        {isChatGptOutput ? (
          <S.GridDefinition>
            <TextField
              value={choice.falaGPTContext}
              onChange={(e) => handleChangeGPTContext(e.target.value)}
              label="Informe o contexto"
              fullWidth
              style={{ marginBottom: '14px' }}
            />
            {/* <Autocomplete
              options={falaGPTContextsNames || []}
              popupIcon={null}
              value={choice.falaGPTContext}
              fullWidth
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => handleChangeGPTContext(e.target.value)}
                  variant="standard"
                  disabled={!falaGPTContextsNames}
                  value={choice.falaGPTContext}
                  placeholder="Escolha um contexto para o bloco"
                  onKeyDown={(event) => handleInsertValue(event)}
                />
              )}
              noOptionsText={getTranslation('noResults')}
              onChange={(e, value) => handleChangeGPTContext(value || '')}
            /> */}
          </S.GridDefinition>
        ) : (
          <>
            <S.GridInputDefinition>
              <Autocomplete
                options={types.map((t) => t.id)}
                open={!!inputValue && inputValue.length > 2}
                onBlur={() => setInputValue('')}
                popupIcon={null}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => setInputValue(e.target.value)}
                    variant="standard"
                    disabled={choice.title === ''}
                    fullWidth
                    value={
                      choice.title === 'Outros' ? choice.title : inputValue
                    }
                    placeholder={`${getTranslation('variables.typeSynonym')} "${
                      choice.title || ''
                    }"`}
                    onKeyDown={(event) => handleInsertValue(event)}
                  />
                )}
                noOptionsText={getTranslation('noResults')}
                onChange={(e, value) => addSynonymByVariable(e, value || '')}
              />
            </S.GridInputDefinition>
            <S.ContentSynonyms>
              {choice.userSentences?.map((item, index) =>
                handleShowSynonyms(item, index)
              )}
            </S.ContentSynonyms>
          </>
        )}
      </S.ContainerOptionContent>
    </S.Container>
  );
}
