import { ToastType } from 'contexts/Flow/types';
import { IModalBlockOptionsProps } from '../types';
export interface IBlockAccessCountProps extends IModalBlockOptionsProps {
  blocksData: { [key: string]: number };
  name: string;
  isShowing?: boolean;
  state: any;
  nodeId: number;
  intentType: number;
  toastNotification: (
    type: ToastType,
    message: string,
    duration?: number
  ) => void;
}

export interface ColorsAndBorders {
  [key: string]: { border: string; background: string };
}

export interface ColorAndBorder {
  border: string;
  background: string;
}

export const HeatMapColorsAndBorders: ColorsAndBorders = {
  hot: {
    border: '#D27C5D',
    background: '#FE926C',
  },
  warm: {
    border: '#D2AE7A',
    background: '#FDD294',
  },
  medium: {
    border: '#CBCB98',
    background: '#FDFDBE',
  },
  cold: {
    border: '#8BCB94',
    background: '#AFFFBA',
  },
  colder: {
    border: '#81B7AC',
    background: '#DAFAF4',
  },
};
