import { useTranslation } from 'react-i18next';
import { getTranslationKeys } from '../i18n/types';

export default function useTranslator() {
  const { t } = useTranslation();

  function getTranslation(key: getTranslationKeys, params?: any) {
		return t(key, params);
  }

  return {
    getTranslation,
  };
}
