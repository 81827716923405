import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttButton from 'components/Material/MttButton/MttButton';
import MttTypography from 'components/Material/MttTypography/MttTypography';

export const ContainerHeader = styled(MttGrid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiButton-contained {
    width: 180px;
    height: 32px;

    color: #ffffff;
    background-color: #34ac26;

    &:hover {
      background-color: #2d9421;
    }
  }
`;

export const StyledDownloadEntitiesButton = styled(MttButton).attrs({})`
  margin-left: 16px;
`;

export const StyledCriarEntityButton = styled(MttButton)``;

export const StyledMttTypographyEntities = styled(MttTypography)``;

export const GridSearch = styled(MttGrid)`
  margin-top: 16px;
`;

export const GridResultEntity = styled(MttGrid)`
  width: 100%;
  max-height: calc(100vh - 40vh);

  margin-top: 16px;

  overflow-x: hidden;
  overflow-y: auto;
`;
