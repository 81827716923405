import React from 'react';

import Box from '@material-ui/core/Box/Box';

import { IBoxProps } from './types';

const MttBox: React.FC<IBoxProps> = (props: IBoxProps) => {
  const { children } = props;

  return <Box {...props}>{children}</Box>;
};

export default MttBox;