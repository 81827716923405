import React from "react";

import { FormControl, InputLabel,FormHelperText , MenuItem, Select } from "@material-ui/core";


import { IMttSelectFieldProps } from "./types";

const MttSelectField: React.FC<IMttSelectFieldProps> = (
  props: IMttSelectFieldProps
) => {
  const {
    name,
    label,
    value,
    options,
    required,
    error,
    errorMessage,
    disabled,
    fullWidth,
    children,
		placeholder,
    onChange,
  } = props;
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={`${name}-simple-select-outlined-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}-simple-select-outlined-label`}
        id={`${name}-simple-select-outlined`}
        name={name}
        label={label}
        value={value}
        required={required}
        error={error}        
        disabled={disabled}
        fullWidth={fullWidth}
        onChange={onChange}
      >
        <MenuItem disabled value="">
          {`${placeholder} ${label ? label : "Item"}`}
        </MenuItem>
        {options?.map((option, index) => (
          <MenuItem key={index} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
        {children && <MenuItem>{children}</MenuItem>}
      </Select>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default MttSelectField;
