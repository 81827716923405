import { useCallback, useEffect, useMemo } from "react";
import { useFlow } from "contexts/Flow/flowContext";
import { useForm } from "contexts/Form/formContext";
import { Linked } from "models/DataFlow";
import Select from "../../Constructors/Select";
import styles from "./styles";

const { Container } = styles;

export default function ToNewIntention() {
  const { groupsNames, botName } = useFlow().state;
  const { state, dispatch } = useForm();
  const { dataForm, intents } = state;

  const groupsWithoutCurrent = useMemo(() => {
    return groupsNames.filter((group) => group !== state.dataForm?.groupId);
  }, [groupsNames, state.dataForm?.groupId]);

  const getIntentsByGroupId = useCallback(
    (groupId: string) => {
      const keyFlowCurrent = (botName || "") + "$$" + (groupId || "");
      const json = localStorage.getItem(keyFlowCurrent);
      if (json) {
        const dataFlow = JSON.parse(json);
        const itens = Object.keys(dataFlow.drawflow.Home.data).map((key) => {
          const { name } = dataFlow.drawflow.Home.data[key].data;
          return {
            id: name,
            name,
          };
        });
        return itens;
      }
      return [];
    },
    [botName]
  );

  const load = useCallback(() => {
    if (!!dataForm && !dataForm?.linked) {
      dataForm.linked = {
        groupId: "",
        intentId: "",
      };
    }
    if (state.dataForm?.linked) {
      const firstGroup = groupsNames.find((_, index) => index === 0);
      if (!!firstGroup) {
        state.dataForm.linked.groupId = firstGroup;
      }
    }
    const intents = getIntentsByGroupId(state.dataForm?.linked?.groupId || "");
    if (!!intents.length && dataForm?.linked) {
      dataForm.linked.intentId = intents[0].id;
    }
    dispatch({
      type: "updateForm",
      data: {
        dataForm,
        intents,
      },
    });
  }, [
    dataForm,
    dispatch,
    getIntentsByGroupId,
    groupsNames,
    state?.dataForm?.linked,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeSelect = (key: keyof Linked) => (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (dataForm?.linked) {
      dataForm.linked[key] = e.target.value;
      if (key === "groupId") {
        state.intents = getIntentsByGroupId(dataForm.linked[key]);
        const intents = getIntentsByGroupId(
          state.dataForm?.linked?.groupId || ""
        );
        if (!!intents.length) {
          if (dataForm?.linked) {
            dataForm.linked.intentId = intents[0].id;
          }
        } else {
          dataForm.linked.intentId = "";
        }
      }
      dispatch({ type: "updateForm", data: state });
    }
  };

  return (
    <>
      Pular para bloco
      <Container>
        <Select
          options={groupsWithoutCurrent}
          name="select-group"
          value={dataForm?.linked?.groupId}
          onChange={handleChangeSelect("groupId")}
        >
          Grupo
        </Select>

        <Select
          options={intents}
          name="select-intent"
          value={dataForm?.linked?.intentId}
          onChange={handleChangeSelect("intentId")}
        >
          Bloco
        </Select>
      </Container>
    </>
  );
}
