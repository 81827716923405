import FooterButtons from 'components/inputs/Material/FooterButtons';
import Info from 'components/inputs/Material/Info';
import RulesBlockContent from 'components/inputs/Material/RulesBlockContent';
import { FormFieldsContainer } from '../Shared/styles';

export default function Rules() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <RulesBlockContent />
      </FormFieldsContainer>
      <FooterButtons showLogicButton={false} />
    </>
  );
}
