import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { CRMElements } from 'contexts/FintalkCloud/types';
import { Customers } from './Customers';
import { CustomParams } from './CustomParams';

export function CRM() {
  const { crmElementToOpen } = useFintalkCloud();

  const getCRMElement: {
    [keyof in CRMElements]: JSX.Element;
  } = {
    customers: <Customers />,
    customParams: <CustomParams />,
  };

  return <>{crmElementToOpen && getCRMElement[crmElementToOpen]}</>;
}
