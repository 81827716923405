import styled from 'styled-components';

export const Container = styled.div<{
  width: number;
  height: number;
  windowWidth: number;
  windowHeight: number;
}>`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 360px;
  min-height: 300px;

  width: ${(props) => props.width};
  height: ${(props) => props.height};

  max-width: ${(props) => `${props.windowWidth - props.windowWidth * 0.1}px`};
  max-height: ${(props) =>
    `${props.windowHeight - props.windowHeight * 0.1}px`};

  border-radius: 22px;

  background: #585858;
  background: linear-gradient(#646464, #585858, #2b2929, #201e1e);

  box-shadow: 0 5px 10px #646464;

  padding: 8px;

  .MuiIconButton-root {
    position: absolute;
    right: 5px;
  }

  z-index: 9999;
  overflow: hidden;
  resize: both;
`;

export const ContainerHeader = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  height: 60px;
  cursor: grab;

  font-size: 1.5rem;
`;

export const ConsoleHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;

  font-size: 1rem;

  .MuiSvgIcon-root {
    color: ${(props) =>
      props.theme.color === '#000000' ? 'rgba(0, 0, 0, 0.54)' : '#fff687'};
  }
`;

export const IConsole = styled.div`
  width: 100%;
  height: 100%;

  border: 0;
  border-radius: 3px;

  background-color: ${(props) => props.theme.background};
  border: 1px solid black;
  line-height: 1.5em;
  overflow: auto;
  margin: 10px 5px 5px 5px;

	flex: 1;

  color: ${(props) => props.theme.color};
  font-family: 'Lucida Console', 'Courier New', monospace;
  font-size: 90%;

  p {
    word-break: break-word;
    padding: 8px 16px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;
