import { NewDrawFlow } from 'contexts/Flow/types';
import { DataFlow } from 'models/DataFlow';

export default class GetOffsetPositionFlowController {
  protected editorFlow: NewDrawFlow;

  constructor(editorFlow: NewDrawFlow) {
    this.editorFlow = editorFlow;
  }

  getLastPosition() {
    const data: DataFlow = this.editorFlow.drawflow.drawflow.Home.data;
    const keys = Object.keys(data);
    if (!keys.length) return { pos_x: 0, pos_y: 0 };
    const key = keys[keys.length - 1];
    const node = data[Number(key)];
    const { pos_x, pos_y } = node;
    return { pos_x, pos_y };
  }

  getBiggestPositions(pos_x: number[], pos_y: number[]) {
    if (!pos_x.length || !pos_y.length) return this.getLastPosition();
    const biggestPos_x = Math.min(...pos_x);
    const biggestPos_y = Math.max(...pos_y);
    return { pos_x: biggestPos_x - 400, pos_y: biggestPos_y + 260 };
  }

  getRelativePosition(block: DataFlow) {
    const keys = Object.keys(block);
    const positionsX = keys.map((key) => block[Number(key)].pos_x);
    const positionsY = keys.map((key) => block[Number(key)].pos_y);
    return this.getBiggestPositions(positionsX, positionsY);
  }

  getMiddleCanvasPosition() {
    if (!this.editorFlow.precanvas) {
      return this.getLastPosition()
    }

    const { width, height } = this.editorFlow.precanvas.getBoundingClientRect()

    const x = width / 2
    const y = height / 2

    let pos_x = x * ( this.editorFlow.precanvas?.clientWidth / (this.editorFlow.precanvas?.clientWidth * this.editorFlow.zoom)) - (this.editorFlow.precanvas?.getBoundingClientRect().x * ( this.editorFlow.precanvas?.clientWidth / (this.editorFlow.precanvas?.clientWidth * this.editorFlow.zoom)));
    let pos_y = y * ( this.editorFlow.precanvas?.clientHeight / (this.editorFlow.precanvas?.clientHeight * this.editorFlow.zoom)) - (this.editorFlow.precanvas?.getBoundingClientRect().y * ( this.editorFlow.precanvas?.clientHeight / (this.editorFlow.precanvas?.clientHeight * this.editorFlow.zoom)));

    return { pos_x: pos_x + 600, pos_y: pos_y + 600}
  }
}