import { IAppContextActions } from '../contexts/App/types';
import createBotObject from './CreateBotObject';

export default async function exportBot(
  botName: string,
  dispatch: React.Dispatch<IAppContextActions>
) {
  const botToStringify = await createBotObject(botName || '', dispatch);
  const documentHtml = document.getElementById('drawflow');

  const botReadyToExport =
    'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(botToStringify));

  if (botReadyToExport) {
    const dlAnchorElem = document.createElement('a');
    documentHtml?.appendChild(dlAnchorElem);
    if (dlAnchorElem) {
      dlAnchorElem.setAttribute('href', botReadyToExport);
      const dateGenerated = new Date();
      const dataFormated =
        dateGenerated.getDate() +
        '-' +
        dateGenerated.getMonth() +
        '-' +
        dateGenerated.getFullYear() +
        '-' +
        dateGenerated.getHours() +
        '-' +
        dateGenerated.getMinutes();
      dlAnchorElem.setAttribute(
        'download',
        `dataflow-${botName}-${dataFormated}.json`
      );
      dlAnchorElem.click();
      dlAnchorElem.parentNode?.removeChild(dlAnchorElem);
    }
  }
}
