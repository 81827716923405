import { MessageButtonsContainer } from '../styles';
import * as S from './styles';

import CallIcon from '@material-ui/icons/Call';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ITemplateButtons } from '../types';
import useTranslator from 'utils/hooks/Translator';
import { ITemplateComponents } from 'services/CampaignService/TemplatesService/types';

interface PreviewProps {
  header: ITemplateComponents;
  body: string;
  footer: string;
  templateButtons: ITemplateButtons;
	imgUrl: string;
}

export function Preview({
  templateButtons,
  header,
  body,
  footer,
	imgUrl
}: PreviewProps) {
  const { getTranslation } = useTranslator();

  const isImage = header.format === 'IMAGE';
  const previewHeader = isImage ? imgUrl : header.text || '';

	return (
    <S.NewTemplatePreview>
      <S.PreviewContainer>
        <strong>{getTranslation('preview')}</strong>
        <S.MessagePreviewContainer>
          <S.MessagePreview>
            <S.MessageContent>
              <S.MessageHeader>
                {isImage ? (
                  <img src={previewHeader} alt="" />
                ) : (
                  <>{previewHeader || ''}</>
                )}
              </S.MessageHeader>
              <S.MessageBody hasContent={!!body}>{body}</S.MessageBody>
              <S.MessageFooter
                hasContent={templateButtons.type === 'PHONE_NUMBER'}
              >
                {footer}
              </S.MessageFooter>
            </S.MessageContent>
            {templateButtons.type === 'PHONE_NUMBER' && (
              <S.StyledCall>
                {templateButtons.buttons.map((button) => (
                  <S.UrlAndNumber>
                    {button.type === 'URL' ? (
                      <OpenInNewIcon fontSize="small" />
                    ) : (
                      <CallIcon fontSize="small" />
                    )}
                    <span>{button.text}</span>
                  </S.UrlAndNumber>
                ))}
              </S.StyledCall>
            )}
          </S.MessagePreview>
          {templateButtons.type === 'QUICK_REPLY' && (
            <MessageButtonsContainer>
              {templateButtons.buttons.map(
                (btn) =>
                  templateButtons.buttons.length <= 3 && (
                    <S.StyledQuickReply key={btn.text}>
                      <S.QuickAnswerButtonContainer>
                        {btn.text}
                      </S.QuickAnswerButtonContainer>
                    </S.StyledQuickReply>
                  )
              )}
            </MessageButtonsContainer>
          )}
        </S.MessagePreviewContainer>
      </S.PreviewContainer>
    </S.NewTemplatePreview>
  );
}
