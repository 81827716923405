import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttTypography from 'components/Material/MttTypography/MttTypography';

export const Container = styled(MttGrid)<{ metaBlock?: boolean }>`
  margin: ${(props) => (props.metaBlock ? 0 : '16px')};
  display: flex;
  justify-content: center;
  flex-direction: column;

  gap: 8px;

  .MuiOutlinedInput-root {
    margin-left: 24px;
  }
`;

export const TitleTypography = styled(MttTypography)``;
