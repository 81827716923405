import { createStyles, makeStyles, withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

import MttButton from '../Material/MttButton/MttButton';
import MttGrid from '../Material/MttGrid/MttGrid';

import { Grid, TableRow, Typography } from '@material-ui/core';

export const ContainerDialog = withTheme(styled(MttGrid) <{ colorHeader?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  min-height: 400px;

  background-color: ${props => props.theme.palette.primary.main};

  border: ${props => props.theme.border.primary};

  & .MuiAppBar-colorPrimary {
    background-color: ${props => props.theme.palette.primary.main};
    position: absolute;
  }
  
  th, td {
    color: #fff;

    svg {
      fill: #fff;
    }
  }
`);

export const Container = styled.div`
  overflow-y: scroll;
  padding: 12px;
`;

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #fff;
`;

export const FieldContainer = styled(Grid)`
  width: 100%;
  margin-top: 10px;
  display: flex;

  overflow-y: auto;

  align-items: center;

  .MuiInputBase-input {
    outline: 0;
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }

  .MuiIconButton-root {
    padding: 8px;
  }

  .tableHeadCell-Button {
    width: 40px;
    text-align: right;
  }
`;

export const HoverableRow = withTheme(styled(TableRow)`
  height: 56px;
  border-bottom: 1px solid #fff;

  td {
    height: 100%;
    border: none;
  }

  &:hover {
    td, th {
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`);

export const ButtonContainer = withTheme(styled(Grid)`
  padding: 12px 24px;
  display: flex;
  justify-content: flex-end;

  background-color: ${props => props.theme.palette.primary.main};

  .MuiCircularProgress-colorPrimary {
    color: #f8f8f24f;
    margin-left: 8px;
  }
`);

export const Button = styled(MttButton)`
  height: 50px;
`;

export const CustomPaper = withTheme(styled.div<{ addminheight?: boolean }>`
  margin-top: 10px;
  padding: 20px;
  flex: 1;
  border: ${props => props.theme.border.primary};
  border-radius: 8px;
  color: #fff;
  background-color: ${props => props.theme.palette.primary.defaultBackground};

  min-height: ${(props) => (props.addminheight ? '62vh' : 'none')};

  h6 {
    font-size: 14px;
  }

  & label,
  svg {
    color: #fff;
  }

  & label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary.main};
  }

  & .MuiOutlinedInput-root {
    background-color: ${props => props.theme.palette.primary.defaultBackground};

    & fieldset {
      border-color: #353759;
    }

    &.Mui-focused fieldset {
      border: 1px solid ${(props) => props.theme.palette.secondary.main};
    }

    &.Mui-focused svg {
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }

  & .MuiOutlinedInput-input {
    background-color: transparent;
    color: #fff;
  }

  & .MuiInput-underline {
    & .MuiInputBase-input {
      padding: 8px;
      color: #fff;
    }
  }
  & .MuiInput-underline:before {
    border-bottom: ${(props) => props.theme.border.primary};
  }
  & .MuiInput-underline:after {
    border-color: ${(props) => props.theme.palette.secondary.main};
  }

  & .MuiFormControl-root {
    background-color: #00032e;
    border-radius: 8px;
    color: #fff;

    & label,
    svg {
      color: #fff;
    }

    & label.Mui-focused {
      color: ${(props) => props.theme.palette.secondary.main};
    }

    &.MuiOutlinedInput-root {
      background-color: transparent;

      & fieldset {
        border-color: #353759;
      }

      &.Mui-focused fieldset {
        border: 1px solid ${(props) => props.theme.palette.secondary.main};
      }

      &.Mui-focused svg {
        color: ${(props) => props.theme.palette.secondary.main};
      }
    }

    & .MuiOutlinedInput-input {
      background-color: transparent;
      color: #fff;
    }
  }

  & .MuiSelect-outlined {
    color: #fff;
    background-color: #00032e;
  }

  & .MuiMenuItem-root {
    color: #fff;
    background-color: #00032e;

    &[data-focus='true'] {
      background-color: ${(props) => props.theme.palette.secondary.main};
      color: #00032e;
    }

    &[aria-selected='true'] {
      background-color: ${(props) => props.theme.palette.secondary.main};
      color: #00032e;
    }
  }

  & .MuiContainer-root {
    max-width: none;
  }

  & .MuiInputBase-root .MuiSelect-icon {
    position: static;
  }

  th, td {
    color: #fff;

    svg {
      fill: #fff;
    }
  }
`);

export const FormsContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    input: {
      background: '#FFFFFF',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    loginWithAdInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    infoIcon: {
      color: '#c2c2c2',
      cursor: 'pointer',
    },
    container: {
      display: 'flex',
      height: '100%',
      justifyContent: 'space-between',
      flexDirection: 'column',
      maxWidth: '100%',
      width: '100%',
    },
  })
);
