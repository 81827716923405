import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';

export const ContainerDialog = styled(MttGrid)`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;
	
  .MuiAppBar-colorPrimary {
		background-color: #0e103b;
    position: absolute;

    .MuiToolbar-root {
      display: flex;
      justify-content: space-between;
    }
  }
`;
