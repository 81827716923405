import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MttButton from 'components/Material/MttButton/MttButton';

import CheckIcon from '@material-ui/icons/Check';

import useTranslator from 'utils/hooks/Translator';

import {
  emptyTemplate,
  IPayloadTemplate,
  ITemplate,
  ITemplateBrokers,
  ITemplateButton,
  ITemplateButtonFields,
  ITemplateButtonTypes,
  ITemplateCategories,
  ITemplateChannels,
  ITemplateComponents,
  ITemplateLanguages,
  KeyValuePair,
  TemplateComponentFormat,
} from 'services/CampaignService/TemplatesService/types';

import { useApp } from 'contexts/App/appContext';
import {
  CreateTemplate,
  CreateTemplateOnZenvia,
  UpdateTemplate,
} from 'services/CampaignService/TemplatesService';
import {
  BoxContainer,
  ButtonsContainer,
  HighlightedButton,
  StyledGridButtons,
  StyledRightButtons,
  TextContainer,
} from '../../styles';
import { ITemplateButtons, SaveOrEditTemplateProps } from './types';

import { CloseRounded } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useToast } from 'contexts/ToastContext';
import { validateTemplateName } from 'utils/String';
import { handleTemplateFileUpload } from 'utils/uploadFile';
import { FormFalazap } from './FormFalazap';
import { Preview } from './Preview';
import {
  NewCampaignContainer,
  NewCampaignFields,
  NewCampaignFieldsContainer,
  useStyles,
} from './styles';

export interface ITemplateError {
  name: string;
  organization: string;
  message?: string;
  body?: string;
  falazapTitle?: string;
  examples?: string;
}

export interface IComponents {
  body: string;
  footer: string;
  header: ITemplateComponents;
  buttons: ITemplateButtons;
  image: string | File;
}

export function SaveOrEditTemplate({
  handleClose,
  currentTemplate,
}: SaveOrEditTemplateProps) {
  const { toastNotification } = useToast();
  const { hasPermissionToAction } = usePermissions();
  const { getTranslation } = useTranslator();
  const { dispatch: dispatchApp } = useApp();
  const { currentData } = useFintalkCloud();
  const { agentName: botName } = currentData;
  const [error, setError] = useState<ITemplateError>({
    name: '',
    organization: '',
  });
  const bot_name = botName || '';
  const classes = useStyles();
  const [importFile, setImportFile] = useState(false);

  const [template, setTemplate] = useState(
    {
      ...currentTemplate,
      payload_message: {
        ...currentTemplate.payload_message,
        bot: {
          ...currentTemplate.payload_message.bot,
          bot_name,
        },
      },
    } || emptyTemplate
  );

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();

  const currentHeader = template.payload_message.template?.components.find(
    (c) => c.type === 'HEADER'
  );

  const currentButtons = template.payload_message.template?.components.find(
    (c) => c.type === 'BUTTONS'
  );

  const [templateButtons, setTemplateButtons] = useState<ITemplateButtons>({
    type: getButtonType(currentButtons),
    buttons: currentButtons?.buttons || [],
  });

  const currentBody = template.payload_message.template?.components.find(
    (c) => c.type === 'BODY'
  );
  const currentIMAGE = template.payload_message.template?.components.find(
    (c) => c.type === 'IMAGE'
  );

  const currentFooter = template.payload_message.template?.components.find(
    (c) => c.type === 'FOOTER'
  );

  const header = currentHeader
    ? {
        ...currentHeader,
        text: !!currentHeader.text?.trim() ? currentHeader.text : '',
      }
    : ({
        text: '',
        type: 'HEADER',
        format: 'TEXT',
      } as ITemplateComponents);

  const [templateComponents, setTemplateComponents] = useState<IComponents>({
    body: currentBody?.text || '',
    footer: currentFooter?.text || '',
    header,
    buttons: templateButtons,
    image: currentIMAGE?.text || '',
  });

  const [imageUrl, setImageUrl] = useState(
    templateComponents.header.text || ''
  );
  const [isTemplateOnDatabase /*setIsTemplateOnDatabase*/] = useState(false);

  const isNotEditable = !hasPermissionToAction({
    company: currentData.companyName!,
    agent: currentData.agentName!,
    action: ['communication_guideline:write'],
  });

  const isWhatsApp = template.channel === 'whatsapp';
  const isFalazap = template.channel === 'falazap';
  const isSms = template.channel === 'sms';

  const buttonsLimit =
    templateButtons.type === 'QUICK_REPLY'
      ? 3
      : templateButtons.type === 'PHONE_NUMBER'
      ? 2
      : 0;

  const variables = useMemo(() => {
    return templateComponents.body.match(/{{\d?}}/g);
  }, [templateComponents.body]);

  useEffect(() => {
    if (!variables) {
      setTemplate((prev) => ({
        ...prev,
        payload_message: {
          ...prev.payload_message,
          template: {
            ...prev.payload_message.template,
            name: prev.payload_message.template?.name ?? '',
            components: prev.payload_message.template?.components ?? [],
            examples: [],
          },
        },
      }));
      return;
    }
    const arr = Array.from({ length: variables?.length || 0 });

    if (template.payload_message.template?.examples) {
      if (template.payload_message.template?.examples.length > arr.length) {
        template.payload_message.template?.examples?.forEach(
          (example, index) => {
            if (arr.length === index + 1)
              arr[index] = !!example ? example : { [index + 1]: '' };
          }
        );
      } else if (
        template.payload_message.template?.examples.length === arr.length
      ) {
        arr.forEach((_, index) => {
          if (template.payload_message.template?.examples)
            arr[index] = template.payload_message.template?.examples[index];
        });
      } else {
        arr.forEach((item, index) => {
          if (item) return;
          arr[index] = { [index + 1]: '' };
        });
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        // eslint-disable-next-line
        arr[i] = { ['']: '' };
      }
    }

    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        template: {
          ...prev.payload_message.template,
          name: prev.payload_message.template?.name ?? '',
          components: prev.payload_message.template?.components ?? [],
          examples: arr as KeyValuePair[],
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables?.length]);

  useEffect(() => {
    if (importFile) {
      const documentHtml = document.getElementById('drawflow');
      const uploadInput = document.createElement('input');
      uploadInput.type = 'file';
      uploadInput.accept = 'application/csv';
      uploadInput.onchange = async (e: any) => {
        const file = e.target.files?.item(0);
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          const reader = new FileReader();
          reader.readAsText(file, 'UTF-8');
          setFile(file);
        } else {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.invalidFileFormat'),
          });
        }
      };

      documentHtml?.appendChild(uploadInput);
      uploadInput.click();
      uploadInput.parentNode?.removeChild(uploadInput);
      setImportFile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFile]);

  useEffect(() => {
    if (!file) return;
    const objectUrl = URL.createObjectURL(file);
    setImageUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  function close() {
    setTemplate(emptyTemplate);
    handleClose();
  }

  async function handleCreateTemplate(template: ITemplate) {
    setLoading(true);
    let response;

    if (template.payload_message.template?.broker === 'Zenvia') {
      response = await CreateTemplateOnZenvia({ ...template }, dispatchApp);
    } else {
      response = await CreateTemplate(
        { ...template, already_in_meta: isTemplateOnDatabase },
        dispatchApp
      );
    }

    if (response.Success) {
      toastNotification({
        type: 'success',
        message: getTranslation('toast.success.templateCreated'),
      });
      close();
    } else {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.templateCreated'),
      });
    }
    setLoading(false);
  }

  async function handleUpdateTemplate(template: ITemplate) {
    setLoading(true);

    const response = await UpdateTemplate(
      { ...template, bot_name },
      dispatchApp
    );

    if (response.Success) {
      toastNotification({
        type: 'success',
        message: getTranslation('toast.success.templateUpdated'),
      });
      setLoading(false);
      close();
    } else {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.templateUpdated'),
      });
      setLoading(false);
    }
  }

  async function validateTemplate(currentTemplate: ITemplate) {
    const errors: ITemplateError = {
      name: '',
      organization: '',
      message: '',
      falazapTitle: '',
      examples: '',
    };

    if (currentTemplate.name === '') {
      errors.name = getTranslation('validations.required', {
        field: getTranslation('name'),
      });
    }

    if (
      currentTemplate.channel === 'sms' &&
      currentTemplate.payload_message.text &&
      currentTemplate.payload_message.text.length > 160
    ) {
      errors.message = getTranslation('validations.smsLimit');
    }

    if (
      currentTemplate.channel === 'falazap' &&
      templateComponents.header.text === ''
    ) {
      errors.falazapTitle = 'Obrigatório fornecer um título';
    }

    if (
      currentTemplate.channel === 'whatsapp' &&
      templateComponents.body === ''
    ) {
      errors.body = getTranslation('validations.required', {
        field: 'Body',
      });
    }

    if (
      currentTemplate.channel === 'whatsapp' &&
      templateComponents.body === ''
    ) {
      errors.body = getTranslation('validations.required', {
        field: 'Body',
      });
    }

    if (currentTemplate.channel === 'falazap' && file) {
      const fileUpload = await handleTemplateFileUpload(
        file,
        template.bot_name
      );

      if (fileUpload.data?.url) {
        templateComponents.image = fileUpload.data.url;
      } else errors.message = getTranslation('toast.error.fileUpload');
    }

    if (
      currentTemplate.payload_message.template?.broker === 'Zenvia' &&
      currentTemplate.payload_message.template?.examples &&
      currentTemplate.payload_message.template?.examples.length > 0
    ) {
      const erroredExample =
        currentTemplate.payload_message.template?.examples.find(
          (example) =>
            !Object.entries(example)[0][0] || !Object.entries(example)[0][1]
        );

      if (erroredExample)
        errors.examples = 'Todas os nomes e valores devem estar preenchidos.';
    }

    if (templateComponents?.header.format === 'IMAGE' && file) {
      const fileUpload = await handleTemplateFileUpload(
        file,
        template.bot_name
      );

      if (fileUpload.data?.url) {
        templateComponents.header.text = fileUpload.data.url;
      } else errors.message = getTranslation('toast.error.fileUpload');
    }

    setError(errors);

    const templateValidated =
      errors.name === '' &&
      errors.organization === '' &&
      errors.message === '' &&
      errors.falazapTitle === '' &&
      errors.examples === '';

    const components: ITemplateComponents[] = [];

    if (templateComponents.header.format !== 'NONE')
      components.push({
        type: templateComponents.header.type,
        text: templateComponents.header.text,
        format: templateComponents.header.format,
      });

    if (
      typeof templateComponents.image === 'string' &&
      currentTemplate.channel === 'falazap'
    )
      components.push({ type: 'IMAGE', text: templateComponents.image });

    if (templateComponents.body)
      components.push({ type: 'BODY', text: templateComponents.body });

    if (templateComponents.footer)
      components.push({ type: 'FOOTER', text: templateComponents.footer });

    if (templateButtons.buttons.length > 0) {
      const buttons: ITemplateButton[] = [];

      templateButtons.buttons.forEach((button) => {
        buttons.push({
          type: button.type,
          text: button.text || null,
          phone_number: button.phone_number
            ? `+55${button.phone_number}`
            : null,
          url: button.url || null,
        });
      });

      if (buttons.length > 0) components.push({ type: 'BUTTONS', buttons });
    }
    if (currentTemplate.channel === 'falazap') {
      currentTemplate.status = 'approved';
    }
    const payloadTemplate: IPayloadTemplate | null =
      currentTemplate.channel === 'whatsapp'
        ? {
            name:
              currentTemplate.payload_message.template?.name ||
              currentTemplate.name ||
              '',
            category:
              currentTemplate.payload_message.template?.category || 'MARKETING',
            broker: currentTemplate.payload_message.template?.broker || 'Meta',
            language:
              currentTemplate.payload_message.template?.language ||
              currentTemplate.language ||
              'pt_BR',
            components,
            examples: currentTemplate.payload_message.template?.examples || [],
          }
        : currentTemplate.channel === 'falazap'
        ? {
            name:
              currentTemplate.payload_message.template?.name ||
              currentTemplate.name ||
              '',
            language:
              currentTemplate.payload_message.template?.language ||
              currentTemplate.language ||
              'pt_BR',
            components,
          }
        : null;

    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        template: payloadTemplate,
      },
    }));

    currentTemplate.payload_message = {
      ...currentTemplate.payload_message,
      template: payloadTemplate,
    };

    if (templateValidated)
      currentTemplate._id
        ? handleUpdateTemplate(currentTemplate)
        : handleCreateTemplate(currentTemplate);
  }

  function handleChangeQuickReply(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    button: ITemplateButton,
    index: number
  ) {
    const updatedButton: ITemplateButton = {
      ...button,
      text: e.target.value,
    };
    const buttons = templateButtons.buttons;
    buttons[index] = updatedButton;

    setTemplateButtons((prev) => ({
      ...prev,
      buttons,
    }));
  }

  function handleChangeCallToAction(
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    button: ITemplateButton,
    field: ITemplateButtonFields,
    index: number
  ) {
    const updatedButton: ITemplateButton = {
      ...button,
      [field]: e.target.value,
    };

    const buttons = templateButtons.buttons;
    buttons[index] = updatedButton;

    setTemplateButtons((prev) => ({
      ...prev,
      buttons,
    }));
  }

  function handleRemoveButton(button: ITemplateButton) {
    const newButtons = templateButtons.buttons.filter((b) => b !== button);
    setTemplateButtons((prev) => ({
      ...prev,
      buttons: newButtons,
    }));
  }

  function handleChangeHeaderType(format: TemplateComponentFormat) {
    setTemplateComponents((prev) => ({
      ...prev,
      header: {
        ...prev.header,
        format,
        text: format === 'TEXT' ? prev.header.text : '',
      },
    }));
  }

  function handleChangeButtonType(type: ITemplateButtonTypes) {
    const buttons: ITemplateButton[] =
      type === 'PHONE_NUMBER' || type === 'QUICK_REPLY'
        ? ([{ text: '', type }] as ITemplateButton[])
        : [];

    setTemplateButtons(() => ({
      type,
      buttons,
    }));
  }

  function handleChangeChannel(channel: ITemplateChannels) {
    if (channel === 'falazap') handleChangeHeaderType('TEXT');

    setTemplate((prev) => ({
      ...prev,
      channel,
      payload_message: {
        ...prev.payload_message,
        template: emptyTemplate.payload_message.template,
      },
    }));
  }

  function getButtonType(buttons: ITemplateComponents | undefined) {
    if (buttons && buttons.buttons) {
      const buttonType =
        buttons?.buttons[0].type === 'PHONE_NUMBER' ||
        buttons?.buttons[0].type === 'URL'
          ? 'PHONE_NUMBER'
          : buttons?.buttons[0].type === 'QUICK_REPLY'
          ? 'QUICK_REPLY'
          : 'NONE';

      return buttonType;
    } else return 'NONE';
  }

  function handleChangeTemplateCategory(
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) {
    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        template: {
          ...prev.payload_message.template,
          category: e.target.value as ITemplateCategories,
          name: prev.payload_message?.template?.name || '',
          components: prev.payload_message?.template?.components || [],
        },
      },
    }));
  }

  function handleChangeTemplateBroker(brokerName: ITemplateBrokers) {
    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        template: {
          ...prev.payload_message.template,
          name: prev.payload_message?.template?.name || '',
          components: prev.payload_message?.template?.components || [],
          broker: brokerName,
        },
      },
    }));
  }

  function handleChangeTemplateName(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.value.toLowerCase();
    const formattedName = validateTemplateName(name);

    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        template: {
          ...prev.payload_message.template,
          components: prev.payload_message?.template?.components || [],
          name: formattedName,
        },
      },
      name: formattedName,
    }));
  }

  function handleChangeZenviaExampleKeyPair(
    index: number,
    variable: KeyValuePair,
    field: 'key' | 'value',
    value: string
  ) {
    const currentExamples = template.payload_message.template?.examples;
    if (!currentExamples) return;

    const currentKey = Object.entries(variable)[0][0];

    const newVariable =
      field === 'key'
        ? { [value]: Object.entries(variable)[0][1] }
        : { [currentKey]: value };

    currentExamples[index] = newVariable;

    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        bot: prev.payload_message.bot,
        template: {
          ...prev.payload_message.template,
          components: prev.payload_message.template?.components || [],
          name: prev.payload_message.template?.name || '',
          examples: currentExamples,
        },
      },
    }));
  }

  return (
    <>
      <NewCampaignContainer>
        <NewCampaignFieldsContainer>
          <NewCampaignFields newWidth={isWhatsApp ? '75%' : '100%'}>
            <BoxContainer>
              <TextContainer>
                <TextField
                  variant="outlined"
                  label={getTranslation('name')}
                  fullWidth
                  disabled={isNotEditable || isWhatsApp}
                  value={template.name}
                  onChange={handleChangeTemplateName}
                  helperText={error.name}
                  error={!!error.name}
                />
              </TextContainer>
            </BoxContainer>

            <BoxContainer>
              <TextContainer>
                <TextField
                  variant="outlined"
                  label={getTranslation('description')}
                  fullWidth
                  disabled={isNotEditable || isWhatsApp}
                  value={template.description}
                  onChange={(e) =>
                    setTemplate((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                />
              </TextContainer>
            </BoxContainer>

            <BoxContainer>
              <TextContainer>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getTranslation('language')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={template.language}
                    disabled={isNotEditable || isWhatsApp}
                    onChange={(e) =>
                      setTemplate((prev) => ({
                        ...prev,
                        language: e.target.value as ITemplateLanguages,
                      }))
                    }
                    label={getTranslation('language')}
                  >
                    <MenuItem value={'pt_BR'}>
                      {getTranslation('portuguese')}
                    </MenuItem>
                    <MenuItem value={'es'}>
                      {getTranslation('spanish')}
                    </MenuItem>
                    <MenuItem value={'en_US'}>
                      {getTranslation('english')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </TextContainer>

              <TextContainer>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getTranslation('channel')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    disabled={isNotEditable || currentTemplate.channel === 'whatsapp'}
                    value={template.channel}
                    onChange={(e) =>
                      handleChangeChannel(e.target.value as ITemplateChannels)
                    }
                    label={getTranslation('channel')}
                  >
                    <MenuItem value={'whatsapp'}>Whatsapp</MenuItem>
                    <MenuItem value={'falazap'}>Falazap</MenuItem>
                    <MenuItem value={'sms'}>SMS</MenuItem>
                    <MenuItem disabled value={'email'}>
                      E-mail
                    </MenuItem>
                    <MenuItem disabled value={'ura'}>
                      URA
                    </MenuItem>
                  </Select>
                </FormControl>
              </TextContainer>
            </BoxContainer>

            <BoxContainer>
              {isWhatsApp && (
                <TextContainer>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {getTranslation('broker')}
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      disabled={isNotEditable || isWhatsApp}
                      value={template.payload_message.template?.broker}
                      onChange={(e) =>
                        handleChangeTemplateBroker(
                          e.target.value as ITemplateBrokers
                        )
                      }
                      label={getTranslation('broker')}
                      defaultValue={template.payload_message.template?.broker}
                    >
                      <MenuItem value={'Meta'}>Meta</MenuItem>
                      <MenuItem value={'InfoBip'}>InfoBip</MenuItem>
                      <MenuItem value={'Blip'}>Blip</MenuItem>
                      <MenuItem value={'Twillio'}>Twillio</MenuItem>
                      <MenuItem value={'Interaxa'}>Interaxa</MenuItem>
                      <MenuItem value={'Zenvia'}>Zenvia</MenuItem>
                    </Select>
                  </FormControl>
                </TextContainer>
              )}
            </BoxContainer>

            <BoxContainer>
              {isWhatsApp && (
                <TextContainer>
                  <FormControl variant="outlined" disabled={isWhatsApp} fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {getTranslation('category')}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      disabled={isNotEditable || isWhatsApp}
                      value={template.payload_message.template?.category}
                      onChange={(e) => handleChangeTemplateCategory(e)}
                      label={getTranslation('category')}
                    >
                      <MenuItem value={'MARKETING'}>
                        {getTranslation('modal.campaigns.template.marketing')}
                      </MenuItem>
                      <MenuItem value={'OTP'}>
                        {getTranslation('modal.campaigns.template.otp')}
                      </MenuItem>
                      <MenuItem value={'UTILITY'}>
                        {getTranslation('modal.campaigns.template.utility')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TextContainer>
              )}
            </BoxContainer>

            {isWhatsApp && (
              <>
                <Container disableGutters={true}>
                  <Typography
                    variant="h6"
                    children={getTranslation('header')}
                    style={{ marginLeft: '8px' }}
                  />

                  <BoxContainer>
                    <TextContainer style={{ width: 'fit-content' }}>
                      <FormControl variant="outlined">
                        <Select
                          disabled={isNotEditable || isWhatsApp}
                          value={templateComponents.header.format}
                          onChange={(e) =>
                            handleChangeHeaderType(
                              e.target.value as TemplateComponentFormat
                            )
                          }
                        >
                          <MenuItem value={'NONE'}>
                            {getTranslation('none')}
                          </MenuItem>
                          <MenuItem value={'TEXT'}>
                            {getTranslation('text')}
                          </MenuItem>
                          <MenuItem
                            disabled={
                              template.payload_message.template?.broker !==
                              'Zenvia'
                            }
                            value={'IMAGE'}
                            hidden
                          >
                            {getTranslation('image')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TextContainer>

                    {templateComponents.header.format === 'TEXT' && (
                      <TextContainer>
                        <OutlinedInput
                          fullWidth
                          disabled={isNotEditable || isWhatsApp}
                          value={templateComponents.header.text}
                          onChange={(e) =>
                            setTemplateComponents((prev) => ({
                              ...prev,
                              header: {
                                ...prev.header,
                                text: e.target.value,
                              },
                            }))
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              {templateComponents.header.text?.length || '0'}/60
                            </InputAdornment>
                          }
                        />
                      </TextContainer>
                    )}

                    {templateComponents.header.format === 'IMAGE' && (
                      <>
                        <TextContainer>
                          <OutlinedInput
                            fullWidth
                            disabled
                            value={templateComponents.header.text || file?.name}
                            onChange={(e) =>
                              setTemplateComponents((prev) => ({
                                ...prev,
                                header: {
                                  ...prev.header,
                                  text: e.target.value,
                                },
                              }))
                            }
                          />
                        </TextContainer>
                        <TextContainer>
                          <HighlightedButton
                            className={classes.selectFileButton}
                            fullWidth
                            onClick={() => setImportFile(true)}
                          >
                            {getTranslation('loadFile')}
                          </HighlightedButton>
                        </TextContainer>
                      </>
                    )}
                  </BoxContainer>
                </Container>

                <Container disableGutters={true}>
                  <Typography
                    variant="h6"
                    children={getTranslation('body')}
                    style={{ marginLeft: '8px' }}
                  />

                  <BoxContainer>
                    <TextContainer style={{ height: 'auto' }}>
                      <OutlinedInput
                        fullWidth
                        disabled={isNotEditable || isWhatsApp}
                        multiline
                        rows={4}
                        value={templateComponents.body}
                        inputProps={{ maxLength: 1024 }}
                        onChange={(e) =>
                          setTemplateComponents((prev) => ({
                            ...prev,
                            body: e.target.value,
                          }))
                        }
                        error={!!error.body}
                        endAdornment={
                          <InputAdornment position="end">
                            {templateComponents.body?.length || '0'}/1024
                          </InputAdornment>
                        }
                      />
                      {!!error.body && (
                        <FormHelperText error>{error.body}</FormHelperText>
                      )}
                    </TextContainer>
                  </BoxContainer>
                </Container>

                {template.payload_message.template?.broker === 'Zenvia' &&
                  template.payload_message.template.examples?.length! > 0 && (
                    <Container disableGutters={true}>
                      <Typography
                        variant="h6"
                        children={'Variáveis de Exemplo'}
                        style={{ marginLeft: '8px' }}
                      />
                      {template.payload_message.template.examples?.map(
                        (variable, index) => {
                          return (
                            variable && (
                              <BoxContainer>
                                <TextContainer>
                                  <Typography
                                    children={'Nome'}
                                    variant="subtitle2"
                                  />
                                  <TextContainer>
                                    <OutlinedInput
                                      disabled={isNotEditable || isWhatsApp}
                                      value={
                                        Object.entries(variable)[0][0] || ''
                                      }
                                      inputProps={{ maxLength: 25 }}
                                      error={!Object.entries(variable)[0][0]}
                                      onChange={(e) =>
                                        handleChangeZenviaExampleKeyPair(
                                          index,
                                          variable,
                                          'key',
                                          e.target.value
                                        )
                                      }
                                    />
                                  </TextContainer>
                                </TextContainer>
                                <TextContainer>
                                  <Typography
                                    children={'Valor'}
                                    variant="subtitle2"
                                  />
                                  <TextContainer>
                                    <OutlinedInput
                                      disabled={isNotEditable || isWhatsApp}
                                      value={
                                        Object.entries(variable)[0][1] || ''
                                      }
                                      inputProps={{ maxLength: 25 }}
                                      error={!Object.entries(variable)[0][1]}
                                      onChange={(e) =>
                                        handleChangeZenviaExampleKeyPair(
                                          index,
                                          variable,
                                          'value',
                                          e.target.value
                                        )
                                      }
                                    />
                                  </TextContainer>
                                </TextContainer>
                              </BoxContainer>
                            )
                          );
                        }
                      )}
                      <TextContainer>
                        {!!error.examples && (
                          <FormHelperText error>
                            {error.examples}
                          </FormHelperText>
                        )}
                      </TextContainer>
                    </Container>
                  )}

                <Container disableGutters={true}>
                  <Typography
                    variant="h6"
                    children={getTranslation('footer')}
                    style={{ marginLeft: '8px' }}
                  />

                  <BoxContainer>
                    <TextContainer>
                      <OutlinedInput
                        disabled={isNotEditable || isWhatsApp}
                        fullWidth
                        value={templateComponents.footer}
                        onChange={(e) =>
                          setTemplateComponents((prev) => ({
                            ...prev,
                            footer: e.target.value,
                          }))
                        }
                        inputProps={{ maxLength: 60 }}
                        endAdornment={
                          <InputAdornment position="end">
                            {templateComponents.footer?.length || '0'}/60
                          </InputAdornment>
                        }
                      />
                    </TextContainer>
                  </BoxContainer>
                </Container>

                <Container disableGutters={true}>
                  <Typography
                    variant="h6"
                    children={getTranslation('buttons')}
                    style={{ marginLeft: '8px' }}
                  />

                  <BoxContainer>
                    <TextContainer>
                      <FormControl variant="outlined">
                        <Select
                          disabled={isNotEditable || isWhatsApp}
                          defaultValue={'NONE'}
                          value={templateButtons.type}
                          onChange={(e) =>
                            handleChangeButtonType(
                              e.target.value as ITemplateButtonTypes
                            )
                          }
                        >
                          <MenuItem value={'NONE'}>
                            {getTranslation('none')}
                          </MenuItem>
                          <MenuItem value={'PHONE_NUMBER'}>
                            {getTranslation('callToAction')}
                          </MenuItem>
                          <MenuItem value={'QUICK_REPLY'}>
                            {getTranslation('quickReply')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TextContainer>
                  </BoxContainer>
                </Container>

                {templateButtons.buttons.map((button, index) =>
                  templateButtons.type === 'QUICK_REPLY' ? (
                    <BoxContainer key={index}>
                      <TextContainer>
                        <Typography
                          children={getTranslation('buttonText')}
                          variant="subtitle2"
                        />
                        <TextContainer>
                          <OutlinedInput
                            disabled={isNotEditable || isWhatsApp}
                            value={button.text}
                            inputProps={{ maxLength: 25 }}
                            onChange={(e) =>
                              handleChangeQuickReply(e, button, index)
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                {button.text?.length || '0'}/25
                              </InputAdornment>
                            }
                          />
                          {templateButtons.buttons.length > 1 && (
                            <IconButton
                              disabled={isNotEditable || isWhatsApp}
                              onClick={() => handleRemoveButton(button)}
                            >
                              <Close />
                            </IconButton>
                          )}
                        </TextContainer>
                      </TextContainer>
                    </BoxContainer>
                  ) : (
                    <BoxContainer>
                      <BoxContainer>
                        <TextContainer autoWidth>
                          <Typography
                            children={getTranslation('actionType')}
                            variant="subtitle2"
                          />
                          <FormControl variant="outlined">
                            <Select
                              defaultValue={'URL'}
                              disabled={isNotEditable || isWhatsApp}
                              value={button.type}
                              onChange={(e) =>
                                handleChangeCallToAction(
                                  e,
                                  button,
                                  'type',
                                  index
                                )
                              }
                            >
                              <MenuItem value={'URL'}>
                                {getTranslation('accessSite')}
                              </MenuItem>
                              <MenuItem value={'PHONE_NUMBER'}>
                                {getTranslation('call')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </TextContainer>

                        {button.type === 'URL' ? (
                          <>
                            <TextContainer autoWidth>
                              <Typography
                                children={getTranslation('buttonText')}
                                variant="subtitle2"
                              />
                              <OutlinedInput
                                value={button.text}
                                disabled={isNotEditable || isWhatsApp}
                                inputProps={{ maxLength: 25 }}
                                onChange={(e) =>
                                  handleChangeCallToAction(
                                    e,
                                    button,
                                    'text',
                                    index
                                  )
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    {button.text?.length || '0'}/25
                                  </InputAdornment>
                                }
                              />
                            </TextContainer>

                            <TextContainer autoWidth>
                              <Typography
                                children={getTranslation('urlType')}
                                variant="subtitle2"
                              />
                              <FormControl variant="outlined">
                                <Select
                                  defaultValue={'static'}
                                  value={'static'}
                                >
                                  <MenuItem value={'static'}>Estática</MenuItem>
                                  <MenuItem value={'dynamic'}>
                                    Dinâmica
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </TextContainer>

                            <TextContainer autoWidth>
                              <Typography
                                children={getTranslation('urlSite')}
                                variant="subtitle2"
                              />
                              <OutlinedInput
                                value={button.url}
                                disabled={isNotEditable || isWhatsApp}
                                inputProps={{ maxLength: 2000 }}
                                onChange={(e) =>
                                  handleChangeCallToAction(
                                    e,
                                    button,
                                    'url',
                                    index
                                  )
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    {button.url?.length || '0'}/2000
                                  </InputAdornment>
                                }
                              />

                              {templateButtons.buttons.length > 1 && (
                                <IconButton
                                  onClick={() => handleRemoveButton(button)}
                                  disabled={isNotEditable || isWhatsApp}
                                >
                                  <Close />
                                </IconButton>
                              )}
                            </TextContainer>
                          </>
                        ) : (
                          <>
                            <TextContainer autoWidth>
                              <Typography
                                children={getTranslation('buttonText')}
                                variant="subtitle2"
                              />
                              <OutlinedInput
                                disabled={isNotEditable || isWhatsApp}
                                value={button.text}
                                inputProps={{ maxLength: 25 }}
                                onChange={(e) =>
                                  handleChangeCallToAction(
                                    e,
                                    button,
                                    'text',
                                    index
                                  )
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    {button.text?.length || '0'}/25
                                  </InputAdornment>
                                }
                              />
                            </TextContainer>

                            <TextContainer autoWidth>
                              <Typography
                                children={getTranslation('country')}
                                variant="subtitle2"
                              />
                              <FormControl variant="outlined">
                                <Select
                                  disabled={isNotEditable || isWhatsApp}
                                  defaultValue={'BR +55'}
                                  value={'BR +55'}
                                >
                                  <MenuItem value={'BR +55'}>BR +55</MenuItem>
                                </Select>
                              </FormControl>
                            </TextContainer>

                            <TextContainer autoWidth>
                              <Typography
                                children={getTranslation('phone')}
                                variant="subtitle2"
                              />
                              <OutlinedInput
                                disabled={isNotEditable || isWhatsApp}
                                value={button.phone_number}
                                inputProps={{ maxLength: 20 }}
                                placeholder="(XX) XXXXXXXXX"
                                onChange={(e) =>
                                  handleChangeCallToAction(
                                    e,
                                    button,
                                    'phone_number',
                                    index
                                  )
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    {button.phone_number?.length || '0'}/20
                                  </InputAdornment>
                                }
                              />

                              {templateButtons.buttons.length > 1 && (
                                <IconButton
                                  onClick={() => handleRemoveButton(button)}
                                  disabled={isNotEditable || isWhatsApp}
                                >
                                  <Close />
                                </IconButton>
                              )}
                            </TextContainer>
                          </>
                        )}
                      </BoxContainer>
                    </BoxContainer>
                  )
                )}

                {templateButtons.type !== 'NONE' && (
                  <div style={{ padding: 4, height: 'auto' }}>
                    <MttButton
                      variant="contained"
                      startIcon={<AddIcon />}
                      disabled={
                        templateButtons.buttons.length >= buttonsLimit ||
                        isNotEditable
                      }
                      onClick={() => {
                        setTemplateButtons((prev) => ({
                          ...prev,
                          buttons: [
                            ...prev.buttons,
                            {
                              text: '',
                              type: templateButtons.type,
                            },
                          ] as ITemplateButton[],
                        }));
                      }}
                    >
                      {getTranslation('add')} {getTranslation('button')}
                    </MttButton>
                  </div>
                )}
              </>
            )}

            {isSms && (
              <>
                <BoxContainer>
                  <TextContainer>
                    <Typography
                      variant="h6"
                      children={getTranslation('text')}
                    />
                  </TextContainer>
                </BoxContainer>
                <BoxContainer>
                  <TextContainer>
                    <TextField
                      multiline
                      disabled={isNotEditable || isWhatsApp}
                      minRows={4}
                      variant="outlined"
                      fullWidth
                      value={template.payload_message.text}
                      error={!!error.message}
                      helperText={error.message}
                      onChange={(e) =>
                        setTemplate((prev) => ({
                          ...prev,
                          payload_message: {
                            ...prev.payload_message,
                            text: e.target.value,
                          },
                        }))
                      }
                    />
                  </TextContainer>
                </BoxContainer>
              </>
            )}

            {isFalazap && (
              <FormFalazap
                error={error}
                setImportFile={setImportFile}
                file={file}
                setTemplateComponents={setTemplateComponents}
                templateComponents={templateComponents}
                isNotEditable={isNotEditable}
              />
            )}
          </NewCampaignFields>

          {isWhatsApp && (
            <Preview
              body={templateComponents.body}
              footer={templateComponents.footer}
              header={templateComponents.header}
              templateButtons={templateButtons}
              imgUrl={imageUrl}
            />
          )}
        </NewCampaignFieldsContainer>
      </NewCampaignContainer>

      <StyledGridButtons>
        <StyledRightButtons isTemplate>
          <Button
            disabled={isNotEditable || loading}
            variant="contained"
            color="default"
            startIcon={<CloseRounded />}
            onClick={() => handleClose()}
          >
            {getTranslation('footerButtons.discardAndExit')}
          </Button>

          <ButtonsContainer>
            <Button
              disabled={isNotEditable || loading || isWhatsApp}
              variant="contained"
              color="secondary"
              startIcon={<CheckIcon />}
              onClick={() => validateTemplate(template)}
            >
              {getTranslation('footerButtons.saveAndExit')}
              {loading && <CircularProgress color="inherit" size={18} />}
            </Button>
          </ButtonsContainer>
        </StyledRightButtons>
      </StyledGridButtons>
    </>
  );
}
