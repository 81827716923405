import { useMemo } from 'react';
import { useFlow } from 'contexts/Flow/flowContext';
import { useForm } from 'contexts/Form/formContext';
import { DataForm } from 'models/DataFlow';
import Typography from '../../Typography';
import styles from './styles';

const { Container, FirstLine, DescriptionInput } = styles;

export default function Info() {
  const { groupsNames } = useFlow().state;
  const { state, dispatch } = useForm();

  const { dataForm } = state;

  const selectedGroup = useMemo(
    () => groupsNames.find((group) => group === (state.dataForm?.groupId || '')),
    [groupsNames, state.dataForm?.groupId]
  );

  const handleChangeInput =
    (key: keyof DataForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (dataForm) {
        const { value } = e.target;
        dispatch({
          type: 'updateForm',
          data: { dataForm: { ...dataForm, [key]: value } },
        });
      }
    };

  return (
    <Container>
      <FirstLine>
        <label html-for="input-name">
          <Typography>Nome do Bloco</Typography>
          <input
            name="input-name"
            type="text"
            value={dataForm?.name || ''}
            onChange={handleChangeInput('name')}
          />
        </label>
        <label>
          Grupo
          <input
            name="input-group"
            value={selectedGroup || ''}
            type="text"
            disabled
          />
        </label>
      </FirstLine>
      <label>
        Descrição
        <DescriptionInput
          type="text"
          value={dataForm?.description || ''}
          onChange={handleChangeInput('description')}
        />
      </label>
    </Container>
  );
}
