import * as yup from 'yup';
import { IResultError } from './ResultError';

export interface IOrganization {
  email: string;
  usedEmails: string;
}

interface IAddBot {
  botName: string;
  usedBots: string;
}

export const emailValidation = async (email: string, usedEmails: string[]) => {
  const emailResult = await emailValidator(email);

  if (emailResult.isValid) {
    emailResult.isValid = !usedEmails.some(
      (item) => item.trim().toLowerCase() === email.trim().toLowerCase()
    );
    if (!emailResult.isValid) {
      emailResult.errors = {
        ...emailResult.errors,
        usedEmails: 'Este e-mail já está cadastrado.',
      };
    }
  }

  const isValid = emailResult.isValid;
  const errors = {
    ...emailResult.errors,
  };
  return { isValid, errors };
};

export const botValidation = async (botName: string, usedBots: string[]) => {
  const botNameResult = await botValidator(botName);

  if (botNameResult.isValid) {
    botNameResult.isValid = !usedBots.some(
      (item) => item.trim().toLowerCase() === botName.trim().toLowerCase()
    );
    if (!botNameResult.isValid) {
      botNameResult.errors = {
        ...botNameResult.errors,
        usedBots: 'Usuário já tem acesso a esse bot.',
      };
    }
  }

  const isValid = botNameResult.isValid;
  const errors = {
    ...botNameResult.errors,
  };
  return { isValid, errors };
};

export const emailValidator = async (email: string) => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .required('*E-mail obrigatório.')
      .email('*E-mail inválido.'),
  });

  const promiseResult = new Promise<IResultError<IOrganization>>((resolve) => {
    schema
      .validate({ email }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { email: '' } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { email: err.errors[0] } });
      });
  });
  return await promiseResult;
};

export const botValidator = async (botName: string) => {
  const schema = yup.object().shape({
    botName: yup.string().required('*Nome de bot obrigatório.'),
  });

  const promiseResult = new Promise<IResultError<IAddBot>>((resolve) => {
    schema
      .validate({ botName }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { botName: '' } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { botName: err.errors[0] } });
      });
  });
  return await promiseResult;
};
