import * as yup from 'yup';
import { DataFlow } from './DataFlow';
import { IResultError } from './ResultError';

export interface IBlockSet {
  name: string;
  description: string;
  blocks: DataFlow;
}

export const newBlockSetValidation = async (
  newBlockSet: IBlockSet,
  list: IBlockSet[],
  error: string,
  errorDuplicatedLibrary: string
) => {
  const nameResult = await nameValidator(newBlockSet.name, error);
  let isValid = nameResult.isValid;
  let errors = { ...nameResult.errors };
  if (isValid) {
    isValid = !list.some(
      (item) =>
        item.name.trim().toLowerCase() === newBlockSet.name.trim().toLowerCase()
    );
    if (!isValid) {
      errors = {
        ...errors,
        name:
          errorDuplicatedLibrary || 'Já existe uma biblioteca com esse nome.',
      };
    }
  }
  return { isValid, errors };
};
const nameValidator = async (name: string, error: string) => {
  const schema = yup.object().shape({
    name: yup.string().required(error),
  });

  const promiseResult = new Promise<IResultError<IBlockSet>>((resolve) => {
    schema
      .validate({ name }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { name: '' } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { name: err.errors[0] } });
      });
  });
  return await promiseResult;
};
