import { IconButton, TableCell, TableRow } from '@material-ui/core';
import {
  FunctionPermission,
  IPermissionFunction,
} from 'services/PermissionServices/types';
import { InputCheckboxWithLabel } from 'components/Forms/InputCheckbox/InputCheckboxWithLabel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import useTranslator from 'utils/hooks/Translator';

type IPermissionType = {
  bot: string;
  functions: IPermissionFunction;
};

type Props = {
  permission: IPermissionType;
  index: number;
  handleChangePermission: (
    permission: FunctionPermission,
    permissionIndex: number,
    checked: boolean
  ) => void;
  handleRemoveBot: (botName: string) => void;
};

export function PermissionTableRow({
  permission,
  index,
  handleChangePermission,
  handleRemoveBot,
}: Props) {
  const { getTranslation } = useTranslator();

  return (
    <TableRow>
      <TableCell>
        <IconButton onClick={() => handleRemoveBot(permission.bot)}>
          <RemoveCircleIcon color={'error'} />
        </IconButton>
      </TableCell>
      <TableCell>{permission.bot}</TableCell>
      <TableCell>
        <InputCheckboxWithLabel
          label={getTranslation('function.publish')}
          name="publish"
          value={permission.functions.publish}
          setValue={(value, name) => {
            handleChangePermission('publish', index, value);
          }}
        />
      </TableCell>
      <TableCell>
        <InputCheckboxWithLabel
          label={getTranslation('function.edit')}
          value={permission.functions.edit}
          setValue={(value) => handleChangePermission('edit', index, value)}
        />
      </TableCell>
      <TableCell>
        <InputCheckboxWithLabel
          label={getTranslation('function.keys')}
          value={permission.functions.secrets}
          setValue={(value) => handleChangePermission('secrets', index, value)}
        />
      </TableCell>
      <TableCell>
        <InputCheckboxWithLabel
          label={getTranslation('function.libraries')}
          value={permission.functions.libraries}
          setValue={(value) =>
            handleChangePermission('libraries', index, value)
          }
        />
      </TableCell>
      <TableCell>
        <InputCheckboxWithLabel
          label={getTranslation('function.variables')}
          value={permission.functions.entities}
          setValue={(value) => handleChangePermission('entities', index, value)}
        />
      </TableCell>
      <TableCell>
        <InputCheckboxWithLabel
          label={getTranslation('function.conversations')}
          value={permission.functions.conversations}
          setValue={(value) =>
            handleChangePermission('conversations', index, value)
          }
        />
      </TableCell>
      <TableCell>
        <InputCheckboxWithLabel
          label={getTranslation('function.rulers')}
          value={permission.functions.campaign}
          setValue={(value) => handleChangePermission('campaign', index, value)}
        />
      </TableCell>
    </TableRow>
  );
}
