import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const USFlagIcon: React.FC<ISVGProps> = (props) => {
  const { size } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg2"
      viewBox="0 0 768 768"
      version="1.1"
			width={size || 20}
			height={size || 20}
    >
      <defs id="defs4">
        <clipPath id="clipPathFlag" clipPathUnits="userSpaceOnUse">
          <rect
            id="rect3918"
            style={{ fillOpacity: 0.67, fill: '#000000' }}
            ry="57"
            height="511"
            width="511"
            y=".5"
            x=".5"
          />
        </clipPath>
        <linearGradient id="linearGradient3852-0">
          <stop id="stop3854-6" style={{ stopColor: '#dbe3db', offset: '0' }} />
          <stop
            id="stop3856-1"
            style={{ stopColor: '#dbe3db', stopOpacity: 0 }}
            offset="1"
          />
        </linearGradient>
        <linearGradient
          id="linearGradient5122"
          y2="523.41"
          xlinkHref="#linearGradient3852-0"
          gradientUnits="userSpaceOnUse"
          x2="358.08"
          gradientTransform="matrix(1.1571 0 0 1.3167 -91.204 -253.99)"
          y1="705.76"
          x1="342.19"
        />
        <linearGradient
          id="linearGradient5125"
          y2="360.92"
          xlinkHref="#linearGradient3852-0"
          gradientUnits="userSpaceOnUse"
          x2="405.75"
          gradientTransform="matrix(.95303 0 0 .86647 11.575 -4.5016)"
          y1="118.06"
          x1="405.26"
        />
        <filter id="filter5740">
          <feGaussianBlur id="feGaussianBlur5742" stdDeviation="5.12" />
        </filter>
        <clipPath id="clipPath5796" clipPathUnits="userSpaceOnUse">
          <rect
            id="rect5798"
            style={{ stroke: '#00fa00', strokeWidth: 20, fill: 'none' }}
            ry="57"
            height="512"
            width="512"
            y="0"
            x="0"
          />
        </clipPath>
        <filter id="filter5912">
          <feGaussianBlur id="feGaussianBlur5914" stdDeviation="17.92" />
        </filter>
      </defs>
      <g id="template-button">
        &gt;
        <g id="00" transform="translate(90,90)">
          <rect
            id="shadow"
            style={{
              filter: 'url(#filter5912)',
              stroke: '#a8a8a8',
              strokeWidth: 5,
              fill: '#000000',
            }}
            ry="57"
            height="512"
            width="512"
            y="56"
            x="38"
          />
        </g>
        <g
          id="01"
          clipPath="url(#clipPathFlag)"
          transform="translate(128,128)"
        >
          <rect
            id="mask"
            style={{ fill: '#0000ff' }}
            ry="57"
            height="512"
            width="512"
            y="0"
            x="0"
          />
          <g
            id="flag"
            clipPath="url(#clipPath6304)"
            transform="matrix(3.9385 0 0 3.9385 0 .000005)"
          >
            <g id="g390">
              <g id="g169" style={{ fill: '#bd3d44' }}>
                <rect
                  id="rect156"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#bd3d44',
                  }}
                  height="10"
                  width="247"
                  y="0"
                  x="0"
                />
                <rect
                  id="rect158"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#bd3d44',
                  }}
                  height="10"
                  width="247"
                  y="20"
                  x="0"
                />
                <rect
                  id="rect160"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#bd3d44',
                  }}
                  height="10"
                  width="247"
                  y="40"
                  x="0"
                />
                <rect
                  id="rect162"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#bd3d44',
                  }}
                  height="10"
                  width="247"
                  y="60"
                  x="0"
                />
                <rect
                  id="rect164"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#bd3d44',
                  }}
                  height="10"
                  width="247"
                  y="80"
                  x="0"
                />
                <rect
                  id="rect166"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#bd3d44',
                  }}
                  height="10"
                  width="247"
                  y="100"
                  x="0"
                />
                <rect
                  id="rect168"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#bd3d44',
                  }}
                  height="10"
                  width="247"
                  y="120"
                  x="0"
                />
              </g>
              <g id="g177" style={{ fill: '#ffffff' }}>
                <rect
                  id="rect157"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#ffffff',
                  }}
                  height="10"
                  width="247"
                  y="10"
                  x="0"
                />
                <rect
                  id="rect159"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#ffffff',
                  }}
                  height="10"
                  width="247"
                  y="30"
                  x="0"
                />
                <rect
                  id="rect161"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#ffffff',
                  }}
                  height="10"
                  width="247"
                  y="50"
                  x="0"
                />
                <rect
                  id="rect163"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#ffffff',
                  }}
                  height="10"
                  width="247"
                  y="70"
                  x="0"
                />
                <rect
                  id="rect165"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#ffffff',
                  }}
                  height="10"
                  width="247"
                  y="90"
                  x="0"
                />
                <rect
                  id="rect167"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: '1pt',
                    fill: '#ffffff',
                  }}
                  height="10"
                  width="247"
                  y="110"
                  x="0"
                />
              </g>
            </g>
            <rect
              id="rect200"
              style={{
                fillRule: 'evenodd',
                strokeWidth: '1pt',
                fill: '#192f5d',
              }}
              height="70"
              width="98.8"
              y="0"
              x="0"
            />
            <g id="g274">
              <g id="g218">
                <g id="g194">
                  <polygon
                    id="polygon207"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(8.2333 7)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon188"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(24.7,7)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon189"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(41.167 7)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon190"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(57.633 7)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon191"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(74.1,7)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon192"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(90.567 7)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                </g>
                <g id="g205">
                  <polygon
                    id="polygon193"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(16.467 14)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon201"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(32.933 14)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon202"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(49.4,14)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon203"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(65.867 14)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon204"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    transform="translate(82.333 14)"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                </g>
              </g>
              <g id="g232" transform="translate(0,14)">
                <g id="g233">
                  <polygon
                    id="polygon234"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(8.2333 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon235"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(24.7,7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon236"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(41.167 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon237"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(57.633 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon238"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(74.1,7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon239"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(90.567 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                </g>
                <g id="g240">
                  <polygon
                    id="polygon241"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(16.467 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon242"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(32.933 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon243"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(49.4,14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon244"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(65.867 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon245"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(82.333 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                </g>
              </g>
              <g id="g246" transform="translate(0,28)">
                <g id="g247">
                  <polygon
                    id="polygon248"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(8.2333 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon249"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(24.7,7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon250"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(41.167 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon251"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(57.633 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon252"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(74.1,7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon253"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(90.567 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                </g>
                <g id="g254">
                  <polygon
                    id="polygon255"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(16.467 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon256"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(32.933 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon257"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(49.4,14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon258"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(65.867 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon259"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(82.333 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                </g>
              </g>
              <g id="g260" transform="translate(0,42)">
                <g id="g261">
                  <polygon
                    id="polygon262"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(8.2333 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon263"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(24.7,7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon264"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(41.167 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon265"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(57.633 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon266"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(74.1,7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon267"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(90.567 7)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                </g>
                <g id="g268">
                  <polygon
                    id="polygon269"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(16.467 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon270"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(32.933 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon271"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(49.4,14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon272"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(65.867 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                  <polygon
                    id="polygon273"
                    style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                    transform="translate(82.333 14)"
                    d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                    points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                  />
                </g>
              </g>
              <g id="g211" transform="translate(0,56)">
                <polygon
                  id="polygon212"
                  style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                  transform="translate(8.2333 7)"
                  d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                  points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                />
                <polygon
                  id="polygon213"
                  style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                  transform="translate(24.7,7)"
                  d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                  points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                />
                <polygon
                  id="polygon214"
                  style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                  transform="translate(41.167 7)"
                  d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                  points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                />
                <polygon
                  id="polygon215"
                  style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                  transform="translate(57.633 7)"
                  d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                  points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                />
                <polygon
                  id="polygon216"
                  style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                  transform="translate(74.1,7)"
                  d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                  points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                />
                <polygon
                  id="polygon217"
                  style={{ fillRule: 'evenodd', fill: '#ffffff' }}
                  transform="translate(90.567 7)"
                  d="M 1.3084868e-6,-4.0040002 0.89895399,-1.237304 3.8080309,-1.2373029 1.4545381,0.47260808 2.3534912,3.239305 0,1.5293919 l -2.3534933,1.7099115 0.8989552,-2.76669531 -2.353492,-1.70991339 2.9090761,1.3e-6 z"
                  points="0 -4.004 0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="02" transform="translate(0,38)">
          <path
            id="path3862-8"
            d="m214.21 111.21c-45.245 0.18809-68.716 7.7308-64.094 83.553l2.8252 46.345c47.474 5.5124 56.664 18.445 104.36 21.914 68.073 4.9513 67.937 5.4209 130.56-14.384 62.628-19.805 5.8248-65.247 207.32-57.82 4.6033 0.16968 17.314-3.0527 27.462-12.15 5.5817-46.524-2.7842-69.143-66.934-68.876z"
            style={{ color: '#000000', fill: 'url(#linearGradient5125)' }}
          />
          <path
            id="path3079-9"
            d="m531.03 495.17c-31.605 19.022-27.601 31.26-37.913 32.573-94.704 12.053-145.98-0.72701-165.8-12.061-59.588-34.078-68.468-13.014-100.22-16.574-20.115-2.2551-59.245 12.984-73.388 44.344 3.4722 27.608 18.111 32.098 44.281 31.986l356.06-1.5268c69.692-0.29885 56.335-8.4926 65.543-56.039-14.772-21.671-13.157 9.109-44.224-30.717z"
            style={{ color: '#000000', fill: 'url(#linearGradient5122)' }}
          />
          <rect
            id="rect5734"
            style={{
              filter: 'url(#filter5740)',
              stroke: '#000000',
              strokeWidth: 20,
              fill: 'none',
            }}
            transform="translate(128,90)"
            clipPath="url(#clipPath5796)"
            ry="57"
            height="512"
            width="512"
            y="0"
            x="0"
          />
        </g>
      </g>
    </svg>
  );
};

export default USFlagIcon;
