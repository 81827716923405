import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const BrazilFlagIcon: React.FC<ISVGProps> = (props) => {
  const { size } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg2"
      viewBox="0 0 768 768"
      version="1.1"
      width={size || 20}
      height={size || 20}
    >
      <defs id="defs4">
        <clipPath id="clipPathFlag" clipPathUnits="userSpaceOnUse">
          <rect
            id="rect3918"
            fillOpacity="0.67"
            ry="57"
            height="511"
            width="511"
            y=".5"
            x=".5"
          />
        </clipPath>
        <linearGradient id="linearGradient3852-0">
          <stop id="stop3854-6" stopColor="#dbe3db" offset="0" />
          <stop
            id="stop3856-1"
            stopColor="#dbe3db"
            stopOpacity="0"
            offset="1"
          />
        </linearGradient>
        <linearGradient
          id="linearGradient5122"
          y2="523.41"
          xlinkHref="#linearGradient3852-0"
          gradientUnits="userSpaceOnUse"
          x2="358.08"
          gradientTransform="matrix(1.1571 0 0 1.3167 -91.204 -253.99)"
          y1="705.76"
          x1="342.19"
        />
        <linearGradient
          id="linearGradient5125"
          y2="360.92"
          xlinkHref="#linearGradient3852-0"
          gradientUnits="userSpaceOnUse"
          x2="405.75"
          gradientTransform="matrix(.95303 0 0 .86647 11.575 -4.5016)"
          y1="118.06"
          x1="405.26"
        />
        <filter id="filter5740">
          <feGaussianBlur id="feGaussianBlur5742" stdDeviation="5.12" />
        </filter>
        <clipPath id="clipPath5796" clipPathUnits="userSpaceOnUse">
          <rect
            id="rect5798"
            ry="57"
            height="512"
            width="512"
            stroke="#00fa00"
            y="0"
            x="0"
            strokeWidth="20"
            fill="none"
          />
        </clipPath>
        <filter id="filter5912">
          <feGaussianBlur id="feGaussianBlur5914" stdDeviation="17.92" />
        </filter>
      </defs>
      <g id="template-button">
        &gt;
        <g id="00" transform="translate(90,90)">
          <rect
            id="shadow"
            ry="57"
            height="512"
            filter="url(#filter5912)"
            width="512"
            stroke="#a8a8a8"
            y="56"
            x="38"
            strokeWidth="5"
          />
        </g>
        <g
          id="01"
          clipPath="url(#clipPathFlag)"
          transform="translate(128,128)"
        >
          <rect
            id="mask"
            ry="57"
            height="512"
            width="512"
            y="0"
            x="0"
            fill="#00f"
          />
          <g id="flag">
            <rect
              id="rect552"
              fillRule="evenodd"
              height="512"
              width="512"
              y="0"
              x="0"
              strokeWidth="1pt"
              fill="#229e45"
            />
            <g id="g3551" transform="matrix(.74806 0 0 .74806 65.576 64.496)">
              <path
                id="path554"
                d="m261.72 455.71 307.29-199.44-309.15-199.98-308.3 200.46 310.16 198.96z"
                fillRule="evenodd"
                strokeWidth="1pt"
                fill="#f8e509"
              />
              <path
                id="path557"
                d="m293.25 149.98c0 41.878-34.418 75.827-76.874 75.827-42.457 0-76.874-33.949-76.874-75.827s34.418-75.827 76.874-75.827c42.457 0 76.874 33.949 76.874 75.827z"
                fillRule="evenodd"
                transform="matrix(1.6911 0 0 1.7117 -100.3 -.71516)"
                strokeWidth="1pt"
                fill="#2b49a3"
              />
              <polygon
                id="polygon559"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.5567 0 0 1.5877 -132.89 162.78)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon560"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.5567 0 0 1.5877 -45.771 184.84)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon561"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.3448 0 0 1.3716 -34.894 172.87)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon562"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.3204 0 0 1.3467 58.669 162.88)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon563"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.5567 0 0 1.5877 -84.912 110.55)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon564"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.5567 0 0 1.5877 -192.18 70.278)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon565"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.5567 0 0 1.5877 -179.28 123.85)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon566"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.3751 0 0 1.4025 -3.9188 70.499)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon567"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.083 0 0 1.1046 55.481 137.43)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon568"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.0393 0 0 1.06 -79.913 190.38)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon569"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(.84693 0 0 .79301 167.62 231.47)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon570"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.0393 0 0 1.06 -79.913 190.38)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon571"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.0393 0 0 1.06 -79.913 190.38)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon572"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.0393 0 0 1.06 -37.226 190.37)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon573"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.0393 0 0 1.06 -42.53 204.57)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon574"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.0393 0 0 1.06 46.108 178.72)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon575"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.0393 0 0 1.06 20.098 178.71)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon576"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(.65125 0 0 .66422 38.233 212.26)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon577"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.0393 0 0 1.06 120.35 213.95)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon578"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(.86091 0 0 .88028 139.46 236.14)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon579"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(.80183 0 0 .81855 163.18 242.54)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon580"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(.77230 0 0 .78768 199.27 220.26)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon581"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.0086 0 0 .97287 105.45 240.62)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon582"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(.91998 0 0 .97287 125.85 252.28)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon583"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(.77230 0 0 .78768 139.93 245.86)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon584"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(.77230 0 0 .78768 121.49 245.86)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon585"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(.77230 0 0 .78768 90.179 218.48)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon586"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(.65415 0 0 .66718 120.67 287.26)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <polygon
                id="polygon587"
                d="m 228.54263,107.67979 -2.58769,-1.46504 -2.67388,1.30112 0.5937,-2.91377 -2.06371,-2.14093 2.95462,-0.33577 1.39843,-2.624291 1.23236,2.706261 2.92799,0.51903 -2.19299,2.00832 z"
                fillRule="evenodd"
                transform="matrix(1.5567 0 0 1.5877 -132.89 97.877)"
                points="228.54 107.68 225.96 106.22 223.28 107.52 223.88 104.6 221.81 102.46 224.77 102.12 226.16 99.501 227.4 102.21 230.32 102.73 228.13 104.74"
                strokeWidth="1pt"
                fill="#ffffef"
              />
              <path
                id="path726"
                d="m387.05 302.7c1.9812-5.1813 4.537-12.995 5.8942-20.167-69.048-60.656-146.02-91.728-243.28-85.335-3.4867 6.6859-6.2778 13.683-8.6308 21.256 115.24-10.994 199.71 40.026 246.02 84.246z"
                strokeOpacity=".50314"
                fillRule="evenodd"
                strokeWidth="1pt"
                fill="#fff"
              />
              <path
                id="path790"
                d="m356.01 268.6 2.4671 1.3471c-0.38704 0.87468-0.49104 1.6398-0.31543 2.292 0.18244 0.659 0.63597 1.2324 1.3589 1.722 0.76726 0.52378 1.4527 0.75315 2.0562 0.69153 0.6104-0.0616 1.0656-0.31153 1.3606-0.74801 0.18584-0.27901 0.26257-0.57342 0.22847-0.87981-0.0273-0.3064-0.19608-0.6727-0.50468-1.0955-0.21313-0.28585-0.73316-0.89008-1.5584-1.811-1.0605-1.1828-1.6931-2.1807-1.8977-2.9938-0.28303-1.1434-0.11083-2.1824 0.52003-3.1187 0.40749-0.60252 0.9548-1.051 1.6436-1.3437 0.69905-0.29441 1.4612-0.36117 2.283-0.20198 0.82692 0.1609 1.6965 0.54945 2.6001 1.1657 1.48 1.0048 2.3734 2.0866 2.6786 3.2437 0.31201 1.1605 0.12105 2.2971-0.57459 3.4097l-2.4501-1.5114c0.30691-0.67783 0.38193-1.2632 0.22166-1.7562-0.15005-0.49468-0.56095-0.96882-1.2327-1.4241-0.69053-0.469-1.3282-0.69495-1.9113-0.67612-0.3768 0.012-0.66665 0.17117-0.87296 0.47757-0.18926 0.28071-0.23188 0.6008-0.13129 0.96026 0.13129 0.45702 0.6803 1.2153 1.6556 2.2766 0.97185 1.0595 1.6351 1.9256 1.988 2.5966 0.36146 0.66928 0.52515 1.3659 0.49105 2.0883-0.0239 0.72062-0.28986 1.4549-0.79283 2.203-0.45694 0.67954-1.0724 1.1845-1.8465 1.5217-0.77408 0.33721-1.601 0.41937-2.4842 0.24306-0.87808-0.17972-1.8295-0.61792-2.8525-1.3146-1.4902-1.0099-2.404-2.1328-2.7382-3.3635-0.32906-1.2358-0.12787-2.571 0.6104-4.0002z"
                strokeOpacity=".50196"
                strokeWidth="1pt"
                fill="#309e3a"
              />
              <path
                id="path791"
                d="m344.31 260.92 2.52 1.2444c-0.35124 0.88837-0.42455 1.6586-0.22336 2.3022 0.21142 0.65044 0.68371 1.2067 1.4271 1.6672 0.78942 0.49126 1.4816 0.69324 2.0818 0.60765 0.6104-0.0873 1.0554-0.35261 1.3299-0.80107 0.17732-0.28585 0.2404-0.58369 0.19437-0.88837-0.0392-0.30639-0.22336-0.66585-0.55072-1.0766-0.22335-0.2773-0.76384-0.85756-1.6283-1.7459-1.1066-1.14-1.7783-2.1122-2.0153-2.915-0.32906-1.1314-0.19778-2.1756 0.39215-3.1375 0.38534-0.61792 0.91389-1.0869 1.5891-1.407 0.68882-0.3218 1.4476-0.42107 2.2745-0.29612 0.83375 0.13009 1.7169 0.4827 2.6445 1.0612 1.5209 0.94314 2.4552 1.989 2.8064 3.1324 0.35805 1.1485 0.21312 2.2902-0.43478 3.4302l-2.5115-1.4122c0.27963-0.6881 0.33248-1.2752 0.15175-1.7613-0.1705-0.48954-0.60016-0.94657-1.2873-1.3745-0.71099-0.44162-1.3572-0.64017-1.9369-0.59738-0.3768 0.0257-0.65984 0.19856-0.85421 0.51351-0.17561 0.28756-0.20801 0.60594-0.0921 0.96197 0.14834 0.45189 0.72804 1.1879 1.7442 2.2098 1.0128 1.0185 1.7118 1.8572 2.0903 2.5128 0.38875 0.65387 0.57971 1.3437 0.57459 2.066 0.007 0.72234-0.23188 1.4652-0.70246 2.2355-0.42966 0.69324-1.0281 1.2256-1.7851 1.5953-0.76044 0.36802-1.5857 0.4827-2.474 0.34234-0.88149-0.14378-1.8499-0.54603-2.9019-1.1982-1.5277-0.9517-2.4859-2.0352-2.8695-3.2522-0.38192-1.2222-0.23358-2.5624 0.44672-4.0191z"
                strokeOpacity=".50196"
                strokeWidth="1pt"
                fill="#309e3a"
              />
              <path
                id="path792"
                d="m329.83 257.04 7.4168-12.196 9.0076 5.5202-1.2549 2.0643-6.5523-4.0173-1.647 2.7028 6.0988 3.7383-1.2498 2.054-6.0988-3.7366-2.017 3.319 6.7859 4.1594-1.2515 2.0557-9.2377-5.664z"
                strokeOpacity=".50196"
                strokeWidth="1pt"
                fill="#309e3a"
              />
              <path
                id="path795"
                d="m308.74 239.72 1.0997-2.1413 5.5055 2.85-2.595 5.058c-0.80476 0.24306-1.8158 0.30126-3.04 0.17289-1.2123-0.1318-2.3393-0.46558-3.381-1.0048-1.3231-0.68468-2.3342-1.5559-3.0332-2.6206-0.69565-1.0698-1.0281-2.2474-0.99232-3.5381 0.0392-1.2975 0.36999-2.5538 0.99573-3.7726 0.67688-1.3231 1.5601-2.3536 2.641-3.0982 1.0759-0.74117 2.2915-1.1109 3.6368-1.1109 1.0281-0.003 2.1449 0.31153 3.3538 0.93287 1.572 0.81477 2.6274 1.7802 3.1645 2.8996 0.54901 1.1177 0.64961 2.3245 0.30349 3.6236l-2.7809-0.83702c0.14151-0.71548 0.058-1.3813-0.25405-1.9941-0.30179-0.61792-0.82181-1.116-1.5567-1.4977-1.1185-0.57855-2.1875-0.68296-3.2156-0.31153-1.0196 0.36973-1.8874 1.253-2.6036 2.6514-0.77578 1.508-1.0247 2.8123-0.74509 3.9146 0.28304 1.0938 0.96333 1.9222 2.0477 2.4837 0.53366 0.27558 1.1219 0.44847 1.763 0.51693 0.65132 0.0668 1.2447 0.0496 1.7868-0.0514l0.82693-1.6124-2.9275-1.5131z"
                strokeOpacity=".50196"
                strokeWidth="1pt"
                fill="#309e3a"
              />
              <path
                id="path804"
                d="m216.76 216.91 2.0733-14.135 4.2523 0.6299 1.1441 10.017 3.9352-9.2688 4.2676 0.6299-2.0716 14.137-2.6411-0.39026 1.6334-11.128-4.4279 10.715-2.7365-0.40567-1.156-11.539-1.6317 11.126-2.6411-0.38856z"
                strokeOpacity=".50196"
                strokeWidth="1pt"
                fill="#309e3a"
              />
              <path
                id="path805"
                d="m202.38 215.24 1.3299-14.229 10.506 0.99278-0.22165 2.4066-7.647-0.72062-0.29667 3.1546 7.1133 0.66927-0.22336 2.3981-7.115-0.67099-0.35976 3.8718 7.9129 0.74459-0.22165 2.3998-10.777-1.0168z"
                strokeOpacity=".50196"
                strokeWidth="1pt"
                fill="#309e3a"
              />
              <g
                id="g812"
                transform="matrix(1.397 -.89423 .89074 1.4025 -199.05 128.1)"
              >
                <path
                  id="path810"
                  d="m154.79 154.48c0.49-0.725 1.01-1.26 1.56-1.605 0.406-0.253 0.847-0.429 1.319-0.529 0.476-0.098 0.928-0.103 1.356-0.016 0.57 0.112 1.145 0.364 1.724 0.755 1.048 0.71 1.666 1.602 1.853 2.676 0.192 1.078-0.105 2.196-0.888 3.354-0.777 1.148-1.698 1.837-2.763 2.064-1.062 0.225-2.116-0.016-3.16-0.723-1.059-0.716-1.681-1.607-1.87-2.673-0.187-1.07 0.103-2.171 0.869-3.303z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#309e3a"
                />
                <path
                  id="path811"
                  d="m156.31 155.43c-0.545 0.805-0.773 1.543-0.683 2.212 0.091 0.666 0.424 1.193 0.997 1.58 0.572 0.387 1.181 0.499 1.824 0.336 0.649-0.165 1.252-0.66 1.811-1.485 0.551-0.815 0.783-1.545 0.696-2.189-0.085-0.642-0.423-1.163-1.015-1.564-0.592-0.4-1.207-0.519-1.846-0.357-0.636 0.159-1.231 0.648-1.784 1.467z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#f7ffff"
                />
              </g>
              <g
                id="g815"
                transform="matrix(1.383 -.91592 .91234 1.3884 -199.32 133.77)"
              >
                <path
                  id="path808"
                  d="m160.44 163.42 4.814-7.115 3.023 2.046c0.761 0.515 1.269 0.952 1.524 1.314 0.261 0.36 0.384 0.774 0.37 1.241s-0.163 0.91-0.448 1.331c-0.361 0.534-0.817 0.869-1.367 1.006-0.548 0.134-1.165 0.035-1.851-0.297 0.188 0.391 0.31 0.764 0.363 1.116 0.058 0.354 0.07 0.924 0.039 1.71l-0.07 1.975-1.718-1.162 0.009-2.251c0.006-0.802-0.012-1.321-0.051-1.555-0.038-0.238-0.115-0.434-0.232-0.589-0.115-0.157-0.337-0.348-0.668-0.571l-0.29-0.197-2.01 2.97-1.437-0.972z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#309e3a"
                />
                <path
                  id="path809"
                  d="m164.66 160.29 1.063 0.719c0.689 0.467 1.139 0.729 1.35 0.787 0.211 0.057 0.413 0.048 0.606-0.029s0.362-0.222 0.507-0.435c0.162-0.24 0.228-0.476 0.196-0.709-0.025-0.234-0.141-0.45-0.348-0.646-0.106-0.095-0.45-0.34-1.032-0.734l-1.121-0.758-1.221 1.805z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#fff"
                />
              </g>
              <g
                id="g818"
                transform="matrix(1.4175 -.86112 .85776 1.4231 -194.72 119.26)"
              >
                <path
                  id="path806"
                  d="m172.42 161.16 2.626 1.776c0.592 0.401 1.012 0.751 1.261 1.052 0.334 0.405 0.544 0.866 0.629 1.381 0.086 0.515 0.052 1.053-0.101 1.615-0.152 0.558-0.452 1.17-0.903 1.836-0.396 0.586-0.811 1.041-1.243 1.367-0.529 0.397-1.065 0.644-1.613 0.741-0.412 0.075-0.877 0.036-1.393-0.115-0.385-0.114-0.843-0.351-1.374-0.71l-2.702-1.828 4.813-7.115z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#309e3a"
                />
                <path
                  id="path807"
                  d="m173.05 163.33-3.188 4.713 1.073 0.725c0.4 0.272 0.706 0.445 0.914 0.52 0.272 0.099 0.532 0.131 0.778 0.095 0.25-0.034 0.53-0.177 0.841-0.429 0.314-0.254 0.657-0.656 1.029-1.206s0.609-1.006 0.712-1.366c0.102-0.36 0.121-0.681 0.058-0.965-0.063-0.283-0.209-0.544-0.438-0.784-0.17-0.181-0.548-0.47-1.134-0.866l-0.645-0.437z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#fff"
                />
              </g>
              <g
                id="g824"
                transform="matrix(1.5511 -.58458 .58229 1.5572 -164.55 46.058)"
              >
                <path
                  id="path800"
                  d="m200.75 190.69 4.813-7.115 2.306 1.56c0.873 0.591 1.419 1.012 1.636 1.263 0.335 0.382 0.519 0.846 0.551 1.391 0.034 0.542-0.141 1.096-0.524 1.662-0.295 0.437-0.622 0.751-0.983 0.941-0.359 0.191-0.72 0.288-1.082 0.294-0.357 0.004-0.68-0.052-0.97-0.168-0.388-0.164-0.908-0.466-1.558-0.906l-0.936-0.634-1.816 2.684-1.437-0.972z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#309e3a"
                />
                <path
                  id="path801"
                  d="m206.19 185.75-1.366 2.019 0.786 0.532c0.567 0.383 0.97 0.602 1.211 0.656 0.242 0.055 0.469 0.039 0.683-0.047 0.217-0.084 0.397-0.231 0.539-0.442 0.175-0.259 0.244-0.523 0.206-0.794-0.039-0.272-0.16-0.507-0.364-0.706-0.15-0.149-0.483-0.398-1.001-0.748l-0.694-0.47z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#fff"
                />
              </g>
              <g
                id="g827"
                transform="matrix(1.6028 -.42116 .41952 1.6091 -143.19 2.8883)"
              >
                <path
                  id="path798"
                  d="m207.39 195.18 4.813-7.114 3.024 2.046c0.761 0.514 1.269 0.952 1.524 1.313 0.261 0.361 0.384 0.774 0.37 1.241s-0.163 0.911-0.448 1.331c-0.361 0.534-0.817 0.869-1.367 1.006-0.548 0.134-1.165 0.035-1.851-0.296 0.188 0.391 0.31 0.763 0.363 1.115 0.058 0.355 0.07 0.925 0.039 1.71l-0.07 1.976-1.718-1.162 0.009-2.251c0.005-0.803-0.012-1.321-0.051-1.556-0.038-0.237-0.115-0.433-0.232-0.588-0.115-0.158-0.337-0.348-0.668-0.572l-0.29-0.197-2.01 2.971-1.437-0.973z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#309e3a"
                />
                <path
                  id="path799"
                  d="m211.6 192.05 1.063 0.719c0.689 0.466 1.138 0.728 1.349 0.785 0.211 0.058 0.413 0.049 0.607-0.028 0.193-0.076 0.362-0.222 0.506-0.435 0.162-0.24 0.228-0.476 0.197-0.709-0.025-0.234-0.142-0.449-0.348-0.646-0.106-0.095-0.45-0.339-1.032-0.733l-1.121-0.759-1.221 1.806z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#fff"
                />
              </g>
              <g
                id="g830"
                transform="matrix(1.5864 -.47997 .47809 1.5926 -149.72 20.414)"
              >
                <path
                  id="path796"
                  d="m216.64 196.32c0.491-0.725 1.011-1.26 1.559-1.605 0.408-0.253 0.847-0.43 1.321-0.529 0.476-0.098 0.928-0.103 1.355-0.017 0.571 0.112 1.145 0.364 1.724 0.756 1.049 0.709 1.666 1.601 1.854 2.676 0.191 1.077-0.105 2.195-0.889 3.353-0.777 1.149-1.698 1.837-2.762 2.065-1.062 0.225-2.116-0.017-3.161-0.724-1.058-0.715-1.681-1.606-1.87-2.672-0.187-1.07 0.103-2.171 0.869-3.303z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#309e3a"
                />
                <path
                  id="path797"
                  d="m218.16 197.28c-0.545 0.805-0.773 1.542-0.683 2.212 0.092 0.665 0.424 1.192 0.997 1.58 0.573 0.387 1.18 0.499 1.824 0.335 0.649-0.164 1.253-0.659 1.811-1.484 0.552-0.816 0.784-1.545 0.696-2.19-0.084-0.641-0.423-1.163-1.015-1.563-0.592-0.401-1.207-0.52-1.845-0.357-0.636 0.159-1.231 0.648-1.785 1.467z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#fff"
                />
              </g>
              <g
                id="g833"
                transform="matrix(1.6454 -.19485 .19409 1.6519 -103.71 -53.916)"
              >
                <path
                  id="path793"
                  d="m230.03 210.5 4.813-7.115 3.024 2.046c0.76 0.515 1.268 0.952 1.524 1.314 0.26 0.36 0.384 0.774 0.37 1.241s-0.164 0.91-0.448 1.331c-0.361 0.534-0.817 0.869-1.368 1.006-0.548 0.134-1.164 0.035-1.85-0.297 0.188 0.391 0.309 0.764 0.363 1.116 0.057 0.355 0.07 0.924 0.039 1.71l-0.071 1.975-1.717-1.162 0.008-2.25c0.005-0.804-0.011-1.322-0.051-1.556-0.037-0.237-0.115-0.434-0.232-0.589-0.114-0.157-0.337-0.348-0.667-0.571l-0.291-0.197-2.009 2.97-1.437-0.972z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#309e3a"
                />
                <path
                  id="path794"
                  d="m234.24 207.36 1.063 0.719c0.689 0.467 1.139 0.728 1.35 0.786 0.21 0.058 0.413 0.048 0.607-0.028 0.193-0.077 0.361-0.222 0.506-0.436 0.162-0.239 0.227-0.475 0.197-0.708-0.026-0.234-0.142-0.45-0.348-0.646-0.106-0.095-0.45-0.34-1.033-0.734l-1.12-0.758-1.222 1.805z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#fff"
                />
              </g>
              <g
                id="g836"
                transform="matrix(1.6568 0 0 1.6633 -60.955 -99.344)"
              >
                <path
                  id="path788"
                  d="m259.17 225.1c0.49-0.725 1.01-1.26 1.56-1.605 0.407-0.253 0.847-0.429 1.32-0.529 0.477-0.097 0.928-0.103 1.355-0.017 0.571 0.113 1.146 0.365 1.725 0.757 1.048 0.709 1.666 1.601 1.854 2.676 0.191 1.077-0.106 2.194-0.89 3.353-0.777 1.148-1.697 1.837-2.761 2.065-1.063 0.224-2.116-0.017-3.162-0.724-1.057-0.716-1.681-1.607-1.87-2.673-0.187-1.07 0.103-2.171 0.869-3.303z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#309e3a"
                />
                <path
                  id="path789"
                  d="m260.69 226.05c-0.545 0.806-0.773 1.543-0.684 2.212 0.093 0.666 0.425 1.192 0.997 1.58 0.573 0.387 1.181 0.499 1.825 0.336 0.649-0.165 1.253-0.659 1.811-1.485 0.551-0.815 0.783-1.545 0.695-2.189-0.085-0.642-0.423-1.163-1.015-1.564s-1.207-0.52-1.845-0.357c-0.636 0.159-1.23 0.649-1.784 1.467z"
                  strokeOpacity=".50196"
                  strokeWidth="1pt"
                  fill="#fff"
                />
              </g>
              <path
                id="path872"
                d="m241.76 219.84 2.2915-10.031 7.4083 1.7083-0.38534 1.6946-5.3895-1.241-0.51321 2.2235 5.0195 1.1571-0.38874 1.6877-5.0127-1.1537-0.62574 2.7284 5.5805 1.2855-0.38533 1.6912-7.5992-1.7511z"
                strokeOpacity=".50196"
                strokeWidth="1pt"
                fill="#309e3a"
              />
            </g>
          </g>
        </g>
        <g id="02" transform="translate(0,38)">
          <path
            id="path3862-8"
            style={{ color: '#000000' }}
            d="m214.21 111.21c-45.245 0.18809-68.716 7.7308-64.094 83.553l2.8252 46.345c47.474 5.5124 56.664 18.445 104.36 21.914 68.073 4.9513 67.937 5.4209 130.56-14.384 62.628-19.805 5.8248-65.247 207.32-57.82 4.6033 0.16968 17.314-3.0527 27.462-12.15 5.5817-46.524-2.7842-69.143-66.934-68.876z"
            fill="url(#linearGradient5125)"
          />
          <path
            id="path3079-9"
            style={{ color: '#000000' }}
            d="m531.03 495.17c-31.605 19.022-27.601 31.26-37.913 32.573-94.704 12.053-145.98-0.72701-165.8-12.061-59.588-34.078-68.468-13.014-100.22-16.574-20.115-2.2551-59.245 12.984-73.388 44.344 3.4722 27.608 18.111 32.098 44.281 31.986l356.06-1.5268c69.692-0.29885 56.335-8.4926 65.543-56.039-14.772-21.671-13.157 9.109-44.224-30.717z"
            fill="url(#linearGradient5122)"
          />
          <rect
            id="rect5734"
            transform="translate(128,90)"
            clipPath="url(#clipPath5796)"
            ry="57"
            height="512"
            filter="url(#filter5740)"
            width="512"
            stroke="#000"
            y="0"
            x="0"
            strokeWidth="20"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
};

export default BrazilFlagIcon;
