import { CreateCustomParamRequest } from './types';

const baseUrl = `${process.env.REACT_APP_CRM_URL}/bot`;
const apiKey = process.env.REACT_APP_CRM_API_KEY ?? '';

export const GetCustomParams = async (agent: string) => {
  const action = `${baseUrl}/${agent}/parameters`;
  const headers = new Headers();
  headers.append('x-api-key', apiKey);

  return await fetch(action, {
    headers,
  });
};

export const CreateCustomParam = async ({
  agent,
  param,
}: CreateCustomParamRequest) => {
  const action = `${baseUrl}/${agent}/parameters`;
  const headers = new Headers();
  headers.append('x-api-key', apiKey);

  return await fetch(action, {
		method: 'POST',
    headers,
    body: JSON.stringify(param),
  });
};
