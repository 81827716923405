export function FintalkDesk() {
  return (
    <iframe
      title="desk"
      src="https://quoti.cloud/fintalk-poc"
      width="100%"
      height="100%"
    ></iframe>
  );
}
