import { grey } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';

import {
  Dialog,
  Divider,
  InputAdornment,
  Slide,
  TextField,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useFlow } from 'contexts/Flow/flowContext';

import { CircularProgress } from '@material-ui/core';
import { useApp } from 'contexts/App/appContext';
import AddBlockToFlowController from 'controllers/library/AddBlockToFlowController';
import GetOffsetPositionFlowController from 'controllers/library/GetOffsetPositionFlowController';
import EIntentType from 'enums/EIntentType';
import { DataFlow, ItemDataFlow, Output } from 'models/DataFlow';
import {
  AddFlexBlockToGroup,
  DeleteFlexBlockGroup,
  GetFlexBlocks,
} from 'services/FlexBlocksService';
import { GetGroup, GetGroupIsLiberated } from 'services/FlowService';
import useTranslator from 'utils/hooks/Translator';
import {
  Button,
  ButtonsContainer,
  ContainerDialog,
  ContainerDivider,
  ContainerHeader,
  Description,
  FieldContainer,
  ModalTitle,
  Section,
  Templates,
  TemplatesContainer,
} from './styles';
import './styles.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      background: grey[100],
    },

    buttonUpload: {
      background: grey[600],
      color: '#FFFFFF',
      letterSpacing: 2,
      borderRadius: 8,
      '&:hover': {
        background: grey[700],
      },
    },
  })
);

export default function ModalFlexBlocks() {
  const { dispatch: dispatchApp } = useApp();
  const { state, dispatch, mountNodeContent, toastNotification } = useFlow();
  const { openModalFlexBlocks } = state;
  const classes = useStyles();

  const options = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

  const [search, setSearch] = useState('');

  const [flexBlocks, setFlexBlocks] = useState<string[]>([]);
  const [load, setLoad] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { getTranslation } = useTranslator();

  const loadFlexBlocks = useCallback(async () => {
    setLoad(true);
    const resultFlexBlocks = await GetFlexBlocks(
      state.botName || '',
      dispatchApp
    );
    if (
      resultFlexBlocks.Success &&
      resultFlexBlocks.Data &&
      resultFlexBlocks.Data.data
    ) {
      const result = resultFlexBlocks.Data.data;
      const blocksNames: string[] = [];
      result.flex_blocks.forEach((block) => {
        if (`flex-${block.block_name}` !== state.idGroup)
          blocksNames.push(block.block_name);
      });

      setFlexBlocks(blocksNames);
    }
    setLoad(false);
  }, [dispatchApp, state.botName, state.idGroup]);

  useEffect(() => {
    if (openModalFlexBlocks) {
      setSearch('');
      loadFlexBlocks();
    }
  }, [loadFlexBlocks, openModalFlexBlocks]);

  function handleClose() {
    setSelectedIndex(null);
    dispatch({ type: 'closeModalFlexBlocks' });
  }

  if (!state.openModalFlexBlocks) return null;

  const handleSelectBlock = (index: number) => {
    setSelectedIndex(index);
  };

  const renderDetails = () => {
    if (selectedIndex === null) return <></>;
    // const block = flexBlocks[selectedIndex];
    return (
      <>
        {/* <strong> {block.block_name} </strong>
        <DescriptionInfo>
          <span>{getTranslation('modal.flexBlocks.description')}:</span>
          <strong>{block.description}</strong>
          <span>{getTranslation('modal.flexBlocks.createdBy')}</span>
          <strong>{block?.author || ''}</strong>
          <span>{getTranslation('modal.flexBlocks.createdAt')}</span>
          <strong>{formatDate(block?.created_at || '')}</strong>
        </DescriptionInfo> */}
      </>
    );
  };

  const handleRemoveBlock = async (index: number) => {
    if (load) return;
    setLoad(true);
    const templatesChanged = [...flexBlocks];
    const template_id = templatesChanged[index] || '-1';
    templatesChanged.splice(index, 1);

    const response = await GetGroupIsLiberated(
      {
        bot_name: state.botName!,
        groupName: `flex-${template_id}`,
      },
      dispatchApp
    );

    if (!response.Data.group_is_liberated) {
      toastNotification(
        'error',
        getTranslation('toast.error.agentOrGroupInUse', {
          object: getTranslation('group'),
          user: response.Data.current_user,
          status: getTranslation('agentOrGroupStatus.removed'),
        })
      );
      setLoad(false);
      return;
    }

    const flexBlocksResponse = await GetFlexBlocks(state.botName!, dispatchApp);

    if (flexBlocksResponse.Data.data) {
      const flexBlocks = flexBlocksResponse.Data.data;
      const flexBlockBeingDeleted = flexBlocks.flex_blocks.find(
        (block) => block.block_name === template_id
      );
      if (
        flexBlockBeingDeleted &&
        flexBlockBeingDeleted.groups_used.length > 0
      ) {
        const groups = flexBlockBeingDeleted.groups_used.map(
          (group) => group.group_name
        );

        toastNotification(
          'error',
          `Não foi possível apagar esse bloco, o mesmo está em uso no(s) grupo(s) "${groups}".`
        );
        setLoad(false);

        return;
      }
    }

    const result = await DeleteFlexBlockGroup(
      {
        bot_name: state.botName || '',
        block_name: template_id,
        groups_used: [],
      },
      dispatchApp
    );

    if (result.Success) {
      toastNotification('success', 'Bloco Flex removido com sucesso.');
      loadFlexBlocks();
    } else {
      toastNotification(
        'error',
        'Erro ao remover Bloco Flex, verifique e tente novamente.'
      );
    }
    setSelectedIndex(null);
    setLoad(false);
  };

  const isDisabled = () => {
    return selectedIndex === null;
  };

  const verifyBlockWithSameNameInGroup = () => {
    if (selectedIndex === null) return;
    const blocksKeys = Object.keys(
      state.editorFlow?.drawflow.drawflow.Home.data
    );
    const groupData = state.editorFlow?.drawflow.drawflow.Home.data;
    let blockAlreadyInGroup = false;
    for (const blockKey of blocksKeys) {
      const currentBlock = groupData[Number(blockKey)];
      if (currentBlock.data.name === flexBlocks[selectedIndex]) {
        blockAlreadyInGroup = true;
        return blockAlreadyInGroup;
      }
    }
  };

  const handleSendToFlow = async () => {
    const blockInGroup = verifyBlockWithSameNameInGroup();
    if (!blockInGroup && state.editorFlow?.drawflow && selectedIndex !== null) {
      const groupResponse = await GetGroup(
        {
          bot_name: state.botName || '',
          groupName: `flex-${flexBlocks[selectedIndex]}`,
        },
        dispatchApp
      );
      if (groupResponse.Success) {
        const groupData = groupResponse.Data.data;
        const groupBlocks = groupData.blocks.drawflow.Home.data;
        const groupBlocksKeys = Object.keys(groupBlocks);

        let entryBlock: ItemDataFlow = {} as ItemDataFlow;
        let exitBlocks: ItemDataFlow[] = [];

        for (const key in groupBlocksKeys) {
          const currentBlock = groupBlocks[Number(groupBlocksKeys[key])];
          if (currentBlock.data.intentType === EIntentType.FlexBlockEntry) {
            entryBlock = currentBlock;
          }
          if (currentBlock.data.intentType === EIntentType.FlexBlockExit)
            exitBlocks.push(currentBlock);
        }

        const dataOutputs: Output[] = [];
        const outputs = {};
        exitBlocks.forEach((exitBlock, index) => {
          Object.assign(outputs, {
            ...outputs,
            [`output_${index + 1}`]: { connections: [] },
          });
          dataOutputs.push({ title: exitBlock.data.name });
        });

        const { editorFlow } = state;

        const offsetPosition = new GetOffsetPositionFlowController(
          editorFlow
        ).getLastPosition();
        const drawflowCurrent = { ...editorFlow.drawflow };
        const controller = new AddBlockToFlowController(
          drawflowCurrent,
          state.idGroup || '',
          offsetPosition
        );
        const nextId = controller.getNextNodeId();
        const flexBlock: DataFlow = {
          [nextId]: {
            class: 'dbclick',
            html: '',
            id: nextId,
            name: 'dbclick',
            pos_x: offsetPosition.pos_x,
            pos_y: offsetPosition.pos_y,
            typenode: false,
            data: {
              name: flexBlocks[selectedIndex],
              description: entryBlock.data.description,
              groupId: state.idGroup || 'principal',
              intentType: EIntentType.FlexBlock,
              outputs: dataOutputs,
              entryName: entryBlock.data.name,
              dataBlockly: {
                compiledPayload: `event('flex-${flexBlocks[selectedIndex]}_${entryBlock.data.name}')`,
                lastVersion: 1,
                payload: '',
                xml: '',
              },
            },
            outputs,
            inputs: { input_1: { connections: [] } },
          },
        };

        await controller.addBlock(flexBlock);
        const drawflow = controller.getDrawflow();

        editorFlow.clear();
        editorFlow.import(drawflow);
        const dataFlow: DataFlow = drawflow.drawflow.Home.data;
        mountNodeContent(dataFlow);
        handleClose();

        await AddFlexBlockToGroup(
          {
            block_name: flexBlocks[selectedIndex],
            bot_name: state.botName || '',
            block_id: nextId,
            group_name: state.idGroup || '',
          },
          dispatchApp
        );
      }
    } else {
      toastNotification('error', 'Este Bloco Flex já existe nesse grupo.');
    }
  };

  const isSelected = (index: number) => {
    return selectedIndex === index;
  };

  return (
    <Dialog
      open={state.openModalFlexBlocks}
      TransitionComponent={Transition}
      maxWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog}>
        <ContainerHeader>
          <ModalTitle style={{ fontWeight: 600 }}>Blocos Flex</ModalTitle>
          <IconButton
            edge="start"
            color="default"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </ContainerHeader>
        <FieldContainer>
          <Autocomplete
            freeSolo
            options={options.map((option) => option)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Buscar blocos flex"
                variant="outlined"
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
                InputProps={{
                  type: 'search',
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
        </FieldContainer>
        <TemplatesContainer>
          <Templates>
            {load ? (
              <CircularProgress />
            ) : (
              flexBlocks
                .sort((a, b) => a.localeCompare(b))
                .filter(
                  (item) =>
                    item.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    !search
                )
                .map((item, index) => (
                  <React.Fragment key={item}>
                    <Section
                      key={`CL${index}`}
                      onClick={() => handleSelectBlock(index)}
                      selected={isSelected(index)}
                    >
                      <span>{item}</span>
                      <div>
                        {/* <IconButton
                          edge="start"
                          color="default"
                          aria-label="publish"
                        >
                          <PublishIcon />
                        </IconButton> */}
                        <IconButton
                          edge="start"
                          color="default"
                          aria-label="delete"
                          onClick={() => handleRemoveBlock(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Section>
                    <ContainerDivider>
                      <Divider variant="fullWidth" />
                    </ContainerDivider>
                  </React.Fragment>
                ))
            )}
          </Templates>
          <Description>{renderDetails()}</Description>
        </TemplatesContainer>
        <ButtonsContainer>
          <Button
            style={{ marginLeft: 16, letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            disabled={isDisabled()}
            onClick={handleSendToFlow}
          >
            {getTranslation('modal.templates.sendToFlowButton')}
          </Button>
        </ButtonsContainer>
      </ContainerDialog>
    </Dialog>
  );
}
