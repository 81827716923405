import { css } from 'styled-components';

export const chipStyles = {
  approved: css`
    background-color: #b0e3b2;
    color: ${props => props.theme.palette.success.dark};
  `,
  rejected: css`
    background-color: #ffbaba;
    color: ${props => props.theme.palette.error.dark};
  `,
  moderate: css`
    background-color: #bfe3ff;
  `,
  processing: css`
    background-color: #fffdbc;
  `,
  pendingUpload: css`
    background-color: #ffebc2;
  `,
};
