import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import useTranslator from 'utils/hooks/Translator';
import { Actions } from 'contexts/Permissions/types';
import { useCompanies } from 'contexts/Company';
import {
  ActionCategories,
  ActionCategoryTypes,
  ActionCategoryItem,
} from './types';
import { CustomPaper } from 'components/ModalManageCompanies/styles';

export function ManageActions() {
  const { state, dispatch } = useCompanies();
  const { group } = state;
  const { getTranslation } = useTranslator();

  const [actions, setActions] = useState<ActionCategories>(getActions());

  function getActions() {
    if (!group.actions)
      return {
        flow: {
          read: false,
          write: false,
          publish: false,
        },
        communication_guideline: {
          read: false,
          write: false,
        },
        access_key: {
          read: false,
          write: false,
        },
      };
    if (group.actions.includes('*'))
      return {
        flow: {
          read: true,
          write: true,
          publish: true,
        },
        communication_guideline: {
          read: true,
          write: true,
        },
        access_key: {
          read: true,
          write: true,
        },
      };
    return {
      flow: {
        read: group.actions.includes('flow:read'),
        write: group.actions.includes('flow:write'),
        publish: group.actions.includes('flow:publish'),
      },
      communication_guideline: {
        read: group.actions.includes('communication_guideline:read'),
        write: group.actions.includes('communication_guideline:write'),
      },
      access_key: {
        read: group.actions.includes('access_key:read'),
        write: group.actions.includes('access_key:write'),
      },
    };
  }

  const getPrettyTitle = {
    flow: getTranslation('flow'),
    communication_guideline: getTranslation('communicationRuler.title'),
    access_key: getTranslation('accessKeys'),
  };

  const getPrettyItemLabel = {
    read: 'Ler',
    write: 'Editar',
    publish: 'Publicar',
  };

  function handleChangePermissionToAction(
    category: ActionCategoryTypes,
    categoryItem: ActionCategoryItem
  ) {
    const currValue = actions[category][categoryItem];

    const permissions: Actions[] = [];

    setActions((prev) => {
      const updatedActions = {
        ...prev,
        [category]: { ...prev[category], [categoryItem]: !currValue },
      };

      const actionKeys = Object.keys(updatedActions);
      actionKeys.forEach((act) => {
        const convertedCategory = act as ActionCategoryTypes;

        const categoryKeys = Object.keys(updatedActions[convertedCategory]);
        categoryKeys.forEach((item) => {
          const convertedItem = item as ActionCategoryItem;

          if (updatedActions[convertedCategory][convertedItem])
            permissions.push(
              `${convertedCategory}:${convertedItem}` as Actions
            );
        });
      });
      return updatedActions;
    });

    dispatch({ type: 'updateActions', data: { actions: permissions } });
  }

  return (
    <CustomPaper addminheight>
      <h6>
        {getTranslation(
          'modal.manageCompany.project.group.manageGroupPermissions'
        )}
      </h6>
      <br />

      {Object.keys(actions).map((category, index) => {
        const convertedCategory = category as ActionCategoryTypes;
        return (
          <div key={index}>
            <h6>{getPrettyTitle[convertedCategory]}</h6>
            {Object.keys(actions[convertedCategory]).map((categoryItem) => {
              const convertedCategoryItem = categoryItem as ActionCategoryItem;
              return (
                <FormControlLabel
                  key={`${index}-${convertedCategoryItem}`}
                  control={
                    <Checkbox
                      checked={
                        actions[convertedCategory][convertedCategoryItem]
                      }
                      onChange={() =>
                        handleChangePermissionToAction(
                          convertedCategory,
                          convertedCategoryItem
                        )
                      }
                      color="primary"
                    />
                  }
                  label={getPrettyItemLabel[convertedCategoryItem]}
                />
              );
            })}
          </div>
        );
      })}
    </CustomPaper>
  );
}
