import { useState, useEffect } from 'react';
import { useFlow } from 'contexts/Flow/flowContext';
import { permissionFunctionToArray } from 'utils/permission';
import { localStore } from '../Stores';

type AccessControlLayer = {
  clearStorageUserPermissions: () => void;
  getBots: () => string[];
  getPermissions: () => string[];
  getPermissionsByBot: (botName: string) => string[];
  hasPermissionToResource: (resource: string[]) => boolean;
  isAdmin: () => boolean;
  isFintalkStaff: () => boolean;
  isAdminInOrganization: (organizationName: string) => boolean;
  saveStorageUserPermission: (permissions: string[]) => void;
  currentPermissions: string[];
};
const ADMIN_ORGANIZATION = 'Fintalk STAFF';

let isFirstRender = true;

export default function useAccessControlLayer(): AccessControlLayer {
  const { state } = useFlow();
  const { userOrganizations, userPermissions, botName } = state;
  const [currentPermissions, setCurrentPermissions] = useState<string[]>([]);

  if (isFirstRender) {
    setCurrentPermissions(loadStorageUserPermission());
    isFirstRender = false;
  }

  useEffect(() => {
    let permissions: string[] = [];

    if (!!botName) {
      const botPermission = userPermissions.find(
        (userPermission) => userPermission.bot === botName
      );

      if (!!botPermission) {
        permissions = permissionFunctionToArray(botPermission);
      }
    }

    if (isAdmin()) permissions = [...permissions, 'admin'];

    if (isFintalkStaff()) permissions = [...permissions, 'adminStaff'];

    saveStorageUserPermission(permissions);
    setCurrentPermissions(permissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissions, botName]);

  function saveStorageUserPermission(permissions: string[]) {
    localStore.set('userPermissions', permissions);
  }

  function loadStorageUserPermission(): string[] {
    return localStore.get('userPermissions') ?? [];
  }

  function clearStorageUserPermissions() {
    localStore.remove('userPermissions');
  }

  function isAdmin() {
    return userOrganizations.some((organization) => organization.isAdmin);
  }

  function isFintalkStaff() {
    return userOrganizations.some(
      (organization) => organization.name === ADMIN_ORGANIZATION
    );
  }

  function isAdminInOrganization(organizationName: string) {
    const organization = userOrganizations.find(
      (organization) => organization.name === organizationName
    );

    return !!organization && organization.isAdmin;
  }

  function getBots() {
    return userPermissions.map((permission) => permission.bot);
  }

  function getPermissions() {
    return currentPermissions;
  }

  function getPermissionsByBot(organizationName: string) {
    const permission = userPermissions.find(
      (permission) => permission.bot === organizationName
    );

    if (!permission) return [];

    return permissionFunctionToArray(permission);
  }

  function hasPermissionToResource(resources: string[]) {
    return resources.some((resource) =>
      currentPermissions.some(
        (currentPermission) => currentPermission === resource
      )
    );
  }

  return {
    clearStorageUserPermissions,
    getBots,
    getPermissions,
    getPermissionsByBot,
    isAdmin,
    isFintalkStaff,
    isAdminInOrganization,
    hasPermissionToResource,
    saveStorageUserPermission,
    currentPermissions,
  };
}
