import styled from 'styled-components';
import MttTypography from '../../Material/MttTypography/MttTypography';

export const MsgForUserTypography = styled(MttTypography).attrs({
  children: 'Mensagem para o usuário',
  variant: 'h6',
  color: 'primary',
})``;

export const VariableTypography = styled(MttTypography).attrs({
  children: 'Variável de resposta',
  variant: 'h6',
  color: 'primary',
})``;

export const Container = styled.div`
  margin: 0 16px;
  display: flex;
  flex-direction: column;
`;

export const ContentQuestions = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: auto;
  padding: 16px;

  .MuiOutlinedInput-root {
    margin-top: 8px;
    margin-left: 32px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: auto;
  padding: 16px;

  .MuiFormControl-fullWidth {
    margin-top: 16px;
  }
`;
export const ContentOutputVariable = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: auto;
  padding: 0 16px;

`;

export const VariableContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px 0;

  .MuiOutlinedInput-root {
    margin-top: 16px;
  }

  .MuiFormControl-root {
    width: 100%;
  }
`;

const styles = {
  Input: styled.input`
    width: 70vw;
  `,
  InputLine: styled.div`
    display: flex;
    align-items: center;
    padding: 0 8px;
    max-width: calc(100% - 40px);
  `,
  ChoicesContent: styled.div`
    background: #b6bad9;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    height: 20vh;
    overflow-y: scroll;
  `,
  ChoicesLine: styled.div`
    span {
      font-size: 2em;
    }
  `,
  ButtonsContent: styled.div`
    span {
      font-weight: 600;

      i {
        font-style: normal;
        font-size: 0.8em;
      }
    }
  `,
};

export default styles;
