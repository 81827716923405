import ptBrTranslations from './pt-br/pt-br.json';
import enUsTranslations from './en-us/en-us.json';
import esTranslations from './es/es.json';

const Translations = {
  'pt-BR': ptBrTranslations,
  'en-US': enUsTranslations,
  es: esTranslations,
};

export default Translations;
