import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Switch,
  Toolbar,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Info, Settings } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { TranslatedTooltip } from 'components/TooltipTranslated';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useToast } from 'contexts/ToastContext';
import React, { useCallback, useEffect, useState } from 'react';
import { CreateContentGroup, GetContentGroupById, UpdateContentGroup } from 'services/123FalaService';
import { ContentGroupLLMs, ICreateContentGroupRequest, IUpdateContentGroupRequest } from 'services/123FalaService/types';
import useTranslator from 'utils/hooks/Translator';
import { getTranslationKeys } from 'utils/i18n/types';
import { ModalScoreThreshold } from './ModalScoreThreshold';
import * as S from './style';
import { ContentGroupCreateSchema, ContentGroupErrors, ModalAddOrEditContentGroupProps } from './types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ModalAddOrEditContentGroup({
  setIsOpen,
  contentGroupId,
  setRefresh,
}: ModalAddOrEditContentGroupProps) {
  const classes = S.useStyles();
  const { dispatch } = useApp();
  const { currentData: { agentName } } = useFintalkCloud();
  const [isLoading, setIsLoading] = useState(false);
  const { getTranslation } = useTranslator();
  const { toastNotification: toast } = useToast();

  const [isOpenModalScoreThreshold, setIsOpenModalScoreThreshold] = useState<boolean>(false);

  const [errors, setErrors] = useState<ContentGroupErrors>({
    max_tokens: '',
    extract_image: '',
    name: '',
    extractive: '',
    score_threshold: '',
    llm_model: '',
    instructions: '',
  });

  const [contentGroupData, setContentGroupData] = useState<ContentGroupCreateSchema>({
    max_tokens: 512,
    extract_image: false,
    name: '',
    extractive: true,
    score_threshold: 0.8,
    llm_model: 'gpt-4o-mini',
    instructions: '',
  });

  async function handleCreateOrUpdateContentGroup() {
    const validationErros = validateContentGroupData();

    if (Object.values(validationErros).some(value => value)) {
      return
    };

    try {
      setIsLoading(true);

      let resp;

      const {
        max_tokens,
        extractive,
        llm_model,
        instructions,
        extract_image,
        score_threshold,
      } = contentGroupData

      const updatePayload: IUpdateContentGroupRequest = {
        max_tokens,
        extractive,
        llm_model,
        instructions: instructions || null,
        extract_image,
        score_threshold,
      }

      const createPayload: ICreateContentGroupRequest = {
        ...contentGroupData,
        instructions: instructions || null
      }

      if (!contentGroupId) {
        resp = await CreateContentGroup(createPayload, agentName, dispatch);
      } else {
        resp = await UpdateContentGroup(updatePayload, agentName, contentGroupId, dispatch);
      };

      if (resp.StatusCode === 409) {
        toast({
          type: 'warning',
          message: getTranslation('toast.error.contentGrupNameDuplicated')
        });

        return;
      } else if (
        resp.StatusCode < 200 ||
        resp.StatusCode >= 300
      ) {
        toast({ 
          type: 'warning', 
          message: getTranslation('toast.error.createOrUpdateContentGroup')
        });

        return;
      };

      toast({
        type: 'success',
        message: !contentGroupId ? (
          getTranslation('toast.success.createContentGroup')
        ) : (
          getTranslation('toast.success.updateContentGroup')
        )
      });

      setRefresh && setRefresh(prev => !prev);

    } catch (error) {
      toast({
        type: 'error',
        message: getTranslation('toast.error.error'),
      })
      
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  function handleChangeContentGroupData(
    field: keyof ContentGroupCreateSchema, 
    value: string | boolean
  ) {
    setContentGroupData((prevState) => {
      if (field === 'extractive') {
        return {
          ...prevState,
          extractive: value === 'extractive' ? true : false,
          instructions: '',
          llm_model: 'gpt-4o-mini',
        }
      } else {
        return {
          ...prevState,
          [field]: value,
        }
      }
    })

    setErrors((prevState) => ({
      ...prevState,
      [field]: ''
    }))
  };

  function validateContentGroupData(): ContentGroupErrors {
    let errors: ContentGroupErrors = {
      max_tokens: '',
      extract_image: '',
      name: '',
      extractive: '',
      score_threshold: '',
      instructions: '',
      llm_model: '',
    };

    for (const [key, value] of Object.entries(contentGroupData)) {
      if (
        key !== 'extractive' &&
        key !== 'extract_image' &&
        key !== 'instructions' &&
        !value
      ) {
        errors[key as keyof ContentGroupErrors] = getTranslation('validations.required', {
          field: getTranslation(`${key as getTranslationKeys}`)
        })
      }
    };

    setErrors(errors);
    return errors;
  };

  const loadContentGroup = useCallback(async () => {
    if (contentGroupId) {
      try {
        setIsLoading(true);

        const resp = await GetContentGroupById(contentGroupId, agentName, dispatch);

        if (
          resp.StatusCode < 200 ||
          resp.StatusCode >= 300 ||
          !resp.Data.id
        ) {
          toast({ 
            type: 'warning', 
            message: getTranslation('toast.error.contentGroupNotFound') 
          });

          setIsOpen(false);

          return;
        }

        const {
          max_tokens,
          extract_image,
          name,
          extractive,
          score_threshold,
          instructions,
          llm_model,
        } = resp.Data;

        setContentGroupData({
          max_tokens,
          extract_image,
          name,
          extractive,
          score_threshold: score_threshold ?? 0.8,
          llm_model,
          instructions
        })
      } catch (error) {
        toast({
          type: 'error',
          message: getTranslation('toast.error.error'),
        })

        setIsOpen(false);
      } finally {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentName, contentGroupId, dispatch]);

  useEffect(() => {
    if (contentGroupId) {
      loadContentGroup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentGroupId]);

  return (
    <>
      <Dialog
        open
        fullWidth={true}
        TransitionComponent={Transition}
        maxWidth="xs"
        PaperProps={{ classes: { rounded: classes.dialog } }}
      >
        <S.DialogContainer>
          <AppBar>
            <Toolbar>
              <Typography
                variant="h6"
                className={classes.title}
              >
                {contentGroupId ? (
                  getTranslation('modal.123Fala.editContentGroup').toUpperCase()
                ) : (
                  getTranslation('modal.123Fala.addContentGroup').toUpperCase()
                )}
              </Typography>

              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          {!isLoading ? (
            <S.ContentContainer>
              <FormControl
                fullWidth
                error={!!errors.name}
                disabled={!!contentGroupId}
              >
                <S.AddContentGroupInputLabel
                  shrink
                  htmlFor="name-content-group"
                  style={{ display: 'inline-block' }}
                >
                  {getTranslation('name')}
                </S.AddContentGroupInputLabel>

                <S.AddContentGroupInput
                  id="name-content-group"
                  value={contentGroupData.name}
                  onChange={(event) => handleChangeContentGroupData('name', event.target.value)}
                  error={!!errors.name}
                  disabled={!!contentGroupId}
                />

                {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
              </FormControl>

              <FormControl
                fullWidth
                error={!!errors.extractive}
              >
                <Box component='div' style={{ display: 'flex', alignItems: 'center' }}>
                  <S.AddContentGroupInputLabel
                    shrink
                    htmlFor="mode-content-group"
                  >
                    {getTranslation('mode')}
                  </S.AddContentGroupInputLabel>

                  <TranslatedTooltip
                    i18nKey='extractiveAndGenerativeExplain'
                    components={{
                      bold: <strong />,
                      br: <br />
                    }}
                    Icon={
                      <Info
                        style={{
                          margin: '0 0 4px 52px',
                          width: 18,
                          height: 18
                        }}
                      />
                    }
                  />

                  <ButtonBase
                    onClick={() => setIsOpenModalScoreThreshold(true)}
                    style={{
                      margin: '0 12px 4px auto'
                    }}
                  >
                    <Settings
                      style={{
                        width: 18,
                        height: 18
                      }}
                    />
                  </ButtonBase>
                </Box>

                <S.AddContentGroupSelect
                  id='mode-content-group'
                  input={<S.AddContentGroupInput />}
                  defaultChecked
                  value={contentGroupData.extractive ? 'extractive' : 'generative'}
                  onChange={(event) => handleChangeContentGroupData('extractive', event.target.value)}
                >
                  <option value={'extractive'}>{getTranslation('extractive')}</option>
                  <option value={'generative'}>{getTranslation('generative')}</option>
                </S.AddContentGroupSelect>

                {errors.extractive && <FormHelperText>{errors.extractive}</FormHelperText>}
              </FormControl>

              {!contentGroupData.extractive && (
                <>
                  <FormControl
                    fullWidth
                    error={!!errors.instructions}
                  >
                    <Box
                      component='div'
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <S.AddContentGroupInputLabel
                        shrink
                        htmlFor='instructions-content-group'
                        style={{ display: 'inline-block' }}
                      >
                        {getTranslation('purpose')}
                      </S.AddContentGroupInputLabel>

                      <TranslatedTooltip
                        i18nKey='purposeExplain'
                        components={{
                          bold: <strong />,
                          br: <br />
                        }}
                        Icon={
                          <Info
                            style={{
                              marginLeft: 80,
                              width: 18,
                              height: 18
                            }}
                          />
                        }
                      />
                    </Box>

                    <S.AddContentGroupInput
                      id="instructions-content-group"
                      onChange={(event) => handleChangeContentGroupData('instructions', event.target.value)}
                      value={contentGroupData.instructions}
                      error={!!errors.instructions}
                      multiline
                      minRows={4}
                      maxRows={8}
                      inputProps={{ maxLength: 250 }}
                    />

                    {errors.instructions && (
                      <FormHelperText id='instructions-content-group'>
                        {errors.instructions}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    error={!!errors.llm_model}
                  >
                    <S.AddContentGroupInputLabel
                      shrink
                      htmlFor="llm_model-content-group"
                    >
                      {getTranslation('generativeModel')}
                    </S.AddContentGroupInputLabel>

                    <S.AddContentGroupSelect
                      id="llm_model-content-group"
                      input={<S.AddContentGroupInput />}
                      defaultChecked
                      value={contentGroupData.llm_model}
                      onChange={(event) => {
                        handleChangeContentGroupData(
                          'llm_model', 
                          event.target.value as ContentGroupLLMs
                        )
                      }}
                    >
                      <option value={'gpt-3.5-turbo'}>gpt-3.5-turbo</option>
                      <option value={'gpt-4'}>gpt-4</option>
                      <option value={'gpt-4-turbo'}>gpt-4-turbo</option>
                      <option value={'gpt-4o'}>gpt-4o</option>
                      <option value={'gpt-4o-mini'}>gpt-4o-mini</option>
                    </S.AddContentGroupSelect>

                    {errors.llm_model && <FormHelperText>{errors.llm_model}</FormHelperText>}
                  </FormControl>
                </>
              )}

              <FormControlLabel
                label={getTranslation('extractImages')}
                labelPlacement='start'
                control={
                  <Switch
                    checked={contentGroupData.extract_image}
                    onChange={() => handleChangeContentGroupData('extract_image', !contentGroupData.extract_image)}
                    name='extract_image'
                    color='primary'
                    disabled={isLoading}
                  />
                }
                style={{ marginLeft: 0 }}
              />
            </S.ContentContainer>
          ) : (
            <Box
              component='div'
              style={{
                width: '100%',
                height: 200,
                padding: 24,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="inherit" size={56} />
            </Box>
          )}

          <S.FooterContainer>
            <Button
              onClick={() => setIsOpen(false)}
              color='primary'
              size='large'
              className={classes.footerButtons}
              disabled={isLoading}
            >
              {getTranslation('cancel')}
            </Button>

            <Button
              onClick={() => handleCreateOrUpdateContentGroup()}
              variant='contained'
              color='secondary'
              size='large'
              className={classes.footerButtons}
              disabled={isLoading}
            >
              {getTranslation('save')}
            </Button>
          </S.FooterContainer>
        </S.DialogContainer>
      </Dialog>

      {isOpenModalScoreThreshold && (
        <ModalScoreThreshold
          setIsOpen={setIsOpenModalScoreThreshold}
          contentGroupData={contentGroupData}
          setContentGroupData={setContentGroupData}
          title={getTranslation('minRelevanceTitle')}
        />
      )}
    </>
  );
}
