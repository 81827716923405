import { useCallback, useEffect } from 'react';
import { useForm } from 'contexts/Form/formContext';
import { Variable } from 'models/DataFlow';
import Variables from '../Variables';
import styles from './styles';

const { VariablesLine, MultiVariablesContainer, VariablesBtn } = styles;

export default function MultiVars() {
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const variables = dataForm?.inputs?.variables || [];

  const load = useCallback(() => {
    if (dataForm && !dataForm?.inputs) {
      dataForm.inputs = {
        variables: [
          {
            name: '',
            questions: ['', '', ''],
            required: false,
            vartype: '',
            value: '',
            defaultValue: '',
            isList: false,
          },
        ],
      };
      dispatch({ type: 'updateForm', data: { dataForm } });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleRemoveVariable = (index: number) => {
    variables.splice(index, 1);

    dispatch({ type: 'updateForm', data: { dataForm } });
  };

  const renderVars = (variable: Variable, index: number) => {
    return (
      <VariablesLine key={`variable-${index}`}>
        <Variables showTitle={false} index={index} />
        <VariablesBtn onClick={() => handleRemoveVariable(index)}>
          Remover
        </VariablesBtn>
      </VariablesLine>
    );
  };

  const handleAddVariable = () => {
    variables.push({
      name: '',
      vartype: '',
      value: '',
      required: false,
      questions: ['', '', ''],
      defaultValue: '',
      isList: false,
    });

    dispatch({ type: 'updateForm', data: { dataForm } });
  };

  return (
    <div>
      <div>
        <span>Variável para indentificar no texto do usuário</span>
        <VariablesBtn type="button" onClick={handleAddVariable}>
          adicionar variáveis
        </VariablesBtn>
      </div>
      <MultiVariablesContainer>
        {variables.map(renderVars)}
      </MultiVariablesContainer>
    </div>
  );
}
