/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import * as Blockly from 'blockly/core';

/**
 * Unused constant for the common HSV hue for all blocks in this category.
 * @deprecated Use Blockly.Msg['LISTS_HUE']. (2018 April 5)
 */
Blockly.Constants.Lists.HUE = 260;

Blockly.defineBlocksWithJsonArray([
  // BEGIN JSON EXTRACT
  // Block for creating an empty list
  // The 'list_create_with' block is preferred as it is more flexible.
  // <block type="lists_create_with">
  //   <mutation items="0"></mutation>
  // </block>
  {
    type: 'lists_create_empty',
    message0: '%{BKY_LISTS_CREATE_EMPTY_TITLE}',
    output: 'Array',
    style: 'list_blocks',
    tooltip: '%{BKY_LISTS_CREATE_EMPTY_TOOLTIP}',
    helpUrl: '%{BKY_LISTS_CREATE_EMPTY_HELPURL}',
  },
  // Block for creating a list with one element repeated.
  {
    type: 'lists_repeat',
    message0: '%{BKY_LISTS_REPEAT_TITLE}',
    args0: [
      {
        type: 'input_value',
        name: 'ITEM',
      },
      {
        type: 'input_value',
        name: 'NUM',
        check: 'Number',
      },
    ],
    output: 'Array',
    style: 'list_blocks',
    tooltip: '%{BKY_LISTS_REPEAT_TOOLTIP}',
    helpUrl: '%{BKY_LISTS_REPEAT_HELPURL}',
  },
  // Block for reversing a list.
  {
    type: 'lists_reverse',
    message0: '%{BKY_LISTS_REVERSE_MESSAGE0}',
    args0: [
      {
        type: 'input_value',
        name: 'LIST',
        check: 'Array',
      },
    ],
    output: 'Array',
    inputsInline: true,
    style: 'list_blocks',
    tooltip: '%{BKY_LISTS_REVERSE_TOOLTIP}',
    helpUrl: '%{BKY_LISTS_REVERSE_HELPURL}',
  },
  // Block for checking if a list is empty
  {
    type: 'lists_isEmpty',
    message0: '%{BKY_LISTS_ISEMPTY_TITLE}',
    args0: [
      {
        type: 'input_value',
        name: 'VALUE',
        check: ['String', 'Array'],
      },
    ],
    output: 'Boolean',
    style: 'list_blocks',
    tooltip: '%{BKY_LISTS_ISEMPTY_TOOLTIP}',
    helpUrl: '%{BKY_LISTS_ISEMPTY_HELPURL}',
  },
  // Block for getting the list length
  {
    type: 'lists_length',
    message0: '%{BKY_LISTS_LENGTH_TITLE}',
    args0: [
      {
        type: 'input_value',
        name: 'VALUE',
        check: ['String', 'Array'],
      },
    ],
    output: 'Number',
    style: 'list_blocks',
    tooltip: '%{BKY_LISTS_LENGTH_TOOLTIP}',
    helpUrl: '%{BKY_LISTS_LENGTH_HELPURL}',
  },
]); // END JSON EXTRACT (Do not delete this comment.)

Blockly.Blocks['list_from'] = {
  /**
   * Block for creating a list with any number of elements of any type.
   * @this Blockly.Block
   */
  init: function () {
    this.setHelpUrl(Blockly.Msg['LISTS_CREATE_WITH_HELPURL']);
    this.setStyle('list_blocks');
    this.itemCount_ = 1;
    this.updateShape_();
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour('#8007f2');
    this.setMutator(new Blockly.Mutator(['lists_create_with_item']));
    this.setTooltip(Blockly.Msg['LISTS_CREATE_WITH_TOOLTIP']);
  },
  /**
   * Create XML to represent list inputs.
   * @return {!Element} XML storage element.
   * @this Blockly.Block
   */
  mutationToDom: function () {
    var container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    return container;
  },
  /**
   * Parse XML to restore the list inputs.
   * @param {!Element} xmlElement XML storage element.
   * @this Blockly.Block
   */
  domToMutation: function (xmlElement) {
    this.itemCount_ = parseInt(xmlElement.getAttribute('items'), 10);
    this.updateShape_();
  },
  /**
   * Populate the mutator's dialog with this block's components.
   * @param {!Blockly.Workspace} workspace Mutator's workspace.
   * @return {!Blockly.Block} Root block in mutator.
   * @this Blockly.Block
   */
  decompose: function (workspace) {
    var containerBlock = workspace.newBlock('lists_create_with_container');
    containerBlock.initSvg();
    var connection = containerBlock.getInput('STACK').connection;
    for (var i = 0; i < this.itemCount_; i++) {
      var itemBlock = workspace.newBlock('lists_create_with_item');
      itemBlock.initSvg();
      connection.connect(itemBlock.previousConnection);
      connection = itemBlock.nextConnection;
    }
    return containerBlock;
  },
  /**
   * Reconfigure this block based on the mutator dialog's components.
   * @param {!Blockly.Block} containerBlock Root block in mutator.
   * @this Blockly.Block
   */
  compose: function (containerBlock) {
    var itemBlock = containerBlock.getInputTargetBlock('STACK');
    // Count number of inputs.
    var connections = [];
    while (itemBlock) {
      connections.push(itemBlock.valueConnection_);
      itemBlock =
        itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
    }
    // Disconnect any children that don't belong.
    for (var i = 0; i < this.itemCount_; i++) {
      var connection = this.getInput('ADD' + i).connection.targetConnection;
      if (connection && connections.indexOf(connection) == -1) {
        connection.disconnect();
      }
    }
    this.itemCount_ = connections.length;
    this.updateShape_();
    // Reconnect any child blocks.
    for (var i = 0; i < this.itemCount_; i++) {
      Blockly.Mutator.reconnect(connections[i], this, 'ADD' + i);
    }
  },
  /**
   * Store pointers to any connected child blocks.
   * @param {!Blockly.Block} containerBlock Root block in mutator.
   * @this Blockly.Block
   */
  saveConnections: function (containerBlock) {
    var itemBlock = containerBlock.getInputTargetBlock('STACK');
    var i = 0;
    while (itemBlock) {
      var input = this.getInput('ADD' + i);
      itemBlock.valueConnection_ = input && input.connection.targetConnection;
      i++;
      itemBlock =
        itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
    }
  },
  /**
   * Modify this block to have the correct number of inputs.
   * @private
   * @this Blockly.Block
   */
  updateShape_: function () {
    if (this.itemCount_ && this.getInput('EMPTY')) {
      this.removeInput('EMPTY');
    } else if (!this.itemCount_ && !this.getInput('EMPTY')) {
      this.appendDummyInput('EMPTY').appendField(
        Blockly.Msg['LISTS_CREATE_EMPTY_TITLE']
      );
    }
    // Add new inputs.
    for (var i = 0; i < this.itemCount_; i++) {
      if (!this.getInput('ADD' + i)) {
        var input = this.appendValueInput('ADD' + i);
        if (i == 0) {
          input.appendField('FROM');
          input.setCheck(['conditionchooser']);
        }
      }
    }
    // Remove deleted inputs.
    while (this.getInput('ADD' + i)) {
      this.removeInput('ADD' + i);
      i++;
    }
  },
};

Blockly.Blocks['lists_create_with_container'] = {
  /**
   * Mutator block for list container.
   * @this Blockly.Block
   */
  init: function () {
    this.setStyle('list_blocks');
    this.appendDummyInput().appendField('attributes');
    this.appendStatementInput('STACK');
    this.setTooltip();
    this.contextMenu = false;
  },
};

Blockly.Blocks['lists_create_with_item'] = {
  /**
   * Mutator block for adding items.
   * @this Blockly.Block
   */
  init: function () {
    this.setStyle('list_blocks');
    this.appendDummyInput().appendField('attribute');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.JavaScript['list_from'] = function (block) {
  var select = '';
  for (var i = 0; i < this.itemCount_; i++) {
    var current = 'ADD' + i;
    select += Blockly.JavaScript.statementToCode(block, current);

    if (i != this.itemCount_ - 1) {
      select += ', ';
    } else {
      select += ' ';
    }
  }

  if (!select.includes(' *')) {
    select = select.substring(0, select.length - 1);
  } else if (select.includes(' * ')) {
    select = select.substring(0, select.length - 1);
  }

  var option = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('option')
  );
  if (option == 'blank' || option == 'all') {
    option = 'select  ';
  } else {
    option = 'select DISTINCT ';
  }

  var code = option + select;

  return code;
};
