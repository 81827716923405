import React, { useState } from 'react';
import {
  Container,
  ContentSynonyms,
  GridCloseIcon,
  GridDefinition,
  GridInputDefinition,
  GridSynonym,
} from './styles';
import CloseIcon from '@material-ui/icons/CloseRounded';
import TextField from '@material-ui/core/TextField/TextField';
import { useEntity } from 'contexts/Entity/entityContext';
import { IInputFieldProps } from './types';
import useTranslator from 'utils/hooks/Translator';

export default function InputField(props: IInputFieldProps) {
  const indexRow = props.index;
  const { state, dispatch } = useEntity();
  const { selectedIEntity } = state;
  const { values } = state.selectedIEntity;

  const [inputValue, setInputValue] = useState<string>('');
	const { getTranslation } = useTranslator();

  function handleDeleteSynonym(index: number) {
    values[indexRow].synonyms.splice(index, 1);
    dispatch({ type: 'updateEntities', data: { selectedIEntity } });
  }

  function handleShowSynonyms(item: string, index: number) {
    return (
      <GridSynonym key={index}>
        {item}
        <GridCloseIcon onClick={() => handleDeleteSynonym(index)}>
          <CloseIcon />
        </GridCloseIcon>
      </GridSynonym>
    );
  }

  function handleInsertValue(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.keyCode === 13 || event.keyCode === 9) {
      if (inputValue !== '') {
        if (!values[indexRow].value) {
          values[indexRow].value = inputValue;
        }
        values[indexRow].synonyms.push(inputValue);
        dispatch({ type: 'updateEntities', data: { selectedIEntity } });
      }
      setInputValue('');
    }
  }

  return (
    <Container>
      <GridInputDefinition>
        <TextField
          onChange={(e) => setInputValue(e.target.value)}
          variant="standard"
          fullWidth
          value={inputValue}
          placeholder={
            !values[indexRow].value
              ? getTranslation('variables.typeName')
              : `${getTranslation('variables.typeSynonym')} "${values[indexRow].value || ''}"`
          }
          onKeyDown={(event) => handleInsertValue(event)}
        />
      </GridInputDefinition>
      <GridDefinition>{values[indexRow].value || ''}</GridDefinition>
      <ContentSynonyms>
        {values[indexRow].synonyms.map((item, index) =>
          handleShowSynonyms(item, index)
        )}
      </ContentSynonyms>
    </Container>
  );
}
