import { IAppContextActions } from 'contexts/App/types';
import { Dispatch } from 'react';
import { SaveEntities } from 'services/EntityService';
import {
  IEntityContextActions,
  IEntityContextState,
} from '../contexts/Entity/types';
import { IEntity } from '../models/Entity';

export interface UploadEntityToastMessages {
  overwriteSuccess: string;
  addSuccess: string;
  invalidFormat: string;
  addError: string;
}

export async function exportEntity(entity: IEntity) {
  const csvData = csvMaker(entity);

  const documentHtml = document.getElementById('drawflow');

  const BOM = '\uFEFF';
  const csv = new Blob([BOM + csvData], {
    type: 'text/csv;charset=utf-8',
  });

  const entitiesReadyToExport = window.URL.createObjectURL(csv);

  if (entitiesReadyToExport) {
    const dlAnchorElem = document.createElement('a');
    documentHtml?.appendChild(dlAnchorElem);
    if (dlAnchorElem) {
      dlAnchorElem.setAttribute('href', entitiesReadyToExport);

      dlAnchorElem.setAttribute('download', `${entity.name}.csv`);
      dlAnchorElem.click();
      dlAnchorElem.parentNode?.removeChild(dlAnchorElem);
    }
  }
}

const csvMaker = function (entity: IEntity) {
  let concatenatedEntity = '';

  const values = entity.values;

  values.forEach((value) => {
    const valueName = value.value;
    concatenatedEntity += valueName + ',';
    const synonyms = value.synonyms;

    synonyms.forEach((synonym) => {
      concatenatedEntity += `"${synonym}",`;
    });

    concatenatedEntity = concatenatedEntity.replace(/,(?=[^,]*$)/, '');
    concatenatedEntity += '\n';
  });

  return concatenatedEntity;
};

export async function importEntity(
  state: IEntityContextState,
  dispatch: Dispatch<IEntityContextActions>,
  toast: any,
  messages: UploadEntityToastMessages,
  botName: string,
  dispatchApp: Dispatch<IAppContextActions>,
  index?: number,
  overwriting?: boolean
) {
  const entities = state.entities;

  const documentHtml = document.getElementById('drawflow');
  const uploadInput = document.createElement('input');
  uploadInput.type = 'file';
  uploadInput.accept = 'application/csv';

  let entityIndex = -1;

  if (overwriting) {
    entityIndex =
      index ||
      entities.findIndex((entity) => entity.name === state.uploadedEntity.name);

    if (entityIndex >= 0) {
      const currEntity = state.uploadedEntity;
      const entitiesCopy = [...entities];
      entitiesCopy[entityIndex] = currEntity;

      const resp = await SaveEntities(
        {
          bot_name: botName,
          entities: entitiesCopy,
        },
        dispatchApp
      );

      if (resp.Success) {
        dispatch({
          type: 'updateEntities',
          data: { entities: entitiesCopy },
        });
        toast('success', messages.overwriteSuccess);
      } else toast('error', messages.addError);
    }
		return;
  }

  uploadInput.onchange = async (e: any) => {
    const file = e.target.files?.item(0);
    const fileName = file.name;
    let entityName = fileName;

    if (file.type === 'text/csv') {
      const regexExp = /^[^.]*/;
      entityName = fileName.match(regexExp)[0];

      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = async function (e) {
        if (e.target && e.target.result) {
          if (typeof e.target.result === 'string') {
            const parsedData = parseData(e.target.result);

            if (parsedData) {
              const values = parsedData.map((item: any[]) => {
                const updatedItems = item.map((i: any) => i.replace(/"/g, ''));
                updatedItems.splice(0, 1);
                return { value: item[0], synonyms: updatedItems };
              });

              const regex = /[a-zA-Z]/;
              const isWord = regex.test(values[0].value);

              const selectedIEntity: IEntity = {
                name: entityName,
                values,
                entitytype: isWord ? 'words' : 'expressions',
              };

              if (overwriting && index !== undefined) {
                let currEntity = entities[index];

                if (currEntity) {
                  currEntity = selectedIEntity;
                  const entitiesCopy = [...entities];
                  entitiesCopy[index] = currEntity;

                  dispatch({
                    type: 'updateEntities',
                    data: { entities: entitiesCopy },
                  });
                }
                return;
              }

              if (!overwriting) {
                entityIndex = entities.findIndex(
                  (entity) => entity.name === entityName
                );

                if (entityIndex >= 0) {
                  dispatch({ type: 'openModalEntityExists' });
                  dispatch({
                    type: 'updateEntities',
                    data: { uploadedEntity: selectedIEntity },
                  });
                  return;
                } else {
                  const resp = await SaveEntities(
                    {
                      bot_name: botName,
                      entities: [...entities, selectedIEntity],
                    },
                    dispatchApp
                  );

                  if (resp.Success) {
                    dispatch({
                      type: 'updateEntities',
                      data: { entities: [...entities, selectedIEntity] },
                    });
                    toast('success', messages.addSuccess);
                  } else toast('error', messages.addError);
                }
              }
            }
          }
        }
      };
    } else {
      toast('error', messages.invalidFormat);
    }
  };

  documentHtml?.appendChild(uploadInput);
  uploadInput.click();
  uploadInput.parentNode?.removeChild(uploadInput);
}

function parseData(data: string) {
  let csvData: any = [];
  let lineBreak = data.split('\n');
  lineBreak.forEach((res) => {
    csvData.push(res.split(','));
  });
  return csvData;
}

const entityUtils = { exportEntity, importEntity };

export default entityUtils;
