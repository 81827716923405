import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CheckIcon from '@material-ui/icons/Check';
import * as S from '../styles';

import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import useTranslator from 'utils/hooks/Translator';

import { CloseRounded } from '@material-ui/icons';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useToast } from 'contexts/ToastContext';
import csv from 'csvtojson';
import { isBefore } from 'date-fns';
import {
  CreateActioning,
  UpdateActioning,
} from 'services/CampaignService/ActioningsService';
import { IActioning } from 'services/CampaignService/ActioningsService/types';
import { GetCampaigns } from 'services/CampaignService/CampaignsService';
import {
  ICampaign,
  emptyCampaign,
} from 'services/CampaignService/CampaignsService/types';
import { GetFilteredTemplates } from 'services/CampaignService/TemplatesService';
import {
  ITemplate,
  emptyTemplate,
} from 'services/CampaignService/TemplatesService/types';
import i18n from 'utils/i18n';
import { handleActioningFileUpload } from 'utils/uploadFile';
import {
  BoxContainer,
  HighlightedButton,
  NewCampaignContainer,
  NewCampaignFields,
  StyledGridButtons,
  StyledRightButtons,
  TextContainer,
} from '../../styles';
import { ResultDownloadButton } from './styled';
import { IActioningsError, Props } from './types';

export function CreateOrEditActioning({
  handleClose,
  currentActioning,
}: Props) {
  const { hasPermissionToAction } = usePermissions();
  const { language } = i18n;
  const { getTranslation } = useTranslator();
  const { currentData } = useFintalkCloud();
  const { toastNotification } = useToast();
  const { agentName: botName } = currentData;

  const bot_name = botName || '';

  const [error, setError] = useState<IActioningsError>({
    campaign: '',
    file: '',
    scheduledDate: '',
  });
  const { dispatch: dispatchApp } = useApp();
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [templates, setTemplates] = useState<ITemplate[]>([]);

  const [loading, setLoading] = useState(false);
  const [importFile, setImportFile] = useState(false);
  const [sheetValues, setSheetValues] = useState<string[]>(
    currentActioning.sheet_values || []
  );

  const [file, setFile] = useState<File>();

  const [actioning, setActioning] = useState(currentActioning);

  const readOnly =
    actioning.status !== 'not_started' &&
    hasPermissionToAction({
      company: currentData.companyName!,
      agent: currentData.agentName!,
      action: ['communication_guideline:write'],
    });

  const currentLanguage =
    language === 'pt-BR' ? ptBR : language === 'en-US' ? enUS : es;

  const currentCampaign =
    campaigns.find((campaign) => campaign.name === actioning.campaign_name) ||
    emptyCampaign;

  const currentTemplate =
    templates.find(
      (templates) => templates.name === currentCampaign.template
    ) || emptyTemplate;

  useEffect(() => {
    if (actioning.status === 'not_started') loadCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (actioning.campaign_name !== '' && currentCampaign.name !== '')
      loadTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actioning.campaign_name, currentCampaign]);

  async function loadTemplates() {
    if (currentCampaign.template_channel) {
      if (currentCampaign.template_data?.broker) {
        try {
          setLoading(true);

          const response = await GetFilteredTemplates({
            bot_name,
            channel: currentCampaign.template_channel,
            broker: currentCampaign.template_data.broker
          },
            dispatchApp
          )

          if (response.Success && response.Data?.data) {
            const templates = response.Data.data;

            setTemplates([...templates])
          } else {
            toastNotification({
              type: 'warning',
              message: getTranslation('toast.error.searchTemplates'),
            });

            setTemplates([])
          }

        } catch (error: any) {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.searchTemplates'),
          });

          setTemplates([])
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);

          const response = await GetFilteredTemplates({
            bot_name,
            channel: currentCampaign.template_channel
          },
            dispatchApp
          )

          if (response.Success && response.Data?.data) {
            const templates = response.Data.data;

            setTemplates([...templates])
          } else {
            toastNotification({
              type: 'warning',
              message: getTranslation('toast.error.searchTemplates'),
            });

            setTemplates([])
          }

        } catch (error: any) {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.searchTemplates'),
          });
          setTemplates([])
        } finally {
          setLoading(false);
        }
      }
    }
  }

  const getFormattedContent = useCallback(() => {
    const templateHeader =
      currentTemplate.payload_message.template?.components.find(
        (component) =>
          component.type === 'HEADER' && component.format === 'TEXT'
      )?.text || '';

    const templateBody =
      currentTemplate.payload_message.template?.components.find(
        (component) => component.type === 'BODY'
      )?.text || '';

    const templateFooter =
      currentTemplate.payload_message.template?.components.find(
        (component) => component.type === 'FOOTER'
      )?.text || '';

    const templateMessage =
      currentTemplate.channel === 'whatsapp' ||
        currentTemplate.channel === 'falazap'
        ? `${templateHeader} ${templateHeader ? '\n' + templateBody : templateBody
        } ${templateBody ? '\n\n' + templateFooter : templateFooter}`
        : currentTemplate.payload_message.text || '';

    const variables = templateMessage.match(/{{\d?}}/g);

    const variablesAndValues = variables?.map((variable, index) => ({
      variable,
      value: sheetValues[index + 1] || variable,
    }));

    let parsedMessage = templateMessage;

    variablesAndValues?.forEach((varAndValue) => {
      parsedMessage = parsedMessage.replace(
        varAndValue.variable,
        varAndValue.value
      );
    });

    if (parsedMessage && sheetValues.length > 0) {
      setActioning((prev) => ({
        ...prev,
        content: parsedMessage,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate, sheetValues]);

  useEffect(() => {
    if (importFile) {
      const documentHtml = document.getElementById('drawflow');
      const uploadInput = document.createElement('input');
      uploadInput.type = 'file';
      uploadInput.accept = 'application/csv';
      uploadInput.onchange = async (e: any) => {
        const file = e.target.files?.item(0);

        if (
          file.type === 'text/csv' ||
          file.type === 'application/vnd.ms-excel'
        ) {
          setActioning((prev) => ({ ...prev, file: file.name }));

          const reader = new FileReader();

          reader.readAsText(file, 'UTF-8');

          setFile(file);

          reader.onload = async function (e) {
            if (reader && reader.result) {
              if (typeof reader.result === 'string') {
                const jsonArray = await csv({
                  delimiter: [',', ';'],
                }).fromString(reader.result);

                const values: string[] = []

                values.push(jsonArray[0][currentCampaign.sender.column])

                if (currentCampaign.variables) {
                  for (let i = 0; i < currentCampaign.variables.length; i++) {
                    values.push(jsonArray[0][currentCampaign.variables[i].column])
                  }
                }

                setSheetValues(values);

                setActioning((prev) => ({
                  ...prev,
                  sheet_values: values,
                }));
              }
            }
          };
        } else {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.invalidFileFormat'),
          });
        }
      };

      documentHtml?.appendChild(uploadInput);
      uploadInput.click();
      uploadInput.parentNode?.removeChild(uploadInput);
      setImportFile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFile]);

  useEffect(() => {
    getFormattedContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate, sheetValues]);

  async function loadCampaigns() {
    setLoading(true);

    try {
      const response = await GetCampaigns({ bot_name }, dispatchApp);

      if (response.Success && response.Data?.data) {
        const campaigns = response.Data.data;

        setCampaigns([...campaigns]);

      } else throw new Error('erro');

    } catch (error: any) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.searchCampaigns'),
      });

      setCampaigns([]);
    } finally {
      setLoading(false);
    }
  }

  async function handleCreateActioning() {
    setLoading(true);
    let result = false;

    try {
      if (file) {
        const fileUpload = await handleActioningFileUpload(file, currentTemplate.bot_name);

        if (fileUpload?.data?.url) {
          const response = await CreateActioning(
            {
              ...actioning,
              file: fileUpload.data.url,
            },
            dispatchApp
          );

          if (response?.Success) {
            toastNotification({
              type: 'success',
              message: getTranslation('toast.success.actioningCreated'),
            });
            result = true;
            handleClose();
          } else {
            toastNotification({
              type: 'error',
              message: getTranslation('toast.error.actioningCreated'),
            });
          }
        } else {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.fileUpload'),
          });
        }
      } else {
        toastNotification({
          type: 'error',
          message: getTranslation('toast.error.fileUpload'),
        });
      }
    } catch (error) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.fileUpload'),
      });
    } finally {
      setLoading(false);
    }

    return result;
  }

  async function handleUpdateActioning() {
    try {
      if (file) {
        const fileUpload = await handleActioningFileUpload(file, currentTemplate.bot_name);

        if (fileUpload?.data?.url) {
          const response = await UpdateActioning(
            {
              ...actioning,
              file: fileUpload.data.url,
            },
            dispatchApp
          );

          if (response?.Success) {
            toastNotification({
              type: 'success',
              message: getTranslation('toast.success.actioningUpdated'),
            });
            handleClose();
          } else {
            toastNotification({
              type: 'error',
              message: getTranslation('toast.error.actioningUpdated'),
            });
          }
        } else {
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.fileUpload'),
          });
        }
      } else {
        toastNotification({
          type: 'error',
          message: getTranslation('toast.error.fileUpload'),
        });
      }
    } catch (error) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.fileUpload'),
      });
    }
  }

  function validateActioning(actioning: IActioning) {
    const errors: IActioningsError = {
      campaign: '',
      file: '',
      scheduledDate: '',
    };

    if (actioning.campaign_name === '') {
      errors.campaign = getTranslation('validations.required', {
        field: getTranslation('campaign'),
      });
    }

    if (actioning.file === '') {
      errors.file = getTranslation('validations.required', {
        field: 'File',
      });
    }

    if (isBefore(new Date(actioning.scheduled_date), new Date())) {
      errors.scheduledDate = getTranslation(
        'validations.selectedDateBeforeCurrentDate'
      );
    }

    setError(errors);

    if (
      errors.campaign === '' &&
      errors.file === '' &&
      errors.scheduledDate === ''
    )
      actioning._id ? handleUpdateActioning() : handleCreateActioning();
  }

  function handleChangeCampaign(
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) {
    const campaign = campaigns.find(
      (campaign) => campaign.name === e.target.value
    );

    if (campaign) {
      setActioning((prev) => ({
        ...prev,
        campaign_name: String(e.target.value),
      }));
    }
  }

  const handleDownload = (url: string): void => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'relatorio.csv';
    link.click();
  };

  return (
    <>
      <NewCampaignContainer>
        <NewCampaignFields>
          <BoxContainer>
            <TextContainer>
              <TextField
                variant="outlined"
                label={getTranslation('name')}
                fullWidth
                disabled={readOnly}
                value={actioning.name}
                onChange={(e) =>
                  setActioning((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
              />
            </TextContainer>
          </BoxContainer>

          <BoxContainer>
            <TextContainer>
              <TextField
                variant="outlined"
                label={getTranslation('description')}
                fullWidth
                disabled={readOnly}
                value={actioning.description}
                onChange={(e) =>
                  setActioning((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </TextContainer>
          </BoxContainer>

          <BoxContainer>
            <TextContainer>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={currentLanguage}
              >
                <KeyboardDateTimePicker
                  fullWidth
                  variant="dialog"
                  disabled={readOnly}
                  ampm={false}
                  label={getTranslation('dateAndHour')}
                  error={!!error.scheduledDate}
                  helperText={error.scheduledDate}
                  value={new Date(actioning.scheduled_date)}
                  minDate={new Date()}
                  onChange={(date) => {
                    setActioning((prev) => ({
                      ...prev,
                      scheduled_date: new Date(`${date}`),
                    }));
                  }}
                />
              </MuiPickersUtilsProvider>
            </TextContainer>

            <TextContainer>
              {readOnly ? (
                <TextField
                  variant="outlined"
                  label={getTranslation('campaign')}
                  fullWidth
                  disabled={readOnly}
                  value={actioning.campaign_name}
                />
              ) : (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getTranslation('campaign')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={actioning.campaign_name}
                    defaultValue={actioning.campaign_name}
                    disabled={readOnly}
                    onChange={(e) => handleChangeCampaign(e)}
                    error={!!error.campaign}
                    label={getTranslation('campaign')}
                  >
                    {campaigns.map((campaign) => (
                      <MenuItem key={campaign._id} value={campaign.name}>
                        {campaign.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!error.campaign && (
                    <FormHelperText id="campaign-id" error={!!error.campaign}>
                      {error.campaign}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            </TextContainer>

            <TextContainer>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  disabled
                  value={actioning.status}
                  label="Status"
                >
                  <MenuItem value={'not_started'}>
                    {getTranslation('notStarted')}
                  </MenuItem>
                  <MenuItem value={'in_progress'}>
                    {getTranslation('inProgress')}
                  </MenuItem>
                  <MenuItem value={'concluded'}>
                    {getTranslation('concluded')}
                  </MenuItem>
                  <MenuItem value={'error'}>Error</MenuItem>
                </Select>
              </FormControl>
            </TextContainer>
          </BoxContainer>

          <BoxContainer>
            <TextContainer>
              <TextField
                variant="outlined"
                label={getTranslation('loadFile')}
                fullWidth
                value={actioning.file}
                error={!!error.file}
                helperText={error.file}
                disabled={readOnly}
              />
            </TextContainer>

            <Tooltip
              title={!currentCampaign.name ? getTranslation('modal.campaigns.actioning.campaign.required') : ''}
              placement='bottom'
            >
              <span>
                <HighlightedButton
                  onClick={() => setImportFile(true)}
                  disabled={readOnly || !currentCampaign.name}
                  height={54}
                  style={{ marginRight: '8px' }}
                >
                  {getTranslation('loadFile')}
                </HighlightedButton>
              </span>
            </Tooltip>
          </BoxContainer>

          {actioning.file && (
            <S.BoxVariableAndColumn>
              <Typography variant="h6" children={getTranslation('content')} />

              <TextContainer>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={5}
                  value={actioning.content}
                  disabled={readOnly}
                  style={{
                    whiteSpace: 'pre-wrap',
                  }}
                />
              </TextContainer>
            </S.BoxVariableAndColumn>
          )}

          {actioning.log_file && (
            <ResultDownloadButton
              variant="contained"
              startIcon={<ArrowDownward />}
              onClick={() => handleDownload(actioning.log_file || '')}
            >
              {'Baixar Resultado'}
              {loading && <CircularProgress color="inherit" size={18} />}
            </ResultDownloadButton>
          )}
        </NewCampaignFields>

        <StyledGridButtons>
          <StyledRightButtons>
            <Button
              disabled={loading}
              variant="contained"
              color="default"
              startIcon={<CloseRounded />}
              onClick={() => handleClose()}
            >
              {getTranslation('footerButtons.discardAndExit')}
            </Button>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<CheckIcon />}
              onClick={() => validateActioning(actioning)}
              disabled={loading || readOnly}
            >
              {getTranslation('footerButtons.saveAndExit')}
              {loading && <CircularProgress color="inherit" size={18} />}
            </Button>
          </StyledRightButtons>
        </StyledGridButtons>
      </NewCampaignContainer>
    </>
  );
}
