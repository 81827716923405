import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const RulesIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size || '24px'}
      height={size || '24px'}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      fill={color || '#424242'}
    >
      <path d="M4 2A2 2 0 0 0 2 4V12H4V8H6V12H8V4A2 2 0 0 0 6 2H4M4 4H6V6H4M22 15.5V14A2 2 0 0 0 20 12H16V22H20A2 2 0 0 0 22 20V18.5A1.54 1.54 0 0 0 20.5 17A1.54 1.54 0 0 0 22 15.5M20 20H18V18H20V20M20 16H18V14H20M5.79 21.61L4.21 20.39L18.21 2.39L19.79 3.61Z" />{' '}
    </svg>
  );
};

export default RulesIcon;
