import {
  Button,
  Container,
  Grid,
  makeStyles,
  Table,
  Theme,
  withTheme,
} from '@material-ui/core';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';
import { defaultTheme } from 'themes/DefaultTheme';

export const CampaignsContainer = styled(Container)`
  width: 100%;
  height: 100%;
  background-color: #0e103b;
  border-radius: 8px;
  color: #fff;
  border: 1px solid #353759;
`;

export const CampaignsContent = styled(MttGrid)`
  overflow-y: hidden;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px 0;

  & .MuiInputBase-root .MuiInputAdornment-root {
    color: #eee;
  }

  .MuiAppBar-colorPrimary {
    background-color: ${defaultTheme.palette.primary.main};
    position: absolute;
  }
  .MuiOutlinedInput-input {
    background-color: #fff;
  }
  .MuiFormControl-root {
    background-color: #fff;
  }
  .MuiToolbar-root {
    justify-content: space-between;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;

  padding: 0 8px;
`;

export const SearchAndTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContainerHeader = styled(MttGrid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiIconButton-root {
    padding: 4px;
    margin: 0 12px;
  }

  .MuiButton-contained {
    height: 32px;
  }
`;

export const HeaderButtonsContainer = styled.div`
  .MuiButton-outlined {
    margin-right: 16px;
    max-height: 30px;
  }

  .MuiButton-contained {
    width: auto;
  }
`;

export const GridSearch = styled(MttGrid)`
  margin-top: 16px;
`;

export const ModalTemplatesSearch = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: 16px;

  & .MuiFormControl-root {
    background: transparent;
  }
`

export const CampaignsTable = styled(Table)`
  min-width: 650px;

  svg {
    fill: #fff;
  }

  thead,
  tfoot {
    th {
      color: #fff;
      font-weight: bold;
      border-bottom: 1px solid #353759;
      padding: 16px 0;
      font-size: medium;
    }

    td {
      color: #fff;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid #353759;
        color: #fff;
        font-weight: lighter;
        padding: 16px 0;
      }
    }
  }

  tfoot {
    td {
      border-bottom: none;
      justify-content: flex-end;
    }
  }
`;

export const NewCampaignContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const NewCampaignFields = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  height: 85%;
  overflow-x: hidden;
  padding-right: 16px;
  gap: 18px;

  & label,
  svg {
    color: #fff;
  }

  & label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary.main};
  }

  & .MuiOutlinedInput-root {
    background-color: #00032e;

    & fieldset {
      border-color: #353759;
    }

    &.Mui-focused fieldset {
      border: 1px solid ${(props) => props.theme.palette.secondary.main};
    }

    &.Mui-focused svg {
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }

  & .MuiOutlinedInput-input {
    background-color: transparent;
    color: #fff;
  }

  & .MuiInput-underline {
    & .MuiInputBase-input {
      padding: 8px;
      color: #fff;
    }
  }
  & .MuiInput-underline:after {
    border-color: ${(props) => props.theme.palette.secondary.main};
  }

  & .MuiFormControl-root {
    background-color: #00032e;
    border-radius: 4px;
    color: #fff;

    & label,
    svg {
      color: #fff;
    }

    & label.Mui-focused {
      color: ${(props) => props.theme.palette.secondary.main};
    }

    & .MuiOutlinedInput-root {
      background-color: transparent;

      & fieldset {
        border-color: #353759;
      }

      &.Mui-focused fieldset {
        border: 1px solid ${(props) => props.theme.palette.secondary.main};
      }

      &.Mui-focused svg {
        color: ${(props) => props.theme.palette.secondary.main};
      }
    }

    & .MuiOutlinedInput-input {
      background-color: transparent;
      color: #fff;
    }
  }

  & .MuiSelect-outlined {
    color: #fff;
    background-color: #00032e;
  }

  & .MuiMenuItem-root {
    color: #fff;
    background-color: #00032e;

    &[data-focus='true'] {
      background-color: ${(props) => props.theme.palette.secondary.main};
      color: #00032e;
    }

    &[aria-selected='true'] {
      background-color: ${(props) => props.theme.palette.secondary.main};
      color: #00032e;
    }
  }

  & .MuiContainer-root {
    max-width: none;
  }
`);

export const StyledGridButtons = styled(MttGrid).attrs({
  className: 'grayButtonMaterial',
})`
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledRightButtons = styled.div<{ isTemplate?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: ${(props) =>
    props.isTemplate ? 'space-between' : 'flex-end'};
`;

export const ContentContainer = styled(Grid)`
  overflow-y: auto;
  padding: 20px;
  margin-top: 16px;
`;

export const ButtonsContainer = styled.div`
  gap: 8px;
  display: flex;
`;

export const TextContainer = styled(MttGrid)<{ autoWidth?: boolean, paddingx?: string }>`
  width: ${(props) => (!props.autoWidth ? '100%' : 'auto')};
  min-height: 56px;
  padding: 8px ${(props) => (props.paddingx && props.paddingx?.length > 0 ? `${props.paddingx}` : '')};
`;

export const BoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HighlightedButton = withTheme(styled(Button)<{ height?: number }>`
  height: ${(props) => props.height + 'px' || '100%'};

  &.MuiButtonBase-root {
    color: #fff;
    border: ${(props) => props.theme.border.primary};
    background-color: ${(props) => props.theme.palette.primary.main};
    padding: 6px 16px;

    &:hover {
      transition: all ease 0.3s;
      background-color: ${(props) => props.theme.palette.primary.dark};
      border: ${(props) => props.theme.border.secondary};
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`);

export const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    '& .MuiFormControl-root': {
      background: 'transparent',
    },
  },
  searchInput: {
    '& label, svg': {
      color: '#fff',
    },
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },

    '& .MuiOutlinedInput-root': {
      backgroundColor: '#00032e',

      '& fieldset': {
        borderColor: '#353759',
      },

      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.secondary.main}`,
      },

      '&.Mui-focused svg': {
        color: theme.palette.secondary.main,
      },
    },

    '& .MuiOutlinedInput-input': {
      backgroundColor: 'transparent',
      color: '#fff',
    },
  },
  tableContainer: {
    borderRadius: '4px',
    marginTop: 24,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 32,
  },
  textContainer: {
    padding: '8px 0px'
  }
}));
