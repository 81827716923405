import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TableFooter,
  TablePagination,
  TextField,
  Typography,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Add, Search } from '@material-ui/icons';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useToast } from 'contexts/ToastContext';
import React, { useEffect, useState } from 'react';
import { GetCustomParams } from 'services/CustomParamsService';
import { CustomParam } from 'services/CustomParamsService/types';
import useTranslator from 'utils/hooks/Translator';
import * as S from '../styles';
import { CreateOrEditParam } from './CreateOrEditParam';

export function CustomParams() {
  const classes = S.useStyles();
  const { currentData } = useFintalkCloud();
  const [openCustomerDetailsModal, setOpenCustomerDetailsModal] =
    useState(false);
  const [customParams, setCustomParams] = useState<CustomParam[]>([]);
  const [param, setParam] = useState<CustomParam | null>(null);
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { getTranslation } = useTranslator();
  const { toastNotification } = useToast();

  async function loadParams() {
    setLoading(true);
    try {
      const response = await GetCustomParams(
        currentData.agentName.split('-')[0]
      );
      if (response.ok) {
        const jsonResponse = await response.json();
        setCustomParams(jsonResponse);
      }
    } catch (error) {
      console.log(error);
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.searchCustomParams'),
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    loadParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData.agentName]);

  const filteredParams =
    customerId !== ''
      ? customParams.filter((param) => param.name.includes(customerId))
      : customParams;

  const handleOpenParamDetails = (selectedParam: CustomParam) => {
    setParam(selectedParam);
    setOpenCustomerDetailsModal(true);
  };

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  function handleCreateParam() {
    setParam(null);
    setOpenCustomerDetailsModal(true);
  }

  function handleCloseCustomerDetails() {
    setOpenCustomerDetailsModal(false);
    loadParams();
  }

  return (
    <>
      <CreateOrEditParam
        open={openCustomerDetailsModal}
        param={param}
        handleClose={handleCloseCustomerDetails}
      />

      <S.CustomersContainer disableGutters maxWidth={false}>
        <S.CustomersContent>
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <Typography variant="h6" style={{ flex: 1 }}>
              {getTranslation('parameters')}
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              onClick={handleCreateParam}
            >
              {getTranslation('add')}
            </Button>
          </div>

          <S.SearchTagContainer>
            <S.TextContainer>
              <TextField
                label={getTranslation('modal.conversationHistory.search')}
                value={customerId || ''}
                onChange={(e) => setCustomerId(e.target.value)}
                helperText={error || ''}
                error={!!error}
                onBlur={() => setError('')}
                InputLabelProps={{ color: 'secondary' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </S.TextContainer>
          </S.SearchTagContainer>

          {loading ? (
            <Grid
              style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="secondary" size={36} />
            </Grid>
          ) : filteredParams.length > 0 ? (
            <S.CustomersListGrid>
              <TableContainer classes={{ root: classes.tableContainer }}>
                <S.CustomersTable aria-label="simple table" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell>{getTranslation('name')}</TableCell>
                      <TableCell align="center">
                        {getTranslation('types')}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(rowsPerPage > 0
                      ? filteredParams.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : filteredParams
                    ).map((row) => (
                      <TableRow
                        key={row.name}
                        onClick={() => handleOpenParamDetails(row)}
                      >
                        <TableCell scope="row">{row.name}</TableCell>
                        <TableCell align="center">{row.type}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={filteredParams.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={getTranslation(
                          'modal.conversationHistory.linesPerPage'
                        )}
                      />
                    </TableRow>
                  </TableFooter>
                </S.CustomersTable>
              </TableContainer>
            </S.CustomersListGrid>
          ) : (
            <p style={{ marginTop: 24 }}>{getTranslation('noCustomersParamsResult')}</p>
          )}
        </S.CustomersContent>
      </S.CustomersContainer>
    </>
  );
}
