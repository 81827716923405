import React, { useEffect, useRef, useState } from 'react';
import './BlocklyComponent.css';

import HeaderBlockly from './Header/HeaderBlockly';

import 'blockly/blocks';
import Blockly from 'blockly/core';
import {
  ClearWorkSpace,
  ShowJS,
  exit_logic,
  populate_variables_with_formdata,
  populate_vars,
  setCurrentAgent,
  setCurrentGroup,
} from './logic';

import {
  setCurrentAgent as setAgentOnCompiler,
  setCurrentGroup as setGroupOnCompiler,
} from './compiler';

// import { getTextCode } from './logic_custom_generator';
import { array_connectors, create_array_connectors } from './compiler';
import localeEN from './locales/en-us.json';
import localeES from './locales/es.json';
import localePT from './locales/pt-br';
import './logic';
import './logic_custom_category';
import './logic_custom_generator';
import './logic_custom_sql';

import { useFlow } from 'contexts/Flow/flowContext';
import { useForm } from 'contexts/Form/formContext';
import EIntentType from 'enums/EIntentType';
import useTranslator from 'utils/hooks/Translator';
import CodeEditor from '../CodeEditor';
import TextEditor from '../TextEditor';
import { Block, Category, Field, Mutation, Sep, Shadow, Value } from './index';

// Blockly.setLocale(locale);
const environment = process.env.REACT_APP_ENV;

let primaryWorkspace;

export default function BlocklyConstructor(props) {
  const { state, dispatch } = useForm();
  const { idGroup, botName } = useFlow().state;
  const [showCode, setShowCode] = useState(false);
  const [showTextEditor, setShowTextEditor] = useState(false);
  const [code, setCode] = useState('');
  const [currentBlock, setCurrentBlock] = useState();
  const [currentBlockValue, setCurrenBlockValue] = useState('');
  const [blockEditable, setBlockEditable] = useState(false);

  const blocklyDiv = React.useRef();
  const toolbox = useRef();
  const { getTranslation } = useTranslator();
  const currLocale = localStorage.getItem('i18nextLng');

  useEffect(() => {
    if (currLocale === 'pt-BR') Blockly.setLocale(localePT);
    else if (currLocale === 'es') Blockly.setLocale(localeES);
    else Blockly.setLocale(localeEN);
  }, [currLocale]);

  useEffect(() => {
    const { initialXml, children, flows, dataForm, ...rest } = props;

    populate_vars(flows);
    populate_variables_with_formdata(dataForm);
    primaryWorkspace = Blockly.inject(blocklyDiv.current, {
      toolbox: toolbox.current,
      media: '../../media/',
      toolboxPosition: 'start',
      sounds: true,
      zoom: {
        controls: true,
        wheel: true,
        startScale: 1.0,
        maxScale: 3,
        minScale: 0.3,
        scaleSpeed: 1.2,
        pinch: true,
      },
      maxBlocks: Infinity,
      grid: {
        spacing: 10,
        length: 1,
        colour: '#888',
        snap: true,
      },
      ...rest,
    });

    Blockly.mainWorkspace.addChangeListener(onBlockSelected);

    if (initialXml) {
      Blockly.Xml.domToWorkspace(
        Blockly.Xml.textToDom(initialXml),
        primaryWorkspace
      );
    }

    const modal = document.getElementById('blocklyDiv');
    const blocklyWidgetDiv = document
      .getElementsByClassName('blocklyWidgetDiv')
      ?.item(0);

    const blocklyTooltipDiv = document
      .getElementsByClassName('blocklyTooltipDiv')
      ?.item(0);

    if (modal) {
      if (blocklyWidgetDiv) modal.append(blocklyWidgetDiv);
      if (blocklyTooltipDiv) modal.append(blocklyTooltipDiv);
    }
  }, [blocklyDiv, props, toolbox]);

  useEffect(() => {
    if (idGroup) {
      setCurrentGroup(idGroup);
      setGroupOnCompiler(idGroup);
    }
  }, [idGroup]);

  useEffect(() => {
    if (botName) {
      setCurrentAgent(botName);
      setAgentOnCompiler(botName);
    }
  }, [botName]);

  const handleClear = () => {
    ClearWorkSpace();
  };

  const handleShowCode = () => {
    const codeJs = ShowJS();
    setCode(codeJs);
    setShowCode(true);
  };

  const handleSaveAndExit = () => {
    resetDivInputslockly();
    const dataBlockly = exit_logic();
    create_array_connectors(dataBlockly.payload);
    updateOutputs();

    state.dataForm.dataBlockly = {
      lastVersion: state.dataForm.dataBlockly.lastVersion + 1 || 0,
      ...dataBlockly,
    };

    dispatch({
      type: 'updateForm',
      data: {
        ...state,
        openModalLogicEditor: false,
      },
    });
  };

  const handleSave = () => {
    const dataBlockly = exit_logic();
    localStorage.setItem('transfer-area', dataBlockly.xml);
  };

  const handleClose = () => {
    resetDivInputslockly();
    dispatch({
      type: 'updateForm',
      data: {
        ...state,
        openModalLogicEditor: false,
      },
    });
  };

  const updateOutputs = () => {
    if (
      array_connectors &&
      ![
        EIntentType.CarouselParent,
        EIntentType.MultipleChoiceParent,
        EIntentType.OptionsBlock,
      ].includes(state.dataForm.intentType)
    ) {
      let auxArray = [];
      if (![EIntentType.NewGroup].includes(state.dataForm.intentType)) {
        auxArray = !!array_connectors.length ? array_connectors : [];
      }

      const outputs = [];
      for (let index = 0; index < auxArray.length; index++) {
        outputs.push({
          title: auxArray[index],
        });
      }
      state.dataForm.outputs = outputs;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...state.dataForm,
          },
        },
      });
    }
  };

  const resetDivInputslockly = () => {
    const body = document.body;
    const blocklyWidgetDiv = document
      .getElementsByClassName('blocklyWidgetDiv')
      ?.item(0);
    const blocklyTooltipDiv = document
      .getElementsByClassName('blocklyTooltipDiv')
      ?.item(0);
    if (body) {
      if (blocklyWidgetDiv) {
        blocklyWidgetDiv.style.display = 'none';
        body.append(blocklyWidgetDiv);
      }
      if (blocklyTooltipDiv) body.append(blocklyTooltipDiv);
    }
  };

  const handleRecover = () => {
    ClearWorkSpace();
    const xml = localStorage.getItem('transfer-area');
    if (xml) {
      Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), primaryWorkspace);
    }
  };

  function onBlockSelected(event) {
    if (event.type === Blockly.Events.Create) {
      return;
    }

    if (event.type === Blockly.Events.CLICK) {
      if (event.blockId === null) {
        setBlockEditable(false);
      }
      return;
    }

    if (event.type === Blockly.Events.SELECTED) {
      const block = Blockly.mainWorkspace.getBlockById(event.newElementId);
      setCurrentBlock(block);

      if (block) {
        if (
          block.type === 'jscodemultiline' ||
          block.type === 'jscode_inline'
        ) {
          setCurrenBlockValue(block.getFieldValue('CODE'));
          setBlockEditable(true);
        } else {
          setBlockEditable(false);
        }
      }
    }
  }

  function updateBlockValue(value) {
    setShowTextEditor(false);

    if (currentBlock) {
      const block = Blockly.mainWorkspace.getBlockById(currentBlock.id);
      if (
        block.type === 'jscodemultiline' ||
        block.type === 'jscode_inline' ||
        block.type === 'comment'
      ) {
        block.setFieldValue(value, 'CODE');
      } else block.setFieldValue(value, 'TEXT');
    }
  }

  function getCurrentBlockValue() {
    return currentBlockValue;
  }

  function editBlock() {
    setShowTextEditor(true);
  }

  return (
    <>
      <div style={{ height: '100vh' }}>
        <HeaderBlockly
          fluxName={getTranslation('blockly.title')}
          clean={handleClear}
          showJS={handleShowCode}
          saveAndClose={handleSaveAndExit}
          save={handleSave}
          recover={handleRecover}
          close={handleClose}
          editBlock={editBlock}
          blockEditable={blockEditable}
        />
        <div ref={blocklyDiv} id="blocklyDiv" />
        <xml
          xmlns="https://developers.google.com/blockly/xml"
          is="blockly"
          style={{ display: 'none' }}
          ref={toolbox}
        >
          <Category
            name={getTranslation('blockly.menu.logic')}
            colour="#ffef96"
          >
            <Block type="controls_if"></Block>
            <Block type="logic_compare">
              <Field name="OP">EQ</Field>
            </Block>
            <Block type="logic_operation">
              <Field name="OP">AND</Field>
            </Block>
            <Block type="logic_negate"></Block>
            <Block type="logic_boolean">
              <Field name="BOOL">TRUE</Field>
            </Block>
            <Block type="logic_null"></Block>
            <Block type="logic_ternary"></Block>
          </Category>
          <Category
            name={getTranslation('blockly.menu.repetitions')}
            colour="#995ba5"
          >
            <Block type="controls_repeat_ext">
              <Value name="TIMES">
                <Shadow type="math_number">
                  <Field name="NUM">10</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="controls_whileUntil">
              <Field name="MODE">WHILE</Field>
            </Block>
            <Block type="controls_for">
              <Field name="VAR" id="!Nv{W6B`7e2-RC9cYfUB">
                i
              </Field>
              <Value name="FROM">
                <Shadow type="math_number">
                  <Field name="NUM">1</Field>
                </Shadow>
              </Value>
              <Value name="TO">
                <Shadow type="math_number">
                  <Field name="NUM">10</Field>
                </Shadow>
              </Value>
              <Value name="BY">
                <Shadow type="math_number">
                  <Field name="NUM">1</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="controls_forEach">
              <Field name="VAR" id="3ER{pv]cGgf+rWf^m7=B">
                j
              </Field>
            </Block>
            <Block type="controls_flow_statements">
              <Field name="FLOW">BREAK</Field>
            </Block>
          </Category>
          <Category name={getTranslation('blockly.menu.math')} colour="#9fa55b">
            <Block type="math_number">
              <Field name="NUM">0</Field>
            </Block>
            <Block type="math_arithmetic">
              <Field name="OP">ADD</Field>
              <Value name="A">
                <Shadow type="math_number">
                  <Field name="NUM">1</Field>
                </Shadow>
              </Value>
              <Value name="B">
                <Shadow type="math_number">
                  <Field name="NUM">1</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="ToInt"></Block>
            <Block type="ToDec"></Block>
            <Block type="math_single">
              <Field name="OP">ROOT</Field>
              <Value name="NUM">
                <Shadow type="math_number">
                  <Field name="NUM">9</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="math_trig">
              <Field name="OP">SIN</Field>
              <Value name="NUM">
                <Shadow type="math_number">
                  <Field name="NUM">45</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="math_constant">
              <Field name="CONSTANT">PI</Field>
            </Block>
            <Block type="math_number_property">
              <Mutation divisor_input="false"></Mutation>
              <Field name="PROPERTY">EVEN</Field>
              <Value name="NUMBER_TO_CHECK">
                <Shadow type="math_number">
                  <Field name="NUM">0</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="math_round">
              <Field name="OP">ROUND</Field>
              <Value name="NUM">
                <Shadow type="math_number">
                  <Field name="NUM">3.1</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="math_on_list">
              <Mutation op="SUM"></Mutation>
              <Field name="OP">SUM</Field>
            </Block>
            <Block type="math_modulo">
              <Value name="DIVIDEND">
                <Shadow type="math_number">
                  <Field name="NUM">64</Field>
                </Shadow>
              </Value>
              <Value name="DIVISOR">
                <Shadow type="math_number">
                  <Field name="NUM">10</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="math_constrain">
              <Value name="VALUE">
                <Shadow type="math_number">
                  <Field name="NUM">50</Field>
                </Shadow>
              </Value>
              <Value name="LOW">
                <Shadow type="math_number">
                  <Field name="NUM">1</Field>
                </Shadow>
              </Value>
              <Value name="HIGH">
                <Shadow type="math_number">
                  <Field name="NUM">100</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="math_random_int">
              <Value name="FROM">
                <Shadow type="math_number">
                  <Field name="NUM">1</Field>
                </Shadow>
              </Value>
              <Value name="TO">
                <Shadow type="math_number">
                  <Field name="NUM">100</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="math_random_float"></Block>
          </Category>
          <Category name={getTranslation('blockly.menu.text')} colour="#5ba58c">
            <Block type="text">
              <Field name="TEXT"></Field>
            </Block>
            <Block type="multiline_text_input">
              <Field name="TEXT"></Field>
            </Block>
            <Block type="text_join">
              <Mutation items="2"></Mutation>
            </Block>
            <Block type="text_append">
              <Field name="VAR" id="A6.A/Yl%eu+iQ(,(Jw54">
                item
              </Field>
              <Value name="TEXT">
                <Shadow type="text">
                  <Field name="TEXT"></Field>
                </Shadow>
              </Value>
            </Block>

            <Block type="text_length">
              <Value name="VALUE">
                <Shadow type="text">
                  <Field name="TEXT">abc</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="convert"></Block>
            <Block type="text_isEmpty">
              <Value name="VALUE">
                <Shadow type="text">
                  <Field name="TEXT"></Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="text_indexOf">
              <Field name="END">FIRST</Field>
              <Value name="VALUE">
                <Block type="variables_get">
                  <Field name="VAR" id="_1pi*JE[cQ;PeTqoxXAP">
                    text
                  </Field>
                </Block>
              </Value>
              <Value name="FIND">
                <Shadow type="text">
                  <Field name="TEXT">abc</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="text_charAt">
              <Mutation at="true"></Mutation>
              <Field name="WHERE">FROM_START</Field>
              <Value name="VALUE">
                <Block type="variables_get">
                  <Field name="VAR" id="_1pi*JE[cQ;PeTqoxXAP">
                    text
                  </Field>
                </Block>
              </Value>
            </Block>
            <Block type="text_getSubstring">
              <Mutation at1="true" at2="true"></Mutation>
              <Field name="WHERE1">FROM_START</Field>
              <Field name="WHERE2">FROM_START</Field>
              <Value name="STRING">
                <Block type="variables_get">
                  <Field name="VAR" id="_1pi*JE[cQ;PeTqoxXAP">
                    text
                  </Field>
                </Block>
              </Value>
            </Block>
            <Block type="text_changeCase">
              <Field name="CASE">UPPERCASE</Field>
              <Value name="TEXT">
                <Shadow type="text">
                  <Field name="TEXT">abc</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="text_trim">
              <Field name="MODE">BOTH</Field>
              <Value name="TEXT">
                <Shadow type="text">
                  <Field name="TEXT">abc</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="conv_date"></Block>
            <Block type="conv_time"></Block>
            <Block type="text_print">
              <Value name="TEXT">
                <Shadow type="text">
                  <Field name="TEXT">abc</Field>
                </Shadow>
              </Value>
            </Block>

            <Block type="text_prompt_ext">
              <Mutation type="TEXT"></Mutation>
              <Field name="TYPE">TEXT</Field>
              <Value name="TEXT">
                <Shadow type="text">
                  <Field name="TEXT">abc</Field>
                </Shadow>
              </Value>
            </Block>
          </Category>

          <Category
            name={getTranslation('blockly.menu.lists')}
            colour="#745ba5"
          >
            <Block type="lists_create_with">
              <Mutation items="0"></Mutation>
            </Block>
            <Block type="lists_create_with">
              <Mutation items="3"></Mutation>
            </Block>
            <Block type="lists_repeat">
              <Value name="NUM">
                <Shadow type="math_number">
                  <Field name="NUM">5</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="lists_length"></Block>
            <Block type="lists_isEmpty"></Block>
            <Block type="lists_indexOf">
              <Field name="END">FIRST</Field>
              <Value name="VALUE">
                <Block type="variables_get">
                  <Field name="VAR" id="IO4-BJyAbSzAHeBs+~Gb">
                    lista
                  </Field>
                </Block>
              </Value>
            </Block>
            <Block type="lists_getIndex">
              <Mutation statement="false" at="true"></Mutation>
              <Field name="MODE">GET</Field>
              <Field name="WHERE">FROM_START</Field>
              <Value name="VALUE">
                <Block type="variables_get">
                  <Field name="VAR" id="IO4-BJyAbSzAHeBs+~Gb">
                    list
                  </Field>
                </Block>
              </Value>
            </Block>
            <Block type="lists_setIndex">
              <Mutation at="true"></Mutation>
              <Field name="MODE">SET</Field>
              <Field name="WHERE">FROM_START</Field>
              <Value name="LIST">
                <Block type="variables_get">
                  <Field name="VAR" id="IO4-BJyAbSzAHeBs+~Gb">
                    list
                  </Field>
                </Block>
              </Value>
            </Block>
            <Block type="lists_getSublist">
              <Mutation at1="true" at2="true"></Mutation>
              <Field name="WHERE1">FROM_START</Field>
              <Field name="WHERE2">FROM_START</Field>
              <Value name="LIST">
                <Block type="variables_get">
                  <Field name="VAR" id="IO4-BJyAbSzAHeBs+~Gb">
                    list
                  </Field>
                </Block>
              </Value>
            </Block>
            <Block type="lists_split">
              <Mutation mode="SPLIT"></Mutation>
              <Field name="MODE">SPLIT</Field>
              <Value name="DELIM">
                <Shadow type="text">
                  <Field name="TEXT">,</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="lists_sort">
              <Field name="TYPE">NUMERIC</Field>
              <Field name="DIRECTION">1</Field>
            </Block>
          </Category>
          <Sep></Sep>

          <Category
            name={getTranslation('blockly.menu.localVariables')}
            colour="#a55b80"
            custom="VARIABLE"
          ></Category>
          {/* <!--
        <Category name="Variáveis com tipo fixo" colour="#995ba5" custom="VARIABLE_DYNAMIC"></Category>
        --> */}
          <Category
            name={getTranslation('blockly.menu.globalVariables')}
            colour="#b9936c"
          >
            <Block type="var_user"></Block>
            <Block type="set_var_user"></Block>
            <Block type="get_vars"></Block>
            <Block type="set_vars"></Block>
            <Block type="clearvars"></Block>
            <Block type="date"></Block>
          </Category>
          <Category
            name={getTranslation('blockly.menu.blockVariables')}
            colour="#b9936c"
          >
            <Block type="get_variables"></Block>
          </Category>
          <Sep></Sep>
          <Category
            name={getTranslation('blockly.menu.createFunctions')}
            colour="#86af49"
            custom="PROCEDURE"
          ></Category>

          <Category
            name={getTranslation('blockly.menu.useFunctions')}
            colour="#e3eaa7"
          >
            <Block type="isvalidtext"></Block>
            <Block type="isvaliddate"></Block>
            <Block type="isvalidcpf"></Block>
            <Block type="isvalidcnpj"></Block>
            <Block type="isvalidcep"></Block>
            <Block type="getcities"></Block>
            <Block type="wait"></Block>
            <Block type="JSONstringify"></Block>
            <Block type="JSONparse"></Block>
            <Block type="getphone"></Block>
            <Block type="sendsmscode"></Block>
          </Category>
          <Category
            name={getTranslation('blockly.menu.conversationFlow')}
            colour="#b5e7a0"
          >
            <Block type="connector"></Block>
            <Block type="jump_to"></Block>
            <Block type="jump_to_group"></Block>
          </Category>
          <Category
            name={getTranslation('blockly.menu.messageAndOutputs')}
            colour="#0a8006"
          >
            <Block type="msg"></Block>
            <Block type="audio_out"></Block>
            <Block type="sendsms"></Block>
            <Block type="comment"></Block>
          </Category>
          <Sep></Sep>
          <Category
            name={getTranslation('blockly.menu.advanced')}
            colour="#f87a7a"
          >
            <Block type="jscodemultiline">
              <Field name="CODE">{Blockly.Msg['JS_CODE_INNER_TEXT']}</Field>
            </Block>
            <Block type="jscode_inline">
              <Field name="CODE">{Blockly.Msg['JS_CODE_INNER_TEXT']}</Field>
            </Block>
            <Block type="json_create_with"></Block>
            <Block type="dupla_json"></Block>
            <Block type="json"></Block>
            <Block type="array"></Block>
            <Block type="request"></Block>
            <Block type="pwa"></Block>
            <Block type="debug_flag"></Block>
            <Block type="debug_console_log"></Block>
            <Block type="debug_console_log_var"></Block>
            <Sep></Sep>
          </Category>
          <Sep></Sep>
          <Category
            name={getTranslation('blockly.menu.services')}
            colour="#fb8004"
          >
            <Block type="workflow_push"></Block>
            <Block type="livechat"></Block>
            <Block type="translate"></Block>
            {environment !== 'production' && (
              <>
                <Block type="llm_falagpt"></Block>
                <Block type="llm_basic"></Block>
                <Block type="llm_advanced"></Block>
              </>
            )}
            <Block type="create_chart"></Block>
            <Block type="create_table"></Block>
            {environment !== 'production' && <Block type="functions"></Block>}
            <Block type="ocr"></Block>

            <Sep></Sep>
          </Category>
          <Sep></Sep>
          <Category
            name={getTranslation('blockly.menu.googleSheets')}
            colour="#1fff6a"
          >
            <Block type="googlesheetaddrow"></Block>
            <Block type="googlesheetsetheaderrow"></Block>
            <Block type="googlesheetgetrowbynumber"></Block>
            <Block type="googlesheetgetcellbynumber"></Block>
            <Block type="googlesheetsetcellbynumber"></Block>
            <Block type="googlesheetgetcellbya1"></Block>
            <Block type="googlesheetgetrange"></Block>
            <Block type="googlesheetgettable"></Block>
            <Block type="googlesheetgettableA1"></Block>
            <Block type="googlesheetnewtab"></Block>
          </Category>
          <Sep></Sep>
          {environment !== 'production' && (
            <>
              <Category
                name={getTranslation('blockly.menu.dataBases')}
                colour="#ffc31f"
              >
                {/* <Block type="crm_read_field_to_var"></Block> */}
                <Block type="crm_read_field_to_var"></Block>
                <Block type="crm_write_var_to_field"></Block>
                <Block type="crm_get_one"></Block>
                <Block type="crm_new_one"></Block>
                <Block type="crm_update_one"></Block>
                <Block type="crm_delete_one"></Block>
                <Sep></Sep>
                <Block type="get_user_data"></Block>
                <Block type="set_user_data"></Block>
                <Block type="search_data"></Block>
                <Block type="get_user_data_json"></Block>
                <Block type="new_user_data"></Block>
              </Category>
              <Sep></Sep>
            </>
          )}
        </xml>
      </div>
      {showCode && (
        <CodeEditor
          showEditor={showCode}
          code={code}
          handleClose={() => setShowCode(false)}
        />
      )}
      {showTextEditor && (
        <TextEditor
          showEditor={showTextEditor}
          code={getCurrentBlockValue()}
          handleClose={(value) => updateBlockValue(value)}
        />
      )}
    </>
  );
}
