import React, { createContext, useContext, useReducer } from 'react';

import { reducerForm, initialState } from './entityReducers';
import { IEntityContext, IEntityProviderProps } from './types';

export const EntityContext = createContext<IEntityContext>(
  {} as IEntityContext
);

export const EntityProvider: React.FC<IEntityProviderProps> = (
  props: IEntityProviderProps
) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducerForm, initialState);

  return (
    <EntityContext.Provider
      value={{
        state: state || initialState,
        dispatch,
      }}
    >
      {children}
    </EntityContext.Provider>
  );
};

export function useEntity(): IEntityContext {
  const context = useContext(EntityContext);

  return context;
}
