import { makeStyles, Theme, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import { defaultTheme } from 'themes/DefaultTheme';

export const NewCampaignContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 76px);
  overflow: auto;
`;

export const NewCampaignFieldsContainer = withTheme(styled.div`
  display: flex;
  flex: 1;

  & label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary.main};
  }

  & .MuiOutlinedInput-root {
    background-color: #00032e;

    & fieldset {
      border-color: #353759;
    }

    &.Mui-focused fieldset {
      border: 1px solid ${(props) => props.theme.palette.secondary.main};
    }

    &.Mui-focused svg {
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }

  & .MuiOutlinedInput-input {
    background-color: transparent;
    color: #fff;
  }

  & .MuiFormControl-root {
    background-color: #00032e;
    border-radius: 4px;
    color: #fff;

    & label,
    svg {
      color: #fff;
    }

    & label.Mui-focused {
      color: ${(props) => props.theme.palette.secondary.main};
    }

    & .MuiOutlinedInput-root {
      background-color: transparent;

      & fieldset {
        border-color: #353759;
      }

      &.Mui-focused fieldset {
        border: 1px solid ${(props) => props.theme.palette.secondary.main};
      }

      &.Mui-focused svg {
        color: ${(props) => props.theme.palette.secondary.main};
      }
    }

    & .MuiOutlinedInput-input {
      background-color: transparent;
      color: #fff;
    }
  }

  & .MuiSelect-outlined {
    color: #fff;
    background-color: #00032e;
  }

  & .MuiMenuItem-root {
    color: #fff;
    background-color: #00032e;

    &[data-focus='true'] {
      background-color: ${(props) => props.theme.palette.secondary.main};
      color: #00032e;
    }

    &[aria-selected='true'] {
      background-color: ${(props) => props.theme.palette.secondary.main};
      color: #00032e;
    }
  }

  & .MuiContainer-root {
    max-width: none;
  }
`);

export const NewCampaignFields = styled.div<{ newWidth?: string }>`
  width: ${(props) => props.newWidth || '100%'};
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 12px;
  overflow-x: hidden;
`;

export const MessageButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: 0;
  padding: 0 6px;
`;

export const DialogTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`;

export const useStyles = makeStyles((theme: Theme) => ({
  selectFileButton: {
    maxWidth: 225,
    height: '52px',
    marginRight: '8px',
    background: `${defaultTheme.palette.secondary.main}`,
    color: '#fff',

    '&:hover': {
      background: '#aa3a58',
      color: '#fff',
    },
  },
  title: {
    flexGrow: 1,
  },
  toolBar: {
    gap: '20px',
  },
  dialogContainer: {
    width: '25vw',
    minWidth: '200px',
    padding: '18px 30px 24px 30px',
    position: 'absolute',
    top: theme.spacing(5),
    gap: '4px',
  },
  dialogIconContainer: {
    padding: '8px',
    marginRight: '-12px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: 0,
  },
}));
