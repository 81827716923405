import { IAppContextActions } from "contexts/App/types";
import { json } from "services/RequestService";
import { IBaseResponse } from "services/RequestService/Response";
import { IGetBubblesResponse } from "./types";

export const GetBubbles = async (
  bot_name: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetBubblesResponse>> => {
  const action = `bots/${bot_name}/bubbles`;
  return json<IGetBubblesResponse>('GET', action, dispatch);
};

export const GetBubbleInfo = async (
  bubbleId: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetBubblesResponse>> => {
  const action = `/bubbles/${bubbleId}`;
  return json<IGetBubblesResponse>('GET', action, dispatch);
};