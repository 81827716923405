import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as S from '../../styles';
import useTranslator from 'utils/hooks/Translator';
import { useEffect, useState } from 'react';
import { GetSingleCustomer } from 'services/CustomerService';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useToast } from 'contexts/ToastContext';

export function SingleCustomer({
  customer,
  open,
  handleClose,
}: {
  customer: any;
  open: boolean;
  handleClose: () => void;
}) {
  const classes = S.useStyles();
  const { getTranslation } = useTranslator();
  const [loading, setLoading] = useState(false);
  const { currentData } = useFintalkCloud();
  const [customerData, setCustomerData] = useState<any>({});
  const { toastNotification } = useToast();

  async function loadCustomer() {
    setLoading(true);
    try {
      const response = await GetSingleCustomer({
        agent: currentData.agentName,
        customerId: customer,
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        setCustomerData(jsonResponse);
      }
    } catch (error) {
      console.log(error);
      toastNotification({
        type: 'error',
        message: 'Error fetching customer data.',
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    loadCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.dialogContainer }}
    >
      {loading ? (
        <Grid
          style={{
            width: '100%',
            height: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="secondary" size={36} />
        </Grid>
      ) : (
        <>
          <Toolbar>
            <Typography variant="h5" style={{ flex: 1 }}>
              {getTranslation('customerDetails')}
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>

          <DialogContent classes={{ root: classes.dialogContent }}>
            <TableContainer
              component={Paper}
              classes={{ root: classes.customerDetailsTableContainer }}
            >
              <Table aria-label="simple table" size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '40%' }}></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell scope="row">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold', color: '#fff' }}
                      >
                        ID
                      </Typography>
                    </TableCell>
                    <TableCell align="left" style={{ color: '#fff' }}>
                      {customer}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold', color: '#fff' }}
                      >
                        {getTranslation('name')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{
                          width: 'fit-content',
                          bgcolor: '#eee',
                          borderRadius: '8px',
                          padding: '4px 8px',
                          color: '#494949',
                          fontWeight: 'bold',
                        }}
                      >
                        {customerData.nickname ?? 'Name not found'}
                      </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold', color: '#fff' }}
                      >
                        CPF
                      </Typography>
                    </TableCell>
                    <TableCell align="left" style={{ color: '#fff' }}>
                      {customerData.cpf ?? 'CPF not found'}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold', color: '#fff' }}
                      >
                        Next Event
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{
                          width: 'fit-content',
                          bgcolor: '#bbffda',
                          borderRadius: '8px',
                          padding: '4px 8px',
                          color: '#008f40',
                          fontWeight: 'bold',
                        }}
                      >
                        {customerData.nextEvent ?? 'Next Event not found'}
                      </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold', color: '#fff' }}
                      >
                        {getTranslation('phone')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{
                          width: 'fit-content',
                          bgcolor: '#ffc6e0',
                          borderRadius: '8px',
                          padding: '4px 8px',
                          color: '#ff188f',
                          fontWeight: 'bold',
                        }}
                      >
                        {customerData.phoneNumber ?? 'Phone Number not found'}
                      </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold', color: '#fff' }}
                      >
                        AppClient
                      </Typography>
                    </TableCell>
                    <TableCell align="left" style={{ color: '#fff' }}>
                      {customerData.appClient ?? 'AppClient not found'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
