import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";
import MttButton from "components/Material/MttButton/MttButton";
import MttGrid from "components/Material/MttGrid/MttGrid";


export const ContainerDialog = styled(MttGrid)<{colorHeader?: string}>`
  height: 100%;

  padding: 8px 16px;
`;

export const ContainerHeader = styled(MttGrid)`
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ModalTitle = styled(Typography).attrs({
  variant:'h6'
})`
  color:#0e103b;
`;

export const FieldContainer = styled(Grid)`
  padding: 5px 0;
  width:100%;
  
  .MuiInputBase-input {
    padding: 16px;
    border: solid 1px rgba(0, 0, 0, 0.1);
  }
  .MuiInputBase-input::-webkit-input-placeholder {
    color: #000;
    opacity: 0.9;
  }
  .MuiInput-underline:before{
    display: none;
  }
`;

export const MoreVariable = styled(Grid)`
    display:flex;
    align-items: center;
    padding: 6px 0;

    .MuiTypography-body1 {
        color: #0e103b;
        font-weight: bold; 
    }
`

export const ButtonContainer = styled(Grid)`
  padding: 16px 0;
  width:100%;  
  display:flex;
  justify-content: flex-end;
`;

export const Button = styled(MttButton)`
  height: 50px;
`;

