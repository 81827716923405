import { DataForm } from "models/DataFlow";
import IntentController from "./IntentController";

export default class RemoveIntentController extends IntentController {

  public removeNodeChild(nodeId: number) {
    const node = this.editorFlow.getNodeFromId(nodeId);

    this.editorFlow.removeNodeId("node-" + nodeId);
    const nodeConnection = node.inputs["input_1"].connections.find(
      (connection) => String(connection.node) === String(node.data.parentId)
    );
    if (!!nodeConnection) {
      this.editorFlow.removeNodeOutput(
        Number(nodeConnection.node),
        nodeConnection.input
      );
    }
    const dataForm: DataForm = this.editorFlow.drawflow.drawflow.Home.data[node.data.parentId].data;
    dataForm.outputs = dataForm.outputs?.filter(output => output.outputid !== String(node.id))
  }

  public removeNodeParent(parentId: number) {
    const block = this.editorFlow.drawflow.drawflow.Home.data;
    const dataForm: DataForm = block[parentId].data

    const childrenIds = dataForm.outputs
      ?.map(output => Number(output.outputid)) || [];

    childrenIds
      .filter((id) => block[id]?.data.parentId === parentId)
      .forEach((id) => this.removeNodeChild(id));

    this.editorFlow.removeNodeId("node-" + parentId);
  }



}
