/* eslint-disable eqeqeq */
import React, { useCallback, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import CloseIcon from '@material-ui/icons/Close';

import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ContainerDialog } from './styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let l, i, id: any, nome, outputs, node_name, node_id;
let map_array: any[] = [];
let sankey_array: any[] = [];
let loop_blocker: any[] = [];
let level, temp;
let temp_array = [];
let level_max = 7;
let drawflow: any = {};

const ModalHeatmap: React.FC<{
  google: any;
  open: boolean;
  handleClose: () => void;
  nodeId: any;
  state: any;
}> = ({ google, open, handleClose, nodeId, state }) => {
  id = nodeId;
  drawflow = state.editorFlow.drawflow.drawflow;
  map_array = [];
  sankey_array = [];
  loop_blocker = [];
  temp_array = [];

  const drawChart = useCallback(() => {
    if (google) {
      var data = new google.visualization.DataTable();
      data.addColumn('string', 'de');
      data.addColumn('string', 'para');
      data.addColumn('number', '% volume', {
        className: 'box-font',
      });
      data.addRows(sankey_array);

      // Set chart options
      var colors = [
        '#a6cee3',
        '#b2df8a',
        '#fb9a99',
        '#fdbf6f',
        '#cab2d6',
        '#ffff99',
        '#1f78b4',
        '#33a02c',
      ];
      var options = {
        width: 600,
        height: 400,
        sankey: {
          node: {
            colors: colors,
          },
          link: {
            colorMode: 'gradient',
            colors: colors,
          },
        },
      };

      // Instantiate and draw our chart, passing in some options.
      var chart = new google.visualization.Sankey(
        document.getElementById('sankey_multiple')
      );
      chart.draw(data, options);
    }
  }, [google]);

  //adiciona no array de saida os dados no sankey_array
  const create_array = useCallback((array: string | any[]) => {
    if (array) {
      for (var x = 0; x < array.length; x++) {
        // verifica se não é loop, ou seja, se esta apontando para algum id pai
        if (is_loop(array[x].id_output) == false) {
          if (ok_to_save(array[x].name_ini, array[x].name_output)) {
            sankey_array.push([array[x].name_ini, array[x].name_output, 100]);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (google && open) {
      google.charts.setOnLoadCallback(drawChart);

      for (level = 0; level < level_max; level++) {
        temp_array = [];
        //O primeiro é diferente porque recebe o "id"
        if (level == 0) {
          nome = get_node_name(id);
          outputs = get_node_outputs(id);

          loop_blocker.push(id);
          if (outputs) {
            for (i = 0; i < outputs.length; i++) {
              node_id = outputs[i]['outputid'];
              node_name = get_node_name(node_id);
              temp = {
                name_ini: nome,
                id_ini: id,
                name_output: node_name,
                id_output: node_id,
              };

              temp_array.push(temp);
            }
            map_array.push(temp_array);
            create_array(temp_array);
          }
        } else {
          //os demais usam o level 0
          if (map_array[level - 1]) {
            for (l = 0; l < map_array[level - 1].length; l++) {
              id = map_array[level - 1][l].id_output;
              nome = get_node_name(id);
              outputs = get_node_outputs(id);
              if (outputs) {
                for (i = 0; i < outputs.length; i++) {
                  node_id = outputs[i]['outputid'];
                  node_name = get_node_name(node_id);
                  if (node_name) {
                    temp = {
                      name_ini: nome,
                      id_ini: id,
                      name_output: node_name,
                      id_output: node_id,
                    };

                    temp_array.push(temp);
                  }
                }
                map_array.push(temp_array);
                create_array(temp_array);
              }
            }
          }
        }
      }
    }
  }, [create_array, drawChart, google, open]);

  function is_loop(id: any) {
    var is_loop = false;
    for (var count = 0; count < loop_blocker.length; count++) {
      if (loop_blocker[count]) {
        if (loop_blocker[count] == id) {
          is_loop = true;
        }
      }
    }
    loop_blocker.push(id);
    return is_loop;
  }

  function ok_to_save(a: any, b: any) {
    var ok_to_save = true;
    var aa, bb;
    for (var k = 0; k < sankey_array.length; k++) {
      aa = sankey_array[k][0];
      bb = sankey_array[k][1];
      if (a == aa) {
        if (b == bb) {
          ok_to_save = false;
        }
      }
    }
    return ok_to_save;
  }

  //pega o nome a partir do id
  function get_node_name(id: any) {
    if (drawflow.Home.data[id]) {
      if (drawflow.Home.data[id]['data']) {
        if (drawflow.Home.data[id]['data']['name']) {
          return drawflow.Home.data[id]['data']['name'];
        }
      }
    } else return '';
  }

  //pega os outputs (array) a partir do id
  function get_node_outputs(id: any) {
    if (drawflow.Home.data[id]) {
      if (drawflow.Home.data[id]['data']) {
        if (drawflow.Home.data[id]['data']['outputs']) {
          return drawflow.Home.data[id]['data']['outputs'];
        }
      }
    }
    return;
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
    >
      <ContainerDialog>
        <AppBar>
          <Toolbar>
            <Typography variant="h6">Mapa de Calor de Fluxo</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div id="sankey_multiple" style={{ marginTop: '12px' }}></div>
      </ContainerDialog>
    </Dialog>
  );
};

export default ModalHeatmap;
