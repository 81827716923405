import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const StyledTitleGrid = styled(MttGrid)`
  display: flex;
	align-items: center;
  justify-content: space-between;
`;

export const UserParametersContainer = styled.div``;
