import { IAppContextActions } from 'contexts/App/types';
import { ItemDataFlow } from 'models/DataFlow';
import {
  DeleteFlexBlockRegister,
  SaveFlexBlock,
} from 'services/FlexBlocksService';
import { FlexBlocksGroups } from 'services/FlexBlocksService/types';

interface IExportGroup {
  groupName: string;
  blocks: {
    drawflow: {
      Home: {
        data: {
          [key: string]: ItemDataFlow;
        };
      };
    };
  };
}

interface BlockInfo {
  block_name: string;
  groups_used: FlexBlocksGroups[];
}

export default async function flexGroupsTreatment(
  botName: string,
  flows: IExportGroup[],
  dispatch: React.Dispatch<IAppContextActions>
) {
  await DeleteFlexBlockRegister(botName, dispatch);

  const flexGroups = filterFlexGroups(flows);
  const flexBlocksNamesToFind = flexGroups.map((group) =>
    group.groupName.replace('flex-', '')
  );
  const flexBlocksFound = findFlexBlocksByGroupName(
    flows,
    flexBlocksNamesToFind
  );

  const blocksToSave = extractBlocksAndGroups(flexBlocksFound);

  blocksToSave.forEach(async (blockToSave) => {
    const { block_name, groups_used } = blockToSave;
    await SaveFlexBlock(
      {
        block_name,
        groups_used,
        bot_name: botName,
      },
      dispatch
    );
  });
}

function extractBlocksAndGroups(blocks: ItemDataFlow[]): BlockInfo[] {
  const result: Record<string, BlockInfo> = {};

  blocks.forEach((block) => {
    const block_name: string = block.data.name;

    if (result[block_name]) {
      result[block_name].groups_used.push({
        block_id: block.id,
        group_name: block.data.groupId,
      });
    } else {
      result[block_name] = {
        block_name,
        groups_used: [
          {
            group_name: block.data.groupId,
            block_id: block.id,
          },
        ],
      };
    }
  });

  return Object.values(result);
}

function filterFlexGroups(groups: IExportGroup[]) {
  return groups.filter((group) => group.groupName.startsWith('flex-'));
}

function findFlexBlocksByGroupName(
  groups: IExportGroup[],
  flexBlocksNamesToFind: string[]
) {
  const foundBlocks: ItemDataFlow[] = [];
  flexBlocksNamesToFind.forEach((flexName) => {
    groups.forEach((group) => {
      const groupKeys = Object.keys(group.blocks.drawflow.Home.data);
      for (const key in groupKeys) {
        const currentBlock = group.blocks.drawflow.Home.data[groupKeys[key]];
        if (currentBlock && currentBlock.data.name === flexName) {
          foundBlocks.push(currentBlock);
        }
      }
    });
  });

  return foundBlocks;
}
