import React, { useState } from 'react';
import {
  ButtonsDialog,
  Container,
  ContentButtons,
  ContentDialog,
  ContentNameFlux,
  DiscardAndCloseGrid,
  GridAlertButtons,
  GridLeftButtons,
  GridRightButtons,
  SaveAndCloseGrid,
  StyledMttButtonPublic,
} from './styles';
import { IHeaderBlocklyProps } from './types';
import MttTypography from '../../Material/MttTypography/MttTypography';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import Dialog from '@material-ui/core/Dialog/Dialog';
import CleaningIcon from '../../Icons/CleaningIcon';
import { useFlow } from 'contexts/Flow/flowContext';
import useTranslator from 'utils/hooks/Translator';

export default function HeaderBlockly(props: IHeaderBlocklyProps) {
  const {
    clean,
    close,
    saveAndClose,
    save,
    recover,
    showJS,
    editBlock,
    fluxName,
    title,
    blockEditable,
  } = props;
  const { state } = useFlow();
  const { getTranslation } = useTranslator();

  const [showDialogDiscartAndClose, setShowDialogDiscartAndClose] =
    useState<boolean>(false);

  const dialogDiscartAndClose = () => {
    return (
      <Dialog
        open={showDialogDiscartAndClose}
        onClose={() => setShowDialogDiscartAndClose(false)}
      >
        <ContentDialog>
          <MttTypography variant="h6">
            {getTranslation('blockly.confirmDiscard.title')}
          </MttTypography>
          <MttTypography variant="subtitle1">
					{getTranslation('blockly.confirmDiscard.subtitle')}
          </MttTypography>
          <ButtonsDialog>
            <GridAlertButtons>
              <SaveAndCloseGrid>
                <StyledMttButtonPublic
                  color="inherit"
                  variant="contained"
                  onClick={() => setShowDialogDiscartAndClose(false)}
                >
                  {getTranslation('return').toUpperCase()}
                </StyledMttButtonPublic>
              </SaveAndCloseGrid>
              <DiscardAndCloseGrid>
                <StyledMttButtonPublic
                  color="inherit"
                  variant="contained"
                  onClick={() => {
                    setShowDialogDiscartAndClose(false);
                    if (close) close();
                  }}
                >
                  {getTranslation('discard').toUpperCase()}
                </StyledMttButtonPublic>
              </DiscardAndCloseGrid>
            </GridAlertButtons>
          </ButtonsDialog>
        </ContentDialog>
      </Dialog>
    );
  };

  return (
    <>
      <Container>
        <ContentNameFlux>
          <MttTypography variant="subtitle1">
            {fluxName || 'Flux Name'}
          </MttTypography>
          <div>
            <MttTypography variant="subtitle2">{state.botName}</MttTypography>
            <MttTypography variant="subtitle2">{state.idGroup}</MttTypography>
          </div>
        </ContentNameFlux>
        <ContentButtons>
          <GridLeftButtons>
            <StyledMttButtonPublic
              variant="contained"
              color="inherit"
              startIcon={<SaveIcon />}
              onClick={save}
            >
              {getTranslation('blockly.copyLogic')}
            </StyledMttButtonPublic>
            <StyledMttButtonPublic
              variant="contained"
              color="inherit"
              startIcon={<UndoIcon />}
              onClick={recover}
            >
              {getTranslation('blockly.pasteLogic')}
            </StyledMttButtonPublic>
            <StyledMttButtonPublic
              variant="contained"
              color="inherit"
              startIcon={<CleaningIcon color="#fff" size="20" />}
              onClick={clean}
            >
              {getTranslation('clean')}
            </StyledMttButtonPublic>

            <StyledMttButtonPublic
              variant="contained"
              color="inherit"
              onClick={showJS}
            >
              {title ?? getTranslation('blockly.showJS')}
            </StyledMttButtonPublic>
            <StyledMttButtonPublic
              variant="contained"
              color="inherit"
              onClick={editBlock}
              disabled={!blockEditable}
            >
              {getTranslation('blockly.editBlock')}
            </StyledMttButtonPublic>
          </GridLeftButtons>
          <GridRightButtons>
            <SaveAndCloseGrid>
              <StyledMttButtonPublic
                variant="contained"
                color="inherit"
                startIcon={<SaveIcon />}
                onClick={saveAndClose}
              >
                {getTranslation('footerButtons.saveAndExit')}
              </StyledMttButtonPublic>
            </SaveAndCloseGrid>
            <DiscardAndCloseGrid>
              <StyledMttButtonPublic
                variant="contained"
                color="inherit"
                startIcon={<UndoIcon />}
                onClick={() => setShowDialogDiscartAndClose(true)}
              >
                {getTranslation('footerButtons.discardAndExit')}
              </StyledMttButtonPublic>
            </DiscardAndCloseGrid>
          </GridRightButtons>
        </ContentButtons>
      </Container>
      {showDialogDiscartAndClose && dialogDiscartAndClose()}
    </>
  );
}
