import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  border-radius: 4px;
`;

export const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  background: #FFF;

  margin: 16px 16px;
`

export const AutoCompleteOption = styled.span`
  flex: 1;
  margin: 3px 0;
`;
