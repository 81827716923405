import { Snackbar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useFlow } from 'contexts/Flow/flowContext';
import { FormProvider } from 'contexts/Form/formContext';
import EIntentType from 'enums/EIntentType';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTranslator from 'utils/hooks/Translator';
import ABTest from 'views/dataEntries/ABTest';
import Advanced from 'views/dataEntries/Advanced';
import AI123 from 'views/dataEntries/AI123';
import Cancel from 'views/dataEntries/Cancel';
import CarouselChild from 'views/dataEntries/CarouselChild';
import CarouselParent from 'views/dataEntries/CarouselParent';
import Fallback from 'views/dataEntries/Fallback';
import FlexBlock from 'views/dataEntries/FlexBlock';
import FlexBlockEntry from 'views/dataEntries/FlexBlockEntry';
import FlexBlockExit from 'views/dataEntries/FlexBlockExit';
import InitialFlow from 'views/dataEntries/InitialFlow';
import Logic from 'views/dataEntries/Logic';
import MultipleChoiceChild from 'views/dataEntries/MultipleChoiceChild';
import MultipleChoiceParent from 'views/dataEntries/MultipleChoiceParent';
import NewCarousel from 'views/dataEntries/NewCarousel';
import NewGroup from 'views/dataEntries/NewGroup';
import NLU from 'views/dataEntries/NLU';
import OpenHTML from 'views/dataEntries/OpenHTML';
import OptionsBlock from 'views/dataEntries/OptionsBlock';
import Others from 'views/dataEntries/Outhers';
import QuestionsAndAnswers from 'views/dataEntries/QuestionsAndAnswers';
import Rules from 'views/dataEntries/Rules';
import SaveTable from 'views/dataEntries/SaveTable';
import SendDocument from 'views/dataEntries/SendDocument';
import MessageForUser from 'views/dataEntries/SendMessage';
import SimpleQuestion from 'views/dataEntries/SimpleQuestion';
import Welcome from 'views/dataEntries/Welcome';
import { ContainerDialog } from './style';
import './styles.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      background: theme.palette.primary.main,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalForms() {
  const classes = useStyles();

  const { state, dispatch } = useFlow();
  const { toastNotification } = state;

  const [title, setTitle] = useState<string>('');
  const { getTranslation } = useTranslator();
  const { language } = useTranslation().i18n;

  const headerColor = '#0e103b';

  const handleClose = () => {
    dispatch({ type: 'closeModalForms' });
  };

  const form = useMemo(() => {
    switch (state.dataForm?.intentType) {
      case EIntentType.InitialFlow: {
        setTitle(getTranslation('intents.initialFlow'));
        return <InitialFlow />;
      }
      case EIntentType.MultipleChoiceParent: {
        setTitle(getTranslation('intents.extractVariables'));
        return <MultipleChoiceParent />;
      }
      case EIntentType.MultipleChoiceChild: {
        setTitle(getTranslation('intents.extractVariablesChild'));
        return <MultipleChoiceChild />;
      }
      case EIntentType.CarouselParent: {
        setTitle(getTranslation('intents.oldCarousel'));
        return <CarouselParent />;
      }
      case EIntentType.CarouselChild: {
        setTitle(getTranslation('intents.oldCarouselChild'));
        return <CarouselChild />;
      }
      case EIntentType.QuestionsAndAnswers: {
        setTitle(getTranslation('intents.questionAndInput'));
        return <QuestionsAndAnswers />;
      }
      case EIntentType.Advanced: {
        setTitle(getTranslation('intents.advanced'));
        return <Advanced />;
      }
      case EIntentType.NewGroup: {
        setTitle(getTranslation('intents.newGroup'));
        return <NewGroup />;
      }
      case EIntentType.Welcome: {
        setTitle(getTranslation('intents.welcome'));
        return <Welcome />;
      }
      case EIntentType.Fallback: {
        setTitle(getTranslation('intents.fallback'));
        return <Fallback />;
      }
      case EIntentType.Cancel: {
        setTitle(getTranslation('intents.cancel'));
        return <Cancel />;
      }
      case EIntentType.Others: {
        setTitle(getTranslation('intents.others'));
        return <Others />;
      }
      case EIntentType.SaveTable: {
        setTitle('Salvar tabela');
        return <SaveTable />;
      }
      case EIntentType.OptionsBlock: {
        setTitle(getTranslation('intents.optionsBlock'));
        return <OptionsBlock />;
      }
      case EIntentType.SendMessage: {
        setTitle(getTranslation('intents.sendMessage'));
        return <MessageForUser />;
      }
      case EIntentType.SimpleQuestion: {
        setTitle(getTranslation('intents.simpleQuestion'));
        return <SimpleQuestion />;
      }
      case EIntentType.SendDocument: {
        setTitle(getTranslation('intents.sendDocument'));
        return <SendDocument />;
      }
      case EIntentType.Logic: {
        setTitle(getTranslation('intents.logic'));
        return <Logic />;
      }
      case EIntentType.NLU: {
        setTitle(getTranslation('intents.nlu'));
        return <NLU />;
      }
      case EIntentType.OpenHTML: {
        setTitle(getTranslation('intents.toHtml'));
        return <OpenHTML />;
      }
      case EIntentType.Carousel: {
        setTitle(getTranslation('intents.carousel'));
        return <NewCarousel />;
      }
      case EIntentType.Rules: {
        setTitle(getTranslation('intents.rulesBlock'));
        return <Rules />;
      }
      case EIntentType.ABTest: {
        setTitle(getTranslation('intents.abTest'));
        return <ABTest />;
      }
      case EIntentType.AI123: {
        setTitle('AI123');
        return <AI123 />;
			}
      case EIntentType.FlexBlock: {
        setTitle('Cria Flex');
        return <FlexBlock />;
      }
      case EIntentType.FlexBlockExit: {
        setTitle('Saída do FlexBlock');
        return <FlexBlockExit />;
      }
      case EIntentType.FlexBlockEntry: {
        setTitle('Entrada do FlexBlock');
        return <FlexBlockEntry />;
      }
      default: {
        setTitle(getTranslation('intents.initialFlow'));
        return <InitialFlow />;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataForm?.intentType, language]);

  function handleCloseAlert(event?: React.SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({
      type: 'updateForm',
      data: {
        toastNotification: {
          message: '',
          show: false,
          type: 'success',
        },
      },
    });
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <FormProvider>
      <Dialog
        maxWidth="lg"
        fullScreen
        open={state.openModalForms}
        TransitionComponent={Transition}
        PaperProps={{ style: { height: 'auto' } }}
      >
        <ContainerDialog colorheader={headerColor}>
          <AppBar>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          {form}
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={toastNotification.show}
            autoHideDuration={
              toastNotification.duration ? toastNotification.duration : 6000
            }
            onClose={handleCloseAlert}
          >
            <Alert onClose={handleCloseAlert} severity={toastNotification.type}>
              {toastNotification.message}
            </Alert>
          </Snackbar>
        </ContainerDialog>
      </Dialog>
    </FormProvider>
  );
}
