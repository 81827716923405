import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'contexts/Form/formContext';
import AddIcon from '@material-ui/icons/Add';
import * as S from './styles';
import { IItensMultEscolha } from './types';
import MttButton from 'components/Material/MttButton/MttButton';
import { Output } from 'models/DataFlow';
import CircularDeleteButton from 'components/CircularDeleteButton/index';
import useTranslator from 'utils/hooks/Translator';

export default function ItensMultiplaEscolha(props: IItensMultEscolha) {
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const { state, dispatch } = useForm();
  const { dataForm, formErrors } = state;
  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && !dataForm.outputs) {
      dataForm.outputs = [{ title: '' }];
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    } else if (dataForm && !!dataForm.outputs) {
      setCheckBox(dataForm.outputs.some((output) => output.isChip));
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeTitle = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!!dataForm && dataForm.outputs) {
      const output = dataForm.outputs[index];
      output.title = e.target.value;
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleRemoveChoise = (index: number) => {
    if (!!dataForm && dataForm.outputs) {
      const outputs = dataForm.outputs.filter((_, i) => i !== index);
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
            outputs,
          },
        },
      });
    }
  };

  const renderChoise = (choise: Output, index: number) => {
    return (
      <S.ContentTitlesGrid key={`OC${index}`}>
        <S.StyledMttTextField
          variant="outlined"
          fullWidth
          label={getTranslation('outputTitle')}
          inputProps={{ maxLength: 50 }}
          name="choice-input"
          type="text"
          value={choise.title}
          disabled={choise.title.toLowerCase() === 'outros'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeTitle(index, e);
          }}
          error={!!formErrors[`outputs[${index}].title`]}
          helperText={formErrors[`outputs[${index}].title`]}
        />
        <S.StyledDeleteIconGrid>
          {choise.title.toLowerCase() !== 'outros' && (
            <CircularDeleteButton
              handleDeleteItem={() => handleRemoveChoise(index)}
            />
          )}
        </S.StyledDeleteIconGrid>
      </S.ContentTitlesGrid>
    );
  };

  const handleAddChoise = () => {
    if (dataForm) {
      if (dataForm.outputs) {
        const other = dataForm.outputs.find(
          (o) => o.title.toLowerCase() === 'outros'
        );
        const newOutputs = dataForm.outputs.filter(
          (o) => o.title.toLowerCase() !== 'outros'
        );
        newOutputs.push({
          title: '',
        });
        if (other) {
          newOutputs.push(other);
        }
        dataForm.outputs = newOutputs;
      } else {
        dataForm.outputs = [{ title: '' }];
      }
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeButtons = () => {
    setCheckBox(!checkBox);
    if (!!dataForm && dataForm.outputs) {
      const outputs = dataForm.outputs;

      outputs.forEach((output) => {
        if (output.isChip) delete output.isChip;
        else output.isChip = true;
      });

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
            outputs,
          },
        },
      });
    }
  };

  return (
    <S.GridContainer>
      <S.StyledMttTypographyFrase variant="h6" color="primary">
        {getTranslation('multipleChoiceItems')}
      </S.StyledMttTypographyFrase>
      <S.ContentGrid>
        <S.ContainerButton>
          <MttButton
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddChoise}
          >
            {getTranslation('addNewPhrase')}
          </MttButton>
        </S.ContainerButton>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkBox}
              onChange={handleChangeButtons}
              color="primary"
            />
          }
          label={getTranslation('createButtonsAutomatically')}
        />
        {dataForm?.outputs?.map(renderChoise)}
      </S.ContentGrid>
    </S.GridContainer>
  );
}
