import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { CampaignElements } from 'contexts/FintalkCloud/types';
import { ModalTemplates } from './ModalTemplates';
import { ModalCampaigns } from './ModalCampaigns';
import { ModalActioning } from './ModalActioning';

export function CampaignModule() {
  const { campaignElementToOpen } = useFintalkCloud();

  const getCampaignElement: {
    [keyof in CampaignElements]: JSX.Element;
  } = {
    templates: <ModalTemplates />,
    campaigns: <ModalCampaigns />,
    actionings: <ModalActioning />,
  };

  return (
    <>{campaignElementToOpen && getCampaignElement[campaignElementToOpen]}</>
  );
}
