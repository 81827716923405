import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IGetConversationsRequest,
  IGetConversationsResponse,
  IGetOneConversationRequest,
  IGetOneConversationResponse,
  IGetOneCustomerRequest,
  IGetOneCustomerResponse,
  IGetPhrasesNotUnderstoodRequest,
  IGetPhrasesNotUnderstoodResponse,
  IGetPhrasesOfIntentRequest,
  IGetPhrasesOfIntentResponse,
  IGetConversationHistoryRequest,
  IGetConversationHistoryResponse,
} from './types';

export const GetConversations = async (
  request: IGetConversationsRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetConversationsResponse>> => {
  const action = `bots/${request.bot_name}/conversations`;
  return await json<IGetConversationsResponse>('GET', action, dispatch);
};

export const GetOneConversation = async (
  request: IGetOneConversationRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetOneConversationResponse>> => {
  const action = `bots/${request.bot_name}/conversations/${request.customer}`;
  return await json<IGetOneConversationResponse>('GET', action, dispatch);
};

export const GetOneCustomer = async (
  request: IGetOneCustomerRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetOneCustomerResponse>> => {
  const action = `bots/${request.bot_name}/customer/${request.customer}`;
  return await json<IGetOneCustomerResponse>('GET', action, dispatch);
};

export const GetPhrasesOfIntent = async (
  request: IGetPhrasesOfIntentRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `bots/${request.bot_name}/conversations/did-not-understood/${request.dateInterval}`;
  return await json<IGetPhrasesOfIntentResponse>('GET', action, dispatch);
};

export const GetPhrasesNotUnderstood = async (
  request: IGetPhrasesNotUnderstoodRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  let action = `bots/${request.bot_name}/conversations/did-not-understood?startTime=${request.startDate}&endTime=${request.endDate}`;
  if (request.nextToken) action += `&nextToken=${request.nextToken}`;
  return await json<IGetPhrasesNotUnderstoodResponse>('GET', action, dispatch);
};

export const GetConversationHistory = async (
  request: IGetConversationHistoryRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetConversationHistoryResponse>> => {
  let action = `bots/${request.bot_name}/conversations/conversation-history?target_date=${request.target_date}&customer=${request.customer}`;

  if (request.next_logs) action += `&next_logs=${request.next_logs}`;
  if (request.previous_logs)
    action += `&previous_logs=${request.previous_logs}`;

  return await json<IGetConversationHistoryResponse>('GET', action, dispatch);
};
