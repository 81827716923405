import React from 'react';

import {
  ClickAwayListener,
  createStyles,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@material-ui/core';

import IIconButtonWithMenuListProps, {
  IOptionMenuList,
} from '../IconButtonWithMenuList/types';
import { getIcon } from '../Icons';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
    },
    list: {
      backgroundColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 100,
    },
  })
);

const SpeedDialWithMenuList: React.FC<IIconButtonWithMenuListProps> = (
  props: IIconButtonWithMenuListProps
) => {
  const { iconName, options } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const classes = useStyles();

  function handleMenuListOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  function handleMenuListClose() {
    setAnchorEl(null);
  }

  function renderOptions(option: IOptionMenuList, index: number) {
    return (
      <MenuItem
        key={index}
        onClick={() => {
          if (
            typeof props?.referenceIndex === 'number'
            && typeof option?.actionToReference === 'function'
          )
            option?.actionToReference(props?.referenceIndex);

          else if (!!option?.action) option?.action();
        }}
        className={classes.list}
      >
        <Tooltip title={option.label} placement="left">
          <>
            <IconButton
              color="inherit"
              aria-owns={anchorEl ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleMenuListOpen}
            >
              {option.iconName && getIcon(option.iconName)}
            </IconButton>
            {option.label}
          </>
        </Tooltip>
      </MenuItem>
    );
  }

  return (
    <>
      <IconButton
        color="inherit"
        aria-owns={anchorEl ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleMenuListOpen}
      >
        {getIcon(iconName)}
      </IconButton>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom"
        style={{ zIndex: 999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'left bottom' }}>
            <Paper id="menu-list-grow" className={classes.root}>
              <ClickAwayListener onClickAway={handleMenuListClose}>
                <MenuList style={{ backgroundColor: 'white', padding: 0 }}>
                  {options.map(renderOptions)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SpeedDialWithMenuList;
