import styled from 'styled-components';

const styles = {
  Container: styled.div`
    align-items: center;
    background: #b6bad9;
    border-radius: 0.8rem;
    display: flex;
    padding: 1rem;
    margin: 1rem 0 1rem 0;
    width: 50vw;
  `,
  Btn: styled.button`
    background: #0e103b;
    border: none;
    border-radius: 3rem;
    color: white;
    padding: 0.5rem 1rem;
    margin: 1rem 1rem;
    height: 3rem;
    width: 40vw;
  `,
}

export default styles;
