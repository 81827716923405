import React from 'react';

import * as S from './style';
import { SimilarityComponent } from './SimilarityComponent';
import { Container } from '@material-ui/core';
import { TitleTypography } from '../Material/MessageForUser/styles';

export default function MultipleTags({
  hideTitle = false,
  inColumns = false,
  noPadding = false,
}: {
  hideTitle?: boolean;
  inColumns?: boolean;
  noPadding?: boolean;
}) {
  return (
    <Container style={{ padding: noPadding ? 0 : '0px 24px' }}>
      {!hideTitle && (
        <TitleTypography variant="h6" color="primary">
          Tags
        </TitleTypography>
      )}
      <S.HeaderContent>
        <SimilarityComponent inColumns={inColumns} />
      </S.HeaderContent>
    </Container>
  );
}
