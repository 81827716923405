import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IGetEntitiesRequest,
  IGetEntitiesResponse,
  ISaveEntitiesResponse,
  ISaveEntitiestRequest,
} from './types';

export const GetEntities = async (
  request: IGetEntitiesRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetEntitiesResponse>> => {
  const action = `bots/${request.bot_name}/entities`;
  return await json<IGetEntitiesResponse>('GET', action, dispatch);
};

export const SaveEntities = async (
  request: ISaveEntitiestRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ISaveEntitiesResponse>> => {
  const action = `bots/${request.bot_name}/entities`;
  return await json<ISaveEntitiesResponse>('POST', action, dispatch, request);
};
