import { getNodePosition } from 'components/Flow/utils/NodeContainer';
import { validateBlockedIntents } from 'enums/EIntentType';
import { DataFlow } from 'models/DataFlow';
import { DrawflowModel } from 'models/DrawflowModel';

export function saveBackupBotGroup({
  dataflow,
  group,
  botName,
}: {
  dataflow: any;
  group: string;
  botName: string;
}) {
  const lastSave = JSON.stringify(dataflow);

  localStorage.setItem('lastSave', lastSave);
  localStorage.setItem('lastSave_group', group);
  localStorage.setItem('lastSave_bot', botName);
}

export function restoreBackupBotGroup({
  toastNotification,
  state,
  mountNodeContent,
  errors,
}: {
  toastNotification: any;
  state: any;
  mountNodeContent: any;
  errors: {
    onlyValidForPrincipal: string;
    invalidFileForPrincipal: string;
    invalidFileForGroup: string;
  };
}) {
  const botName = state.botName;
  const group = state.idGroup;
  const lastSaveGroup = localStorage.getItem('lastSave_group');
  const lastSave = localStorage.getItem('lastSave');
  const lastSaveBot = localStorage.getItem('lastSave_bot');

  if (group === lastSaveGroup && botName === lastSaveBot && !!lastSave) {
    toastNotification(
      'warning',
      'Falha ao excluir o bloco, tente recriar as conexões, remover o bloco pai ou algum elemento duplicado.'
    );

    const drawflow = JSON.parse(lastSave);

    if (drawflow.drawflow) {
      if (drawflow.drawflow.Home) {
        const dataFlow: DataFlow = drawflow.drawflow.Home.data;

        const keys = Object.keys(dataFlow);
        let hasInitialBlocks = false;
        const isPrincipalGroup = group === 'principal';

        try {
          for (const key of keys) {
            const { data } = dataFlow[Number(key)];

            if (isPrincipalGroup) {
              if (validateBlockedIntents(data.intentType)) {
                hasInitialBlocks = true;
                break;
              }
            } else if (validateBlockedIntents(data.intentType)) {
              throw new Error(errors.onlyValidForPrincipal);
            }

            dataFlow[Number(key)].id = Number(key);

            const sPayload = data.dataBlockly?.payload as string;
            if (sPayload) {
              const newPayload = sPayload.replaceAll(
                `${data.groupId}_`, //nome atual do grupo
                `${group}_` //novo nome do Grupo
              );

              if (data.dataBlockly?.payload)
                data.dataBlockly.payload = newPayload;
            }
          }

          if (isPrincipalGroup && !hasInitialBlocks) {
            throw new Error(errors.invalidFileForPrincipal);
          }
        } catch (error: any) {
          toastNotification('error', error.message);
          return;
        }

        const drawflowModel = new DrawflowModel(dataFlow, group || '');

        drawflow.drawflow.Home.data = drawflowModel.getDrawflow();
        state.editorFlow?.import(drawflow);

        mountNodeContent(dataFlow);

        const block = state.editorFlow?.drawflow.drawflow.Home.data;
        const blocksKeys = Object.keys(block);
        const positionsX = blocksKeys.map((key) => block[Number(key)].pos_x);

        const biggestPos_x = Math.min(...positionsX);

        const minBlock = blocksKeys.find(
          (key) => block[Number(key)].pos_x === biggestPos_x
        );

        const { x, y } = getNodePosition(minBlock as unknown as number);

        const newX = x / 2 + x * 0.1;
        const newY = y / 2 + y * 0.1;

        if (state.editorFlow) {
          state.editorFlow.canvas_x = -newX;
          state.editorFlow.canvas_y = -newY;
          if (state.editorFlow.precanvas) {
            state.editorFlow.precanvas.style.transform = `translate(${state.editorFlow.canvas_x}px, 
                  ${state.editorFlow.canvas_y}px) 
                  scale(${state.editorFlow.zoom}) `;
          }
        }
      } else {
        toastNotification('error', errors.invalidFileForGroup);
      }
    } else {
      toastNotification('error', errors.invalidFileForGroup);
    }
  }
}
