import * as Blockly from 'blockly/core';

const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

Blockly.Blocks['conditionchooser'] = {
  init: function () {
    let fields = [];
    const appendDummyInput = this.appendDummyInput('listInput');

    letters.forEach((letter) => {
      fields.push([letter, 'ITEM_' + letter]);
    });

    appendDummyInput.appendField(
      new Blockly.FieldDropdown(fields),
      'chooseColumnC'
    );

    this.appendDummyInput('listOrder')
      .appendField(' ')
      .appendField(
        new Blockly.FieldDropdown([
          ['\u2009', 'BLANK'],
          ['ASC', 'ASC'],
          ['DESC', 'DESC'],
        ]),
        'orderC'
      );

    this.setInputsInline(true);
    this.setOutput(true, 'CONDITIONCHOOSER');
    this.setColour('#f1bf06');
    this.setTooltip('Selecione os atributos da pesquisa.');
    this.setHelpUrl('');

    this.setOnChange(function (changeEvent) {
      var parent = this.getSurroundParent();

      if (
        parent != null &&
        parent.toString().includes('ORDER BY') &&
        this.getField('orderC') == null
      ) {
        this.appendDummyInput('listOrder')
          .appendField(' ')
          .appendField(
            new Blockly.FieldDropdown([
              ['\u2009', 'BLANK'],
              ['ASC', 'ASC'],
              ['DESC', 'DESC'],
            ]),
            'orderC'
          );
      } else if (
        (parent == null || !parent.toString().includes('ORDER BY')) &&
        this.getField('orderC') != null
      ) {
        this.removeInput('listOrder');
      }
    });

    const googleSheetId = localStorage.getItem('googleSheetId') ?? '';

    const response = fetch(
      `${process.env.REACT_APP_URL}payload-backend/columns/${googleSheetId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    );

    response
      .then((data) => data.json())
      .then((json) => {
        const correctColumn = this.getFieldValue('chooseColumnC');
        const fields = [];

        if (!!json.result && Array.isArray(json.result)) {
          json.result.forEach((item) => {
            fields.push([item.value, 'ITEM_' + item.letter]);
          });
        }

        if (!!correctColumn) {
          appendDummyInput.removeField('chooseColumnC');
          appendDummyInput.appendField(
            new Blockly.FieldDropdown(fields),
            'chooseColumnC'
          );

          this.setFieldValue(correctColumn, 'chooseColumnC');
        }
      })
      .catch((err) => {
        console.log('err', err.message);
      });
  },
};

Blockly.JavaScript['conditionchooser'] = function (block) {
  let chosenColumnC = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('chooseColumnC')
  );

  let chosenOrderC = '';

  if (this.getInput('listOrder')) {
    chosenOrderC = Blockly.JavaScript.variableDB_.getName(
      block.getFieldValue('orderC')
    );
    if (chosenOrderC === 'BLANK') {
      chosenOrderC = '';
    }
  }

  if (chosenColumnC === 'all') {
    chosenColumnC = '*';
  }
  return chosenColumnC + ' ' + chosenOrderC;
};
