import { TextField } from '@material-ui/core';
import { Output } from 'models/DataFlow';
import useTranslator from 'utils/hooks/Translator';
import * as S from './styles';

export function FlexBlockContentOutputs({
  index,
  output,
}: {
  index: number;
  output: Output;
}) {
  const { getTranslation } = useTranslator();

  return (
    <S.OutputContent key={index}>
      <TextField
        disabled
        variant="outlined"
        fullWidth
        value={output.title}
        label={getTranslation('outputTitle')}
      />
    </S.OutputContent>
  );
}
