import React from 'react';

import Typography from '@material-ui/core/Typography/Typography';

import { IMttTypographyProps } from './types';

const MttTypography: React.FC<IMttTypographyProps> = (props: IMttTypographyProps) => {
  const { children } = props;
  return (
    <Typography {...props} >
      {children}
    </Typography>
  );
};

export default MttTypography;