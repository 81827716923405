import React from 'react';

import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as S from './styles';
import { useEntity } from 'contexts/Entity/entityContext';
import { importEntity, UploadEntityToastMessages } from 'utils/Entity';
import { useFlow } from 'contexts/Flow/flowContext';
import useTranslator from 'utils/hooks/Translator';
import { useApp } from 'contexts/App/appContext';

const ModalVariableAlreadyExists: React.FC = () => {
  const { state, dispatch } = useEntity();
  const { toastNotification, state: flowState } = useFlow();
  const { botName } = flowState;
  const { dispatch: dispatchApp } = useApp();

  const { getTranslation } = useTranslator();

  const toastMessages: UploadEntityToastMessages = {
    addSuccess: getTranslation('toast.success.varAdded'),
    overwriteSuccess: getTranslation('toast.success.varOverwritten'),
    invalidFormat: getTranslation('toast.error.invalidFileForEntities'),
    addError: getTranslation('toast.error.varAdded'),
  };

  if (!state.openModalEntityExists) return <></>;

  function handleClose() {
    dispatch({ type: 'closeModalEntityExists' });
  }

  function handleOverwrite() {
    importEntity(
      state,
      dispatch,
      toastNotification,
      toastMessages,
      botName || '',
      dispatchApp,
      undefined,
      true
    );
    handleClose();
  }

  return (
    <Dialog open={state.openModalEntityExists} maxWidth="xs" fullWidth>
      <S.ContainerDialog>
        <AppBar>
          <Toolbar>
            <Typography variant="h6">{getTranslation('warning')}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </S.ContainerDialog>
      <S.Content>
        <S.Message>
          <Typography variant="body1">
            {getTranslation('modal.overwriteVariable.message')}
          </Typography>
        </S.Message>
        <S.FooterButtons>
          <Button onClick={handleClose}>{getTranslation('cancel')}</Button>
          <Button onClick={handleOverwrite}>
            {getTranslation('overwrite')}
          </Button>
        </S.FooterButtons>
      </S.Content>
    </Dialog>
  );
};

export default ModalVariableAlreadyExists;
