import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IGetKeysResponse,
  IUpdateKeysRequest,
  IUpdateKeysResponse,
} from './types';

export const GetKeys = async (
  bot_name: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetKeysResponse>> => {
  const action = `bots/${bot_name}/secrets`;
  return json<IGetKeysResponse>('GET', action, dispatch);
};

export const UpdateKeys = async (
  request: IUpdateKeysRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IUpdateKeysResponse>> => {
  const action = `bots/${request.botName}/secrets`;
  return json<IUpdateKeysResponse>('PATCH', action, dispatch, request);
};
