import jwtDecode from 'jwt-decode';

type TokenHandler = {
  getToken: () => string | null;
  setToken: (token: string) => void;
  removeToken: () => void;
  getDecodedToken: () => Decoded | undefined;
};

type Decoded = {
  sub: string;
  email: string;
  iat: number;
  exp: number;
};

export default function useTokenHandler(): TokenHandler {
  function getToken() {
    return localStorage.getItem('token');
  }

  function setToken(token: string) {
    localStorage.setItem('token', token);
  }

  function removeToken() {
    localStorage.removeItem('token');
  }

  function getDecodedToken(): Decoded | undefined {
    const token = getToken() ?? '';
    if (!token) return;
    const decoded: any = jwtDecode(token);

    if (!!decoded) {
      return decoded;
    }

    return undefined;
  }

  return {
    getToken,
    setToken,
    removeToken,
    getDecodedToken,
  };
}
