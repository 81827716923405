export interface ITemplate {
  _id?: string;
  bot_name: string;
  name: string;
  channel: ITemplateChannels;
  status: ITemplateStatus;
  creation_date: Date;
  description: string;
  language: ITemplateLanguages;

  payload_message: IPayloadMessage;
}

export interface IPayloadMessage {
  id?: string;
  bot: IPayloadBot;
  template: IPayloadTemplate | null;
  text?: string;
}

interface IPayloadBot {
  bot_name: string;
  group: string;
  block: string;
}

export interface IPayloadTemplate {
	id?: string;
  name: string;
  components: ITemplateComponents[];
  language?: ITemplateLanguages;
  category?: ITemplateCategories;
  example?: string;
  examples?: KeyValuePair[];
  broker?: ITemplateBrokers;
}

export interface KeyValuePair {
  [key: string]: string;
}

type TemplateComponentTypes =
  | 'BODY'
  | 'HEADER'
  | 'FOOTER'
  | 'BUTTONS'
  | 'IMAGE'
  | 'MEDIA_IMAGE';

export type TemplateComponentFormat =
  | 'NONE'
  | 'TEXT'
  | 'IMAGE'
  | 'DOCUMENT'
  | 'VIDEO';

export interface ITemplateComponents {
  type: TemplateComponentTypes;
  text?: string;
  format?: TemplateComponentFormat;
  example?: any;
  buttons?: ITemplateButton[];
}

export interface IZenviaTemplateButton {
  items: [
    {
      payload: ITemplateButtonTypes;
      text: string | null;
      type: string | null;
    }
  ];
}

export type ITemplateButtonTypes =
  | 'NONE'
  | 'PHONE_NUMBER'
  | 'URL'
  | 'QUICK_REPLY';

export type ITemplateButtonFields = keyof ITemplateButton;
export interface ITemplateButton {
  type: ITemplateButtonTypes;
  text: string | null;
  url: string | null;
  phone_number: string | null;
}

export type ITemplateChannels =
  | 'whatsapp'
  | 'email'
  | 'sms'
  | 'falazap'
  | 'ura';

export type ITemplateStatus =
  | 'approved'
  | 'rejected'
  | 'pending'
  | 'in_appeal'
  | 'pending_deletion'
  | 'deleted'
  | 'disabled'
  | 'paused'
  | 'limit_exceeded';

export type ITemplateLanguages = 'es_US' | 'pt_BR' | 'es';

export type ITemplateCategories = 'MARKETING' | 'OTP' | 'UTILITY';

export type ITemplateBrokers =
  | 'InfoBip'
  | 'Blip'
  | 'Twillio'
  | 'Meta'
  | 'Interaxa'
  | 'Zenvia'
  | ''

export type ITemplateData = {
  bot: IPayloadBot;
  header?: ITemplateCampaignHeader;
  broker: ITemplateBrokers;
}

export type ITemplateCampaignHeader = {
  type?: TemplateComponentTypes;
  format?: TemplateComponentFormat;
  text?: string;
}

export interface IGetTemplatesRequest extends Pick<ITemplate, 'bot_name'> {}

export interface IGetTemplatesForCampaignRequest {
  bot_name: string;
  channel: ITemplateChannels | '';
  broker?: ITemplateBrokers;
}

export interface IGetTemplatesResponse {
  data: ITemplate[];
}

export interface IGetOneTemplateRequest
  extends Pick<ITemplate, '_id' | 'bot_name'> {}

export interface IGetOneTemplateResponse {
  data: ITemplate;
}

export interface ICreateTemplateRequest extends ITemplate {
  already_in_meta?: boolean;
}

export interface ICreateTemplateResponse {
  data: ITemplate;
}

export interface IUpdateTemplateRequest extends ITemplate {}

export interface IUpdateTemplateResponse {
  data: ITemplate;
}

export interface IDeleteTemplateRequest
  extends Pick<ITemplate, 'bot_name' | '_id'> {}

export interface IGetTemplateOnDatabaseRequest
  extends Pick<ITemplate, 'bot_name' | 'name'> {}

export interface IGetTemplateOnMetaRequest
  extends Pick<ITemplate, 'bot_name' | 'name'> {}
export interface IGetTemplateOnMetaResponse {
  data: TemplateOnMeta;
}

export interface IGetTemplateOnZenviaRequest
  extends Pick<ITemplate, 'bot_name' | 'name'> {}
export interface IGetTemplateOnZenviaResponse {
  data: TemplateOnZenvia;
}

interface TemplateOnMeta {
  category: ITemplateCategories;
  components: ITemplateComponents[];
  text: string;
  type: TemplateComponentTypes;
  id: string;
  language: ITemplateLanguages;
  name: string;
  status: string;
  examples?: KeyValuePair[];
}

interface TemplateOnZenvia {
  id: string;
  name: string;
  locale: ITemplateLanguages;
  channel: ITemplateChannels;
  category: ITemplateCategories;
  components: Record<string, ITemplateComponents>;
  text: string;
  examples: {
    imageUrl?: string;
  };
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface SearchTemplates {
  name: string;
  channel: ITemplateChannels | '';
  broker: ITemplateBrokers | '';
}

export const emptyTemplate: ITemplate = {
  bot_name: '',
  payload_message: {
    bot: {
      bot_name: '',
      group: '',
      block: '',
    },
    template: {
			id: '',
      name: '',
      category: 'MARKETING',
      components: [],
      example: '',
      language: 'pt_BR',
      broker: 'Meta',
    },
  },
  name: '',
  channel: 'falazap',
  status: 'pending',
  creation_date: new Date(),
  description: '',
  language: 'pt_BR',
};
