import {
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useForm } from 'contexts/Form/formContext';
import useTranslator from 'utils/hooks/Translator';
import { IUserSentence, IUserSentenceParameter } from '../types';

type Props = {
  sentence: IUserSentence;
  sentenceIndex: number;
  removeParameterFromIndex: (sentence: string, index: number) => void;
};

export function SentenceParameters({
  sentence,
  sentenceIndex,
  removeParameterFromIndex,
}: Props) {
  const { state } = useForm();
  const { dataForm } = state;
	const {getTranslation} = useTranslator();

  function handleRemove(parameter: IUserSentenceParameter) {
    const { entity, name, resolvedValue } = parameter;
    const regexpPhrase = `[${resolvedValue}](${name}:${entity})`;
    removeParameterFromIndex(
      sentence.phrase.replace(regexpPhrase, resolvedValue),
      sentenceIndex
    );
  }

  function getVariableIndex(vartype: string) {
    let index = -1;

    if (!!dataForm && dataForm?.inputs) {
      index = dataForm.inputs.variables.findIndex(
        (variable) => variable.vartype === vartype
      );
    }

    return index;
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{getTranslation('parameterName')}</TableCell>
            <TableCell>{getTranslation('menu.variables')}</TableCell>
            <TableCell>{getTranslation('resolvedValue')}</TableCell>
          </TableRow>
        </TableHead>
        {sentence.parameters.map((parameter: any) => (
          <TableRow>
            <TableCell>{parameter.name}</TableCell>
            <TableCell className={`color${getVariableIndex(parameter.entity)}`}>
              {parameter.entity.replace('@', '')}
            </TableCell>
            <TableCell>{parameter.resolvedValue}</TableCell>
            <TableCell padding="none">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleRemove(parameter);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
}
