/* eslint-disable */
import * as Blockly from 'blockly/core';
import { fix_vars_declarations } from 'components/BlocklyConstructor/compiler';

export let WorkSpaceSavedXml;
export let WorkSpaceCodeJS;
export let Workspace;
export let APIKEY = "b1fcea2c-d3a6-11eb-b8bc-0242ac130003";

//******************************************************************** */

//   ESTES DADOS PRECISAM SER CONECTADOS AO CANVAS
// Precisa colocar o nome do Grupo Corrente - em que o bloco (intencao) esta
// Precisa disponiibilizar o drawflow para o sistema buscar variaveis VARS e criadas pelo usuario $
// Precisa colocar o numero do bloco atual - numero do array do drawflow

//let grupo_corrente = groupid; recebe do React JS

export let grupo_corrente = 'GRUPOCORRENTE'; // linha com nome do grupo corrente
export function setCurrentGroup(currentGroup) {
    grupo_corrente = currentGroup;
}

export let agente_atual = 'AGENTEATUAL'; // linha com nome do grupo corrente
export function setCurrentAgent(currentAgent) {
	agente_atual = currentAgent;
}
//  Abaixo o drawflow mocado

/************************************************************** */

//////// WAGNER DISPONIBILIZAR essas variabeis ARRAYS
//array_variables
//array_connectors

// o array_vars contem a lista de todas as variaveis VARS. encontradas no bot
// estou mockando para começar com state
//let array_vars = ['vars.nomefantasia', 'vars.nomeestabelecimento', 'vars.cnpj', 'vars.cpf', 'vars.nomedecisor', 'vars.nomeproprietario', 'vars.uuidcliente', 'vars.datadecadastro'];
export let array_vars = new Array();

// array_vars_blockly contém a lista de VARS. que o bloco vai usar para listar
// esse array será carregado com o conteudo do array_vars
export let array_vars_blockly = new Array();

export function getArrayVarsBlockly() {
    return !!array_vars_blockly.length ?
        array_vars_blockly : [
            ['vars.exemplo', 'vars.exemplo']
        ];
}

// variaveis criadas pelo usuario na tela de propriedade do bloco
export let array_variables = new Array();

// variaveis dos campos de banco de dados
export let array_fields = new Array();


// array_variables_blockly contém a lista de $variables que o bloco vai usar para listar
// esse array será carregado com o conteudo do array_variables
export let array_variables_blockly = new Array();

export function getArrayVariablesBlockly() {
    return !!array_variables_blockly.length ?
        array_variables_blockly : [
            ['$exemplo', '$exemplo']
        ];
}

export let array_customer_database_fields = new Array();

export function getArrayCustomerDataBase() {
    return !!array_customer_database_fields.length ?
        array_customer_database_fields : [
            ['nome', 'nome'],
            ['cpf', 'cpf'],
            ['cnpj', 'cnpj'],
            ['email', 'email'],
            ['celular', 'celular'],
        ];
}

export let array_connectors = new Array();

export let insertline;

// dado para testar o codigo - lixo - APAGAR !!!

//exemplod texto de codigo
let payload = 'linha 1\n2\n3\n4\n5\n6\nultima linha';
// linha exemplo para busca de variaveis vars.
let line = '0vars.Anome123=alskdj + vars.Aidade-1kjhask';

//*********************************************************** */

//*********************************************************** */
export function Mostra() {
    alert('Essa é uma função de teste\n\nBora lá !');
    //console.log(message); // Outputs a message to the Web Console
    console.log('imprime a mensagem debug/n'); // Outputs a message to the Web Console
    console.log(
        '%c My message here',
        'background: blue; color: white; padding-left:10px;'
    );
    var toolbox = Blockly.getMainWorkspace().getToolbox();
    console.log(toolbox.getToolboxItems()[0]);
}
export function myUpdateFunction(event) {
    var code = Blockly.JavaScript.workspaceToCode(Workspace);
    document.getElementById('textarea').value = code;
    if (code) {
        Workspace.addChangeListener(myUpdateFunction);
    }
}

export function xmlToString(xmlData) {
    var xmlString;
    //IE
    if (window.ActiveXObject) {
        xmlString = xmlData.xml;
    }
    // code for Mozilla, Firefox, Opera, etc.
    else {
        xmlString = new XMLSerializer().serializeToString(xmlData);
    }
    return xmlString;
}

export function SaveWorkSpace() {
    let WorkSpaceText;

    WorkSpaceSavedXml = Blockly.Xml.workspaceToDom(Blockly.getMainWorkspace());
    WorkSpaceText = xmlToString(WorkSpaceSavedXml);
    //document.getElementById('textarea').value = WorkSpaceText;

    if (WorkSpaceText != null) {
        document.getElementById('output').innerHTML = WorkSpaceText;
    }
    alert(WorkSpaceText);
}

export function LoadWorkSpace() {
    let workspace = Blockly.getMainWorkspace();
    workspace.clear();
    if (WorkSpaceSavedXml) {
        Blockly.Xml.domToWorkspace(WorkSpaceSavedXml, workspace);
    }
}

export function ClearWorkSpace() {
    let workspace = Blockly.getMainWorkspace();
    workspace.clear();
}

export function ShowJS() { // mostra o codigo
    //Blockly.JavaScript.addReservedWords('code');
    //var code;
    //code = Blockly.JavaScript.workspaceToCode(Workspace);
    //var CodeText = xmlToString(code);
    //alert("Código Javascript\n\n" + Code);
    WorkSpaceCodeJS = Blockly.JavaScript.workspaceToCode(Workspace);
    WorkSpaceCodeJS = fix_vars_declarations(WorkSpaceCodeJS);
    //console.log(WorkSpaceCodeJS);
    // editor.setValue(WorkSpaceCodeJS);

    // alert("LEGO  >>  Código Javascript gerado:\n\n" + WorkSpaceCodeJS);
    return WorkSpaceCodeJS;
}

export function exit_logic() {
    let WorkSpaceText;
    let WorkSpaceCodeJS = Blockly.JavaScript.workspaceToCode(Workspace);
    let WorkSpaceSavedXml = Blockly.Xml.workspaceToDom(
        Blockly.getMainWorkspace()
    );
    WorkSpaceText = xmlToString(WorkSpaceSavedXml);
    // alert("Código JS:\n\n" + WorkSpaceCodeJS + "\nWorkSpace XML:\n\n" + WorkSpaceText);
    return { payload: WorkSpaceCodeJS, xml: WorkSpaceText };
}

export function show(ele) {
    // GET THE SELECTED VALUE FROM <select> ELEMENT AND SHOW IT.
    var msg = document.getElementById('var');
    msg.innerHTML =
        'Selected VARS: <b>' +
        ele.options[ele.selectedIndex].text +
        '</b> </br>' +
        'ID: <b>' +
        ele.value +
        '</b>';
}

export function add_vars_to_array_vars(newItem) {
    //array_vars
    // Verifica se ja existe o newItem em array_vars, se não existe adiciona
    var array = new Array();
    if (array_vars.indexOf(newItem) === -1) {
        // id === -1 o item nao existe então inclui senão nao faz nada
        array_vars.push(newItem);
        array = array_vars.sort((a, b) => a.localeCompare(b));
        array_vars = array;
    }
}

export function add_vars_to_array_variables(newItem) {
    //array_variables
    // Verifica se ja existe o newItem em array_variables, se não existe adiciona
    var array = new Array();
    if (array_variables.indexOf(newItem) === -1) {
        // id === -1 o item nao existe então inclui senão nao faz nada
        array_variables.push(newItem);
        array = array_variables.sort((a, b) => a.localeCompare(b));
        array_variables = array;
    }
}

//  find_vars_in_string retorna um ARRAY com as variaveis vars.AAANNN encontradas na linha"
export function find_vars_in_string(line) {
    const a_vars = new Array();

    //line = line.toLowerCase();
    var pointer, pos, char, varbegin, varend;
    let regx = new RegExp(/^[0-9A-Za-z]{1}$/g);
    var letterNumber = /^[0-9a-zA-Z]+$/;
    var parsestring = line; // parsestring contem a linha a ser analisada e consumida
    //console.log(line)
    while (true) {
        pos = parsestring.search('vars.'); // aponta para o inicio de vars.
        if (pos == -1) {
            //se nao encoutrou o string vars. aborta o for
            break; // não tem mais "vars." no string
        }
        varbegin = pos; // achou o string vars. na posicao varbegin
        pos += 5; // pula o "vars." ou seja, move o cursor pos por 5 posicoes
        //alert(parsestring + "\n" + parsestring.substring(0, pos) + "<\npos = " + pos);

        //varre letra a letra até achar um caracter diferente de letra ou numero
        for (pos = pos; pos < parsestring.length; pos++) {
            char = parsestring.charAt(pos);
            if (char.match(letterNumber)) {
                // Podia usar regx.test(char))
                //se le letra ou numero continua movendo o cursor para o proximao caracter
            } else {
                //console.log("nao é letra ou numero");
                varend = pos;
                // parsestring.substring(varbegin, varend) contem uma variavel vars. v';a'lida
                a_vars.push(parsestring.substring(varbegin, varend));
                // alert(a_vars); vai mostrando cada variavel adicionada ao array temporário a_vars
                break;
            }
        }

        // limpa a parte anterior ao VARS e continua procurando outra variavel
        parsestring = parsestring.substring(pos, parsestring.length);
    }
    return a_vars; // retorna um ARRAY com as variaveis vars.AAANNN encontradas na linha"
}

// testar parse_line(payload)

export function parse_line(texto) {
    // varre linha a linha de um texto
    // split the contents by new line\
    //texto = payload;
    const lines = texto.split(/\r?\n/);

    // print all lines
    lines.forEach((line) => {
        // aqui usa a linha line
        //alert(line);s
        let a = find_vars_in_string(line); // a = array com as vars. encontradas
        if (a.length > 0) {
            a.forEach(function(item, index) {
                // varre o array de vars. encontradas e incluir no array_vars que contem todas as vars do bot
                //alert(item, index)
                add_vars_to_array_vars(item); // adicionao ao repositoriod e todas as vars.
            });
        }
        //  VFALTA ADICIONAR VARS no ARRAY de VARS
    });
    //alert("variaveis encontradas:\n" + array_vars);
}

export function parse_drawflow_dataBlockly(drawflow) {
    //parse nested json
    //

    const keys = Object.keys(drawflow['drawflow']['drawflow']['Home']['data']);
    let n = keys.length;
    var payload, dataBlockly, data;
    //alert("Número de Blocos:" + n); a serem varridos
    if (n == 0) {
        return;
    }
    for (let i = 0; i < n; i++) {
        data = drawflow['drawflow']['drawflow']['Home']['data'][keys[i]]['data'];
        //get_intention_variables(i);
        //alert("bloco " + i);
        if (data['dataBlockly']) {
            //alert(i + ") existe entrada dataBlockly" + data["dataBlockly"]);
            if (data['dataBlockly']['payload']) {
                // existe payload
                payload = data['dataBlockly']['payload'];
                //alert(i + ") payload\n" + payload); // existe payload
                parse_line(payload); // varre linha a alinha para pegar as variaveis vars.
            }
        } else {
            //alert(i + ") nao existe entrada "dataBlockly" >> data["dataBlockly"]);
        }
    }
}

export function get_intention_variables(bloconum) {
    let n = Object.keys(drawflow['drawflow']['drawflow']['Home']['data']).length;
    var dados, vname, vtype, vvalue, variables;
    insertline = '';
    //alert("get intention variable entrou");
    if (n < bloconum) {
        // não tem o bloco que esta sendo buscado
        return;
    }
    dados = drawflow['drawflow']['drawflow']['Home']['data'][bloconum]['data']; //["inputs"]["variables"];
    if ('inputs' in dados) {
        //alert("input existe !")
    } else {
        //alert("input nao existe");
        return;
    }
    dados =
        drawflow['drawflow']['drawflow']['Home']['data'][bloconum]['data'][
            'inputs'
        ];
    if ('variables' in dados) {
        // tem variables
        dados =
            drawflow['drawflow']['drawflow']['Home']['data'][bloconum]['data'][
                'inputs'
            ]['variables'];
        for (let i = 0; i < dados.length; i++) {
            //alert("pegando variavel #" + i);
            if (dados[i]['name'].length) {
                // existe variables
                //varre as variaveis
                vname = dados[i]['name'];
                vtype = dados[i]['vartype'];
                vvalue = dados[i]['value'];
                add_vars_to_array_variables('$' + vname);
                //alert("variavel" + i + "\nvname: " + vname + "\n " + vtype + "\n " + vvalue);
                // insert line já é o codigo que será inserido ANTES do codigo do payload no "compiled_payload"
                // insertline =insertline + 'vars.z_' + vname + ' = ($' + vname + ');\n';
            }
        }
    }

    return;
}
export function get_block_dolar_var(blockvariables) {
    var dados, vname, vtype, vvalue;
    insertline = '';

    dados = blockvariables;
    for (let i = 0; i < dados.length; i++) {
        //alert("pegando variavel #" + i);
        if (dados[i]['name'].length) {
            // existe variables
            //varre as variaveis
            vname = dados[i]['name'];
            vtype = dados[i]['vartype'];
            vvalue = dados[i]['value'];
            add_vars_to_array_variables('$' + vname);
            //console.log("variavel $" + i + "\nvname: " + vname + "\n " + vtype + "\n " + vvalue);
            // insert line já é o codigo que será inserido ANTES do codigo do payload no "compiled_payload"
            // insertline =insertline + 'vars.z_' + vname + ' = \'$' + vname + '\';\n';
        }
    }
    return insertline;
}





export function savefile() {
    filename = 'drawflow_lobo.json';
    var ret = 200; // status de erro ok
    try {
        // read contents of the file
        //const data = fs.readFileSync(filename, 'UTF-8');
        // split the contents by new line
        //const lines = data.split(/\r?\n/);

        // print all lines
        //lines.forEach((line) => {
        //    alert(line);
        //});
        let data = JSON.stringify(drawflow);
        //write to file
        fs.writeFileSync('filename', data, finished);
    } catch (err) {
        alert(err);
        ret = 501; //erro de abertura de arquivo
    }
    return ret;
}

export function populate_vars(drawflows) {
    array_vars = [];
    array_vars_blockly = [];
    for (let index = 0; index < drawflows.length; index++) {
        const drawflow = drawflows[index];
        parse_drawflow_dataBlockly({ drawflow });
    }
    // caso não tenha ainda nenhuma vars. criada no bot - stateless
    // colocar um exemplo para não quebrar o blockly
    if (array_vars.length == 0) {
        array_vars = ['vars.exemplo'];
    }
    var ele = document.getElementById('selectvars');
    let array = array_vars.sort((a, b) => a.localeCompare(b));
    var n = 1;
    array.forEach((element) => {
        //ele.innerHTML = ele.innerHTML +
        //    '<option value="' + String(n) + '"disabled>' +
        //    element + '"</option>';
        array_vars_blockly.push([element, element]);
        n = n + 1;
    });
}

export function populate_variables(bloconum) {
    get_intention_variables(bloconum);
    // caso não tenha ainda nenhuma variables criada no bot - stateless
    // colocar um exemplo para não quebrar o blockly
    if (array_variables.length == 0) {
        array_variables = ['$exemplo'];
    }
    let array = array_variables.sort((a, b) => a.localeCompare(b));
    var n = 1;
    array.forEach((element) => {
        array_variables_blockly.push([element, element]);
        n = n + 1;
    });
}

//---------------------------------Wagner----------------------
export function populate_variables_with_formdata(form_data) {
    get_intention_variables_with_form(form_data);
    // caso não tenha ainda nenhuma variables criada no bot - stateless
    // colocar um exemplo para não quebrar o blockly
    if (array_variables.length == 0) {
        array_variables = ['$exemplo'];
    }
    let array = array_variables.sort((a, b) => a.localeCompare(b));
    var n = 1;
    array.forEach((element) => {
        array_variables_blockly.push([element, element]);
        n = n + 1;
    });
}

export function populate_array_customer_database_fields() {
    // TODO LOBO COLOCAR OS CAMPOS DO array_customer_database_fields AQUI no array_fields

    if (array_fields.length == 0) {
        array_fields = ['nome'];
    }
    let array = array_fields.sort((a, b) => a.localeCompare(b));
    var n = 1;
    // coloca em ordem alfabética
    array.forEach((element) => {
        array_customer_database_fields.push([element, element]);
        n = n + 1;
    });
}

export function get_intention_variables_with_form(form_data) {
    array_variables_blockly = [];
    array_variables = [];
    insertline = '';
    var dados, vname, vtype, vvalue, variables;
    dados = form_data;
    if (dados.inputs) {
        //alert("input existe !")
    } else {
        //alert("input nao existe");
        return;
    }
    dados = dados['inputs'];
    if ('variables' in dados) {
        // tem variables
        dados = dados['variables'];
        if (!dados) return;
        for (let i = 0; i < dados.length; i++) {
            //alert("pegando variavel #" + i);
            if (dados[i]['name'].length) {
                // existe variables
                //varre as variaveis
                vname = dados[i]['name'];
                vtype = dados[i]['vartype'];
                vvalue = dados[i]['value'];
                add_vars_to_array_variables('$' + vname);
                // insert line já é o codigo que será inserido ANTES do codigo do payload no "compiled_payload"
                //insertline = insertline + 'vars.z_' + vname + ' = ($' + vname + ');\n';
                //alert("variavel" + i + "\nvname: " + vname + "\n " + vtype + "\n " + vvalue);
            }
        }
    }

    return;
}