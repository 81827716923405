import * as yup from 'yup';
import { slugify } from '../utils/String';
import { DataForm, Variable } from './DataFlow';
import { IResultError } from './ResultError';

export interface InfoForm {
  name: string;
  description: string;
}

export interface VarForm {
  varType: string;
  description: string;
}

export const infoValidation = async (newGroup: DataForm, errorMsg: string) => {
  const nameResult = await nameValidator(newGroup.name, errorMsg);
  let varResult: IResultError<VarForm>;

  if (newGroup.inputs?.variables) {
    varResult = await varTypeValidation(newGroup.inputs?.variables);
  } else {
    varResult = nameResult;
  }

  const isValid = nameResult.isValid && varResult.isValid;
  let errors;

  if (nameResult.errors.name !== '') {
    errors = { ...nameResult.errors };
  } else {
    errors = { ...varResult.errors };
  }

  return { isValid, errors };
};

export const varTypeValidation = async (variables: Variable[] | undefined) => {
  let varType = '';
  const array: string[] = [];

  const schema = yup.object().shape({
    varType: yup.string().required('*Tipo de variável obrigatório.'),
  });

  variables?.forEach((element) => {
    if (element.name === '') {
      varType = 'check non required';
    } else {
      varType = element.vartype;
      array[0] = varType;
    }
  });

  if (array.length !== 0) {
    if (array[0] === '') {
      varType = '';
    }
  } else varType = 'check non required';

  const promiseResult = new Promise<IResultError<VarForm>>((resolve) => {
    schema
      .validate({ varType }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { varType: '' } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { varType: err.errors[0] } });
      });
  });
  return await promiseResult;
};

const nameValidator = async (name: string, errorMsg:string) => {
  const schema = yup.object().shape({
    name: yup.string().required(errorMsg),
  });

  const promiseResult = new Promise<IResultError<InfoForm>>((resolve) => {
    schema
      .validate({ name }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { name: '' } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { name: err.errors[0] } });
      });
  });
  return await promiseResult;
};

export const infoFormMask = {
  name: (value: string) => {
    return slugify(value.replace(/\s/, '')).substring(0, 20);
  },
  description: (value: string) => value,
};
