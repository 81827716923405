import React from 'react';
import { useForm } from 'contexts/Form/formContext';
import { SendUser } from 'models/DataFlow';
import useTranslator from 'utils/hooks/Translator';
import * as S from './styles';
import { IMessageForUserProps } from './types';

export default function MessageForUser(props: IMessageForUserProps) {
  const { hideUrl } = props;
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const { getTranslation } = useTranslator();

  const handleChangeInput = (
    key: keyof SendUser,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (dataForm) {
      const value = e.target.value;

      dataForm.sendUser = dataForm.sendUser
        ? dataForm.sendUser
        : { message: '', url: '' };

      dataForm.sendUser[key] = value;
      dispatch({
        type: 'updateForm',
        data: { dataForm: { ...dataForm } },
      });
    }
  };

  return (
    <S.Container>
      <S.TitleTypography variant="h6" color="primary">
        {getTranslation('info.messageForUser')}
      </S.TitleTypography>
      <S.HeaderContent>
        <S.HeaderOptions>
          <S.MessageInputContainer>
            <S.StyledMttTextField
              variant="outlined"
              label={getTranslation('message')}
              fullWidth
              value={dataForm?.sendUser?.message || ''}
              onChange={(e) => handleChangeInput('message', e)}
              rows={5}
              maxRows={5}
              multiline
            />
          </S.MessageInputContainer>
          {!hideUrl && (
            <S.StyledMttTextField
              variant="outlined"
              label={getTranslation('imageUrl')}
              maxRows={5}
              fullWidth
              value={dataForm?.sendUser?.url || ''}
              onChange={(e) => handleChangeInput('url', e)}
            />
          )}
        </S.HeaderOptions>
      </S.HeaderContent>
    </S.Container>
  );
}
