import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';


export const GrupHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const FloatingIconButton = styled(IconButton)`
    && {
    margin: 0;
    padding: 0;
    display: inline-flex;
  }
`;

export const TextContainer = styled.div`
  height: 64px;
  margin-right: 10px;
  width: 100%;
`;

export const AutoCompleteWrap = styled.div`
  width: 100%;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    height: auto;
  }

  button {
    width: auto;
    height: auto;
  }
`;
export const TableContainer = styled.div`
width: 100%;
max-height: 58vh;
overflow: auto;
`;
