import styled from "styled-components";
import MttGrid from "components/Material/MttGrid/MttGrid";
import MttTypography from "components/Material/MttTypography/MttTypography";

export const Container = styled(MttGrid)`
  width: calc(100% - 84px);
  margin: 16px 42px 16px;
`;

export const StyledMttTypography = styled(MttTypography)``;

export const Content = styled(MttGrid)`
  width: calc(100% - 32px);
  margin-top: 8px;

  padding: 16px;
  background-color: #f2f2f2;
  border-radius: 4px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  .MuiInputBase-input {
    background-color: #ffffff;

    &:focus {
      background-color: #ffffff;
    }
  }
`;
