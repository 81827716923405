import React from "react";
import { ICircularDeleteButton } from "./types";
import DeleteIcon from "@material-ui/icons/Delete";
import { StyledDeleteGrid } from "./styles";

export default function CircularDeleteButton(props: ICircularDeleteButton) {
  const { size, handleDeleteItem } = props;

  return(
    <StyledDeleteGrid size={size} onClick={handleDeleteItem}>
      <DeleteIcon />
    </StyledDeleteGrid>
  );
}