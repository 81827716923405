import React, { useCallback, useEffect } from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Info from 'components/inputs/Material/Info/index';
import MessageForUser from 'components/inputs/Material/MessageForUser';
import { useForm } from 'contexts/Form/formContext';

import { FormFieldsContainer } from '../Shared/styles';

export default function Others() {
  const { state, dispatch } = useForm();
  const { dataForm } = state;

  const load = useCallback(() => {
    if (dataForm) {
      if (!dataForm.inputs) {
        dataForm.inputs = {
          userSentences: [''],
          variables: [
            {
              name: '',
              vartype: '',
              questions: ['', '', ''],
              required: false,
              defaultValue: '',
              isList: false,
              value: '',
            },
          ],
        };
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
      if (!dataForm.attempts || !dataForm.attempts.length) {
        dataForm.attempts = [
          { msg: '', action: '' },
          { msg: '', action: '' },
          { msg: '', action: '' },
        ];
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
      if (!dataForm.attempts) {
        dataForm.attempts = [
          {
            msg: '',
            action: '',
          },
          {
            msg: '',
            action: '',
          },
          {
            msg: '',
            action: '',
          },
        ];
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      <FormFieldsContainer>
        <FormFieldsContainer>
          <Info fieldsLock={['name', 'description']} />
        </FormFieldsContainer>
        <MessageForUser />
      </FormFieldsContainer>
      <FooterButtons />
    </>
  );
}
