import { DataFlow, ItemDataFlow, MessageType, Output } from 'models/DataFlow';
import { IEntity } from 'models/Entity';

const payloadURL = process.env.REACT_APP_S3_URL || '';
const payloadApiKey = process.env.REACT_APP_S3_API_KEY || '';

const similarityAccuracyLimit = 0.3;
const similarityAPI =
  'https://api-hub.fintalk.io/nlu/similarity?apikey=b1fcea2c-d3a6-11eb-b8bc-0242ac130003';

let lastBlockId = 0;

export function QuestionBlockTreatment(
  block: ItemDataFlow,
  blocks: DataFlow,
  lastBlock: number
) {
  const questionOutputs = block.data.outputs || [];
  const firstPositionOutputQuestion = questionOutputs[0];

  const questions = block.data.inputs?.variables[0]?.questions;

  if (questions) {
    for (const question in questions) {
      block.data.inputs!.variables[0].questions[question] = questions[
        question
      ].replace(/'/g, "\\'");
    }
  }

  const destinationBlock = firstPositionOutputQuestion?.outputid
    ? blocks[Number(questionOutputs[0].outputid)]
    : ({ data: { name: '' } } as ItemDataFlow);

  const questionNextBlock = {
    id: lastBlock + 1,
    data: {
      dataBlockly: {
        compiledPayload: `event('${block.data.groupId}_${destinationBlock.data.name}');`,
        lastVersion: 0,
        payload: `${block.data.metadata?.jsCode || ''}`,
        xml: '',
      },
      intentType: 120,
      groupId: block.data.groupId,
      name: `${block.data.name}-question`,
      outputs: block.data.outputs,
    },
  } as ItemDataFlow;

  let fallBack: Output = { title: '' };
  if (block.data.outputs && block.data.outputs[1])
    fallBack = block.data.outputs[1];

  questionNextBlock.data.dataBlockly!.compiledPayload += getCompiledPayload(
    blocks,
    block,
    block,
    destinationBlock.id,
    fallBack.nameIntent || ''
  );

  lastBlockId = lastBlock + 1;
  blocks[lastBlock + 1] = questionNextBlock;

  const savedVarCode =
    block.data.varValue &&
    `${block.data.varValue} = '${block.data.inputs?.variables[0].value}'`;

  block.data.dataBlockly = {
    ...block.data.dataBlockly,
    payload: '',
    compiledPayload: `${savedVarCode};\n event('${block.data.groupId}_${questionNextBlock.data.name}')`,
    lastVersion: 0,
    xml: block.data.dataBlockly?.xml || '',
  };

  return lastBlockId;
}

export function ABTestTreatment(block: ItemDataFlow) {
  if (block.data.dataBlockly && !!block.data.outputs) {
    const outputs = block.data.outputs;
    const allValues = block.data.outputs.map((o) => o.value || '0');

    block.data.dataBlockly.payload = '';

    block.data.dataBlockly.compiledPayload = `
		var random = Math.random() * 100;
		`;

    let switchString = ``;
    let previousValue = 0;

    allValues?.forEach((value, index) => {
      if (outputs[index]) {
        const outputName = outputs[index].nameIntent;
        previousValue = previousValue + Number(value);

        switchString += `
					if (random < ${previousValue}){
						event('${block.data.groupId}_${outputName}');
				} else
				`;

        if (!outputs[index + 1])
          switchString += `
					{
						event('${block.data.groupId}_${block.data.name}');
					}`;
      }
    });

    block.data.dataBlockly.compiledPayload += switchString.replaceAll('\t', '');
  }
}

export function AiBlockTreatment(
  botName: string,
  blocks: DataFlow,
  block: ItemDataFlow,
  lastBlock: number,
  entities: IEntity[]
) {
  const groupName = block.data.groupId;
  const contentGroupId = block.data.ai123Info?.contentGroupId;

  const falaGptInputBlock = {
    id: lastBlock + 1,
    data: {
      dataBlockly: {
        compiledPayload: '',
        lastVersion: 0,
        payload: '',
        xml: '',
      },
      outputs: block.data.outputs,
      intentType: 4,
      groupId: groupName,
      name: `${block.data.name}-input`,
    },
  } as ItemDataFlow;

  blocks[lastBlock + 1] = falaGptInputBlock;

  if (block.data.outputs) {
    const ai123Output = block.data.outputs[0];
    const othersOutput = block.data.outputs[block.data.outputs.length - 1];

    let switchString = '';
    const synonymArray: any[] = [];

    block.data.outputs?.forEach((output) => {
      return synonymArray.push(output.userSentences?.filter((s) => s !== ''));
    });

    synonymArray.forEach((sArr) => {
      if (sArr) {
        sArr.forEach((s: string, index: number) => {
          if (s.substring(0, 1) === '@') {
            const foundEntity = entities.find((e) => `@${e.name}` === s);
            if (foundEntity) {
              foundEntity.values[0].synonyms.forEach((v) => {
                sArr.push(v);
              });
            }
            sArr.splice(index, 1);
          }
        });
      }
    });

    const arr = synonymArray.filter((arr) => {
      if (!!arr) return arr.length > 0;
      else return false;
    });

    let compiledPayload = `
      setLocalCapture(false);
      vars.userInput = \`$raw.resposta\`;
      vars.contagem = (vars.contagem || 0);
    `;

    if (arr.length > 0) {
      compiledPayload += `
        const resp = (
          await request(
            'POST',
            '${similarityAPI}', {
              "input": \`\${vars.userInput}\`,
              "list": ${JSON.stringify(arr)},
              "accuracy_limit": 0.5
            }
          )
        );
      `;

      if (block.data.outputs) {
        let evt = '';
        const outputs = block.data.outputs;
        const saveVarValueString = block.data.saveVarValue
          ? `${block.data.varValue} = resp.option_selected;`
          : '';

        const ifOptionSelectedStatement = saveVarValueString
          ? `if (resp.option_selected) { ${saveVarValueString} }`
          : '';

        switchString = `if (resp && resp.option_selected) {
          ${ifOptionSelectedStatement} 
          switch (resp.option_selected) {
        `;

        const similarityOutputs = outputs.slice(1, outputs.length);

        similarityOutputs.forEach((output) => {
          if (output.title !== '' && output.title !== 'Outros') {
            if (output.nameIntent !== '') {
              evt = `event('${block.data.groupId}_${output.nameIntent}');`;
            }
            switchString += `
              case '${output.title}':
                ${evt}
                break;
              `;
          }
          evt = '';
        });
        switchString += `
          default:
          ${getCountCondition(
            block.data.sendUser?.messageDidNotUnderstood || '',
            block.data.groupId,
            othersOutput.nameIntent || ''
          )}
          }
        } else {
          const resp = await send123fala('${contentGroupId}');
          if (resp) {
            event('${block.data.groupId}_${ai123Output.nameIntent}');
          } else {
            event('${block.data.groupId}_${othersOutput.nameIntent}');
          }
        }
        `;
      }
    } else {
      switchString = `
        const resp = await send123fala('${contentGroupId}');
        if (resp) {
          event('${block.data.groupId}_${ai123Output.nameIntent}');
        } else {
          event('${block.data.groupId}_${othersOutput.nameIntent}');
        }
      `;
    }

    compiledPayload += switchString;

    const chipsTitles = block.data.outputs
      ?.map((output) => (output.isChip ? output.title : ''))
      .filter((item) => item !== 'Outros' && item !== '');

    let chipsString = '[';

    chipsTitles?.forEach((chip, index) => {
      chipsString += `'${chip}'`;
      if (index < chipsTitles.length - 1) {
        chipsString += ',';
      }
    });

    chipsString += ']';

    const chipsEvent =
      chipsString !== '[]' ? `msg(${chipsString}, 'chips');\n` : '';

    block.data.dataBlockly = {
      lastVersion: 0,
      compiledPayload: `
        setLocalCapture(true, '${block.data.groupId}_${falaGptInputBlock.data.name}')
        ${chipsEvent}
      `,
      payload: '',
      xml: '',
    };

    falaGptInputBlock.data.dataBlockly = {
      lastVersion: 0,
      compiledPayload: compiledPayload.replaceAll('\t', ''),
      payload: '',
      xml: '',
    };
  }
  return lastBlock + 1;
}

export function OptionsBlockTreatment(
  blocks: DataFlow,
  block: ItemDataFlow,
  lastBlock: number,
  entities: IEntity[]
) {
  const optionsBlockInput = {
    id: lastBlock + 1,
    data: {
      dataBlockly: {
        compiledPayload: '',
        lastVersion: 0,
        payload: '',
        xml: '',
      },
      intentType: 4,
      outputs: [{ title: '', nameIntent: '' }],
      groupId: block.data.groupId,
      name: `${block.data.name}-input`,
    },
  } as ItemDataFlow;

  lastBlockId = lastBlock + 1;
  blocks[lastBlock + 1] = optionsBlockInput;

  if (block.data.outputs) {
    const othersOutput = block.data.outputs[block.data.outputs.length - 1];

    let switchString = '';
    const synonymArray: any[] = [];
    block.data.outputs?.forEach((output) => {
      return synonymArray.push(output.userSentences?.filter((s) => s !== ''));
    });

    synonymArray.forEach((sArr) => {
      if (sArr) {
        sArr.forEach((s: string, index: number) => {
          if (s.substring(0, 1) === '@') {
            const foundEntity = entities.find((e) => `@${e.name}` === s);
            if (foundEntity) {
              foundEntity.values[0].synonyms.forEach((v) => {
                sArr.push(v);
              });
            }
            sArr.splice(index, 1);
          }
        });
      }
    });

    const arr = synonymArray.filter((arr) => {
      if (!!arr) return arr.length > 0;
      else return false;
    });

    let compiledPayload = `
			setLocalCapture(false);
			var resp;
			vars.userInput = \`$raw.resposta\`;
			vars.contagem = (vars.contagem || 0);
			resp = (
					await request(
							'POST',
							'${similarityAPI}',{
                "input": \`\${vars_userInput}\`,
                "list": ${JSON.stringify(arr)},
                "accuracy_limit": ${similarityAccuracyLimit}
							}
					)
			);
	  `;

    if (block.data.outputs) {
      let evt = '';

      const outputs = block.data.outputs;
      const saveVarValueString = block.data.saveVarValue
        ? `${block.data.varValue} = resp.option_selected;`
        : '';

      const ifOptionSelectedStatement = saveVarValueString
        ? `if (resp.option_selected) { ${saveVarValueString} }`
        : '';

      switchString = `if (resp) {
					${ifOptionSelectedStatement} 
					switch (resp.option_selected) {
		`;

      outputs.forEach((output) => {
        if (output.title !== '' && output.title !== 'Outros') {
          if (output.nameIntent !== '') {
            evt = `event('${block.data.groupId}_${output.nameIntent}');`;
          }
          switchString += `
					case '${output.title}':
						${evt}
						break;
						`;
        }
        evt = '';
      });
      switchString += `
			default:
			${getCountCondition(
        block.data.sendUser?.messageDidNotUnderstood || '',
        block.data.groupId,
        othersOutput.nameIntent || ''
      )}
			}
		} else {
			msg("Encontrei um erro ao tentar processar sua mensagem, tente novamente em alguns instantes");
			event('${block.data.groupId}_${block.data.name}');
			}
		`;
    }

    compiledPayload += switchString;

    const chipsTitles = block.data.outputs
      ?.map((output) => (output.isChip ? output.title : ''))
      .filter((item) => item !== 'Outros' && item !== '');

    let chipsString = '[';

    chipsTitles?.forEach((chip, index) => {
      chipsString += `'${chip}'`;
      if (index < chipsTitles.length - 1) {
        chipsString += ',';
      }
    });

    chipsString += ']';

    const chipsEvent =
      chipsString !== '[]' ? `msg(${chipsString}, 'chips');\n` : '';

    if (block.data.sendUser && block.data.sendUser.message) {
      block.data.sendUser.message = block.data.sendUser.message.replace(
        /'/g,
        "\\'"
      );
    }

    block.data.dataBlockly = {
      lastVersion: 0,
      compiledPayload: `
				setLocalCapture(true, '${block.data.groupId}_${optionsBlockInput.data.name}')
				${chipsEvent}
			`,
      payload: '',
      xml: '',
    };

    optionsBlockInput.data.dataBlockly = {
      lastVersion: 0,
      compiledPayload: compiledPayload.replaceAll('\t', ''),
      payload: '',
      xml: '',
    };
  }
  return lastBlockId;
}

export function GetDocumentLogic(
  blocks: DataFlow,
  block: ItemDataFlow,
  lastBlock: number,
  botName: string
) {
  if (!block.data.outputs) return lastBlock;
  const fallbackId = Number(
    block.data.outputs[block.data.outputs.length - 1].outputid
  );
  const fallbackBlock = blocks[fallbackId] || null;
  const destinationId = Number(block.data.outputs[0].outputid);
  const destinationBlock = blocks[destinationId];

  const destinationBlockName = destinationBlock
    ? destinationBlock.data.name
    : '';

  const fallBackMessage = block.data.sendUser?.messageDidNotUnderstood || '';

  if (block.data.dataBlockly) {
    const inputBlock = {
      id: lastBlock + 1,
      data: {
        dataBlockly: {
          compiledPayload: '',
          lastVersion: 0,
          payload: '',
          xml: '',
        },
        inputs: { variables: [{ questions: [' '] }] },
        intentType: 3,
        groupId: block.data.groupId,
        name: `${block.data.name}-input`,
      },
    } as ItemDataFlow;

    blocks[lastBlock + 1] = inputBlock;
    lastBlockId = lastBlock + 1;

    block.data.dataBlockly = {
      lastVersion: 0,
      payload: '',
      xml: '',
      compiledPayload: `event('${inputBlock.data.groupId}_${inputBlock.data.name}');`,
    };

    if (inputBlock.data.inputs) {
      const blockVarValue = block.data.varValue || 'vars.url';
      // const fixedVarValue =
      // blockVarValue?.replaceAll('vars.', '').replaceAll('user.', '') || '';

      // inputBlock.data.inputs = {
      //   variables: [
      //     {
      //       name: `${fixedVarValue}`,
      //       questions: [' '],
      //       required: true,
      //       vartype: '@sys.any',
      //       defaultValue: '',
      //       isList: false,
      //       value: `$${fixedVarValue}`,
      //     },
      //   ],
      // };

      if (inputBlock.data.dataBlockly) {
        inputBlock.data.dataBlockly.compiledPayload = `
						var pic;
						var url;
						pic = user.input.match("https://");
						vars.contagem = vars.contagem || 0;
						if(pic){
							url = (await request('POST', '${payloadURL}', {
								"bot": '${botName}',
								"userId": user.id,
								"url": user.input
							}, {
								headers: {
									"X-API-Key": "${payloadApiKey}"
								}
							}
							));
							if (url) {
								vars.contagem = 0;
								${blockVarValue} = url.document;
								event('${block.data.groupId}_${destinationBlockName}');
							} else {
								vars.contagem = vars.contagem + 1;
								${getCountCondition(
          fallBackMessage,
          block.data.groupId,
          fallbackBlock?.data.name || ''
        )}
							}
						} else {
							vars.contagem = vars.contagem + 1;
							${getCountCondition(
          fallBackMessage,
          block.data.groupId,
          fallbackBlock?.data.name || ''
        )}
						}
					`;

        inputBlock.data.dataBlockly.compiledPayload =
          inputBlock.data.dataBlockly.compiledPayload.replaceAll('\t', '');
      }
    }
  }
  return lastBlockId;
}

export function SimpleCarouselTreatment(block: ItemDataFlow) {
  const items = block.data.carouselItems
    ?.map((output) => {
      return {
        title: output.title,
        description: output.description || ' ',
        price: Number(output.value),
        image: output.url,
      };
    })
    .filter((item) => item.title !== 'Outros');

  if (block.data.carouselItems) {
    const carouselOrList = block.data.listType === 1 ? 'carousel' : 'list';
    const stringifiedItems = JSON.stringify(items);

    block.data.dataBlockly!.payload = '';

    const event =
      block.data.outputs && block.data.outputs[0].nameIntent
        ? `event('${block.data.groupId}_${block.data.outputs[0].nameIntent}')`
        : '';

    if (block.data.dataBlockly)
      block.data.dataBlockly.compiledPayload = `
		items = ${stringifiedItems};
		if (user.appClient == "Web" || user.appClient == "Facebook") {
			msg(items, '${carouselOrList}');
		} else {
			let msgTxt = "";
			let chips = [];

				for (let i = 0; i < items.length; i++) {
					if (!!items[i]) {
						const price = !!items[i].price ? items[i].price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL'}) : " "\n;
						const description = (!!items[i].description && items[i].description.trim() !== "") ?  \`\n\`+ items[i].description + \`\n\` : \`\`;
						msgTxt += !!items[i].title ? '*' + (i+1) + '*. ' + items[i].title + \` \` + price + description + \`\n\` : \`''\`\n;
						if (items[i]) chips.push(items[i].title);    
					}
				}
			 msg(msgTxt, 'text');     
			 msg(chips, 'chips');
		}
	`;
    if (event) block.data.dataBlockly!.compiledPayload += event;
  }
}

export function CarouselTreatment(
  blocks: DataFlow,
  block: ItemDataFlow,
  lastBlock: number
) {
  const carouselBlock1 = {
    id: lastBlock + 1,
    data: {
      dataBlockly: {
        compiledPayload: '',
        lastVersion: 0,
        payload: '',
        xml: '',
      },
      intentType: 120,
      groupId: block.data.groupId,
      name: `${block.data.name}-logica`,
    },
  } as ItemDataFlow;

  blocks[lastBlock + 1] = carouselBlock1;

  const carouselBlock2 = {
    id: lastBlock + 2,
    data: {
      dataBlockly: {
        compiledPayload: '',
        lastVersion: 0,
        payload: '',
        xml: '',
      },
      intentType: 120,
      groupId: block.data.groupId,
      name: `${block.data.name}-input`,
    },
  } as ItemDataFlow;

  blocks[lastBlock + 2] = carouselBlock2;
  lastBlockId = lastBlock + 2;

  const items = block.data.outputs
    ?.map((output) => {
      return {
        title: output.title,
        description: output.description || ' ',
        price: Number(output.value),
        image: output.url,
      };
    })
    .filter((item) => item.title !== 'Outros');

  if (block.data.outputs) {
    const othersOutput = block.data.outputs[block.data.outputs.length - 1];
    const carouselOrList = block.data.listType === 1 ? 'carousel' : 'list';
    const stringifiedItems = JSON.stringify(items);

    if (carouselBlock1.data.dataBlockly)
      carouselBlock1.data.dataBlockly.compiledPayload = `
		items = ${stringifiedItems};
		if (user.appClient == "Web" || user.appClient == "Facebook") {
			msg(items, '${carouselOrList}');
		} else {
			let msgTxt = "";
			let chips = [];

				for (let i = 0; i < items.length; i++) {
					if (!!items[i]) {
						const price = !!items[i].price ? items[i].price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL'}) : " "\n;
						const description = (!!items[i].description && items[i].description.trim() !== "") ?  \`\n\`+ items[i].description + \`\n\` : \`\`;
						msgTxt += !!items[i].title ? '*' + (i+1) + '*. ' + items[i].title + \` \` + price + description + \`\n\` : \`''\`\n;
						if (items[i]) chips.push(items[i].title);    
					}
				}
			 msg(msgTxt, 'text');     
			 msg(chips, 'chips');
		}
		setLocalCapture(true, '${block.data.groupId}_${carouselBlock2.data.name}')
	`;

    const fallBackMessageCarousel =
      block.data.sendUser?.messageDidNotUnderstood || '';

    let switchString = '';
    const synonymArray: any[] = [];
    block.data.outputs?.forEach((output, index) => {
      const foundIndex = output.userSentences?.find(
        (s) => s === (index + 1).toString()
      );

      if (!foundIndex) output.userSentences?.push(`${index + 1}`);

      return synonymArray.push(output.userSentences?.filter((s) => s !== ''));
    });

    const arr = synonymArray.filter((arr) => {
      if (!!arr) return arr.length > 0;
      else return false;
    });

    let compiledPayload = `
			setLocalCapture(false)
			var resp;
			vars.userInput = \`$raw.resposta\`;
			vars.contagem = (vars.contagem || 0);
			resp = (
					await request(
							'POST',
							'${similarityAPI}',{
									"input": \`\${vars_userInput}\`,
									"list": ${JSON.stringify(arr)},
									"accuracy_limit": ${similarityAccuracyLimit}
							}
					)
				)
	`;
    if (block.data.outputs) {
      let evt = '';

      const outputs = block.data.outputs;
      const saveVarValueString = block.data.saveVarValue
        ? `${block.data.varValue} = resp.option_selected;`
        : '';

      const ifOptionSelectedStatement = saveVarValueString
        ? `if (resp.option_selected) { ${saveVarValueString} }`
        : '';

      switchString = `if (resp) {
					${ifOptionSelectedStatement} 
					switch (resp.option_selected) {
		`;

      outputs.forEach((output) => {
        if (output.title !== '' && output.title !== 'Outros') {
          if (output.nameIntent !== '') {
            evt = `event('${block.data.groupId}_${output.nameIntent}');`;
          }
          switchString += `
					case '${output.title}':
						${evt}
						break;
						`;
        }
        evt = '';
      });
      switchString += `
			default:
			${getCountCondition(
        fallBackMessageCarousel,
        block.data.groupId,
        othersOutput.nameIntent || ''
      )}
			}
		} else {
			msg("Encontrei um erro ao tentar processar sua mensagem, tente novamente em alguns instantes");
			event('${block.data.groupId}_${block.data.name}');
			}
		`;
    }

    compiledPayload += switchString;

    if (block.data.sendUser && block.data.sendUser.message) {
      block.data.sendUser.message = block.data.sendUser.message.replace(
        /'/g,
        "\\'"
      );
    }

    block.data.dataBlockly = {
      lastVersion: 0,
      compiledPayload: `
				event('${block.data.groupId}_${carouselBlock1.data.name}');
			`,
      payload: '',
      xml: '',
    };

    carouselBlock1.data.dataBlockly = {
      lastVersion: 0,
      compiledPayload:
        carouselBlock1.data?.dataBlockly?.compiledPayload.replaceAll(
          '\t',
          ''
        ) || '',
      payload: '',
      xml: '',
    };

    carouselBlock2.data.dataBlockly = {
      lastVersion: 0,
      compiledPayload: compiledPayload.replaceAll('\t', ''),
      payload: '',
      xml: '',
    };
  }
  return lastBlockId;
}

export function getCompiledPayload(
  blocks: DataFlow,
  block: ItemDataFlow,
  inputBlock: ItemDataFlow,
  outputBlockId: number,
  fallBackBlock: string
) {
  let compiledPayload = '';
  const msgType = block.data.messageType as MessageType;
  const outputBlock = blocks[outputBlockId];
  const outputBlockName = outputBlock ? outputBlock.data.name : '';
  switch (msgType) {
    case 'cep':
      compiledPayload += `${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'cep'
      )}`;
      break;

    case 'cpf':
      compiledPayload += `${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'cpf'
      )}`;
      break;

    case 'cnpj':
      compiledPayload += `${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'cnpj'
      )}`;
      break;

    case 'location':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'location'
      )}`;
      break;

    case 'email':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'email'
      )}`;
      break;

    case 'text':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'text'
      )}`;
      break;

    case 'anyNumber':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'anyNumber'
      )}`;
      break;

    case 'data':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'data'
      )}`;
      break;

    case 'phone':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'phone'
      )}`;
      break;

    case 'password':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'password'
      )}`;
      break;

    case 'money':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'money'
      )}`;
      break;

    case 'url':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'url'
      )}`;
      break;

    case 'uuid':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'uuid'
      )}`;
      break;

    case 'lettersOnly':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        outputBlockName,
        fallBackBlock,
        'lettersOnly'
      )}`;
      break;
  }

  return compiledPayload;
}

function getIfCondition(
  block: ItemDataFlow,
  blockName: string,
  destinationBlockName: string,
  fallBackBlock: string,
  msgType: MessageType
) {
  const groupName = block.data.groupId;
  const varValue = block.data.varValue || 'vars.resposta';
  const errorMessage = block.data.sendUser?.messageDidNotUnderstood || '';
  let condition = '';
  const regex = getRegex(msgType);

  const validationFuncion =
    msgType === 'cpf'
      ? 'isValidCPF'
      : msgType === 'cnpj'
        ? 'isValidCNPJ'
        : msgType === 'cep'
          ? 'isValidCEP'
          : '';

  if (validationFuncion !== '') {
    condition = `
			const regexResult = user.input.match(${regex});
			input = regexResult;
			${varValue} = regexResult;

			if (input !== null) {
				${msgType} = (await ${validationFuncion}(input.toString()));\n
				if (${msgType}) {
					${varValue} = ${msgType};
					vars.contagem == null;
					event('${groupName}_${destinationBlockName}');
				} else {
					${getCountCondition(errorMessage, groupName, blockName, fallBackBlock)}
				}		
			} else {
				${getCountCondition(errorMessage, groupName, blockName, fallBackBlock)}
			}
		`;
  } else if (msgType === 'location') {
    condition += `
		regexResult = ${regex}.exec(user.input);
		if (regexResult) {
			lat = user.input.substring(0, user.input.indexOf("|"));
			long = user.input.substring(user.input.indexOf("|") + 1, user.input.length);
			ret = await getAddress(long, lat);
			if (ret) {
				${varValue} = ret;
				vars.contagem == null;
				event('${groupName}_${destinationBlockName}');
			}
			else {
				${getCountCondition(
      `Erro ao buscar sua Localização.\n\n ${errorMessage}`,
      groupName,
      blockName,
      fallBackBlock
    )}
			}		
		}
		else {
			${getCountCondition(errorMessage, groupName, blockName, fallBackBlock)}
		}		
		`;
  } else {
    condition += ` 
			regexResult = ${regex}.exec(user.input);
			if (regexResult) {
				${varValue} = regexResult[0].toString();
				vars.contagem == null;
				event('${groupName}_${destinationBlockName}');
			} else {
				${getCountCondition(errorMessage, groupName, blockName, fallBackBlock)}
			}		
		`;
  }

  return condition;
}

const getCountCondition = (
  errorMessage: string,
  groupName: string,
  blockName: string,
  fallBackBlock?: string
) => {
  const fallBackEvent = fallBackBlock
    ? `event('${groupName}_${fallBackBlock}');`
    : blockName !== ''
      ? `event('${groupName}_${blockName}');`
      : '';

  const errorEvent =
    errorMessage !== '' ? `msg(\`${errorMessage}\`, 'text');` : '';

  return `
	if (vars.contagem >= 2) {
		vars.contagem = null;
		${errorEvent}
		event("999-did-not-understood");
	} else {
		vars.contagem = vars.contagem + 1;
		${errorEvent}
		${fallBackEvent}
	}
	`;
};

const getRegex = (type: MessageType) => {
  let regex: RegExp = /.+/gm;

  switch (type) {
    case 'text':
      regex = /.+/gm;
      break;
    case 'anyNumber':
      regex = /\d+/gm;
      break;
    case 'email':
      regex = /[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+/gm;
      break;
    case 'url':
      regex =
        // eslint-disable-next-line no-useless-escape
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%.\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%\+.~#?&\/=]*)/gm;
      break;
    case 'phone':
      regex = /\(?\d{2}\)? ?\d{5}-?\d{4}/gm;
      break;
    case 'lettersOnly':
      regex = /[A-Za-z ã á â é ê í õ ó ç]*/gm;
      break;
    case 'cpf':
      regex = /\d{3}\.?\d{3}\.?\d{3}-?\d{2}/gm;
      break;
    case 'cnpj':
      regex =
        // eslint-disable-next-line no-useless-escape
        /[0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2}/gm;
      break;
    case 'cep':
      regex = /\d{5}-?\d{3}/gm;
      break;
    case 'data':
      regex = /\d{2}\/?\d{2}\/?\d{4}/gm;
      break;
    case 'money':
      regex = /[1-9]\d{0,2}(\.\d{3})*,\d{2}/gm;
      break;
    case 'password':
      regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      break;
    case 'location':
      regex =
        /[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)[|]\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/gm;
      break;
    case 'uuid':
      regex =
        /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/gm;
      break;
  }

  return regex;
};
