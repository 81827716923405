import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Info from 'components/inputs/Material/Info/index';
import MultiVariablesQuestions from 'components/inputs/Material/MultiVariablesQuestions';
import MessageAfterUserReply from 'components/inputs/Material/MessageAfterUserReply';
import { FormFieldsContainer } from '../Shared/styles';

export default function QuestionsAndAnswers() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <MultiVariablesQuestions
          showDelete={true}
          showQuestions={true}
          indexsRequired={[0]}
        />
        <MessageAfterUserReply />
      </FormFieldsContainer>
      <FooterButtons />
    </>
  );
}
