import React, { ChangeEvent } from 'react';

import { TransitionProps } from '@material-ui/core/transitions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  Dialog,
  Slide,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import './styles.css';
import {
  ContainerDialog,
  ContainerHeader,
  Button,
  ButtonContainer,
  ModalTitle,
  FieldContainer,
  MoreVariable,
} from './styles';

import { IModalVariableAdvancedProps } from './types';
import { useForm } from 'contexts/Form/formContext';
import { validateVarNames } from 'utils/String';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      background: '#F2F2F2',
    },

    input: {
      background: '#FFFFFF',
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalVariableAdvanced(
  props: IModalVariableAdvancedProps
) {
  const classes = useStyles();
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const variable = dataForm?.inputs?.variables[props.index];

  function handleClose() {
    props.handleCloseModal();
  }

  const handleChangeFields =
    (key: 'value' | 'defaultValue') => (e: ChangeEvent<HTMLInputElement>) => {
      if (dataForm && variable) {
        const value = String(e.target.value);
        if (key === 'value') {
          const filteredValue = validateVarNames(e, key, true)
          variable.isValueEdited = true;
          variable[key] = '$' + filteredValue.replace('$', '');
        } else {
          variable[key] = value;
        }
        dispatch({
          type: 'updateForm',
          data: { dataForm },
        });
      }
    };

  const handleChangeMoreThanOneVariable = () => {
    if (dataForm && variable) {
      variable['isList'] = !variable['isList'];
      dispatch({
        type: 'updateForm',
        data: { dataForm },
      });
    }
  };

  if (!props.open) return <></>;

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog}>
        <ContainerHeader>
          <ModalTitle style={{ fontWeight: 600 }}>Opções avançadas</ModalTitle>
          <IconButton
            edge="start"
            color="default"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </ContainerHeader>
        <FieldContainer>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-value">
              $ValorVariavel
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-value"
              value={variable?.value || ''}
              onChange={handleChangeFields('value')}
              labelWidth={120}
              inputProps={{
                style: { background: '#fff' },
              }}
            />
          </FormControl>
        </FieldContainer>
        <FieldContainer>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-defaultValue">
              Valor padrão
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-defaultValue"
              value={variable?.defaultValue || ''}
              onChange={handleChangeFields('defaultValue')}
              labelWidth={120}
              inputProps={{
                style: { background: '#fff' },
              }}
            />
          </FormControl>
        </FieldContainer>
        <MoreVariable>
          <FormControlLabel
            control={
              <Switch
                checked={!!variable?.isList}
                onChange={handleChangeMoreThanOneVariable}
                name="checked"
                color="primary"
              />
            }
            label="Mais de uma variável?"
          />
        </MoreVariable>
        <ButtonContainer>
          <Button
            style={{ letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            onClick={() => props.handleCloseModal()}
          >
            Salvar Definições
          </Button>
        </ButtonContainer>
      </ContainerDialog>
    </Dialog>
  );
}
