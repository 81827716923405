import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'contexts/Form/formContext';
import * as S from './styles';
import { TextField, Typography } from '@material-ui/core';
import useTranslator from 'utils/hooks/Translator';
import { validateVarNames } from 'utils/String';

export default function SendDocumentFields() {
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const [varValue, setVarValue] = useState(
    dataForm?.varValue?.substring(5, dataForm?.varValue.length) || 'url_doc'
  );
  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && !dataForm.inputs) {
      dataForm.inputs = {
        userSentences: ['', '', ''],
        variables: [
          {
            name: '',
            vartype: '',
            questions: ['', '', ''],
            required: false,
            value: '',
            defaultValue: '',
            isList: false,
          },
        ],
      };
      dataForm.sendUser = {
        message: '',
      };
      dataForm.varValue = 'vars.resposta';
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeField = (
    e: React.ChangeEvent<
      | { name?: string | undefined; value: unknown }
      | HTMLTextAreaElement
      | HTMLInputElement
    >
  ) => {
    if (!!dataForm) {
      const validatedValue = validateVarNames(e, 'name', true);
      dataForm['varValue'] = `vars.${validatedValue}`;
      setVarValue(validatedValue);

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeDidNotUnderstood = (newValue: string) => {
    if (!!dataForm && dataForm.sendUser) {
      dataForm.sendUser.messageDidNotUnderstood = newValue;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  return (
    <S.Container>
      <S.ContentOutputVariable>
        <Typography variant="body1">
          {getTranslation('outputVariable')}
        </Typography>
        <div>
          <TextField value={'vars.'} style={{ maxWidth: '50px' }} />
          <TextField
            value={varValue}
            onChange={handleChangeField}
          />
          <S.VariableContainer>
            <Typography variant="body1">
              {getTranslation('messageDidNotUnderstood')}
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={dataForm?.sendUser?.messageDidNotUnderstood}
              onChange={(e) => handleChangeDidNotUnderstood(e.target.value)}
              multiline
              minRows={3}
            />
          </S.VariableContainer>
        </div>
      </S.ContentOutputVariable>
    </S.Container>
  );
}
