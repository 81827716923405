import React from 'react';
import CircularDeleteButton from 'components/CircularDeleteButton';
import { Container, ContainerOptions, ContentOptions } from './styles';
import { Divider } from '@material-ui/core';
import { BoxAddLine, StyledAddButtom } from '../styles';
import AddIcon from '@material-ui/icons/Add';
import { useFlow } from 'contexts/Flow/flowContext';
import InputField from './InputField';
import { GridDeleteIcon } from '../AdvancedExpressions/styles';
import { useEntity } from 'contexts/Entity/entityContext';
import useTranslator from 'utils/hooks/Translator';

export default function DefineWords() {
  const { toastNotification } = useFlow();
  const { state, dispatch } = useEntity();
  const { selectedIEntity } = state;
  const { values } = state.selectedIEntity;
  const { getTranslation } = useTranslator();

  const handleDeleteLine = (index: number) => {
    if (values.length > 1) {
      values.splice(index, 1);
      dispatch({ type: 'updateEntities', data: { selectedIEntity } });
    } else {
      toastNotification('error', 'não é possível excluir');
    }
  };

  const handleShowLines = (index: number) => {
    return (
      <ContentOptions key={`HSL${index}`}>
        <InputField index={index} />
        {values.length > 1 ? (
          <GridDeleteIcon>
            <CircularDeleteButton
              handleDeleteItem={() => handleDeleteLine(index)}
            />
          </GridDeleteIcon>
        ) : (
          <GridDeleteIcon> </GridDeleteIcon>
        )}
      </ContentOptions>
    );
  };

  const handleAddNewLine = () => {
    values.push({ value: '', synonyms: [] });
    dispatch({ type: 'updateEntities', data: { selectedIEntity } });
  };

  return (
    <Container>
      <BoxAddLine>
        <span>
          {getTranslation('variables.hintWords')} <strong>TAB</strong> ou{' '}
          <strong>ENTER</strong>.
        </span>
        <StyledAddButtom
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNewLine}
        >
					{getTranslation('variables.addLine')}
				</StyledAddButtom>
      </BoxAddLine>
      <Divider />
      <ContainerOptions size={values.length}>
        {values.map((_, index) => handleShowLines(index))}
      </ContainerOptions>
    </Container>
  );
}
