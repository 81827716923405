import {
  AppBar,
  Box,
  IconButton,
  Slider,
  Toolbar,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import useTranslator from 'utils/hooks/Translator';
import * as S from './style';
import { ModalAddMinRelevanceProps } from './types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ModalScoreThreshold({
  setIsOpen, 
  contentGroupData, 
  setContentGroupData,
  title
}: ModalAddMinRelevanceProps) {
  const classes = S.useStyles();
  const { getTranslation } = useTranslator();

  function valuetext(value: number) {
    return `${value}`;
  }

  function handleChangeMinRelevance(value: number | number[]) {
    if (value !== contentGroupData.score_threshold) {
      setContentGroupData((prevState) => ({
        ...prevState,
        score_threshold: Number(value),
      }));
    }
  }

  return (
    <Dialog
      open
      fullWidth={true}
      TransitionComponent={Transition}
      maxWidth="sm"
      PaperProps={{ classes: { rounded: classes.dialog } }}
    >
      <S.DialogContainer>
        <AppBar>
          <Toolbar>
            <Typography
              variant="h5"
              className={classes.title}
            >
              {getTranslation('minRelevance')}
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <S.ContentContainer>
          <Typography variant='subtitle1'>
            {title}
          </Typography>

          <Box 
            component='div' 
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              marginTop: 16
            }}
          >
            <Typography variant='subtitle2'>
              0.5
            </Typography>

            <Slider
              defaultValue={contentGroupData.score_threshold ?? 0.8}
              getAriaValueText={(value) => valuetext(value)}
              onChange={(_, value) => handleChangeMinRelevance(value)}
              aria-labelledby="min-relevance-slider"
              step={0.01}
              marks
              min={0.5}
              max={0.9}
            />

            <Typography variant='subtitle2'>
              0.9
            </Typography>

            <S.MinRelevanceBoxValue>
              <Typography variant='body2' style={{ fontWeight: 'bolder' }} >
                {contentGroupData.score_threshold}
              </Typography>
            </S.MinRelevanceBoxValue>
          </Box>
        </S.ContentContainer>
      </S.DialogContainer>
    </Dialog>
  );
}
