import { createContext, useContext, useEffect, useState } from 'react';
import { Builder } from 'views/Canvas/Builder';
import {
  CampaignElements,
  CloudData,
  DashboardOptions,
  FintalkCloudContextState,
  BuilderElementType,
  Props,
  SidebarOption,
  CRMElements,
} from './types';

import ModalManageCompanies from 'components/ModalManageCompanies';
import { BuilderContextProvider } from 'contexts/Builder';
import { CompanyProvider } from 'contexts/Company';
import { useFintalkCloudSidebarItems } from 'utils/hooks/useFintalkCloudSidebarItems';
import { CampaignModule } from 'views/Canvas/CampaignsModule';
import { FintalkDesk } from 'views/Canvas/Desk';
import { N8N } from 'views/Canvas/N8N';
import { CRM } from 'views/Canvas/CRM';
import { BubbleModule } from 'views/Canvas/Bubble';
import { SupersetModule } from 'views/Canvas/Superset';

const FintalkCloudContext = createContext<FintalkCloudContextState>(
  {} as FintalkCloudContextState
);

export function FintalkCloudContextProvider({ children, history }: Props) {
  const {
    homeSidebar,
    // adminSidebar,
    builderSidebarOptions,
    campaignSidebar,
    crmSidebar,
  } = useFintalkCloudSidebarItems();

  const [currentElementName, setCurrentElementName] =
    useState<DashboardOptions>('builder');

  const [currentSidebar, setCurrentSidebar] =
    useState<SidebarOption[]>(homeSidebar);

  const [currentData, setCurrentData] = useState<CloudData>({
    companyName: '',
    projectName: '',
    agentName: '',
    groupName: '',
    isEditing: false,
    exportAgent: false,
    importAgent: false,
  });

  const [builderElementToOpen, setBuilderElementToOpen] = useState<
    BuilderElementType | undefined
  >(undefined);

  const [campaignElementToOpen, setCampaignElementToOpen] = useState<
    CampaignElements | undefined
  >('templates');

  const [crmElementToOpen, setCrmElementToOpen] = useState<
    CRMElements | undefined
  >('customers');

  const getCurrentSidebar: {
    [keyof in DashboardOptions]: SidebarOption[];
  } = {
    home: homeSidebar,
    admin: [],
    automation: [],
    builder: builderSidebarOptions,
    campaign: campaignSidebar,
    airtalk: builderSidebarOptions,
    desk: [],
    crm: crmSidebar,
    bubble: [],
    dashboard: [],
  };

  const getCurrentElement: {
    [keyof in DashboardOptions]: JSX.Element;
  } = {
    home: <></>,
    admin: (
      <CompanyProvider>
        <ModalManageCompanies />
      </CompanyProvider>
    ),
    automation: <N8N />,
    builder: (
      <BuilderContextProvider>
        <Builder history={history} />
      </BuilderContextProvider>
    ),
    campaign: <CampaignModule />,
    desk: <FintalkDesk />,
    airtalk: (
      <BuilderContextProvider>
        <Builder history={history} usesAirTalk />
      </BuilderContextProvider>
    ),
    crm: <CRM />,
    bubble: <BubbleModule/>,
    dashboard: <SupersetModule/>,
  };

  useEffect(() => {
    const localElement =
      localStorage.getItem('@fintalkCloud:element') ?? 'home';
    handleChangeCurrentElementName(localElement as DashboardOptions);
    loadLocalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadLocalData() {
    const companyName = localStorage.getItem('@fintalkCloud:company') ?? '';
    const projectName = localStorage.getItem('@fintalkCloud:project') ?? '';
    const agentName = localStorage.getItem('@fintalkCloud:agent') ?? '';
    const groupName = localStorage.getItem('@fintalkCloud:group') ?? '';

    setCurrentData({
      ...currentData,
      companyName,
      projectName,
      agentName,
      groupName,
    });
  }

  function handleChangeCurrentElementName(name: DashboardOptions) {
    setCurrentElementName(name);
    setCurrentSidebar(getCurrentSidebar[name]);
    localStorage.setItem('@fintalkCloud:element', name);
  }

  return (
    <FintalkCloudContext.Provider
      value={{
        getCurrentElement,
        currentElementName,
        currentSidebar,
        currentData,
        builderElementToOpen,
        campaignElementToOpen,
        crmElementToOpen,
        setCampaignElementToOpen,
        setCrmElementToOpen,
        setCurrentElementName,
        setCurrentData,
        setCurrentSidebar,
        handleChangeCurrentElementName,
        setBuilderElementToOpen,
      }}
    >
      {children}
    </FintalkCloudContext.Provider>
  );
}

export function useFintalkCloud() {
  const context = useContext(FintalkCloudContext);
  return context;
}
