import React from 'react';
import { EntityProvider } from 'contexts/Entity/entityContext';
import Main from './Main';

export default function Entities() {
  return (
    <EntityProvider>
      <Main />
    </EntityProvider>
  );
}
