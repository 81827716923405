import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'contexts/Form/formContext';
import { Variable } from 'models/DataFlow';
import MttButton from 'components/Material/MttButton/MttButton';
import VariavelIdentificacaoTextoUsuario from '../VariavelIdentificacaoTextoUsuario';
import { StyledMttTypographyIdentificacao } from '../VariableIndentificationTextUser/styles';
import AddIcon from '@material-ui/icons/Add';
import * as S from './styles';
import { MultiVarsProps } from './types';
import useTranslator from 'utils/hooks/Translator';

const { VariablesLine, MultiVariablesContainer } = S.styles;

export default function MultiVars(props: MultiVarsProps) {
  const { showQuestions, showDelete } = props;
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const [variables, setVariables] = useState(dataForm?.inputs?.variables || []);

  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && !dataForm.inputs?.userSentences) {
      if (dataForm.inputs) {
        dataForm.inputs.userSentences = ['', '', ''];
      }
    }

    if (dataForm && !dataForm?.inputs) {
      dataForm.inputs = {
        variables: [
          {
            name: '',
            questions: ['', '', ''],
            required: false,
            vartype: '',
            defaultValue: '',
            isList: false,
            value: '',
          },
        ],
      };
      dispatch({ type: 'updateForm', data: { dataForm } });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const renderVars = (variable: Variable, index: number) => {
    return (
      <VariablesLine key={`${variable}-${index}`}>
        <VariavelIdentificacaoTextoUsuario
          index={index}
          showDelete={showDelete}
          showQuestions={showQuestions}
        />
      </VariablesLine>
    );
  };

  const handleAddVariable = (e: any) => {
    const variable = {
      name: '',
      vartype: '',
      value: '',
      required: false,
      questions: ['', '', ''],
      defaultValue: '',
      isList: false,
    };

    setVariables((prev) => [...prev, variable]);
  };

  useEffect(() => {
    if (!!dataForm && !!dataForm.inputs) dataForm.inputs.variables = variables;
    dispatch({ type: 'updateForm', data: { dataForm } });
  }, [dataForm, dispatch, variables]);

  return variables.length > 0 || dataForm?.intentType === 4 ? (
    <>
      <S.StyledTitleGrid>
        <StyledMttTypographyIdentificacao
          style={{ fontWeight: 600 }}
          variant="h6"
          color="primary"
        >
          {getTranslation('extractedVariables')}
        </StyledMttTypographyIdentificacao>
        {dataForm?.intentType === 4 && (
          <MttButton
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddVariable}
          >
            {getTranslation('addVariables')}
          </MttButton>
        )}
      </S.StyledTitleGrid>
      <MultiVariablesContainer>
        {variables.map(renderVars)}
      </MultiVariablesContainer>
    </>
  ) : (
    <></>
  );
}
