import { createTheme } from '@material-ui/core/styles';

export const theme = {
  palette: {
    primary: {
      main: '#0e103b',
      defaultBorder: '1px solid #353759',
      defaultBackground: '#00032e',
      lightBackground: '#353759',
      defaultBorderRadius: '8px',
    },
    secondary: {
      main: '#f3537e',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '&.Mui-disabled': {
          border: '1px solid #353759 !important',
          color: '#353759 !important',
        }
      }
    },
  },
  border: {
    primary: '1px solid #353759',
    secondary: '1px solid #f3537e',
  },
  zIndex: {
    modal: 500,
  },
};

export const defaultTheme = createTheme(theme) as unknown as typeof theme;
