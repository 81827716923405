import {
  Typography,
  IconButton,
  TextField,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import { AddOutputContainer } from 'components/MetadataBlocksDrawerEditor/styles';
import { ApiType, KeyValuePair, Property } from 'contexts/Flow/types';
import { Add, Delete } from '@material-ui/icons';
import { ChangeEvent } from 'react';
import { useForm } from 'contexts/Form/formContext';

export function ApiEntries({ entries }: Readonly<{ entries: Property[] }>) {
  const { state, updateForm } = useForm();
  const { dataForm, formErrors } = state;

  const handleChangeMultivaluedEntry = (
    entryName: 'headers' | 'query' | 'params',
    entryField: 'key' | 'value',
    index: number,
    newValue: string
  ) => {
    if (dataForm?.apiInfo) {
      dataForm.apiInfo[entryName][index][entryField] = newValue;
      updateForm(dataForm);
    }
  };

  const handleRemoveMultivaluedEntry = (
    entryName: 'headers' | 'query' | 'params',
    index: number
  ) => {
    if (dataForm && dataForm.apiInfo) {
      dataForm.apiInfo[entryName].splice(index, 1);

      updateForm(dataForm);
    }
  };

  const handleChangeApiTypeRegularFields = (
    e: ChangeEvent<{ name?: string; value: unknown }>,
    field: 'method' | 'url' | 'body'
  ) => {
    if (dataForm?.apiInfo) {
      dataForm.apiInfo[field] = e.target.value as ApiType;

      updateForm(dataForm);
    }
  };

  const handleAddOutput = (entry: Property) => {
    const isArray = Array.isArray(dataForm?.apiInfo?.[entry.fieldName!]);
    if (!isArray) return;
    const fieldAsArray = dataForm?.apiInfo?.[
      entry.fieldName!
    ] as Array<KeyValuePair>;

    fieldAsArray.push({
      key: '',
      value: '',
    });

    if (
      entry.fieldName === 'headers' ||
      entry.fieldName === 'query' ||
      entry.fieldName === 'params'
    ) {
      dataForm!.apiInfo![entry.fieldName] = fieldAsArray;

      updateForm(dataForm);
    }
  };

  const renderMultivaluedEntry = (entry: Property) => {
    const isArray = Array.isArray(dataForm?.apiInfo?.[entry.fieldName!]);
    const fieldAsArray = dataForm?.apiInfo?.[
      entry.fieldName!
    ] as Array<KeyValuePair>;

    const treatedFieldName = entry.fieldName as 'headers' | 'query' | 'params';

    return dataForm?.apiInfo && entry.fieldName && isArray ? (
      fieldAsArray.map((_, index) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <TextField
            type={entry.type}
            placeholder="Chave"
            inputProps={{ maxLength: entry.maxLength }}
            value={fieldAsArray[index].key || ''}
            onChange={(e) =>
              handleChangeMultivaluedEntry(
                treatedFieldName,
                'key',
                index,
                e.target.value
              )
            }
          />
          <TextField
            type={entry.type}
            inputProps={{ maxLength: entry.maxLength }}
            placeholder="Valor"
            value={fieldAsArray[index].value || ''}
            onChange={(e) =>
              handleChangeMultivaluedEntry(
                treatedFieldName,
                'value',
                index,
                e.target.value
              )
            }
          />
          <IconButton
            onClick={() =>
              handleRemoveMultivaluedEntry(treatedFieldName, index)
            }
          >
            <Delete />
          </IconButton>
        </div>
      ))
    ) : (
      <></>
    );
  };

  const renderRegularEntry = (entry: Property) => {
    const multiline = entry.component === 'multiline';

    const componentByType: {
      [keyof in 'text' | 'select']: JSX.Element;
    } = {
      select: (
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={dataForm?.apiInfo?.method ?? 'GET'}
            onChange={(e) => handleChangeApiTypeRegularFields(e, 'method')}
          >
            {entry.values?.map((value) => (
              <MenuItem value={value}>{value.toUpperCase()}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      text: (
        <TextField
          type={entry.type}
          value={dataForm?.apiInfo?.[entry.fieldName!] ?? ''}
          inputProps={{ maxLength: entry.maxLength }}
          fullWidth
          multiline={multiline}
          minRows={multiline ? 3 : 1}
          variant={multiline ? 'outlined' : 'standard'}
          error={entry.fieldName === 'url' && !!formErrors.apiInfoUrl}
          helperText={entry.fieldName === 'url' && formErrors.apiInfoUrl}
          onChange={(e) =>
            handleChangeApiTypeRegularFields(
              e,
              entry.fieldName as 'body' | 'method' | 'url'
            )
          }
        />
      ),
    };
    return componentByType[entry.type as 'text' | 'select'];
  };

  const renderEntries = () =>
    entries?.map((entry, index) => (
      <div key={index} style={{ marginBottom: 16 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2">{entry.label}</Typography>
          {entry.multivalued && (
            <AddOutputContainer>
              <IconButton onClick={() => handleAddOutput(entry)}>
                <Add />
              </IconButton>
            </AddOutputContainer>
          )}
        </div>
        {entry.multivalued
          ? renderMultivaluedEntry(entry)
          : renderRegularEntry(entry)}
      </div>
    ));

  return <>{renderEntries()}</>;
}
