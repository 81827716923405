import styled from 'styled-components';
import MttButton from '../Material/MttButton/MttButton';
import Popover from '@material-ui/core/Popover';

export const PopoverContainer = styled(Popover)`
  margin-top: 10px;
  margin-left: 5px;
  z-index: 3;

  .MuiButton-iconSizeMedium > *:first-child {
    font-size: 1.3em;
  }

  .MuiButton-text {
    border-bottom: 2px solid #f2f2f2;
    border-radius: 0;
    display: flex;
    justify-content: left;
    padding: 11.4px;
    height: 56px;
    width: 100%;

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-bottom: none;
      border-radius: 0 0 4px 4px;
    }
  }

  .MuiButton-label {
    text-transform: none;
    font-size: 1.2em;
  }
`;

export const SelectOpt = styled(MttButton).attrs({
  children: 'Selecionar',
})``;

export const DeselectOpt = styled(MttButton).attrs({
  children: 'Desselecionar todos',
})``;

export const FlowHeatMap = styled(MttButton).attrs({
  children: 'Mapa de Calor de Fluxo',
})``;

export const DuplicateOpt = styled(MttButton).attrs({
  children: 'Duplicar',
})``;

export const CopyToLibOpt = styled(MttButton).attrs({
  children: 'Copiar para Biblioteca',
})``;

export const DeleteOpt = styled(MttButton).attrs({
  children: 'Excluir',
})``;
