import React from 'react';

import MetadataBlocksDrawerEditor from 'components/MetadataBlocksDrawerEditor';
import ModalCorrectSentences from 'components/ModalCorrectSentences';
import ModalFlexBlocks from 'components/ModalFlexBlocks';
import ModalForms from 'components/ModalForms';
import ModalInactiveWarning from 'components/ModalInactiveWarning';
import ModalManageAccount from 'components/ModalManageAccount';
import ModalManagePermission from 'components/ModalManagePermission';
import ModalNewGroup from 'components/ModalNewGroup';
import ModalPhrasesOfIntentManager from 'components/ModalPhrasesOfIntentManager';
import ModalTemplateUpload from 'components/ModalTemplateUpload';
import ModalUsedGroup from 'components/ModalUsedGroup';
import { FormProvider } from 'contexts/Form/formContext';

const Flow: React.FC = () => {
  return (
    <>
      <ModalForms />
      <FormProvider>
        <MetadataBlocksDrawerEditor />
      </FormProvider>
      <ModalNewGroup />
      <ModalFlexBlocks />
      <ModalTemplateUpload />
      <ModalInactiveWarning />
      <ModalUsedGroup />
      <ModalManageAccount />
      <ModalManagePermission />
      <ModalPhrasesOfIntentManager />
      <ModalCorrectSentences />
    </>
  );
};

export default Flow;
