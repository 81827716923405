import { IAppContextActions } from 'contexts/App/types';
import { json } from 'services/RequestService';
import { IBaseResponse } from 'services/RequestService/Response';
import {
  ICreateActioningRequest,
  ICreateActioningResponse,
  IDeleteActioningRequest,
  IGetActioningsRequest,
  IGetActioningsResponse,
  IGetOneActioningRequest,
  IGetOneActioningResponse,
  ITriggerActioningRequest,
  IUpdateActioningRequest,
  IUpdateActioningResponse,
} from './types';


const actioningAction = 'actionings';
const botsAction = 'bots';

export const GetActionings = async (
  request: IGetActioningsRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetActioningsResponse>> => {
  const action = `${botsAction}/${request.bot_name}/${actioningAction}`;
  return await json<IGetActioningsResponse>('GET', action, dispatch);
};

export const GetOneActioning = async (
  request: IGetOneActioningRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${actioningAction}/${request.campaignId}`;
  return await json<IBaseResponse<IGetOneActioningResponse>>(
    'GET',
    action,
    dispatch
  );
};

export const CreateActioning = async (
  request: ICreateActioningRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${actioningAction}`;
  return await json<IBaseResponse<ICreateActioningResponse>>(
    'POST',
    action,
    dispatch,
    request
  );
};

export const UpdateActioning = async (
  request: IUpdateActioningRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${actioningAction}/${request._id}`;
  return await json<IBaseResponse<IUpdateActioningResponse>>(
    'PATCH',
    action,
    dispatch,
    request
  );
};

export const DeleteActioning = async (
  request: IDeleteActioningRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${actioningAction}/${request._id}`;
  return await json<IBaseResponse<void>>('DELETE', action, dispatch);
};

export const TriggerActioning = async (
  request: ITriggerActioningRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${actioningAction}/execute/${request._id}`;
  return await json<IBaseResponse<void>>('GET', action, dispatch);
};
