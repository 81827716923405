import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { FormProvider } from 'contexts/Form/formContext';
import React from 'react';
import useTranslator from 'utils/hooks/Translator';
import PublishProgressBar from '../PublishProgressBar';
import logo from './llego-animated.gif';
import { Container, ContainerImage, ModalTitle, useStyles } from './style';
import './styles.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalPublishBot() {
  const classes = useStyles();
  const { getTranslation } = useTranslator();

  return (
    <FormProvider>
      <Container id="teste2">
        <Dialog
          open={true}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              boxShadow: '8px 8px 8px #5a5a5a',
              borderRadius: '10px',
            },
          }}
        >
          <Grid className={classes.grid}>
            <ModalTitle>{getTranslation('publishing')}</ModalTitle>
          </Grid>

          <ContainerImage>
            <img src={logo} alt="loading..." className={classes.img} />
          </ContainerImage>
          <PublishProgressBar />
        </Dialog>
      </Container>
    </FormProvider>
  );
}
