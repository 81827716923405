import React from 'react';

import { TextField } from '@material-ui/core';

import { ITextFieldProps } from './types';

const MttTextField: React.FC<ITextFieldProps> = (props: ITextFieldProps) => {
  const {
    maxRows,
    label,
    value,
    variant,
    fullWidth,
    inputProps,
    disabled,
    error,
    rows,
    required,
    onBlur,
    onChange,
    type,
    helperText,
    multiline,
    placeholder,
    ...rest
  } = props;
  return (
    <TextField
      maxRows={maxRows}
      variant={variant}
      label={label}
      minRows={rows}
      fullWidth={fullWidth}
      inputProps={inputProps}
      error={error}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      required={required}
      type={type}
      helperText={helperText}
      multiline={multiline}
      {...rest}
    />
  );
};

export default MttTextField;
