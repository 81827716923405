import FooterButtons from 'components/inputs/Material/FooterButtons';
import Info from 'components/inputs/Material/Info';
import MessageForUser from 'components/inputs/Material/MessageForUser/index';
import TypeOfMessage from 'components/inputs/Material/TypeOfMessage';
import { FormFieldsContainer } from '../Shared/styles';

export default function SendMessage() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <MessageForUser hideUrl />
				<TypeOfMessage />
      </FormFieldsContainer>
      <FooterButtons showLogicButton={false} />
    </>
  );
}
