import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const Container = styled(MttGrid)`
  width: 100%;
`;

export const ContainerOptions = styled(MttGrid)<{ size?: number }>`
  padding: ${(props) =>
    props.size && props.size > 1 ? '10px 10px 10px 20px' : '10px 20px'};

  max-height: 500px;
  overflow-y: auto;
`;

export const ContentOptions = styled(MttGrid)`
  display: flex;
  max-height: 500px;

  margin-bottom: 16px;

  .MuiOutlinedInput-input {
    background-color: #fff;
  }
`;

export const GridDeleteIcon = styled(MttGrid)`
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
