import clsx from 'clsx';
import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { BubbleChartRounded } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HttpIcon from '@material-ui/icons/Http';
import InputIcon from '@material-ui/icons/Input';
import SmsIcon from '@material-ui/icons/Sms';
import MultipleChoiceIcon from 'components/Icons/MultipleChoiceIcon';
import { useFlow } from 'contexts/Flow/flowContext';
import {
  MetaBlockType,
  MetadataBlock,
  MetadataBlocks,
} from 'contexts/Flow/types';
import AddBlockToFlowController from 'controllers/library/AddBlockToFlowController';
import GetOffsetPositionFlowController from 'controllers/library/GetOffsetPositionFlowController';
import EIntentType from 'enums/EIntentType';
import { DataFlow } from 'models/DataFlow';
import { ModalToPopProps } from 'views/Canvas/Builder/MainComponent/BuilderModals/types';
import data from '../ModalOptions/metablocks.json';
import { useStyles } from './styles';

export default function MetadataBlocksDrawer({ handleClose }: ModalToPopProps) {
  const { state, mountNodeContent } = useFlow();
  const classes = useStyles();
  const [blocksGroupedByCategory, setBlocksGroupedByCategory] = useState<{
    [category: string]: MetadataBlock[];
  }>({});

  // useEffect(() => {
  //   async function fetchMetaBlocks() {
  //     const response = await fetch(
  //       'https://cloud-metablocks.web.fintalk.io/metablocks.json'
  //     );
  //     if (response.ok) {
  //       const metaBlocks = await response.json();
  //       const grouped = groupBlocksByCategory(metaBlocks as MetadataBlocks);
  //       setBlocksGroupedByCategory(grouped);
  //     }
  //   }

  //   fetchMetaBlocks();
  // }, []);

  useEffect(() => {
    const grouped = groupBlocksByCategory(data as MetadataBlocks);
    setBlocksGroupedByCategory(grouped);
  }, []);

  function groupBlocksByCategory(data: MetadataBlocks): {
    [category: string]: MetadataBlock[];
  } {
    const grouped: { [category: string]: MetadataBlock[] } = {};

    data.blocks.forEach((block) => {
      if (!grouped[block.category]) {
        grouped[block.category] = [];
      }

      grouped[block.category].push(block);
    });

    return grouped;
  }

  function getIcon(type: MetaBlockType) {
    switch (type) {
      case 'Question':
        return <InputIcon />;
      case 'Message':
        return <SmsIcon />;
      case 'Multiple':
        return <MultipleChoiceIcon />;
      case 'Api':
        return <HttpIcon />;
      case 'Bubble':
        return <BubbleChartRounded />
    }
  }

  const handleSendToFlow = async (block: MetadataBlock) => {
    const { editorFlow } = state;
    if (!editorFlow) return;

    const offsetPosition = new GetOffsetPositionFlowController(
      editorFlow
    ).getLastPosition();
    const drawflowCurrent = { ...editorFlow.drawflow };
    const controller = new AddBlockToFlowController(
      drawflowCurrent,
      state.idGroup || '',
      offsetPosition
    );
    const nextId = controller.getNextNodeId();

    await controller.addBlock({
      0: {
        class: 'dbclick',
        html: '',
        id: 0,
        name: 'dbclick',
        pos_x: offsetPosition.pos_x,
        pos_y: offsetPosition.pos_y,
        typenode: false,
        data: {
          metadata: block,
          name: block.name,
          description: block.description,
          groupId: state.idGroup || 'principal',

          sendUser: block.type !== 'Question' ? { message: '' } : undefined,
          inputs:
            block.type === 'Question'
              ? {
                  variables: [
                    {
                      name: '',
                      questions: [],
                      required: false,
                      vartype: '@sys.any',
                      defaultValue: '',
                      isList: false,
                      value: '',
                    },
                  ],
                }
              : undefined,
          intentType: EIntentType.MetadataBlock,
          block_id: String(nextId),
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 1,
            payload: '',
            xml: '',
          },
        },
        outputs: { output_1: { connections: [] } },
        inputs: { input_1: { connections: [] } },
      },
    });
    const drawflow = controller.getDrawflow();

    editorFlow.clear();
    editorFlow.import(drawflow);
    const dataFlow: DataFlow = drawflow.drawflow.Home.data;
    mountNodeContent(dataFlow);
    handleClose();
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: true,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: true,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        {Object.entries(blocksGroupedByCategory).map((entry, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordion}
            >
              <Typography>{entry[0]}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              <List>
                {entry[1].map((block) => (
                  <ListItem
                    key={block.id}
                    button
                    onClick={() => handleSendToFlow(block)}
                  >
                    <ListItemIcon>
                      {getIcon(block.type as MetaBlockType)}
                      <Typography>{block.name}</Typography>
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </Drawer>
  );
}
