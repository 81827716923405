import { Button, TextField } from '@material-ui/core';
import { MetaBlockType } from 'contexts/Flow/types';
import { useForm } from 'contexts/Form/formContext';
import { Output } from 'models/DataFlow';
import { useState } from 'react';
import TextEditor from 'components/TextEditor';
import useTranslator from 'utils/hooks/Translator';

export function ConditionalOutputs({
  item,
  type,
  index,
}: {
  item: Output;
  type: MetaBlockType;
  index: number;
}) {
  const { state, updateForm } = useForm();
  const { dataForm } = state;
  const [showJsEditor, setShowJsEditor] = useState(false);
  const { getTranslation } = useTranslator();

  function handleChangeIfCondition(value: string) {
    if (dataForm?.outputs?.[index]) {
      dataForm.outputs[index].ifCondition = value;
      updateForm(dataForm);
    }
  }

  function handleChangeConditionCode(value: string) {
    if (dataForm?.outputs?.[index]) {
      dataForm.outputs[index].conditionCode = value;
      updateForm(dataForm);
    }
    setShowJsEditor(false);
  }

  function handleEditConditionCode() {
    setShowJsEditor(true);
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        marginTop: 16,
        gap: 16,
        width: '100%',
      }}
    >
      {type === 'Api' ? (
        <>
          <TextField
            value={item.ifCondition}
            onChange={(e) => handleChangeIfCondition(e.target.value)}
            label={'Se'}
            fullWidth
            variant="standard"
          />
          <Button variant="contained" onClick={handleEditConditionCode}>
            {getTranslation('logic')}
          </Button>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            gap: 8,
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              width: '100%',
            }}
          >
            <TextField
              value={item.ifCondition}
              onChange={(e) => handleChangeIfCondition(e.target.value)}
              label={'Se'}
              fullWidth
              variant="standard"
            />
          </div>
        </div>
      )}

      <TextEditor
        code={item.conditionCode ?? ''}
        handleClose={(value) => handleChangeConditionCode(value)}
        showEditor={showJsEditor}
      />
    </div>
  );
}
