import { Debug } from 'components/Debug';
import { ModalFalazapToken } from 'components/FalazapConfiguration/ModalFalazapToken';
import AirTalkPrompt from 'components/Menus/FlowsUrls/AirTalkPrompt';
import FalazapIframe from 'components/Menus/FlowsUrls/FalazapIframe';
import ModalPublishBot from 'components/ModalPublishBot';
import ModalUsedTab from 'components/ModalUsedTab';

import FlowComponent from 'components/Flow';
import MetadataBlocksDrawer from 'components/MetadataBlocksDrawer';
import ModalAccessKeys from 'components/ModalAccessKeys';
import ModalSavedBlocks from 'components/ModalSavedBlocks';
import ModalSaveSelectedBlocks from 'components/ModalSaveSelectedBlocks';
import ModalStandardTemplates from 'components/ModalStandardTemplates';
import { useApp } from 'contexts/App/appContext';
import { useBuilder } from 'contexts/Builder';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { BuilderElementType } from 'contexts/FintalkCloud/types';
import { useFlow } from 'contexts/Flow/flowContext';
import Entities from 'pages/Entities';
import ModalContentGroupList from '../123FalaModals/ModalContentGroupList';
import ModalBotImportError from '../ModalBotImportError';
import ModalConversations from '../ModalConversations';
import ModalDeleteGroup from '../ModalDeleteGroup';
import ModalRestoreBot from '../ModalImportExportBot/ModalRestoreBot';

export function BuilderModals({
  handleDeleteGroup,
  errors,
  botToRestore,
}: {
  handleDeleteGroup: () => Promise<false | undefined>;
  errors: string[];
  botToRestore: string;
}) {
  const { builderElementToOpen, setBuilderElementToOpen } = useFintalkCloud();
  const { state } = useFlow();
  const { state: appState } = useApp();
  const { state: builderState, handleBuilderDispatch } = useBuilder();

  const {
    openAirTalk,
    openDebug,
    openFalazap,
    openModalImportError,
    groupToDelete,
  } = builderState;

  function handleCloseModal() {
    setBuilderElementToOpen(undefined);
  }

  const getBuilderElementToOpen: {
    [keyof in BuilderElementType]: JSX.Element;
  } = {
    conversationHistory: <ModalConversations handleClose={handleCloseModal} />,
    entities: <Entities />,
    keys: <ModalAccessKeys handleClose={handleCloseModal} />,
    publishHistory: <ModalRestoreBot handleClose={handleCloseModal} />,
    metadataBlocks: <MetadataBlocksDrawer handleClose={handleCloseModal} />,
    savedBlocks: <ModalSavedBlocks handleClose={handleCloseModal} />,
    standartTemplates: (
      <ModalStandardTemplates handleClose={handleCloseModal} />
    ),
    saveSelectedBlocks: (
      <ModalSaveSelectedBlocks handleClose={handleCloseModal} />
    ),
    "123Fala": (
      <ModalContentGroupList handleClose={handleCloseModal} />
    )
  };

  return (
    <>
      <FlowComponent />
      
      {builderElementToOpen && getBuilderElementToOpen[builderElementToOpen]}

      {state.openModalPublishBot && <ModalPublishBot />}

      {!!groupToDelete?.id && (
        <ModalDeleteGroup handleDeleteGroup={handleDeleteGroup} />
      )}

      {appState.renderModalUsedTab && <ModalUsedTab />}

      {openModalImportError && (
        <ModalBotImportError
          open={openModalImportError}
          errors={errors}
          botToRestore={botToRestore}
          handleClose={() => handleBuilderDispatch('closeModalImportError')}
          handleOpen={() => handleBuilderDispatch('openModalImportError')}
        />
      )}

      {openFalazap && (
        <FalazapIframe
          url={state.botsUrls.urlFalazap || ''}
          closeFalazap={() => handleBuilderDispatch('closeFalazap')}
        />
      )}

      {openDebug && (
        <Debug
          open={openDebug}
          handleCloseDebug={() => handleBuilderDispatch('closeDebug')}
        />
      )}

      {openAirTalk && (
        <AirTalkPrompt
          closeAirTalk={() => handleBuilderDispatch('closeAirTalk')}
        />
      )}

      {state.openModalFalazapToken && <ModalFalazapToken />}
    </>
  );
}
