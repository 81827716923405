import { FlowProvider } from 'contexts/Flow/flowContext';
import { LibraryProvider } from 'contexts/Library/libraryContext';
import { History } from 'history';
import { Router } from 'react-router-dom';
import MainComponent from './MainComponent';

export interface Props {
  history: History<unknown>;
  usesAirTalk?: boolean;
}

export function Builder({ history, usesAirTalk = false }: Props) {
  return (
    <FlowProvider>
      <LibraryProvider>
        <Router history={history}>
          <MainComponent usesAirTalk={usesAirTalk} />
        </Router>
      </LibraryProvider>
    </FlowProvider>
  );
}
