import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import MttButton from 'components/Material/MttButton/MttButton';
import MttTextField from 'components/Material/MttTextField/MttTextFIeld';
import styled from 'styled-components';

export const LoginContainer = styled(Grid)`
  min-height: 100vh;
  width: 100vw;
  background-color: #0e103b;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ForgotPasswordButton = styled(Typography).attrs({
  children: 'redefinir a senha',
})`
  cursor: pointer;
  width: fit-content;

  font-weight: bold !important;

  :hover {
    color: #0e103b;
  }
`;

export const ForgotEmailButton = styled.button`
  cursor: pointer;
  font-weight: bold !important;
  border: none;
  background: none;

  color: #000000e3;

  :hover {
    color: #0e103b;
  }
`;

export const FormContainer = styled(Paper)`
  width: 390px;
  padding: 48px;
`;

export const InputEmail = styled(MttTextField)``;

export const InputPassword = styled(TextField)``;

export const ButtonLoginMicrosoft = styled.button`
  color: #0f0f0f;
  padding: 4px 16px;
  border: 0;
  border-radius: 4px;

  display: flex;
  align-items: center;
  text-align: center;

  cursor: pointer;

  span {
    margin-left: 4px;
  }
`;

export const ButtonEnter = styled(MttButton)`
  color: transparent;
  height: 56px;
`;

export const FieldContainerMicrosoft = styled.div`
  display: flex;
  justify-content: center;
`;

export const FieldContainer = styled(Grid)`
  padding: 8px;
  text-align: center;

  .MuiTypography-body1 {
    button {
      font-size: 1rem !important;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }
  }
`;

export const FieldEmailContainer = styled(Grid)`
  padding: 8px;
  text-align: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  .MuiTypography-body1 {
    button {
      font-size: 1rem !important;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }
  }
`;

export const FieldButtonsContainer = styled(Grid)`
  padding: 8px;
  text-align: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  .MuiTypography-body1 {
    button {
      font-size: 1rem !important;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }
  }
`;
