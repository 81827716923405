/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  AppBar,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import ptbrLocale from 'date-fns/locale/pt-BR';
import esLocale from 'date-fns/locale/es';
import usLocale from 'date-fns/locale/en-US';

import * as S from './styles';
import Phrase from './Phrase';
import { Autocomplete } from '@material-ui/lab';
import { useFlow } from 'contexts/Flow/flowContext';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useApp } from 'contexts/App/appContext';
import { GetGroup, UpdateGroup } from 'services/FlowService';
import { GetPhrasesNotUnderstood } from 'services/ConversationService';
import { GetBotsGroups } from 'services/BotService';
import { IGroupData } from 'services/FlowService/types';
import {
  IConversationNotUnderstood,
  IGetPhrasesNotUnderstoodResponse,
  IMessagesNotUnderstood,
} from 'services/ConversationService/types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { subDays } from 'date-fns';
import EIntentType from 'enums/EIntentType';
import useTranslator from 'utils/hooks/Translator';
import i18n from 'utils/i18n';
import { usePermissions } from 'contexts/Permissions/permissionsContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class IInfo {
  bot_name = '';
  initialDate: Date | null = subDays(new Date(), 1);
  finalDate: Date | null = new Date();
}

const ModalCorrectSentences: React.FC = () => {
  const { state, dispatch, toastNotification, loadFlow } = useFlow();
  const { dispatch: dispatchApp } = useApp();
  const { state: permissionsState, hasPermissionToAction } = usePermissions();
  const { companies } = permissionsState;
  const [info, setInfo] = useState(new IInfo());
  const [viewPhrase, setViewPhrase] = useState('');
  const [loading, setLoading] = useState(false);

  const [sentence, setSentence] = useState<IGetPhrasesNotUnderstoodResponse>();
  const [groups, setGroups] = useState<IGroupData[]>([]);
  const [previousTokens, setPreviousTokens] = useState<(string | undefined)[]>(
    []
  );
  const [blocksListName, setBlocksListName] = useState<string[]>([]);
  const { getTranslation } = useTranslator();

  function getLocation() {
    if (i18n.language === 'en-US') {
      return usLocale;
    } else if (i18n.language === 'es') {
      return esLocale;
    } else return ptbrLocale;
  }

  const currLocale = getLocation();
  const agents = useMemo(() => {
    const company = companies?.find(
      (company) => company.name === state.companyName
    );

    return company
      ? company.agents
          .filter((agent) =>
            hasPermissionToAction({
              agent: agent.name,
              company: state.companyName ?? '',
              action: ['flow:read', 'flow:write'],
            })
          )
          .map((agent) => agent.name)
          .sort()
      : [];
  }, [companies, state.companyName]);

  useEffect(() => {
    if (agents && state.openModalManageCorrectSentences)
      handleChangeBot(agents[0]);
  }, [state.openModalManageCorrectSentences]);

  useEffect(() => {
    if (info.bot_name && info.initialDate && info.finalDate) {
      getPhrasesNotUnderstood();
      setViewPhrase('');
    } else {
      setLoading(false);
      setViewPhrase(getTranslation('modal.fixPhrases.infoPhrase'));
    }
  }, [info]);

  const getBackPhrase = () => {
    const tokenRender = previousTokens[previousTokens.length - 2];
    const newPreviousToken = previousTokens.slice(0, previousTokens.length - 1);
    setPreviousTokens(newPreviousToken);
    getPhrasesNotUnderstood(tokenRender, true);
  };

  const getPhrasesNotUnderstood = async (token?: string, back?: boolean) => {
    setLoading(true);
    try {
      if (!info.bot_name || !info.initialDate || !info.finalDate) {
        setLoading(false);
        return;
      }

      const response = await GetPhrasesNotUnderstood(
        {
          bot_name: info.bot_name,
          startDate: info.initialDate.setHours(0, 0, 0),
          endDate: info.finalDate.setHours(23, 59, 59),
          nextToken: token,
        },
        dispatchApp
      );

      if (!response.Success) {
        setViewPhrase(getTranslation('modal.fixPhrases.error'));
        setLoading(false);
        return;
      }

      if (response.Data.conversations.length > 0) {
        setSentence(response.Data);
        if (!back) setPreviousTokens([...previousTokens, token]);
        setViewPhrase('');
      } else {
        if (response.Data.nextToken)
          await getPhrasesNotUnderstood(response.Data.nextToken);
        else setViewPhrase(getTranslation('modal.fixPhrases.noPhrases'));
      }
    } catch (e) {
      setViewPhrase(getTranslation('modal.fixPhrases.error'));
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSentence(undefined);
    setInfo({
      bot_name: '',
      initialDate: subDays(new Date(), 1),
      finalDate: new Date(),
    });
    dispatch({ type: 'closeModalManageCorrectSentences' });
  };

  async function handleChangeBot(newValue: string | null) {
    setLoading(true);
    const botNameSelected = newValue ?? '';

    setPreviousTokens([]);

    if (botNameSelected) {
      const response = await GetBotsGroups(
        { bot_name: botNameSelected },
        dispatchApp
      );

      if (response.Success && response.Data) {
        const flows = response.Data.data;

        const principal = flows.find((group) => group === 'principal');

        if (principal) {
          const updatedGroups = [
            principal,
            ...flows
              .filter((group) => group !== 'principal')
              .map((group) => group)
              .sort(),
          ];

          await handleChangeGroup(botNameSelected, updatedGroups);
        }
      }
    }
    setInfo({ ...info, bot_name: botNameSelected });
  }

  async function handleChangeGroup(botName: string, groups: string[]) {
    const newGroups: IGroupData[] = [];
    const newBlocksListName: string[] = [];

    for (const groupName of groups) {
      const response = await GetGroup(
        { bot_name: botName, groupName },
        dispatchApp
      );

      if (!response.Success) return;

      const group = response.Data.data;
      const keys = Object.keys(group.blocks.drawflow.Home.data);

      for (const key in keys) {
        const block = group.blocks.drawflow.Home.data[Number(keys[key])];
        if (
          block.data.intentType === EIntentType.InitialFlow ||
          block.data.intentType === EIntentType.MultipleChoiceChild ||
          block.data.intentType === EIntentType.CarouselChild ||
          block.data.intentType === EIntentType.NLU
        )
          newBlocksListName.push(`${groupName}_${block.data.name}`);
      }

      newGroups.push(group);
    }
    setGroups(newGroups);
    setBlocksListName(newBlocksListName);
  }

  const handleConfirmMessage = async (
    intentDestination: string,
    message: IMessagesNotUnderstood
  ) => {
    setLoading(true);
    const [group, intention] = intentDestination.split('_');
    const groupFind = groups.find((g) => g.group_name === group);

    if (groupFind) {
      const keys = Object.keys(groupFind.blocks.drawflow.Home.data);

      for (const key in keys) {
        const block =
          groupFind.blocks.drawflow.Home.data[Number(keys[key])].data;
        if (block.name === intention) {
          if (!block.inputs?.userSentences) {
            groupFind.blocks.drawflow.Home.data[Number(keys[key])].data.inputs =
              {
                userSentences: [message.message.msg],
                variables: [],
              };
          } else {
            block.inputs.userSentences?.forEach((sentence, index) => {
              if (sentence === '') {
                if (block.inputs?.userSentences) {
                  block.inputs.userSentences[index] = message.message.msg;
                }
              }
            });
            if (
              !block.inputs?.userSentences?.find(
                (sentence) => sentence === message.message.msg
              )
            ) {
              groupFind.blocks.drawflow.Home.data[
                Number(keys[key])
              ].data.inputs?.userSentences?.push(message.message.msg);
            }
          }
          break;
        }
      }

      const response = await UpdateGroup(
        {
          bot_name: info.bot_name,
          group_name: group,
          blocks: {
            drawflow: groupFind.blocks.drawflow,
          },
        },
        dispatchApp
      );

      if (response.Success && sentence) {
        if (state.botName && state.idGroup) loadFlow(group, info.bot_name);
        toastNotification(
          'success',
          getTranslation('toast.success.update'),
          3000
        );
        handleRemoveMessage(message);
      } else {
        toastNotification(
          'error',
          response.Message || getTranslation('toast.error.update')
        );
      }
    }
    setLoading(false);
  };

  const handleRemoveMessage = async (message: IMessagesNotUnderstood) => {
    setLoading(true);
    if (sentence) {
      const conversations: IConversationNotUnderstood[] =
        sentence.conversations.map((conversation) => {
          const messages = conversation.messages.filter(
            (m) =>
              m.message !== message.message && m.timestamp !== message.timestamp
          );
          return {
            customer: conversation.customer,
            messages,
          };
        });

      const conversationsFilter = conversations.filter(
        (conversation) => conversation.messages.length > 0
      );

      if (conversationsFilter.length)
        setSentence({ ...sentence, conversations: conversationsFilter });
      else if (sentence.nextToken) {
        await getPhrasesNotUnderstood(sentence.nextToken);
      } else setViewPhrase(getTranslation('modal.fixPhrases.noPhrases'));
    }
    setLoading(false);
  };

  const phrasesOfIntent = () =>
    sentence &&
    sentence.conversations?.map((conversation: IConversationNotUnderstood) =>
      conversation.messages.map((message, index: number) => {
        return (
          <Phrase
            key={index}
            customer={conversation.customer}
            message={message}
            botName={info.bot_name}
            destinationIntents={blocksListName}
            confirmMessage={handleConfirmMessage}
            deleteMessage={handleRemoveMessage}
            index={index}
          />
        );
      })
    );

  const noView = () => (
    <S.GridInfo item>
      <InfoOutlinedIcon />
      <Typography
        variant="body1"
        style={{ marginTop: 24, textAlign: 'center' }}
      >
        {viewPhrase}
      </Typography>
    </S.GridInfo>
  );

  const renderLoading = () => (
    <S.GridInfo item>
      <Typography
        variant="body1"
        style={{ marginTop: 24, textAlign: 'center', marginBottom: 24 }}
      >
        {getTranslation('modal.fixPhrases.loading')}
      </Typography>
      <CircularProgress color="primary" style={{ marginBottom: 32 }} />
    </S.GridInfo>
  );

  if (!state.openModalManageCorrectSentences) return <></>;

  return (
    <>
      <Dialog
        open={state.openModalManageCorrectSentences}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
        fullScreen
      >
        <S.ContainerDialog>
          <AppBar>
            <Toolbar>
              <Typography variant="h6">
                {getTranslation('modal.fixPhrases.title')}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleClose()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <S.ContentContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  value={info.bot_name}
                  options={agents}
                  noOptionsText={getTranslation('noResults')}
                  renderOption={(option) => (
                    <S.AutoCompleteOption>{option}</S.AutoCompleteOption>
                  )}
                  onChange={(event: any, newValue: string | null) => {
                    handleChangeBot(newValue);
                  }}
                  fullWidth
                  disabled={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={getTranslation('agent')}
                      placeholder={getTranslation('selectAgent')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={currLocale}
                >
                  <DatePicker
                    label={getTranslation('modal.fixPhrases.initialDate')}
                    value={info.initialDate}
                    variant="inline"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    onChange={(date: Date | null) => {
                      setPreviousTokens([]);
                      setInfo({ ...info, initialDate: date });
                    }}
                    autoOk
                    disabled={loading}
                    fullWidth
                    minDate={subDays(new Date(), 14)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={currLocale}
                >
                  <DatePicker
                    label={getTranslation('modal.fixPhrases.finalDate')}
                    variant="inline"
                    format="dd/MM/yyyy"
                    value={info.finalDate}
                    inputVariant="outlined"
                    disabled={loading}
                    onChange={(date: Date | null) => {
                      setPreviousTokens([]);
                      setInfo({ ...info, finalDate: date });
                    }}
                    autoOk
                    minDate={subDays(new Date(), 30) || info.initialDate}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid style={{ padding: 20, flexDirection: 'column' }}>
              <Grid item style={{ flex: 1, paddingBottom: 16 }}>
                <Typography variant="h6" color="primary">
                  {getTranslation('modal.fixPhrases.entryPhrases')}
                </Typography>
              </Grid>
              <S.PhrasesContainer item>
                {loading
                  ? renderLoading()
                  : viewPhrase
                  ? noView()
                  : phrasesOfIntent()}
              </S.PhrasesContainer>
              {sentence && (
                <Grid item style={{ display: 'flex', justifyContent: 'end' }}>
                  <IconButton
                    size="small"
                    disabled={loading || !(previousTokens.length > 1)}
                    onClick={() => getBackPhrase()}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    disabled={
                      !sentence.nextToken || loading || viewPhrase !== ''
                    }
                    onClick={() => getPhrasesNotUnderstood(sentence.nextToken)}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </S.ContentContainer>
        </S.ContainerDialog>
      </Dialog>
    </>
  );
};

export default ModalCorrectSentences;
