import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { Delete, Forum } from '@material-ui/icons';
import MttTextField from 'components/Material/MttTextField/MttTextFIeld';
import {
  StyledGridPerguntas,
  StyledMttTypographyPerguntas,
  StyledSwitchGrid,
} from 'components/inputs/Material/VariavelIdentificacaoTextoUsuario/styles';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useForm } from 'contexts/Form/formContext';
import { DataForm, Variable } from 'models/DataFlow';
import { useCallback, useEffect, useState } from 'react';
import { GetEntities } from 'services/EntityService';
import useTranslator from 'utils/hooks/Translator';

export function Variables({
  variable,
  index: variableIndex,
  handleRemoveVariable,
}: {
  variable: Variable;
  index: number;
  handleRemoveVariable: () => void;
}) {
  const { getTranslation } = useTranslator();
  const { currentData } = useFintalkCloud();
  const { dispatch: dispatchApp } = useApp();
  const { state: stateForm, dispatch } = useForm();
  const { formErrors, dataForm } = stateForm;
  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);

  const updateForm = useCallback(
    (form: DataForm) => {
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...form,
          },
        },
      });
    },
    [dispatch]
  );

  const load = useCallback(() => {
    if (dataForm && dataForm.inputs && !dataForm.inputs.variables.length) {
      dataForm.inputs = {
        variables: [
          {
            name: '',
            vartype: '',
            questions: ['', '', ''],
            required: false,
            value: '',
            defaultValue: '',
            isList: false,
          },
        ],
      };

      updateForm(dataForm);
    }
  }, [dataForm, updateForm]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    let isMounted = true

    async function loadVariableTypes() {
      let allTypes = [
        { id: '@sys.any', name: 'qualquer-input' },
        { id: '@sys.email', name: 'input-de-email' },
        { id: '@sys.number', name: 'input-de-numero' },
        { id: '@sys.date-time', name: 'input-de-data' },
        { id: '@sys.url', name: 'input-de-url' },
      ];

      if (currentData.agentName) {
        let result = await GetEntities(
          { bot_name: currentData.agentName },
          dispatchApp
        );

        if (result.Success) {
          if (!!result.Data) {
            const entities = result.Data;

            allTypes = [
              ...allTypes,
              ...entities.map((entity) => ({
                id: entity.name,
                name: entity.name,
              })),
            ].sort(function (a, b) {
              return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
            });

            allTypes.map((type) => {
              if (type.id.substring(0, 1) !== '@') {
                const insertAt = `${type.id.slice(0, 0)}${'@'}${type.id.slice(
                  0
                )}`;
                let filteredValue = insertAt;
                type.id = filteredValue;
              }
              return allTypes;
            });
          }
        }
      }

      if (isMounted) {
        setTypes(allTypes);
      }
    }
    loadVariableTypes();

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuestion = (value: string, index: number) => {
    if (variable && dataForm && dataForm.inputs && dataForm) {
      variable.questions[index] = value;
      dataForm.inputs.variables[variableIndex] = variable;

      updateForm(dataForm);
    }
  };

  const handleShowQuestions = () => {
    if (variable && dataForm && dataForm.inputs && dataForm) {
      variable.required = !variable.required;
      dataForm.inputs.variables[variableIndex] = variable;

      updateForm(dataForm);
    }
  };

  const handleChangeInput =
    (key: 'value' | 'name' | 'vartype') =>
    (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      if (dataForm && dataForm.inputs && variable) {
        const value = String(e.target.value);

        if (dataForm.dataBlockly) {
          dataForm.dataBlockly.payload =
            dataForm.dataBlockly?.payload.replaceAll(
              `$${variable['name']}`,
              `$${value}`
            );
        }

        if (key === 'name' && !variable.isValueEdited) {
          variable['value'] = '$' + value;
        }
        variable[key] = value;
        dataForm.inputs.variables[variableIndex] = variable;

        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    };

  const renderQuestions = () => {
    return (
      <>
        <StyledSwitchGrid>
          <Switch
            checked={!!variable?.required}
            onChange={handleShowQuestions}
            color="primary"
          />
          <StyledMttTypographyPerguntas />
        </StyledSwitchGrid>
        <StyledGridPerguntas showgrid={variable?.required || undefined}>
          {variable?.questions.map((perg, index) => {
            return (
              <TextField
                key={index}
                onChange={(e) => handleQuestion(e.target.value, index)}
                value={perg}
                label="Escreva uma pergunta"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Forum color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            );
          })}
        </StyledGridPerguntas>
      </>
    );
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <MttTextField
            variant="standard"
            label={getTranslation('name')}
            value={variable?.name
              .replace(/[^A-Z a-z 0-9 \\-]/g, '')
              .replace(/\s/g, '')}
            onChange={handleChangeInput('name')}
          />
          <FormControl variant="standard">
            <InputLabel id="groups-simple-select-outlined-label">
              {getTranslation('types')}
            </InputLabel>
            <Select
              labelId="groups-simple-select-outlined-label"
              id="groups-simple-select-outlined"
              value={variable?.vartype}
              onChange={handleChangeInput('vartype')}
              label={getTranslation('types')}
              fullWidth
              style={{ minWidth: '60px' }}
              error={
                variable?.name !== '' &&
                variable?.vartype === '' &&
                !!formErrors['varType']
              }
            >
              <MenuItem value="">
                <em>{getTranslation('selectType')}</em>
              </MenuItem>
              {types.map((type, index) => (
                <MenuItem key={index} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {variable?.name && variable?.vartype === '' && (
              <FormHelperText error>{formErrors['varType']}</FormHelperText>
            )}
          </FormControl>
        </div>

        <IconButton onClick={handleRemoveVariable}>
          <Delete />
        </IconButton>
      </div>
      {renderQuestions()}
    </div>
  );
}
