import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Slide,
  Button,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { CircularProgress } from '@material-ui/core';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import RefreshIcon from '@material-ui/icons/Refresh';

import * as S from './styles';
import { useFlow } from 'contexts/Flow/flowContext';
import {
  CreateIdentityToken,
  GetCredentials,
  GetIdentityToken,
  UpdateIdentityToken,
} from 'services/FalazapTokenService';
import { useApp } from 'contexts/App/appContext';
import { FalazapCredentials } from 'services/FalazapTokenService/types';
import useTranslator from 'utils/hooks/Translator';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Credentials = Pick<FalazapCredentials, 'access_key' | 'secret_key'>;

export function ModalFalazapToken() {
  const { dispatch: dispatchApp } = useApp();
  const { dispatch, state, toastNotification } = useFlow();
  const { getTranslation } = useTranslator();
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [credentials, setCredentials] = useState<Credentials | undefined>();
  const botName = state.botName;
  const classes = S.useStyles();

  function handleClose() {
    dispatch({
      type: 'closeModalFalazapToken',
    });
  }

  const getToken = useCallback(
    async (rCredentials: Credentials) => {
      if (!botName || !rCredentials) return;
      setLoadingMessage(getTranslation('modal.falazapToken.searchingToken'));

      setLoading(true);
      try {
        const response = await GetIdentityToken(
          { botName, ...rCredentials },
          dispatchApp
        );

        if (response.Success) {
          const rToken = response.Data.data.token;
          setToken(rToken);
        } else {
          toastNotification(
            'error',
            getTranslation('toast.error.identificationTokenSearch')
          );
        }
      } catch (e) {
        const error = e as Error;
        toastNotification(
          'error',
          `${getTranslation(
            'toast.error.identificationTokenSearch'
          )}, ${error.message.toString()}`
        );
      }

      setLoading(false);
    },
    [botName, dispatchApp, getTranslation, toastNotification]
  );

  const init = useCallback(async () => {
    if (!botName) return;
    try {
      const response = await GetCredentials(botName, dispatchApp);
      if (response.Success) {
        const { access_key, secret_key } = response.Data.data;
        setCredentials({ access_key, secret_key });
        await getToken({ access_key, secret_key });
      } else {
        toastNotification(
          'error',
          getTranslation('toast.error.tokenCredentialsSearch')
        );
      }
    } catch (e) {
      const error = e as Error;
      toastNotification(
        'error',
        `${getTranslation(
          'toast.error.tokenCredentialsSearch'
        )}, ${error.message.toString()}`
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  async function handleCreateToken() {
    if (!botName || !credentials) return;
    try {
      setLoadingMessage(getTranslation('modal.falazapToken.generatingToken'));

      setLoading(true);
      const response = await CreateIdentityToken(
        { botName, ...credentials },
        dispatchApp
      );
      if (response.Success) {
        const rToken = response.Data.data.token;
        setToken(rToken);
        toastNotification(
          'success',
          getTranslation('toast.success.identificationTokenCreated')
        );
      } else {
        toastNotification(
          'error',
          getTranslation('toast.error.identificationTokenCreated')
        );
      }
    } catch (e) {
      const error = e as Error;
      toastNotification(
        'error',
        `${getTranslation(
          'toast.error.identificationTokenCreated'
        )}, ${error.message.toString()}`
      );
    }

    setLoading(false);
  }

  async function handleUpdateToken() {
    if (!botName || !credentials) return;

    try {
      setLoadingMessage(getTranslation('modal.falazapToken.updatingToken'));
      setLoading(true);
      const response = await UpdateIdentityToken(
        { botName, ...credentials },
        dispatchApp
      );
      if (response.Success) {
        const rToken = response.Data.data.token;
        setToken(rToken);
        toastNotification(
          'success',
          getTranslation('toast.success.identificationTokenUpdated')
        );
      } else {
        toastNotification(
          'error',
          getTranslation('toast.error.identificationTokenUpdated')
        );
      }
    } catch (e) {
      const error = e as Error;
      toastNotification(
        'error',
        `${getTranslation(
          'toast.error.identificationTokenUpdated'
        )}, ${error.message.toString()}`
      );
    }
  }

  async function handleCopyToken() {
    await navigator.clipboard.writeText(token);
    toastNotification(
      'success',
      getTranslation('modal.falazapToken.tokenCopied')
    );
  }

  if (!state.openModalFalazapToken) return null;

  const tokenDoesNotExistsBody = () =>
    loading ? (
      <S.GeneratingTokenContainer>
        <Typography variant="body1" style={{ margin: 16, textAlign: 'center' }}>
          {loadingMessage}
        </Typography>
        <CircularProgress color="inherit" size={18} />
      </S.GeneratingTokenContainer>
    ) : (
      <S.GridInfo>
        <InfoOutlinedIcon />
        <Typography variant="body1">
          {getTranslation('modal.falazapToken.tokenNotCreatedMessage')}
          <br />
          {getTranslation('modal.falazapToken.generateTokenMessage')}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleCreateToken}>
          {getTranslation('create')}
        </Button>
      </S.GridInfo>
    );

  const tokenExistsBody = () => (
    <S.TokenExistsContainer>
      <Typography variant="h6" color="primary">
        Token
      </Typography>
      <Typography>{token}</Typography>
      <S.ButtonsContainer>
        <Button variant="contained" onClick={handleCopyToken}>
          {getTranslation('copy')}
          <FileCopyIcon fontSize="small" />
        </Button>
        <Button variant="contained" color="primary" onClick={handleUpdateToken}>
          {getTranslation('refresh')}
          <RefreshIcon fontSize="small" />
        </Button>
      </S.ButtonsContainer>
    </S.TokenExistsContainer>
  );

  return (
    <Dialog
      open={state.openModalFalazapToken}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
    >
      <S.ContainerDialog>
        <AppBar>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">
              {getTranslation('modal.falazapToken.title')}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </S.ContainerDialog>
      <S.Container>
        {!token ? tokenDoesNotExistsBody() : tokenExistsBody()}
      </S.Container>
    </Dialog>
  );
}
