import { DataFlowObject } from 'contexts/Flow/types';

export default class UpdateNodesPositionController {
  protected drawflow: DataFlowObject;

  constructor(drawflow: DataFlowObject) {
    this.drawflow = drawflow;
  }

  updatePosition(nodesIds: number[], offsetX: number, offsetY: number) {
    const data = this.drawflow.Home.data;
    for (const nodeId of nodesIds) {
      const { pos_x, pos_y } = data[nodeId];
      data[nodeId].pos_x = pos_x - offsetX;
      data[nodeId].pos_y = pos_y - offsetY;
    }
  }

  resetPosition() {
    const data = this.drawflow.Home.data;
    const keys = Object.keys(data).map((key) => Number(key));
    const { pos_x, pos_y } = this.getRelativePosition();
    const flow = document.getElementById('drawflow');

    this.updatePosition(
      keys,
      pos_x -
        -(flow?.clientWidth ? Math.round(flow.clientWidth / 2) - 300 : 32),
      pos_y
    );
  }

  getRelativePosition() {
    const block = this.drawflow.Home.data;
    const keys = Object.keys(block);
    const positionsX = keys.map((key) => block[Number(key)].pos_x);
    const positionsY = keys.map((key) => block[Number(key)].pos_y);
    return this.getBiggestPositions(positionsX, positionsY);
  }

  getBiggestPositions(pos_x: number[], pos_y: number[]) {
    if (!pos_x.length || !pos_y.length) return { pos_x: 0, pos_y: 0 };
    const biggestPos_x = Math.min(...pos_x);
    const biggestPos_y = Math.min(...pos_y);
    return { pos_x: biggestPos_x, pos_y: biggestPos_y };
  }

  getDrawflow() {
    return this.drawflow;
  }
}
