import { Tooltip, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import { TranslatedTooltipProps } from "./types";

export function TranslatedTooltip({
  i18nKey,
  components = {},
  Icon,
  placement = 'top'
}: TranslatedTooltipProps) {
  const IconComponent = typeof Icon === 'function' ? <Icon /> : Icon;

  return (
    <Tooltip
      title={
        <Typography variant="body2">
          <Trans i18nKey={String(i18nKey)} components={components} />
        </Typography>
      }
      placement={placement}
      arrow
    >
      {IconComponent}
    </Tooltip>
  );
}
