import { IAppContextActions } from 'contexts/App/types';
import { json } from 'services/RequestService';
import { IBaseResponse } from 'services/RequestService/Response';

interface IGetTokenResponse {
  token: string;
  id: string;
  dashboard_id: string;
}

export const GetSupersetToken = async (
  agent: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetTokenResponse>> => {
  const action = `dash/${agent}/token`;
  return await json<IGetTokenResponse>('GET', action, dispatch);
};
