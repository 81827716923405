import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;

  .MuiSelect-select.MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    width: 100px;

    background-color: #f2f2f2;
  }
`;
