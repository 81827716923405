import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const Container = styled(MttGrid)``;

export const ContainerOptions = styled(MttGrid)<{ size?: number }>`
  padding: ${(props) =>
    props.size && props.size > 1 ? '10px 10px 10px 20px' : '10px 20px 10px'};

  max-height: 500px;
`;

export const ContentOptions = styled(MttGrid)`
  display: flex;

  margin-bottom: 16px;

  .MuiAutocomplete-fullWidth {
    background-color: #fff;
  }

  .MuiChip-root {
    border-radius: 4px;
  }
`;
