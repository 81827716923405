import React from 'react';
import { History } from 'history';
import { AppProvider } from './contexts/App/appContext';
import ThemeCustom from './themes/ThemeCustom';
import GlobalStyles from './styles';
import './utils/i18n';
import { PermissionsProvider } from 'contexts/Permissions/permissionsContext';
import { FintalkCloud } from 'views/FintalkCloud';
import { FintalkCloudContextProvider } from 'contexts/FintalkCloud/fintalkCloudContext';
import PrivateRoute from 'routes/PrivateRoute';
import Login from 'pages/Login';
import { Router, Switch } from 'react-router-dom';
import { ToastContextProvider } from 'contexts/ToastContext';
import { OAuthRedirect } from 'pages/OAuthRedirect';

type Props = {
  history: History<unknown>;
};

function App(props: Props) {
  return (
    <>
      <GlobalStyles />
      <AppProvider>
        <PermissionsProvider>
          <ThemeCustom>
            <ToastContextProvider>
              <Router history={props.history}>
                <Switch>
                  <PrivateRoute
                    path="/oauth"
                    Component={OAuthRedirect}
                    exact={false}
                  />
                  <PrivateRoute path="/login" Component={Login} exact />
                  <FintalkCloudContextProvider history={props.history}>
                    <PrivateRoute path="/" Component={FintalkCloud} exact />
                  </FintalkCloudContextProvider>
                </Switch>
              </Router>
            </ToastContextProvider>
          </ThemeCustom>
        </PermissionsProvider>
      </AppProvider>
    </>
  );
}

export default App;
