import React, { useCallback, useEffect } from 'react';
import * as S from './styles';
import AddIcon from '@material-ui/icons/Add';
import MttButton from 'components/Material/MttButton/MttButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useForm } from 'contexts/Form/formContext';
import { Output } from 'models/DataFlow';
import TextField from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import EListType from 'enums/EListType';

export default function CarouselContent() {
  const { state, dispatch } = useForm();
  const { dataForm, formErrors } = state;

  const load = useCallback(() => {
    if (dataForm) {
      if (!dataForm.inputs) {
        dataForm.inputs = {
          userSentences: [''],
          variables: [
            {
              name: '',
              vartype: '',
              questions: ['', '', ''],
              required: false,
              defaultValue: '',
              isList: false,
              value: '',
            },
          ],
        };
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
      if (!dataForm.outputs || !dataForm.outputs.length) {
        dataForm.outputs = [{ title: '' }];
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
      if (!dataForm.sendUser) {
        dataForm.sendUser = {
          message: '',
        };
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChange = (
    key: keyof Omit<Output, 'isChip'>,
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!!dataForm && dataForm.outputs) {
      const output = dataForm.outputs[index];
      output[key] = e.target.value;
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleRemoveChoise = (index: number) => {
    if (!!dataForm && dataForm.outputs) {
      const outputs = dataForm.outputs.filter((_, i) => i !== index);
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
            outputs,
          },
        },
      });
    }
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!dataForm && !!dataForm.sendUser) {
      const { value } = e.target;

      dataForm.sendUser.message = value;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const renderCarouselItem = (choise: Output, index: number) => {
    return (
      <S.GridContainerInfos key={`CI${index}`}>
        <S.GridInfos>
          <S.GridInfosFirstLine>
            <S.StyledMttTextField
              variant="outlined"
              label="Titulo"
              inputProps={{ maxLength: 50 }}
              value={choise.title}
              disabled={choise.title.toLowerCase() === 'outros'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange('title', index, e)
              }
              error={!!formErrors[`outputs[${index}].title`]}
              helperText={formErrors[`outputs[${index}].title`]}
            />
            <S.StyledMttTextField
              variant="outlined"
              disabled={choise.title.toLowerCase() === 'outros'}
              label="URL da Imagem"
              value={choise.url}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange('url', index, e)
              }
            />
          </S.GridInfosFirstLine>
          <S.StyledMttTextField
            variant="outlined"
            disabled={choise.title.toLowerCase() === 'outros'}
            label="Descrição"
            inputProps={{ maxLength: 40 }}
            value={choise.description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('description', index, e)
            }
          />
        </S.GridInfos>
        {choise.title.toLowerCase() !== 'outros' && (
          <S.StyledDeleteGrid onClick={() => handleRemoveChoise(index)}>
            <DeleteIcon />
          </S.StyledDeleteGrid>
        )}
      </S.GridContainerInfos>
    );
  };

  const handleAddChoise = () => {
    if (dataForm) {
      if (dataForm.outputs) {
        const other = dataForm.outputs.find(
          (o) => o.title.toLowerCase() === 'outros'
        );
        const newOutputs = dataForm.outputs.filter(
          (o) => o.title.toLowerCase() !== 'outros'
        );
        newOutputs.push({
          title: '',
        });
        if (other) {
          newOutputs.push(other);
        }
        dataForm.outputs = newOutputs;
      } else {
        dataForm.outputs = [{ title: '' }];
      }
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeOrientation = () => {
    if (dataForm) {
      dataForm.listType =
        dataForm.listType === EListType.Horizontal
          ? EListType.Vertical
          : EListType.Horizontal;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  return (
    <S.Container>
      <S.StyledMttTypography />
      <S.ContentBody>
        <S.GridTextField>
          <TextField
            fullWidth
            label="Escreva uma frase"
            variant="outlined"
            name="inputPhrases"
            value={dataForm?.sendUser?.message || ''}
            onChange={handleChangeTitle}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FormatQuoteIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </S.GridTextField>
        <S.RadioGrid>
          <S.BoxHeader>
            <RadioGroup
              row
              value={dataForm?.listType === EListType.Vertical ? true : false}
              onChange={handleChangeOrientation}
            >
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="Carrossel horizontal"
              />
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Carrossel Vertical"
              />
            </RadioGroup>
          </S.BoxHeader>
          <MttButton
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddChoise}
          >
            Adicionar Elemento
          </MttButton>
        </S.RadioGrid>

        {dataForm?.outputs?.map(renderCarouselItem)}
      </S.ContentBody>
    </S.Container>
  );
}
