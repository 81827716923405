import { Grid, InputLabel, makeStyles, Theme, withTheme } from '@material-ui/core';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';

export const DialogContainer = styled(MttGrid)`
  width: 100%;
  height: 100%;

  position: relative;

  .MuiAppBar-colorPrimary {
    background-color: #0e103b;
    position: absolute;
  }
`;

export const ContentContainer = styled(Grid)`
  padding: 32px 24px;
`;

export const DataSourceFileInputLabel = withTheme(styled(InputLabel)`
  width: 90%;
  height: 200px;
  border: 2px dashed ${props => props.theme.palette.primary.main};
  border-radius: 12px;
  margin: 12px auto;
  background-color: #D9D9D9;
`);

export const FooterContainer = styled(Grid)`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E3E3E3;
  padding: 0 40px;
`;

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderRadius: 12,
  },
  title: {
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    flex: 1,
  },
  footerButtons: {
    fontWeight: 'bold',

    '.Mui-disabled': {
      border: 0,
    }
  }
}));
