import { IAppContextActions } from 'contexts/App/types';
import { json } from 'services/RequestService';
import { IBaseResponse } from 'services/RequestService/Response';
import {
  ICreateProjectRequest,
  IDeleteProjectRequest,
  IGetAllProjectsResponse,
  IGetProjectRequest,
  IProjectResponse,
  IUpdateProjectRequest,
} from './types';

const companyUrl = 'company';
const baseUrl = 'projects';

export async function CreateProject(
  request: ICreateProjectRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IProjectResponse>> {
  const action = `${companyUrl}/${request.company}/${baseUrl}`;
  return await json<IProjectResponse>('POST', action, dispatch, request);
}

export async function GetAllProjects(
  companyName: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetAllProjectsResponse>> {
  const action = `${companyUrl}/${companyName}/${baseUrl}`;
  return await json<IGetAllProjectsResponse>('GET', action, dispatch);
}

export async function GetProject(
  { companyName, projectName }: IGetProjectRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IProjectResponse>> {
  const action = `${companyUrl}/${companyName}/${baseUrl}/${projectName}`;
  return await json<IProjectResponse>('GET', action, dispatch);
}

export async function UpdateProject(
  request: IUpdateProjectRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IProjectResponse>> {
  const action = `${companyUrl}/${request.company}/${baseUrl}/${request._id}`;
  return await json<IProjectResponse>('PATCH', action, dispatch, request);
}

export async function DeleteProject(
  request: IDeleteProjectRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IProjectResponse>> {
  const action = `company/${request.company}/projects/${request._id}`;
  return await json<IProjectResponse>('DELETE', action, dispatch);
}
