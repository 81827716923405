import * as yup from "yup";
import { IResultError } from "./ResultError";

export interface IUser {
  email: string;
  password: string;
	error?: {
		email: string;
		password: string;
	}
}

export const userValidation = async (user: IUser) => {
  const emailResult = await userValidator(user.email, user.error?.email || '*Email obrigatório');
  const passwordResult = await passwordValidator(user.password, user.error?.password || '*Senha obrigatória');
  const isValid = emailResult.isValid && passwordResult.isValid;
  const errors = { ...emailResult.errors, ...passwordResult.errors };
  return { isValid, errors };
};

export const emailValidator = async (email: string) => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("*E-mail obrigatório.")
      .email("*E-mail inválido."),
  });

  const promiseResult = new Promise<IResultError<IUser>>((resolve) => {
    schema
      .validate({ email }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { email: "" } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { email: err.errors[0] } });
      });
  });
  return await promiseResult;
};

export const userValidator = async (email: string, error: string) => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .required(error)
  });

  const promiseResult = new Promise<IResultError<IUser>>((resolve) => {
    schema
      .validate({ email }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { email: "" } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { email: err.errors[0] } });
      });
  });
  return await promiseResult;
};

const passwordValidator = async (password: string, error: string) => {
  const schema = yup.object().shape({
    password: yup.string().required(error),
  });

  const promiseResult = new Promise<IResultError<IUser>>((resolve) => {
    schema
      .validate({ password }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { password: "" } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { password: err.errors[0] } });
      });
  });
  return await promiseResult;
};
