import { useState } from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { useCompanies } from 'contexts/Company';
import useTranslator from 'utils/hooks/Translator';

import {
  CustomPaper,
  HoverableRow,
} from 'components/ModalManageCompanies/styles';
import { useApp } from 'contexts/App/appContext';
import { emptyProject } from 'contexts/Company/types';
import { GetProject } from 'services/CompaniesService/Projects';
import { TableContainer } from '../styles';
import { ManageProject } from './ManageProject';
import { ProjectHeader } from './styles';

export function ManageProjects() {
  const { dispatch: dispatchApp } = useApp();
  const { state, setCompany, setProject } = useCompanies();
  const { company } = state;
  const projects = company.projects;
  const { getTranslation } = useTranslator();
  const [editProject, setEditProject] = useState(false);

  async function handleRemoveProject(projectName: string) {
    const projectToRemove = projects?.find(
      (project) => project === projectName
    );
    if (!projectToRemove) return;

    const cloneGroups = projects;
    if (!cloneGroups) return;

    const fixedGroups = cloneGroups.filter(
      (project) => project !== projectName
    );
    setCompany({ ...company, projects: fixedGroups });
  }

  function handleClose() {
    setProject(emptyProject, true);
    setEditProject(false);
  }

  async function handleEditProject(projectName: string) {
    const response = await GetProject(
      { projectName, companyName: company.name },
      dispatchApp
    );
    if (response.Success) {
      setProject(response.Data, true);
      setEditProject(true);
    }
  }

  return (
    <CustomPaper>
      <ProjectHeader>
        <h6>
          {getTranslation('modal.manageCompany.project.manageCompanyProjects')}
        </h6>
        <IconButton
          disabled={!company._id}
          onClick={() => setEditProject(true)}
        >
          <AddIcon />
        </IconButton>
      </ProjectHeader>
      {company.projects && company.projects.length > 0 ? (
        <TableContainer>
          <Table
            size="small"
            aria-label="Lista de projetos"
            style={{ height: 'auto' }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  {getTranslation('name')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {company.projects?.map((project) => (
                <HoverableRow style={{ width: '100%', height: '80px' }} key={project}>
                  <TableCell>{project}</TableCell>
                  <TableCell style={{ textAlign: 'right', padding: '6px 0px' }}>
                    <IconButton
                      aria-label="edita um projeto"
                      onClick={() => handleEditProject(project)}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      aria-label="remove um projeto"
                      onClick={() => handleRemoveProject(project || '')}
                    >
                      <CloseIcon />
                      
                    </IconButton>
                  </TableCell>
                </HoverableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <h6>{getTranslation('modal.manageCompany.project.notFound')}</h6>
      )}

      {editProject && (
        <ManageProject editProject={editProject} handleClose={handleClose} />
      )}
    </CustomPaper>
  );
}
