import {
  AppBar,
  CircularProgress,
  Dialog,
  IconButton,
  TextField,
  Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useTranslator from 'utils/hooks/Translator';
import { Container, ContainerDialog } from '../../../../styles';

import {
  CustomPaper,
  FormsContainer,
} from 'components/ModalManageCompanies/styles';
import { useApp } from 'contexts/App/appContext';
import { useCompanies } from 'contexts/Company';
import { useToast } from 'contexts/ToastContext';
import { useEffect, useMemo, useState } from 'react';
import { CreateGroup, UpdateGroup } from 'services/CompaniesService/Groups';
import { GetProject } from 'services/CompaniesService/Projects';
import { arraysAreEqual } from 'utils/arraysAreEqual';
import * as SSSSS from '../../../../../styles';
import { ManageAgents } from '../../../../ManageAgents';
import { ManageUsers } from '../../../../ManageUsers';
import { ManageActions } from './ManageActions';
import { ChildrenContainer } from './styles';

interface Props {
  handleClose: () => void;
  editGroup: boolean;
}
interface Errors {
  name: string;
}

export function ManageGroup({ handleClose, editGroup }: Props) {
  const { toastNotification } = useToast();
  const { dispatch: dispatchApp } = useApp();
  const { state, dispatch, setCompany } = useCompanies();
  const { group, company, project, projectCopy } = state;
  const [error, setError] = useState<Errors>({ name: '' });
  const [loading, setLoading] = useState(false);

  const classes = SSSSS.useStyles()

  const [groupCopy, setGroupCopy] = useState(group);
  const isEdit = !!group._id;
  const { getTranslation } = useTranslator();

  const [agents, setAgents] = useState(['']);
  const [users, setUsers] = useState(['']);

  const hasChanges = useMemo(() => {
    return (
      group.name !== groupCopy.name ||
      !arraysAreEqual(groupCopy.users, group.users) ||
      !arraysAreEqual(groupCopy.agents, group.agents) ||
      !arraysAreEqual(groupCopy.actions, group.actions)
    );
  }, [group, groupCopy]);

  async function handleGetProject() {
    const response = await GetProject(
      { companyName: company.name, projectName: project.name },
      dispatchApp
    );
    if (response.Success) {
      setUsers(response.Data.users);
      setAgents(response.Data.agents);
    } else
      toastNotification({
        type: 'error',
        message:
          'Ocorreu um erro ao buscar os grupos, verifique e tente novamente',
      });
  }

  function handleValidate(): boolean {
    const { name } = groupCopy;
    if (!name) {
      setError((prev) => ({
        ...prev,
        name: getTranslation('validations.required', {
          field: getTranslation('name'),
        }),
      }));
      return true;
    }
    return false;
  }
  useEffect(
    () => {
      handleGetProject();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editGroup]
  );

  useEffect(() => {
    if (!isEdit) {
      dispatch({
        type: 'setGroup',
        data: {
          group: {
            ...group,
            project: project.name || '',
            company: company.name || '',
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleFetchAndSetGrups() {
    const response = await GetProject(
      { companyName: company.name, projectName: project.name },
      dispatchApp
    );
    if (response.Success) {
      dispatch({
        type: 'setProject',
        data: { project: { ...project, groups: response.Data.groups } },
      });
      dispatch({
        type: 'setProjectCopy',
        data: { projectCopy: { ...projectCopy, groups: response.Data.groups } },
      });
    } else
      toastNotification({
        type: 'error',
        message:
          'Ocorreu um erro ao buscar os grupos, verifique e tente novamente',
      });
  }

  async function handleCreateOrUpdateGroup() {
    if (handleValidate()) return;
    if (!!groupCopy.name && !!group.project) {
      setGroupCopy((prev) => ({ ...prev, name: prev.name.trim() }));
      setLoading(true);
      const response = await getResponse();
      if (response.Success) {
        const companyGroups = company.groups;
        if (!companyGroups) return;

        const currentGroupId = companyGroups.findIndex(
          (g) => g._id === group._id
        );

        if (currentGroupId !== -1) {
          companyGroups[currentGroupId] = { ...group, name: groupCopy.name };
          setCompany({ ...company, groups: companyGroups });
          setCompany({ ...company, groups: companyGroups });
        } else {
          setCompany({ ...company, groups: [...companyGroups, group] });
        }
        await handleFetchAndSetGrups();
        toastNotification({
          type: 'success',
          message: `Grupo ${isEdit ? 'atualizado' : 'criado'} com sucesso.`,
        });

        handleClose();
      } else {
        toastNotification({
          type: 'error',
          message: `Ocorreu um erro ao ${
            isEdit ? 'atualizar' : 'criar'
          } o grupo, verifique e tente novamente.`,
        });
      }
      setLoading(false);
    } else {
      toastNotification({
        type: 'error',
        message: `Ocorreu um erro ao ${
          isEdit ? 'atualizar' : 'criar'
        } o grupo, verifique e tente novamente.`,
      });
    }
  }

  async function getResponse() {
    if (!isEdit)
      return await CreateGroup(
        {
          ...group,
          name: groupCopy.name.trim(),
          projects: [group.project],
          company: group.company,
        },
        dispatchApp
      );
    else
      return await UpdateGroup(
        {
          ...group,
          name: groupCopy.name.trim(),
          company: group.company,
        },
        dispatchApp
      );
  }
  function handleChangeName(name: string) {
    if (!name.includes('/')) {
      setError((prev) => ({ ...prev, name: '' }));
      setGroupCopy((prev) => ({ ...prev, name }));
    }
  }
  return (
    <Dialog
      open
      fullScreen
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog >
        <AppBar>
          <Toolbar className={classes.toolbar}>
            <SSSSS.ModalTitle style={{ fontWeight: 600 }}>
              {isEdit ? getTranslation('edit') : getTranslation('create')}{' '}
              {getTranslation('group')}
            </SSSSS.ModalTitle>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container>
          <FormsContainer onSubmit={(e) => e.preventDefault()}>
            <CustomPaper>
              <TextField
                error={!!error.name}
                label={getTranslation('name')}
                value={groupCopy.name}
                onChange={(e) => handleChangeName(e.target.value)}
                helperText={error.name}
              />
            </CustomPaper>

            <ChildrenContainer>
              <ManageUsers
                currentUsers={group.users || []}
                sourceUsers={users || []}
                objectToEdit="group"
              />
              <ManageAgents
                objectToEdit="group"
                sourceAgents={agents}
                currentAgents={group.agents}
              />
              <ManageActions />
            </ChildrenContainer>
          </FormsContainer>
        </Container>

        <SSSSS.ButtonContainer>
          <SSSSS.Button
            disabled={!hasChanges}
            style={{ letterSpacing: 2 }}
            variant="contained"
            color="secondary"
            onClick={handleCreateOrUpdateGroup}
          >
            {getTranslation('save')}
            {loading && (
              <CircularProgress
                size={18}
                color="inherit"
                style={{ marginLeft: '8px' }}
              />
            )}
          </SSSSS.Button>
        </SSSSS.ButtonContainer>
      </ContainerDialog>
    </Dialog>
  );
}
