import styled from 'styled-components';
import MttTypography from 'components/Material/MttTypography/MttTypography';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import TextField from '@material-ui/core/TextField/TextField';

export const GridContainer = styled(MttGrid)`
  margin: 24px 42px 0;
`;

export const StyledMttTypographyFrase = styled(MttTypography).attrs({
  children: 'Google Sheet',
  variant: 'h6',
  color: 'primary',
})``;

export const StyledMttTypographyFooter = styled(MttTypography).attrs({
  children: 'Clique aqui para ver como se ativa essa funcionalidade',
  variant: 'h6',
  color: 'primary',
})``;

export const StyledTitleFooterClick = styled.div`
  font-size: 20px;
  color: #0e103b;
  font-weight: bold;
  margin-right: 4px;
`;

export const ContainerTitleFooter = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const StyledTitleFooter = styled.div`
  font-size: 20px;
  color: #434343;
`;

export const ContentGrid = styled(MttGrid)`
  width: calc(100% - 32px);
  max-height: 192px;

  padding: 16px;

  border-radius: 4px;

  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: auto;

  .MuiTypography-body1 {
    color: #0e103b;
    font-weight: 500;
  }
`;

export const ContentTitlesGrid = styled(MttGrid)`
  display: flex;

  margin-top: 8px;

  background-color: #f2f2f2;

  .MuiInputBase-input {
    background-color: #ffffff;
  }

  > div:first-child {
    background-color: #ffffff;
  }
`;

export const ContainerIcon = styled.div`
  .MuiSvgIcon-root {
    color: #00c914;
  }

  .icon-spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {transform: rotate(360deg)}
  }
`;

export const ContainerButton = styled(MttGrid)`
  display: flex;
  width: 200px;
  margin-left: 12px;
`;

export const StyledMttTextField = styled(TextField)``;

const styles = {
  Container: styled.div`
    border: 2px solid #cfc5e2;
    border-radius: 3rem;
    display: flex;
    margin: 1rem;
  `,
  ChoicesContent: styled.div`
    background: #b6bad9;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    height: 20vh;
    overflow-y: scroll;
  `,
  ChoicesLine: styled.div`
    span {
      font-size: 2em;
    }
  `,
  ButtonsContent: styled.div`
    span {
      font-weight: 600;

      i {
        font-style: normal;
        font-size: 0.8em;
      }
    }
  `,
};

export default styles;
