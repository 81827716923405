/* eslint-disable no-extend-native */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { ModalCustomerProps } from './types';
import { GetConversationHistory } from 'services/ConversationService';
import { ConversationMessage, Msgs } from 'services/ConversationService/types';
import { Message, MessageType } from 'models/Conversation';
import {
  AppBar,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import * as S from './style';
import { useApp } from 'contexts/App/appContext';
import { format, isToday } from 'date-fns';
import useTranslator from 'utils/hooks/Translator';
import { isYesterday } from 'date-fns/esm';
import { ptBR, enUS, es } from 'date-fns/locale';
import i18n from 'utils/i18n';
import { Filter } from '../ModalCustomer/Filter';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useFlow } from 'contexts/Flow/flowContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalMisunderstoodPhrases(props: ModalCustomerProps) {
  const classes = S.useStyles();
  const { toastNotification } = useFlow();
  const { dispatch: dispatchApp } = useApp();
  const { open, bot_name, customer, handleClose, messageSelected } = props;
  const [conversation, setConversation] = useState<ConversationMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [paginationToken, setPaginationToken] = useState('');
  const [loadLast, setLoadLast] = useState(false);
  const [loadOlder, setLoadOlder] = useState(false);

  const dateConfigString =
    i18n.language === 'en-US' ? 'dd MMM yyy' : "dd 'de' MMMM 'de' yyyy";

  const currLocale =
    i18n.language === 'en-US' ? enUS : i18n.language === 'pt-BR' ? ptBR : es;

  const { getTranslation } = useTranslator();

  useEffect(() => {
    window.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'f' && e.ctrlKey === true) {
        e.preventDefault();
        setOpenFilter(true);
      }
    });
  }, []);

  useEffect(() => {
    async function load() {
      setLoading(true);

      const resultConversation = await GetConversationHistory(
        {
          bot_name,
          customer,
          target_date: `${messageSelected?.created_at}`,
        },
        dispatchApp
      );
      if (resultConversation.Success) {
        const newConversation: ConversationMessage[] = [];
        resultConversation.Data.messages.messages.forEach((m) => {
          newConversation.push(m);
        });
        setConversation(newConversation);
        setPaginationToken(resultConversation.Data.messages.cursor);
      } else {
        setConversation([]);
      }
      setLoading(false);

      if (messageSelected) {
        const selectedGrid = document.getElementById(
          `${messageSelected.message.msg}-${messageSelected.message.timestamp}`
        );

        if (selectedGrid)
          selectedGrid.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    if (open) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, bot_name, customer]);

  const close = () => {
    handleClose();
  };

  function getMessageClass(row: Msgs) {
    let currClass = `${classes.msgContainer}`;
    if (row.type === 'outgoing') currClass += ` ${classes.botMsg}`;
    else currClass += ` ${classes.userMsg}`;

    if (
      messageSelected &&
      row.msg === messageSelected.message.msg &&
      row.timestamp === messageSelected.timestamp
    )
      currClass += ` ${classes.selectedMsg}`;

    return currClass;
  }

  function groupMessagesByDay(
    currDate: number | Date,
    messagesByEveryDay: { day: string; rows: Msgs[] }[],
    row: Msgs
  ) {
    if (isToday(currDate)) {
      const index = messagesByEveryDay.findIndex(
        (m: { day: string }) => m.day === getTranslation('today')
      );

      if (index !== -1) messagesByEveryDay[index].rows.push(row);
      else
        messagesByEveryDay.push({ day: getTranslation('today'), rows: [row] });
    } else if (isYesterday(currDate)) {
      const index = messagesByEveryDay.findIndex(
        (m: { day: string }) => m.day === getTranslation('yesterday')
      );

      if (index !== -1) messagesByEveryDay[index].rows.push(row);
      else
        messagesByEveryDay.push({
          day: getTranslation('yesterday'),
          rows: [row],
        });
    } else {
      const formattedDate = format(currDate, dateConfigString, {
        locale: currLocale,
      });

      const index = messagesByEveryDay.findIndex(
        (m: { day: string }) => m.day === formattedDate
      );

      if (index !== -1) messagesByEveryDay[index].rows.push(row);
      else messagesByEveryDay.push({ day: formattedDate, rows: [row] });
    }
  }

  const getMessages = useCallback(() => {
    const messages: Message[] = [];
    conversation?.forEach((m) =>
      m.msgs.forEach((msg) => {
        messages.push({
          message: msg.msg,
          messageType: msg.type as MessageType,
          timestamp: msg.timestamp,
          contentType: 'text',
        });
      })
    );

    return messages;
  }, [conversation]);

  async function getMoreConversations(previous: boolean) {
    const timeStampToSearchFrom = !previous
      ? conversation[0].created_at
      : conversation[conversation.length - 1].created_at;

    const requestObj = {
      bot_name,
      customer,
      target_date: timeStampToSearchFrom || '',
      cursor: paginationToken,
      previous_logs: previous || undefined,
      next_logs: !previous || undefined,
    };

    const response = await GetConversationHistory(requestObj, dispatchApp);

    if (response.Success) {
      if (response.Data && response.Data.messages.messages.length > 0) {
        const newMessages = response.Data.messages.messages;

        if (previous) setConversation([...conversation, ...newMessages]);
        else setConversation([...newMessages, ...conversation]);
      } else {
        toastNotification(
          'warning',
          getTranslation('modal.fixPhrases.noMorePhrases')
        );
      }
    } else {
      toastNotification('error', getTranslation('modal.fixPhrases.error'));
    }

    !!previous ? setLoadOlder(false) : setLoadLast(false);
  }

  const olderLoading = () =>
    conversation.length > 0 && (
      <Grid
        container
        justifyContent={'center'}
        style={{
          padding: 12,
          justifySelf: 'flex-start',
        }}
      >
        {!loadOlder ? (
          <Button
            className={classes.loadMessagesButton}
            onClick={() => {
              setLoadOlder(true);
              getMoreConversations(true);
            }}
          >
            {getTranslation('modal.fixPhrases.older')}
          </Button>
        ) : (
          <CircularProgress color="inherit" size={18} />
        )}
      </Grid>
    );

  const lastLoading = () =>
    conversation.length > 0 && (
      <Grid
        container
        justifyContent={'center'}
        className={classes.regularGrid}
        style={{
          padding: 12,
          position: 'absolute',
          bottom: 0,
          background: 'transparent',
        }}
      >
        {!loadLast ? (
          <Button
            className={classes.loadMessagesButton}
            onClick={() => {
              setLoadLast(true);
              getMoreConversations(false);
            }}
          >
            {getTranslation('modal.fixPhrases.last')}
          </Button>
        ) : (
          <CircularProgress color="inherit" size={18} />
        )}
      </Grid>
    );

  const renderMessagesByDate = useMemo(() => {
    const messagesByEveryDay: { day: string; rows: Msgs[] }[] = [];

    conversation?.forEach((msg) => {
      msg.msgs.forEach((row) => {
        const currDate = new Date(row.timestamp);
        groupMessagesByDay(currDate, messagesByEveryDay, row);
      });
    });

    if (messagesByEveryDay && messagesByEveryDay.length > 0) {
      return messagesByEveryDay.reverse().map((messagesGroup, index) => (
        <div key={index}>
          <div>
            <S.DateChip
              className={classes.regularGrid}
              style={{ lineHeight: '1rem' }}
            >
              <span>{messagesGroup.day}</span>
            </S.DateChip>

            {messagesGroup.rows
              .map((row, index) => (
                <Grid
                  container
                  justifyContent={
                    row.type === 'outgoing' ? 'flex-start' : 'flex-end'
                  }
                  className={
                    row.type === 'incoming' &&
                    messageSelected?.message.msg === row.msg
                      ? classes.intentGrid
                      : classes.regularGrid
                  }
                  key={`${row.msg}-${row.timestamp}-${index}`}
                  id={`${row.msg}-${row.timestamp}`}
                >
                  <Card className={getMessageClass(row)} variant="outlined">
                    <Typography className={classes.msg}>{row.msg}</Typography>
                    <Typography className={classes.msgHour}>
                      {format(new Date(`${row.timestamp} UTC`), 'HH:mm')}
                    </Typography>
                  </Card>

                  {row.type !== 'outgoing' && (
                    <Card
                      className={`${getMessageClass(row)} ${classes.intentMsg}`}
                      variant="outlined"
                    >
                      <Typography className={classes.msg}>
                        {row?.intentName}
                      </Typography>
                    </Card>
                  )}
                </Grid>
              ))
              .reverse()}
          </div>
        </div>
      ));
    } else
      return (
        <S.GridInfo item style={{ marginTop: 54 }}>
          <InfoOutlinedIcon />
          <Typography
            variant="body1"
            style={{ marginTop: 24, textAlign: 'center' }}
          >
            {getTranslation('modal.fixPhrases.noPhrases')}
          </Typography>
        </S.GridInfo>
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, getMoreConversations]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
      PaperProps={{ className: classes.modal }}
    >
      <S.ContainerDialog>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {getTranslation('modal.messageHistory.title')}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {loading ? (
          <Grid
            style={{
              padding: 24,
              marginTop: 54,
            }}
          >
            <CircularProgress color="inherit" size={18} />
          </Grid>
        ) : (
          <>
            {openFilter && (
              <Filter
                handleClose={() => setOpenFilter(false)}
                conversation={getMessages()}
              />
            )}
            <S.ContentContainer>
              {olderLoading()}
              <S.MessagesContainer id="scrollDiv">
                {renderMessagesByDate}
              </S.MessagesContainer>
            </S.ContentContainer>
          </>
        )}
      </S.ContainerDialog>
      {lastLoading()}
    </Dialog>
  );
}
