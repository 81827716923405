// StatusChipComponent.js

import { Chip, withTheme } from "@material-ui/core";
import { Status } from "services/123FalaService/types";
import styled from "styled-components";
import { chipStyles } from "./mixins";

export const StatusChipComponent = withTheme(styled(Chip) <{ status: Status }>`
  &.MuiChip-colorPrimary {
    ${chipStyles.approved}

    span {
      font-weight: 500;
    }
  }

  &.MuiChip-colorSecondary {
    ${chipStyles.rejected}

    span {
      font-weight: 500;
    }
  }

  &.MuiChip-root {
    ${props => {
      switch (props.status) {
        case 'MODERATE':
          return chipStyles.moderate;
        case 'PROCESSING':
          return chipStyles.processing;
        case 'PENDING_UPLOAD':
          return chipStyles.pendingUpload;
      }
    }}
  }
`);
