import { createRef, useEffect } from 'react';

export function OutsideClick(props: { children: any; callback: () => void }) {
  const outsideAlerterRef = createRef<any>();

  function handleClickOutside(event: any) {
    event.target.classList.contains('OptionsDropdown-option');
    if (
      outsideAlerterRef &&
      !outsideAlerterRef.current?.contains(event.target) &&
      !event.target.classList.contains('OptionsDropdown-option')
    ) {
      props.callback();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return <div ref={outsideAlerterRef}>{props.children}</div>;
}
