import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Switch from '@material-ui/core/Switch/Switch';
import ForumIcon from '@material-ui/icons/Forum';
import React, { useCallback, useEffect, useState } from 'react';
import {
  StyledComponent,
  StyledFooterGrid,
  StyledGridPerguntas,
  StyledInputsGrid,
  StyledMttTextField,
  StyledMttTypographyIdentificacao,
  StyledMttTypographyPerguntas,
  StyledSwitchGrid,
  StyledTextFieldPergunta,
  StyledTitleGrid,
} from './styles';

import { useForm } from 'contexts/Form/formContext';
import { propsData } from './types';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import CircularDeleteButton from 'components/CircularDeleteButton/index';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { GetEntities } from 'services/EntityService';
import useTranslator from 'utils/hooks/Translator';
import { GridIconDelete } from '../VariavelIdentificacaoTextoUsuario/styles';

export default function VariavelIdentificacaoTextoUsuario(props: Readonly<propsData>) {
  const { botName } = useFlow().state;
  const { state, dispatch } = useForm();
  const { dataForm, formErrors } = state;
  const { dispatch: dispatchApp } = useApp();

  const { index, showDelete, showTitle, showQuestions } = props;
  const variables = dataForm?.inputs?.variables ?? [];
  const variable = dataForm?.inputs?.variables[index];

  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);
	const { getTranslation } = useTranslator();

  useEffect(() => {
    let isMounted = true

    async function load() {
      let allTypes = [
        { id: '@sys.any', name: 'qualquer-input' },
        { id: '@sys.email', name: 'input-de-email' },
        { id: '@sys.number', name: 'input-de-numero' },
        { id: '@sys.date-time', name: 'input-de-data' },
        { id: '@sys.url', name: 'input-de-url' },
      ];

      if (!botName) return;

      let result = await GetEntities({ bot_name: botName }, dispatchApp);

      if (!result.Success || !result.Data) return;

      const entities = result.Data;

      allTypes = [
        ...allTypes,
        ...entities.map((entity) => ({
          id: entity.name,
          name: entity.name,
        })),
      ].sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        } else if (b.name > a.name) {
          return -1;
        } else {
          return 0;
        }
      });

      allTypes.forEach((type) => {
        if (!type.id.startsWith('@')) type.id = `@${type.id}`;
      });

      if (isMounted) {
        setTypes(allTypes);
      }
    }
    load();

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName]);

  const load = useCallback(() => {
    if (dataForm?.inputs?.variables?.length) {
      dataForm.inputs = {
        variables: [
          {
            name: '',
            vartype: '',
            questions: ['', '', ''],
            required: false,
            value: '',
            defaultValue: '',
            isList: false,
          },
        ],
      };
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeInput =
    (key: 'value' | 'name' | 'vartype') =>
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      if (dataForm && variable) {
        const value = String(e.target.value);

        if (dataForm.dataBlockly) {
          dataForm.dataBlockly.payload =
            dataForm.dataBlockly?.payload.replaceAll(
              `$${variable['name']}`,
              `$${value}`
            );
        }

        if (key === 'name' && !variable.isValueEdited) {
          variable['value'] = '$' + value;
        }
        variable[key] = value;
        dispatch({
          type: 'updateForm',
          data: { dataForm },
        });
      }
    };

  const handleQuestion = (value: string, index: number) => {
    if (variable && dataForm) {
      variable.questions[index] = value;
      dispatch({
        type: 'updateForm',
        data: { dataForm },
      });
    }
  };

  const handleShowPerguntas = () => {
    if (variable && dataForm) {
      variable.required = !variable.required;
      dispatch({
        type: 'updateForm',
        data: { dataForm },
      });
    }
  };

  const handleRemoveVariable = (index: number) => {
    if (dataForm?.inputs) {
      variables.splice(index, 1);
      dispatch({ type: 'updateForm', data: { dataForm } });
    }
  };

  const renderQuestions = () => {
    if (!showQuestions) return <></>;
    return (
      <>
        <StyledSwitchGrid>
          <Switch
            checked={!!variable?.required}
            onChange={handleShowPerguntas}
            color="primary"
          />
          <StyledMttTypographyPerguntas />
        </StyledSwitchGrid>
        <StyledGridPerguntas showgrid={!!variable?.required}>
          {variable?.questions.map((perg, index) => {
            return (
              <StyledTextFieldPergunta
                key={index}
                onChange={(e) => handleQuestion(e.target.value, index)}
                value={perg}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ForumIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            );
          })}
        </StyledGridPerguntas>
      </>
    );
  };

  return (
    <>
      {!!showTitle && (
        <StyledTitleGrid>
          <StyledMttTypographyIdentificacao />
        </StyledTitleGrid>
      )}
      <StyledFooterGrid>
        <StyledComponent>
          <StyledInputsGrid>
            <StyledMttTextField
              variant="outlined"
              label={getTranslation('name')}
              value={variable?.name
                .replace(/[^A-Z a-z 0-9 \\-]/g, '')
                .replace(/\s/g, '')}
              onChange={handleChangeInput('name')}
            />
            <FormControl variant="outlined" style={{ height: '65px' }}>
              <InputLabel id="groups-simple-select-outlined-label">
							{getTranslation('types')}
              </InputLabel>
              <Select
                labelId="groups-simple-select-outlined-label"
                id="groups-simple-select-outlined"
                value={variable?.vartype}
                onChange={handleChangeInput('vartype')}
                label={getTranslation('types')}
                fullWidth
                error={
                  variable?.name !== '' &&
                  variable?.vartype === '' &&
                  !!formErrors['varType']
                }
              >
                <MenuItem value="">
                  <em>{getTranslation('selectType')}</em>
                </MenuItem>
                {types.map((type, index) => (
                  <MenuItem key={index} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
              {variable?.name && variable?.vartype === '' && (
                <FormHelperText error>{formErrors['varType']}</FormHelperText>
              )}
            </FormControl>

            {/* <StyledMttTextField
              variant="outlined"
              label="Valor"
              value={variable?.value}
              onChange={handleChangeInput("value")}
            /> */}
          </StyledInputsGrid>
          {!!showDelete && (
            <GridIconDelete key={`variable-${index}`}>
              <CircularDeleteButton
                handleDeleteItem={() => handleRemoveVariable(index)}
              />
            </GridIconDelete>
          )}
        </StyledComponent>
        {renderQuestions()}
      </StyledFooterGrid>
    </>
  );
}
