import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TransitionProps } from '@material-ui/core/transitions';
import { Delete, Info, Refresh } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { ModalActionConfirm } from 'components/ModalActionConfirm';
import { StatusChip } from 'components/StatusChip';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useToast } from 'contexts/ToastContext';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { DeleteDataSource, GetDataSourceList } from 'services/123FalaService';
import { DataSourceListSchema } from 'services/123FalaService/types';
import { formatDateTime } from 'utils/Date';
import useTranslator from 'utils/hooks/Translator';
import { ModalAddDataSource } from '../ModalAddDataSource';
import * as S from '../style';
import { ModalDataSourceListProps } from './types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalDataSourceList(props: ModalDataSourceListProps) {
  const classes = S.useStyles();
  const { contentGroup, setIsOpen } = props;
  const { dispatch } = useApp();
  const { currentData: { agentName } } = useFintalkCloud()
  const { getTranslation } = useTranslator();
  const { toastNotification: toast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalAddDataSource, setIsOpenModalAddDataSource] = useState<boolean>(false);
  const [isOpenModalActionConfirm, setIsOpenModalActionConfirm] = useState<boolean>(false);
  const [dataSourceSelected, setDataSourceSelected] = useState<DataSourceListSchema | null>(null);
  const [dataSources, setDataSources] = useState<DataSourceListSchema[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const loadDataSources = useCallback(async () => {
    try {
      setIsLoading(true);

      const resp = await GetDataSourceList(agentName, contentGroup.id, dispatch);

      if (
        resp.StatusCode < 200 ||
        resp.StatusCode >= 300
      ) {
        toast({
          type: 'warning', 
          message: getTranslation('toast.error.dataSourcesNotFound') 
        })

        return;
      };

      const data = resp.Data.data.sort((a, b) => {
        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      });

      setDataSources(data);
    } catch (error) {
      toast({
        type: 'error',
        message: getTranslation('toast.error.error'),
      })
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    loadDataSources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const deleteDataSource = async () => {
    if (dataSourceSelected) {
      try {
        const resp = await DeleteDataSource(agentName, dataSourceSelected.id, dispatch);
  
        if (resp.StatusCode < 200 || resp.StatusCode >= 300) {
          toast({
            type: 'warning', 
            message: getTranslation('toast.error.error')
          });

          return;
        }

        toast({ 
          type: 'success',
          message: getTranslation('toast.success.deleteDataSource')
        });

        setIsOpenModalActionConfirm(false);
        loadDataSources();
      } catch (error) {
        toast({
          type: 'error',
          message: getTranslation('toast.error.error'),
        });
      }
    }
  }

  const openActionConfirm = (dataSource: DataSourceListSchema) => {
    setDataSourceSelected(dataSource);
    setIsOpenModalActionConfirm(true);
  };

  return (
    <>
      <Dialog
        open
        fullWidth={true}
        TransitionComponent={Transition}
        maxWidth="md"
        PaperProps={{ classes: { rounded: classes.dialog } }}
      >
        <S.DialogContainer>
          <AppBar>
            <Toolbar>
              <Typography
                variant="h5"
                className={classes.title}
              >
                123Fala | {contentGroup.name}
              </Typography>

              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          {isLoading ? (
            <Box
              component='div'
              style={{
                width: '100%',
                height: 200,
                padding: 24,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="inherit" size={56} />
            </Box>
          ) : (
            <S.ContentContainer>
              <S.ContentHeader>
                <Typography
                  variant="h6"
                >
                  <strong>{dataSources.length ?? 0}</strong> {getTranslation('modal.123Fala.dataSource')}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gridGap: 16,
                  }}
                >
                  <IconButton onClick={() => loadDataSources()}>
                    <Refresh htmlColor='#000' />
                  </IconButton>

                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.addButton}
                    onClick={() => setIsOpenModalAddDataSource(true)}
                  >
                    {getTranslation('modal.123Fala.addDataSource')}
                  </Button>
                </Box>
              </S.ContentHeader>

              {dataSources.length < 1 ? (
                <Typography
                  variant='subtitle1'
                  style={{
                    fontSize: 16,
                    textAlign: 'center',
                    paddingTop: 32,
                  }}
                >
                  {getTranslation('noDataSources')}...
                </Typography>
              ) : (
                <TableContainer style={{ height: '50vh', maxHeight: '640px' }}>
                  <Table
                    className={classes.table}
                    aria-label="simple table"
                    size="medium"
                    style={{
                      height: 'fit-content'
                    }}
                  >
                    <TableHead
                      style={{
                        position: 'sticky', 
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 1
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          {getTranslation('modal.123Fala.dataSource')}
                        </TableCell>

                        <TableCell align="center">
                          {getTranslation('modal.conversationHistory.updatedAt')}
                        </TableCell>

                        <TableCell align="center">
                          Status
                        </TableCell>

                        <TableCell align="center">
                          {getTranslation('actions')}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dataSources.map((dataSource, index) => (
                        <TableRow key={dataSource.id}>
                          <TableCell>{dataSource.name}</TableCell>

                          <TableCell align="center">
                            {formatDateTime(new Date(dataSource.updated_at))}
                          </TableCell>

                          <TableCell align='center'>
                            <Box
                              style={{
                                display: 'flex',
                                alignContent: 'center',
                                justifyContent: 'center',
                                gap: 8
                              }}
                            >
                              <StatusChip
                                label={dataSource.status}
                                status={dataSource.status}
                              />

                              {dataSource.status_reason && dataSource.status_reason.length > 0 && (
                                <Tooltip
                                  title={
                                    <Typography>
                                      {dataSource.status_reason.map((reason, index) => (
                                        <div key={index} dangerouslySetInnerHTML={{ __html: reason }} />
                                      ))}
                                    </Typography>
                                  }
                                  placement='top'
                                  style={{
                                    margin: 'auto 0'
                                  }}
                                >
                                  <Info />
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>

                          <TableCell align="center">
                            <IconButton onClick={() => openActionConfirm(dataSource)}>
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

            </S.ContentContainer>
          )}
        </S.DialogContainer>
      </Dialog>

      {isOpenModalAddDataSource && (
        <ModalAddDataSource
          setIsOpen={setIsOpenModalAddDataSource}
          contentGroupId={contentGroup.id}
          setRefresh={setRefresh}
        />
      )}

      {isOpenModalActionConfirm && (
        <ModalActionConfirm
          setIsOpen={setIsOpenModalActionConfirm}
          title={getTranslation('modal.actionConfirm.removeSourceData.title')}
          subtitle={
            <Trans 
              i18nKey='modal.actionConfirm.removeSourceData.subtitle'
              components={{
                bold: <strong />
              }}
              values={{
                name: dataSourceSelected?.name
              }}
            />
          }
          confirmActionText={dataSourceSelected?.name}
          confirmActionRequest={deleteDataSource}
        />
      )}
    </>
  );
}
