import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Info from 'components/inputs/Material/Info/index';
// import MessageForUser from 'components/inputs/Material/MessageForUser';
// import RulesBlockContent from 'components/inputs/Material/RulesBlockContent';
import { FormFieldsContainer } from '../Shared/styles';

export default function Fallback() {
  return (
    <>
      <FormFieldsContainer>
        <Info fieldsLock={['name', 'description']} />
        {/* <MessageForUser hideUrl /> */}
        {/* <RulesBlockContent /> */}
      </FormFieldsContainer>
      <FooterButtons /*showLogicButton={true}*/ />
    </>
  );
}
