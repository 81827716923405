import { useEffect, useState, useRef } from 'react';
import Pusher from 'pusher-js';
import * as S from './styles';
import { useFlow } from '../../contexts/Flow/flowContext';
import Draggable, { DraggableData } from 'react-draggable';
import { IconButton } from '@material-ui/core';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import CloseIcon from '@material-ui/icons/Close';

const API_KEY = 'd90b6724351cbd1d98e6';
const API_CLUSTER = 'us2';

const Themes = {
  lightTheme: {
    name: 'light',
    color: '#000000',
    background: '#fff',
  },

  darkTheme: {
    name: 'dark',
    color: '#00ff2b',
    background: '#000',
  },
};

export function Debug(props: { open: boolean; handleCloseDebug: () => void }) {
  const { open, handleCloseDebug } = props;
  const { botName } = useFlow().state;
  const [currTheme, setCurrTheme] = useState(getTheme());
  const nodeRef = useRef(null);
  const containerRef = document.getElementById('container');
  const [containerDimensions, setContainerDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 360, height: 500 });

  const [position, setPosition] = useState(
    JSON.parse(
      localStorage.getItem('debugPosition') ||
        JSON.stringify({
          x: 0,
          y: 0,
        })
    )
  );

  const zeroPad = (num: number, places: number) =>
    String(num).padStart(places, '0');

  useEffect(() => {
    const widthAndHeight = localStorage.getItem('consoleWidthAndHeight');
    if (widthAndHeight) {
      setContainerDimensions(JSON.parse(widthAndHeight));
    } else {
      localStorage.setItem(
        'consoleWidthAndHeight',
        JSON.stringify({
          width: 360,
          height: 300,
        })
      );
    }
  }, []);

  useEffect(() => {
    const consoleMsgContainer = document.getElementById('console_data');
    if (botName) {
      var pusher = new Pusher(API_KEY, {
        cluster: API_CLUSTER,
      });

      if (pusher) {
        var channel = pusher.subscribe(botName);
        if (consoleMsgContainer) {
          let msg;

          channel.bind('console_log', function (data: { message: string }) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            msg = JSON.stringify(data.message);
            msg = msg.replace(/\r\n|\n|\r/g, '<br>');
            const consoleMsg =
              consoleMsgContainer.innerHTML + '<br>' + timeStamp() + '> ' + msg;
            consoleMsgContainer.innerHTML = consoleMsg;
          });

          channel.bind('console_log_var', function (data: { message: string }) {
            msg = JSON.stringify(data.message);
            const parsedMsg = JSON.parse(data.message);
            msg = msg.replace(/\t\r\n/, '<br>');

            consoleMsgContainer.innerHTML +=
              '<br>' + timeStamp() + '> VARIABLES<br>';
            const keys = Object.keys(parsedMsg);

            for (const key of keys) {
              consoleMsgContainer.innerHTML += `${key}: ${JSON.stringify(
                parsedMsg[key]
              )}<br>`;
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName]);

  function setTheme(theme: {
    name: string;
    color: string;
    background: string;
  }) {
    setCurrTheme(theme);
    localStorage.setItem('debuggerTheme', theme.name);
  }

  function timeStamp() {
    let timestamp = new Date();
    return (
      zeroPad(timestamp.getDate(), 2) +
      '/' +
      zeroPad(timestamp.getMonth() + 1, 2) +
      '/' +
      timestamp.getFullYear() +
      '#' +
      zeroPad(timestamp.getHours(), 2) +
      ':' +
      zeroPad(timestamp.getMinutes(), 2) +
      ':' +
      zeroPad(timestamp.getSeconds(), 2)
    );
  }

  function getTheme() {
    const lastTheme = localStorage.getItem('debuggerTheme') || 'dark';

    if (lastTheme === 'dark') return Themes.darkTheme;
    else return Themes.lightTheme;
  }

  function handleClose() {
    const currDimensions = localStorage.getItem('consoleWidthAndHeight');

    if (currDimensions) {
      const parsedDimensions = JSON.parse(currDimensions);

      const consoleWidthAndHeight = {
        width: containerRef?.style.width || parsedDimensions.width,
        height: containerRef?.style.height || parsedDimensions.height,
      };

      localStorage.setItem(
        'consoleWidthAndHeight',
        JSON.stringify(consoleWidthAndHeight)
      );
    }
    handleCloseDebug();
  }

  function handleStopDrag(data: DraggableData) {
    localStorage.setItem(
      'debugPosition',
      JSON.stringify({
        x: data.lastX,
        y: data.lastY,
      })
    );
    setPosition({
      x: data.lastX,
      y: data.lastY,
    });
  }

  return open ? (
    <Draggable
      defaultPosition={position}
      onStop={(_, data) => handleStopDrag(data)}
      nodeRef={nodeRef}
      bounds={'body'}
      handle={'header'}
    >
      <S.Container
        id="container"
        ref={nodeRef}
        width={containerDimensions.width}
        height={containerDimensions.height}
        windowWidth={window.innerWidth}
        windowHeight={window.innerHeight}
      >
        <S.ContainerHeader id="header">
          LLEGO - Agent Console
          <IconButton onClick={() => handleClose()}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </S.ContainerHeader>
        <S.IConsole id="console" theme={currTheme}>
          <S.ConsoleHeaderContainer theme={currTheme}>
            Agent Console
            <IconButton
              onClick={() =>
                setTheme(
                  currTheme === Themes.lightTheme
                    ? Themes.darkTheme
                    : Themes.lightTheme
                )
              }
            >
              {currTheme === Themes.lightTheme ? (
                <Brightness2Icon fontSize="medium" />
              ) : (
                <WbSunnyIcon fontSize="medium" />
              )}
            </IconButton>
          </S.ConsoleHeaderContainer>
          <p id="console_data"></p>
        </S.IConsole>
      </S.Container>
    </Draggable>
  ) : (
    <></>
  );
}
