import { IconButton, Theme, createStyles } from '@material-ui/core';
import styled from 'styled-components';

export const FormatCodeButton = styled(IconButton)`
  background: #2651ac !important;
  font-size: 0.9rem !important;
  color: #fff !important;
  border-radius: 5px !important;
  margin-right: 10px !important;

  max-width: 120px;

  &:hover {
    background: #2651ac85 !important;
    color: #fff !important;
  }
`;

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    footer: {
      margin: 0,
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      background: '#282a36',
      borderTop: '1px solid #44475a',
    },
  });
