import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import MttButton from 'components/Material/MttButton/MttButton';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttTextField from 'components/Material/MttTextField/MttTextFIeld';

export const Container = styled(MttGrid)`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 8px;

  ul {
    .MuiListItemIcon-root {
      svg {
        margin-right: 20px;
        color: #424242;
        font-size: 20px;
      }
    }
  }
`;

export const GridHeader = styled(MttGrid)`
  grid-area: Header;

  display: flex;
  align-items: center;

  height: 64px;

  background-color: #0e103b;
  padding: 0 12px 0 42px;
  border-radius: 8px;
  border: 1px solid #353759;
`;

export const GridInputsHeader = styled(MttGrid)`
  display: flex;
  align-items: center;
  padding: 0 32px;

  button {
    height: 56px;
    width: 260px;
  }

  #groups-simple-select-outlined {
    display: flex;

    button {
      height: 0px;
      width: 0px;
      visibility: hidden;
    }
  }
`;

export const GridGreenButton = styled(MttGrid).attrs({
  className: 'greenButtonMaterial',
})``;

export const StyledMttButtonGreen = styled(MttButton)``;

export const StyledMttButtonPurple = styled(MttButton)``;

export const StyledMttButtonPublic = styled(MttButton)`
  width: 260;
  height: 56;
  justify-content: space-around;
  position: fixed;
  bottom: 16px;
  left: 16px;
`;

export const GridMenu = styled(MttGrid)`
  width: 100%;
  height: 100%;

  grid-area: Menu;
  border-right: 2px solid #00000029;

  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;

  button {
    background-color: #0e103b;
    color: #fff;
    align-self: center;
    margin-bottom: 16px;
  }

  .MuiIconButton-root:hover {
    background-color: #5a36a3;
    color: #fff;
  }

  .MuiIconButton-root.Mui-disabled {
    background-color: #6c50a485;
    color: #fff;
  }
`;

export const GridListMenuChild = styled(MttGrid)<{
  selected?: boolean;
  isChild?: boolean;
  disabled?: boolean;
}>`
  .Mui-disabled {
    :hover {
      background-color: #fff;
    }
  }

  .MuiListItemIcon-root {
    min-width: auto;
    margin-right: 18px;
  }
  .MuiListItemText-root {
    span {
      font-size: 14px;
    }
  }
`;

export const GridListMenu = styled(MttGrid)<{
  selected?: boolean;
  child?: boolean;
  disabled?: boolean;
}>`
  max-width: 56px;

  /* background-color: ${(props) =>
    props.selected && !props.child ? '#6C50A4' : '#fff'}; */
  /* color: ${(props) =>
    props.selected && !props.child ? '#fff' : '#515151'}; */

  svg,
  img {
    /* color: ${(props) =>
      props.selected && !props.child ? '#fff' : 'inherit'}; */
  }

  :hover {
    /* color: '#fff'; */

    /* background-color: ${(props) =>
      props.disabled && props.selected && props.child
        ? '#a1a1a1'
        : props.selected && props.child
        ? '#6c50a4ad'
        : props.selected
        ? '#6C50A4'
        : '#a1a1a1'}; */

    /* svg {
      color: ${(props) =>
      props.disabled && props.selected && props.child ? 'inherit' : '#fff'};
    } */
  }

  .MuiListItemIcon-root {
    min-width: auto;
  }

  .Mui-disabled {
    :hover {
      background-color: #fff;
    }
  }

  :first-child {
    margin-top: 30px;
  }
`;

export const GridChildren = styled(MttGrid)`
  grid-area: Children;
  background: #fff;
  position: relative;
  border-left: 2px solid #00000029;
  color: #000;
  padding: 24px;
  overflow-x: auto;
`;

export const StyledMttTextField = styled(MttTextField)``;

export const ContainerSelect = styled.div`
  flex-direction: row;
  display: flex;
  margin: 0 12px 0 12px;
`;

export const Logo = styled.div`
  margin-left: 16px;
  display: flex;
`;

export const OptionsHeader = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

export const Options = styled.div`
  cursor: pointer;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  width: 110px;
  height: 32px;

  &:hover {
    background-color: white;
    border-radius: 10px;
  }

  svg {
    align-items: center;
    justify-content: center;
    margin: 2px;
  }
`;
export const StyledMenuButtonFooter = styled(MttButton).attrs({
  children: 'Publicar Agora',
})``;

export const LabelTitle = styled(Typography).attrs({})`
  margin-left: 8px;
`;

export const GridFooterButton = styled(MttGrid)`
  width: 292px;
  display: flex;
  justify-content: center;

  position: fixed;
  bottom: 10px;

  .MuiButton-contained {
    width: 260px;
    height: 56px;
  }
`;

export const GridSearch = styled(MttGrid)`
  margin-top: 16px;
`;

export const AutoCompleteWrap = styled.div`
  width: 100%;
  margin: 4px;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  padding: 0 8px;

  .MuiTypography-body1 {
    max-height: 32px;
    padding: 0 8px;
    font-size: 12px;
  }

  input {
    height: auto;
  }

  button {
    width: auto;
    height: auto;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 8px;
    font-size: 12px;
    background-color: #26274c;
    color: #fff;
    border: 1px solid #353759;
    border-radius: 18px;

    svg {
      fill: #fff;
    }
  }
  .MuiOutlinedInput-notchedOutline:hover {
    border-color: red;
  }
`;

export const AutoCompleteOption = styled.span`
  flex: 1;
  margin: 3px 0;
  width: 220px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BotAndGroupInfo = styled.div`
  min-width: 820px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-left: -64px; */

  color: #fff;

  h6 {
    margin: 0px 3px;
  }
`;

export const ImportExportIcons = styled.div`
  display: flex;
  align-items: center;

  button {
    width: auto;
    height: auto;
    color: #fff;
    margin-top: 16px;

    :disabled {
      color: rgb(255 255 255 / 40%);
    }
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;

  button {
    color: #fff;
    float: right;
    font-size: 42px;
  }
`;

export const MenuListContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  width: 100%;

  overflow-y: scroll;

  /* @media (max-height: 800px) {
    max-height: calc(100vh - 70vh);
  }

  @media (max-height: 600px) {
    max-height: calc(100vh - 80vh);
  } */
`;
