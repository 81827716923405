import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';
import MttButton from '../Material/MttButton/MttButton';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  position: absolute;
  left: 32%;
  top: 12%;
  border-radius: 10px;

  z-index: 10;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 3px 6px #00000014;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 350px;
  max-height: 90px;
`;

export const ImageContainer = styled.div`
  margin: 0px 25px 0px 25px;
  max-width: 100px;
  max-height: 50px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    max-width: 60px;
  }
`;

export const TextContainer = styled(Typography).attrs({})`
  display: flex;
  width: 355px;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #0e103b;
`;

export const ButtonContainer = styled.div`
  padding: 22px 16px;
`;

export const ButtonOk = styled(MttButton)`
  width: 134px;
  height: 35px;
`;

export const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    left: 'center',
    top: 45,
    borderRadius: '10px',
  },

  modalTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.6,
  },
});
