import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IAddFlexBlockRequest,
  IGetFlexBlockResponse,
  IRemoveFlexBlockRequest,
  IRemoveFlexBlockResponse,
  ISaveFlexBlockRequest,
  ISaveFlexBlockResponse,
  IUpdateFlexBlockNameRequest,
  IUpdateFlexBlockRequest,
  IUpdateFlexBlockResponse,
  RemoveFlexBlockGroup,
} from './types';

const baseUrl = 'flexblocks';
const botsUrl = 'bots';

export const GetFlexBlocks = async (
  botName: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetFlexBlockResponse>> => {
  const action = `${botsUrl}/${botName}/${baseUrl}`;
  return json<IGetFlexBlockResponse>('GET', action, dispatch);
};

export const SaveFlexBlock = async (
  request: ISaveFlexBlockRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ISaveFlexBlockResponse>> => {
  const action = `${botsUrl}/${request.bot_name}/${baseUrl}`;
  return json<ISaveFlexBlockResponse>('POST', action, dispatch, request);
};

export const AddFlexBlockToGroup = async (
  request: IAddFlexBlockRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ISaveFlexBlockResponse>> => {
  const action = `${botsUrl}/${request.bot_name}/${baseUrl}/${request.block_name}/groups`;
  return json<ISaveFlexBlockResponse>('POST', action, dispatch, request);
};

export const UpdateFlexBlockGroups = async (
  request: IUpdateFlexBlockRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IUpdateFlexBlockResponse>> => {
  const action = `${botsUrl}/${request.bot_name}/${baseUrl}/${request.block_name}/groups-bot`;
  return json<IUpdateFlexBlockResponse>('PUT', action, dispatch, request.block);
};

export const DeleteFlexBlock = async (
  request: IRemoveFlexBlockRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IRemoveFlexBlockResponse>> => {
  const action = `${botsUrl}/${request.bot_name}/groups/${request.group_name}/${baseUrl}`;
  return json<IRemoveFlexBlockResponse>(
    'DELETE',
    action,
    dispatch,
    request.data
  );
};

export const DeleteFlexBlockRegister = async (
  botName: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IRemoveFlexBlockResponse>> => {
  const action = `${botsUrl}/${botName}/${baseUrl}`;
  return json<IRemoveFlexBlockResponse>('DELETE', action, dispatch);
};

export const DeleteFlexBlockGroup = async (
  request: RemoveFlexBlockGroup,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IRemoveFlexBlockResponse>> => {
  const action = `${botsUrl}/${request.bot_name}/${baseUrl}/remove-block`;
  return json<IRemoveFlexBlockResponse>('PUT', action, dispatch, request);
};

export const UpdateFlexBlockName = async (
  request: IUpdateFlexBlockNameRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IRemoveFlexBlockResponse>> => {
  const action = `${botsUrl}/${request.bot_name}/${baseUrl}/${request.block_name}/name`;
  return json<IRemoveFlexBlockResponse>('PUT', action, dispatch, request);
};
