import { Variables } from './Variables';
import { useEffect, useState } from 'react';
import { Add } from '@material-ui/icons';
import { StyledTitleGrid, UserParametersContainer } from './styles';
import { IconButton, Typography } from '@material-ui/core';
import { Variable } from 'models/DataFlow';
import { useForm } from 'contexts/Form/formContext';

export function UserParameters() {
  const [variables, setVariables] = useState<Variable[]>([]);
  const { state, updateForm } = useForm();
  const { dataForm } = state;

  useEffect(() => {
    setVariables(dataForm?.inputs?.variables || []);
  }, [dataForm?.inputs?.variables]);

  const handleAddVariable = (e: any) => {
    const variable = {
      name: '',
      vartype: '',
      value: '',
      required: false,
      questions: [''],
      defaultValue: '',
      isList: false,
    };

    setVariables((prev) => [...prev, variable]);
  };

  const handleRemoveVariable = (index: number) => {
    if (dataForm && dataForm.inputs) {
      variables.splice(index, 1);

      updateForm(dataForm);
    }
  };

  return (
    <UserParametersContainer>
      <StyledTitleGrid>
        <Typography>Variáveis extraídas</Typography>
        <IconButton onClick={handleAddVariable}>
          <Add />
        </IconButton>
      </StyledTitleGrid>
      {variables.map((variable, index) => (
        <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
          <Variables
            variable={variable}
            index={index}
            handleRemoveVariable={() => handleRemoveVariable(index)}
          />
        </div>
      ))}
    </UserParametersContainer>
  );
}
