import { InputAdornment } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import SyncIcon from '@material-ui/icons/Sync';
import CloseIcon from '@material-ui/icons/Close';

import React, { useState } from 'react';
import MttButton from 'components/Material/MttButton/MttButton';
import green from '@material-ui/core/colors/green';

import {
  ContainerButton,
  ContainerTitleFooter,
  ContentGrid,
  ContentTitlesGrid,
  GridContainer,
  StyledMttTextField,
  StyledMttTypographyFrase,
  StyledTitleFooter,
  StyledTitleFooterClick,
} from './styles';
import { useFlow } from 'contexts/Flow/flowContext';
import { VerifyGoogleSheetID } from 'services/VerificationCodeService';
import { useApp } from 'contexts/App/appContext';

const GoogleSheet: React.FC = () => {
  const { toastNotification } = useFlow();
  const { dispatch: dispatchApp } = useApp();
  const [load, setLoad] = useState(false);
  const [showId, setShowId] = useState(true);

  const handleVerifyId = async () => {
    setLoad(true);
    const result = await VerifyGoogleSheetID(
      {
        googleSheetID: '',
      },
      dispatchApp
    );
    if (result.Success) {
      if (result.Data.data) {
        toastNotification('success', 'Id verificado com sucesso');
        setShowId(true);
      } else {
        toastNotification('error', 'Id inválido');
        setShowId(false);
      }
    } else {
      toastNotification('error', 'Error ao tentar verificar Id');
      setShowId(false);
    }
    setLoad(false);
  };

  return (
    <GridContainer>
      <StyledMttTypographyFrase />
      <ContentGrid>
        <ContentTitlesGrid>
          <StyledMttTextField
            variant="outlined"
            placeholder="GoogleSheetID"
            fullWidth
            inputProps={{}}
            name="choice-input"
            type="text"
            onChange={() => {}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showId ? (
                    <CheckIcon style={{ color: green[400] }} />
                  ) : (
                    <CloseIcon color="secondary" />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <ContainerButton>
            <MttButton
              variant="contained"
              color="primary"
              startIcon={
                load ? <SyncIcon className="icon-spin" /> : <SyncIcon />
              }
              onClick={handleVerifyId}
              fullWidth
            >
              Verificar
            </MttButton>
          </ContainerButton>
        </ContentTitlesGrid>
        <ContainerTitleFooter>
          <StyledTitleFooterClick>CLIQUE AQUI</StyledTitleFooterClick>
          <StyledTitleFooter>
            para ver como se ativa essa funcionalidade.
          </StyledTitleFooter>
        </ContainerTitleFooter>
      </ContentGrid>
    </GridContainer>
  );
};

export default GoogleSheet;
