import React from "react";
import FooterButtons from "components/inputs/Material/FooterButtons";
import Info from "components/inputs/Material/Info";
import ToNewIntention from "components/inputs/Material/ToNewIntention";
import { FormFieldsContainer } from "../Shared/styles";

export default function NewGroup() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <ToNewIntention />
      </FormFieldsContainer>
      <FooterButtons showLogicButton={false} />
    </>
  );
}
