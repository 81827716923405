import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { FileSelectedBox } from 'components/FileSelectedBox';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useToast } from 'contexts/ToastContext';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { GetPreSignedURL } from 'services/123FalaService';
import { IGetPreSignedURLRequest } from 'services/123FalaService/types';
import { getMimeType } from 'utils/getMimeType';
import useTranslator from 'utils/hooks/Translator';
import * as S from './style';
import { DataSourceCreateSchema, ModalAddDataSourceProps } from './types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ModalAddDataSource({
  setIsOpen,
  contentGroupId,
  setRefresh,
  setIsOpenReturnComponent,
}: ModalAddDataSourceProps) {
  const classes = S.useStyles();
  const { dispatch } = useApp();
  const { currentData: { agentName } } = useFintalkCloud();
  const { toastNotification: toast } = useToast();
  const { getTranslation } = useTranslator();

  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceData, setDataSourceData] = useState<DataSourceCreateSchema>({
    file: null,
  });

  const allowedFileTypes = ['txt', 'docx', 'pdf'];

  const handleCreateDataSource = async () => {
    if (dataSourceData.file) {
      try {
        setIsLoading(true);

        const file = dataSourceData.file;
        const contentType = await getMimeType(file);

        const payload: IGetPreSignedURLRequest = {
          filename: file.name,
          mimetype: contentType,
        }

        const resp = await GetPreSignedURL(
          payload,
          agentName,
          contentGroupId,
          dispatch
        );

        if (
          resp.StatusCode < 200 ||
          resp.StatusCode >= 300 ||
          !resp.Data.pre_signed_url
        ) {
          toast({
            type: 'warning',
            message: getTranslation('toast.error.addDataSource')
          });

          return;
        }

        const preSignedURL = resp.Data.pre_signed_url;

        const upload = await fetch(preSignedURL, {
          method: 'PUT',
          body: file,
          headers: {
            "Content-Length": String(file.size),
            "Content-Type": contentType,
          },
        });

        if (
          upload.status < 200 ||
          upload.status >= 300
        ) {
          toast({
            type: 'warning',
            message: getTranslation('toast.error.addDataSource')
          });

          return;
        }

        toast({
          type: 'success',
          message: getTranslation('toast.success.addDataSource')
        });

        setRefresh && setRefresh(prev => !prev);
        setIsOpenReturnComponent && setIsOpenReturnComponent(true);
      } catch (error) {
        toast({
          type: 'error',
          message: getTranslation('toast.error.error'),
        });
      } finally {
        setIsLoading(false);
        setIsOpen(false);
      }
    }
  };

  const handleChangeDataSourceData = (
    field: string,
    value: File | boolean | null,
  ) => {
    setDataSourceData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFileSelect = (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (fileExtension && !allowedFileTypes.includes(fileExtension)) {
      toast({
        type: 'error',
        message: getTranslation('toast.error.invalidFileForEntities')
      });
      return;
    }

    handleChangeDataSourceData('file', file);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    if (event.dataTransfer.files?.[0]) {
      handleFileSelect(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  return (
    <Dialog
      open
      fullWidth={true}
      TransitionComponent={Transition}
      maxWidth="xs"
      PaperProps={{ classes: { rounded: classes.dialog } }}
    >
      <S.DialogContainer>
        <Toolbar>
          <Typography
            variant="h5"
            className={classes.title}
          >
            {getTranslation('modal.123Fala.addDataSource')}
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setIsOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <Divider />

        <S.ContentContainer>
          {!isLoading ? (
            <>
              <S.DataSourceFileInputLabel
                htmlFor='data-source-file'
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <input
                  id='data-source-file'
                  type='file'
                  accept=".txt,.docx,.pdf"
                  draggable
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    if (event.target.files) {
                      handleFileSelect(event.target.files[0]);
                    }
                  }}
                  disabled={isLoading}
                />

                <Box
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <img
                    src="media/upload.svg"
                    alt=""
                    style={{
                      width: 100,
                      height: 100,
                    }}
                  />

                  <Typography
                    variant='subtitle1'
                    component='p'
                    color='textPrimary'
                  >
                    <Trans
                      i18nKey='addDocument'
                      components={{
                        span: <span
                          style={{
                            cursor: 'pointer',
                            color: '#0E103B',
                            textDecoration: 'underline',
                          }}
                        />
                      }}
                    />
                  </Typography>

                  <Typography
                    variant='subtitle1'
                    component='span'
                  >
                    .TXT .DOCX .PDF
                  </Typography>
                </Box>
              </S.DataSourceFileInputLabel>

              {dataSourceData.file && (
                <FileSelectedBox
                  file={dataSourceData.file}
                  onDelete={() => handleChangeDataSourceData('file', null)}
                />
              )}
            </>
          ) : (
            <CircularProgress
              size={96}
              style={{
                display: 'block',
                margin: '0 auto',
              }}
            />
          )}
        </S.ContentContainer>

        <S.FooterContainer>
          <Button
            onClick={() => setIsOpen(false)}
            color='primary'
            size='large'
            className={classes.footerButtons}
            disabled={isLoading}
          >
            {getTranslation('cancel')}
          </Button>

          <Button
            onClick={() => handleCreateDataSource()}
            variant='contained'
            color='secondary'
            size='large'
            className={classes.footerButtons}
            disabled={isLoading}
          >
            {getTranslation('save')}
          </Button>
        </S.FooterContainer>
      </S.DialogContainer>
    </Dialog>
  );
}
