import {
  Check,
  Clear,
  FileCopy,
  FlipToFront,
  Delete,
} from '@material-ui/icons';
import { useState } from 'react';
import { validateBlockedIntents } from 'enums/EIntentType';
import { DataForm } from 'models/DataFlow';
import DataFormModel from 'models/DataFormModel';
import HeatmapIcon from 'components/Icons/HeatmapIcon';
import * as S from './styles';
import { IModalBlockOptionsProps } from './types';
import SettingsIcon from '@material-ui/icons/Settings';
import { IconButton } from '@material-ui/core';

import ModalHeatmap from './ModalHeatmap';
import useGoogleCharts from 'utils/hooks/googleCharts';

function ModalBlockOptions(props: IModalBlockOptionsProps) {
  const google = useGoogleCharts();
  const {
    nodeId,
    node,
    state,
    dispatch,
    addNodeToList,
    clearNodeList,
    deleteNode,
    duplicateNode,
		form
  } = props;
  const { intentType } = node.data;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openHeatMap, setOpenHeatMap] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const bool = state.editorFlow?.editor_mode === 'fixed';
    if (!bool) {
      if (state) {
        setAnchorEl(event.currentTarget);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModalCopyToLib = () => {
    dispatch({ type: 'openModalSaveSelectedBlocks' });
  };

  const DisableOptions = (allowChild = true) => {
    const dataForm: DataForm =
      state.editorFlow?.drawflow.drawflow.Home.data[nodeId]?.data;
    if (dataForm) {
      const form = new DataFormModel(dataForm);
      const blockedIntents = !validateBlockedIntents(intentType);

      if (!allowChild) {
        const isChild = form.isChild() || form.isOthers();
        return isChild || !blockedIntents;
      }

      return !blockedIntents;
    }
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;
  const block = {
    nodeId,
    node,
  };

  const getButtons = (type: string) => {
    switch (type) {
      case 'SelectOpt':
        return (
          <S.SelectOpt
            key={`${type}-button`}
            startIcon={<Check />}
            onClick={() => {
              handleClose();
              addNodeToList(block, state.nodesList);
            }}
            disabled={DisableOptions(false)}
          />
        );
      case 'DeselectOpt':
        return (
          <S.DeselectOpt
            key={`${type}-button`}
            startIcon={<Clear />}
            onClick={() => {
              clearNodeList();
              alert('Blocos desselecionados !');
              handleClose();
            }}
          />
        );
      case 'FlowHeatMap':
        return (
          <S.FlowHeatMap
            key={`${type}-button`}
            startIcon={<HeatmapIcon />}
            onClick={() => {
              //TODO LOBO: LOBO SANKEY CHART
              // window.open("../../lego_sankey_chart.html");
              //************************* */
              setOpenHeatMap(true);
              clearNodeList();
              handleClose();
            }}
          />
        );
      case 'DuplicateOpt':
        return (
          <S.DuplicateOpt
            key={`${type}-button`}
            startIcon={<FileCopy />}
            onClick={() => {
              duplicateNode(block);
              handleClose();
            }}
            disabled={DisableOptions(false) || form.isFlexBlock()}
          />
        );
      case 'CopyToLibOpt':
        return (
          <S.CopyToLibOpt
            key={`${type}-button`}
            startIcon={<FlipToFront />}
            onClick={() => {
              handleOpenModalCopyToLib();
              handleClose();
            }}
            disabled={DisableOptions(false) || form.isFlexBlock()}
          />
        );
      case 'DeleteOpt':
        return (
          <S.DeleteOpt
            key={`${type}-button`}
            startIcon={<Delete />}
            onClick={() => {
              deleteNode(nodeId);
              handleClose();
            }}
            disabled={DisableOptions()}
          />
        );
      default:
        return null;
    }
  };

  const getMenu = (arr: string[]) => arr.map((type) => getButtons(type));

  const buttons = [
    'SelectOpt',
    'DeselectOpt',
    'FlowHeatMap',
    'DuplicateOpt',
    'CopyToLibOpt',
    'DeleteOpt',
  ];

  const handleLockMouseEvent = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        onMouseDown={handleLockMouseEvent}
        onMouseUp={handleLockMouseEvent}
      >
        <SettingsIcon fontSize="medium" />
      </IconButton>
      <S.PopoverContainer
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {getMenu(buttons)}
      </S.PopoverContainer>
      {openHeatMap && (
        <ModalHeatmap
          google={google}
          open={openHeatMap}
          handleClose={() => setOpenHeatMap(false)}
          nodeId={nodeId}
          state={state}
        />
      )}
    </>
  );
}

export default ModalBlockOptions;
