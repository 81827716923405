import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useToast } from 'contexts/ToastContext';
import { useState } from 'react';
import { CreateCustomParam } from 'services/CustomParamsService';
import { CustomParam } from 'services/CustomParamsService/types';
import useTranslator from 'utils/hooks/Translator';
import * as S from '../../styles';
import { ButtonsContainer, CreateParamContainer, CustomChip } from './styles';

const paramTypes = ['string', 'number', 'date'];

export function CreateOrEditParam({
  param,
  open,
  handleClose,
}: {
  param: any;
  open: boolean;
  handleClose: () => void;
}) {
  const classes = S.useStyles();
  const [loading, setLoading] = useState(false);
  const { currentData } = useFintalkCloud();
  const [customParamForm, setCustomParamForm] = useState({
    name: param?.name ?? '',
    type: param?.type ?? 'string',
  });
  const { toastNotification } = useToast();
  const { getTranslation } = useTranslator();

  async function handleCreateCustomParam() {
    setLoading(true);
    try {
      const response = await CreateCustomParam({
        agent: currentData.agentName.split('-')[0],
        param: customParamForm,
      });
      if (response.ok) {
        toastNotification({
          type: 'success',
          message: getTranslation('toast.success.createCustomParam'),
        });

        handleClose();
      }
    } catch (error) {
      console.log(error);
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.createCustomParam'),
      });
    }

    setLoading(false);
  }

  function handleChangeCustomParam(type: keyof CustomParam, value: string) {
    setCustomParamForm((prev) => ({
      ...prev,
      [type]: value.toLocaleLowerCase(),
    }));
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.dialogContainer }}
    >
      <Toolbar>
        <Typography variant="h5" style={{ flex: 1 }}>
          {!!param ? getTranslation('view') : getTranslation('create')} {getTranslation('parameters')}
        </Typography>
      </Toolbar>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <CreateParamContainer>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              component={Paper}
              classes={{ root: classes.customerDetailsTableContainer }}
            >
              <Table aria-label="simple table" size="medium">
                <TableBody>
                  <TableRow>
                    <TableCell scope="row">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold' }}
                      >
                        {getTranslation('name')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Input
                        value={param?.name}
                        disabled={!!param}
                        onChange={(e) =>
                          handleChangeCustomParam('name', e.target.value)
                        }
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold' }}
                      >
                        {getTranslation('types')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {paramTypes.map((paramType) => (
                        <CustomChip
                          key={paramType}
                          label={paramType}
                          color={
                            customParamForm.type === paramType
                              ? 'secondary'
                              : 'default'
                          }
                          variant="outlined"
                          clickable={!param}
                          selected={customParamForm.type === paramType}
                          size="medium"
                          onClick={
                            !param
                              ? (e: { currentTarget: { textContent: any } }) =>
                                  handleChangeCustomParam(
                                    'type',
                                    e.currentTarget.textContent ?? ''
                                  )
                              : null
                          }
                        />
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <ButtonsContainer>
            <Button variant="contained" color="default" onClick={handleClose}>
              {getTranslation('footerButtons.discardAndExit')}
            </Button>
            {!param && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCreateCustomParam}
              >
                {getTranslation('save')}
              </Button>
            )}
          </ButtonsContainer>
        </CreateParamContainer>
      </DialogContent>
    </Dialog>
  );
}
