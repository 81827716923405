import { FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';
import {
  ContentGrid,
  ContentTitlesGrid,
  GridContainer,
  StyledMttTextField,
  StyledMttTypographyFrase,
} from './styles';

const ExcelOrCSV: React.FC = () => {
  return (
    <GridContainer>
      <StyledMttTypographyFrase />
      <ContentGrid>
        <FormControlLabel
          control={
            <Switch
              onChange={() => {}}
              color="primary"
              name="check"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
          label="Permitir download para Excel ou CSV "
        />
        <ContentTitlesGrid>
          <StyledMttTextField
            variant="outlined"
            fullWidth
            label="Nome da tabela"
            inputProps={{}}
            name="choice-input"
            type="text"
            onChange={() => {}}
          />
        </ContentTitlesGrid>
      </ContentGrid>
    </GridContainer>
  );
};

export default ExcelOrCSV;
