import styled from "styled-components";

export const ContainerLoading = styled.div`
    position:absolute; 
    z-index:99999999;
    width:100%; 
    height:100%; 
    display:flex; 
    justify-content:center;
    align-items:center; 
    background-color:rgba(161, 160, 160, 0.589);
`;