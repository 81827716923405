import FT from 'file-type';

export async function getMimeType(file: File) {
    const arrayBuffer = await file.arrayBuffer();
    const buffer = new Uint8Array(arrayBuffer);

    //@ts-ignore
    const type = await FT.fromBuffer(buffer);

    return type ? type.mime : file.type;
};