import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const ContainerDialog = styled(MttGrid)`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  overflow: hidden;
 
	.MuiAppBar-colorPrimary {
    background-color: #0e103b;
    position: absolute;
  }

  .MuiToolbar-regular {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ContainerHeader = styled(MttGrid)`
  overflow-y: auto;
  max-height: 65vh;
`;

export const ContainerImage = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
`;

export const ContentContainer = styled(Grid)`
  overflow-y: auto;

  /* max-height: 65vh; */
`;

export const TagsContainer = styled(Grid)`
  margin-top: 20px;
  padding: 10px;
`;

export const SearchTagContainer = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;

  button {
    margin: 5px;
    width: 200px;
  }
`;

export const TextContainer = styled.div`
  height: 64px;

  margin-right: 10px;
  width: 100%;
`;

export const ChipContainer = styled.div`
  max-width: 100px;

  div {
    margin: 3px;
  }
`;

export const TagsList = styled(Grid)`
  display: flex;
  margin: 10px 8px;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})``;

export const ModalFooter = styled(Typography).attrs({})`
  display: flex;
  text-align: center;
`;

export const ContainerFooter = styled.div`
  align-items: center;
  justify-content: center;
  margin: 0 24px 16px 24px;

  .MuiTypography-body1 {
    color: #808080;
  }
`;

export const ContainerMessage = styled.div`
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;

  .MuiTypography-body1 {
    color: #0e103b;
    font-weight: 500;
  }
`;

export const ModalMessage = styled(Typography).attrs({})`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
