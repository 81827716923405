import { Button, CircularProgress, TextField, Tooltip } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { useApp } from 'contexts/App/appContext';
import { IFlag } from 'models/DataFlow';
import { useEffect, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { GetFlags, UpdateFlags } from 'services/FlagsService';
import useTranslator from 'utils/hooks/Translator';
import * as S from './styles';
import { useStyles } from './styles';
import { IFlagsContainerProps, defaultFlags, emptyFlag } from './types';

export default function FlagsContainer(props: IFlagsContainerProps) {
  const { state, dispatch, form, node, toast } = props;
  const { dispatch: dispatchApp } = useApp();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currFlag, setCurrFlag] = useState(
    form.flag || {
      color: 'transparent',
      title: '',
    }
  );
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { getTranslation } = useTranslator()
  const classes = useStyles()

  const [flagOptions, setFlagOptions] = useState(state.flags ?? defaultFlags);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleNewFlag = () => {
    const newFlag = { title: '', color: '#000' }
    setFlagOptions((prev) => [...prev, newFlag])
  };

  useEffect(() => {
    localStorage.removeItem('@fintalkCloud:flags');
  }, [])

  useEffect(() => {
    loadFlags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, dispatchApp, state.botName])

  const loadFlags = async () => {
    if (open && state.botName) {
      const flagsString = localStorage.getItem('@fintalkCloud:flags');

      if (flagsString) {
        const flags = JSON.parse(flagsString) as IFlag[];
        setFlagOptions(flags ?? emptyFlag);
      } else {
        try {
          setIsLoading(true)

          const resp = await GetFlags(state.botName, dispatchApp);

          if (resp.Success && resp.Data?.data?.flags) {
            const flags = resp.Data.data.flags

            localStorage.setItem('@fintalkCloud:flags', JSON.stringify([...flags]));

            setFlagOptions([...flags]);
          } else {
            toast(
              'error',
              resp.Message ?? 'Ocorreu algum erro ao obter as flags. Por favor, reinicie a página.',
              3000
            );

            setFlagOptions([emptyFlag]);

            localStorage.setItem('@fintalkCloud:flags', JSON.stringify([emptyFlag]));
          }
        } catch (error) {
          toast(
            'error',
            'Ocorreu algum erro ao obter as flags. Por favor, reinicie a página.',
            3000
          );

          localStorage.setItem('@fintalkCloud:flags', JSON.stringify([]));

          setFlagOptions([]);
        } finally {
          setIsLoading(false)
        }
      }
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickFlagIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    const bool = state.editorFlow?.editor_mode === 'fixed';
    if (!bool) {
      if (state) {
        setAnchorEl(event.currentTarget);
      }
    }
  };

  const handleChangeFlagTitle = (newTitle: string, index: number) => {
    flagOptions[index].title = newTitle
    setFlagOptions([...flagOptions])
  };

  const handleChangeFlagColor = (newColorResult: ColorResult) => {
    const index = flagOptions.indexOf(currFlag)
    flagOptions[index].color = newColorResult.hex
    setFlagOptions([...flagOptions])
  };

  const handleChangeFlag = (flag: IFlag) => {
    setCurrFlag(flag)
    setIsColorPickerOpen(flag.color !== 'transparent' && true)

    if (state.editorFlow) {
      state.editorFlow.drawflow.drawflow.Home.data[node].data.flag = flag
    }
  };

  const handleSaveFlags = async () => {
    const response = await UpdateFlags(
      {
        botName: state.botName || '',
        flags: flagOptions,
      },
      dispatchApp
    );

    if (response.Success) {
      const flags = response.Data.data.flags

      dispatch({ type: 'updateForm', data: { flags } })

      localStorage.setItem('@fintalkCloud:flags', JSON.stringify(flags))

      toast('success', 'Flags atualizados com sucesso', 3000);
    } else {
      toast(
        'error',
        response.Message ||
        'Ocorreu algum erro ao atualizar a flag, tente novamente.',
        3000
      );
    }

    handleClose();
  };

  const handleDeleteFlag = async (flag: IFlag) => {
    const flags = flagOptions.filter(flagItem => flagItem !== flag)
    setFlagOptions(flags)
  };

  return (
    <>
      <Tooltip title={currFlag.title} arrow placement="top">
        <S.FlagIcon onClick={handleClickFlagIcon} color={currFlag.color} />
      </Tooltip>

      <S.PopoverContainer
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxHeight: '300px',
            width: '200px',
            maxWidth: '200px',
            padding: '12px',
            background: '#ddd',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          },
        }}
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <>
            {flagOptions.map((flag, index) => (
              <S.FlagContainer key={index}>
                <S.Flag color={flag.color} onClick={() => handleChangeFlag(flag)} />

                <TextField
                  variant="outlined"
                  value={flag.title || ''}
                  onChange={(e) => handleChangeFlagTitle(e.target.value, index)}
                  disabled={flag.color === 'transparent'}
                />

                <Button
                  onClick={() => handleDeleteFlag(flag)}
                  classes={{ root: classes.flagDeleteButton }}
                  disabled={flag.color === 'transparent'}
                >
                  <Delete />
                </Button>
              </S.FlagContainer>
            ))}

            <S.FlagFooterButtonsContainer>
              <Tooltip title="Nova flag" arrow placement="right">
                <Button onClick={() => handleNewFlag()}>
                  <Add />
                </Button>
              </Tooltip>

              <Button onClick={handleSaveFlags} fullWidth variant='contained' color='secondary'>
                Salvar
              </Button>
            </S.FlagFooterButtonsContainer>
          </>
        )}
      </S.PopoverContainer>

      <S.ModalColorPickerContainer
        open={isColorPickerOpen}
        onClose={() => setIsColorPickerOpen(false)}
      >
        <div
          style={{
            background: '#00032e',
            padding: '40px',
            borderRadius: '4px',
            border: '1px solid #353759',
          }}
        >
          <SketchPicker
            color={currFlag.color}
            onChangeComplete={(colorResult) => handleChangeFlagColor(colorResult)}
            disableAlpha
            styles={{
              default: {
                picker: {
                  background: '#0e103b',
                  border: '1px solid #353759',
                  marginBottom: '24px'
                }
              }
            }}
          />

          <Button
            onClick={() => setIsColorPickerOpen(false)}
            variant='contained'
            color='secondary'
            fullWidth
          >
            {getTranslation('footerButtons.saveAndExit')}
          </Button>
        </div>
      </S.ModalColorPickerContainer>
    </>
  );
}
