import React, { createContext, useContext, useReducer } from 'react';
import { BuilderContextStateActionTypes, IBuilderContext, Props } from './types';
import { initialState, reducerBuilder } from './builderReducer';

const BuilderContext = createContext<IBuilderContext>({} as IBuilderContext);

export function BuilderContextProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducerBuilder, initialState);

  function handleBuilderDispatch(type: BuilderContextStateActionTypes) {
    dispatch({ type });
  }

  return (
    <BuilderContext.Provider value={{ state, dispatch, handleBuilderDispatch }}>
      {children}
    </BuilderContext.Provider>
  );
}

export function useBuilder() {
  const context = useContext(BuilderContext);
  return context;
}
