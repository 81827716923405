import styled from 'styled-components';

const styles = {
  Container: styled.div`
  `,
  Content: styled.div`
    background: #b6bad9;
    border-radius: 0.8rem;
    padding: 1rem;
    margin: 1rem 0 1rem 0;
  `,
  Line: styled.div`
    display: flex;
  `,
  LineInput: styled.input`
    width: 30vw;
  `,
  LineSelect: styled.select`
    width: 30vw;
  `,
  Questions: styled.div`
    display: flex;
  `,
  QuestionsAside: styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 30vw;
  `,
  QuestionsContent: styled.div`
    display: flex;
    flex-direction: column;

    input {
      width: 60vw;
    }
  `,
}

export default styles;
