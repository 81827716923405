import React, { useRef, useState } from 'react';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Draggable, { DraggableData } from 'react-draggable';
import * as S from './styles';

type Props = {
  url: string;
  closeFalazap: () => void;
};

const FalazapIframe: React.FC<Props> = ({ url, closeFalazap }) => {
  const nodeRef = useRef(null);
  const [position, setPosition] = useState(
    JSON.parse(
      localStorage.getItem('falazapPosition') ||
        JSON.stringify({
          x: window.innerWidth / 2 - 360 / 2,
          y: window.innerHeight / 2.2 - 650 / 2,
        })
    )
  );

  function handleStopDrag(data: DraggableData) {
    localStorage.setItem(
      'falazapPosition',
      JSON.stringify({
        x: data.lastX,
        y: data.lastY,
      })
    );
    setPosition({
      x: data.lastX,
      y: data.lastY,
    });
  }

  return (
    <Draggable
      onStop={(_, data) => handleStopDrag(data)}
      position={position}
      nodeRef={nodeRef}
			bounds={'body'}
    >
      <S.Container ref={nodeRef}>
        <S.ContainerHeader>
          <S.AudioOutputs />
        </S.ContainerHeader>
        <IconButton onClick={() => closeFalazap()}>
          <CloseIcon htmlColor="#fff" />
        </IconButton>
        <S.IFrame title="falazap" id="falazap-iframe" src={url} allow="microphone;camera" />
        <S.ContainerHeader>
          <S.AudioOutputs />
        </S.ContainerHeader>
      </S.Container>
    </Draggable>
  );
};

export default FalazapIframe;
