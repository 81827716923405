import styled from 'styled-components';
import MttTextField from 'components/Material/MttTextField/MttTextFIeld';
import MttTypography from 'components/Material/MttTypography/MttTypography';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import TextField from '@material-ui/core/TextField/TextField';

export const StyledMttGridMain = styled(MttGrid)`
  width: 100%;
  margin-top: 16px;
`;

export const StyledMttTextField = styled(MttTextField)``;

export const StyledMttTypographyIdentificacao = styled(MttTypography)``;

export const StyledFooterGrid = styled(MttGrid)`
  width: calc(100% - 116px);

  margin: 0 42px;
  padding: 16px;
  border-radius: 4px;

  background-color: #f2f2f2;
`;

export const StyledSwitchGrid = styled(MttGrid).attrs({
  container: true,
  item: true,
  xs: 6,
})`
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

export const StyledMttTypographyPerguntas = styled(MttTypography).attrs({
  children: 'Perguntas obrigatórias',
  variant: 'subtitle2',
  color: 'primary',
})``;

export const StyledGridPerguntas = styled.div<{ showgrid: boolean }>`
  margin-top: 16px;

  display: ${(props) => (props.showgrid ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;


  min-height: 128px;

  max-height: 192px;

	margin-bottom: 16px;

  div {
    margin-bottom: 8px;
    height: 56px;
  }

  div:last-child {
    margin-bottom: 0px;
  }
`;

export const StyledTextFieldPergunta = styled(TextField).attrs({
  label: 'Escreva uma pergunta',
  variant: 'outlined',
})`
  background-color: #ffffff;
  width: 50%;
`;

export const StyledTitleGrid = styled(MttGrid)`
  display: flex;
  justify-content: space-between;
  padding: 4px ​0px 8px 0px;
`;

export const GridIconDelete = styled(MttGrid)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 8px;
  cursor: pointer;
`;

export const StyledComponent = styled(MttGrid).attrs({
  container: true,
  item: true,
  xs: 12,
})`
  display: flex;
`;

export const GridStyledManyOptions = styled(MttGrid)`
  margin-bottom: 8px;
`;

export const StyledInputsGrid = styled(MttGrid)`
  width: 100%;
  display: flex;
  gap: 18px;

  div {
    flex-direction: row;
  }

  .MuiInputBase-input {
    background-color: #ffffff;

    &:focus {
      background-color: #ffffff;
    }
  }
`;

export const ErrorWarning = styled.div`
  width: 100%;
  height: 50px;

  p {
    margin: 3px 0px 0px 5px;
  }
`;
