import { createGlobalStyle } from 'styled-components';
import { defaultTheme } from 'themes/DefaultTheme';

const GlobalStyles = createGlobalStyle`
	*{
		margin: 0;
		padding: 0;
	}

		@media(max-width: 2200px){
			html{
				font-size: 90%;
			} 
			
	 }
	
		@media(max-width: 1200px){
			html{
				font-size: 85%;
			}
	}

	@media(max-width: 720px){
		html{
			font-size: 70%;
		}
	}

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
		overflow: hidden;

		.MuiAutocomplete-listbox {
			border: ${() => defaultTheme.border.primary};
			background: ${() => defaultTheme.palette.primary.lightBackground};
			color: #aaa;
			border-radius: ${() => defaultTheme.palette.primary.defaultBackground};

      .MuiAutocomplete-option:hover {
        color: #fff;
      }
    }

  }

  .greenButtonMaterial{
		
    .MuiButton-colorInherit{
      background-color: #34AC26;
      color: #fff;
     
      :hover{
        background-color: #1C7412;
      }
    }
  }

  .lightBlueButtonMaterial{
    .MuiButton-colorInherit{
      background-color: #50A4A4;
      color: #fff;

      
      :hover{
        background-color: #418787;
      }
    }
  }

  .darkBlueButtonMaterial{
    .MuiButton-colorInherit{
      background-color: #005AE1;
      color: #fff;
      
      :hover{
        background-color: #024ec1;
      }
    }
  }

  .orangeButtonMaterial{
    .MuiButton-colorInherit{
      background-color: #E46B01;
      color: #fff;
    
      :hover{
        background-color: #c25c02;
      }
    }
  }

  .grayButtonMaterial{
    .MuiButton-colorInherit{
      background-color: #878787;
      color: #fff;
    
      :hover{
        background-color: #767676;
      }
    }
  }

  .redButtonMaterial{
    .MuiButton-colorInherit{
      background-color: #DC1414;
      color: #fff;
    
      :hover{
        background-color: #800909;
      }
    }
  }
`;

export default GlobalStyles;
