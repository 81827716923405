import styled from 'styled-components';

export const BlockFlow = styled.div`
  /* position: absolute; */
  z-index: 9;
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(161, 160, 160, 0.589);
  :hover{
      cursor: default;
  }
`;
