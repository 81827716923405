import { Theme, createStyles, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const DashboardContainer = styled.div`
  height: 100dvh;
  overflow: hidden;
  background: #00032e;
  color: #fff;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ElementContainer = styled.div`
  width: 100%;
  height: calc(100% - 128px);
  display: flex;
  gap: 8px;
`;

export const DashboardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  img {
    max-width: 150px;
    border-radius: 8px;
  }
`;

export const DashboardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0e103b;
  border-radius: 8px;
  border: 1px solid #353759;
`;

export const AutoCompletesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`;

export const AutoCompleteWrap = styled.div`
  min-width: 186px;
  margin: 4px;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  padding: 0 8px;

  .MuiTypography-body1 {
    max-height: 32px;
    padding: 0 8px;
    font-size: 12px;
  }

  input {
    height: auto;
  }

  button {
    width: auto;
    height: auto;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 8px;
    font-size: 12px;
    background-color: #26274c;
    color: #fff;
    border: 1px solid #353759;
    border-radius: 18px;

    svg {
      fill: #fff;
    }
  }
  .MuiOutlinedInput-notchedOutline:hover {
    border-color: red;
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    img: {
      background: theme.palette.primary.main,
    },
  })
);
