import { IAppContextState, IAppContextActions } from "./types";

export const initialState = {
  signed: false,
  load: true,
  renderModalPasswordReset: false,
  passwordResetRequired: false,
	renderModalUsedTab: false,
	renderModalForgotPassword: false,
} as IAppContextState;

export function reducerApp(
  state: IAppContextState,
  action: IAppContextActions
): IAppContextState {
  switch (action.type) {
    case "updateApp":
      return functionsReducer[action.type](state, action.data);
    case "openModalPasswordReset":
      return functionsReducer[action.type](state, action.data);
    case "closeModalPasswordReset":
      return functionsReducer[action.type](state, action.data);
    case "openModalPasswordResetUnauthenticated":
      return functionsReducer[action.type](state, action.data);
    case "openModalUsedTab":
      return functionsReducer[action.type](state, action.data);
    default:
      return state;
    // throw new Error();
  }
}

const functionsReducer = {
  updateApp: (state: IAppContextState, data?: Partial<IAppContextState>) => {
    return { ...state, ...data };
  },
  openModalPasswordReset: (state: IAppContextState, data?: Partial<IAppContextState>) => {
    return { ...state, ...data };
  },
  openModalPasswordResetUnauthenticated: (state: IAppContextState, data?: Partial<IAppContextState>) => {
    return { ...state, ...data };
  },
  closeModalPasswordReset: (state: IAppContextState, data?: Partial<IAppContextState>) => {
    return { ...state, ...data };
  },
  openModalUsedTab: (state: IAppContextState, data?: Partial<IAppContextState>) => {
    return { ...state, ...data };
  },
};
