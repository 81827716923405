import { MetaBlockType } from 'contexts/Flow/types';
import { getArrayConnectors } from 'utils/getConnectors';
import * as yup from 'yup';
import { TFormErrors } from '../contexts/Form/types';
import EIntentType from '../enums/EIntentType';
import { DataFlow, DataForm } from '../models/DataFlow';
import DataFormModel from '../models/DataFormModel';
import { infoValidation } from '../models/InfoForm';
import { toNewIntentionValidation } from '../models/ToNewIntention';

interface TranslationErrors {
  intentNameRequired: string;
  intentNameDuplicated: string;
  outputTitleRequired: string;
  outputTitleDuplicated: string;
  totalSum: string;
  formMessage: string;
  entryName: string;
  apiInfoUrl?: string;
}

export const schema = yup.object().shape({
  name: yup.string().required().max(40),
});

export const formValidators: {
  [key: string]: (
    form: DataForm,
    errorMsg?: TranslationErrors,
    drawflow?: DataFlow,
    nodeId?: number
  ) => Promise<TFormErrors>;
} = {
  Info: async (
    form: DataForm,
    errorMsg?: TranslationErrors,
    drawflow?: DataFlow,
    nodeId?: number
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    if (form) {
      const resultValidation = await infoValidation(
        { ...form },
        errorMsg?.intentNameRequired || ''
      );
      if (!resultValidation.isValid) {
        result = { ...resultValidation.errors };
      } else {
        if (drawflow) {
          const mainDataModel = new DataFormModel({ ...form });
          if (!mainDataModel.isChild() && !mainDataModel.isOthers()) {
            const keys = Object.keys(drawflow).filter((key) => {
              if (Number(key) === nodeId) return false;
              const { data } = drawflow[Number(key)];
              const currentdataModel = new DataFormModel(data);
              if (!currentdataModel.isChild() && !currentdataModel.isOthers()) {
                return (
                  currentdataModel.name.toLocaleLowerCase() ===
                  mainDataModel.name.toLocaleLowerCase()
                );
              }
              return false;
            });

            if (!!keys.length) {
              result = { name: errorMsg?.intentNameDuplicated || '' };
            }
          }
        }
      }
    }
    return result;
  },

  Bubble: async (
    form: DataForm,
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};

    if (!form.bubbleInfo?.bubbleId) {
      result = { bubble: 'Selecione uma Bubble.' }
    }

    return result;
  },

  AI123: async (
    form: DataForm,
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};

    if (!form.ai123Info?.contentGroupId) {
      result = { contentGroup: 'Selecione um Grupo de Conteúdo.' }
    }

    return result;
  },

  ToNewIntention: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    if (form.linked) {
      const resultValidation = await toNewIntentionValidation(form.linked);
      if (!resultValidation.isValid) {
        result = { ...resultValidation.errors };
      }
    }
    return result;
  },

  OutputIntentName: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};

    if (form.dataBlockly?.payload) {
      const arrayConnectors = getArrayConnectors(form.dataBlockly?.payload);

      for (let index = 0; index < arrayConnectors.length; index++) {
        const output = arrayConnectors[index];
        const amount = arrayConnectors.filter(
          (connector) => connector.trim().toLocaleLowerCase() === output.trim().toLocaleLowerCase()
        ).length;

        if (amount > 1) {
          result = {
            ...result,
            [`outputs[${index}].title`]: errorMsg?.outputTitleDuplicated || '',
          };
        }
      }
      return result;
    }

    if (form.outputs && form.outputs.length > 1) {
      const { outputs } = form;

      for (let index = 0; index < outputs.length; index++) {
        const output = outputs[index];
        const amount = outputs.filter(
          (o) =>
            o.title.trim().toLocaleLowerCase() ===
            output.title.trim().toLocaleLowerCase()
        ).length;

        if (amount > 1) {
          result = {
            ...result,
            [`outputs[${index}].title`]: errorMsg?.outputTitleDuplicated || '',
          };
        }
      }
    }

    if (form.outputs) {
      const { outputs } = form;

      for (let index = 0; index < outputs.length; index++) {
        if (outputs[index].title.trim() === '') {
          result = {
            ...result,
            [`outputs[${index}].title`]: errorMsg?.outputTitleRequired || '',
          };
        }
      }
    }

    return result;
  },

  RocketChatId: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};

    if (form.rocketChat?.rocketID === '') {
      result = { rocketChatId: 'Rocket Chat ID obrigatório.' };
    }
    return result;
  },

  ABTestValidation: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    const { outputs } = form;

    if (outputs) {
      for (let index = 0; index < outputs.length; index++) {
        if (
          !outputs[index].value ||
          outputs[index].value?.trim() === '' ||
          outputs[index].value === '0'
        ) {
          result = {
            ...result,
            [`outputs[${index}].value`]:
              '*O valor da saída não pode ser vazio ou zero',
          };
        }
      }

      let sum = 0;
      for (let index = 0; index < outputs.length; index++) {
        if (Number(outputs[index].value)) {
          sum += Number(outputs[index].value);
        }
      }

      if (sum < 100) {
        result = {
          ...result,
          sum: errorMsg?.totalSum,
        };
      }
    }
    return result;
  },

  RulesValidation: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    const { outputs } = form;

    if (outputs) {
      for (let index = 0; index < outputs.length; index++) {
        const { rules } = outputs[index];

        if (rules) {
          for (let jIndex = 0; jIndex < rules.length; jIndex++) {
            if (rules[jIndex].operator?.trim() === '') {
              result = {
                ...result,
                [`outputs[${index}].rules[${jIndex}].operator`]:
                  '*Escolha uma regra de validação',
              };
            }
          }
        }
      }
    }
    return result;
  },

  RequiredMsg: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    const msg = form.sendUser?.message;
    if (!msg) {
      result = {
        ...result,
        [`formMsg`]: errorMsg?.formMessage,
      };
    }
    return result;
  },

  RequireEntryName: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    const msg = form.entryName;
    if (!msg) {
      result = {
        ...result,
        [`entryName`]: errorMsg?.entryName,
      };
    }
    return result;
  },

  ApiUrl: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    const url = form.apiInfo?.url;
    if (!url) {
      result = {
        ...result,
        [`apiInfoUrl`]: errorMsg?.apiInfoUrl,
      };
    }
    return result;
  },
};

export const getValidatorsIntentType = (intentType: EIntentType) => {
  switch (intentType) {
    case EIntentType.InitialFlow:
      return ['Info'];
    case EIntentType.NewGroup:
      return ['Info', 'ToNewIntention'];
    case EIntentType.CarouselParent:
      return ['Info', 'OutputIntentName'];
    case EIntentType.MultipleChoiceParent:
      return ['Info', 'OutputIntentName'];
    case EIntentType.OptionsBlock:
      return ['Info', 'OutputIntentName', 'RequiredMsg'];
    case EIntentType.Carousel:
      return ['Info', 'OutputIntentName'];
    case EIntentType.Rules:
      return ['Info', 'OutputIntentName'];
    case EIntentType.ABTest:
      return ['Info', 'OutputIntentName', 'ABTestValidation'];
    case EIntentType.Livechat:
      return ['Info', 'RocketChatId'];
    case EIntentType.FlexBlock:
      return ['Info', 'RequireEntryName'];
    case EIntentType.MetadataBlock:
      return ['Info', 'ABTestValidation', 'OutputIntentName'];
    default:
      return ['Info'];
  }
};

export const getMetaBlocksValidatorsIntentType = (
  intentType: MetaBlockType
) => {
  switch (intentType) {
    case 'ABTest':
      return ['Info', 'ABTestValidation', 'OutputIntentName'];
    case 'Api':
      return ['Info', 'OutputIntentName', 'ApiUrl'];
    case 'Bubble':
      return ['Info', 'Bubble' /*, 'BubbleVersion' */]
    case 'Carousel':
      return ['Info', 'OutputIntentName'];
    case 'Entry':
      return ['Info'];
    case 'GetDocument':
      return ['Info', 'OutputIntentName'];
    case 'Logic':
      return ['Info', 'OutputIntentName'];
    case 'Message':
      return ['Info', 'OutputIntentName'];
    case 'Multiple':
      return ['Info', 'OutputIntentName'];
    case 'Question':
      return ['Info', 'OutputIntentName'];
    case 'ToAnotherBlock':
      return ['Info', 'ToNewIntention'];
    case 'Conditional':
      return ['Info', 'OutputIntentName'];
    case 'AI123':
      return ['Info', 'AI123'];
    case 'Advanced':
      return ['Info', 'OutputIntentName'];
    default:
      return ['Info'];
  }
};
