import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {
  Container,
  HeaderContent,
  HeaderOptions,
  TitleTypography,
} from './styles';
// import { useFlow } from 'contexts/Flow/flowContext';
// import GetVarsFlowController from 'controllers/intent/GetVarsFlowController';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 450,
    backgroundColor: '#f2f2f2',
  },
});

interface Column {
  id: 'gravar' | 'tipo' | 'nomeVariavel' | 'nomeColuna';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const ExportDataSpreadSheet: React.FC = () => {
  // const {state} = useFlow()
  const [stateSwitch, setStateSwitch] = React.useState({
    checkedA: true,
  });
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const columns: Column[] = [
    { id: 'gravar', label: 'Gravar', minWidth: 50 },
    { id: 'tipo', label: 'Tipo', minWidth: 70 },
    {
      id: 'nomeVariavel',
      label: 'Nome da variável',
      minWidth: 100,
    },
    {
      id: 'nomeColuna',
      label: 'Nome da coluna',
      minWidth: 200,
    },
  ];

  interface Data {
    gravar: JSX.Element;
    tipo: string;
    nomeVariavel: string;
    nomeColuna: string;
  }

  function createData(
    gravar: JSX.Element,
    tipo: string,
    nomeVariavel: string,
    nomeColuna: string
  ): Data {
    return { gravar, tipo, nomeColuna, nomeVariavel };
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateSwitch({
      ...stateSwitch,
      [event.target.name]: event.target.checked,
    });
  };

  const rows = [
    createData(
      <Switch
        checked={stateSwitch.checkedA}
        onChange={handleChange}
        color="primary"
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />,
      'Bloco',
      'cep',
      'CEP Cli'
    ),
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // useEffect(()=>{
  //   if(state.editorFlow){
  //     const blocks = state.editorFlow.drawflow.drawflow.Home.data;
  //     const vars = new GetVarsFlowController(blocks).getVars();
			
  //   }
  // },[state.editorFlow])

  return (
    <Container>
      <TitleTypography />
      <HeaderContent>
        <HeaderOptions>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: '#f2f2f2',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.tipo}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 50]}
              labelRowsPerPage="Linhas por página"
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </HeaderOptions>
      </HeaderContent>
    </Container>
  );
};

export default ExportDataSpreadSheet;
