import { Dialog, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { useForm } from 'contexts/Form/formContext';
import { DrawflowModel } from 'models/DrawflowModel';
import React, { useCallback, useEffect, useState } from 'react';
import { GetFlows } from 'services/BotService';
import BlocklyConstructor from '../BlocklyConstructor/';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalLogic: React.FC = () => {
  const { botName } = useFlow().state;
	const {dispatch: dispatchApp} = useApp();
  const { state, dispatch } = useForm();
  const { dataForm } = state;

  const [flows, setFlows] = useState<any[]>([]);

  const load = useCallback(() => {
    if (!!dataForm && !dataForm?.dataBlockly) {
      dataForm.dataBlockly = {
        lastVersion: 0,
        payload: '',
        xml: `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
        compiledPayload: '',
      };
      dispatch({
        type: 'updateForm',
        data: {
          ...state,
        },
      });
    }
  }, [dataForm, dispatch, state]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    let isMounted = true

    async function loadFlows() {
      const result = await GetFlows({
        bot_name: botName || '',
      }, dispatchApp);
      if (result.Success) {
        const flowsResult = result.Data.data.groups
          .map((flow) => {
            if (!flow.blocks.drawflow) return null;
            const home = flow.blocks.drawflow.Home;
            const drawflowModel = new DrawflowModel(home.data, flow.group_name);
            home.data = drawflowModel.getDrawflow();
            return flow.blocks;
          })
          .filter((f) => !!f);

        if (isMounted) {
          setFlows(flowsResult);
        }
      }
    }

    loadFlows();

    return () => {
      isMounted = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName]);

  if (!state.openModalLogicEditor) return <></>;

  return (
    <Dialog
      open={state.openModalLogicEditor}
      TransitionComponent={Transition}
      fullScreen
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div style={{ zIndex: 9999 }} id="modal-container-blockly">
        <BlocklyConstructor
          flows={flows}
          dataForm={dataForm}
          readOnly={false}
          trashcan={true}
          media={'media/'}
          move={{
            scrollbars: true,
            drag: true,
            wheel: true,
          }}
          initialXml={
            dataForm?.dataBlockly?.xml ||
            `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`
          }
        />
      </div>
    </Dialog>
  );
};

export default ModalLogic;
