import IconButton from '@material-ui/core/IconButton';
import { useEffect, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

import * as S from './styles';

import { useApp } from 'contexts/App/appContext';
import { useCompanies } from 'contexts/Company';
import { Company, emptyCompany } from 'contexts/Company/types';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useToast } from 'contexts/ToastContext';
import { GetCompanies, GetCompany } from 'services/CompaniesService/Company';
import useTranslator from 'utils/hooks/Translator';
import { ModalCompanyEdit } from './ModalCompanyEdit';

export default function ModalManageCompanies() {
  const { toastNotification } = useToast();
  const { isRoot, getCompanies } = usePermissions();
  const { dispatch: dispatchApp } = useApp();

  const { setCompany } = useCompanies();
  const [companies, setCompanies] = useState<string[]>([]);

  const { getTranslation } = useTranslator();

  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (isRoot()) {
      handleSetCompanyIsRoot();
    } else {
      setCompanies(handleGetAdminNames());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function closeEditCompany() {
    setCompany(emptyCompany, true);
    setShowEdit(false);
    if (isRoot()) handleSetCompanyIsRoot();
  }

  async function handleSetCompanyIsRoot() {
    const companysFetch = await GetCompanies(dispatchApp);
    if (companysFetch.Success) {
      const companiesNames = (companysFetch.Data as Company[]).map(
        (company) => company.name
      );
      setCompanies(companiesNames);
    } else {
      // toastNotification('error', 'Erro ao buscar dados da empresa');
    }
  }

  function handleGetAdminNames(): string[] {
    const filteredCompanies = getCompanies()
      .filter((obj) => obj.isAdmin)
      .map((obj) => obj.name);

    console.log(filteredCompanies);
    return filteredCompanies;
  }

  async function handleEditCompany(companyName: string) {
    const result = await GetCompany(companyName, dispatchApp);
    if (result.Success) {
      const {
        agents_limit: agentsLimit,
        users_limit: usersLimit,
        admins,
        agents,
        users,
        login_with_ad,
        name,
        ...rest
      } = result.Data;
      setCompany(
        {
          ...rest,
          agentsLimit,
          usersLimit,
          loginWithAd: login_with_ad,
          admins,
          agents,
          users,
          name,
        },
        true
      );
      setShowEdit(true);
    } else {
      toastNotification({
        type: 'error',
        message: 'Erro ao buscar dados da empresa',
      });
    }
  }

  return (
    <>
      <S.ContainerDialog>
        <S.Container>
          <S.FieldContainer>
            <Table size="small" style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{getTranslation('company')}</TableCell>
                  <TableCell className="tableHeadCell-Button" />
                </TableRow>
              </TableHead>
              <TableBody>
                {companies.sort().map((company) => (
                  <S.HoverableRow key={company}>
                    <TableCell component="th">{company}</TableCell>
                    <TableCell className="tableCell-Button">
                      <IconButton
                        edge="end"
                        color="default"
                        onClick={() => handleEditCompany(company)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </S.HoverableRow>
                ))}
              </TableBody>
            </Table>
          </S.FieldContainer>
        </S.Container>

        <S.ButtonContainer>
          {isRoot() && (
            <S.Button
              style={{ letterSpacing: 2 }}
              variant="contained"
              color="secondary"
              onClick={() => setShowEdit(true)}
            >
              {getTranslation('add')}
            </S.Button>
          )}
        </S.ButtonContainer>
      </S.ContainerDialog>

      {showEdit && (
        <ModalCompanyEdit
          handleEditCompany={handleEditCompany}
          handleClose={closeEditCompany}
        />
      )}
    </>
  );
}
