import styles from './styles';
import { useCallback, useEffect } from 'react';
import { useForm } from 'contexts/Form/formContext';
import * as S from './styles';
import { MenuItem, Select, TextField, Typography } from '@material-ui/core';
import GlobalVariablesSelection from '../GlobalVariablesSelection';
import useTranslator from 'utils/hooks/Translator';

const { InputLine } = styles;

export default function SimpleQuestionFields() {
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && !dataForm.inputs) {
      dataForm.inputs = {
        userSentences: ['', '', ''],
        variables: [
          {
            name: '',
            vartype: '',
            questions: ['', '', ''],
            required: false,
            value: '',
            defaultValue: '',
            isList: false,
          },
        ],
      };

      dataForm.sendUser = {
        message: '',
        messageDidNotUnderstood: '',
      };

      if (!dataForm.varValue) {
        dataForm.varValue = 'vars.resposta';
      }

      if (!dataForm.messageType) {
        dataForm.messageType = 'text';
      }

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeTitle = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!!dataForm && !!dataForm.inputs?.variables[0]?.questions) {
      const { value } = e.target;
      const phrases =
        dataForm.inputs?.variables[0].questions.map((sentence, i) =>
          i === index ? value : sentence
        ) || [];

      dataForm.inputs.variables[0].questions = phrases;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeField = (
    e: React.ChangeEvent<
      | { name?: string | undefined; value: unknown }
      | HTMLTextAreaElement
      | HTMLInputElement
    >,
    field: 'varValue' | 'sendUser' | 'messageType'
  ) => {
    if (!!dataForm) {
      let validatedValue = String(e.target.value);

      if (field === 'sendUser' && !!dataForm.sendUser)
        dataForm.sendUser.messageDidNotUnderstood = validatedValue;
      else if (field === 'varValue') {
      } else dataForm[field] = validatedValue;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const renderSentences = (sentence: string, i: number) => {
    return (
      <InputLine key={`sentence-${i}`}>
        <strong>{getTranslation('question')}</strong>

        <TextField
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeTitle(i, e);
          }}
          value={sentence}
          fullWidth
          multiline
          minRows={2}
        />
      </InputLine>
    );
  };

  return (
    <S.Container>
      <S.MsgForUserTypography variant="h6" color="primary">
        {getTranslation('info.messageForUser')}
      </S.MsgForUserTypography>
      <S.ContentQuestions>
        <div>
          {dataForm?.inputs?.variables[0].questions?.map(renderSentences)}
        </div>
      </S.ContentQuestions>

      <S.Content>
        <S.VariableTypography variant="h6" color="primary">
          {getTranslation('responseVariable')}
        </S.VariableTypography>
        <S.VariableContainer>
          <Typography variant="body1">
            {getTranslation('typeOfVariable')}
          </Typography>
          <Select
            value={dataForm?.messageType || 'text'}
            variant="outlined"
            onChange={(e) => handleChangeField(e, 'messageType')}
          >
            <MenuItem value={'text'}>
              {getTranslation('typesOfVariable.text')}
            </MenuItem>
            <MenuItem value={'anyNumber'}>
              {getTranslation('typesOfVariable.number')}
            </MenuItem>
            <MenuItem value={'email'}>
              {getTranslation('typesOfVariable.email')}
            </MenuItem>
            <MenuItem value={'data'}>
              {getTranslation('typesOfVariable.data')}
            </MenuItem>
            <MenuItem value={'phone'}>
              {getTranslation('typesOfVariable.phone')}
            </MenuItem>
            <MenuItem value={'money'}>
              {getTranslation('typesOfVariable.money')}
            </MenuItem>
            <MenuItem value={'lettersOnly'}>
              {getTranslation('typesOfVariable.lettersOnly')} letras
            </MenuItem>
            <MenuItem value={'cpf'}>
              {getTranslation('typesOfVariable.cpf')}
            </MenuItem>
            <MenuItem value={'cnpj'}>
              {getTranslation('typesOfVariable.cnpj')}
            </MenuItem>
            <MenuItem value={'cep'}>
              {getTranslation('typesOfVariable.cep')}
            </MenuItem>
            <MenuItem value={'password'}>
              {getTranslation('typesOfVariable.password')}
            </MenuItem>
            <MenuItem value={'url'}>URL</MenuItem>
            <MenuItem value={'uuid'}>Uuid</MenuItem>
            <MenuItem value={'location'}>
              {getTranslation('typesOfVariable.location')}
            </MenuItem>
          </Select>

          <GlobalVariablesSelection showTitle />

          {/* <FormGroup>
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Mais de uma variável?"
            />
          </FormGroup> */}
        </S.VariableContainer>
        <S.Content>
          <Typography variant="body1">
            {getTranslation('messageDidNotUnderstood')}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={dataForm?.sendUser?.messageDidNotUnderstood}
            onChange={(e) => handleChangeField(e, 'sendUser')}
            multiline
            minRows={3}
          />
        </S.Content>
      </S.Content>
    </S.Container>
  );
}
