import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IGetLibraryRequest,
  IGetLibraryResponse,
  IRemoveLibraryRequest,
  IRemoveLibraryResponse,
  ISaveLibraryRequest,
  ISaveLibraryResponse,
  IUpdateLibraryRequest,
} from './types';

export const GetLibrary = async (
  request: IGetLibraryRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetLibraryResponse>> => {
  const action = `bots/${request.bot_name}/libraries`;
  return json<IGetLibraryResponse>('GET', action, dispatch);
};

export const SaveLibrary = async (
  request: ISaveLibraryRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ISaveLibraryResponse>> => {
  const action = `bots/${request.bot_name}/libraries`;
  return json<ISaveLibraryResponse>('POST', action, dispatch, request);
};

export const UpdateLibrary = async (
  botName: string,
  request: IUpdateLibraryRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IRemoveLibraryResponse>> => {
  const action = `bots/${botName}/libraries/${request.name}`;
  return json<IRemoveLibraryResponse>('PATCH', action, dispatch, request);
};

export const RemoveLibrary = async (
  request: IRemoveLibraryRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IRemoveLibraryResponse>> => {
  const action = `bots/${request.bot_name}/libraries/${request.library_name}`;
  return json<IRemoveLibraryResponse>('DELETE', action, dispatch);
};
