import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
const drawerWidth = 270;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiDrawer-paper': {
        position: 'unset',
        borderRadius: 8,
        border: '1px solid #353759',
				background: theme.palette.primary.main,
				color: '#fff',
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      top: 'auto',
      zIndex: 'auto',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      top: 'auto',
      zIndex: 'auto',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '4px',
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    sidebarListItem: {
      '& .MuiListItemText-root, & .MuiListItemIcon-root svg, & .MuiListItemIcon-root svg path': {
        color: '#fff',
        fill: '#fff',
      },

      '&.MuiButtonBase-root:hover': {
        '& .MuiListItemText-root, & .MuiListItemIcon-root svg, & .MuiListItemIcon-root svg path': {
          color: theme.palette.secondary.main,
          fill: theme.palette.secondary.main,
        },
      }
    },
  })
);
