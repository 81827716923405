import { IFormContextState, IFormContextActions } from "./types";

export const initialState = {
  dataForm: null,
  openModalLogicEditor: false,
  openLogicEditorCampaign: false,
	openModalLogicEditorNoSql: false,
  intents: [],
  handlesValidators: {},
  formErrors: {},
} as IFormContextState;

export function reducerForm(
  state: IFormContextState,
  action: IFormContextActions
): IFormContextState {
  return functionsReducer[action.type](state, action.data);
}

const functionsReducer = {
  updateForm: (state: IFormContextState, data?: Partial<IFormContextState>) => {
    return { ...state, ...data };
  }
};

export type IFormContextActionsTypes = keyof typeof functionsReducer;
