import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IDeleteGroupRequest,
  IGetGroupRequest,
  IGetGroupResponse,
  IPostBlockGroup,
  ISaveGroupRequest,
  ISaveGroupResponse,
  IUpdateGroupRequest,
  IUpdateGroupResponse,
  IGetGroupBlocsCountsResponse,
	IGetGroupsInUse
} from './types';

export const SaveGroup = async (
  request: ISaveGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ISaveGroupResponse>> => {
  const action = `bots/${request.bot_name}/groups/${request.group_name}`;
  return await json<ISaveGroupResponse>(
    'POST',
    action,
    dispatch,
    request.request
  );
};

export const UpdateGroup = async (
  request: IUpdateGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IUpdateGroupResponse>> => {
  const action = `bots/${request.bot_name}/groups/${request.group_name}`;
  return await json<IUpdateGroupResponse>('PATCH', action, dispatch, request);
};

export const GetGroup = async (
  request: IGetGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetGroupResponse>> => {
  const action = `bots/${request.bot_name}/groups/${request.groupName}`;
  return await json<IGetGroupResponse>('GET', action, dispatch);
};

export const GetGroupIsLiberated = async (
  request: IGetGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IPostBlockGroup>> => {
  const action = `lock/${request.bot_name}/groups/${request.groupName}`;
  return await json<IPostBlockGroup>('GET', action, dispatch);
};

export const GetGroupsInUse = async (
  botName: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetGroupsInUse>> => {
  const action = `lock/${botName}/groups`;
  return await json<IGetGroupsInUse>('GET', action, dispatch);
};

export const ReleaseGroupAccess = async (
  request: IGetGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetGroupResponse>> => {
  const action = `lock/${request.bot_name}/groups/${request.groupName}`;
  return await json<IGetGroupResponse>('DELETE', action, dispatch);
};

export const UpdateLastAccess = async (
  request: IGetGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetGroupResponse>> => {
  const action = `lock/${request.bot_name}/groups/${request.groupName}`;

  return await json<IGetGroupResponse>('PATCH', action, dispatch);
};

export const BlockGroupAccess = async (
  request: IGetGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IPostBlockGroup>> => {
  const action = `lock/${request.bot_name}/groups/${request.groupName}`;
  return await json<IPostBlockGroup>('POST', action, dispatch);
};

export const DeleteGroup = async (
  request: IDeleteGroupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<void>> => {
  const { bot_name, group_name, group_name_confirmation } = request;
  const action = `bots/${bot_name}/groups/${group_name}`;
  return await json<void>('DELETE', action, dispatch, group_name_confirmation);
};

export const GetGroupBlocksCounts = async (
  botName: string,
  groupName: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetGroupBlocsCountsResponse>> => {
  const action = `bots/${botName}/groups/${groupName}/counts`;
  return await json<IGetGroupBlocsCountsResponse>('GET', action, dispatch);
};
