/* eslint-disable no-extend-native */
import React, { useEffect, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

import './styles.css';
import InfoIcon from '@material-ui/icons/Info';
import { ModalCustomerProps } from './types';
import {
  GetOneConversation,
  GetOneCustomer,
} from 'services/ConversationService';
import { Customer, Message } from 'models/Conversation';
import {
  AppBar,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ContainerDialog,
  ContentContainer,
  MsgFieldContainer,
  ClientInfo,
  PopoverContainer,
  useStyles,
  MessagesContainer,
  DateChip,
} from './style';

import { useApp } from 'contexts/App/appContext';
import useTranslator from 'utils/hooks/Translator';

import { formatDateHour, hour } from 'utils/Date';
import { subHours, format, isToday } from 'date-fns';
import { isYesterday } from 'date-fns/esm';
import { ptBR, enUS, es } from 'date-fns/locale';

import i18n from 'utils/i18n';

import { Filter } from './Filter';
import Search from '@material-ui/icons/Search';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalCustomer(props: ModalCustomerProps) {
  const classes = useStyles();
  const { dispatch: dispatchApp } = useApp();
  const { open, bot_name, customer, handleClose, messageSelected } = props;

  const [currentCustomer, setCurrentCustomer] = useState<Customer | null>(null);
  const [conversation, setConversation] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFilter, setOpenFilter] = useState(true);

  const dateConfigString =
    i18n.language === 'en-US' ? 'dd MMM yyy' : "dd 'de' MMMM 'de' yyyy";

  const currLocale =
    i18n.language === 'en-US' ? enUS : i18n.language === 'pt-BR' ? ptBR : es;

  const { getTranslation } = useTranslator();

  const close = () => {
    window.location.href = '#';
    handleClose();
  };

  useEffect(() => {
    async function load() {
      setLoading(true);
      const resultCustomer = await GetOneCustomer(
        { bot_name, customer },
        dispatchApp
      );

      if (resultCustomer.Success) {
        setCurrentCustomer(resultCustomer.Data.data);
      } else {
        setCurrentCustomer(null);
      }
      const resultConversation = await GetOneConversation(
        {
          bot_name,
          customer,
        },
        dispatchApp
      );
      if (resultConversation.Success) {
        setConversation(resultConversation.Data.data);
      } else {
        setConversation([]);
      }
      setLoading(false);

      const div = document.getElementById('scrollDiv');

      if (messageSelected) {
        const selectedGrid = document.getElementById(
          `${messageSelected.message.msg}-${format(
            new Date(messageSelected.timestamp),
            'yyyy-MM-dd HH:MM:ss'
          )}`
        );

        if (selectedGrid) {
          selectedGrid.scrollIntoView({ behavior: 'smooth' });
        }
      }

      if (!messageSelected && div) {
        div.scrollTop = div.scrollHeight - div.clientHeight;
      } else
        window.location.href = `#CD${messageSelected?.message.msg}-${subHours(
          new Date(messageSelected?.timestamp || ''),
          3
        )}`;
    }
    if (open) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, bot_name, customer]);

  function handleClickInfo(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClosePopover() {
    setAnchorEl(null);
  }

  function getMessageClass(row: Message) {
    let currClass = `${classes.msgContainer}`;
    if (row.messageType === 'outgoing') currClass += ` ${classes.botMsg}`;
    else currClass += ` ${classes.userMsg}`;

    if (messageSelected && row.message === messageSelected.message.msg)
      currClass += ` ${classes.selectedMsg}`;

    return currClass;
  }

  function groupMessagesByDay(
    currDate: number | Date,
    messagesByEveryDay: { day: string; rows: Message[] }[],
    row: Message
  ) {
    if (isToday(currDate)) {
      const index = messagesByEveryDay.findIndex(
        (m: { day: string }) => m.day === getTranslation('today')
      );

      if (index !== -1) messagesByEveryDay[index].rows.push(row);
      else
        messagesByEveryDay.push({ day: getTranslation('today'), rows: [row] });
    } else if (isYesterday(currDate)) {
      const index = messagesByEveryDay.findIndex(
        (m: { day: string }) => m.day === getTranslation('yesterday')
      );

      if (index !== -1) messagesByEveryDay[index].rows.push(row);
      else
        messagesByEveryDay.push({
          day: getTranslation('yesterday'),
          rows: [row],
        });
    } else {
      const formattedDate = format(currDate, dateConfigString, {
        locale: currLocale,
      });

      const index = messagesByEveryDay.findIndex(
        (m: { day: string }) => m.day === formattedDate
      );

      if (index !== -1) messagesByEveryDay[index].rows.push(row);
      else messagesByEveryDay.push({ day: formattedDate, rows: [row] });
    }
  }

  const renderMessagesByDate = useMemo(() => {
    const messagesByEveryDay: { day: string; rows: Message[] }[] = [];

    conversation.forEach((row) => {
      const currDate = new Date(row.timestamp);
      groupMessagesByDay(currDate, messagesByEveryDay, row);
    });

    if (messagesByEveryDay) {
      return messagesByEveryDay
        .map((messagesGroup) => (
          <>
            <DateChip>
              <span>{messagesGroup.day}</span>
            </DateChip>

            {messagesGroup.rows.map((row, index) => (
              <Grid
                container
                justifyContent={
                  row.messageType === 'outgoing' ? 'flex-start' : 'flex-end'
                }
                key={`${row.message}-${format(
                  new Date(row.timestamp),
                  'yyyy-MM-dd HH:MM:ss'
                )}-${index}`}
                id={`${row.message}-${row.timestamp}`}
              >
                <Card className={getMessageClass(row)} variant="outlined">
                  <Typography className={classes.msg}>{row.message}</Typography>
                  <Typography className={classes.msgHour}>
                    {hour(new Date(row.timestamp))}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </>
        ))
        // .reverse();
    } else return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
      PaperProps={{ className: classes.modal }}
    >
      <ContainerDialog>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {getTranslation('modal.messageHistory.title')}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {loading ? (
          <Grid
            style={{
              padding: 24,
            }}
          >
            <CircularProgress color="inherit" size={18} />
          </Grid>
        ) : (
          <ContentContainer>
            <ClientInfo>
              <MsgFieldContainer>
                <Typography>
                  {getTranslation('modal.messageHistory.subtitle')}
                </Typography>
              </MsgFieldContainer>
              <MsgFieldContainer>
                <IconButton onClick={() => setOpenFilter(true)}>
                  <Tooltip
                    title={getTranslation('modal.messageHistory.clientData')}
                    placement="left-start"
                  >
                    <Search fontSize="large" />
                  </Tooltip>
                </IconButton>
                <IconButton onClick={handleClickInfo}>
                  <Tooltip
                    title={getTranslation('modal.messageHistory.clientData')}
                    placement="left-start"
                  >
                    <InfoIcon fontSize="large" htmlColor="#34ac26" />
                  </Tooltip>
                </IconButton>
              </MsgFieldContainer>
              <Popover
                open={!!anchorEl}
                anchorEl={anchorEl || null}
                onClose={handleClosePopover}
                PaperProps={{
                  style: {
                    marginTop: '10px',
                    marginLeft: '4px',
                  },
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverContainer>
                  <Typography>
                    <strong>
                      {getTranslation('modal.messageHistory.name')}
                    </strong>{' '}
                    {currentCustomer?.name ||
                      getTranslation('modal.messageHistory.noData')}
                  </Typography>
                  <Typography>
                    <strong>
                      {getTranslation('modal.messageHistory.nickname')}{' '}
                    </strong>{' '}
                    {currentCustomer?.nickname ||
                      getTranslation('modal.messageHistory.noData')}
                  </Typography>
                  <Typography>
                    <strong>
                      {' '}
                      {getTranslation('modal.messageHistory.phone')}
                    </strong>{' '}
                    {currentCustomer?.phoneNumber ||
                      getTranslation('modal.messageHistory.noData')}
                  </Typography>
                  <Typography>
                    <strong>
                      {getTranslation('modal.messageHistory.address')}
                    </strong>
                    {JSON.stringify(currentCustomer?.address) ||
                      getTranslation('modal.messageHistory.noData')}
                  </Typography>
                  <Typography>
                    <strong>
                      {getTranslation('modal.messageHistory.firstMessage')}
                    </strong>
                    {formatDateHour(currentCustomer?.createdAt)}
                  </Typography>
                  <Typography>
                    <strong>
                      {getTranslation('modal.messageHistory.lastMessage')}
                    </strong>
                    {formatDateHour(currentCustomer?.updatedAt)}
                  </Typography>
                </PopoverContainer>
              </Popover>
            </ClientInfo>

            {openFilter && (
              <Filter
                handleClose={() => setOpenFilter(false)}
                conversation={conversation}
              />
            )}

            <MessagesContainer id="scrollDiv">
              {renderMessagesByDate}
            </MessagesContainer>
          </ContentContainer>
        )}
      </ContainerDialog>
    </Dialog>
  );
}
