export interface IForgotPasswordProps {
  open: boolean;
  onClose: () => void;
  dispatchUser: (token: string) => Promise<void>;
	firstAccess?: boolean;
	temporaryPassword?: string;
	email: string;
}

export interface IForgotPasswordOpenProps {
	open: boolean;
	firstAccess: boolean;
	temporaryPassword?: string;
	email: string;
}

export interface PasswordErrors {
	min1upperCase: string
  differentPasswords: string
  min6digitVerificationCode: string
  min8digitPassword: string
  min1digitSpecial: string
}
export class RegistrationError {
  email = ''
  recoveryCode = ''
  password = ''
  passwordConfirmation = ''
	temporaryPassword = ''
}

export class RegistrationInfo {
	email = ''
	recoveryCode = ''
	password = ''
	passwordConfirmation = ''
	error = new RegistrationError()
	temporaryPassword = ''

	constructor(email: string, temporaryPassword?: string){
		this.email = email
		this.temporaryPassword = temporaryPassword || ''
	}

}
