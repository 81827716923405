import React, { useEffect, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { Dialog, Slide, TextField } from '@material-ui/core';

import { useFlow } from 'contexts/Flow/flowContext';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import * as S from './styles';

import { CircularProgress } from '@material-ui/core';
import { GetAdminData, UpdateAdminData } from 'services/AdminServices';
import { IAdmin, adminValidation } from 'models/AdminValidation';
import { useApp } from 'contexts/App/appContext';

import useTranslator from 'utils/hooks/Translator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      background: '#F2F2F2',
    },

    input: {
      background: '#FFFFFF',
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalManageAccount() {
  const { state, dispatch, toastNotification } = useFlow();
  const classes = useStyles();
  const { dispatch: dispatchApp } = useApp();
  const [load /*setLoad*/] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState<Partial<IAdmin>>({
    username: '',
    password: '',
    passwordConfirmation: '',
  });
  const { getTranslation } = useTranslator();

  useEffect(() => {
    if (state.openModalManageAccount) {
      const getAdmin = async () => {
        const result = await GetAdminData(dispatchApp);
        if (result.Success) {
          const admin = result.Data.data;
          setUsername(admin.username);
        }
      };

      getAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.openModalManageAccount]);

  const handleClose = () => {
    setErrors({ username: '', password: '', passwordConfirmation: '' });
    dispatch({ type: 'closeModalManageAccount' });
  };

  const validateFields = async () => {
    const { isValid, errors } = await adminValidation({
      username,
      password,
      passwordConfirmation,
    });
    setErrors(errors);
    return isValid;
  };

  const handleSave = async () => {
    const isValid = await validateFields();
    if (isValid) {
      const response = await UpdateAdminData(
        { username, password },
        dispatchApp
      );

      if (response.Success) {
        toastNotification('success', getTranslation('toast.success.update'));
      } else {
        toastNotification('error', getTranslation('toast.error.update'));
      }
    }
  };

  if (!state.openModalManageAccount) return <></>;

  return (
    <Dialog
      open={state.openModalManageAccount}
      TransitionComponent={Transition}
      maxWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <S.ContainerDialog className={classes.dialog}>
        <S.ContainerHeader>
          <S.ModalTitle style={{ fontWeight: 600 }}>
            {getTranslation('modal.manageAccount.title')}
          </S.ModalTitle>
          <IconButton
            edge="start"
            color="default"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </S.ContainerHeader>
        <S.FieldContainer>
          <TextField
            required
            value={username || ''}
            variant="outlined"
            placeholder={getTranslation('modal.manageAccount.username')}
            inputProps={{ maxLength: 40 }}
            className={classes.input}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            error={!!errors.username}
            helperText={errors.username || ''}
            autoFocus={false}
          />
        </S.FieldContainer>
        <S.FieldContainer>
          <TextField
            required
            autoFocus={false}
            variant="outlined"
            value={password || ''}
            placeholder={getTranslation('passwordUpper')}
            inputProps={{ maxLength: 40 }}
            className={classes.input}
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            error={!!errors.password}
            helperText={errors.password || ''}
          />
        </S.FieldContainer>
        <S.FieldContainer>
          <TextField
            required
            variant="outlined"
            value={passwordConfirmation || ''}
            placeholder={getTranslation('modal.manageAccount.confirmPassword')}
            inputProps={{ maxLength: 40 }}
            className={classes.input}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            fullWidth
            type="password"
            error={!!errors.passwordConfirmation}
            helperText={errors.passwordConfirmation || ''}
          />
        </S.FieldContainer>
        <S.ButtonContainer>
          <S.Button
            style={{ letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            {getTranslation('save')}
            {load && <CircularProgress color="inherit" size={18} />}
          </S.Button>
        </S.ButtonContainer>
      </S.ContainerDialog>
    </Dialog>
  );
}
