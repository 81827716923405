import {
  Button,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import * as SS from '../styles';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useTranslator from 'utils/hooks/Translator';

import { CircularProgress } from '@material-ui/core';

import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useToast } from 'contexts/ToastContext';
import { isBefore } from 'date-fns';
import { GetActionings } from 'services/CampaignService/ActioningsService';
import {
  DeleteCampaign,
  GetCampaigns,
} from 'services/CampaignService/CampaignsService';
import {
  emptyCampaign,
  ICampaign,
  ICampaignStatus,
} from 'services/CampaignService/CampaignsService/types';
import { formatDateHour } from 'utils/Date';
import { CreateOrEditCampaign } from './CreateOrEditCampaign';

export function ModalCampaigns() {
  const classes = SS.useStyles();
  const { hasPermissionToAction } = usePermissions();
  const { toastNotification } = useToast();
  const { getTranslation } = useTranslator();
  const { dispatch: dispatchApp } = useApp();
  const { currentData } = useFintalkCloud();
  const { agentName: botName } = currentData;

  const bot_name = botName || '';

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModalCreateOrEditCampaign, setOpenModalCreateOrEditCampaign] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [currentCampaign, setCurrentCampaign] =
    useState<ICampaign>(emptyCampaign);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [tooltipTitle, setTooltipTitle] = useState(getTranslation('copy'));

  const isCommunicationGuidelineWrite = hasPermissionToAction({
    company: currentData.companyName!,
    agent: currentData.agentName!,
    action: ['communication_guideline:write'],
  });

  const filteredCampaigns =
    search.length > 0
      ? campaigns.filter((t) => t.name.includes(search))
      : campaigns;

  useEffect(() => {
    if (!openModalCreateOrEditCampaign) loadCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalCreateOrEditCampaign, botName]);

  async function loadCampaigns() {
    setLoading(true);

    try {
      const response = await GetCampaigns(
        {
          bot_name,
        },
        dispatchApp
      );

      if (response.Success) {
        const allCampaigns = response.Data.data;

        setCampaigns(
          allCampaigns.sort((a, b) => {
            if (
              isBefore(
                new Date(a.creation_date).getTime(),
                new Date(b.creation_date).getTime()
              )
            )
              return 1;
            else return -1;
          })
        );
      } else throw new Error('erro');
    } catch (error: any) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.searchCampaigns'),
      });
    }

    setLoading(false);
  }

  function handleNewCampaign() {
    setCurrentCampaign(emptyCampaign);
    setOpenModalCreateOrEditCampaign(true);
  }

  function handleCloseNewCampaign() {
    setCampaigns([]);
    setOpenModalCreateOrEditCampaign(false);
    setSearch('');
  }

  function handleChangeRowsPerPage(e: { target: { value: string | number } }) {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }

  function handleChangePage(event: any, newPage: React.SetStateAction<number>) {
    setPage(newPage);
  }

  async function campaignIsUsed(campaign: ICampaign): Promise<boolean> {
    const response = await GetActionings(
      {
        bot_name,
        organization: '',
      },
      dispatchApp
    );
    let campaignUsed = true;

    if (response.Success) {
      const actionings = response.Data.data;

      const foundCampaign = actionings.find(
        (actioning) => actioning.campaign_name === campaign.name
      );

      if (foundCampaign) campaignUsed = true;
      else campaignUsed = false;
    }

    return campaignUsed;
  }

  async function handleRemoveCampaign(campaign: ICampaign) {
    const campaignUsed = await campaignIsUsed(campaign);

    if (!campaignUsed) {
      const response = await DeleteCampaign(
        {
          ...campaign,
          bot_name,
        },
        dispatchApp
      );

      if (response.Success) {
        toastNotification({
          type: 'success',
          message: getTranslation('toast.success.campaignDeleted'),
        });
        setCampaigns(campaigns.filter((camp) => camp._id !== campaign._id));
      } else {
        toastNotification({
          type: 'error',
          message: getTranslation('toast.error.campaignDeleted'),
        });
      }
    } else
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.usedCampaign'),
      });
  }

  function handleEditCampaign(campaign: ICampaign) {
    setCurrentCampaign(campaign);
    setOpenModalCreateOrEditCampaign(true);
  }

  function getPrettyStatus(status: ICampaignStatus) {
    switch (status) {
      case 'active':
        return getTranslation('active');
      case 'inactive':
      default:
        return getTranslation('inactive');
    }
  }

  async function handleCopyId(id: string) {
    await navigator.clipboard.writeText(id);
    setTooltipTitle(getTranslation('copied'));
  }

  const renderCampaignsList = () => (
    <SS.ContainerList>
      <SS.SearchAndTableContainer>
        <SS.ContainerHeader>
          <Typography variant="h6">
            {getTranslation('campaignsList')}
          </Typography>

          <SS.HeaderButtonsContainer>
            {isCommunicationGuidelineWrite && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => handleNewCampaign()}
              >
                {getTranslation('modal.campaigns.campaign.newButton')}
              </Button>
            )}
          </SS.HeaderButtonsContainer>
        </SS.ContainerHeader>

        <SS.GridSearch>
          <Autocomplete
            options={campaigns.map((option) => option.name)}
            classes={{ root: classes.autocomplete }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={search}
                label={getTranslation('search')}
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                classes={{ root: classes.searchInput }}
              />
            )}
          />
        </SS.GridSearch>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress color="secondary" size={36} />
          </div>
        ) : filteredCampaigns.length > 0 ? (
          <TableContainer classes={{ root: classes.tableContainer }}>
            <SS.CampaignsTable
              aria-label="simple table"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Id</TableCell>
                  <TableCell align="center">
                    {getTranslation('name')}
                  </TableCell>
                  <TableCell align="center">Template</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">
                    {getTranslation('creationDate')}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredCampaigns.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredCampaigns
                ).map((campaign) => (
                  <TableRow key={campaign._id}>
                    <TableCell
                      scope="campaign"
                      align="center"
                      onClick={() => handleCopyId(campaign._id || '')}
                    >
                      <Tooltip title={tooltipTitle} placement="right">
                        <span
                          onMouseLeave={() =>
                            setTooltipTitle(getTranslation('copy'))
                          }
                        >
                          {campaign._id}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell scope="campaign" align="center">
                      {campaign.name}
                    </TableCell>
                    <TableCell align="center">{campaign.template}</TableCell>
                    <TableCell align="center">
                      {getPrettyStatus(campaign.status)}
                    </TableCell>
                    <TableCell align="center">
                      {formatDateHour(campaign.creation_date)}
                    </TableCell>
                    <TableCell align="center">
                      {isCommunicationGuidelineWrite && (
                        <>
                          <Tooltip title={getTranslation('edit')}>
                            <IconButton
                              onClick={() => handleEditCampaign(campaign)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={getTranslation('delete')}>
                            <IconButton
                              onClick={() => handleRemoveCampaign(campaign)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              {campaigns.length > 0 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      count={filteredCampaigns.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={getTranslation(
                        'modal.conversationHistory.linesPerPage'
                      )}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </SS.CampaignsTable>
          </TableContainer>
        ) : (
          <p style={{ marginTop: 24 }}>{getTranslation('noCampaignsResult')}</p>
        )}
      </SS.SearchAndTableContainer>
    </SS.ContainerList>
  );

  return (
    <SS.CampaignsContainer
      maxWidth={false}
      disableGutters
    >
      <SS.CampaignsContent>
        {openModalCreateOrEditCampaign ? (
          <CreateOrEditCampaign
            handleClose={handleCloseNewCampaign}
            currentCampaign={{
              ...currentCampaign,
              bot_name,
            }}
          />
        ) : (
          renderCampaignsList()
        )}
      </SS.CampaignsContent>
    </SS.CampaignsContainer>
  );
}
