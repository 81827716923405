import React from 'react';
import * as S from './styles';
import Sentences from 'components/inputs/Material/Sentences';

const PhraseOfIntent: React.FC<{ bot_name: string }> = ({ bot_name }) => {
  const newPhrase = () => {
    return (
      <S.NewPhraseContainer>
        <Sentences bot_name={bot_name} />
      </S.NewPhraseContainer>
    );
  };

  return <S.Container>{newPhrase()}</S.Container>;
};

export default PhraseOfIntent;
