import CircularProgress from '@material-ui/core/CircularProgress';
import IconLLego from 'components/Icons/logo-longo.png';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';

import { useApp } from 'contexts/App/appContext';
import { IUser, userValidation } from 'models/LoginModel';

import { PublicClientApplication } from '@azure/msal-browser';
import { Button, IconButton, InputAdornment, Tooltip, Typography } from '@material-ui/core';
import { ChevronRight, Undo, Visibility, VisibilityOff } from '@material-ui/icons';
import { CognitoLogin } from 'services/AuthService';

import ModalResetPassword from './ModalResetPassword';

import ChooseLanguage from 'components/ChooseLanguage';
import useTranslator from 'utils/hooks/Translator';
import { IForgotPasswordOpenProps } from './ModalResetPassword/types';

import Toast from 'components/Toast';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useToast } from 'contexts/ToastContext';

const redirectUrl = String(process.env.REACT_APP_REDIRECT_SSO_URL);
const APP_ID = String(process.env.REACT_APP_AVENUE_AD_CLIENT_ID );
const TENANT_ID = String(process.env.REACT_APP_AVENUE_AD_TENANT_ID);

const config = {
  appId: APP_ID,
  redirectUri: '',
  scopes: ['user.read'],
  authority: `https://login.microsoftonline.com/${TENANT_ID}`,
};

const Login: React.FC = () => {
  const { dispatch } = useApp();
  const { generatePermissions } = usePermissions();
  const { toastNotification } = useToast();
  const { getTranslation } = useTranslator();
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      authority: config.authority,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  });

  const handleAdLogin = async () => {
    try {
      const response = await publicClientApplication.loginPopup({
        scopes: config.scopes,
        prompt: 'select_account',
      });

      const token = response.accessToken;

      if (!!token) {
        const responseCognitoLogin = await CognitoLogin({ token, legacy: true });
        if (
          !!responseCognitoLogin.token &&
          !!responseCognitoLogin.refresh_token
        ) {
          localStorage.setItem('token', responseCognitoLogin.token);
          localStorage.setItem(
            'refresh_token',
            responseCognitoLogin.refresh_token
          );
          dispatchUser(responseCognitoLogin.token).finally();
        } else {
          throw new Error(responseCognitoLogin.detail.message ?? '');
        }
      }
    } catch (e) {
      console.log('erro', e);
    }
  };

  const [user, setUser] = useState<IUser>({
    email: '',
    password: '',
    error: {
      email: getTranslation('validations.required', {
        field: getTranslation('emailUpper'),
      }),
      password: getTranslation('validations.required', {
        field: getTranslation('passwordUpper'),
      }),
    },
  });
  const [errors, setErrors] = useState<Partial<IUser>>({
    email: '',
    password: '',
  });

  const [load, setLoad] = useState(false);
  const [showPasswordContent, setShowPasswordContent] = useState(false);
  const [openResetPassword, setOpenResetPassword] =
    useState<IForgotPasswordOpenProps>({
      open: false,
      firstAccess: false,
      email: '',
    });

  const passwordInputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPasswordContent(!showPasswordContent)}
          onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
            e.preventDefault()
          }
          edge="end"
        >
          {showPasswordContent ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  };

  async function fetchUrlProvider(domain: string) {
    try {
      setLoad(true);

      const response = await fetch(`${redirectUrl}${domain}`);

      if (response.ok) {
        const jsonResponse = await response.json();
        const { url } = jsonResponse;
        if (url) {
          window.location.href = url;
        }
      } else {
        setShowPassword(true);
      }

      setLoad(false);
    } catch (e) {
      console.log(e);
      setShowPassword(true);
      setLoad(false);
    }
  }

  async function handleSSOLogin() {
    if (!user.email) return;

    const emailRegex = /@(.+)/;
    const match = user.email.match(emailRegex);

    if (!match || !match[1]) {
      setErrors((oldState) => ({ ...oldState, email: 'Por favor, insira um email válido.' }));
      return;
    }

    const domain = match[1];

    if (domain.includes('avenue')) {
      await handleAdLogin()
      return
    }

    await fetchUrlProvider(domain);
  }

  const handleChangeInput =
    (key: keyof IUser) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setUser((oldState) => ({ ...oldState, [key]: e.target.value }));
      setErrors((oldState) => ({ ...oldState, [key]: '' }));
    };

  const handleForgotPassword = () => {
    setOpenResetPassword({ open: true, firstAccess: false, email: user.email });
  };

  const handleSubmit = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (load) return;

    const resultUserValidate = await userValidation(user);
    setErrors(resultUserValidate.errors);

    if (!resultUserValidate.isValid) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.userOrPasswordIncorrect'),
      });
      return;
    }

    try {
      setLoad(true);
      const { email, password } = user;

      const response = await CognitoLogin({ user: email, password });
      if (!!response.token && !!response.refresh_token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('refresh_token', response.refresh_token);
        dispatchUser(response.token);
      } else if (
        response.detail.error === 'Change password before authenticating'
      ) {
        setOpenResetPassword({
          open: true,
          firstAccess: true,
          temporaryPassword: user.password,
          email: user.email,
        });
      } else {
        throw new Error(response.detail.message ?? '');
      }
    } catch (error: any) {
      toastNotification({
        type: 'error',
        message:
          error.message || getTranslation('toast.error.userOrPasswordInvalid'),
      });
    } finally {
      setLoad(false);
    }
  };

  async function dispatchUser(token: string) {
    dispatch({ type: 'updateApp', data: { signed: true } });
    generatePermissions(token);
    history.push('/');
  }

  return (
    <S.LoginContainer>
      <Toast />

      <div style={{ marginBottom: 30 }}>
        <img src={IconLLego} alt="fintalk logo" style={{ width: 200 }} />
      </div>

      <S.FormContainer>
        <form onSubmit={(event) => {
          event.preventDefault();
          handleSSOLogin();
        }}>
          <S.FieldEmailContainer>
            <S.InputEmail
              variant="outlined"
              label={getTranslation('emailUpper')}
              value={user.email}
              error={!!errors.email}
              helperText={errors.email}
              fullWidth
              onChange={handleChangeInput('email')}
            />

            {!showPassword && (
              <Tooltip title="Seguir com este email" placement='top'>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={load}
                  onClick={() => handleSSOLogin()}
                  style={{ 
                    height: '53px', 
                    marginBottom: errors.email && '20px', 
                  }}
                >
                  {!load ? (
                    <ChevronRight />
                  ) : (
                    <CircularProgress />
                  )}
                </Button>
              </Tooltip>
            )}
          </S.FieldEmailContainer>
        </form>

        <form onSubmit={handleSubmit}>
          {showPassword && (
            <>
              <S.FieldContainer>
                <S.InputPassword
                  variant="outlined"
                  label={getTranslation('passwordUpper')}
                  value={user.password}
                  error={!!errors.password}
                  helperText={errors.password}
                  type={showPasswordContent ? 'text' : 'password'}
                  fullWidth
                  onChange={handleChangeInput('password')}
                  InputProps={passwordInputProps}
                />
              </S.FieldContainer>

              <S.FieldButtonsContainer>
                <S.ButtonEnter
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  {getTranslation('enter')}
                  {load && (
                    <CircularProgress
                      color="inherit"
                      size={18}
                      style={{ marginLeft: '10px' }}
                    />
                  )}
                </S.ButtonEnter>

                  <Tooltip 
                    title='Digitar email novamente para SSO'
                    placement='top'
                  >
                    <Button
                      variant='contained'
                      onClick={() => setShowPassword(false)}
                      style={{ height: '54px' }}
                    >
                      <Undo />
                    </Button>
                  </Tooltip>
              </S.FieldButtonsContainer>

              <S.FieldContainer>
                <Typography>
                  {getTranslation('login.phrase')}
                  <p></p>
                  <S.ForgotEmailButton
                    type="button"
                    onClick={handleForgotPassword}
                  >
                    {getTranslation('login.resetPassword')}
                  </S.ForgotEmailButton>
                </Typography>
              </S.FieldContainer>
            </>
          )}
        </form>
      </S.FormContainer>

      {openResetPassword.open && (
        <ModalResetPassword
          open={openResetPassword.open}
          firstAccess={openResetPassword.firstAccess}
          temporaryPassword={openResetPassword.temporaryPassword || ''}
          email={openResetPassword.email}
          onClose={() =>
            setOpenResetPassword({ open: false, firstAccess: false, email: '' })
          }
          dispatchUser={dispatchUser}
        />
      )}
      <ChooseLanguage />
    </S.LoginContainer>
  );
};

export default Login;
