import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const ContainerOptionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px;
  min-height: 18px;
`;

export const ContainerOptionContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export const OptionIndexAndTip = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
	min-height: 40px;
`;

export const HeaderSpan = styled.span`
  color: #0e103b;
  margin-top: -24px;
`;

export const Container = styled(MttGrid)`
  width: calc(100% - 32px);
  min-height: 48px;

  padding: 4px 16px;
  border-radius: 4px;

  border: 1px solid #0000001f;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;

  margin-top: 8px;
`;

export const GridInputDefinition = styled(MttGrid)`
  width: 100%;
  max-width: 456px;
  margin-left: 8px;
  padding-bottom: 8px;
  padding-right: 16px;

  input {
    color: #000;
    padding-left: 4px;
  }
`;

export const GridDefinition = styled(MttGrid)`
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 24px;

  height: 46px;
`;

export const ContentSynonyms = styled(MttGrid)`
  min-width: 40%;
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
`;

export const GridSynonym = styled(MttGrid).attrs((props) => ({
  className: props.className,
}))`
  height: 32px;

  margin-left: 8px;

  padding: 0 8px;
  margin: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  font-weight: 500;

	svg {
		margin: inherit;
	}
`;

export const GridSynonymRegular = styled(GridSynonym)`
  background-color: #f2f2f2;
`;

export const GridSynonymVariable = styled(GridSynonym)`
  background-color: #60a46057;
  border: 1px solid #0000001f;
`;
