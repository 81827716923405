import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IGetCredentialsResponse,
  IGetIdentityTokenResponse,
  IdentityTokenRequest,
} from './types';

const service = 'falazap';
const identityUrl = 'identity-token';
const credentialsUrl = 'credentials';

export const GetCredentials = async (
  botName: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetCredentialsResponse>> => {
  const action = `${service}/${credentialsUrl}/${botName}`;
  return json<IGetCredentialsResponse>('GET', action, dispatch);
};

export const GetIdentityToken = async (
  { botName, access_key, secret_key }: IdentityTokenRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetIdentityTokenResponse>> => {
  const action = `${service}/${identityUrl}/${botName}`;
  return json<IGetIdentityTokenResponse>('GET', action, dispatch, undefined, {
    access_key,
    secret_key,
  });
};

export const CreateIdentityToken = async (
  { botName, access_key, secret_key }: IdentityTokenRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetIdentityTokenResponse>> => {
  const action = `${service}/${identityUrl}/${botName}`;
  return json<IGetIdentityTokenResponse>('POST', action, dispatch, undefined, {
    access_key,
    secret_key,
  });
};

export const UpdateIdentityToken = async (
  { botName, access_key, secret_key }: IdentityTokenRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetIdentityTokenResponse>> => {
  const action = `${service}/${identityUrl}/${botName}`;
  return json<IGetIdentityTokenResponse>('PATCH', action, dispatch, undefined, {
    access_key,
    secret_key,
  });
};
