import * as yup from 'yup'

import { verifyClassEmpty } from 'utils/classEmpty'
import { genericValidation } from 'utils/validationGenerics'

import { RegistrationInfo, RegistrationError, PasswordErrors } from './types'

export const resetPasswordValidation = async (object: RegistrationInfo, errors: PasswordErrors, firstAccess?: boolean): Promise<true | RegistrationError> => {
  const schemaRegister = firstAccess ? yup.object().shape({
    temporaryPassword: yup.string().required(),
    password: yup.string().min(8, errors.min8digitPassword).required().test('isValidTest', errors.min1upperCase,
			(value: any) => {
				const hasUpperCase = /[A-Z]/.test(value)
				return hasUpperCase;
			}
		),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], errors.differentPasswords).required()
  }) : yup.object().shape({
    recoveryCode: yup.string().min(6, errors.min6digitVerificationCode).required(),
    password: yup.string().min(8, errors.min8digitPassword).required(),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], errors.differentPasswords).required()
  })

  const mountError = await genericValidation<RegistrationInfo, RegistrationError>(schemaRegister, object, new RegistrationError())
  const regExp = /[^0-9A-Za-z ]/

  if (!regExp.test(object.password) && mountError.password === '') {
    mountError.password = errors.min1digitSpecial
  }

  if (!verifyClassEmpty(mountError)) return true
  return mountError
}

export const emailValidation = async (email: string): Promise<true | RegistrationError> => {
  const schemaRegister = yup.object().shape({
    email: yup.string().required().email()
  })

  const mountError = await genericValidation<Object, RegistrationError>(schemaRegister, { email }, new RegistrationError())

  if (!verifyClassEmpty(mountError)) return true
  return mountError
}
