import EIntentType from '../enums/EIntentType';
import { slugify } from '../utils/String';
import { DataFlow, Input, InputOutputObject, Output } from './DataFlow';

export class DrawflowModel {
  private dataFlow: DataFlow;
  private groupId: string;

  constructor(dataFlow: DataFlow, groupId: string) {
    this.dataFlow = dataFlow;
    this.groupId = groupId;
    this.treatment();
  }

  treatment() {
    this.removeInputsOutputs();
    const keys = Object.keys(this.dataFlow);
    for (const key of keys) {
      const { outputs, data, inputs } = this.dataFlow[Number(key)];
      this.dataFlow[Number(key)].id = Number(key);
      this.removeDeletedNodesConnection(inputs, keys);
      data.name = this.rewriteNameIntent(data.name);
      data.outputs = this.updateOutputData(data.outputs || [], outputs);
      data.inputs = this.updateInputsData(data.intentType, data.inputs);
      if (!!this.groupId) {
        data.groupId = this.groupId;
      }
      if (!data.dataBlockly) {
        data.dataBlockly = {
          lastVersion: 1,
          payload: '',
          xml: '',
          compiledPayload: '',
        };
      } else {
        if (!data.dataBlockly.compiledPayload) {
          data.dataBlockly.compiledPayload = '';
        }
      }
    }
  }

  removeInputsOutputs() {
    const keys = Object.keys(this.dataFlow);
    for (const key of keys) {
      const { inputs, outputs, data } = this.dataFlow[Number(key)];
      this.dataFlow[Number(key)].id = Number(key);
      if (data.intentType === EIntentType.InitialFlow) {
        const keysIn = Object.keys(inputs);
        for (const keyIn of keysIn) {
          const input = inputs[keyIn];
          const connections = [...input.connections];
          for (const conn of connections) {
            const data = this.dataFlow[Number(conn.node)];
            const out = data.outputs[conn.input || ''];
            if (out) {
              out.connections = out.connections.filter(
                (o) => Number(o.node) !== Number(key)
              );
            }
          }

          delete inputs[keyIn];
        }
      } else if (
        // data.intentType === EIntentType.Cancel ||
        // data.intentType === EIntentType.Fallback ||
        data.intentType === EIntentType.NewGroup
      ) {
        const keysOut = Object.keys(outputs);
        for (const keyOut of keysOut) {
          const output = outputs[keyOut];
          const connections = [...output.connections];
          for (const conn of connections) {
            const data = this.dataFlow[Number(conn.node)];
            const inp = data.outputs[conn.output || ''];
            if (inp) {
              inp.connections = inp.connections.filter(
                (i) => Number(i.node) !== Number(key)
              );
            }
          }

          delete outputs[keyOut];
        }
      }
    }
  }

  updateOutputData(outputsData: Output[], outputs: InputOutputObject) {
    const keys = Object.keys(outputs);
    const outputsClean = this.clearOutputsData(outputsData);
    if (keys.length === 1 && outputsClean.length === 0) {
      outputsClean.push({
        title: 'Saída',
      });
    }
    for (let index = 0; index < keys.length; index++) {
      const output = outputs[keys[index]];
      if (output.connections && !!output.connections.length) {
        const nodeId = output.connections[0].node;
        const outputData = outputsClean.find(
          (_, outputIndex) => outputIndex === index
        );
        if (outputData) {
          outputData.outputid = String(nodeId);
          outputData.nameIntent = this.getNameIntentById(nodeId);
        }
      }
    }
    return [...outputsClean];
  }

  removeDeletedNodesConnection(
    inputs: InputOutputObject,
    allNodesKeys: string[]
  ) {
    const keys = Object.keys(inputs);

    for (const key of keys) {
      const { connections } = inputs[key];
      const connectionKeys = Object.keys(connections);
      for (const connKey of connectionKeys) {
        if (connections[Number(connKey)]) {
          const { node } = connections[Number(connKey)];
          if (!allNodesKeys.includes(String(node))) {
            connections.splice(Number(connKey), 1);
          }
        }
      }
    }
  }

  updateInputsData(intentType: EIntentType, inputsData?: Input) {
    if (intentType === EIntentType.QuestionsAndAnswers && inputsData) {
      inputsData.variables = inputsData.variables.map((variable) => {
        variable.required = true;
        return variable;
      });
    }
    return inputsData;
  }

  clearOutputsData(outputsData: Output[]) {
    return outputsData.map((output) => {
      output.outputid = undefined;
      output.nameIntent = '';
      return output;
    });
  }

  getNameIntentById(nodeId: number) {
    if (this.dataFlow[nodeId]) return this.dataFlow[nodeId].data.name;
  }

  getDrawflow() {
    return this.dataFlow;
  }

  rewriteNameIntent(name: string) {
    return slugify(name.toLowerCase().trim()).replace(/\s/, '');
  }
}
