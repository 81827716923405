import styled, { css } from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttTypography from 'components/Material/MttTypography/MttTypography';

export const PaletteColorPhrases = css`
  .color0 {
    background: #ffd1af;
  }

  .color1 {
    background: #fffda6;
  }

  .color2 {
    background: #ffcdf6;
  }

  .color3 {
    background: #d2c1e1;
  }

  .color4 {
    background: #9fecfe;
  }

  .color5 {
    background: #afffc8;
  }

  .color6 {
    background: #d7ccc8;
  }

  .color7 {
    background: #dadfe3;
  }
`;

export const Container = styled(MttGrid)`
  width: 100%;
`;

export const StyledFrasesUsuario = styled(MttGrid)`
  height: 40px;
  margin:0 16px;
  margin-bottom: 8px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const StyledMttTypographyFrase = styled(MttTypography).attrs({
  variant: 'h6',
  color: 'primary',
})``;

export const StyleSentenceRow = styled.div``;

export const StyledContainerFrases = styled.div`
  margin: 0 16px;
  min-height: 126px;
  max-height: 175px;
  overflow-x: hidden;
  overflow-y: auto;

  padding: 16px;
  border-radius: 4px;

  background-color: #f2f2f2;

  ${StyleSentenceRow} {
    margin-bottom: 16px;
  }

  .MuiInputBase-input {
    background-color: #ffffff;
  }

  div:last-child {
    margin-bottom: 0;
  }

  ${PaletteColorPhrases};
`;

export const StyledItemFrases = styled(MttGrid)`
  display: grid;
  grid-template: 'Frase Icon';
  grid-template-columns: 1fr 56px;
  grid-gap: 8px;

  margin-bottom: 12px;

  position: relative;

  .FormatQuoteIcon {
    position: absolute;
    left: 10px;
    top: 5px;
    color: #3f51b5;
  }
`;

export const GridTextField = styled(MttGrid)`
  width: 100%;
  height: 100%;

  div:first-child {
    background-color: #ffffff;
  }
`;

export const GridDeleteIcon = styled(MttGrid)`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SentenceInput = styled.div`
  background-color: #fff;
  white-space: nowrap;
  display: block;
  padding: 10px;
  padding-left: 35px;
`;
