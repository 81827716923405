import { Box, Grid, makeStyles, Theme, withTheme } from '@material-ui/core';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';

export const DialogContainer = styled(MttGrid)`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  .MuiAppBar-colorPrimary {
    background-color: #0e103b;
    position: absolute;
  }
`;

export const ContentContainer = styled(Grid)`
  padding: 56px;
`;

export const MinRelevanceBoxValue = withTheme(styled(Box)`
  width: 56px;
  border: 2px solid ${props => props.theme.palette.primary.main};
  border-radius: 8px;
  padding: 1px 8px;
  margin-left: 16px;
  display: flex;
  justify-content: center;
`);

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderRadius: 12,
  },
  title: {
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    flex: 1,
  }
}));
