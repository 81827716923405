import { DataFlow } from 'models/DataFlow';
import { INewGroup, newGroupValidation } from 'models/NewGroup';
import { GetGroup, UpdateGroup } from 'services/FlowService';
import { Props } from './types';
import { GetBotsGroups } from 'services/BotService';
import { UpdateFlexBlockName } from 'services/FlexBlocksService';

export default async function UpdateAgentGroup({
  dataForm,
  state,
  toastNotification,
  dispatchApp,
  dispatch,
  getTranslation,
  blockPreviousName,
}: Props) {
  let newGroup: INewGroup = {
    name: '',
    description: '',
    unidBus: '',
  };
  const groupName = `flex-${dataForm?.name}`;
  newGroup = {
    name: groupName,
    description: dataForm?.description || '',
    unidBus: '',
  };
  const { isValid } = await newGroupValidation(newGroup);

  if (!isValid) return;

  let groups = state.groupsNames;
  const groupExist = !!groups.find(
    (g) => g.toLowerCase() === newGroup.name.toLowerCase()
  );

  if (!groupExist) {
    console.log('grupo inexistente');
  }

  const bots = [...state.botsNames];
  const bot = bots.find((b) => b === state.botName);
  const groupToUpdateResponse = await GetGroup(
    { bot_name: bot ?? '', groupName: blockPreviousName ? `flex-${blockPreviousName}` : '' },
    dispatchApp
  );

  if (groupToUpdateResponse.Success) {
    const data: DataFlow = {};
    const groupToUpdateBlocks =
      groupToUpdateResponse.Data.data.blocks.drawflow.Home.data;
    const groupToUpdateBlocksKeys = Object.keys(groupToUpdateBlocks);

    const entryAppBlock =
      groupToUpdateBlocks[Number(groupToUpdateBlocksKeys[0])];

    Object.assign(data, groupToUpdateBlocks);
    data[entryAppBlock.id] = {
      ...entryAppBlock,
      data: {
        ...entryAppBlock.data,
        description: dataForm.description,
        entryName: dataForm.entryName,
        name: dataForm.entryName || data[entryAppBlock.id].name,
      },
    };

    Promise.all([
      await UpdateGroup(
        {
          bot_name: bot ?? '',
          group_name: `flex-${blockPreviousName}`,
          blocks: { drawflow: { Home: { data } } },
          new_group_name: groupName,
        },
        dispatchApp
      ),
      await UpdateFlexBlockName(
        {
          bot_name: bot ?? '',
          block_name: blockPreviousName ?? '',
          new_block_name: dataForm?.name,
        },
        dispatchApp
      ),
    ]);

    const groupsResponse = await GetBotsGroups(
      { bot_name: bot ?? '' },
      dispatchApp
    );
    if (groupsResponse.Data) {
      groups = groupsResponse.Data.data;
      const principalIndex = groups.findIndex((group) => group === 'principal');

      const principal = groups[principalIndex];

      if (principalIndex >= 0) {
        groups.splice(principalIndex, 1);
        groups = [principal, ...groups];
      }

      dispatch({
        type: 'updateForm',
        data: {
          botsNames: [...bots],
          groupsNames: groups,
        },
      });
    }
  }
}