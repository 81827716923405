import styled from 'styled-components';

export const Content = styled.div<{
  colors?: { border: string; background: string };
  color: string;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 4px;

  top: -1px;
  left: -1px;

  width: -webkit-fill-available;
  height: 30px;

  color: #fff;
  font-size: 1.2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  text-align: center;

  background: ${(props) => (props.color ? props.color : '#fff')};
  z-index: 4;

  border-top-right-radius : 8px;
  border-top-left-radius : 8px;

  cursor: pointer;
`;

export const CountContainer = styled.div`
  width: 36px;
  height: 22px;

  margin-left: 2px;

  border: 1.5px solid #fff;

  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    width: 100%;
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    margin-left: 28px;
  }

	svg {
		fill: #fff;
	}
`;

export const RightContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const ConfigContainer = styled.div`
	margin-right: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
	
	.MuiIconButton-root {
		color: #fff;

		:hover {
			color: #ebebeb;
		}
	}
`;