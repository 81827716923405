import { FormControl, Grid, makeStyles } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import styled from 'styled-components';

export const Form = styled(FormControl).attrs({
  className: 'customScroll',
})``;

export const ContainerContent = styled(Grid).attrs({})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  overflow-x: hidden;

  .MuiFab-sizeSmall {
    position: absolute;
    margin-left: 20px;
  }

  @media (max-width: 600px) {
    .MuiFab-sizeSmall {
      margin-left: 4px;
    }
  }
`;

export const MttDialogActions = styled(MuiDialogActions)`
  margin: 8px 0;

  &.MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 1em;
  }

  .MuiButton-contained:first-child {
    color: #ffffff;
    background: #878787;
  }

  .MuiButton-contained {
    padding: 8px 40px;
  }
`;

export const ContainerGrid = styled(Grid).attrs({
  item: true,
  xs: 12,
})`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

	padding: 16px;
	margin-top: 16px !important;
`;

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  enabledButton: {
    padding: '8px 32px',
    backgroundColor: '#878787',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#4e4e4e',
    },
  },
  enabledConfirmButton: {
    backgroundColor: '#0e103b',
    color: '#ffffff',
    padding: '8px 32px',
    '&:hover': {
      backgroundColor: '#54407d',
    },
  },
  button: {
    backgroundColor: '#0000001f',
    color: '#00000042',
    padding: '8px 32px',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    paddingBottom: 0,
  },
}));
