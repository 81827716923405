import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { useFlow } from '../Flow/flowContext';
import { reducerForm, initialState } from './formReducers';
import { IFormContext, IFormProviderProps } from './types';
import { DataForm } from 'models/DataFlow';

export const FormContext = createContext<IFormContext>({} as IFormContext);

export const FormProvider: React.FC<IFormProviderProps> = (
  props: IFormProviderProps
) => {
  const { children } = props;

  const flowState = useFlow().state;
  const [state, dispatch] = useReducer(reducerForm, initialState);

  useEffect(() => {
    dispatch({
      type: 'updateForm',
      data: { dataForm: flowState.dataForm || null },
    });
  }, [flowState.dataForm, dispatch]);

  useEffect(() => {
    if (!flowState.isEditing) {
      dispatch({
        type: 'updateForm',
        data: {
          ...state,
          openModalLogicEditor: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowState.isEditing]);

  function updateForm(form: DataForm) {
    dispatch({
      type: 'updateForm',
      data: {
        dataForm: {
          ...form,
        },
      },
    });
  }

  return (
    <FormContext.Provider
      value={{
        state: state || initialState,
        dispatch,
        updateForm,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export function useForm(): IFormContext {
  const context = useContext(FormContext);

  return context;
}
