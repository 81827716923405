import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { Close, Visibility } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { SimilarityContainer } from 'components/MetadataBlocksDrawerEditor/styles';
import { GridSynonymRegular } from 'components/inputs/Material/OptionsBlockContent/OptionsBlockOutputsSentences/styles';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useForm } from 'contexts/Form/formContext';
import { Output } from 'models/DataFlow';
import { IEntity } from 'models/Entity';
import { ContentSynonyms } from 'pages/Entities/NewVariable/DefineWords/InputField/styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GetEntities } from 'services/EntityService';
import * as S from './styles';

interface SimilarityComponentProps {
  item: Output;
  index: number;
}

interface IIdNameEntity {
  id: string;
  name: string;
}

export function SimilarityComponent({ item, index }: SimilarityComponentProps) {
  const [inputValue, setInputValue] = useState<string>('');
  const { dispatch: dispatchApp } = useApp();
  const { state, updateForm } = useForm();
  const { dataForm } = state;
  const { currentData } = useFintalkCloud();
  const [entities, setEntities] = useState<IEntity[]>([]);
  const [types, setTypes] = useState<IIdNameEntity[]>([]);

  const firstRender = useRef(true);

  const loadEntities = useCallback(async () => {
    if (currentData.agentName) {
      const result = await GetEntities(
        { bot_name: currentData.agentName },
        dispatchApp
      );

      let entitiesResult: IEntity[] = [];
      if (result.Success) {
        if (!!result.Data) {
          entitiesResult = result.Data.filter((e) => e.entitytype === 'words');
          setEntities([...entitiesResult]);
        }
      }

      let allTypes: IIdNameEntity[] = [];

      if (entitiesResult) {
        allTypes = [
          ...allTypes,
          ...entitiesResult.map((entity) => ({
            id: entity.name,
            name: entity.name,
          })),
        ].sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });

        allTypes.map((type) => {
          if (type.id.substring(0, 1) !== '@') {
            const insertAt = `${type.id.slice(0, 0)}${'@'}${type.id.slice(0)}`;
            let filteredValue = insertAt;
            type.id = filteredValue;
          }
          return allTypes;
        });
      }

      setTypes(allTypes);
    }
  }, [currentData.agentName, dispatchApp]);

  useEffect(() => {
    if (firstRender.current) {
      loadEntities();
      firstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowSynonyms = (
    item: string,
    userSentenceIndex: number,
    index: number
  ) => {
    if (item !== '') {
      const isVariable = item.substring(0, 1) === '@';
      return isVariable
        ? returnVariables(item, userSentenceIndex, index)
        : returnRegulars(item, userSentenceIndex, index);
    } else return <></>;
  };

  const returnVariables = (
    item: string,
    userSentenceIndex: number,
    index: number
  ) => {
    return (
      <S.GridSynonymVariable key={index}>
        <Tooltip
          title={
            entities
              .find((t) => `@${t.name}` === item)
              ?.values.map((v) => v.synonyms)
              .map((s) => s)
              .join(',') || ''
          }
        >
          <Visibility fontSize={'small'} />
        </Tooltip>
        {item}
        <IconButton
          onClick={() => handleDeleteSynonym(userSentenceIndex, index)}
          size="small"
        >
          <Close />
        </IconButton>
      </S.GridSynonymVariable>
    );
  };

  const handleDeleteSynonym = (choiceIndex: number, phraseIndex: number) => {
    if (!!dataForm && dataForm.outputs) {
      const choice = dataForm.outputs[choiceIndex];
      const values = choice?.userSentences || [''];
      values.splice(phraseIndex, 1);
      choice.userSentences = values;
      updateForm(dataForm);
    }
  };

  const returnRegulars = (item: string, index: number, choiceIndex: number) => {
    return (
      <GridSynonymRegular key={index}>
        {item}
        {index !== 0 && (
          <IconButton
            onClick={() => handleDeleteSynonym(choiceIndex, index)}
            size="small"
          >
            <Close />
          </IconButton>
        )}
      </GridSynonymRegular>
    );
  };

  const handleInsertValue = (
    event: React.KeyboardEvent<HTMLDivElement>,
    choice: Output,
    index: number
  ) => {
    const values = choice.userSentences || [''];

    if (choice.title !== 'Outros') {
      if (event.keyCode === 13 || event.keyCode === 9) {
        if (inputValue !== '') {
          values.push(inputValue);

          if (!!dataForm && dataForm.outputs) {
            dataForm.outputs[index].userSentences = values;
            updateForm(dataForm);
          }
        }
        setInputValue('');
        const input = event.currentTarget.children[0]
          .children[0] as HTMLDivElement;
        input.blur();
        input.focus();
      }
    }
  };

  const addSynonymByVariable = (varName: string) => {
    item.userSentences?.push(varName);
    if (!!dataForm && dataForm.outputs) {
      dataForm.outputs[index].userSentences = item.userSentences;
      updateForm(dataForm);
    }
    setInputValue('');
  };

  return (
    <SimilarityContainer>
      <Autocomplete
        options={types.map((t) => t.id)}
        open={!!inputValue && inputValue.length > 2}
        onBlur={() => setInputValue('')}
        popupIcon={null}
        getOptionLabel={(option) => option}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            variant="standard"
            disabled={item.title === ''}
            fullWidth
            value={item.title === 'Outros' ? item.title : inputValue}
            placeholder="Digite uma frase de treinamento para a saída"
            onKeyDown={(event) => handleInsertValue(event, item, index)}
          />
        )}
        onChange={(_, value) => addSynonymByVariable(value || '')}
      />
      <ContentSynonyms style={{ marginTop: 16 }}>
        {item.userSentences?.map((item, userSentenceIndex) =>
          handleShowSynonyms(item, userSentenceIndex, index)
        )}
      </ContentSynonyms>
    </SimilarityContainer>
  );
}
