import { useState } from 'react';

import { Paper, InputBase, IconButton } from '@material-ui/core';
import MttTypography from '../../../../../../components/Material/MttTypography/MttTypography';
import Divider from '@material-ui/core/Divider';
import ArrowLeftOutlinedIcon from '@material-ui/icons/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { Message } from 'models/Conversation';

import * as S from './styles';
import Draggable from 'react-draggable';
import useTranslator from 'utils/hooks/Translator';

interface FilterProps {
  handleClose: () => void;
  conversation: Message[];
}

export function Filter({ handleClose, conversation }: FilterProps) {
  const classes = S.useStyles();
  const [search, setSearch] = useState('');
  const [searchIndex, setSearchIndex] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [highlightedElements, setHighlightedElements] = useState<HTMLElement[]>(
    []
  );
  const { getTranslation } = useTranslator();

  const handleSearchMessage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);

    if (e.target.value) {
      const foundMessages = conversation.filter((c) =>
        c.message?.match(e.target.value)
      );
      setTotalResults(foundMessages.length);
      setSearchIndex(-1);

      highlightFoundMessages(foundMessages);
      removeHighlightMessages(
        conversation.filter((c) => !foundMessages.includes(c))
      );
    } else {
      removeHighlightMessages(conversation);
    }
  };

  function highlightFoundMessages(messages: Message[]) {
    const elements: HTMLElement[] = [];

    messages.forEach((message) => {
      const element = document.getElementById(
        `${message.message}-${message.timestamp}`
      );

      if (element && element.children[0]) {
        const keys = Object.keys(element.children[0].classList);
        let isHighlighted = false;

        for (const key of keys) {
          if (
            element.children[0].classList[Number(key)].match('foundMsg') !==
            null
          ) {
            isHighlighted = true;
          }
        }

        if (!isHighlighted) {
          element.children[0].className += ` ${classes.foundMsg}`;
        }
        elements.push(element);
      }
    });
    setHighlightedElements(elements);
  }

  function removeHighlightMessages(messages: Message[]) {
    messages.forEach((message) => {
      const id = `${message.message}-${message.timestamp}`;

      const element = document.getElementById(id);
      if (element && element.children[0]) {
        const keys = Object.keys(element.children[0].classList);

        let removeClassIndex = -1;
        for (const key of keys) {
          if (element.children[0].classList[Number(key)].match('foundMsg'))
            removeClassIndex = Number(key);
        }
        element.children[0].classList.remove(
          element.children[0].classList[removeClassIndex]
        );
      }
    });
  }

  function goToElement(index: number) {
    if (highlightedElements && highlightedElements[index]) {
      highlightedElements[index].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  return (
    <Draggable defaultPosition={{ x: 175, y: -10 }}>
      <Paper
        component="form"
        className={classes.root}
        onSubmit={(e: React.FormEvent<HTMLDivElement>) => e.preventDefault()}
      >
        <InputBase
          className={classes.input}
          placeholder={getTranslation('search')}
          value={search}
          onChange={(e) => handleSearchMessage(e)}
          autoFocus
        />
        <MttTypography variant="subtitle1" className={classes.results}>
          {search && `${searchIndex + 1}/${totalResults}`}
        </MttTypography>
        <Divider className={classes.divider} orientation="vertical" />

        <IconButton
          size="small"
          onClick={() => {
            if (searchIndex > 0) {
              goToElement(searchIndex - 1);
              setSearchIndex(searchIndex - 1);
            }
          }}
          disabled={!(totalResults !== 0) || !!!search}
        >
          <ArrowLeftOutlinedIcon />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => {
            if (searchIndex < totalResults - 1) {
              goToElement(searchIndex + 1);
              setSearchIndex(searchIndex + 1);
            }
          }}
          disabled={!(totalResults !== 0) || !!!search}
        >
          <ArrowRightOutlinedIcon />
        </IconButton>

        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
          onClick={() => {
            removeHighlightMessages(conversation);
            setHighlightedElements([]);
            handleClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Paper>
    </Draggable>
  );
}
