import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import * as SS from '../styles';
import * as S from './styles';

import useTranslator from 'utils/hooks/Translator';

import {
  Add,
  Delete,
  Edit,
  Search,
  Visibility
} from '@material-ui/icons';

import { CircularProgress } from '@material-ui/core';

import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useToast } from 'contexts/ToastContext';
import { isBefore } from 'date-fns';
import { GetCampaigns } from 'services/CampaignService/CampaignsService';
import {
  DeleteTemplate,
  GetFilteredTemplates,
} from 'services/CampaignService/TemplatesService';
import {
  emptyTemplate,
  ITemplate,
  ITemplateBrokers,
  ITemplateChannels,
  ITemplateStatus,
  SearchTemplates,
} from 'services/CampaignService/TemplatesService/types';
import { formatDateHour } from 'utils/Date';
import { SaveOrEditTemplate } from './CreateOrEditTemplate';

export function ModalTemplates() {
  const { toastNotification } = useToast();
  const { hasPermissionToAction } = usePermissions();
  const classes = SS.useStyles();
  const { dispatch: dispatchApp } = useApp();
  const { currentData } = useFintalkCloud();

  const { agentName: botName } = currentData;

  const bot_name = botName || '';

  const { getTranslation } = useTranslator();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModalCreateOrEditTemplate, setOpenModalCreateOrEditTemplate] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<SearchTemplates>({
    name: '',
    channel: 'falazap',
    broker: '',
  });
  const [currentTemplate, setCurrentTemplate] = useState(emptyTemplate);
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<ITemplate[]>([]);

  const isCommunicationGuidelineWrite = hasPermissionToAction({
    company: currentData.companyName!,
    agent: currentData.agentName!,
    action: ['communication_guideline:write'],
  });

  useEffect(() => {
    if (!openModalCreateOrEditTemplate) loadTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalCreateOrEditTemplate, botName]);

  useEffect(() => {
    filterTemplates();

    setFilteredTemplates((prev) =>
      prev.sort((a, b) => {
        if (
          isBefore(
            new Date(a.creation_date).getTime(),
            new Date(b.creation_date).getTime()
          )
        )
          return 1;
        else return -1;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.name, templates])

  async function loadTemplates() {
    try {
      setLoading(true);

      let response;

      if (search.broker) {
        response = await GetFilteredTemplates({ bot_name, channel: search.channel, broker: search.broker }, dispatchApp);
      } else {
        response = await GetFilteredTemplates({ bot_name, channel: search.channel }, dispatchApp);
      }

      if (response.Success && response.Data?.data) {
        const allTemplates = response.Data.data;

        setTemplates([...allTemplates]);
      } else {
        toastNotification({
          type: 'warning',
          message: 'Não foi encontrado templates nessas condições',
        });

        setTemplates([])
      }
      
    } catch (error: any) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.searchTemplates'),
      });
      setTemplates([])
    } finally {
      setTemplates((prev) =>
        prev.sort((a, b) => {
          if (
            isBefore(
              new Date(a.creation_date).getTime(),
              new Date(b.creation_date).getTime()
            )
          )
            return 1;
          else return -1;
        })
      );
  
      setLoading(false);
    }
  }

  function handleNewTemplate() {
    setCurrentTemplate({
      ...emptyTemplate,
      bot_name,
    });
    setOpenModalCreateOrEditTemplate(true);
  }

  function handleCloseNewTemplate() {
    setTemplates([]);
    setOpenModalCreateOrEditTemplate(false);
  }

  function handleChangeRowsPerPage(e: { target: { value: string | number } }) {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }

  function handleChangePage(event: any, newPage: React.SetStateAction<number>) {
    setPage(newPage);
  }

  async function templateIsUsed(template: ITemplate): Promise<boolean> {
    const response = await GetCampaigns({ bot_name }, dispatchApp);
    let templateUsed = false;

    if (response.Success) {
      const campaigns = response.Data.data;

      const foundTemplate = campaigns.find(
        (campaign) => campaign.template === template.name
      );

      if (foundTemplate) templateUsed = true;
      else templateUsed = false;
    }

    return templateUsed;
  }

  async function handleRemoveTemplate(template: ITemplate) {
    const templateUsed = await templateIsUsed(template);

    if (!templateUsed) {
      const response = await DeleteTemplate({ ...template }, dispatchApp);

      if (response.Success) {
        toastNotification({
          type: 'success',
          message: getTranslation('toast.success.templateDeleted'),
        });
        setTemplates(templates.filter((temp) => temp._id !== template._id));
      } else {
        toastNotification({
          type: 'error',
          message: getTranslation('toast.error.templateDeleted'),
        });
      }
    } else
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.usedTemplate'),
      });
  }

  function handleEditTemplate(template: ITemplate) {
    setCurrentTemplate(template);
    setOpenModalCreateOrEditTemplate(true);
  }

  function getColor(status: ITemplateStatus) {
    switch (status) {
      case 'approved':
        return '#79e16d';
      case 'pending':
        return '#ffb84d';
      case 'in_appeal':
        return '#0071b3';
      case 'pending_deletion':
        return '#ffb84d';
      case 'deleted':
        return '#535353';
      case 'disabled':
        return '#b1b1b1';
      case 'paused':
        return '#f3f02d';
      case 'limit_exceeded':
        return '#8a0202';
      case 'rejected':
      default:
        return '#d80101';
    }
  }

  function getPrettyChannel(channel: ITemplateChannels) {
    switch (channel) {
      case 'ura':
        return 'URA';
      case 'email':
        return 'E-mail';
      case 'sms':
        return 'SMS';
      case 'whatsapp':
        return 'WhatsApp';
      case 'falazap':
      default:
        return 'FalaZap';
    }
  }

  function getPrettyStatus(status: ITemplateStatus) {
    switch (status) {
      case 'approved':
        return getTranslation('approved');
      case 'rejected':
        return getTranslation('rejected');
      case 'in_appeal':
        return getTranslation('in_appeal');
      case 'pending_deletion':
        return getTranslation('pending_deletion');
      case 'deleted':
        return getTranslation('deleted');
      case 'disabled':
        return getTranslation('disabled');
      case 'paused':
        return getTranslation('paused');
      case 'limit_exceeded':
        return getTranslation('limit_exceeded');
      case 'pending':
      default:
        return getTranslation('pending');
    }
  }

  async function handleChangeChannel(channel: ITemplateChannels) {
    if (channel !== 'whatsapp') {
      try {
        setLoading(true);

        const response = await GetFilteredTemplates({ bot_name, channel }, dispatchApp)

        if (response.Success && response.Data?.data) {
          const filteredTemplates = response.Data.data;

          setTemplates([...filteredTemplates])
        } else {
          toastNotification({
            type: 'warning',
            message: 'Não foi encontrado templates nessas condições',
          });

          setTemplates([])
        }

      } catch (error: any) {
        toastNotification({
          type: 'error',
          message: getTranslation('toast.error.searchTemplates'),
        });

        setTemplates([])
      } finally {
        setTemplates((prev) =>
          prev.sort((a, b) => {
            if (
              isBefore(
                new Date(a.creation_date).getTime(),
                new Date(b.creation_date).getTime()
              )
            )
              return 1;
            else return -1;
          })
        );

        setLoading(false);
      }
    }

    setSearch((prev) => (
      { 
        ...prev, 
        channel,
        broker: channel !== 'whatsapp' ? '' : prev.broker
      }
    ))
  }

  async function handleChangeTemplateBroker(broker: ITemplateBrokers) {
    try {
      setLoading(true);

      const response = await GetFilteredTemplates({ bot_name, channel: search.channel, broker }, dispatchApp)

      if (response.Success && response.Data?.data) {
        const filteredTemplates = response.Data.data;

        setTemplates([...filteredTemplates])
      } else {
        toastNotification({
          type: 'warning',
          message: 'Não foi encontrado templates nessas condições',
        });

        setTemplates([])
      }
    } catch (error: any) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.searchTemplates'),
      });

      setTemplates([])
    } finally {
      setTemplates((prev) =>
        prev.sort((a, b) => {
          if (
            isBefore(
              new Date(a.creation_date).getTime(),
              new Date(b.creation_date).getTime()
            )
          )
            return 1;
          else return -1;
        })
      );

      setLoading(false);
    }

    setSearch((prev) => ({ ...prev, broker }))
  }

  function filterTemplates() {
    if (search.name) {
      const filteredTemplates = templates.filter(template => template.name.includes(search.name))

      setFilteredTemplates([...filteredTemplates])
    } else {
      setFilteredTemplates([...templates])
    }
  }

  const renderTemplatesList = () => (
    <SS.ContainerList>
      <SS.SearchAndTableContainer>
        <SS.ContainerHeader>
          <Typography variant="h6">
            {getTranslation('templatesList')}
          </Typography>

          <SS.HeaderButtonsContainer>
            {isCommunicationGuidelineWrite && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Add />}
                  onClick={() => handleNewTemplate()}
                >
                  {getTranslation('modal.campaigns.template.newButton')}
                </Button>
              </>
            )}
          </SS.HeaderButtonsContainer>
        </SS.ContainerHeader>

        <SS.ModalTemplatesSearch>
          <TextField
            value={search.name}
            label={getTranslation('search')}
            variant="outlined"
            onChange={(e) => setSearch((prev) => ({ ...prev, name: String(e.target.value) }))}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            style={{ width: '100%' }}
            classes={{ root: classes.searchInput }}
          />

          <FormControl variant="outlined" style={{ width: '200px' }} classes={{ root: classes.searchInput }} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              {getTranslation('channel')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={search.channel}
              onChange={(e) => handleChangeChannel(e.target.value as ITemplateChannels)}
              label={getTranslation('channel')}
            >
              <MenuItem value={'whatsapp'}>Whatsapp</MenuItem>
              <MenuItem value={'falazap'}>Falazap</MenuItem>
              <MenuItem value={'sms'}>SMS</MenuItem>
              <MenuItem disabled value={'email'}>
                E-mail
              </MenuItem>
              <MenuItem disabled value={'ura'}>
                URA
              </MenuItem>
            </Select>
          </FormControl>

          {search.channel === 'whatsapp' && (
            <FormControl variant="outlined" style={{ width: '200px' }} classes={{ root: classes.searchInput }} fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                {getTranslation('broker')}
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={search.broker}
                onChange={(e) => handleChangeTemplateBroker(e.target.value as ITemplateBrokers)}
                label={getTranslation('broker')}
              >
                <MenuItem value={'Meta'}>Meta</MenuItem>
                <MenuItem value={'InfoBip'}>InfoBip</MenuItem>
                <MenuItem value={'Blip'}>Blip</MenuItem>
                <MenuItem value={'Twillio'}>Twillio</MenuItem>
                <MenuItem value={'Interaxa'}>Interaxa</MenuItem>
                <MenuItem value={'Zenvia'}>Zenvia</MenuItem>
              </Select>
            </FormControl>
          )}
        </SS.ModalTemplatesSearch>

        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress color="secondary" size={36} />
          </div>
        ) : filteredTemplates.length > 0 ? (
          <TableContainer classes={{ root: classes.tableContainer }}>
            <SS.CampaignsTable
              aria-label="simple table"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {getTranslation('name')}
                  </TableCell>
                  <TableCell align="center">
                    {getTranslation('channel')}
                  </TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">
                    {getTranslation('creationDate')}
                  </TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {(rowsPerPage > 0
                  ? filteredTemplates.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  : filteredTemplates
                ).map((template, index) => (
                  <TableRow key={index}>
                    <TableCell scope="template" align="center">
                      {template.name}
                    </TableCell>
                    <TableCell align="center">
                      {getPrettyChannel(template.channel ?? search.channel)}
                    </TableCell>
                    <TableCell align="center">
                      <S.StatusDiv>
                        <S.Status bgColor={getColor(template.status.toLocaleLowerCase() as ITemplateStatus)} />
                        {getPrettyStatus(template.status.toLocaleLowerCase() as ITemplateStatus)}
                      </S.StatusDiv>
                    </TableCell>
                    <TableCell align="center">
                      {formatDateHour(template.creation_date) ?? getTranslation('notProvided')}
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={getTranslation(
                          !template._id && !search.broker ? 'edit' : 'view'
                        )}
                      >
                        <IconButton
                          onClick={() => handleEditTemplate(template)}
                        >
                          {!template._id && !search.broker ? <Edit /> : <Visibility />}
                        </IconButton>
                      </Tooltip>
                      
                      {isCommunicationGuidelineWrite && !search.broker && (
                        <Tooltip title={getTranslation('delete')}>
                          <IconButton
                            onClick={() => handleRemoveTemplate(template)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              {templates.length > 0 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      count={filteredTemplates.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={getTranslation(
                        'modal.conversationHistory.linesPerPage'
                      )}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </SS.CampaignsTable>
          </TableContainer>
        ) : (
          <p style={{ marginTop: 24 }}>{getTranslation('noTemplatesResult')}</p>
        )}
      </SS.SearchAndTableContainer>
    </SS.ContainerList>
  );

  return (
    <SS.CampaignsContainer
      maxWidth={false}
      disableGutters={true}
    >
      <SS.CampaignsContent>
        {openModalCreateOrEditTemplate ? (
          <SaveOrEditTemplate
            handleClose={handleCloseNewTemplate}
            currentTemplate={currentTemplate}
          />
        ) : (
          renderTemplatesList()
        )}
      </SS.CampaignsContent>
    </SS.CampaignsContainer>
  );
}
