import {
  Container,
  Grid,
  makeStyles,
  Table,
  Theme,
  withTheme,
} from '@material-ui/core';
import styled from 'styled-components';

export const CustomersContainer = styled(Container)`
  width: 100%;
  height: 100%;
  background-color: #0e103b;
  border-radius: 8px;
  color: #fff;
  border: 1px solid #353759;
`;

export const CustomersContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;

  & .MuiButtonBase-root:not(.Mui-disabled) {
    color: #fff;
  }
  & .MuiInputBase-root .MuiSvgIcon-root {
    color: #fff;
  }
`;

export const CustomersListGrid = styled(Grid)`
  overflow-y: auto;
  height: calc(100% - 82px);
`;

export const SearchTagContainer = withTheme(styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;

  button {
    margin: 5px;
    width: 200px;
  }

  & .MuiFormLabel-root:not(.Mui-focused) {
    color: #fff;
  }
  & .MuiInput-underline:before {
    border-color: #fff;
  }
  & .MuiInput-underline:after {
    border-color: ${(props) => props.theme.palette.secondary.main};
  }
  & .MuiInputAdornment-root {
    color: #fff;
  }
  & .Mui-focused .MuiInputAdornment-root svg {
    color: ${(props) => props.theme.palette.secondary.main};
  }
  & .MuiInputBase-input {
    color: #fff;
  }
`);

export const TextContainer = styled.div`
  height: 64px;

  margin-right: 10px;
  width: 100%;
`;

export const CustomersTable = withTheme(styled(Table)`
  min-width: 650px;

  thead,
  tfoot {
    th {
      color: #fff;
      font-weight: bold;
      border-bottom: 1px solid #353759;
      padding: 16px 0;
      font-size: medium;
    }

    td {
      color: #fff;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid #353759;
        color: #fff;
        font-weight: lighter;
        padding: 16px 0;
      }

      &:hover {
        cursor: pointer;

        td {
          color: ${(props) => props.theme.palette.secondary.main};
        }
      }
    }
  }

  tfoot {
    td {
      border-bottom: none;
      padding: 16px 0;
    }
  }
`);

export const useStyles = makeStyles((theme: Theme) => ({
  customersContainer: {
    backgroundColor: '#0e103b',
    borderRadius: 8,
    color: '#fff',
    border: '1px solid #353759',
  },
  tableContainer: {
    borderRadius: '4px',
  },
  dialogContainer: {
    padding: '16px',
    backgroundColor: '#0e103b',
    borderRadius: 8,
    color: '#fff',
    border: '1px solid #353759',
  },
  dialogContent: {
    paddingBottom: '32px',
  },
  customerDetailsTableContainer: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
		padding: 0,
    color: '#fff',
  },
}));
