import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    footerSaveButton: {
      background: '#34AC26',
      fontSize: '1.1rem',
      color: '#fff',
      width: 'fit-content',
      borderRadius: '5px',
      '&:hover': {
        background: '#34ac2694',
        color: '#fff',
      },
      marginRight: '10px',
    },
    footerFormatCodeButton: {
      background: '#2651ac',
      fontSize: '1.1rem',
      color: '#fff',
      borderRadius: '5px',
      '&:hover': {
        background: '#2651ac85',
        color: '#fff',
      },
      marginRight: '10px',
    },
  })
);
