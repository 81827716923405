import { createStyles, Grid, InputBase, InputLabel, makeStyles, NativeSelect, Theme, withStyles } from '@material-ui/core';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';

export const DialogContainer = styled(MttGrid)`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  .MuiAppBar-colorPrimary {
    background-color: #0e103b;
    position: absolute;
  }
`;

export const ContentContainer = styled(Grid)`
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
`;

export const AddContentGroupInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    color: #222;
    font-weight: bold;
    font-size: 20px;
  }
`;

export const AddContentGroupInput = withStyles((theme: Theme) => (
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 18,
      border: '2px solid #222',
      padding: '10px 26px 10px 12px',
      boxShadow: '0 2px 4px 1px rgba(0,0,0, 0.2)',

      '&:focus': {
        borderRadius: 18,
        boxShadow: '0 2px 4px 1px rgba(0,0,0, 0.6)',
      },
    },
  })
))(InputBase);

export const AddContentGroupSelect = withStyles((theme: Theme) => (
  createStyles({
    icon: {
      marginRight: '12px'
    }
  })
))(NativeSelect);

export const FooterContainer = styled(Grid)`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E3E3E3;
  padding: 0 40px;
`;

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderRadius: '18px',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  footerButtons: {
    fontWeight: 'bold',

    '.Mui-disabled': {
      border: 0,
    }
  }
}));
