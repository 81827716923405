import styled from 'styled-components';

const styles = {
  Container: styled.div`
    background: #b6bad9;
    border-radius: 2rem;
    padding: 2rem;
  `,
}

export default styles;
