import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const Container = styled(MttGrid)`
  width: calc(100% - 32px);
  min-height: 48px;

  padding: 4px 16px;
  border-radius: 4px;

  border: 1px solid #0000001f;
  background-color: #ffffff;

  display: flex;
  align-items: flex-end;
`;

export const GridDefinition = styled(MttGrid)`
  width: 300px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
`;

export const ContentSynonyms = styled(MttGrid)`
  min-width: 60%;
  display: flex;
  flex-wrap: wrap;
`;

export const GridSynonym = styled(MttGrid)`
  height: 32px;

  margin-left: 8px;

  padding: 0 8px;
  margin: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  background-color: #f2f2f2;

  font-weight: 500;
`;

export const GridCloseIcon = styled(MttGrid)`
  margin-left: 8px;

  color: #aeaeae;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const GridInputDefinition = styled(MttGrid)`
  width: 100%;
  margin-left: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  input {
    color: #000;
    padding-left: 4px;
  }
`;
