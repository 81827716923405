import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const GridContainer = styled(MttGrid)`
  margin: 16px;
`;

export const ContainerButton = styled(MttGrid)`
  display: flex;
  justify-content: space-between;

  padding: 8px;
`;

export const ContentGrid = styled(MttGrid)`
  width: calc(100% - 32px);

  padding: 16px;

  border-radius: 4px;

  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
`;

export const SumContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;

  .MuiTypography-h6 {
    margin: 0px 8px;
  }
`;
