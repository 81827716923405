import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  min-width: 360px;

  max-height: 650px;

  height: 100%;

  border-radius: 22px;

  background: #585858;
  background: linear-gradient(#646464, #585858, #2b2929, #201e1e);

  box-shadow: 0 5px 10px #646464;

  padding: 8px;

	z-index: 999;

  .MuiIconButton-root {
    position: absolute;
    right: 5px;
  }
`;

export const ContainerHeader = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  height: 60px;
  cursor: grab;

`;

export const AudioOutputs = styled.div`
  width: 100px;
  background: #393939;
  height: 8px;
  border-radius: 5px;
`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 100%;

  border: 0;
  border-radius: 3px;
`;
