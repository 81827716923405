/* eslint-disable */

export let WorkSpaceSavedXml;
export let WorkSpaceCodeJS;
export let Workspace;
//******************************************************************** */

//   ESTES DADOS PRECISAM SER CONECTADOS AO CANVAS
// Precisa colocar o nome do Grupo Corrente - em que o bloco (intencao) esta
// Precisa disponiibilizar o drawflow para o sistema buscar variaveis VARS e criadas pelo usuario $
// Precisa colocar o numero do bloco atual - numero do array do drawflow

//let grupo_corrente = groupid; recebe do React JS
export let grupo_corrente = 'GRUPOCORRENTE'; // linha com nome do grupo corrente
export function setCurrentGroup(currentGroup) {
  grupo_corrente = currentGroup;
}

export let agente_atual = 'AGENTEATUAL'; // linha com nome do grupo corrente
export function setCurrentAgent(currentAgent) {
  agente_atual = currentAgent;
}
/************************************************************** */

//////// WAGNER DISPONIBILIZAR essas variabeis ARRAYS
//array_variables
//array_connectors

// o array_vars contem a lista de todas as variaveis VARS. encontradas no bot
export let array_vars = new Array();

// array_vars_blockly contém a lista de VARS. que o bloco vai usar para listar
// esse array será carregado com o conteudo do array_vars
export let array_vars_blockly = new Array();

// variaveis criadas pelo usuario na tela de propriedade do bloco
export let array_variables = new Array();

// array_variables_blockly contém a lista de $variables que o bloco vai usar para listar
// esse array será carregado com o conteudo do array_variables
export let array_variables_blockly = new Array();

export let array_connectors = new Array();

export function create_array_connectors(texto) {
  const lines = texto.split(/\r?\n/);
  let n, line_array;
  array_connectors = new Array();

  lines.forEach((line) => {
    n = line.search('[// #PARSER#]');

    if (n > -1) {
      line = line.substring(n, line.length);
      line_array = line.split('#');

      if (line_array[2] == 'CONNECTOR') {
        array_connectors.push(line_array[3]);
      }
    }
  });
}

export function set_payload_connectors(payload, outputs_array) {
  // varre linha a linha de um texto
  // split the contents by new line\
  const lines = payload.split(/\r?\n/);
  var n, preline, line_array, i;
  var newtext = '',
    newline;
  array_connectors = new Array(); // conectores de saida globais - destino
  //console.log("set_payload_connectors\n\n" + payload)

  lines.forEach((line) => {
    // aqui usa a linha line
    newline = line;
    // Troca CONECTOR - se tiver o #PARSER#
    n = line.search('#PARSER#');
    //console.log("N=" + n + "\n" + line);
    if (n > -1) {
      //console.log("Encontrou PARSER\n" + line);
      line = line.split('\n')[0];
      //line = line.substring(n, line.length + 1);
      //console.log("Line = >>\n" + line + "<<");
      line_array = line.split('#');
      //console.log("line_array = " + line_array);
      if (line_array[2] == 'CONNECTOR') {
        // se tem conector
        if (outputs_array.length > 0) {
          // e se o conector esta conectado a algum blooc (intencao)
          //console.log("Encontrou conector. Varrendo o links (" + outputs_array.length + ") no Outputs para ver se da match")
          for (i = 0; i < outputs_array.length; i++) {
            line = preline;
            //console.log("array", outputs_array);
            if (outputs_array[i]['nameIntent']) {
              //console.log("#" + i + ")  >>" + outputs_array[i]["nameIntent"] + "<<\n>>" + outputs_array[i]["title"] + "<<\n" >> +line_array[3] + "<<");
              if (outputs_array[i]['title'] == line_array[3]) {
                // se o nome do conector bate com o link
                // substitui para o nome da Intent trocando CONNECTOR por event(grupo_nomedaintent)
                newline =
                  "event('" +
                  grupo_corrente +
                  '_' +
                  outputs_array[i]['nameIntent'] +
                  "');\n";
                //console.log("Nova linha conector :\n" + newline)
              }
            }
          }
        }
      }
    }
    newtext = newtext + newline + '\n';
  });
  // retorna o PAYLOAD com o connector apontando para o bloco (intencao) correto.
  //console.log("Retorna NEWTEXT:" + newtext);
  return newtext;
}

export function precompile(drawflow) {
  //parse nested json
  //
  //console.log('array_variables=' + array_variables.toString());
  let keys = Object.keys(drawflow['drawflow']['drawflow']['Home']['data']);
  let outputs_array = new Array(); // conectores de saida - destino
  let payload, compiled_payload, conectorpadrao;
  let p;
  console.log('Entrou no precompile...');
  //return drawflow

  for (let b = 0; b < keys.length; b++) {
    // varrendo cada bloco do grupo
    var bloco = drawflow['drawflow']['drawflow']['Home']['data'][keys[b]]; // coloca na var "bloco" os dados de todo o bloco
    //console.log("bloco #" + b);
    grupo_corrente =
      drawflow['drawflow']['drawflow']['Home']['data'][keys[b]]['data'][
        'groupId'
      ];
    // vamos pegar os nomes dos conectores DESTINO de saida para colocar nos EVENT(DESTINO)
    outputs_array = [];
    conectorpadrao = '';

    if (bloco['data']['dataBlockly']) {
      //console.log(JSON.stringify(bloco["data"]));
      if (bloco['data']['dataBlockly']['payload']) {
        // se tem payload
        payload = bloco['data']['dataBlockly']['payload'];

        if (bloco['data']['outputs']) {
          outputs_array = bloco['data']['outputs'];
          //console.log("Tem Output....vai para set_payload_connectors...")
          compiled_payload = set_payload_connectors(payload, outputs_array);
          //console.log("salvando compile_payload no drawflow...");

          //console.log("Bloco #" + b + " NÃO TEM compiledPayload");
          drawflow['drawflow']['drawflow']['Home']['data'][keys[b]]['data'][
            'dataBlockly'
          ]['compiledPayload'] = compiled_payload;
        } else {
          //console.log("Não tem outputs");
        }
      }
    }

    // troca vars_ por vars.
    compiled_payload =
      drawflow['drawflow']['drawflow']['Home']['data'][keys[b]]['data'][
        'dataBlockly'
      ]['compiledPayload'];

    compiled_payload = fix_vars_declarations(compiled_payload);

    drawflow['drawflow']['drawflow']['Home']['data'][keys[b]]['data'][
      'dataBlockly'
    ]['compiledPayload'] = compiled_payload;
  }

  // startCompile(drawflow['drawflow']['drawflow']['Home']['data']);

  return drawflow;
}

export function compileBot(drawflows) {
  // console.log('Entrou no compilador:  ...compileBot...');
  if (drawflows[0]) agente_atual = drawflows[0].bot;

  const drawflowsUpdate = drawflows.map((item) => precompile(item));

  // drawflowsUpdate = precompile(drawflowtest)
  // console.log('saindo do compilador !');
  return drawflowsUpdate;
}

export function erase_line_with_var(input_line) {
  var newtext = '';
  var l = input_line.toString();
  var line;
  input_line = input_line.split(/\r?\n/);
  //console.log("input_line:: erase_line_with_var\n>>>" + l + "<<<");

  //console.log(typeof input_line + "\nLenght=" + input_line.length);
  //console.log(typeof l + "\nLenght=" + l.length);
  //console.log("lines\n" + lines);
  for (let i = 0; i < input_line.length; i++) {
    line = input_line[i];
    //console.log(">> " + i + "]" + line)
    if (line.startsWith('var ')) {
      //console.log("Erase line with vars" + line)
      if (
        line.startsWith('var vars.') ||
        line.startsWith('var vars_') ||
        line.startsWith('var  vars.') ||
        line.startsWith('var  vars_')
      ) {
        //console.log("line=null");
      } else {
        newtext = newtext + line + '\n';
      }
    } else if (line != '') {
      newtext = newtext + line + '\n';
    }
  }
  return newtext;
}
export function fix_vars_declarations(compiled_payload) {
  var x;
  compiled_payload = compiled_payload.replace(/vars_/g, 'vars.');
  compiled_payload = compiled_payload.replace(/resp_/g, 'resp.');
  //compiled_payload = compiled_payload.split(/\r?\n/);
  //console.log("fix_vars_declarations \n" + compiled_payload);
  if (
    compiled_payload.startsWith('var') &&
    compiled_payload.split('\n')[0].includes(',') &&
		!compiled_payload.split('\n')[0].includes('[') 
  ) {
    //vai desmontar a primeira linha com multiplos var contem "var XX,"
    //console.log("desmonta");
    x = compiled_payload.search('\n');
    compiled_payload =
      compiled_payload.split('\n')[0].replace(/,/g, ';\nvar ') +
      '\n' +
      compiled_payload.substring(x + 2, compiled_payload.length + 1).trim() +
      '\n';
    //console.log("Ve se deu certo\n" + compiled_payload + "\n\n>>\n" + compiled_payload.split('\n')[0] + "\n\n" + compiled_payload.split('\n')[1]);
  }
  compiled_payload = erase_line_with_var(compiled_payload);
  compiled_payload = compiled_payload.replace(/vars_/g, 'vars.');
  compiled_payload = compiled_payload.replace(/resp_/g, 'resp.');

  return compiled_payload;
}
