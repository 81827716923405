import { BuilderContextState, BuilderContextStateActions } from './types';

export const initialState = {
  openModalRestoreBot: false,
  openFalazap: false,
  openDebug: false,
  openAirTalk: false,
  openBotToRestore: false,
  openModalImportError: false,
  openModalConversations: false,
  openMetadataBlocks: false,
  openModalImportExportBot: false,
  openModalDeleteGroup: false,
  groupToDelete: undefined,
};

export function reducerBuilder(
  state: BuilderContextState,
  action: BuilderContextStateActions
): BuilderContextState {
  switch (action.type) {
    case 'openModalRestoreBot':
      return functionsReducer[action.type](state);

    case 'closeModalRestoreBot':
      return functionsReducer[action.type](state);

    case 'openFalazap':
      return functionsReducer[action.type](state);

    case 'closeFalazap':
      return functionsReducer[action.type](state);

    case 'openDebug':
      return functionsReducer[action.type](state);

    case 'closeDebug':
      return functionsReducer[action.type](state);

    case 'openAirTalk':
      return functionsReducer[action.type](state);

    case 'closeAirTalk':
      return functionsReducer[action.type](state);

    case 'openModalImportError':
      return functionsReducer[action.type](state);

    case 'closeModalImportError':
      return functionsReducer[action.type](state);

    case 'openModalConversations':
      return functionsReducer[action.type](state);

    case 'closeModalConversations':
      return functionsReducer[action.type](state);

    case 'openMetadataBlocks':
      return functionsReducer[action.type](state);

    case 'closeMetadataBlocks':
      return functionsReducer[action.type](state);

    case 'openModalImportExportBot':
      return functionsReducer[action.type](state);

    case 'closeModalImportExportBot':
      return functionsReducer[action.type](state);

    case 'setGroupToDelete':
      return functionsReducer[action.type](state, action.data);

    default:
      return state;
  }
}

const functionsReducer = {
  openAirTalk: (state: BuilderContextState) => {
    return { ...state, openAirTalk: true };
  },
  closeAirTalk: (state: BuilderContextState) => {
    return { ...state, openAirTalk: false };
  },
  openModalRestoreBot: (state: BuilderContextState) => {
    return { ...state, openModalRestoreBot: true };
  },
  closeModalRestoreBot: (state: BuilderContextState) => {
    return { ...state, openModalRestoreBot: false };
  },
  openFalazap: (state: BuilderContextState) => {
    return { ...state, openFalazap: true };
  },
  closeFalazap: (state: BuilderContextState) => {
    return { ...state, openFalazap: false };
  },
  openDebug: (state: BuilderContextState) => {
    return { ...state, openDebug: true };
  },
  closeDebug: (state: BuilderContextState) => {
    return { ...state, openDebug: false };
  },

  openModalImportError: (state: BuilderContextState) => {
    return { ...state, openModalImportError: true };
  },
  closeModalImportError: (state: BuilderContextState) => {
    return { ...state, openModalImportError: false };
  },
  openModalConversations: (state: BuilderContextState) => {
    return { ...state, openModalConversations: true };
  },
  closeModalConversations: (state: BuilderContextState) => {
    return { ...state, openModalConversations: false };
  },
  openMetadataBlocks: (state: BuilderContextState) => {
    return { ...state, openMetadataBlocks: true };
  },
  closeMetadataBlocks: (state: BuilderContextState) => {
    return { ...state, openMetadataBlocks: false };
  },
  openModalImportExportBot: (state: BuilderContextState) => {
    return { ...state, openModalImportExportBot: true };
  },
  closeModalImportExportBot: (state: BuilderContextState) => {
    return { ...state, openModalImportExportBot: false };
  },
  setGroupToDelete: (
    state: BuilderContextState,
    data?: Partial<BuilderContextState>
  ) => {
    return { ...state, groupToDelete: data?.groupToDelete };
  },
};
