import styled from 'styled-components';

export const Status = styled.div<{ bgColor: string }>`
  background: ${(props) => props.bgColor};

  border-radius: 50%;
  min-width: 14px !important;
  height: 14px !important;

  margin-right: 6px;
`;

export const StatusDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmailIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 64px;
`;
