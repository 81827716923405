import FooterButtons from 'components/inputs/Material/FooterButtons';
import Info from 'components/inputs/Material/Info';
import ToHtmlFields from 'components/inputs/Material/ToHtmlFields';
import { FormFieldsContainer } from '../Shared/styles';

export default function OpenHTML() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <ToHtmlFields />
      </FormFieldsContainer>
      <FooterButtons showLogicButton={false}/>
    </>
  );
}
