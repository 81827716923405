import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
const drawerWidth = 400;
const drawerMarginTop = 210;

export const OutputsContainer = styled.div`
  margin-bottom: 16px;
`;

export const AddOutputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
`;

export const ChoicesContainer = styled.div`
  margin-bottom: 32;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ChoicesIndexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ChoicesTitleContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ChoicesTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4;
`;

export const SimilarityContainer = styled.div`
  width: 100%;
  margin-top: 32;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      position: 'absolute',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginTop: drawerMarginTop,

      '&.MuiDrawer-paperAnchorDockedRight': {
        height: `calc(100% - ${drawerMarginTop + 9}px)`,
				zIndex: '5'
      },
    },

    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
      position: 'fixed',
      background: '#fff',
      width: '-webkit-fill-available',
      zIndex: 999,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    tabs: {
      marginTop: '64px',
    },
  })
);
