import styled from 'styled-components'
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const Container = styled.div`
.MuiLinearProgress-barColorPrimary {
	background-color: #9537ff;
}
`;

export const useStyles = makeStyles(() =>
  createStyles({
    firstBox: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: '16px',
      backgroundColor: '#e9e9e9',
    },
    secondBox: {
      width: '85%',
      padding: '16px',
    },
    progressBar: {
      color: '#9537ff',
      backgroundColor: '#c7b2f0',
      height: '12px',
      borderRadius: '6px',
    },
    progressValue: {
      color: '#656565',
      fontSize: '1.3rem',
    },
  })
);
