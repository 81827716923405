import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';

export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  overflow: hidden;

  .MuiAppBar-colorPrimary {
    background-color: #0e103b;
    position: absolute;
  }
`;

export const Container = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
