import { Box } from "@material-ui/core";
import styled from "styled-components";

export const DataSourceFileSelectedBox = styled(Box)`
  position: relative;
  min-height: 64px;
  width: 80%;
  min-width: 180px;
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  padding: 8px;
  margin: 0 auto;
  border-radius: 12px;
`;