import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IVerifyGoogleSheetIDRequest,
  IVerifyGoogleSheetIDResponse,
  IVerifyRocketChatIdRequest,
  IVerifyRocketChatIdResponse,
} from './types';

export const VerifyRocketChatId = async (
  request: IVerifyRocketChatIdRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IVerifyRocketChatIdResponse>> => {
  const action = `rocketchat`;
  return await json<IVerifyGoogleSheetIDResponse>(
    'POST',
    action,
    dispatch,
    request
  );
};

export const VerifyGoogleSheetID = async (
  request: IVerifyGoogleSheetIDRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IVerifyGoogleSheetIDResponse>> => {
  const action = `googlesheet`;
  return await json<IVerifyGoogleSheetIDResponse>(
    'POST',
    action,
    dispatch,
    request
  );
};
