import {
  CircularProgress,
  Grid,
  InputAdornment,
  TableFooter,
  TablePagination,
  TextField,
  Typography,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Search } from '@material-ui/icons';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useToast } from 'contexts/ToastContext';
import React, { useEffect, useState } from 'react';
import { GetCustomers } from 'services/CustomerService';
import { formatDateHour } from 'utils/Date';
import useTranslator from 'utils/hooks/Translator';
import * as S from '../styles';
import { SingleCustomer } from './SingleCustomer';

export function Customers() {
  const classes = S.useStyles();
  const { toastNotification } = useToast();
  const {
    currentData: { agentName: botName },
  } = useFintalkCloud();
  const [conversations, setConversations] = useState<{
    itens: any[];
    totalRecords: number;
  }>({ itens: [], totalRecords: 0 });
  const [openCustomerDetailsModal, setOpenCustomerDetailsModal] =
    useState(false);
  const [customer, setCustomer] = useState('');
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { getTranslation } = useTranslator();

  const filteredCustomers =
    customerId !== ''
      ? conversations.itens.filter((item) => item.id === customerId)
      : conversations.itens;

  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        const bot_name = botName || '';
        const result = await GetCustomers({
          agent: bot_name.split('-')[0],
          pageSize: String(rowsPerPage),
          from: String(rowsPerPage * page),
        });

        if (result.ok) {
          const { itens, totalRecords } = await result.json();

          setConversations({ itens, totalRecords });
        } else {
          setConversations({ itens: [], totalRecords: 0 });
          toastNotification({
            type: 'error',
            message: getTranslation('toast.error.searchCustomers'),
          });
        }
      } catch (err) {
        console.log(err);
        setConversations({ itens: [], totalRecords: 0 });
        toastNotification({
          type: 'error',
          message: getTranslation('toast.error.searchCustomers'),
        });
      }
      setLoading(false);
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, botName]);

  const handleOpenCustomerDetails = (currentCustomer: string) => () => {
    setCustomer(currentCustomer);
    setOpenCustomerDetailsModal(true);
  };

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  return (
    <>
      {customer && (
        <SingleCustomer
          customer={customer}
          open={openCustomerDetailsModal}
          handleClose={() => setOpenCustomerDetailsModal(false)}
        />
      )}

      <S.CustomersContainer disableGutters maxWidth={false}>
        <S.CustomersContent>
          <Typography variant="h6" gutterBottom>
            Customers
          </Typography>

          <S.SearchTagContainer>
            <S.TextContainer>
              <TextField
                label={getTranslation('modal.conversationHistory.search')}
                value={customerId || ''}
                onChange={(e) => setCustomerId(e.target.value)}
                helperText={error || ''}
                error={!!error}
                onBlur={() => setError('')}
                InputLabelProps={{ color: 'secondary' }}
                fullWidth
                style={{ maxWidth: 356 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </S.TextContainer>
          </S.SearchTagContainer>

          {loading ? (
            <Grid
              style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="secondary" size={36} />
            </Grid>
          ) : filteredCustomers.length > 0 ? (
            <S.CustomersListGrid>
              <TableContainer classes={{ root: classes.tableContainer }}>
                <S.CustomersTable aria-label="simple table" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {getTranslation('modal.conversationHistory.client')}
                      </TableCell>
                      <TableCell align="center">
                        {getTranslation('modal.conversationHistory.createdAt')}
                      </TableCell>
                      <TableCell align="center">
                        {getTranslation('modal.conversationHistory.updatedAt')}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredCustomers.map((row) => (
                      <TableRow
                        key={row.id}
                        onClick={handleOpenCustomerDetails(row.id)}
                      >
                        <TableCell scope="row">{row.id}</TableCell>
                        <TableCell align="center">
                          {formatDateHour(row.createdAt)}
                        </TableCell>
                        <TableCell align="center">
                          {formatDateHour(row.updatedAt)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        count={conversations.totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={getTranslation(
                          'modal.conversationHistory.linesPerPage'
                        )}
                      />
                    </TableRow>
                  </TableFooter>
                </S.CustomersTable>
              </TableContainer>
            </S.CustomersListGrid>
          ) : (
            <p style={{ marginTop: 24 }}>
              {getTranslation('noCustomersResult')}
            </p>
          )}
        </S.CustomersContent>
      </S.CustomersContainer>
    </>
  );
}
