import { Chip, withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const CreateParamContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  .MuiInput-underline:before {
    border-bottom: 1px solid #fff;
  }

  table {
    tbody {
      td {
        h6,
        input,
        div {
          color: #fff;
        }
      }
    }
  }

  button {
    align-self: end;
  }
`;

export const CustomChip = withTheme(styled(Chip)<{ selected: boolean }>`
  border: ${(props) =>
    props.selected
      ? `1px solid ${props.theme.palette.secondary.main}`
      : '1px solid #fff !important'};

  margin-right: 4px !important;
`);

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  align-self: end;
  margin-top: 24px;
  margin-bottom: -12px;
`;
