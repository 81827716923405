import { IAppContextActions } from 'contexts/App/types';
import { json } from 'services/RequestService';
import { IBaseResponse } from 'services/RequestService/Response';
import {
  IGetCampaignsRequest,
  ICreateCampaignResponse,
  IDeleteCampaignRequest,
  IGetCampaignsResponse,
  IGetOneCampaignRequest,
  IGetOneCampaignResponse,
  IUpdateCampaignRequest,
  IUpdateCampaignResponse,
  ICampaign,
} from './types';

const campaignAction = 'campaigns';
const botsAction = 'bots';

export const GetCampaigns = async (
  request: IGetCampaignsRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetCampaignsResponse>> => {
  const action = `${botsAction}/${request.bot_name}/${campaignAction}`;
  return await json<IGetCampaignsResponse>('GET', action, dispatch);
};

export const GetOneCampaign = async (
  request: IGetOneCampaignRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${campaignAction}/${request.campaignId}`;
  return await json<IBaseResponse<IGetOneCampaignResponse>>(
    'GET',
    action,
    dispatch
  );
};

export const CreateCampaign = async (
  request: ICampaign,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${campaignAction}`;
  return await json<IBaseResponse<ICreateCampaignResponse>>(
    'POST',
    action,
    dispatch,
    request
  );
};

export const UpdateCampaign = async (
  request: IUpdateCampaignRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${campaignAction}/${request._id}`;
  return await json<IBaseResponse<IUpdateCampaignResponse>>(
    'PATCH',
    action,
    dispatch,
    request
  );
};

export const DeleteCampaign = async (
  request: IDeleteCampaignRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${campaignAction}/${request._id}`;
  return await json<IBaseResponse<void>>('DELETE', action, dispatch);
};
