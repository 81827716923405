import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Sentences from 'components/inputs/Material/Sentences';
import Info from 'components/inputs/Material/Info/index';
import VariableIndentificationTextUser from 'components/inputs/Material/VariableIndentificationTextUser/index';
import { FormFieldsContainer } from '../Shared/styles';
import MessageForUser from 'components/inputs/Material/MessageForUser/index';
import { Grid } from '@material-ui/core';

export default function ChildMultipleChoice() {
  return (
    <>
      <FormFieldsContainer>
        <Info fieldsLock={['name']} />
        <Sentences />
        <MessageForUser />
        <Grid style={{ padding: '0 42px' }}>
          <VariableIndentificationTextUser index={0} showTitle={true} />
        </Grid>
        {/* <MultiVars
          showDelete={true}
          showQuestions={true}
          indexsRequired={[0]}
        /> */}
      </FormFieldsContainer>
      <FooterButtons />
    </>
  );
}
