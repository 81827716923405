import * as S from './styles';
import { StatusChipProps } from "./types";

const getStatusChipColorByName = (name: string) => {
    switch (name) {
        case 'APPROVED':
            return 'primary';
        case 'REJECTED':
            return 'secondary';
        default:
            return 'default';
    }
};

export function StatusChip({ label, status, ...props }: StatusChipProps) {
    return (
        <S.StatusChipComponent
            label={label}
            status={status}
            color={getStatusChipColorByName(status.toUpperCase())}
            {...props}
        />
    )
}