import { ILibraryContextActions, ILibraryContextState } from "./types";

export const initialState = {
  templateList: [],
  blockToSaveList: [],
} as ILibraryContextState;

export function reducerLibrary(
  state: ILibraryContextState,
  action: ILibraryContextActions
): ILibraryContextState {
  switch (action.type) {
    case "saveBlockSet":
      return functionsReducer[action.type](state, action.data);
    default:
      return state;
  }
}

const functionsReducer = {
  saveBlockSet: (state: ILibraryContextState, data?: Partial<ILibraryContextState>) => {
    return { ...state, ...data };
  },
}

export type ILibraryContextActionsTypes = keyof typeof functionsReducer;
