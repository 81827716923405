import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const VariablesIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill={color || '#424242'}
    >
      <g>
        <circle cx="10" cy="8" r="4" />
        <path d="M10.35,14.01C7.62,13.91,2,15.27,2,18v2h9.54C9.07,17.24,10.31,14.11,10.35,14.01z" />
        <path d="M19.43,18.02C19.79,17.43,20,16.74,20,16c0-2.21-1.79-4-4-4s-4,1.79-4,4c0,2.21,1.79,4,4,4c0.74,0,1.43-0.22,2.02-0.57 L20.59,22L22,20.59L19.43,18.02z M16,18c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C18,17.1,17.1,18,16,18z" />
      </g>
    </svg>
  );
};

export default VariablesIcon;
