import {
  IPermission,
  IPermissionData,
} from '../services/PermissionServices/types';

import { IUserPermissions } from '../services/OrganizationServices/types';
import { arrayUnique } from './ArrayFunctions';

export function reducerUserPermissions(
  permissionData: IPermissionData[]
): IPermission[] {
  const permissions: IPermission[] = [];

  const allPermissions = permissionData.map((d) => d.permissions);

  allPermissions.forEach((item) => {
    if (Array.isArray(item) && !!item.length) {
      item.forEach((permission) => {
        if (permissions.some((p) => p.bot === permission.bot)) {
          const iterationPermissionIndex = permissions.findIndex(
            (p) => p.bot === permission.bot
          );
          const iterationPermission = permissions.find(
            (p) => p.bot === permission.bot
          );

          if (!!iterationPermission?.functions) {
            permissions[iterationPermissionIndex].functions =
              iterationPermission.functions;
          }
        } else {
          permissions.push(permission);
        }
      });
    }
  });

  return permissions;
}

export function insertBotPermissionInUser(
  botName: string,
  permission: IPermission,
  user: IUserPermissions
) {
  const userPermissionIndex = user?.permissions.findIndex(
    (p: IPermission) => p.bot === botName
  );

  if (
    Array.isArray(user?.permissions) &&
    user?.permissions[userPermissionIndex]
  )
    user.permissions[userPermissionIndex] = permission;
  else {
    user.permissions.push(permission);
  }

  return user;
}

export function reducerPermissionsFunctionToArray(permissions: IPermission[]) {
  let listPermissions: string[] = [];

  permissions.forEach((permission) => {
    const listPermission = permissionFunctionToArray(permission);
    listPermissions = arrayUnique(listPermissions.concat(listPermission));
  });

  return listPermissions;
}

export function permissionFunctionToArray(permission: IPermission) {
  const { functions } = permission;

  const listPermissions: string[] = [];

  if (functions !== null)
    (Object.keys(functions) as Array<keyof typeof functions>).forEach(
      (functionName) => {
        if (functions[functionName] === true) {
          listPermissions.push(functionName);
        }
      }
    );

  return listPermissions;
}
