/* eslint-disable */
export const mainFlow = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'dbclick',
          data: {
            name: 'boas-vindas',
            description:
              'Este bloco é destinado a iniciar a conversa com o usuário',
            intentType: 8,
            groupId: 'Principal',
            inputs: {
              userSentences: ['', '', ''],
              variables: [
                {
                  name: '',
                  vartype: '',
                  questions: ['', '', ''],
                  required: false,
                  value: '',
                },
              ],
            },
            dataBlockly: {
              lastVersion: 0,
              payload: '',
            },
          },
          class: 'dbclick',
          html: '',
          typenode: false,
          inputs: {},
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 32,
          pos_y: 6,
        },
        2: {
          id: 2,
          name: 'dbclick',
          data: {
            name: 'nao-entendi',
            description:
              'Este bloco é acionado toda vez que o usuário estiver fora de algum fluxo',
            intentType: 9,
            groupId: 'Principal',
            dataBlockly: {
              lastVersion: 0,
              payload: '',
            },
          },
          class: 'dbclick',
          html: '',
          typenode: false,
          inputs: {},
          outputs: {},
          pos_x: 32,
          pos_y: 94,
        },
        3: {
          id: 3,
          name: 'dbclick',
          data: {
            name: 'cancelar',
            description:
              'Este bloco é acionado toda vez que o usuário estiver fora de algum fluxo',
            intentType: 10,
            groupId: 'Principal',
            dataBlockly: {
              lastVersion: 0,
              payload: '',
            },
          },
          class: 'dbclick',
          html: '',
          typenode: false,
          inputs: {},
          outputs: {},
          pos_x: 32,
          pos_y: 181,
        },
      },
      version: 1,
    },
  },
};

export const emptyFlow = {
  drawflow: {
    Home: {
      data: {},
      version: 1,
    },
  },
};
