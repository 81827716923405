import { Button, IconButton } from '@material-ui/core';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import MicIcon from '@material-ui/icons/Mic';
import { useAirTalk } from 'contexts/AirTalkContext';
import React from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import useTranslator from 'utils/hooks/Translator';
import i18n from 'utils/i18n';

const Dictaphone = ({
  setUserPrompt,
  userPrompt,
}: {
  setUserPrompt: React.Dispatch<React.SetStateAction<string>>;
  userPrompt: string;
}) => {
  const {
    resetTranscript,
    transcript,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const { generateText } = useAirTalk();
  const { getTranslation } = useTranslator();

  if (!browserSupportsSpeechRecognition) {
    return <span>Este navegador não tem suporte a STT.</span>;
  }

  function handleStopRecording() {
    SpeechRecognition.stopListening();
    setUserPrompt(transcript);
  }

  function generateFlow() {
    resetTranscript();
    generateText(userPrompt, true);
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '8px',
        width: '100%',
        whiteSpace: 'pre-wrap',
      }}
    >
      <p>
        {listening
          ? getTranslation('airtalkSideBar.listening')
          : getTranslation('airtalkSideBar.pressToRecord')}
      </p>
      <IconButton
        onMouseDown={() =>
          SpeechRecognition.startListening({
            continuous: true,
            language: i18n.language,
          })
        }
        onMouseUp={() => handleStopRecording()}
      >
        <MicIcon color={!listening ? 'primary' : 'error'} />
      </IconButton>

      <Button variant="contained" color="primary" onClick={generateFlow}>
        {getTranslation('airtalkSideBar.generateFlow')}
        <FlashOnIcon />
      </Button>
    </div>
  );
};

export default Dictaphone;
