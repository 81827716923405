export interface IActioning {
  _id?: string;
	name: string;
  scheduled_date: Date;
  campaign_name: string;
  status: IActioningStatus;
  bot_name: string;
  creation_date: Date;
  description: string;
  file: string;
  organization: string;
  content: string;
  sheet_values?: string[];
	log_file?: string;
}

export type IActioningStatus = 'concluded' | 'in_progress' | 'not_started' | 'error';

export interface IGetActioningsRequest
  extends Pick<IActioning, 'bot_name' | 'organization'> {}
export interface IGetActioningsResponse {
  data: IActioning[];
}

export interface IGetOneActioningRequest extends IActioning {
  organization: string;
  campaignId: string;
}

export interface IGetOneActioningResponse {
  data: IActioning;
}

export interface ICreateActioningRequest extends IActioning {}

export interface ICreateActioningResponse {
  data: IActioning;
}

export interface IUpdateActioningRequest extends IActioning {}

export interface IUpdateActioningResponse {
  data: IActioning;
}

export interface IDeleteActioningRequest
  extends Pick<IActioning, 'bot_name' | 'organization' | '_id'> {}

export interface ITriggerActioningRequest
  extends Pick<IActioning, 'bot_name' | 'organization' | '_id'> {}

export const emptyActioning: IActioning = {
  scheduled_date: new Date(),
	name: '',
  campaign_name: '',
  status: 'not_started',
  bot_name: '',
  creation_date: new Date(),
  description: '',
  file: '',
  organization: '',
  content: '',
};
