import styled from 'styled-components';
import MttTypography from 'components/Material/MttTypography/MttTypography';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import TextField from '@material-ui/core/TextField/TextField';

export const GridContainer = styled(MttGrid)`
  margin: 16px;
`;

export const StyledMttTypographyMsgForUser = styled(MttTypography)`
  margin-bottom: 16px !important;
`;

export const SwitchDiv = styled(MttGrid)`
  display: flex;
  align-items: center;
  padding: 16px;

  .MuiTypography-body1 {
    font-weight: bold;
    color: #0e103b;
    margin-right: 24px;
  }

  .MuiTypography-subtitle1 {
    margin-right: 16px;
  }

  .MuiInputBase-input {
    max-width: 120px;
  }
`;

export const ContainerButton = styled(MttGrid)`
  display: flex;
  justify-content: space-between;

  padding: 8px 0;
`;

export const Container = styled(MttGrid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const MessageContainer = styled(MttGrid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 8px;
  padding: 16px;
  background: #f2f2f2;
`;

export const ContentGrid = styled(MttGrid)`
  width: calc(100% - 32px);
  padding: 16px;

  border-radius: 4px;

  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  .MuiTypography-body1 {
    color: #0e103b;
    font-weight: 500;
  }
`;

export const ContentMsgForUserGrid = styled.div`
  padding: 16px;

  background-color: #f2f2f2;

  .MuiInputBase-input {
    background-color: #ffffff;
  }
`;

export const StyledMttTextField = styled(TextField)`
  background: #fff;
`;
