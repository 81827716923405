import backgroundImg from 'components/Icons/whats-bg.png';
import styled from 'styled-components';
import { NewCampaignFields } from '../styles';

export const NewTemplatePreview = styled(NewCampaignFields)`
  overflow-x: hidden;
  width: 25%;
  min-width: 200px;

  background: url(${backgroundImg});
  background-size: cover;
  border-radius: 8px;

  margin: 12px 0 12px 4px;
  padding: 12px;
`;

export const PreviewContainer = styled.div`
  width: 20%;
  position: fixed;
  color: #000;
  overflow-y: scroll;
  max-height: 60%;
`;

export const MessagePreviewContainer = styled.div`
  width: fit-content;
  max-width: 284px;
`;

export const MessagePreview = styled.div`
  display: flex;

  flex-direction: column;
  padding: 8px;

  margin: 4px 8px;

  min-width: 94px;
  background-color: #fff;

  border-radius: 6px;

  position: relative;

  border-bottom: 1px solid #c2c2c2;

  :before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid #fff;
    border-top: 6px solid #fff;
    border-bottom: 6px solid transparent;
    left: -6px;
    top: 0px;
  }
`;

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MessageHeader = styled.span`
  font-weight: bold;
  font-size: 16px;

  img {
    width: -moz-available;
    width: -webkit-fill-available;
  }
`;

export const MessageBody = styled.span<{ hasContent: boolean }>`
  padding: ${(props) => (props.hasContent ? '4px 0' : '8px 0')};

  white-space: pre-wrap;
  word-break: break-word;
`;

export const MessageFooter = styled.span<{ hasContent: boolean }>`
  color: #00000073;
  padding-bottom: ${(props) => (props.hasContent ? '4px' : '0px')};
`;

export const StyledCall = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #c2c2c2;
  padding: 8px 4px 4px 4px;
  align-items: center;
  justify-content: center;
  color: #00a5f4;
`;

export const UrlAndNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 4px;
  }
`;

export const StyledQuickReply = styled.div`
  width: auto;
  flex: 1;
`;

export const QuickAnswerButtonContainer = styled.div`
  min-width: 75px;
  width: inherit;

  margin: 2px;

  border-radius: 6px;
  border-bottom: 1px solid #c2c2c2;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  color: #00a5f4;
  font-size: 12px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
`;
