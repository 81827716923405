import * as yup from 'yup';
import { IResultError } from './ResultError';

export interface IAdmin {
  username: string;
  password: string;
  passwordConfirmation: string;
}

export const adminValidation = async (user: IAdmin) => {
  const emailResult = await emailValidator(user.username);
  const passwordResult = await passwordValidator(user.password);
  const confirmPasswordResult = await confirmPasswordValidator(
    user.password,
    user.passwordConfirmation
  );
  const isValid =
    emailResult.isValid && confirmPasswordResult.isValid;
  const errors = {
    ...emailResult.errors,
    ...passwordResult.errors,
    ...confirmPasswordResult.errors,
  };
  return { isValid, errors };
};

export const emailValidator = async (username: string) => {
  const schema = yup.object().shape({
    username: yup
      .string()
      .required('*E-mail obrigatório.')
      .email('*E-mail inválido.'),
  });

  const promiseResult = new Promise<IResultError<IAdmin>>((resolve) => {
    schema
      .validate({ username }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { username: '' } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { username: err.errors[0] } });
      });
  });
  return await promiseResult;
};

const passwordValidator = async (password: string) => {
  const schema = yup.object().shape({
    password: yup
      .string()
      .required('*Senha obrigatória.')
      .min(6, '*Senha deve ter no mínimo 6 caracteres.'),
  });

  const promiseResult = new Promise<IResultError<IAdmin>>((resolve) => {
    schema
      .validate({ password }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { password: '' } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { password: err.errors[0] } });
      });
  });
  return await promiseResult;
};

const confirmPasswordValidator = async (
  password: string,
  passwordConfirmation: string
) => {
  const schema = yup.object().shape({
    passwordConfirmation: yup.mixed().test(
      'match',
      '*Senha e confirmação de senha devem ser iguais.',
      function () {
        return password === this.parent.passwordConfirmation;
      }
    ),
  });

  const promiseResult = new Promise<IResultError<IAdmin>>((resolve) => {
    schema
      .validate({ passwordConfirmation }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { passwordConfirmation: '' } });
      })
      .catch((err) => {
        resolve({
          isValid: false,
          errors: { passwordConfirmation: err.errors[0] },
        });
      });
  });
  return await promiseResult;
};
