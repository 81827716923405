import { Grid, makeStyles, Menu, Theme } from "@material-ui/core";
import MttGrid from "components/Material/MttGrid/MttGrid";
import styled from "styled-components";

export const DialogContainer = styled(MttGrid)`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  .MuiAppBar-colorPrimary {
    background-color: #0e103b;
    position: absolute;
  }
`;

export const ContentContainer = styled(Grid)`
  overflow-y: auto;
  overflow-x: auto;
  padding: 40px;

  table {
    thead {
      box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.4);

      tr {
        th {
          padding: 30px 12px 12px 12px;
          font-size: medium;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 12px;
          border-bottom: 2px solid #DCD7D7;
        }
      }
    }
  }
`;

export const ContentHeader = styled.div`
  width: 100%;
  height: 56px;
  background-color: #E3E3E3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-radius: 18px 18px 0 0;

  p {
    font-size: 20px;

    span {
      font-weight: bolder;
    }
  }
`;

export const MenuActionsContainer = styled(Menu)`
  .MuiPopover-paper {
    min-width: 140px;
    border-radius: 18px;
  }

  .MuiList-padding {
    padding: 0;
  }

  li {
    padding: 12px 16px;
  }
`;

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderRadius: 12,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  addButton: {
    padding: '4px 12px',
    height: '32px',
  },
  table: {
    minWidth: 650,
  },
  appBar: {
    position: 'relative',
    background: theme.palette.primary.main,
  },
}));