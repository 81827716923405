import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useFlow } from 'contexts/Flow/flowContext';
import {
  ButtonContainer,
  ButtonOk,
  Container,
  ContentContainer,
  ImageContainer,
  ModalTitle,
  TextContainer,
} from './styles';

import edit from '../Icons/edit.png';
import useTranslator from 'utils/hooks/Translator';

export default function ModalUsedGroup() {
  const useStyles = makeStyles({
    dialog: {
      position: 'absolute',
      left: 'center',
      top: 45,
      borderRadius: '10px',
    },

    modalTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: 1.6,
    },
  });
  const classes = useStyles();

  const { getTranslation } = useTranslator();

  const { state, dispatch, verifyEditGroup } = useFlow();
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem('user_group')
  );

  useEffect(() => {
    const local = localStorage.getItem('user_group');
    setCurrentUser(local);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('user_group')]);

  async function handleUpdate() {
    dispatch({ type: 'closeModalUsedGroup' });

    if (state.idGroup && state.botName) {
      await verifyEditGroup(state.idGroup, state.botName);
      setCurrentUser(localStorage.getItem('user_group'));
      dispatch({ type: 'updateForm', data: { loading: true } });
      dispatch({ type: 'updateForm', data: { loading: false } });
    }
  }

  if (!state.openModalUsedGroup) return <></>;

  return (
    <Container>
      <ImageContainer>
        <img src={edit} alt="editing" />
      </ImageContainer>
      <ContentContainer>
        <ModalTitle
          classes={{
            h6: classes.modalTitle,
          }}
        >
          {getTranslation('modal.used.title')}
        </ModalTitle>
        <TextContainer>
          {currentUser !== 'undefined'
            ? getTranslation('modal.used.subtitle', { user: currentUser })
            : getTranslation('modal.used.subtitleCouldNotGetUser')}
        </TextContainer>
      </ContentContainer>
      <ButtonContainer>
        <ButtonOk color="primary" variant={'contained'} onClick={handleUpdate}>
          {getTranslation('modal.used.button')}
        </ButtonOk>
      </ButtonContainer>
    </Container>
  );
}
