/* eslint-disable */
import * as Blockly from 'blockly/core';
import { agente_atual, grupo_corrente } from './logic';
const GOOGLE_SPREADSHEET_URL = '/google-spreadsheet';

const apiHubURL = process.env.REACT_APP_API_HUB_RUN_URL

export let APIKEY = '[[fintalkApiKey]]';
///TODO: LOBO testar envio de SMS e TROCAR o tirar o DEV debaixo...api-biz-hub-dev.fintalk.io
export let send_sms_URI =
    `${apiHubURL}/sms_send/?apikey=` + APIKEY;
export let send_sms_sender = '5511988399323';

export let COMPANYID = 'fintalk';
export let API_SERVER =
    process.env.REACT_APP_ENV === 'production' ?
    'payload.fintalk.io' :
    'payload-dev.fintalk.io';

// "t6fthv.deta.dev"
export let DB_API_SERVER =
    process.env.REACT_APP_ENV === 'production' ?
    'zaz_pos.deta.dev' :
    'zaz_pos.deta.dev';
// "t6fthv.deta.dev"

var codelabToolbox = `
<xml id="toolbox" style="display: none">
<block type="object"/>
<block type="member"></block>
<block type="math_number"><field name="NUM">0</field></block>
<block type="text"><field name="TEXT"/></block>
<block type="logic_boolean"><field name="BOOL">TRUE</field></block>
<block type="logic_null"/>
<block type="lists_create_with"><mutation items="3"/></block>
</xml>
`;

function tira_aspas(nome) {
    if (nome.startsWith("'") || nome.startsWith('"')) {
        nome = nome.slice(1, nome.length - 1);
    }

    return nome;
}

function tira_colchetes(nome) {
    if (!nome || nome == 'undefined' || nome == 'null') return "''";

    if (nome.startsWith('(') && nome.endsWith(')')) {
        nome = nome.slice(1, nome.length - 1);
    }
    return nome;
}

var mathChangeJson = {
    message0: 'change %1 by %2',
    args0: [{
            type: 'field_variable',
            name: 'VAR',
            variable: 'item',
            variableTypes: [''],
        },
        { type: 'input_value', name: 'DELTA', check: 'Number' },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 230,
};

Blockly.Blocks['multiline_text_input'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(Blockly.Msg['MULTILINE_TEXT'])
            .appendField(new Blockly.FieldMultilineInput(''), 'TEXT');
        this.setOutput(true, 'Text');
        this.setColour(160, 45, 65);
    },
};

Blockly.JavaScript['multiline_text_input'] = function(block) {
    // Text value.
    var code = Blockly.JavaScript.multiline_quote_(block.getFieldValue('TEXT'));
    var order =
        code.indexOf('+') != -1 ?
        Blockly.JavaScript.ORDER_ADDITION :
        Blockly.JavaScript.ORDER_ATOMIC;
    return [code, order];
};

// Blockly.JavaScript['multiline_text_input'] = function(block) {
// 	var code = block.getFieldValue('TEXT');

// 	return [`'` + code + `'`, Blockly.JavaScript.ORDER_ATOMIC];
// };

Blockly.JavaScript['text_length'] = function(block) {
    // String or array length.
    var argument0 =
        Blockly.JavaScript.valueToCode(
            block,
            'VALUE',
            Blockly.JavaScript.ORDER_FUNCTION_CALL
        ) || "''";
    return [argument0 + '.length', Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.JavaScript['jscode'] = function(block) {
    var value_code = Blockly.JavaScript.valueToCode(
        block,
        'CODE',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = value_code.slice(1, value_code.length - 1) + ';\n';
    return code;
};

Blockly.JavaScript['comment'] = function(block) {
    var comment = Blockly.JavaScript.quote_(block.getFieldValue('TEXT'));

    comment = tira_aspas(comment);
    var code = '/*' + comment + '*/\n';
    return code;
};

Blockly.JavaScript['jscodemultiline'] = function(block) {
    // Text value.
    var code = Blockly.JavaScript.multiline_quote_(block.getFieldValue('CODE'));
    var order =
        code.indexOf('+') != -1 ?
        Blockly.JavaScript.ORDER_ADDITION :
        Blockly.JavaScript.ORDER_ATOMIC;
    var jscode = 'return ' + code + ';';
    var jstext = new Function(jscode);
    return jstext() + '\n';
};
Blockly.JavaScript['jscode_inline'] = function(block) {
    // Text value.
    var code = block.getFieldValue('CODE');
    code = tira_colchetes(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['jump_to_group'] = function(block) {
    var value_grupo = Blockly.JavaScript.valueToCode(
        block,
        'GRUPO',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_bloco = Blockly.JavaScript.valueToCode(
        block,
        'BLOCO',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    value_bloco = tira_aspas(tira_colchetes(value_bloco));
    value_grupo = tira_aspas(tira_colchetes(value_grupo));
    var code = "event('" + value_grupo + '_' + value_bloco + "');\n"; // #PARSER#EVENT#" + value_grupo + "#" + value_bloco + "#\n";
    return code;
};

Blockly.JavaScript['jump_to'] = function(block) {
    var value_bloco = Blockly.JavaScript.valueToCode(
        block,
        'BLOCO',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    value_bloco = tira_aspas(tira_colchetes(value_bloco));
    var code = "event('" + grupo_corrente + '_' + value_bloco + "');\n"; // #PARSER#EVENT#" + grupo_corrente + "#" + value_bloco + "#\n";
    return code;
};
Blockly.JavaScript['connector'] = function(block) {
    var value_connector = Blockly.JavaScript.quote_(block.getFieldValue('TEXT'));

    value_connector = tira_aspas(tira_colchetes(value_connector));

    var code = '// #PARSER#CONNECTOR#' + value_connector + '#\n';
    return code;
};

Blockly.JavaScript['console_print'] = function(block) {
    // Print statement.
    var msg =
        Blockly.JavaScript.valueToCode(
            block,
            'TEXT',
            Blockly.JavaScript.ORDER_NONE
        ) || "''";
    var code =
        'if (isString(vars.debug)) {\nvar debugtext = ' +
        msg +
        ';\n}\n// Print to console\n';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['msg'] = function(block) {
    var value_msg = Blockly.JavaScript.valueToCode(
        block,
        'MSG',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var dropdown_tipo = block.getFieldValue('TIPO');

    var code =
        'msg(' + tira_colchetes(value_msg) + ",'" + dropdown_tipo + "');\n";

    return code;
};

Blockly.JavaScript['audio_msg'] = function(block) {
    var value_msg = Blockly.JavaScript.valueToCode(
        block,
        'MSG',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = 'msg(' + tira_colchetes(value_msg) + ",'tts');\n";

    return code;
};

Blockly.JavaScript['audio_out'] = function(block) {
    var value_voice = Blockly.JavaScript.valueToCode(
        block,
        'voice',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_text = Blockly.JavaScript.valueToCode(
        block,
        'text',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    // var code =
    //   "msg((await request('POST','https://api-hub.fintalk.io/azure/tts',{\"voice\":" +
    //   value_voice +
    //   ',"text":' +
    //   value_text +
    //   ',"provider" :"azure","key": "[[azureSubscriptionKey]]"},false,false)).url,\'audio\');\n';
    // TODO: Change ORDER_NONE to the correct strength.
    //return [code, Blockly.JavaScript.ORDER_NONE];

    var code = `const resp = await request('POST','https://api-hub.fintalk.io/azure/tts?apikey=b1fcea2c-d3a6-11eb-b8bc-0242ac130003',{
		"voice": ${value_voice},
		"text": ${value_text},
		"provider" :"azure",
		"key": "[[azureSubscriptionKey]]"
		}
		,"",false,false);

		msg(resp.url, 'audio');
		`;
    return code;
};



Blockly.JavaScript['wait'] = function(block) {
    var number_seconds = block.getFieldValue('seconds') * 1000;
    var code = `await sleep(${number_seconds});\n`;
    return code;
};

Blockly.JavaScript['workflow_push'] = function(block) {
    var type = block.getFieldValue('type');
    var value_id = Blockly.JavaScript.valueToCode(
        block,
        'id',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_json = Blockly.JavaScript.valueToCode(
        block,
        'json',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var json = '{ "id": value_id, "json": value_json }';
    var code =
        "await request('POST',`${apiHubURL}/workflow_push/?apikey=b1fcea2c-d3a6-11eb-b8bc-0242ac130003&type=`+type," +
        json +
        ',false,false);';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['translate'] = function(block) {
    var value_source = Blockly.JavaScript.valueToCode(
        block,
        'source',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_target = Blockly.JavaScript.valueToCode(
        block,
        'target',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_text = Blockly.JavaScript.valueToCode(
        block,
        'text',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    // TODO: Assemble JavaScript into co_textde variable.
    var code =
        "await request('POST',`${apiHubURL}/translate?apikey=[[payloadAPI]]`,{\"text\":" +
        value_text +
        ',"source":' +
        value_source +
        ',"target" :' +
        value_target +
        ',"key": "[[googleProjectId]]"},false,false)';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['dupla_json'] = function(block) {
    var value_name = Blockly.JavaScript.valueToCode(
        block,
        'NOME',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_value = Blockly.JavaScript.valueToCode(
        block,
        'VALOR',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    // troca 'por ""
    if (value_value.startsWith("'") || value_value.startsWith('"')) {
        value_value = '"' + value_value.slice(1, value_value.length - 1) + '"';
    }

    var code = '"' + tira_aspas(tira_colchetes(value_name)) + '":' + value_value;

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['json_create_with'] = function(block) {
    // Create a list with any number of elements of any type.
    var elements = new Array(block.itemCount_);
    for (var i = 0; i < block.itemCount_; i++) {
        elements[i] =
            Blockly.JavaScript.valueToCode(
                block,
                'ADD' + i,
                Blockly.JavaScript.ORDER_NONE
            ) || 'null';
    }
    var code = '{' + elements.join(', ') + '}';
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript['ToInt'] = function(block) {
    var value = Blockly.JavaScript.valueToCode(
        block,
        'TOINT',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    value = tira_aspas(tira_colchetes(value));
    value = "parseInt('" + value + "')";
    var code = value;
    return [code, Blockly.JavaScript.ORDER_NONE];
};
Blockly.JavaScript['ToDec'] = function(block) {
    var value = Blockly.JavaScript.valueToCode(
        block,
        'TODEC',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    value = tira_aspas(tira_colchetes(value));
    value = "parseFloat('" + value + "')";
    var code = value;
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['getphone'] = function(block) {
    var code = 'getPhone()';

    return [code, Blockly.JavaScript.ORDER_NONE];
    //return code;
};

Blockly.JavaScript['clearvars'] = function(block) {
    var code = 'clearVars();\n';

    //return [code, Blockly.JavaScript.ORDER_NONE];
    return code;
};

Blockly.JavaScript['var_user'] = function(block) {
    var dropdown_tipo = block.getFieldValue('TIPO');

    var code = 'user.' + dropdown_tipo;
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['set_var_user'] = function(block) {
    var value_msg = Blockly.JavaScript.valueToCode(
        block,
        'VALOR',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var dropdown_tipo = block.getFieldValue('TIPO');

    value_msg = tira_colchetes(value_msg);
    if (value_msg == '') {
        value_msg = "''";
    }
    var code = 'user.' + tira_colchetes(dropdown_tipo) + '=' + value_msg + ';\n';
    return code;
};

Blockly.JavaScript['get_vars'] = function(block) {
    var dropdown_tipo = block.getFieldValue('TIPO');

    var code = dropdown_tipo;
    code = code.replace(/\_/g, '.');
    code = code.replace(/vars_/g, 'vars.');
    //console.log("GET_VARS>" + code)
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['set_vars'] = function(block) {
    var value_msg = Blockly.JavaScript.valueToCode(
        block,
        'VALOR',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var dropdown_tipo = block.getFieldValue('TIPO');

    value_msg = tira_colchetes(value_msg);
    if (value_msg == '') {
        value_msg = "''";
    }
    var code = tira_colchetes(dropdown_tipo) + '=' + value_msg + ';\n';
    code = code.replace(/\_/g, '.');
    //console.log("SET_VARS>" + code)
    return code;
};

Blockly.JavaScript['get_variables'] = function(block) {
    var dropdown_tipo = block.getFieldValue('TIPO');

    var code = "'" + dropdown_tipo + "'";
    code = code.replace(/\_/g, '.');
    //console.log("GET_VARS>" + code)
    return [code, Blockly.JavaScript.ORDER_NONE];
};
// TODO: LOBO Banco de Dados de Usuários.
// 5 funcoes de banco de dados
// new_user_data  (cria um novo usuario passando um userid ou nulo se quiser deixar a tarefa
//    do novo userid ser criado pelo banco - e recebe o user id criado)
// get_user_data  (que recebe o user id e o campo e retorna o valor)
// set_user_data  (que recebe o user id e o campo e grava o valor)
// get_user_id  (recebe o campo e um texto para fazer match e retorna um array com os userids que deram match)
// get_user_data_json  (que recebe o user id e retorna todos os campos num json)

Blockly.JavaScript['get_user_data'] = function(block) {
    // recebe o userid e o campo e retorna o conteudo do campo
    var dropdown_field = block.getFieldValue('field');
    var value_userid = Blockly.JavaScript.valueToCode(
        block,
        'userid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_companyid = Blockly.JavaScript.valueToCode(
        block,
        'companyid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    // TODO: LOBO Banco de Dados de Usuários.
    var code =
        "await request('get','https://" +
        DB_API_SERVER +
        "/get_user_data/'+" +
        tira_colchetes(value_userid) +
        '+';
    var code =
        code +
        "'?apikey=" +
        APIKEY +
        "&company='+" +
        tira_colchetes(value_companyid) +
        "+'&field='+" +
        tira_colchetes(dropdown_field) +
        ')';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['set_user_data'] = function(block) {
    // passa o userid, o campo e valor e 200 se operacao ok
    var dropdown_field = block.getFieldValue('field');
    var value_userid = Blockly.JavaScript.valueToCode(
        block,
        'userid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_input_value = Blockly.JavaScript.valueToCode(
        block,
        'input_value',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_companyid = Blockly.JavaScript.valueToCode(
        block,
        'companyid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        "await request('get','https://" +
        DB_API_SERVER +
        "/get_user_data/'+" +
        tira_colchetes(value_userid) +
        '+';
    var code =
        code +
        "'?apikey=" +
        APIKEY +
        "&company='+" +
        tira_colchetes(value_companyid) +
        "+'&field='+" +
        tira_colchetes(dropdown_field) +
        "+'&value='+" +
        tira_colchetes(value_input_value) +
        ')';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['llm_advanced'] = function(block) {
    var value_prompt = Blockly.JavaScript.valueToCode(block, 'prompt', Blockly.JavaScript.ORDER_ATOMIC);
    var value_apikey = Blockly.JavaScript.valueToCode(block, 'apikey', Blockly.JavaScript.ORDER_ATOMIC);
    var value_temperature = Blockly.JavaScript.valueToCode(block, 'temperature', Blockly.JavaScript.ORDER_ATOMIC);
    var value_maxtokens = Blockly.JavaScript.valueToCode(block, 'maxtokens', Blockly.JavaScript.ORDER_ATOMIC);
    var dropdown_modelo = block.getFieldValue('Modelo');
    var value_model = Blockly.JavaScript.valueToCode(block, 'model', Blockly.JavaScript.ORDER_ATOMIC);
    var value_top_p = Blockly.JavaScript.valueToCode(block, 'top_p', Blockly.JavaScript.ORDER_ATOMIC);
    var value_presence_penalty = Blockly.JavaScript.valueToCode(block, 'presence_penalty', Blockly.JavaScript.ORDER_ATOMIC);
    var value_frequency_penalty = Blockly.JavaScript.valueToCode(block, 'frequency_penalty', Blockly.JavaScript.ORDER_ATOMIC);
    var stop_caracter = Blockly.JavaScript.valueToCode(block, 'stop_caracter', Blockly.JavaScript.ORDER_ATOMIC);

		if(stop_caracter.trim() === "") stop_caracter = '""'

    value_apikey = tira_aspas(value_apikey);
    var code = `await request('POST','https://api.openai.com/v1/engines/${dropdown_modelo}/completions',`;
    code = code + `{"prompt": ${value_prompt},"temperature": ${value_temperature}, "max_tokens": ${value_maxtokens}, "stop": ${stop_caracter || ""}, "top_p": ${value_top_p}, "frequency_penalty": ${value_frequency_penalty}, "presence_penalty": ${value_presence_penalty}},`;
    code = code + `{"headers": {"Authorization": "Bearer ${value_apikey}"}  }, false, false)`;

		return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['llm_basic'] = function(block) {
    var dropdown_provider = block.getFieldValue('provider');
    var value_provider = Blockly.JavaScript.valueToCode(block, 'provider', Blockly.JavaScript.ORDER_ATOMIC);
    var value_prompt = Blockly.JavaScript.valueToCode(block, 'prompt', Blockly.JavaScript.ORDER_ATOMIC);
    var value_maxtokens = Blockly.JavaScript.valueToCode(block, 'maxtokens', Blockly.JavaScript.ORDER_ATOMIC);
    var value_apikey = Blockly.JavaScript.valueToCode(block, 'apikey', Blockly.JavaScript.ORDER_ATOMIC);
    value_apikey = tira_aspas(value_apikey);

		var code = "await request('POST','https://api.openai.com/v1/engines/text-davinci-002/completions',";
    code = code + `{"prompt":  ${value_prompt},"temperature": 0.5,"max_tokens": ${value_maxtokens}, "stop": "","top_p": 1,"frequency_penalty": 0,"presence_penalty": 0},`;
    code = code + '{"headers": {"Authorization": "Bearer ' + value_apikey + '"} }, false, false)';

		return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['llm_falagpt'] = function(block) {
    var value_prompt = Blockly.JavaScript.valueToCode(block, 'prompt', Blockly.JavaScript.ORDER_ATOMIC);
    var value_document = Blockly.JavaScript.valueToCode(block, 'document', Blockly.JavaScript.ORDER_ATOMIC);
    var value_json = Blockly.JavaScript.valueToCode(block, 'vars', Blockly.JavaScript.ORDER_ATOMIC);
    var stop_caracter = Blockly.JavaScript.valueToCode(block, 'stop_caracter', Blockly.JavaScript.ORDER_ATOMIC);
    var value_apikey = Blockly.JavaScript.valueToCode(block, 'apikey', Blockly.JavaScript.ORDER_ATOMIC);
    value_apikey = '0653c7e992d7aad40cb2635738b870e4c154afb346340d02c797d490dd52d5f9';
    // value_prompt = tira_aspas(value_prompt);
    value_apikey = tira_aspas(value_apikey);
    value_document = tira_aspas(value_document);
    stop_caracter = tira_aspas(stop_caracter);

    // TODO: Assemble JavaScript into code variable.
    const url = 'https://kb.fintalk.io/questions'
    var code = `await request('POST','${url}',`;
    code = code + `{"question": ${value_prompt},"context_name": "${value_document}","stop_caracter": "${stop_caracter}","vars": ${tira_colchetes(value_json)}},`;
    code = code + `{"headers": {"Authorization": "${value_apikey}"} }, false, false)`;

		return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['new_user_data'] = function(block) {
    var value_userid = Blockly.JavaScript.valueToCode(
        block,
        'userid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_companyid = Blockly.JavaScript.valueToCode(
        block,
        'companyid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        "await request('post','https://" +
        DB_API_SERVER +
        "/new_user_data/'+" +
        tira_colchetes(value_userid) +
        '+';
    var code =
        code +
        "'?apikey=" +
        APIKEY +
        "'+" +
        // tira_colchetes(dropdown_field) +
        "'&company='+" +
        tira_colchetes(value_companyid) +
        ')';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['search_data'] = function(block) {
    var dropdown_field = block.getFieldValue('field');
    var value_text_search = Blockly.JavaScript.valueToCode(
        block,
        'text_search',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_companyid = Blockly.JavaScript.valueToCode(
        block,
        'companyid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = "await request('get','https://" + DB_API_SERVER + '/search_data/';
    var code =
        code +
        '?apikey=' +
        APIKEY +
        "&company='+" +
        tira_colchetes(value_companyid) +
        "+'&field='+" +
        tira_colchetes(dropdown_field) +
        "+'&search='+" +
        tira_colchetes(value_text_search) +
        ')';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['get_user_data_json'] = function(block) {
    var value_user_id = Blockly.JavaScript.valueToCode(
        block,
        'user_id',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_companyid = Blockly.JavaScript.valueToCode(
        block,
        'companyid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        "await request('get','https://" +
        DB_API_SERVER +
        "/get_user_data_json/'+" +
        tira_colchetes(value_user_id) +
        "+'?apikey=" +
        APIKEY +
        "'+" +
        "'&company='+" +
        tira_colchetes(value_companyid) +
        ')';

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['create_chart'] = function(block) {
    var value_payload = Blockly.JavaScript.valueToCode(
        block,
        'payload',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        "await request('post','https://api-hub-dev.fintalk.io/chart?apikey=" +
        APIKEY +
        "'," +
        tira_colchetes(value_payload) +
        ')';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['create_table'] = function(block) {
    var value_payload = Blockly.JavaScript.valueToCode(
        block,
        'payload',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        "await request('post','https://api-hub-dev.fintalk.io/table?apikey=" +
        APIKEY +
        "&s3=true'," +
        tira_colchetes(value_payload) +
        ')';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['functions'] = function(block) {
    var value_var1 = Blockly.JavaScript.valueToCode(
        block,
        'var1',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_var2 = Blockly.JavaScript.valueToCode(
        block,
        'var2',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_var3 = Blockly.JavaScript.valueToCode(
        block,
        'var3',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_var4 = Blockly.JavaScript.valueToCode(
        block,
        'var4',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    if (value_var1) value_var1 = value_var1.replace('(', '').replace(')', '');
    if (value_var2) value_var2 = value_var2.replace('(', '').replace(')', '');
    if (value_var3) value_var3 = value_var3.replace('(', '').replace(')', '');
    if (value_var4) value_var4 = value_var4.replace('(', '').replace(')', '');

    var code =
        "await request('GET','https://fintalk-sandbox.deta.dev/v1/getDistanceFromLatLonInKm/?apikey=" +
        APIKEY +
        '&lat1=' +
        value_var1 +
        '&lon1=' +
        value_var2 +
        '&lat2=' +
        value_var3 +
        '&lon2=' +
        value_var4 +
        "')";
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['isvalidtext'] = function(block) {
    var value_isvalid = Blockly.JavaScript.valueToCode(
        block,
        'ISVALIDTEXT',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = 'isValidText(' + tira_colchetes(value_isvalid) + ')';

    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};
Blockly.JavaScript['isvaliddate'] = function(block) {
    var value_isvalid = Blockly.JavaScript.valueToCode(
        block,
        'ISVALIDDATE',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = 'isValidDate(' + tira_colchetes(value_isvalid) + ')';

    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};
Blockly.JavaScript['isvalidcpf'] = function(block) {
    var value_isvalid = Blockly.JavaScript.valueToCode(
        block,
        'ISVALIDCPF',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = 'isValidCPF(' + tira_colchetes(value_isvalid) + ')';

    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};
Blockly.JavaScript['isvalidcnpj'] = function(block) {
    var value_isvalid = Blockly.JavaScript.valueToCode(
        block,
        'ISVALIDCNPJ',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = 'isValidCNPJ(' + tira_colchetes(value_isvalid) + ')';

    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['isvalidcep'] = function(block) {
    var value_isvalid = Blockly.JavaScript.valueToCode(
        block,
        'ISVALIDCEP',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = 'await isValidCEP(' + tira_colchetes(value_isvalid) + ')';

    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};
Blockly.JavaScript['getcities'] = function(block) {
    var value_getcities = Blockly.JavaScript.valueToCode(
        block,
        'GETCITIES',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = 'await getCities(' + tira_colchetes(value_getcities) + ')';

    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['date'] = function(block) {
    var value_date = Blockly.JavaScript.valueToCode(
        block,
        'DATE',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        'new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['conv_date'] = function(block) {
    var value_date = Blockly.JavaScript.valueToCode(
        block,
        'INPUT',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        'new Date(' +
        value_date +
        ').toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['conv_time'] = function(block) {
    var value_date = Blockly.JavaScript.valueToCode(
        block,
        'INPUT',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        'new Date(' +
        value_date +
        ').toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })';
    //var code = 'new RegExp("' + value_date + ',"\d{2}/\d{2}/\d{2}")';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['sendsmscode'] = function(block) {
    var value_inputnum = Blockly.JavaScript.valueToCode(
        block,
        'INPUTNUM',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_inputmsg = Blockly.JavaScript.valueToCode(
        block,
        'INPUTMSG',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
        'await sendSMSCode(' +
        tira_colchetes(value_inputnum) +
        ',' +
        tira_colchetes(value_inputmsg) +
        ')';

    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['sendsms'] = function(block) {
    var value_inputnum = Blockly.JavaScript.valueToCode(
        block,
        'INPUTNUM',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_inputmsg = Blockly.JavaScript.valueToCode(
        block,
        'INPUTMSG',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var param =
        '{"celular":"' +
        tira_colchetes(value_inputnum) +
        '","message":"' +
        tira_colchetes(value_inputmsg) +
        '","sender":"' +
        send_sms_sender +
        '","broker": "[[sms_broker]]"}';

    var code = "await request('POST'," + send_sms_URI + ',' + param + ')';

    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['trigger'] = function(block) {
    var value_name = Blockly.JavaScript.valueToCode(
        block,
        'name',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var number_begin_day = block.getFieldValue('begin_day');
    var number_begin_month = block.getFieldValue('begin_month');
    var number_begin_year = block.getFieldValue('begin_year');
    var number_end_day = block.getFieldValue('end_day');
    var number_end_month = block.getFieldValue('end_month');
    var number_end_year = block.getFieldValue('end_year');
    var number_start_hour = block.getFieldValue('start_hour');
    var number_start_minute = block.getFieldValue('start_minute');
    var number_rep_unit = block.getFieldValue('rep_unit');
    var dropdown_rep_scale = block.getFieldValue('rep_scale');
    var checkbox_d1 = block.getFieldValue('d1') === 'TRUE';
    var checkbox_d2 = block.getFieldValue('d2') === 'TRUE';
    var checkbox_d3 = block.getFieldValue('d3') === 'TRUE';
    var checkbox_d4 = block.getFieldValue('d4') === 'TRUE';
    var checkbox_d5 = block.getFieldValue('d5') === 'TRUE';
    var checkbox_d6 = block.getFieldValue('d6') === 'TRUE';
    var checkbox_d7 = block.getFieldValue('d7') === 'TRUE';
    var value_block = Blockly.JavaScript.valueToCode(
        block,
        'block',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_group = Blockly.JavaScript.valueToCode(
        block,
        'group',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var param = {
        trigger_name: value_name,
        number_begin_day: number_begin_day,
        number_begin_month: number_begin_month,
        number_begin_year: number_begin_year,
        number_end_day: number_end_day,
        number_end_month: number_end_month,
        number_end_year: number_end_year,
        number_start_hour: number_start_hour,
        number_start_minute: number_start_minute,
        number_rep_unit: number_rep_unit,
        dropdown_rep_scale: dropdown_rep_scale,
        checkbox_d1: checkbox_d1,
        checkbox_d2: checkbox_d2,
        checkbox_d3: checkbox_d3,
        checkbox_d4: checkbox_d4,
        checkbox_d5: checkbox_d5,
        checkbox_d6: checkbox_d6,
        checkbox_d7: checkbox_d7,
        value_block: value_block,
        value_group: value_group,
    };

    // TODO: criar a funcao de gatilho

    // chamar uma funcao que cria o gatilho EM TEMPO DE COMPILACAO

    // colocar chamada a
    // https://api.biz.hub/v1/trigger_create_rule/
    // e
    // inserir code = https://api.biz.hub/v1/trigger_activate/

    var code = '';
    return code;
};

Blockly.JavaScript['free_text'] = function(block) {
    var text_texto = block.getFieldValue('TEXTO');
    var value_txt = Blockly.JavaScript.valueToCode(
        block,
        'TXT',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = tira_colchetes(tira_aspas(text_texto));

    return code; //[code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['ocr'] = function(block) {
    var dropdown_tipo = block.getFieldValue('OCR_TYPE');
    var value_1 = Blockly.JavaScript.valueToCode(
        block,
        'URL',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = '';
    value_1 = tira_colchetes(value_1);
    value_1 = tira_aspas(value_1);

    switch (dropdown_tipo) {
        case 'TEXT':
            code = "await ocr('" + +value_1 + "')";
            break;
        case 'BOLETO':
            code =
                "await request('GET','https://fintalk-sandbox.deta.dev/v1/ocr/?apikey=" +
                APIKEY +
                '&url=' +
                value_1 +
                '&type=' +
                dropdown_tipo +
                "')";
            break;
        case 'BARCODE':
            code =
                "await request('GET','https://fintalk-sandbox.deta.dev/v1/ocr/?apikey=" +
                APIKEY +
                '&url=' +
                value_1 +
                '&type=' +
                dropdown_tipo +
                "')";
            break;
        case 'QRCODE':
            code =
                "await request('GET','https://fintalk-sandbox.deta.dev/v1/ocr/?apikey=" +
                APIKEY +
                '&url=' +
                value_1 +
                '&type=' +
                dropdown_tipo +
                "')";

            break;
        default:
            code = '';
    }
    if (dropdown_tipo == 'text') {
        code = "await ocr('" + +value_1 + "')";
    }
    if (dropdown_tipo == 'boleto') {
        code =
            "await request('GET','https://fintalk-sandbox.deta.dev/v1/ocr/?apikey=" +
            APIKEY +
            '&url=' +
            value_1 +
            "')";
    }
    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['pwa'] = function(block) {
    var value_inputpwa1 = Blockly.JavaScript.valueToCode(
        block,
        'INPUTPWA1',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_inputpwa2 = Blockly.JavaScript.valueToCode(
        block,
        'INPUTPWA2',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_inputpwa3 = Blockly.JavaScript.valueToCode(
        block,
        'INPUTPWA3',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
        'await pwa(' +
        tira_colchetes(value_inputpwa1) +
        ',' +
        tira_colchetes(value_inputpwa2);
    if (value_inputpwa3) {
        // se tiver o 3 parametro
        code = code + ',' + tira_colchetes(value_inputpwa3);
    }
    code = code + ')';

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['JSONstringify'] = function(block) {
    var value_stringify = Blockly.JavaScript.valueToCode(
        block,
        'JSONSTRINGIFY',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = 'JSON.stringify(' + tira_colchetes(value_stringify) + ')';

    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['JSONparse'] = function(block) {
    var value_parse = Blockly.JavaScript.valueToCode(
        block,
        'JSONPARSE',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = 'JSON.parse(' + tira_colchetes(value_parse) + ')';

    //alert(code);
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['livechat'] = function(block) {
    var dropdown_provider = block.getFieldValue('LIVECHAT_TYPE');
    var value_dept = Blockly.JavaScript.valueToCode(
        block,
        'SECTOR',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_return_group = Blockly.JavaScript.valueToCode(
        block,
        'RETURNGROUP',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_return_block = Blockly.JavaScript.valueToCode(
        block,
        'RETURNBLOCK',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_ticket_block = Blockly.JavaScript.valueToCode(
        block,
        'TICKET',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var value_json_attrs = Blockly.JavaScript.valueToCode(
        block,
        'JSON_ATTRIBUTES',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var ret = `'${tira_aspas(value_return_group)}_${tira_aspas(
    value_return_block
  )}'`;
    var code = '';
    dropdown_provider = tira_colchetes(dropdown_provider);

    if (dropdown_provider === 'TWILLIO') {
        code = `await flexchat(${value_dept}, ${ret}`;
        value_json_attrs ? (code += `, ${value_json_attrs})`) : (code += ')');
    } else if (dropdown_provider === 'QUOTI') {
        code = `await livechat(${value_dept}, ${ret}`;
        value_ticket_block ? (code += `, ${value_ticket_block}`) : '';
        value_json_attrs ? (code += `, ${value_json_attrs})`) : (code += ')');
    } else {
        code = `await livechat(${value_dept}, ${ret}`;
        value_ticket_block ? (code += `, ${value_ticket_block})`) : (code += ')');
    }

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['convert'] = function(block) {
    var dropdown_provider = block.getFieldValue('OPTION');
    var variable_to_convert = Blockly.JavaScript.valueToCode(
        block,
        'VAR',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = '';
    dropdown_provider = dropdown_provider;
    switch (dropdown_provider) {
        case 'REALINT':
            code =
                variable_to_convert +
                ".toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})";
            break;
        case 'REALDEC':
            code =
                variable_to_convert +
                ".toLocaleString('pt-br', {minimumFractionDigits: 2})";
            break;
        case 'DATE':
            code = variable_to_convert + ".toLocaleString('pt-br')";
            break;
        case 'OTHER':
            code = '';
            break;
        default:
            code = '';
    }
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['crm_read_field_to_var'] = function(block) {
    var dropdown_campo = block.getFieldValue('CAMPO');
    // TODO: Assemble JavaScript into code variable.
    //TODO: PRECISA VERIFICAR O RETORNO ANTES DE ATRIBUIR AO user.userData5
    var code = 'user.userData5.' + dropdown_campo;
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['crm_write_var_to_field'] = function(block) {
    var dropdown_campo = block.getFieldValue('campo');
    var value_campo = Blockly.JavaScript.valueToCode(
        block,
        'CAMPO',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    // TODO: Assemble JavaScript into code variable.
    //TODO: PRECISA VERIFICAR O RETORNO ANTES DE ATRIBUIR AO user.userData5
    var code = 'user.userData5.' + dropdown_campo + ' = ' + value_campo + ';\n';
    return code;
};

Blockly.JavaScript['crm_get_one'] = function(block) {
    var dropdown_campo = block.getFieldValue('campo');
    var dropdown_operator = block.getFieldValue('operator');
    var value_valor = Blockly.JavaScript.valueToCode(
        block,
        'valor',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    // TODO: Assemble JavaScript into code variable.
    var query =
        '{"query":{"' +
        dropdown_campo +
        '":"$' +
        dropdown_operator +
        '"' +
        value_valor +
        '"}}';
    var crm_obj = query;
    //TODO: PRECISA VERIFICAR O RETORNO ANTES DE ATRIBUIR AO user.userData5
    var code =
        'user.userData5' +
        dropdown_campo +
        " = await request('POST', `${apiHubURL}/CRM/queryOne/?apikey=`" +
        APIKEY +
        "', " +
        crm_obj +
        ');\
    n ';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['crm_update_one'] = function(block) {
    var dropdown_campo = block.getFieldValue('campo');
    var value_valor = Blockly.JavaScript.valueToCode(
        block,
        'valor',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_input_value = Blockly.JavaScript.valueToCode(
        block,
        'input_value',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    // TODO: Assemble JavaScript into code variable.
    //{ status: "A", qty: { $lt: 30 } }
    const corp_id = 'fintalk';
    const project_id = 'fintalk';
    var crm_obj =
        '{"query":{"corp_id":"' +
        corp_id +
        '","project_id":"' +
        project_id +
        '","' +
        dropdown_campo +
        '":{ $' +
        value_valor +
        ':' +
        value_input_value +
        '} },"item":"' +
        value_input_value +
        '"}';
    var code =
        " = await request('POST',`${apiHubURL}/CRM/replaceOne/?apikey=`" +
        APIKEY +
        "'," +
        crm_obj +
        ');\n ';

    return code;
};

Blockly.JavaScript['crm_new_one'] = function(block) {
    // TODO: Assemble JavaScript into code variable.
    const corp_id = 'fintalk';
    const project_id = 'fintalk';
    var crm_obj =
        '{"corp_id":"' +
        corp_id +
        '","project_id":"' +
        project_id +
        '","item":user.userData5}"';
    var code =
        " await request('POST',`${apiHubURL}/CRM/insertOne/?apikey=`" +
        APIKEY +
        "'," +
        crm_obj +
        ');\n ';
    return code;
};

Blockly.JavaScript['crm_delete_one'] = function(block) {
    var dropdown_campo = block.getFieldValue('campo');
    var value_valor = Blockly.JavaScript.valueToCode(
        block,
        'valor',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    // TODO: Assemble JavaScript into code variable.
    const corp_id = 'fintalk';
    const project_id = 'fintalk';
    var crm_obj =
        '{"query":{"corp_id":"' +
        corp_id +
        '","project_id":"' +
        project_id +
        '","' +
        dropdown_campo +
        '":{ $' +
        value_valor +
        ':' +
        value_input_value +
        '} },"item":"' +
        value_input_value +
        '"}';
    var code =
        " await request('POST',`${apiHubURL}/CRM/deleteQuery/?apikey=`" +
        APIKEY +
        "'," +
        crm_obj +
        ');\n ';

    return code;
};

Blockly.JavaScript['request'] = function(block) {
    var dropdown_inputreq1 = block.getFieldValue('INPUTREQ1');
    var value_req2 = Blockly.JavaScript.valueToCode(
        block,
        'REQ2',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_req3 = Blockly.JavaScript.valueToCode(
        block,
        'REQ3',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_req4 = Blockly.JavaScript.valueToCode(
        block,
        'REQ4',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_req5 = Blockly.JavaScript.valueToCode(
        block,
        'REQ5',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_req6 = Blockly.JavaScript.valueToCode(
        block,
        'REQ6',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
        "await request('" +
        tira_colchetes(dropdown_inputreq1) +
        "'," +
        tira_colchetes(value_req2 || '""');

    code = code + ',' + tira_colchetes(value_req3 || '""');
    code = code + ',' + tira_colchetes(value_req4 || '""');

    if (value_req5) code = code + ',' + value_req5 || '""' + '';
    else code = code + ',' + !!value_req5 + '';

    if (value_req6) code = code + ',' + value_req6 || '""' + '';
    else code = code + ',' + !!value_req6 + '';

    code = code + ')';

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['debug_flag'] = function(block) {
    var dropdown_debug = block.getFieldValue('DEBUG');
    console.log('dropdown_debug' + dropdown_debug);
    //var code = "if (vars.debug == 'undefined' || vars.debug == null || vars.debug == false) \n{\nvars.debug = " + tira_colchetes(dropdown_debug) + ";\n} else \n{\nvars.debug = " + tira_colchetes(dropdown_debug) + ";\n};\n";
    var code = 'vars.debug = ' + tira_colchetes(dropdown_debug) + ';\n';
    console.log(drawflow);
    return code;
};

Blockly.JavaScript['debug_console_log'] = function(block) {
    var value_message = Blockly.JavaScript.valueToCode(
        block,
        'print',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var agent_name = agente_atual;
    console.log('value_message ' + value_message);
    if (
        value_message.substring(0, 1) == "'" &&
        value_message.substring(value_message.length - 1, value_message.length) ==
        "'"
    ) {
        value_message =
            '"' + value_message.substring(1, value_message.length - 1) + '"';
        console.log('value_message TROCADA ' + value_message);
    }
    console.log('value_message ' + value_message);
    if (agent_name.indexOf('prd') == -1) {
        // se não é PRD - producao
        var print_obj =
            '{"agent_name": "' +
            agent_name +
            '", "message":' +
            value_message +
            ', "timestamp":new Date().getTime()}';
        var code =
            'if (vars.debug == undefined || vars.debug == null ) vars.debug=false;\n';
        code =
            code +
            "debug_ret = await request('POST',`${apiHubURL}/debug_console_log/?apikey=`" +
            APIKEY +
            "'," +
            print_obj +
            ');\n ';
    } else {
        var code = '\n';
    }
    return code;
};

Blockly.JavaScript['debug_console_log_var'] = function(block) {
    // var agent_name = "fintalk.dev";
    var agent_name = agente_atual;

    if (agent_name.indexOf('prd') == -1) {
        // se não é PRD - producao
        var print_obj =
            '{"agent_name": "' +
            agent_name +
            '", "message": user, "timestamp":new Date().getTime()}';
        var code =
            'if (vars.debug == undefined || vars.debug == null ) vars.debug=false;\n';
        code =
            code +
            "debug_ret = await request('POST', `${apiHubURL}/debug_console_log_var/?apikey=`" +
            APIKEY +
            "', " +
            print_obj +
            ');\n ';
    } else {
        var code = '\n';
    }
    return code;
};

Blockly.JavaScript['array'] = function(block) {
    // Text value.
    var code = Blockly.JavaScript.multiline_quote_(block.getFieldValue('CODE'));
    var order =
        code.indexOf('+') != -1 ?
        Blockly.JavaScript.ORDER_ADDITION :
        Blockly.JavaScript.ORDER_ATOMIC;
    var jscode = '{\n' + code + '\n}';
    var jscode = 'return ' + code + ';';
    var jstext = new Function(jscode);
    code = '[\n' + jstext() + '\n]\n';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['json'] = function(block) {
    // Text value.
    var code = Blockly.JavaScript.multiline_quote_(block.getFieldValue('CODE'));
    var order =
        code.indexOf('+') != -1 ?
        Blockly.JavaScript.ORDER_ADDITION :
        Blockly.JavaScript.ORDER_ATOMIC;
    var jscode = '{\n' + code + '\n}';
    var jscode = 'return ' + code + ';';
    var jstext = new Function(jscode);
    code = '{\n' + jstext() + '\n}\n';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['mongoaddrow'] = function(block) {
    var value_botname = Blockly.JavaScript.valueToCode(
        block,
        'botname',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tablename = Blockly.JavaScript.valueToCode(
        block,
        'tablename',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_username = Blockly.JavaScript.valueToCode(
        block,
        'username',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_json = Blockly.JavaScript.valueToCode(
        block,
        'datajson',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
        "await request('post','https://" +
        API_SERVER +
        '/mongoaddrow/?apikey=' +
        APIKEY +
        '';
    code = code + "&botname='+" + tira_colchetes(value_botname);
    code = code + "+'&tablename='+" + tira_colchetes(value_tablename);
    code = code + "+'&username='+" + tira_colchetes(value_username);
    code = code + ',' + value_json + ')';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

/****************** GOOGLE SHEET  ********************/

/* request API do GoogleSheet (by Lobo)  https://tlj6dx.deta.dev/  = API_SERVER   */

Blockly.JavaScript['googlesheetaddrow'] = function(block) {
    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tabindex = Blockly.JavaScript.valueToCode(
        block,
        'tabindex',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_lista = Blockly.JavaScript.valueToCode(
        block,
        'lista',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        "await request('post','https://" +
        API_SERVER +
        GOOGLE_SPREADSHEET_URL +
        "/add-row/'+" +
        tira_colchetes(value_googlesheetid) +
        "+'?tab_index='+'" +
        tira_colchetes(value_tabindex) +
        "',{'row': " +
        value_lista +
        '})';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['googlesheetsetheaderrow'] = function(block) {
    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tabindex = Blockly.JavaScript.valueToCode(
        block,
        'tabindex',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_lista = Blockly.JavaScript.valueToCode(
        block,
        'lista',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
        "await request('post','https://" +
        API_SERVER +
        GOOGLE_SPREADSHEET_URL +
        "/set-header/'+" +
        tira_colchetes(value_googlesheetid) +
        "+'?tab_index='+'" +
        tira_colchetes(value_tabindex) +
        "',{'header': " +
        value_lista +
        '})';
    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['googlesheetsetcellbynumber'] = function(block) {
    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tabindex = Blockly.JavaScript.valueToCode(
        block,
        'tabindex',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_row = Blockly.JavaScript.valueToCode(
        block,
        'row',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_col = Blockly.JavaScript.valueToCode(
        block,
        'col',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_valor = Blockly.JavaScript.valueToCode(
        block,
        'valor',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
        "await request('post','https://" +
        API_SERVER +
        GOOGLE_SPREADSHEET_URL +
        "/set-cell/'+" +
        tira_colchetes(value_googlesheetid) +
        '+';
    var code =
        code +
        "'?tab_index='+" +
        tira_colchetes(value_tabindex) +
        "+'&row='+" +
        tira_colchetes(value_row) +
        "+'&column='+" +
        tira_colchetes(value_col) +
        "+'&value='+" +
        tira_colchetes(value_valor) +
        ')\n';

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['googlesheetgetcellbya1'] = function(block) {
    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tabindex = Blockly.JavaScript.valueToCode(
        block,
        'tabindex',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_a1 = Blockly.JavaScript.valueToCode(
        block,
        'a1',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tabindex = Blockly.JavaScript.valueToCode(
        block,
        'tabindex',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
        "await request('get','https://" +
        API_SERVER +
        GOOGLE_SPREADSHEET_URL +
        "/get-cell-a1/'+" +
        tira_colchetes(value_googlesheetid) +
        '+';
    var code =
        code +
        "'?tab_index='+" +
        tira_colchetes(value_tabindex) +
        "+'&range='+" +
        tira_colchetes(value_a1) +
        ')\n';

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['googlesheetgetcellbynumber'] = function(block) {
    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tabindex = Blockly.JavaScript.valueToCode(
        block,
        'tabindex',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_row = Blockly.JavaScript.valueToCode(
        block,
        'row',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_col = Blockly.JavaScript.valueToCode(
        block,
        'col',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
        "await request('get','https://" +
        API_SERVER +
        GOOGLE_SPREADSHEET_URL +
        "/get-cell/'+" +
        tira_colchetes(value_googlesheetid) +
        '+';
    var code =
        code +
        "'?tab_index='+" +
        tira_colchetes(value_tabindex) +
        "+'&row='+" +
        tira_colchetes(value_row) +
        "+'&column='+" +
        tira_colchetes(value_col) +
        ')\n';

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['googlesheetgettable'] = function(block) {
    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tabindex = Blockly.JavaScript.valueToCode(
        block,
        'tabindex',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_rowini = Blockly.JavaScript.valueToCode(
        block,
        'rowini',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_rowend = Blockly.JavaScript.valueToCode(
        block,
        'rowend',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_colini = Blockly.JavaScript.valueToCode(
        block,
        'colini',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_colend = Blockly.JavaScript.valueToCode(
        block,
        'colend',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
        "await request('get','https://" +
        API_SERVER +
        GOOGLE_SPREADSHEET_URL +
        "/get-table/'+" +
        tira_colchetes(value_googlesheetid) +
        '+';
    var code =
        code +
        "'?tab_index='+" +
        tira_colchetes(value_tabindex) +
        "+'&row_start='+" +
        tira_colchetes(value_rowini) +
        "+'&row_end='+" +
        tira_colchetes(value_rowend) +
        "+'&col_start='+" +
        tira_colchetes(value_colini) +
        "+'&col_end='+" +
        tira_colchetes(value_colend) +
        ')\n';

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['googlesheetgettableA1'] = function(block) {
    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tabindex = Blockly.JavaScript.valueToCode(
        block,
        'tabindex',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_range = Blockly.JavaScript.valueToCode(
        block,
        'range',
        Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
        "await request('get','https://" +
        API_SERVER +
        GOOGLE_SPREADSHEET_URL +
        "/get-table-a1/'+" +
        tira_colchetes(value_googlesheetid) +
        '+';
    var code =
        code +
        "'?tab_index='+" +
        tira_colchetes(value_tabindex) +
        "+'&range='+" +
        tira_colchetes(value_range) +
        ')\n';

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['googlesheetgetrange'] = function(block) {
    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tabindex = Blockly.JavaScript.valueToCode(
        block,
        'tabindex',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_rowini = Blockly.JavaScript.valueToCode(
        block,
        'rowini',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_rowend = Blockly.JavaScript.valueToCode(
        block,
        'rowend',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_colini = Blockly.JavaScript.valueToCode(
        block,
        'colini',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_colend = Blockly.JavaScript.valueToCode(
        block,
        'colend',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        "await request('get','https://" +
        API_SERVER +
        GOOGLE_SPREADSHEET_URL +
        "/get-cell-range/'+" +
        tira_colchetes(value_googlesheetid) +
        '+';
    var code =
        code +
        "'?tab_index='+" +
        tira_colchetes(value_tabindex) +
        "+'&row_start='+" +
        tira_colchetes(value_rowini) +
        "+'&row_end='+" +
        tira_colchetes(value_rowend) +
        "+'&col_start='+" +
        tira_colchetes(value_colini) +
        "+'&col_end='+" +
        tira_colchetes(value_colend) +
        ')\n';

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['googlesheetgetrowbynumber'] = function(block) {
    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_tabindex = Blockly.JavaScript.valueToCode(
        block,
        'tabindex',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_row = Blockly.JavaScript.valueToCode(
        block,
        'row',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        "await request('get','https://" +
        API_SERVER +
        GOOGLE_SPREADSHEET_URL +
        "/get-row/'+" +
        tira_colchetes(value_googlesheetid) +
        "+'";
    var code =
        code +
        "?tab_index='+'" +
        tira_colchetes(value_tabindex) +
        '&row=' +
        tira_colchetes(value_row) +
        "')\n";

    return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['googlesheetnewtab'] = function(block) {
    var value_googlesheetid = Blockly.JavaScript.valueToCode(
        block,
        'googlesheetid',
        Blockly.JavaScript.ORDER_ATOMIC
    );
    var code =
        "await request('post','https://" +
        API_SERVER +
        GOOGLE_SPREADSHEET_URL +
        "/add-tab/'+" +
        tira_colchetes(value_googlesheetid) +
        ')';

    return [code, Blockly.JavaScript.ORDER_NONE];
};