import { useFintalkCloud } from "contexts/FintalkCloud/fintalkCloudContext";
import { useEffect, useState } from "react";

export function BubbleModule() {

  const { currentData } = useFintalkCloud();
  const [iframeUrl, setIframeUrl] = useState("");

  async function loadParams() {
    try {
      setIframeUrl(`https://bubble-front.run.dev.fintalk.io/${currentData.agentName}/bubbles`);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData.agentName]);

  return (
    <iframe
      id="bubble-iframe"
      title="Bubble"
      src={iframeUrl}
      width="100%"
      height="100%"
    ></iframe>
  );
}