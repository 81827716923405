import {
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { BubbleSchema, Property } from 'contexts/Flow/types';
import { useForm } from 'contexts/Form/formContext';
import { useToast } from 'contexts/ToastContext';
import { useCallback, useEffect, useState } from 'react';
import { GetBubbles } from 'services/BubblesService';

export function BubbleEntries({ entries }: Readonly<{ entries: Property[] }>) {
  const { state: stateFlow } = useFlow();
  const { state, updateForm } = useForm();
  const { dispatch: dispatchApp } = useApp();
  const { toastNotification: toast } = useToast();
  const { dataForm } = state;

  const [bubbles, setBubbles] = useState<BubbleSchema[]>(entries[0].bubbles || []);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getBubbles = useCallback(async (agent: string) => {
    try {
      setIsLoading(true);

      const bubblesResponse = await GetBubbles(agent, dispatchApp);

      if (bubblesResponse.Success) {
        setBubbles(bubblesResponse.Data.data);
      }
    } catch (error) {
      console.log(error);

      toast(
        { 
          type: 'warning', 
          message: 'Não foi possível buscar a lista de Bubbles. Tente novamente mais tarde.' 
        }
      );
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchApp]);

  useEffect(() => {
    getBubbles(stateFlow.botName ?? '');
  }, [getBubbles, stateFlow.botName]);

  const handleChangeBubbleInfo = (
    customFieldName: 'bubble' | 'bubbleVersion',
    newValue: string
  ) => {
    if (dataForm?.bubbleInfo) {
      const updatedBubbleInfo = { ...dataForm.bubbleInfo };

      if (customFieldName === 'bubble') {
        const selectedBubble = bubbles.find((bubble) => bubble.name === newValue);

        if (!selectedBubble?.available_versions) return;

        updatedBubbleInfo.bubbleId = selectedBubble?.id ?? '';
        updatedBubbleInfo.bubbleVersion = selectedBubble?.available_versions;
      }

      updateForm({ ...dataForm, bubbleInfo: updatedBubbleInfo });
    }
  };

  const renderSelectEntry = (entry: Property) => {
    const selectedValue = entry.customFieldName === 'bubble' ? bubbles.filter((bubble) => bubble.id === dataForm?.bubbleInfo?.bubbleId)[0]?.name || '' : '';

    return (
      <FormControl fullWidth error={!!state.formErrors?.bubble}>
        <Select
          labelId={`bubble-select-${entry.customFieldName}`}
          id={`bubble-select-${entry.customFieldName}`}
          value={selectedValue}
          onChange={(e) =>
            handleChangeBubbleInfo(entry.customFieldName as 'bubble' | 'bubbleVersion', e.target.value as string)
          }
        >
          {entry.customFieldName === 'bubble' &&
            bubbles.map((bubble) => (
              <MenuItem key={bubble.id} value={bubble.name} disabled={!bubble.enabled}>
                {bubble.name}
              </MenuItem>
            ))}
        </Select>

        {!!state.formErrors?.bubble && <FormHelperText>{state.formErrors.bubble}</FormHelperText>}
      </FormControl>
    );
  };

  const renderEntries = () => (
    <div style={{ marginBottom: 16 }}>
      <Typography variant="subtitle2">{entries[0].label}</Typography>
      {renderSelectEntry(entries[0])}
    </div>
  );

  return (
    <>
      {isLoading ? (
        <CircularProgress size={28} />
      ) : (
        renderEntries()
      )}
    </>
  );
}
