import styled from 'styled-components';
import MttTypography from 'components/Material/MttTypography/MttTypography';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import TextField from '@material-ui/core/TextField/TextField';

export const GridContainer = styled(MttGrid)`
  margin: 16px;
`;

export const StyledUserPhrases = styled(MttGrid)`
  height: 40px;
  margin-bottom: 8px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const TextContainer = styled(MttGrid)`
  width: 100%;
  height: 56px;
	padding: 4px;
`;

export const StyledContainerPhrases = styled.div`
  min-height: 126px;
  max-height: 175px;
  overflow-x: hidden;
  overflow-y: auto;

  padding: 8px;
  border-radius: 4px;

  background-color: #f2f2f2;

  .MuiInputBase-input {
    background-color: #ffffff;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;

export const SentenceInput = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 4px;

  .FormatQuoteIcon {
    color: #3f51b5;
  }
`;

export const StyledMttTypographyPhrase = styled(MttTypography).attrs({
  children: 'Frases de entrada',
  variant: 'h6',
  color: 'primary',
})``;

export const StyledMttTypographyMsgForUser = styled(MttTypography).attrs({
  children: 'Mensagem para usuário',
  variant: 'h6',
  color: 'primary',
})`
  margin-bottom: 16px !important;
`;

export const ContainerButton = styled(MttGrid)`
  display: flex;
  justify-content: space-between;

  padding: 8px 0;
`;

export const ContentGrid = styled(MttGrid)`
  width: calc(100% - 32px);
  max-height: 192px;

  padding: 16px;

  border-radius: 4px;

  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: auto;

  .MuiTypography-body1 {
    color: #0e103b;
    font-weight: 500;
  }
`;

export const ContentTitlesGrid = styled(MttGrid)`
  width: 100%;
  display: flex;

  background-color: #f2f2f2;

  .MuiInputBase-input {
    background-color: #ffffff;
  }
`;
export const ContentMsgForUserGrid = styled.div`
  padding: 16px;

  background-color: #f2f2f2;

  .MuiInputBase-input {
    background-color: #ffffff;
  }
`;

export const StyledMttTextField = styled(TextField)`
  background: #fff;
`;

export const StyledDeleteIconGrid = styled(MttGrid)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 16px;
  cursor: pointer;

  .MuiFormControlLabel-root {
    margin-right: 4px;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerOptionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px;
`;

export const ContainerOptionContent = styled.div`
  display: flex;
  align-items: center;
	padding: 4px;
`;

export const OptionIndexAndTip = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const HeaderSpan = styled.span`
  margin-left: 64px;
`;

export const Container = styled(MttGrid)`
  width: calc(100% - 32px);
  min-height: 48px;

  padding: 8px;
  border-radius: 4px;

  border: 1px solid #0000001f;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;

  margin-top: 8px;
`;

export const GridInputDefinition = styled(MttGrid)`
  width: 100%;
  max-width: 300px;
  margin-left: 8px;
  padding-bottom: 8px;
  padding-right: 16px;

  input {
    color: #000;
    padding-left: 4px;
  }
`;

export const GridDefinition = styled(MttGrid)`
  padding-left: 12px;
  padding-right: 8px;
  padding-bottom: 24px;
  height: 46px;
`;

export const ContentSynonyms = styled(MttGrid)`
  min-width: 40%;
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
`;

export const GridSynonym = styled(MttGrid)`
  height: 32px;

  margin-left: 8px;

  padding: 0 8px;
  margin: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  background-color: #f2f2f2;

  font-weight: 500;
`;

export const GridCloseIcon = styled(MttGrid)`
  margin-left: 8px;

  color: #aeaeae;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const styles = {
  Container: styled.div`
    border: 2px solid #cfc5e2;
    border-radius: 3rem;
    display: flex;
    margin: 1rem;
  `,
  ChoicesContent: styled.div`
    background: #b6bad9;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    height: 20vh;
    overflow-y: scroll;
  `,
  ChoicesLine: styled.div`
    span {
      font-size: 2em;
    }
  `,
  ButtonsContent: styled.div`
    span {
      font-weight: 600;

      i {
        font-style: normal;
        font-size: 0.8em;
      }
    }
  `,
};

export default styles;
