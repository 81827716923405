import { slugify } from '../utils/String';

export interface IEntity {
  name: string;
  entitytype: 'words' | 'expressions';
  values: IValueEntity[];
}

export interface IValueEntity {
  value: string;
  synonyms: string[];
}

export const EntityFormMask = {
  name: (value: string) => {
    return slugify(value.replace(/\s/, ''), true).substr(0, 20);
  },
};
