import { useState } from 'react';
import { IconButton, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  ContentSynonyms,
  GridSynonymRegular,
  SimilarityContainer,
  TagHeaderContainer,
} from './styles';
import { useForm } from 'contexts/Form/formContext';
import useTranslator from 'utils/hooks/Translator';

export function SimilarityComponent({
  inColumns = false,
}: {
  inColumns?: boolean;
}) {
  const { state, dispatch } = useForm();
  const { getTranslation } = useTranslator();
  const { dataForm } = state;

  const [inputValue, setInputValue] = useState<string>('');

  const handleDeleteSynonym = (phraseIndex: number) => {
    if (!!dataForm && dataForm.outputs) {
      const values = dataForm?.tags || [];
      values.splice(phraseIndex, 1);
      dataForm.tags = values;
      dispatch({ type: 'updateForm', data: { dataForm } });
    }
  };

  const returnRegulars = (item: string, index: number) => {
    if (item === '') return <></>;
    return (
      <GridSynonymRegular key={index}>
        {item}
        <IconButton onClick={() => handleDeleteSynonym(index)} size="small">
          <Close />
        </IconButton>
      </GridSynonymRegular>
    );
  };

  const handleInsertValue = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!dataForm) return;

    const values = dataForm?.tags || [];

    if (event.keyCode === 13 || event.keyCode === 9) {
      if (inputValue !== '') {
        values.push(inputValue);
      }
      setInputValue('');
      const input = event.currentTarget.children[0]
        .children[0] as HTMLDivElement;
      input.blur();
      input.focus();
      dataForm.tags = values;
      dispatch({ type: 'updateForm', data: { dataForm } });
    }
  };

  return (
    <SimilarityContainer inColumns={inColumns}>
      <TagHeaderContainer>
        <div>
          {getTranslation('addTagByPressing')} <strong>TAB</strong> {getTranslation('or')}{' '}
          <strong>ENTER</strong>.
        </div>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          variant="outlined"
          placeholder="Digite uma tag para o bloco"
          fullWidth
          onKeyDown={(e) => handleInsertValue(e)}
        />
      </TagHeaderContainer>
      <ContentSynonyms>
        {dataForm?.tags?.map((item, userSentenceIndex) =>
          returnRegulars(item, userSentenceIndex)
        )}
      </ContentSynonyms>
    </SimilarityContainer>
  );
}
