import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const VariablesIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill={color || 'white'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5.5C10.2761 5.5 10.5 5.27614 10.5 5C10.5 4.72386 10.2761 4.5 10 4.5V5.5ZM10 19.5C10.2761 19.5 10.5 19.2761 10.5 19C10.5 18.7239 10.2761 18.5 10 18.5V19.5ZM14 18.5C13.7239 18.5 13.5 18.7239 13.5 19C13.5 19.2761 13.7239 19.5 14 19.5V18.5ZM14 4.5C13.7239 4.5 13.5 4.72386 13.5 5C13.5 5.27614 13.7239 5.5 14 5.5V4.5ZM10 4.5H9V5.5H10V4.5ZM9 4.5C6.51472 4.5 4.5 6.51472 4.5 9H5.5C5.5 7.067 7.067 5.5 9 5.5V4.5ZM4.5 9V15H5.5V9H4.5ZM4.5 15C4.5 17.4853 6.51472 19.5 9 19.5V18.5C7.067 18.5 5.5 16.933 5.5 15H4.5ZM9 19.5H10V18.5H9V19.5ZM14 19.5H15V18.5H14V19.5ZM15 19.5C17.4853 19.5 19.5 17.4853 19.5 15H18.5C18.5 16.933 16.933 18.5 15 18.5V19.5ZM19.5 15V9H18.5V15H19.5ZM19.5 9C19.5 6.51472 17.4853 4.5 15 4.5V5.5C16.933 5.5 18.5 7.067 18.5 9H19.5ZM15 4.5H14V5.5H15V4.5Z"
        fill={color || 'white'}
      />
      <path
        d="M8.64645 14.6464C8.45118 14.8417 8.45118 15.1583 8.64645 15.3536C8.84171 15.5488 9.15829 15.5488 9.35355 15.3536L8.64645 14.6464ZM12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464C12.1583 11.4512 11.8417 11.4512 11.6464 11.6464L12.3536 12.3536ZM11.6464 11.6464C11.4512 11.8417 11.4512 12.1583 11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536L11.6464 11.6464ZM15.3536 9.35355C15.5488 9.15829 15.5488 8.84171 15.3536 8.64645C15.1583 8.45118 14.8417 8.45118 14.6464 8.64645L15.3536 9.35355ZM12.3536 11.6464C12.1583 11.4512 11.8417 11.4512 11.6464 11.6464C11.4512 11.8417 11.4512 12.1583 11.6464 12.3536L12.3536 11.6464ZM14.6464 15.3536C14.8417 15.5488 15.1583 15.5488 15.3536 15.3536C15.5488 15.1583 15.5488 14.8417 15.3536 14.6464L14.6464 15.3536ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM9.35355 8.64645C9.15829 8.45118 8.84171 8.45118 8.64645 8.64645C8.45118 8.84171 8.45118 9.15829 8.64645 9.35355L9.35355 8.64645ZM9.35355 15.3536L12.3536 12.3536L11.6464 11.6464L8.64645 14.6464L9.35355 15.3536ZM12.3536 12.3536L15.3536 9.35355L14.6464 8.64645L11.6464 11.6464L12.3536 12.3536ZM11.6464 12.3536L14.6464 15.3536L15.3536 14.6464L12.3536 11.6464L11.6464 12.3536ZM12.3536 11.6464L9.35355 8.64645L8.64645 9.35355L11.6464 12.3536L12.3536 11.6464Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default VariablesIcon;
