import { Key } from 'readline';
import { IAppContextActions } from '../contexts/App/types';
import { GetKeys } from '../services/AccessKeysService';
import { GetFlows } from '../services/BotService';
import { IFlowResult } from '../services/BotService/types';
import { GetEntities } from '../services/EntityService';

export interface IExportGroup {
  groupName: string;
  blocks: any;
}

export default async function createBotObject(botName: string, dispatch: React.Dispatch<IAppContextActions>) {
  if (!botName) return;
  const botFlowsResponse = await GetFlows({ bot_name: botName }, dispatch);
  const entitiesResponse = await GetEntities({ bot_name: botName }, dispatch);
  const accessKeysResponse = await GetKeys(botName, dispatch);
  if (
    botFlowsResponse.Success &&
    entitiesResponse.Success &&
    accessKeysResponse.Success
  ) {
    let groups: IFlowResult[] = [];
    let accessKeys: Key[] = [];
    groups = botFlowsResponse.Data.data.groups;

    const organizedGroups: IExportGroup[] = [];

    groups.forEach((group) => {
      organizedGroups.push({
        groupName: group.group_name,
        blocks: group.blocks,
      });
    });

    if (accessKeysResponse.Data.data)
      accessKeys = accessKeysResponse.Data.data.keys;

    const entities = entitiesResponse.Data;

    let botToStringify = {
      botName,
      entities,
      accessKeys,
      groups: organizedGroups,
    };

    return botToStringify;
  }
}
