import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Info from 'components/inputs/Material/Info/index';
import { FormFieldsContainer } from '../Shared/styles';
import FlexBlockContent from 'components/inputs/Material/FlexBlockContent';

export default function FlexBlock() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <FlexBlockContent />
      </FormFieldsContainer>
      <FooterButtons showLogicButton={false} isFlexBlock />
    </>
  );
}
