import React from 'react';
import CircularDeleteButton from 'components/CircularDeleteButton';
import MttTextField from 'components/Material/MttTextField/MttTextFIeld';
import {
  Container,
  ContainerOptions,
  ContentOptions,
  GridDeleteIcon,
} from './styles';
import { BoxAddLine, StyledAddButtom } from '../styles';
import AddIcon from '@material-ui/icons/Add';
import { Divider } from '@material-ui/core';
import { AdvancedExpressionProps } from './types';
import { useFlow } from 'contexts/Flow/flowContext';
import { useEntity } from 'contexts/Entity/entityContext';
import useTranslator from 'utils/hooks/Translator';

export default function AdvancedExpressions() {
  const { toastNotification } = useFlow();
  const { state, dispatch } = useEntity();
  const { selectedIEntity } = state;
  const { values } = state.selectedIEntity;
  const { getTranslation } = useTranslator();

  const handleDeleteLine = (index: number) => {
    if (values.length > 1) {
      values.splice(index, 1);
      dispatch({ type: 'updateEntities', data: { selectedIEntity } });
    } else {
      toastNotification('error', 'não é possível excluir');
    }
  };

  const handleChangeExpression = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    values[index] = { value, synonyms: [value] };
    dispatch({ type: 'updateEntities', data: { selectedIEntity } });
  };

  const handleShowLines = (item: AdvancedExpressionProps, index: number) => {
    return (
      <ContentOptions key={index}>
        <MttTextField
          fullWidth
          label={getTranslation('variables.insertRegex')}
          variant="outlined"
          value={item.value}
          onChange={(e) => handleChangeExpression(e, index)}
        />
        {values.length > 1 ? (
          <GridDeleteIcon>
            <CircularDeleteButton
              handleDeleteItem={() => handleDeleteLine(index)}
            />
          </GridDeleteIcon>
        ) : (
          <> </>
        )}
      </ContentOptions>
    );
  };

  const handleAddNewLine = () => {
    values.push({ value: '', synonyms: [] });
    dispatch({ type: 'updateEntities', data: { selectedIEntity } });
  };

  return (
    <Container>
      <BoxAddLine>
        <span>
          {getTranslation('variables.hintRegex')}{' '}
          <b>
            <a
              href="https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Guide/Regular_Expressions"
              target="_blank"
              rel="noreferrer"
            >
              {getTranslation('variables.hintRegexLink')}
            </a>
          </b>
          .
        </span>
        <StyledAddButtom
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNewLine}
        >
          {getTranslation('variables.addLine')}
        </StyledAddButtom>
      </BoxAddLine>
      <Divider />
      <ContainerOptions size={values.length}>
        {values.map((line, index) => handleShowLines(line, index))}
      </ContainerOptions>
    </Container>
  );
}
