import React, { ReactNode } from "react";
import { ThemeProvider } from "@material-ui/core";
import { defaultTheme } from "./DefaultTheme";

type ThemeCustomProps = {
  children: ReactNode;
};

const ThemeCustom: React.FC<ThemeCustomProps> = ({ children }) => {
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
};

export default ThemeCustom;
