import { MouseEvent, useState } from 'react';
import * as S from './styles';
import { Delete, CloudDownload } from '@material-ui/icons';
import { IEntity } from 'models/Entity';
import { IconButton, Tooltip } from '@material-ui/core';
import { exportEntity } from 'utils/Entity';
import useTranslator from 'utils/hooks/Translator';

type IEntitiesItemProps = {
  item: IEntity;
  index: number;
  handleClick: () => void;
  handleRemove: () => void;
};

export default function EntitiesItem(props: IEntitiesItemProps) {
  const { item, handleClick, handleRemove } = props;
  const [showTrash, setShowTrash] = useState(false);
  const { getTranslation } = useTranslator();

  const handleExportEntity = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    exportEntity(item);
  };

  return (
    <S.ContentLineEntity
      onMouseEnter={() => setShowTrash(true)}
      onMouseLeave={() => setShowTrash(false)}
      onClick={handleClick}
    >
      <div>
        <S.GridNameEntity>{item.name}</S.GridNameEntity>
        <S.GridTypeEntity>
          {item.entitytype === 'words'
            ? getTranslation('variables.words')
            : getTranslation('variables.regex')}
        </S.GridTypeEntity>
      </div>
      {showTrash && (
        <>
          <S.TrashIcon>
            <IconButton onClick={(e) => handleExportEntity(e)}>
              <Tooltip title={getTranslation('variables.download')}>
                <CloudDownload />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleRemove();
              }}
            >
              <Tooltip title={getTranslation('variables.delete')}>
                <Delete />
              </Tooltip>
            </IconButton>
          </S.TrashIcon>
        </>
      )}
    </S.ContentLineEntity>
  );
}
