import { Flag, IFlowContextActions, IFlowContextState, ToastType } from 'contexts/Flow/types';
import DataFormModel from 'models/DataFormModel';
import { Dispatch } from 'react';

export interface IFlagsContainerProps {
  state: IFlowContextState;
  dispatch: Dispatch<IFlowContextActions>;
  form: DataFormModel;
  node: number;
  toast: (type: ToastType, message: string, duration?: number) => void;
}

export const defaultFlags: Flag[] = [
  {
    color: 'transparent',
    title: '',
  },
  {
    color: '#00fa9e',
    title: 'verde',
  },
  {
    color: '#fff60e',
    title: 'amarelo',
  },
  {
    color: '#fc970a',
    title: 'laranja',
  },
  {
    color: '#f62e97',
    title: 'rosa',
  },
];

export const emptyFlag: Flag = {
  title: '',
  color: 'transparent',
}
