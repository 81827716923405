import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerDialog = styled(Grid)`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 54px;

  overflow-y: hidden;

  .MuiAppBar-colorPrimary {
    background-color: #0e103b;
    position: absolute;
  }

  .MuiOutlinedInput-input {
    background-color: #fff;
  }

  .MuiFormControl-root {
    background-color: #fff;
  }

  .MuiToolbar-root {
    justify-content: space-between;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ContentContainer = styled(Grid)`
  overflow-y: hidden;
  max-height: 60vh;
  padding: 20px;
  margin-top: 16px;

  .MuiAutocomplete-popper {
    overflow-x: hidden;
  }
`;

export const ButtonContainer = styled(Grid)`
  height: 76px;

  button {
    width: 100%;
    height: 100%;
    padding: 24px;
  }
`;

export const AutoCompleteOption = styled.span`
  flex: 1;
  margin: 3px 0;
`;

export const GridInfo = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  margin-top: 80px;
`;
