import { ReactNode } from 'react';
import { Actions } from 'contexts/Permissions/types';

export const emptyCompany: Company = {
  _id: '',
  name: '',
  admins: [],
  users: [],
  projects: [],
  agents: [],
  groups: [],
  agentsLimit: 0,
  usersLimit: 0,
  loginWithAd: false,
};

export const emptyGroup: CompanyGroup = {
  _id: '',
  name: '',
  actions: [],
  agents: [],
  company: '',
  project: '',
  users: [],
  projects: [],
};

export const emptyProject: CompanyProject = {
  _id: '',
  name: '',
  agents: [],
  company: '',
  users: [],
  groups: [],
};

export const initialState = {
  company: emptyCompany,
  companyCopy: emptyCompany,
  group: emptyGroup,
  actions: [],
  project: emptyProject,
  projectCopy: emptyProject,
} as CompanyContextState;

export interface Props {
  children: ReactNode;
}

export interface ICompanyContext {
  state: CompanyContextState;
  dispatch: React.Dispatch<CompanyContextActions>;
  setCompany: (company: Company, updateCopy?: boolean) => void;
  setProject(project: CompanyProject, updateCopy?: boolean): void;
}

export interface CompanyContextState {
  company: Company;
  companyCopy: Company;
  group: CompanyGroup;
  project: CompanyProject;
  projectCopy: CompanyProject;
  actions?: Actions[];
}

export type CompanyStateActionTypes =
  | 'setCompanies'
  | 'setCompany'
  | 'setCompanyCopy'
  | 'setGroup'
  | 'setProject'
  | 'setProjectCopy'
  | 'updateActions';

export interface CompanyContextActions {
  type: CompanyStateActionTypes;
  data?: Partial<CompanyContextState>;
}

export interface Company {
  _id?: string;
  name: string;
  admins: string[];
  users: string[];
  usersLimit: number;
  projects?: string[];
  agents: string[];
  agentsLimit: number;
  groups?: CompanyGroup[];
  loginWithAd: boolean;
}

export interface CompanyGroup {
  _id: string;
  name: string;
  project: string;
  projects: string[];
  company: string;
  agents: string[];
  actions: Actions[];
  users: string[];
}

export interface CompanyUser {
  id?: string;
  email: string;
  isAdmin: boolean;
}

export interface CompanyProject {
  _id: string;
  name: string;
  company: string;
  agents: string[];
  users: string[];
  groups?: string[];
}
