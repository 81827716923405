import {
  getAllNodes,
  markSelected,
  uncheckSelectNodes,
  unmarkSelected,
} from '../utils/NodeContainer';
interface NodeIdAndPosition {
  id: number;
  position: {
    left: number;
    top: number;
    width: number;
    height: number;
    leftPlusWidth: number;
    topPlusHeight: number;
  };
}

const NodeSelection = (documentHtml: HTMLElement | null) => {
  if (documentHtml) {
    documentHtml.addEventListener('mousedown', (e) => {
			const clickedElement = e.target as Element;
      if (e.ctrlKey && (clickedElement.id === 'drawflow' || clickedElement.classList.contains('drawflow'))) {
        e.stopImmediatePropagation();
        const nodesIds = getAllNodes();
        const nodesPositions: NodeIdAndPosition[] = [];

        for (let index = 0; index < nodesIds.length; index++) {
          const node = document.getElementById(`node-${nodesIds[index]}`);

          if (node) {
            const nodeWidth = node.getBoundingClientRect().width;
            const nodeLeft = node.getBoundingClientRect().left;
            const nodeHeight = node.getBoundingClientRect().height;
            const nodeTop = node.getBoundingClientRect().top;

            const nodeLeftPlusWidth = nodeWidth + nodeLeft;
            const nodeTopPlusHeigth = nodeHeight + nodeTop;

            nodesPositions.push({
              id: index,
              position: {
                left: nodeLeft,
                top: nodeTop,
                width: nodeWidth,
                height: nodeHeight,
                leftPlusWidth: nodeLeftPlusWidth,
                topPlusHeight: nodeTopPlusHeigth,
              },
            });
          }
        }

        let selectionBox = document.getElementById('selectionBox');
        if (!selectionBox) {
          selectionBox = document.createElement('div');
          documentHtml.append(selectionBox);
        }
        selectionBox.setAttribute(
          'style',
          'position: absolute; width: 0px; height: 0px; border: 1px dotted #5008af; z-index: 999; background-color: #5008af1d'
        );
        selectionBox.setAttribute('id', 'selectionBox');

        if (selectionBox) {
          const initialMouseX = e.clientX;
          const initialMouseY = e.clientY;
          selectionBox.style.left = `${initialMouseX}px`;
          selectionBox.style.top = `${initialMouseY}px`;

          let lastMove = 0;

          documentHtml.onmousemove = function (e) {
            if (e.ctrlKey) {
              e.preventDefault();
              e.stopImmediatePropagation();
            }
            if (Date.now() - lastMove > 10) {
              if (selectionBox) {
                if (e.ctrlKey) {
                  selectionBox.style.height = `${Math.abs(
                    e.clientY - parseInt(selectionBox.style.top)
                  )}px`;

                  selectionBox.style.width = `${Math.abs(
                    e.clientX - parseInt(selectionBox.style.left)
                  )}px`;

                  if (e.pageY < initialMouseY) {
                    selectionBox.style.top = `${e.pageY}px`;
                    selectionBox.style.height = `${Math.abs(
                      parseInt(e.pageY.toString()) - initialMouseY
                    )}px`;
                  }

                  if (e.pageX < initialMouseX) {
                    selectionBox.style.left = `${e.pageX}px`;
                    selectionBox.style.width = `${Math.abs(
                      e.pageX - initialMouseX
                    )}px`;
                  }

                  for (let index = 0; index < nodesPositions.length; index++) {
                    const selectionBoxWidth =
                      selectionBox.getBoundingClientRect().width;
                    const selectionBoxLeft =
                      selectionBox.getBoundingClientRect().left;
                    const selectionBoxHeight =
                      selectionBox.getBoundingClientRect().height;
                    const selectionBoxTop =
                      selectionBox.getBoundingClientRect().top;

                    const selectionLeft = selectionBoxWidth + selectionBoxLeft;
                    const selectionTop = selectionBoxHeight + selectionBoxTop;

                    if (
                      !(
                        selectionTop < nodesPositions[index].position.top ||
                        selectionBoxTop >
                          nodesPositions[index].position.topPlusHeight ||
                        selectionLeft < nodesPositions[index].position.left ||
                        selectionBoxLeft >
                          nodesPositions[index].position.leftPlusWidth
                      )
                    ) {
                      markSelected(nodesIds[index].toString());
                    } else {
                      unmarkSelected(nodesIds[index].toString());
                    }
                  }
                }
              } else {
                documentHtml.onmouseup = null;
              }
              lastMove = Date.now();
            }
          };
          documentHtml.onmouseup = function (e: MouseEvent) {
            documentHtml.onmousemove = null;
            if (selectionBox) {
              selectionBox.style.display = 'none';
            }
          };
        }
      }
    });
    documentHtml.addEventListener('click', (e) => {
      if (!e.ctrlKey) {
        uncheckSelectNodes();
      }
    });
  }
};

export default NodeSelection;
