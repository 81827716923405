import React, { useCallback, useEffect } from 'react';
import { useForm } from 'contexts/Form/formContext';
import AddIcon from '@material-ui/icons/Add';
import * as S from './styles';
import MttButton from 'components/Material/MttButton/MttButton';
import { Output } from 'models/DataFlow';

import RulesBlockOutputsSentences from './RulesBlockOutputsSentences';

export default function RulesBlockContent() {
  const { state, dispatch } = useForm();
  const { dataForm } = state;

  const load = useCallback(() => {
    if (dataForm && dataForm.outputs && dataForm.outputs?.length === 0) {
      dataForm.outputs = [
        { title: '', isChip: false },
        { title: 'Outros', isChip: false },
      ];

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
            inputs: {
              variables: [
                {
                  name: 'resposta',
                  vartype: '@sys.any',
                  value: '$resposta',
                  required: true,
                  defaultValue: '',
                  isList: false,
                  questions: [''],
                  isValueEdited: false,
                },
              ],
            },
          },
        },
      });
    }

    if (dataForm && !dataForm.sendUser) {
      dataForm.sendUser = {
        message: '',
        messageDidNotUnderstood: '',
        url: '',
      };

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeDidNotUnderstoodMessage = (newValue: string) => {
    if (!!dataForm && dataForm.sendUser) {
      dataForm.sendUser.messageDidNotUnderstood = newValue;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const renderRule = (choice: Output, index: number) => {
    return (
      <div key={index}>
        {choice.title !== 'Outros' && (
          <RulesBlockOutputsSentences
            index={index}
            choice={choice}
            showRemoveButton={showRemoveButton}
          />
        )}
      </div>
    );
  };

  const handleAddChoice = () => {
    if (dataForm) {
      if (dataForm.outputs) {
        const other = dataForm.outputs.find(
          (o) => o.title.toLowerCase() === 'outros'
        );
        const newOutputs = dataForm.outputs.filter(
          (o) => o.title.toLowerCase() !== 'outros'
        );
        newOutputs.push({
          title: '',
          userMsg: '',
          userSentences: [''],
          isChip: false,
        });
        if (other) {
          newOutputs.push(other);
        }
        dataForm.outputs = newOutputs;
      } else {
        dataForm.outputs = [{ title: 'Outros' }];
      }

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const showRemoveButton =
    !!dataForm && !!dataForm.outputs ? dataForm.outputs.length > 2 : false;

  return (
    <S.GridContainer>
      <S.ContainerButton>
        <MttButton
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddChoice}
        >
          adicionar saída
        </MttButton>
      </S.ContainerButton>
      <S.ContentGrid>{dataForm?.outputs?.map(renderRule)}</S.ContentGrid>
      <S.ContentMsgForUserGrid>
        <S.StyledMttTypographyMsgForUser />
        <S.StyledMttTextField
          variant="outlined"
          fullWidth
          label="Mensagem"
          multiline
          rows={3}
          name="choice-input"
          type="text"
          value={dataForm?.sendUser?.messageDidNotUnderstood || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeDidNotUnderstoodMessage(e.target.value);
          }}
        />
      </S.ContentMsgForUserGrid>
    </S.GridContainer>
  );
}
