import { IEntityContextState, IEntityContextActions } from './types';

export const initialState = {
  entities: [],
  version: 1,
  screen: 'list',
  selectedIEntity: {
    name: '',
    entitytype: 'words',
    values: [
      {
        value: '',
        synonyms: [],
      },
    ],
  },
  valuesWord: [
    {
      value: '',
      synonyms: [],
    },
  ],
  valuesExpression: [
    {
      value: '',
      synonyms: [],
    },
  ],
  errors: {},
  openModalEntityExists: false,
  uploadedEntity: {
    name: '',
    entitytype: 'words',
    values: [
      {
        value: '',
        synonyms: [''],
      },
    ],
  },
} as IEntityContextState;

export function reducerForm(
  state: IEntityContextState,
  action: IEntityContextActions
): IEntityContextState {
  switch (action.type) {
    case 'updateEntities':
      return functionsReducer[action.type](state, action.data);
    case 'openModalEntityExists':
      return functionsReducer[action.type](state);
    case 'closeModalEntityExists':
      return functionsReducer[action.type](state);
  }
}

const functionsReducer = {
  updateEntities: (
    state: IEntityContextState,
    data?: Partial<IEntityContextState>
  ) => {
    return { ...state, ...data };
  },

  openModalEntityExists: (
    state: IEntityContextState,
    data?: Partial<IEntityContextState>
  ) => {
    return { ...state, openModalEntityExists: true };
  },

  closeModalEntityExists: (
    state: IEntityContextState,
    data?: Partial<IEntityContextState>
  ) => {
    return { ...state, openModalEntityExists: false };
  },
};

export type IFormContextActionsTypes = keyof typeof functionsReducer;
