import { validateBlockedIntents } from '../enums/EIntentType';
import { DataFlow } from '../models/DataFlow';
import {
  fixInputs,
  fixOutputs,
  getNodePosition,
} from '../components/Flow/utils/NodeContainer';
import { DrawflowModel } from '../models/DrawflowModel';
import { IFlowContextState } from '../contexts/Flow/types';

export async function importGroup(
  state: IFlowContextState,
  toastNotification: any,
  mountNodeContent: any,
  errors: {
    onlyValidForPrincipal: string;
    invalidFileForPrincipal: string;
    invalidFileForGroup: string;
  }
) {
  const documentHtml = document.getElementById('drawflow');
  const uploadInput = document.createElement('input');
  uploadInput.type = 'file';
  uploadInput.accept = 'application/json';
  uploadInput.onchange = async (e: any) => {
    const file = e.target.files?.item(0);
    const drawflow = JSON.parse(await file.text());

    if (drawflow.drawflow) {
      if (drawflow.drawflow.Home) {
        const dataFlow: DataFlow = drawflow.drawflow.Home.data;

        const keys = Object.keys(dataFlow);
        let hasInitialBlocks = false;
        const isPrincipalGroup = state.idGroup === 'principal';

        try {
          for (const key of keys) {
            const { data } = dataFlow[Number(key)];

            if (isPrincipalGroup) {
              if (validateBlockedIntents(data.intentType)) {
                hasInitialBlocks = true;
                break;
              }
            } else if (validateBlockedIntents(data.intentType)) {
              throw new Error(errors.onlyValidForPrincipal);
            }

            dataFlow[Number(key)].id = Number(key);

            fixInputs(dataFlow[Number(key)], dataFlow);
            fixOutputs(dataFlow[Number(key)], dataFlow);

            const sPayload = data.dataBlockly?.payload as string;
            if (sPayload) {
              const newPayload = sPayload.replaceAll(
                `${data.groupId}_`, //nome atual do grupo
                `${state.idGroup}_` //novo nome do grupo
              );

              if (data.dataBlockly?.payload)
                data.dataBlockly.payload = newPayload;
            }
          }

          if (isPrincipalGroup && !hasInitialBlocks) {
            throw new Error(errors.invalidFileForPrincipal);
          }
        } catch (error: any) {
          toastNotification('error', error.message);
          return;
        }

        const drawflowModel = new DrawflowModel(dataFlow, state.idGroup || '');

        drawflow.drawflow.Home.data = drawflowModel.getDrawflow();
        state.editorFlow?.import(drawflow);

        mountNodeContent(dataFlow);
        uploadInput.parentNode?.removeChild(uploadInput);

        const block = state.editorFlow?.drawflow.drawflow.Home.data;
        const blocksKeys = Object.keys(block);
        const positionsX = blocksKeys.map((key) => block[Number(key)].pos_x);

        const biggestPos_x = Math.min(...positionsX);

        const minBlock = blocksKeys.find(
          (key) => block[Number(key)].pos_x === biggestPos_x
        );

        const { x, y } = getNodePosition(minBlock as unknown as number);

        const newX = x / 2 + x * 0.1;
        const newY = y / 2 + y * 0.1;

        if (state.editorFlow) {
          state.editorFlow.canvas_x = -newX;
          state.editorFlow.canvas_y = -newY;
          if (state.editorFlow.precanvas) {
            state.editorFlow.precanvas.style.transform = `translate(${state.editorFlow.canvas_x}px, 
						${state.editorFlow.canvas_y}px) 
						scale(${state.editorFlow.zoom}) `;
          }
        }
      } else {
        toastNotification('error', errors.invalidFileForGroup);
      }
    } else {
      toastNotification('error', errors.invalidFileForGroup);
    }
  };
  documentHtml?.appendChild(uploadInput);
  uploadInput.click();
  uploadInput.parentNode?.removeChild(uploadInput);
}

export async function exportGroup(state: IFlowContextState) {
  const documentHtml = document.getElementById('drawflow');
  const dataStr =
    'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(state.editorFlow?.drawflow));
  const dlAnchorElem = document.createElement('a');
  documentHtml?.appendChild(dlAnchorElem);
  if (dlAnchorElem) {
    dlAnchorElem.setAttribute('href', dataStr);
    const dateGenerated = new Date();
    const dataFormated =
      dateGenerated.getDate() +
      '-' +
      dateGenerated.getMonth() +
      '-' +
      dateGenerated.getFullYear() +
      '-' +
      dateGenerated.getHours() +
      '-' +
      dateGenerated.getMinutes();
    dlAnchorElem.setAttribute(
      'download',
      `dataflow-${state.idGroup}-${dataFormated}.json`
    );
    dlAnchorElem.click();
    dlAnchorElem.parentNode?.removeChild(dlAnchorElem);
  }
}
