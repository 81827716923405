import React, { useCallback, useEffect, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import ForumIcon from '@material-ui/icons/Forum';

import CircularDeleteButton from 'components/CircularDeleteButton/index';
import { IOptionMenuList } from 'components/IconButtonWithMenuList/types';
import SpeedDialWithMenuList from 'components/SpeedDialWithMenuList';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { useForm } from 'contexts/Form/formContext';
import { GetEntities } from 'services/EntityService';
import {
  createUserSentenceFromSentence,
  mapperDataFormInputsVariables,
} from 'utils/Sentences';
import {
  replaceAlphaNumericAndUndescoreDash,
  validateVarNames,
} from 'utils/String';

import ModalVariableAdvanced from './ModalVariableAdvanced';
import {
  ErrorWarning,
  GridIconDelete,
  StyledFooterGrid,
  StyledGridPerguntas,
  StyledInputsGrid,
  StyledMttGridMain,
  StyledMttTextField,
  StyledMttTypographyIdentificacao,
  StyledMttTypographyPerguntas,
  StyledTextFieldPergunta,
  StyledTitleGrid,
} from './styles';
import { propsData } from './types';

const errorMessages = {
  notExistsVariable: (variable: string) =>
    `A variavel ${variable} ainda não foi criada.`,
  equalsName: 'O nome do parâmetro deve ser único dentro da ação.',
  emptyName: 'O nome do parâmetro não pode ser vazio.',
};

const defaultVariableValue = {
  name: '',
  vartype: '',
  value: '',
  isValueEdited: false,
  questions: [''],
  defaultValue: '',
  isList: false,
  required: false,
};

export default function VariableIndentificationTextUser(props: Readonly<propsData>) {
  const { botName } = useFlow().state;
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const { dispatch: dispatchApp } = useApp();

  const { index, showTitle, showQuestions } = props;
  const variables = dataForm?.inputs?.variables ?? [
    {
      ...defaultVariableValue,
    },
  ];
  const variable = dataForm?.inputs?.variables[index];

  const [extractedVariables, setExtractVariables] = useState(variables);
  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);
  const [openAdvancedModal, setOpenAdvancedModal] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true
    
    async function load() {
      let allTypes = [
        { id: '@sys.any', name: 'qualquer-input' },
        { id: '@sys.email', name: 'input-de-email' },
        { id: '@sys.number', name: 'input-de-numero' },
        { id: '@sys.date-time', name: 'input-de-data' },
        { id: '@sys.url', name: 'input-de-url' },
      ];

      if(!botName) return;

      let result = await GetEntities({ bot_name: botName }, dispatchApp);

      if (!result.Success || !result.Data) return;

      const entities = result.Data;
        allTypes = [
          ...allTypes,
          ...entities.map((entity) => ({
            id: entity.name,
            name: entity.name,
          })),
        ].sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          } else if (b.name > a.name) {
            return -1;
          } else {
            return 0;
          }
        });

        allTypes.forEach((type) => {
          if (!type.id.startsWith('@')) type.id = `@${type.id}`;
        });

        if (isMounted) {
          setTypes(allTypes);
        }
    }
    load();

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName]);

  const load = useCallback(() => {
    if (dataForm && dataForm.inputs) {
      const currentVariables = dataForm.inputs?.variables ?? [];

      const variables = mapperDataFormInputsVariables(
        dataForm.inputs?.userSentences ?? [],
        currentVariables
      );

      setExtractVariables(variables);
    }
  }, [dataForm]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeInput =
    (key: 'value' | 'name' | 'vartype', index: number) =>
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      if (!dataForm || !variable) return;

      const value = String(e.target.value) || 'selecione-tipo';
      let filteredValue = validateVarNames(e, key, true);
      const selectedVariable = dataForm?.inputs?.variables[index];

      if (key === 'vartype' && !!selectedVariable) {
        const regExp = new RegExp(selectedVariable.vartype, 'ig');

        if (!!dataForm && !!dataForm?.inputs) {
          dataForm.inputs.userSentences =
            dataForm.inputs.userSentences?.map((sentence) =>
              sentence.replace(regExp, filteredValue)
            ) ?? [];
        }
      } else if (key === 'name' && !extractedVariables[index].isValueEdited) {
        extractedVariables[index]['value'] = '$' + value;
      }
      extractedVariables[index][key] = filteredValue;

      dispatch({
        type: 'updateForm',
        data: { dataForm },
      });
    };

  const handleQuestion = (value: string, varIndex: number, index: number) => {
    if (variable && dataForm) {
      variables[varIndex].questions[index] = value;
      dispatch({
        type: 'updateForm',
        data: { dataForm },
      });
    }
  };

  const handleShowPerguntas = (index: number) => {
    if (variable && dataForm) {
      const curVar = variables[index];
      if (curVar) {
        curVar.required = !curVar.required;
				
        dispatch({
          type: 'updateForm',
          data: { dataForm },
        });
      }
    }
  };

  const handleRemoveVariable = (index: number) => {
    const selectedVariable = variables[index];
    const sentences =
      dataForm?.inputs?.userSentences?.map((sentence) =>
        createUserSentenceFromSentence(sentence, variables)
      ) ?? [];

    sentences.forEach((sentence, sentenceIndex) => {
      const parameters = sentence.parameters.filter(
        (parameter) => parameter.entity === selectedVariable.vartype
      );

      parameters.forEach((parameter) => {
        const { entity, name, resolvedValue } = parameter;
        const regexpPhrase = `[${resolvedValue}](${name}:${entity})`;

        removeParameterFromSentenceIndex(
          sentence.phrase.replace(regexpPhrase, resolvedValue),
          sentenceIndex
        );
      });
    });

    variables.splice(index, 1);
    setExtractVariables(variables);

    dispatch({ type: 'updateForm', data: { dataForm } });
  };

  function removeParameterFromSentenceIndex(sentence: string, index: number) {
    const userSentences = dataForm?.inputs?.userSentences ?? [];

    const clone = [...userSentences];
    clone[index] = sentence;

    if (dataForm?.inputs) {
      dataForm.inputs.userSentences = clone;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }

  const options: IOptionMenuList[] = [
    {
      label: 'Avançado',
      iconName: 'Settings',
      actionToReference: openModal,
    },
    {
      label: 'Cancelar',
      iconName: 'Cancel',
      action: () => {
        setOpenAdvancedModal(false);
      },
    },
  ];

  const [selectedIndexVariable, setSelectedIndexVariable] = useState<number>();

  function openModal(indexVariableModal: number) {
    setSelectedIndexVariable(indexVariableModal);
    setOpenAdvancedModal(true);
  }

  const renderQuestions = (varIndex: number) => {
    if (!showQuestions) return <></>;
    return (
        <StyledGridPerguntas showgrid={!!variables[varIndex]?.required}>
          {variables[varIndex]?.questions.map((perg, index) => {
            return (
              <StyledTextFieldPergunta
                key={index}
                onChange={(e) => handleQuestion(e.target.value, varIndex, index)}
                value={perg}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ForumIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            );
          })}
        </StyledGridPerguntas>
    );
  };

  function handleChangeVariableName(name: string, variableIndex: number) {
    name = replaceAlphaNumericAndUndescoreDash(name);

    const oldName = variables[variableIndex].name;
    const regExp = new RegExp(`\\(${oldName}\\:`, 'gi');
    const userSentences = dataForm?.inputs?.userSentences ?? [];
    const hasSentences = userSentences.some((sentence) =>
      regExp.test(sentence)
    );

    if (hasSentences) {
      const replacedSentences = userSentences.map((sentence) =>
        sentence.replace(regExp, `(${name}:`)
      );

      if (dataForm?.inputs) {
        dataForm.inputs.userSentences = replacedSentences;
      }
    }

    if (!variables[variableIndex].isValueEdited) {
      variables[variableIndex]['value'] = '$' + name;
    }

    variables[variableIndex].name = name;

    if (dataForm) {
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }

  function getErrorsInVariable(variableIndex: number) {
    const errors: string[] = [];
    const variable = extractedVariables[variableIndex];

    const notExistsVariable = !types.some(
      (extractedVariable) => extractedVariable.id === variable.vartype
    );
    const isEmptyName = variable.name === '';
    const isEqualsName = extractedVariables.some(
      (extractedVariable, extractedVariableIndex) =>
        extractedVariable.name === variable.name &&
        extractedVariableIndex !== variableIndex
    );

    if (isEmptyName) errors.push(errorMessages.emptyName);

    if (notExistsVariable)
      errors.push(errorMessages.notExistsVariable(variable.vartype));

    if (isEqualsName) errors.push(errorMessages.equalsName);

    return errors;
  }

  return (
    <>
      {!!showTitle && variables.length > 0 && (
        <StyledTitleGrid>
          <StyledMttTypographyIdentificacao />
        </StyledTitleGrid>
      )}
      <StyledMttGridMain>
        {extractedVariables.map((extractedVar, index) => {
          const errors = getErrorsInVariable(index);
          const hasError = errors.length > 0;
          return (
            <StyledFooterGrid key={index}>
              <StyledInputsGrid>
                <StyledMttTextField
                  variant="outlined"
                  label="Nome"
                  value={extractedVar?.name || ''}
                  onChange={(e) =>
                    handleChangeVariableName(e.target.value, index)
                  }
                  fullWidth
                />

                <FormControl variant="outlined" fullWidth error={hasError}>
                  <InputLabel id="groups-simple-select-outlined-label">
                    Tipos
                  </InputLabel>
                  <ErrorWarning>
                    <Select
                      labelId="groups-simple-select-outlined-label"
                      id="groups-simple-select-outlined"
                      value={extractedVar?.vartype || ''}
                      onChange={handleChangeInput('vartype', index)}
                      label="Tipos"
                      fullWidth
                      error={hasError}
                    >
                      {types.map((type, index) => (
                        <MenuItem
                          key={index}
                          value={type.id}
                          disabled={!extractedVar?.name}
                        >
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>

                    {hasError && (
                      <FormHelperText>
                        <ul
                          style={{
                            position: 'relative',
                            background: '#fff',
                            zIndex: 999,
                          }}
                        >
                          {errors.map((error) => (
                            <li key={error}>{error}</li>
                          ))}
                        </ul>
                      </FormHelperText>
                    )}
                  </ErrorWarning>

                  <SpeedDialWithMenuList
                    iconName="MoreVert"
                    options={options}
                    referenceIndex={index}
                  />

                  {/* {!!showDelete && ( */}
                  <GridIconDelete key={`variable-${index}`}>
                    <CircularDeleteButton
                      handleDeleteItem={() => handleRemoveVariable(index)}
                    />
                  </GridIconDelete>
                  {/* )} */}
                </FormControl>
              </StyledInputsGrid>

              {showQuestions && (
                <Grid container xs={12} direction="row" alignItems="center">
                  <Switch
                    checked={!!extractedVar?.required}
                    onChange={() => handleShowPerguntas(index)}
                    color="primary"
                  />
                  <StyledMttTypographyPerguntas />
                </Grid>
              )}
              {renderQuestions(index)}
            </StyledFooterGrid>
          );
        })}
      </StyledMttGridMain>

      {typeof selectedIndexVariable === 'number' && (
        <ModalVariableAdvanced
          index={selectedIndexVariable}
          open={openAdvancedModal}
          handleCloseModal={() => setOpenAdvancedModal(false)}
        />
      )}
    </>
  );
}
