import React, { useCallback, useEffect } from 'react';
import { createContext, ReactNode, useContext, useReducer } from 'react';
import { initialState, permissionsReducer } from './permissionsReducer';
import {
  HasPermissionToActionParams,
  IPermissionCompany,
  IPermissionsContext,
} from './types';
import { useApp } from 'contexts/App/appContext';
import { GetCompanies } from 'services/CompaniesService/Company';
import { Company } from 'contexts/Company/types';
import useTokenHandler from 'utils/hooks/useTokenHandler';
import jwtDecode from 'jwt-decode';

interface Props {
  children: ReactNode;
}

const PermissionsContext = createContext<IPermissionsContext>(
  {} as IPermissionsContext
);

export function PermissionsProvider({ children }: Props) {
  const [state, dispatch] = useReducer(permissionsReducer, initialState);
  const { state: stateApp, dispatch: dispatchApp } = useApp();
  const { getDecodedToken } = useTokenHandler();
  const decodedToken = getDecodedToken();
  const email = decodedToken ? decodedToken.email : '';

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;
    generatePermissions(token);
  }, []);

  const fetchCompanies = useCallback(async () => {
    const response = await GetCompanies(dispatchApp);
    if (!response.Success) return [];
    const companiesResponse = response.Data as Company[];
    const companies: IPermissionCompany[] = companiesResponse.map(
      (company): IPermissionCompany => ({
        name: company.name,
        isAdmin: company.admins.includes(email),
        agents: company.agents.map((agent) => ({
          actions: ['*'],
          name: agent,
        })),
        projects: company.projects!.map((project) => ({
          name: project,
          agents: company.agents,
        })),
      })
    );
    dispatch({ type: 'updatePermissions', data: { companies } });
  }, [dispatchApp, email]);

  useEffect(() => {
    async function loadCompanies() {
      if (stateApp.signed && state.isRoot) await fetchCompanies();
    }

    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateApp.signed, state.isRoot]);

  function isRoot() {
    return state.isRoot;
  }

  function getPermissions() {
    const companies = state.companies;
    const permissions: any = [];

    companies.forEach((company) => {
      const perm = company.agents.map((agent) => agent.actions);

      permissions.push({ company: company.name, agents: perm });
    });

    return companies;
  }

  function getCompaniesNames() {
    const companies = state.companies;

    return companies.map((company) => company.name);
  }

  function getCompanies() {
    return state.companies;
  }

  function hasPermissionToCompany(company: string) {
    const companies = state.companies;
    if (companies) return companies.find((c) => c.name === company);
  }

  function hasPermissionToAgent(company: IPermissionCompany, agent: string) {
    return company.agents.find((a) => a.name === agent);
  }

  function isCompanyAdmin(companyName: string) {
    const companies = state.companies;

    if (!companies) return false;

    const company = companies.find((c) => c.name === companyName);
    return company?.isAdmin || false;
  }

  function hasPermissionToAction({
    company,
    agent,
    action,
  }: HasPermissionToActionParams) {
    if (isRoot()) return true;

    const currentCompany = hasPermissionToCompany(company);

    if (!currentCompany) return false;
    if (currentCompany.isAdmin) return true;

    const currentAgent = hasPermissionToAgent(currentCompany, agent);
    if (!currentAgent) return false;

    if (action.includes('*')) return true;

    let alreadyFoundPermission = false;

    const hasAccess = action.some((a) => {
      if (alreadyFoundPermission) return true;
      return currentAgent.actions.includes(a);
    });

    return hasAccess;
  }

  function generatePermissions(token: string) {
    const decodedToken: { isRoot: boolean; companies: IPermissionCompany[] } =
      jwtDecode(token);
    if (!decodedToken) return;

    const { isRoot, companies } = decodedToken;

    dispatch({
      type: 'updatePermissions',
      data: {
        isRoot,
        companies,
      },
    });
  }

  return (
    <PermissionsContext.Provider
      value={{
        state: state || initialState,
        dispatch,
        getPermissions,
        getCompaniesNames,
        hasPermissionToAction,
        isCompanyAdmin,
        isRoot,
        getCompanies,
        generatePermissions,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
}

export function usePermissions(): IPermissionsContext {
  const context = useContext(PermissionsContext);
  return context;
}
