import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'contexts/Form/formContext';
import AddIcon from '@material-ui/icons/Add';
import * as S from './styles';
import { IItensMultEscolha } from './types';
import MttButton from 'components/Material/MttButton/MttButton';
import { Output } from 'models/DataFlow';

import {
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';

import NewCarouselOutputsSentences from './NewCarouselOutputsSentences';
import EListType from 'enums/EListType';
import useTranslator from 'utils/hooks/Translator';
import { validateVarNames } from 'utils/String';

export default function NewCarouselContent(props: IItensMultEscolha) {
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const [varValue, setVarValue] = useState(
    dataForm?.varValue?.substring(5, dataForm?.varValue?.length) || 'resposta'
  );
  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm) {
      if (dataForm.outputs && dataForm.outputs?.length === 0) {
        dataForm.outputs = [
          { title: '', isChip: false },
          { title: 'Outros', isChip: false },
        ];

        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }

      if (dataForm && !dataForm.sendUser) {
        dataForm.sendUser = {
          message: '',
          messageDidNotUnderstood: '',
          url: '',
        };

        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }

      if (!dataForm.varValue) {
        dataForm.varValue = 'vars.resposta';
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeDidNotUnderstoodMessage = (newValue: string) => {
    if (!!dataForm && dataForm.sendUser) {
      dataForm.sendUser.messageDidNotUnderstood = newValue;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const renderChoice = (choice: Output, index: number) => {
    return (
      <div key={index}>
        {choice.title !== 'Outros' && (
          <NewCarouselOutputsSentences
            index={index}
            choice={choice}
            showRemoveButton={showRemoveButton}
          />
        )}
      </div>
    );
  };

  const handleAddChoice = () => {
    if (dataForm) {
      if (dataForm.outputs) {
        const other = dataForm.outputs.find(
          (o) => o.title.toLowerCase() === 'outros'
        );
        const newOutputs = dataForm.outputs.filter(
          (o) => o.title.toLowerCase() !== 'outros'
        );
        newOutputs.push({
          title: '',
          userMsg: '',
          userSentences: [''],
          isChip: false,
        });
        if (other) {
          newOutputs.push(other);
        }
        dataForm.outputs = newOutputs;
      } else {
        dataForm.outputs = [{ title: 'Outros' }];
      }

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeQuestion = (value: string) => {
    if (!!dataForm && !!dataForm.sendUser) {
      const sendUser = dataForm.sendUser;

      if (sendUser) {
        sendUser.message = value;
        dataForm.sendUser = sendUser;

        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  };

  const handleChangeOrientation = () => {
    if (dataForm) {
      dataForm.listType =
        dataForm.listType === EListType.Horizontal
          ? EListType.Vertical
          : EListType.Horizontal;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeSaveVarValue = (value: boolean) => {
    if (!!dataForm) {
      dataForm.saveVarValue = value;
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeVarValue = (
    e: React.ChangeEvent<
      | { name?: string | undefined; value: unknown }
      | HTMLTextAreaElement
      | HTMLInputElement
    >
  ) => {
    if (!!dataForm && dataForm.varValue) {
      const validatedVarValue = validateVarNames(e, 'name', true);
      dataForm.varValue = `vars.${validatedVarValue}`;
      
			setVarValue(validatedVarValue);

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const showRemoveButton =
    !!dataForm && !!dataForm.outputs ? dataForm.outputs.length > 2 : false;

  return (
    <S.GridContainer>
      <S.ContainerButton>
        <S.Container>
          <Typography variant="h6" color="primary">
            {getTranslation('info.messageForUser')}
          </Typography>
          <S.MessageContainer>
            <S.StyledMttTextField
              variant="outlined"
              label={getTranslation('message')}
              fullWidth
              value={dataForm?.sendUser?.message || ''}
              onChange={(e) => handleChangeQuestion(e.target.value)}
              minRows={3}
              maxRows={3}
              multiline
            />
          </S.MessageContainer>
        </S.Container>
      </S.ContainerButton>
      <S.ContainerButton>
        <RadioGroup
          row
          value={dataForm?.listType === EListType.Vertical ? true : false}
          onChange={handleChangeOrientation}
        >
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label={getTranslation('horizontalCarousel')}
          />
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label={getTranslation('verticalCarousel')}
          />
        </RadioGroup>
        <MttButton
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddChoice}
        >
          {getTranslation('addOption')}
        </MttButton>
      </S.ContainerButton>
      <S.ContentGrid>{dataForm?.outputs?.map(renderChoice)}</S.ContentGrid>

      <S.SwitchDiv>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={dataForm?.saveVarValue}
                color="primary"
                onChange={(e) => {
                  handleChangeSaveVarValue(e.target.checked);
                }}
              />
            }
            label={getTranslation('saveSelectedOption')}
            color="primary"
          />
        </FormGroup>
        {dataForm?.saveVarValue && (
          <>
            <Typography variant="subtitle1">
              {getTranslation('outputVariable')}
            </Typography>
            <TextField value={'vars.'} style={{ maxWidth: '56px' }} />
            <TextField
              value={varValue}
              onChange={handleChangeVarValue}
            />
          </>
        )}
      </S.SwitchDiv>

      <S.ContentMsgForUserGrid>
        <S.StyledMttTypographyMsgForUser variant="body1" color="primary">
          {getTranslation('messageDidNotUnderstood')}
        </S.StyledMttTypographyMsgForUser>
        <S.StyledMttTextField
          variant="outlined"
          fullWidth
          label={getTranslation('message')}
          multiline
          rows={3}
          name="choice-input"
          type="text"
          value={dataForm?.sendUser?.messageDidNotUnderstood || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeDidNotUnderstoodMessage(e.target.value);
          }}
        />
      </S.ContentMsgForUserGrid>
    </S.GridContainer>
  );
}
