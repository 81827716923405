import { IGetCustomers, IGetSingleCustomerRequest } from './types';

const baseUrl = `${process.env.REACT_APP_CRM_URL}/bot`;
const apiKey = process.env.REACT_APP_CRM_API_KEY ?? '';

export const GetCustomers = async ({
  agent,
  from,
  pageSize,
}: IGetCustomers) => {
  let action = `${baseUrl}/${agent}/contacts`;
  if (pageSize) action += `?pageSize=${pageSize}&from=${from}`;

  const headers = new Headers();
  headers.append('x-api-key', apiKey);

  return await fetch(action, {
    headers,
  });
};

export const GetSingleCustomer = async ({
  agent,
  customerId,
}: IGetSingleCustomerRequest) => {
  const action = `${baseUrl}/${agent}/contact/${customerId}`;
  const headers = new Headers();
  headers.append('x-api-key', apiKey);

  return await fetch(action, {
    headers,
  });
};
