/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useRef, useState } from 'react';
import { ToastContextInterface, ToastContextProps, ToastDataProps } from './types';

export const ToastContext = createContext<ToastContextInterface>(
  {} as ToastContextInterface
);

const defaultTimeout = 4000;

export const ToastContextProvider = ({ children }: ToastContextProps) => {
  const timeoutHandle = useRef<NodeJS.Timeout | null>(null);
  const [data, setData] = useState({
    timeout: defaultTimeout,
  } as ToastDataProps);

  const [visible, setVisible] = useState(false);

  function hideFunc() {
    if (timeoutHandle.current) clearTimeout(timeoutHandle.current);
    setVisible(false);
  }

  function toastNotification(toastData: ToastDataProps) {
    setData({ timeout: defaultTimeout, ...toastData });
    setVisible(true);
    if (timeoutHandle.current) clearTimeout(timeoutHandle.current);
    timeoutHandle.current = setTimeout(
      () => hideFunc(),
      toastData.timeout ?? defaultTimeout
    );
  }

  const context = {
    data,
    visible,
    toastNotification,
    hideToast: hideFunc,
  };

  return (
    <ToastContext.Provider value={context}>{children}</ToastContext.Provider>
  );
};

export function useToast() {
  const context = useContext(ToastContext);

  return context;
}
