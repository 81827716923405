import {
  Button,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import * as SS from '../styles';
import * as S from './styles';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useTranslator from 'utils/hooks/Translator';

import {
  DeleteActioning,
  GetActionings,
} from 'services/CampaignService/ActioningsService';
import {
  emptyActioning,
  IActioning,
  IActioningStatus,
} from 'services/CampaignService/ActioningsService/types';
import { hour } from 'utils/Date';
import { CreateOrEditActioning } from './CreateOrEditActioning';

import { CircularProgress } from '@material-ui/core';
import { Refresh, Visibility } from '@material-ui/icons';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useToast } from 'contexts/ToastContext';
import { isBefore } from 'date-fns';

export function ModalActioning() {
  const classes = SS.useStyles();

  const { toastNotification } = useToast();

  const { dispatch: dispatchApp } = useApp();
  const { currentData } = useFintalkCloud();
  const { hasPermissionToAction } = usePermissions();
  const { agentName: botName } = currentData;

  const bot_name = botName || '';

  const isCommunicationGuidelineWrite = hasPermissionToAction({
    company: currentData.companyName!,
    agent: currentData.agentName!,
    action: ['communication_guideline:write'],
  });

  const { getTranslation } = useTranslator();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModalCreateOrEditActioning, setOpenModalCreateOrEditActioning] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const [actionings, setActionings] = useState<IActioning[]>([]);
  const [currentActioning, setCurrentActioning] =
    useState<IActioning>(emptyActioning);

  const filteredActionings =
    search.length > 0
      ? actionings.filter((t) => t.bot_name.includes(search))
      : actionings;

  useEffect(() => {
    if (!openModalCreateOrEditActioning) loadActionings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalCreateOrEditActioning, botName]);

  async function loadActionings(refresh?: boolean) {
    setLoading(true);

    try {
      const response = await GetActionings(
        { bot_name, organization: '' },
        dispatchApp
      );

      if (response.Success) {
        const allActionings = response.Data.data;

        setActionings(
          allActionings.sort((a, b) => {
            if (
              isBefore(
                new Date(a.creation_date).getTime(),
                new Date(b.creation_date).getTime()
              )
            )
              return 1;
            else return -1;
          })
        );
      } else throw new Error('erro');
    } catch (error: any) {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.searchActionings'),
      });
    }

    setLoading(false);
  }

  function handleCloseNewActioning() {
    setActionings([]);
    setOpenModalCreateOrEditActioning(false);
  }

  function handleNewActioning() {
    setCurrentActioning(emptyActioning);
    setOpenModalCreateOrEditActioning(true);
  }

  function handleChangeRowsPerPage(e: { target: { value: string | number } }) {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }

  function handleChangePage(event: any, newPage: React.SetStateAction<number>) {
    setPage(newPage);
  }

  function getColor(status: IActioningStatus) {
    switch (status) {
      case 'concluded':
        return '#79e16d';
      case 'in_progress':
        return '#ffb84d';
      case 'not_started':
        return '#6dc1e1';
      case 'error':
      default:
        return '#cd5334';
    }
  }

  async function handleRemoveActioning(actioning: IActioning) {
    const response = await DeleteActioning(
      {
        ...actioning,
        bot_name,
      },
      dispatchApp
    );

    if (response.Success) {
      toastNotification({
        type: 'success',
        message: getTranslation('toast.success.actioningDeleted'),
      });
      setActionings(actionings.filter((act) => actioning._id !== act._id));
    } else {
      toastNotification({
        type: 'error',
        message: getTranslation('toast.error.actioningDeleted'),
      });
    }
  }

  function handleEditActioning(actioning: IActioning) {
    setCurrentActioning(actioning);
    setOpenModalCreateOrEditActioning(true);
  }

  function getPrettyStatus(status: IActioningStatus) {
    switch (status) {
      case 'concluded':
        return getTranslation('concluded');
      case 'in_progress':
        return getTranslation('inProgress');
      case 'error':
        return getTranslation('error');
      case 'not_started':
      default:
        return getTranslation('notStarted');
    }
  }

  const renderTemplatesList = () => (
    <SS.ContainerList>
      <SS.SearchAndTableContainer>
        <SS.ContainerHeader>
          <Typography variant="h6">
            {getTranslation('actioningsList')}
          </Typography>

          <SS.HeaderButtonsContainer>
            <Tooltip title={getTranslation('refresh')}>
              <IconButton onClick={() => loadActionings(true)}>
                <Refresh htmlColor='#fff'/>
              </IconButton>
            </Tooltip>

            {isCommunicationGuidelineWrite && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => handleNewActioning()}
              >
                {getTranslation('modal.campaigns.actioning.newButton')}
              </Button>
            )}
          </SS.HeaderButtonsContainer>
        </SS.ContainerHeader>

        <SS.GridSearch>
          <Autocomplete
            options={filteredActionings.map((option) => option.name)}
            classes={{ root: classes.autocomplete }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={search}
                label={getTranslation('search')}
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                classes={{ root: classes.searchInput }}
              />
            )}
          />
        </SS.GridSearch>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress color="secondary" size={36} />
          </div>
        ) : actionings.length > 0 ? (
          <div>
            <TableContainer classes={{ root: classes.tableContainer }}>
              <SS.CampaignsTable
                aria-label="simple table"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {getTranslation('scheduledDate')}
                    </TableCell>
                    <TableCell align="center">
                      {getTranslation('name')}
                    </TableCell>
                    <TableCell align="center">
                      {getTranslation('campaign')}
                    </TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">
                      {getTranslation('creationDate')}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? filteredActionings.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : filteredActionings
                  ).map((actioning, index) => {
                    const actioningEditable =
                      actioning.status === 'not_started';

                    const viewOrEditTooltip =
                      actioning.status !== 'not_started'
                        ? getTranslation('view')
                        : getTranslation('edit');

                    return (
                      <TableRow key={index}>
                        <TableCell scope="actioning" align="center">
                          {`${new Date(
                            `${actioning.scheduled_date}`
                          ).toLocaleDateString()} 
													${new Date(actioning.scheduled_date).toLocaleTimeString()}`}
                        </TableCell>
                        <TableCell align="center">{actioning.name}</TableCell>
                        <TableCell align="center">
                          {actioning.campaign_name}
                        </TableCell>
                        <TableCell align="center">
                          <S.StatusDiv>
                            <S.Status bgColor={getColor(actioning.status)} />
                            {getPrettyStatus(actioning.status)}
                          </S.StatusDiv>
                        </TableCell>
                        <TableCell align="center">
                          {`${new Date(
                            `${actioning.creation_date}`
                          ).toLocaleDateString()} 
													${hour(new Date(actioning.creation_date))}`}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={viewOrEditTooltip}>
                            <IconButton
                              onClick={() => handleEditActioning(actioning)}
                            >
                              {actioningEditable ? (
                                <EditIcon />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Tooltip>

                          {actioning.status === 'not_started' && (
                            <Tooltip title={getTranslation('delete')}>
                              <IconButton
                                onClick={() => handleRemoveActioning(actioning)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {filteredActionings.length > 0 && (
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={filteredActionings.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={getTranslation(
                          'modal.conversationHistory.linesPerPage'
                        )}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </SS.CampaignsTable>
            </TableContainer>
          </div>
        ) : (
          <p style={{ marginTop: 24 }}>
            {getTranslation('noActioningsResult')}
          </p>
        )}
      </SS.SearchAndTableContainer>
    </SS.ContainerList>
  );

  return (
    <SS.CampaignsContainer
      maxWidth={false}
      disableGutters
    >
      <SS.CampaignsContent>
        {openModalCreateOrEditActioning ? (
          <CreateOrEditActioning
            handleClose={handleCloseNewActioning}
            currentActioning={{
              ...currentActioning,
              bot_name,
              scheduled_date: currentActioning._id
                ? currentActioning.scheduled_date
                : new Date(),
            }}
          />
        ) : (
          renderTemplatesList()
        )}
      </SS.CampaignsContent>
    </SS.CampaignsContainer>
  );
}
