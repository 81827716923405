import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { TSelectChangeEvent } from 'components/Material/MttSelect/types';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { useForm } from 'contexts/Form/formContext';
import { Linked } from 'models/DataFlow';
import DataFormModel from 'models/DataFormModel';
import { useCallback, useEffect } from 'react';
import { GetGroup } from 'services/FlowService';
import useTranslator from 'utils/hooks/Translator';

export function ToAnotherBlock() {
  const { groupsNames } = useFlow().state;
  const { currentData } = useFintalkCloud();
  const { state, dispatch } = useForm();
  const { dataForm, intents, formErrors } = state;
  const { dispatch: dispatchApp } = useApp();
  const { getTranslation } = useTranslator();

  const load = useCallback(async () => {
    if (!!dataForm && !dataForm?.linked) {
      dataForm.linked = {
        groupId: '',
        intentId: '',
      };
      dispatch({ type: 'updateForm', data: { dataForm } });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeSelect =
    (key: keyof Linked) => async (e: TSelectChangeEvent) => {
      if (dataForm?.linked) {
        dataForm.linked[key] = String(e.target.value);
        delete state.formErrors[`linked.${key}`];
        if (key === 'groupId') {
          state.intents = await getIntentsByGroupId(dataForm.linked[key]);
        }
        dispatch({ type: 'updateForm', data: state });
      }
    };

  const getIntentsByGroupId = useCallback(
    async (groupId: string) => {
      if (groupId) {
        const result = await GetGroup(
          {
            bot_name: currentData.agentName || '',
            groupName: groupId,
          },
          dispatchApp
        );

        if (result.Success && !!result.Data.data.blocks.drawflow) {
          const data = result.Data.data.blocks.drawflow.Home.data;
          const keys = Object.keys(data);
          return keys
            .filter((key) => {
              const dataModel = new DataFormModel(data[Number(key)].data);
              return !dataModel.isChild() && !dataModel.isOthers();
            })
            .map((key) => {
              const { name } = data[Number(key)].data;
              return {
                id: name,
                name,
              };
            });
        }
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData.agentName]
  );

  const getIntents = useCallback(async () => {
    const intents = await getIntentsByGroupId(dataForm?.linked?.groupId || '');
    dispatch({ type: 'updateForm', data: { intents } });
  }, [dataForm?.linked?.groupId, dispatch, getIntentsByGroupId]);

  useEffect(() => {
    getIntents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
      }}
    >
      <FormControl error={!!formErrors['linked.groupId']}>
        <InputLabel id="groupIdSelecet">{getTranslation('group')}</InputLabel>
        <Select
          style={{ minWidth: 100 }}
          labelId="groupIdSelecet"
          id="groupIdSelecet"
          value={dataForm?.linked?.groupId || ''}
          onChange={handleChangeSelect('groupId')}
          fullWidth
        >
          {groupsNames.map((group) => (
            <MenuItem key={group} value={group}>
              {group}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{formErrors['linked.groupId']}</FormHelperText>
      </FormControl>
      <FormControl error={!!formErrors['linked.intentId']}>
        <InputLabel id="blockIdSelect">{getTranslation('block')}</InputLabel>
        <Select
          style={{ minWidth: 100 }}
          labelId="blockIdSelect"
          id="blockIdSelect"
          value={dataForm?.linked?.intentId || ''}
          onChange={handleChangeSelect('intentId')}
          fullWidth
        >
          {intents.map((intent) => (
            <MenuItem key={intent.id} value={intent.name}>
              {intent.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{formErrors['linked.intentId']}</FormHelperText>
      </FormControl>
    </div>
  );
}
