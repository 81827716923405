import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Info from 'components/inputs/Material/Info/index';
import { FormFieldsContainer } from '../Shared/styles';
import ExportDataSpread from 'components/inputs/Material/ExportDataSpreadSheet';
import ExcelOrCSV from 'components/inputs/Material/ExcelOrCSV';
import GoogleSheet from 'components/inputs/Material/GoogleSheet';

export default function SaveTable() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <ExportDataSpread />
        <ExcelOrCSV />
        <GoogleSheet />
      </FormFieldsContainer>
      <FooterButtons />
    </>
  );
}
