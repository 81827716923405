import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 300,
      position: 'absolute',
      top: 80,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    results: {
      margin: 5,
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.9rem',
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
		selectedMsg: {
			border: '2px solid #ff14149e !important',
			background: '#ff00001f !important',
		},
		foundMsg: {
			border: '2px solid #f4ed06 !important',
			background: '#fff36094 !important',
		},
  })
);