import { ObjectSchema, setLocale } from 'yup'
import { Assign, ObjectShape } from 'yup/lib/object'


const LOCALE_YUP = {
  mixed: {
    required: 'Campo obrigatório',
    default: 'Campo não é válido'
  },
  // string: {
  //   min: 'Mínimo de ${min} caracteres',
  //   max: 'Máximo de ${max} caracteres'
  // },
  number: {
    moreThan: 'Campo deve ter um valor selecionado'
  }
}

setLocale(LOCALE_YUP)

interface ICatchValidate {
  path: string
  message: string
}

export const genericValidation = async <T, L>(schema: ObjectSchema<Assign<ObjectShape, any>>, object:T, ErrorClass: any):Promise<L> => {
  const validate: boolean | ICatchValidate[] = await schema.validate(object, { abortEarly: false })
    .then((e) => true)
    .catch((err) => err.inner)

  if (typeof validate !== 'boolean') validate.forEach((item) => { ErrorClass[item.path] = item.message })

  return ErrorClass
}
