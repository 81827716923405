import * as yup from "yup";
import { slugify } from "../utils/String";
import { IResultError } from "./ResultError";
export interface INewGroup {
  name: string;
  description: string;
  unidBus: string;
	error?: string;
}

export const newGroupValidation = async (newGroup: INewGroup) => {
  const nameResult = await nameValidator(newGroup.name, newGroup.error || '');
  const isValid = nameResult.isValid;
  const errors = { ...nameResult.errors };
  return { isValid, errors };
};

const nameValidator = async (name: string, error: string) => {
  const schema = yup.object().shape({
    name: yup.string().required(error),
  });

  const promiseResult = new Promise<IResultError<INewGroup>>((resolve) => {
    schema
      .validate({ name }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { name: "" } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { name: err.errors[0] } });
      });
  });
  return await promiseResult;
};

export const newGroupMask = {
  name: (value: string) => {
    return slugify(value.replace(/\s/, '')).substr(0, 15);
  },
  description: (value: string) => value,
  unidBus: (value: string) => value,
	error: (value: string) => value,
};
