import {
  ContainerHeader,
  GridSearch,
  GridResultEntity,
  StyledCriarEntityButton,
  StyledMttTypographyEntities,
} from './styles';
import { Add, Search, Publish } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import TextField from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import EntitiesItem from 'components/EntitiesItem';
import { useEntity } from 'contexts/Entity/entityContext';
import { useEffect, useState } from 'react';
import { IEntity, IValueEntity } from 'models/Entity';
import { useFlow } from 'contexts/Flow/flowContext';
import { GetEntities, SaveEntities } from 'services/EntityService';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { useApp } from 'contexts/App/appContext';
import { importEntity, UploadEntityToastMessages } from 'utils/Entity';
import ModalVariableAlreadyExists from '../ModalVariableAlreadyExists';
import useTranslator from 'utils/hooks/Translator';

export default function VariableEntities() {
  const botName = useFlow().state.botName || '';
  const { dispatch: dispatchApp } = useApp();
  const toast = useFlow().toastNotification;
  const { dispatch, state } = useEntity();
  const [search, setSearch] = useState('');
  const { entities, screen } = state;
  const [load, setLoad] = useState(false);
  const { getTranslation } = useTranslator();

  const toastMessages: UploadEntityToastMessages = {
    addSuccess: getTranslation('toast.success.varAdded'),
    overwriteSuccess: getTranslation('toast.success.varOverwritten'),
    invalidFormat: getTranslation('toast.error.invalidFileForEntities'),
    addError: getTranslation('toast.error.varAdded'),
  };

  const options = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

  useEffect(() => {
    async function load() {
      if (screen === 'list') {
        setLoad(true);
        const entitiesJson = localStorage.getItem(`Entities&&${botName}`);
        let entities: IEntity[] = JSON.parse(entitiesJson || '[]');
        let result = await GetEntities({ bot_name: botName }, dispatchApp);

        if (result.Success) {
          if (!!result.Data) {
            entities = result.Data;
            dispatch({ type: 'updateEntities', data: { version: 1 } });
            localStorage.removeItem(`Entities&&${botName}`);
          }
        }

        dispatch({ type: 'updateEntities', data: { entities } });
        setLoad(false);
      }
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, screen, botName]);

  const handleNewVariable = () => {
    if (!!botName) {
      let valuesWord: IValueEntity[] = [{ value: '', synonyms: [] }];
      let valuesExpression: IValueEntity[] = [{ value: '', synonyms: [] }];
      const selectedIEntity: IEntity = {
        name: '',
        entitytype: 'words',
        values: [{ value: '', synonyms: [] }],
      };
      dispatch({
        type: 'updateEntities',
        data: {
          screen: 'add',
          selectedIEntity,
          valuesWord,
          valuesExpression,
          errors: {},
        },
      });
    } else {
      toast('warning', getTranslation('toast.warning.selectAgent'));
    }
  };

  const handleEdit = (index: number) => {
    dispatch({ type: 'updateEntities', data: { indexEdit: index } });
    const selectedIEntity: IEntity = entities[index];

    let valuesWord: IValueEntity[] = [{ value: '', synonyms: [] }];
    let valuesExpression: IValueEntity[] = [{ value: '', synonyms: [] }];
    if (selectedIEntity.entitytype === 'words') {
      valuesWord = [...selectedIEntity.values];
    } else {
      valuesExpression = [...selectedIEntity.values];
    }
    dispatch({
      type: 'updateEntities',
      data: {
        screen: 'add',
        selectedIEntity,
        valuesWord,
        valuesExpression,
        indexEdit: index,
        errors: {},
      },
    });
  };

  const handleRemove = async (index: number) => {
    setLoad(true);
    const entitiesRemoved = [...entities];
    entitiesRemoved.splice(index, 1);
    let result = await SaveEntities(
      {
        bot_name: botName,
        entities: entitiesRemoved,
      },
      dispatchApp
    );

    if (result.Success) {
      dispatch({
        type: 'updateEntities',
        data: {
          entities: entitiesRemoved,
          indexEdit: undefined,
        },
      });
      toast('success', getTranslation('toast.success.deleteVariable'));
    } else {
      toast(
        'error',
        result.Message || getTranslation('toast.error.deleteVariable')
      );
    }
    setLoad(false);
  };

  const handleUploadEntity = async () => {
    importEntity(state, dispatch, toast, toastMessages, botName, dispatchApp, undefined, false);
  };


  return (
    <>
      <ContainerHeader>
        <StyledMttTypographyEntities variant="h6" color="primary">
          {getTranslation('variables.title')}
        </StyledMttTypographyEntities>
        <div>
          <StyledCriarEntityButton
            variant="contained"
            startIcon={<Add />}
            onClick={handleNewVariable}
          >
            {getTranslation('variables.createVariableButton')}
          </StyledCriarEntityButton>
          <IconButton onClick={handleUploadEntity}>
            <Tooltip title={getTranslation('variables.uploadVariable')}>
              <Publish />
            </Tooltip>
          </IconButton>
        </div>
      </ContainerHeader>
      <GridSearch>
        <Autocomplete
          options={options.map((option) => option)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={getTranslation('variables.searchVariable')}
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </GridSearch>
      <GridResultEntity>
        {load ? (
          <CircularProgress color="inherit" />
        ) : (
          entities
            .map((entity, index) => ({ entity, index }))
            .filter(
              (item) =>
                item.entity.name.toLowerCase().indexOf(search.toLowerCase()) >
                  -1 || !search
            )
            .map((item) => (
              <EntitiesItem
                key={`entities-item-${item.index}`}
                index={item.index}
                item={item.entity}
                handleClick={() => handleEdit(item.index)}
                handleRemove={() => handleRemove(item.index)}
              />
            ))
        )}
      </GridResultEntity>
      <ModalVariableAlreadyExists />
    </>
  );
}
