import { TextField, Typography } from '@material-ui/core';
import { useState } from 'react';

import TextEditor from 'components/TextEditor';
import { DynamicBlockOutputs, Item, Property } from 'contexts/Flow/types';

import { Alert } from '@material-ui/lab';
import { AirTalkContextProvider } from 'contexts/AirTalkContext';
import { useForm } from 'contexts/Form/formContext';
import { infoFormMask } from 'models/InfoForm';
import useTranslator from 'utils/hooks/Translator';
import { getTranslationKeys } from 'utils/i18n/types';
import { AI123EntriesComponent } from './AI123Entries';
import { ApiEntries } from './ApiEntries';
import { BubbleEntries } from './BubbleEntries';
import { JsEditor } from './JsEditor';
import { MetaBlocksOutputs } from './MetaBlocksOutputs';
import { SendUser } from './SendUser';
import { ToAnotherBlock } from './ToAnotherBlock';

export function GroupChildKeys(props: Item) {
  const {
    description,
    sendUser,
    name,
    logic,
    group,
    block,
    outputs,
    apiEntries,
    returnVariable,
    apiReturnCode,
    startSentences,
    bubbleEntries,
    ai123Entries,
  } = props;
  const { state, updateForm } = useForm();
  const { getTranslation } = useTranslator();
  const { formErrors, dataForm } = state;

  const [showJsEditor, setShowJsEditor] = useState(false);
  const [jsToEdit, setJsToEdit] = useState('');

  const handleChangeProperty = (
    propertyToChange: 'name' | 'description',
    value: string
  ) => {
    if (!dataForm) return;

    const valueWithMask = infoFormMask[propertyToChange](value);
    delete formErrors[propertyToChange];
    dataForm[propertyToChange] = valueWithMask;
    updateForm(dataForm);
  };

  const handleChangeLogic = (value: string) => {
    if (!dataForm) return;
    if (!dataForm.dataBlockly) return;

    const compiledPayload = value;
    dataForm.dataBlockly.payload = compiledPayload;
    updateForm(dataForm);
    setShowJsEditor(false);
  };

  const handleChangeApiReturnCode = (value: string) => {
    if (!dataForm) return;

    const apiReturnTreament = value;
    dataForm.apiReturnCodeTreatment = apiReturnTreament;
    updateForm(dataForm);
  };

  const handleChangeStartMessage = (value: string, index: number) => {
    if (!dataForm) return;
    if (dataForm.inputs && dataForm.inputs.userSentences) {
      dataForm.inputs.userSentences[index] = value;
    }

    updateForm(dataForm);
  };

  function handleChangeVarValue(newValue: string) {
    if (!dataForm) return;
    dataForm.varValue = newValue;
    updateForm(dataForm);
  }

  const renderProperty = (property: Property) => {
    const propertyKey = property.label === 'name' ? 'name' : 'description';
    return (
      <div style={{ marginBottom: 16 }}>
        <Typography variant="subtitle2">
          {getTranslation(property.label as getTranslationKeys)}
        </Typography>

        <TextField
          type={property.type}
          inputProps={{ maxLength: property.maxLength }}
          fullWidth
          value={dataForm?.[propertyKey] || ''}
          onChange={(e) => handleChangeProperty(propertyKey, e.target.value)}
          helperText={formErrors[propertyKey]}
          error={!!formErrors[propertyKey]}
        />
      </div>
    );
  };

  const renderStartSentences = (startSentences: DynamicBlockOutputs) => {
    const { items } = startSentences;
    return items.map((item, index) => {
      const { component, required } = item;
      if (!dataForm?.inputs?.userSentences) return null;

      const currentSentence = dataForm?.inputs?.userSentences[index] || '';
      const multiline = component === 'multiline';
      return (
        <div key={index} style={{ marginTop: 16 }}>
          <TextField
            required={required}
            fullWidth
            multiline={multiline}
            minRows={multiline ? 3 : 1}
            variant={multiline ? 'outlined' : 'standard'}
            value={currentSentence}
            onChange={(e) => handleChangeStartMessage(e.target.value, index)}
          />
        </div>
      );
    });
  };

  const renderLogic = () => {
    if (!logic) return null;
    const { label } = logic;

    return (
      <>
        {
          Object.values(state.formErrors).includes(
            getTranslation('validations.outputTitleDuplicated')
          ) && (
            <Alert icon={false} severity='error'>
              {getTranslation('validations.outputTitleDuplicated')}
            </Alert>
          )
        }

        <JsEditor
          title={label}
          code={
            dataForm?.dataBlockly?.payload ||
            dataForm?.dataBlockly?.compiledPayload || ''
          }
          handleChange={handleChangeLogic}
          readOnly={dataForm?.metadata?.type === 'Notification'}
          setJsToEdit={setJsToEdit}
          setShowJsEditor={setShowJsEditor}
        />
      </>
    );
  };

  const renderApiReturnCode = () => {
    if (!apiReturnCode) return null;
    return (
      <JsEditor
        code={dataForm?.apiReturnCodeTreatment || ''}
        handleChange={handleChangeApiReturnCode}
        title="Código para tratamento de retorno"
        setJsToEdit={setJsToEdit}
        setShowJsEditor={setShowJsEditor}
      />
    );
  };

  const renderReturnVariable = () => {
    if (!returnVariable) return null;

    const { label, required, maxLength } = returnVariable;
    return (
      <div style={{ marginTop: 16 }}>
        <Typography variant="subtitle2">{label}</Typography>
        <TextField
          required={required}
          inputProps={{ maxLength }}
          fullWidth
          variant="standard"
          value={dataForm?.varValue}
          onChange={(e) => handleChangeVarValue(e.target.value)}
        />
      </div>
    );
  };

  const handleChangeCode = (value: string) => {
    if (dataForm?.metadata?.type === 'Api') {
      handleChangeApiReturnCode(value);
      return;
    }

    if (dataForm?.metadata?.type === 'Logic') {
      handleChangeLogic(value);
      return;
    }
  }

  return (
    <div style={{ marginBottom: '16px' }}>
      {name && renderProperty(name)}

      {description && renderProperty(description)}

      {sendUser && <SendUser sendUser={sendUser} />}

      {returnVariable && renderReturnVariable()}

      {logic && renderLogic()}

      {outputs && (
        <AirTalkContextProvider>
          <MetaBlocksOutputs outputs={outputs} />
        </AirTalkContextProvider>
      )}

      {group && block && <ToAnotherBlock />}

      {apiEntries && <ApiEntries entries={apiEntries} />}

      {apiReturnCode && renderApiReturnCode()}

      {startSentences && renderStartSentences(startSentences)}

      {bubbleEntries && <BubbleEntries entries={bubbleEntries} />}

      {ai123Entries && <AI123EntriesComponent entries={ai123Entries} />}

      <TextEditor
        code={jsToEdit}
        handleClose={handleChangeCode}
        showEditor={showJsEditor}
        setShowJsEditor={setShowJsEditor}
        readOnly={dataForm?.metadata?.type === 'Notification'}
      />
    </div>
  );
}
