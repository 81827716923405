import { InputAdornment, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { createRef, useEffect, useState } from 'react';
import './style.css';

type Props = {
  showModalVariables: boolean;
  setShowModalVariables: React.Dispatch<React.SetStateAction<boolean>>;
  definedPosition: boolean;
  setDefinedPosition: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: any;
  remoteVariables: { id: string; name: string }[];
};

export function ModalSearchVariables({
  showModalVariables,
  setShowModalVariables,
  definedPosition,
  setDefinedPosition,
  onClose,
  remoteVariables,
}: Props) {
  const contentRef = createRef<HTMLDivElement>();

  function onGetMousePosition(event: any) {
    const { target } = event;
    if (showModalVariables && !definedPosition)
      setModalVariablesPosition(contentRef.current, target, event);
  }

  function setModalVariablesPosition(
    modalVariables: HTMLDivElement | null,
    target: HTMLElement,
    event: any
  ) {
    const domRect = target.getBoundingClientRect();

    modalVariables?.setAttribute(
      'style',
      `top: ${domRect.y}px;
              left: ${event.pageX}px;
              position: fixed;
              z-index: 999;
              `
    );

    setDefinedPosition(true);
  }

  useEffect(() => {
    document.addEventListener('mouseup', onGetMousePosition);

    return () => {
      document.removeEventListener('mouseup', onGetMousePosition);
    };
  });

  const [type, setType] = useState('');

  function handleSetType(
    event: React.ChangeEvent<{
      name?: string | undefined;
      textContent: string;
    }>
  ) {
    setShowModalVariables(false);

    if (!!event.target.textContent) {
      const variableType = event.target.textContent;
      onClose(variableType);
    }
  }

  if (!showModalVariables) return <></>;

  return (
    <div ref={contentRef} className="OptionsDropdown">
      <Autocomplete
        disablePortal
        id="selectVariable"
        value={type}
        onClose={() => setType(type || '')}
        options={remoteVariables?.map((entity) => entity.name) || []}
        noOptionsText={`Sem resultados`}
        getOptionLabel={(option) => option}
        renderOption={(option, { selected }) => (
          <span className="OptionsDropdown-option">{option}</span>
        )}
        onChange={(event: any, newValue: string | null) => {
          event.stopPropagation();
          event.preventDefault();
          handleSetType(event);
        }}
        style={{ width: '300px', flex: 1 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Variáveis"
            placeholder={`Pesquise por variáveis`}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
