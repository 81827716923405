import Drawflow from 'drawflow';
import EIntentType from 'enums/EIntentType';
import { InputOutputObject, ItemDataFlow, Output } from 'models/DataFlow';

import metablocks from 'components/ModalOptions/metablocks.json';
import { MetaBlockType, MetadataBlock } from 'contexts/Flow/types';
import UpdateIntentController from 'controllers/intent/UpdateIntentController';
import DataFormModel from 'models/DataFormModel';

interface GptReturnOutput {
  destinationBlockId: string;
  condition?: string;
  conditionCode?: string;
}

interface GptReturnBlock {
  id: string;
  name: string;
  message: string;
  type: string;
  inputs: string[];
  outputs: GptReturnOutput[];
  description: string;
  variable?: string;
  blockDestination?: string;
  groupDestination?: string;
  url?: string;
  jsCode?: string;
}

const blocks = metablocks.blocks;
const separatedBlocks: { [key: string]: MetadataBlock } = {};

blocks.forEach((block) => {
  Object.assign(separatedBlocks, { [block.type]: block });
});

const idsReferences: { originalId: number; blockId: number }[] = [];

function blockAwaitsForUserInput(type: MetaBlockType) {
  return ['ValidateCPF', 'ValidadeCNPJ', 'Question'].includes(type);
}

function blockHasInput(type: MetaBlockType) {
  return type !== 'Entry';
}

function blockHasOutputs(type: MetaBlockType) {
  return type !== 'ToAnotherBlock';
}

function createNode(
  node: GptReturnBlock,
  intentController: UpdateIntentController
) {
  const nodeType = node.type as MetaBlockType;
  const metadata = getMetadaFromBlock(nodeType);
  const outputsAccordingToLength: InputOutputObject = {};
  node.outputs.forEach((_, index) => {
    Object.assign(outputsAccordingToLength, {
      ['output_' + (index + 1)]: { connections: [] },
    });
  });
  const outputs: InputOutputObject = blockHasOutputs(nodeType)
    ? outputsAccordingToLength
    : {};

  const newNode: ItemDataFlow = {
    name: 'dbclick',
    class: 'dbclick',
    html: '',
    pos_x: 0,
    pos_y: 0,
    typenode: false,
    inputs: blockHasInput(nodeType) ? { input_1: { connections: [] } } : {},
    outputs,
    id: 0,
    data: {
      description: node.description,
      name: node.name,
      metadata,
      intentType: EIntentType.MetadataBlock,
      groupId: '',
      sendUser: !blockAwaitsForUserInput(nodeType)
        ? { message: node.message }
        : undefined,
      outputs: Array.from(Object.keys(outputs)).map((_) => ({ title: '' })),
      apiInfo: {
        url: node.url ?? '',
        method: 'GET',
        body: '',
        headers: [],
        params: [],
        query: [],
      },
      linked: {
        groupId: node.groupDestination ?? '',
        intentId: node.blockDestination ?? '',
      },
      inputs: blockAwaitsForUserInput(nodeType)
        ? {
            variables: [
              {
                questions: [node.message ?? ''],
                defaultValue: '',
                isList: false,
                required: true,
                vartype: '@sys.any',
                name: node.variable ?? 'resposta',
                value: `$${node.variable ?? 'resposta'}`,
              },
            ],
          }
        : {
            variables: [],
            userSentences: metadata.type === 'Entry' ? ['#oi'] : [],
          },
      dataBlockly: {
        lastVersion: 1,
        payload: metadata.jsCode ?? node.jsCode ?? '',
        xml: '',
        compiledPayload: '',
      },
      varValue: `vars.${node.variable ?? 'resposta'}`,
      messageType: 'text',
			saveVarValue: metadata.usesOutputVar,
    },
  };

  const position = { x: 0, y: 0 };

  try {
    const lastIntentCreated = intentController.getLastIntentCreated();
    let mostToTheRightPosition = 0;

    if (!lastIntentCreated) {
      const blocks =
        intentController.getEditorFlow().drawflow.drawflow.Home.data;
      const blocksKeys = Object.keys(blocks);
      const positionsX = blocksKeys.map((key) => blocks[Number(key)].pos_x);

      mostToTheRightPosition = positionsX.length > 0 ? Math.max(...positionsX) : 0;
    }

    if (lastIntentCreated) {
      const lastIntentCreatedPosition = intentController.getPositionByNodeId(
        lastIntentCreated.id
      );

      Object.assign(position, lastIntentCreatedPosition);
      mostToTheRightPosition = lastIntentCreated.pos_x;
      newNode.data.block_id = String(lastIntentCreated.id);
    }

    const newNodeId = intentController.addNode(
      node.outputs.length,
      newNode.data,
      {
        x: mostToTheRightPosition + 400,
        y: position.y,
      }
    );

    intentController.updateIntent(
      { ...newNode.data, block_id: String(newNodeId) },
      Number(newNodeId)
    );

    idsReferences.push({
      originalId: Number(node.id),
      blockId: Number(newNodeId),
    });
  } catch (e) {
    console.log(e);
  }
}

function createConnections(
  node: GptReturnBlock,
  index: number,
  intentController: UpdateIntentController
) {
  try {
    const blockFlowId = idsReferences[index].blockId;
    const editorFlow = intentController.getEditorFlow();
    const blockToUpdate = editorFlow.getNodeFromId(blockFlowId);

    if (node.outputs.length === 0) {
      const outputs = getOutputsOfBlock(node);

      const blockDataForm = new DataFormModel({
        ...blockToUpdate.data,
        outputs,
      });

      intentController.updateIntent(blockDataForm, blockFlowId);

      return;
    }

    if (node.type === 'Api') {
      node.outputs.forEach((output: GptReturnOutput) => {
        const destinationBlockReference = idsReferences.find(
          (id) => id.originalId === Number(output.destinationBlockId)
        );
        if (destinationBlockReference) {
          const destinationBlockReferenceId = destinationBlockReference.blockId;
          const outputs = getOutputsOfBlock(
            node,
            String(destinationBlockReferenceId)
          );

          const blockDataForm = new DataFormModel({
            ...blockToUpdate.data,
            outputs,
          });

          intentController.updateIntent(blockDataForm, blockFlowId);
        }
      });

      return;
    }

    const outputs: Output[] = [];

    node.outputs.forEach((output: GptReturnOutput, outputIndex: number) => {
      const destinationBlockReference = idsReferences.find(
        (id) => id.originalId === Number(output.destinationBlockId)
      );
      if (destinationBlockReference) {
        const destinationBlockReferenceId = destinationBlockReference.blockId;

				const outputIndexPos = outputIndex === 0 ? '' :  outputIndex + 1;

        outputs.push({
          title: 'Saída' + String(outputIndexPos),
          outputid: String(destinationBlockReferenceId),
          ifCondition: output.condition ? output.condition : '',
          conditionCode: output.conditionCode ? output.conditionCode : '',
        });

        intentController.addConnection(
          blockFlowId,
          destinationBlockReferenceId,
          outputIndex
        );
      }
    });

    const blockDataForm = new DataFormModel({
      ...blockToUpdate.data,
      outputs,
    });

    intentController.updateIntent(blockDataForm, blockFlowId);
  } catch (error) {
    console.log(error);
  }
}

export default function GptToDrawflowFormatter(
  editorFlow: Drawflow,
  newNodes: any
) {
  const updateIntentController = new UpdateIntentController(editorFlow);
  const nodes = newNodes.nodes as GptReturnBlock[];

  nodes.forEach((node: GptReturnBlock) => {
    createNode(node, updateIntentController);
  });

  nodes.forEach((node: any, index: number) => {
    createConnections(node, index, updateIntentController);
  });

  const firstNodeOfNewNodesId = idsReferences[0].blockId;
  idsReferences.splice(0, idsReferences.length);

  return {
    updatedEditorFlow: updateIntentController.getEditorFlow(),
    firstNodeOfNewNodesId,
  };
}

function getMetadaFromBlock(blockType: MetaBlockType) {
  return separatedBlocks[blockType];
}

function getOutputsOfBlock(block: GptReturnBlock, outputid?: string) {
  const { type } = block;
  const metablock = separatedBlocks[type];
  const dataFormOutputs: Output[] = [];

  metablock.properties.groups.forEach((group) => {
    group.children.forEach((child) => {
      child.items.forEach((item) => {
        item.outputs?.items.forEach((outputItem, index) => {
          const currBlockOutput = block.outputs[index];
          const dataFormOutput = currBlockOutput
            ? {
                title: outputItem.title || 'Saída',
                outputid,
                ifCondition: currBlockOutput.condition
                  ? currBlockOutput.condition
                  : outputItem.statusCode
                  ? `resposta.statusCode == ${outputItem.statusCode}`
                  : '',
              }
            : {
                title: outputItem.title || 'Saída',
                outputid,
                ifCondition: outputItem.statusCode
                  ? `resposta.statusCode == ${outputItem.statusCode}`
                  : '',
              };
          dataFormOutputs.push(dataFormOutput);
        });
      });
    });
  });

  return dataFormOutputs;
}
