import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttTextField from 'components/Material/MttTextField/MttTextFIeld';
import MttTypography from 'components/Material/MttTypography/MttTypography';

export const Container = styled(MttGrid)`
  margin: 8px 42px 0;
`;

export const HeaderContent = styled(MttGrid)`
  margin-top: 8px;
`;

export const ContainerSwitch = styled.div``;

export const HeaderOptions = styled(MttGrid)`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledMttTextField = styled(MttTextField)``;

export const TitleTypography = styled(MttTypography).attrs({
  children: 'Exportar dados para planilha',
  variant: 'h6',
  color: 'primary',
})``;
