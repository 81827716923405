import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IGetSingleOrganizationResponse,
  IGetOrganizationsResponse,
  IUpdateOrganizationRequest,
  IUpdateOrganizationResponse,
  ISaveOrganizationRequest,
  ISaveOrganizationResponse,
  IGetOrganizationResponse,
} from './types';

export const GetOrganizations = async (
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetOrganizationsResponse>> => {
  const action = 'organizations';
  return await json<IGetOrganizationsResponse>('GET', action, dispatch);
};

export const GetOrganization = async (
  organizationId: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetOrganizationResponse>> => {
  const action = `organizations/${organizationId}`;
  return await json<IGetOrganizationResponse>('GET', action, dispatch);
};

export const GetSingleOrganization = async (
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetSingleOrganizationResponse>> => {
  const action = 'organizations';
  return await json<IGetSingleOrganizationResponse>('GET', action, dispatch);
};

export const SaveOrganization = async (
  request: ISaveOrganizationRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ISaveOrganizationResponse>> => {
  const action = 'organizations';
  return await json<ISaveOrganizationResponse>(
    'POST',
    action,
    dispatch,
    request.organization
  );
};

export const UpdateOrganization = async (
  request: IUpdateOrganizationRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IUpdateOrganizationResponse>> => {
  const action = `organizations/${request._id}`;
  return await json<IUpdateOrganizationResponse>(
    'PATCH',
    action,
    dispatch,
    request.organization
  );
};
