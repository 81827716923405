import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const GridContainer = styled(MttGrid)`
  margin: 16px;
`;

export const ContainerButton = styled(MttGrid)`
  display: flex;
  justify-content: space-between;

  padding: 8px;
`;

export const ContentGrid = styled(MttGrid)`
  width: calc(100% - 32px);

  padding: 16px;

  border-radius: 4px;

  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;

  overflow-y: auto;

	.MuiOutlinedInput-input {
    background: #fff;
  }
`;
