import React, { useEffect, useState } from 'react';
import {
  ContainerHeader,
  StyledSaveButtom,
  BoxWords,
  BoxHeader,
  DisplayLines,
  GridBackButton,
  TesteContainer,
  StyledBackButtom,
  ContainerButtom,
} from './styles';
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DefineWords from './DefineWords';
import AdvancedExpressions from './AdvancedExpressions';
import TextField from '@material-ui/core/TextField';
import { useEntity } from 'contexts/Entity/entityContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { SaveEntities } from 'services/EntityService';
import { EntityFormMask } from 'models/Entity';
import { useApp } from 'contexts/App/appContext';
import useTranslator from 'utils/hooks/Translator';

export default function NewVariable() {
  const botName = useFlow().state.botName || '';
  const { toastNotification } = useFlow();
  const { dispatch, state } = useEntity();
  const { selectedIEntity, errors, indexEdit } = state;
  const { dispatch: dispatchApp } = useApp();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [numberOfLines] = useState(1);
  const [loading, setLoading] = useState(false);
  const { getTranslation } = useTranslator();

  useEffect(() => {
    if (selectedIEntity.entitytype === 'words') {
      setShowAdvanced(false);
    } else {
      setShowAdvanced(true);
    }
  }, [selectedIEntity.entitytype]);

  const getLines = () => {
    let lines = [];
    for (let line = 0; line < numberOfLines; line += 1) {
      lines.push(
        <>
          {showAdvanced ? (
            <AdvancedExpressions key={`AE${line}`} />
          ) : (
            <DefineWords key={`DW${line}`} />
          )}
        </>
      );
    }
    return lines;
  };

  const handleSaveNewVariable = async () => {
    if (loading) return;

    setLoading(true);

    const { name } = state.selectedIEntity;
    const values = selectedIEntity.values.filter((v) => !!v.value.trim());
    if (!!name && !!values.length) {
      state.selectedIEntity.name = name;
      state.selectedIEntity.values = values;
      const entities = [...state.entities];

      const foundEntities = entities.filter((e) => e.name === name);
      if (foundEntities.length > 1) {
        toastNotification('error', 'Já existe uma variável com esse nome.');
        setLoading(false);
        return;
      }

      if (indexEdit === undefined) {
        entities.push({ ...state.selectedIEntity });
      } else {
        entities[indexEdit] = { ...state.selectedIEntity };
      }

      let result = await SaveEntities(
        {
          bot_name: botName,
          entities,
        },
        dispatchApp
      );

      if (result.Success) {
        dispatch({
          type: 'updateEntities',
          data: { ...state, screen: 'list', indexEdit: undefined },
        });
      } else {
        toastNotification(
          'error',
          result.Message || getTranslation('toast.error.saveVariable')
        );
      }
    } else {
      let errors: { [key: string]: string } = {};
      if (!name) {
        errors['name'] = `*${getTranslation('validations.variableName')}`;
      }
      if (!values.length) {
        errors['values'] =  `*${getTranslation('validations.variableValue')}`;
      }
      toastNotification('error', getTranslation('toast.error.oneOrMoreFieldsInvalid'));
      dispatch({
        type: 'updateEntities',
        data: {
          errors,
        },
      });
    }

    setLoading(false);
  };

  const handleSelectType = () => {
    selectedIEntity.entitytype = showAdvanced ? 'words' : 'expressions';
    if (selectedIEntity.entitytype === 'words') {
      state.valuesExpression = [...selectedIEntity.values];
      selectedIEntity.values = [...state.valuesWord];
    } else {
      state.valuesWord = [...selectedIEntity.values];
      selectedIEntity.values = [...state.valuesExpression];
    }
    dispatch({ type: 'updateEntities', data: { selectedIEntity } });
  };

  const handleChangeName = (e: any) => {
    const { value } = e.target;
    selectedIEntity.name = EntityFormMask.name(value);
    dispatch({
      type: 'updateEntities',
      data: { selectedIEntity, errors: { name: '' } },
    });
  };

  const handleBackToList = () => {
    dispatch({
      type: 'updateEntities',
      data: { screen: 'list', indexEdit: undefined },
    });
  };

  return (
    <>
      <ContainerHeader>
        <ContainerButtom>
          <StyledBackButtom variant="extended" onClick={handleBackToList}>
            <ArrowBackIcon />
          </StyledBackButtom>
        </ContainerButtom>
        <TextField
          label={getTranslation('variables.name')}
          variant="outlined"
          fullWidth={true}
          value={selectedIEntity.name}
          onChange={handleChangeName}
          error={!!errors['name']}
          helperText={errors['name']}
        />
        <StyledSaveButtom
          color="primary"
          variant="contained"
          onClick={handleSaveNewVariable}
        >
          {getTranslation('save')}
          {loading && <CircularProgress color="inherit" size={18} />}
        </StyledSaveButtom>
      </ContainerHeader>
      <BoxWords>
        <BoxHeader>
          <RadioGroup row value={showAdvanced} onChange={handleSelectType}>
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label={getTranslation('variables.defineWords')}
            />
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label={getTranslation('variables.defineRegex')}
            />
          </RadioGroup>
        </BoxHeader>

        <DisplayLines>
          {!!errors['values'] && (
            <Typography color="error" style={{ padding: '8px 16px' }}>
              {errors['values']}
            </Typography>
          )}
          <TesteContainer>{getLines()}</TesteContainer>
        </DisplayLines>
      </BoxWords>
      <GridBackButton></GridBackButton>
    </>
  );
}
