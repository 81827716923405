import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IGetBotsResponse,
  IGetBotsNamesResponse,
  IGetFlowRequest,
  IGetFlowResponse,
  IPublishBotHistoryRequest,
  IPublishBotHistoryResponse,
  IPublishBotRequest,
  ISaveBotRequest,
  ISaveBotResponse,
  ISemaphoreResponse,
  PublishBotResponse,
  IGetBotsGroupsRequest,
  IGetBotsGroupsResponse,
  IPublishBotBackupRequest,
  IGetPublishBackupResponse,
} from './types';

const baseUrl = 'bots';

export const GetBots = async (
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetBotsResponse>> => {
  return await json<IGetBotsResponse>('GET', baseUrl, dispatch);
};

export const GetBotsGroups = async (
  request: IGetBotsGroupsRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetBotsGroupsResponse>> => {
  const action = `${baseUrl}/${request.bot_name}/groups/names`;
  return await json<IGetBotsGroupsResponse>('GET', action, dispatch);
};

export const GetBotsNames = async (
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetBotsNamesResponse>> => {
  const action = `${baseUrl}/names`;
  return await json<IGetBotsNamesResponse>('GET', action, dispatch);
};

export const SaveBot = async (
  request: ISaveBotRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ISaveBotResponse>> => {
  return await json<ISaveBotResponse>('POST', baseUrl, dispatch, request);
};

export const GetFlows = async (
  request: IGetFlowRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetFlowResponse>> => {
  const action = `${baseUrl}/${request.bot_name}`;
  return await json<IGetFlowResponse>('GET', action, dispatch);
};

export const GetPublishBackup = async (
  bot_name: string,
  dispatch: React.Dispatch<IAppContextActions>,
  skip = '0',
  limit = '5'
): Promise<IBaseResponse<IGetPublishBackupResponse>> => {
  const action = `${baseUrl}/${bot_name}/backup?skip=${skip}&limit=${limit}`;
  return await json<IGetPublishBackupResponse>('GET', action, dispatch);
};

export const SavePublishBackup = async (
  request: IPublishBotBackupRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<any>> => {
  const action = `${baseUrl}/${request.botName}/backup`;
  return await json<any>('POST', action, dispatch, request);
};

export const PublishBot = async (
  request: IPublishBotRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<PublishBotResponse>> => {
  const action = `${baseUrl}/${request.bot_name}/publish`;
  //console.log("**** JSON para salvar na API Cris\n\n");
  //console.log(JSON.stringify(request,null,2));
  //console.log("****fim");
  return await json<PublishBotResponse>('POST', action, dispatch, request);
};

export const PublishHistory = async (
  request: IPublishBotHistoryRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IPublishBotHistoryResponse>> => {
  const action = `${baseUrl}/${request.bot_name}/publish_history`;
  return await json<IPublishBotHistoryResponse>('GET', action, dispatch);
};

export const Semaphore = async (
  semaphore_id: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ISemaphoreResponse>> => {
  const action = `semaphore/${semaphore_id}`;
  return await json<ISemaphoreResponse>('GET', action, dispatch);
};
