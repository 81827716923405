const apiURL = process.env?.REACT_APP_URL ?? ''

interface HandleFileUploadResponse {
  data: {
    url: string
  }
};

export async function getFileFromUrl(url: string, defaultType = 'image/jpeg') {
  const name = 'headerImage';
  const response = await fetch(url);
  const data = await response.blob();

  return new File([data], name, {
    type: data.type || defaultType,
  });
};

export function getFormData(file: File) {
  const formData = new FormData();

  formData.append('file', file);

  return formData;
};

export async function handleTemplateFileUpload(file: File, botName: string): Promise<HandleFileUploadResponse> {
  const formData = getFormData(file);

  const url = apiURL.concat(`bots/${botName}/templates/upload`)

  const token = localStorage.getItem('token')

  const resp = await fetch(url, {
    method: 'POST',

    headers: {
      'Authorization': token ?? '',
    },

    body: formData,
  });

  return await resp.json();
};

export async function handleActioningFileUpload(file: File, botName: string): Promise<HandleFileUploadResponse> {
  const formData = getFormData(file);

  const url = apiURL.concat(`bots/${botName}/actionings/upload`);

  const token = localStorage.getItem('token');

  const resp = await fetch(url, {
    method: 'POST',

    headers: {
      'Authorization': token ?? '',
    },

    body: formData,
  });

  return await resp.json();
};

export async function handle123FalaFileUpload(
  file: File, 
  botName: string,
  contentGroupId: string,
): Promise<HandleFileUploadResponse> {
  const formData = getFormData(file);

  const url = apiURL.concat(`${botName}/${contentGroupId}/upload`);

  const token = localStorage.getItem('token');

  const resp = await fetch(url, {
    method: 'POST',

    headers: {
      'Authorization': token ?? '',
    },

    body: formData,
  });

  return await resp.json();
};