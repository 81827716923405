import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import LinearProgress, {
  LinearProgressProps,
} from '@material-ui/core/LinearProgress';
import usePublishBot from 'utils/hooks/publishBotHook';
import { useStyles, Container } from './styles';
import useAgents from 'utils/hooks/useAgents';
import { useFlow } from 'contexts/Flow/flowContext';

export default function PublishProgressBar() {
  const { drawflowToPublish } = useFlow().state;
  const publishBotHook = usePublishBot();
  const { finishPublish } = useAgents();
  const classes = useStyles();

  const publish = useCallback(() => {
    if (drawflowToPublish) {
			const drawflow = drawflowToPublish.drawflowToPublish;
      publishBotHook.publishBot(drawflow, drawflow.bot_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawflowToPublish]);

  useEffect(() => {
    publish();
  }, [publish]);

  useEffect(() => {
    if (publishBotHook.getConnectionStatus() === 3) {
      const msg = publishBotHook.getMessage();
      const progress = publishBotHook.getProgress();

      if (progress >= 100) {
        finishPublish(true, msg);
      } else {
        finishPublish(false, msg);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishBotHook]);

  return (
    <Box>
      <LinearProgressWithLabel
        value={publishBotHook.getProgress()}
        className={classes.progressBar}
      />
    </Box>
  );
}

function LinearProgressWithLabel(
  props: JSX.IntrinsicAttributes & LinearProgressProps
) {
  const classes = useStyles();

  return (
    <Box className={classes.firstBox}>
      <Box className={classes.secondBox}>
        <Container>
          <LinearProgress
            className={classes.progressBar}
            variant="determinate"
            {...props}
            value={props.value}
          />
        </Container>
      </Box>
      <Box>
        <Typography className={classes.progressValue}>{`${Math.trunc(
          props.value || 0
        )} %`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};
