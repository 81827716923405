import { IconButton, Menu, Typography } from '@material-ui/core';
import { useFlow } from 'contexts/Flow/flowContext';
import { useCallback, useEffect, useState } from 'react';

import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneIcon from '@material-ui/icons/Phone';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FintalkIcon from '../../Icons/fintalk.png';

import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { GetKeys } from 'services/AccessKeysService';
import { Key } from 'services/AccessKeysService/types';
import useTranslator from 'utils/hooks/Translator';
import { ListContainer } from './styles';

interface FlowsUrlsProps {
  handleCloseUrlsTests: () => void;
  openFlowTest: boolean;
  anchorElFlow: any;
  openFalazap: () => void;
}

export default function FlowsUrls(props: FlowsUrlsProps) {
  const { state, dispatch, toastNotification } = useFlow();
  const { currentData } = useFintalkCloud();
  const { dispatch: dispatchApp } = useApp();
  const [falazapIframeElement, setFalazapIframeElement] =
    useState<HTMLElement | null>(null);
  const { anchorElFlow, openFlowTest, handleCloseUrlsTests, openFalazap } =
    props;

  const { getTranslation } = useTranslator();

  const updateStateKeys = useCallback(
    (keys: Key[]) => {
      const urlFalazap = keys.find((resKey) => {
        return resKey.key === 'url-falazap' ? resKey.value : '';
      });
      const urlFacebook = keys.find((resKey) => {
        return resKey.key === 'url-facebook' ? resKey.value : '';
      });
      const urlTelegram = keys.find((resKey) => {
        return resKey.key === 'url-telegram' ? resKey.value : '';
      });
      const urlWhatsApp = keys.find((resKey) => {
        return resKey.key === 'url-whatsapp' ? resKey.value : '';
      });
      const urlTwitter = keys.find((resKey) => {
        return resKey.key === 'url-twitter' ? resKey.value : '';
      });

      const botsUrls = {
        urlFalazap: urlFalazap?.value || '',
        urlFacebook: urlFacebook?.value || '',
        urlTelegram: urlTelegram?.value || '',
        urlTwitter: urlTwitter?.value || '',
        urlWhatsApp: urlWhatsApp?.value || '',
      };

      dispatch({ type: 'updateForm', data: { botsUrls } });
    },
    [dispatch]
  );

  useEffect(() => {
    let isMounted = true

    const getKeys = async () => {
      if (currentData.agentName) {
        const result = await GetKeys(currentData.agentName, dispatchApp);
        if (result.Success) {
          if (result.Data.data) {
            const keys = Object.keys(result.Data.data.keys).sort((a, b) =>
              a.localeCompare(b, undefined, { sensitivity: 'base' })
            );
            if (keys) {
              let values: Key[] = [];
              for (const key of keys) {
                const element = result.Data.data.keys[key as any];
                values.push({ key, value: element });
              }

              if (isMounted) {
                updateStateKeys(values);
              }
            }
          }
        }
      }
    };
    getKeys();

    return () => {
      isMounted = false
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData.agentName, state.openModalAccessKeys, updateStateKeys]);

  const handleOpenLink = (url?: string) => {
    if (url) {
      try {
        const validUrl = new URL(url);
        window.open(validUrl, '_blank');
      } catch (_) {
        toastNotification('error', getTranslation('toast.error.invalidUrl'));
        return false;
      }
    }
  };

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorElFlow}
      open={openFlowTest}
      onClose={handleCloseUrlsTests}
      PaperProps={{
        style: {
          marginTop: '56px',
          marginLeft: '-8px',
          background: 'rgb(255, 255, 255)',
          width: '148px',
        },
      }}
    >
      <ListContainer>
        <IconButton
          disabled={!state.botsUrls.urlFalazap}
          aria-label="filter-list"
          onClick={() => {
            if (!falazapIframeElement) {
              const iframe = document.getElementById('falazap-iframe');
              if (iframe) {
                iframe.setAttribute('src', state.botsUrls.urlFalazap + '');
                setFalazapIframeElement(iframe);
              }
            } else
              falazapIframeElement.setAttribute(
                'src',
                state.botsUrls.urlFalazap + ''
              );

            handleCloseUrlsTests();
            openFalazap();
          }}
        >
          <img src={FintalkIcon} style={{ width: '20px' }} alt="falazapIcon" />
          <Typography>FalaZap</Typography>
        </IconButton>

        <IconButton
          disabled={!state.botsUrls.urlTelegram}
          aria-label="filter-list"
          onClick={() => handleOpenLink(state.botsUrls.urlTelegram)}
        >
          <TelegramIcon />
          <Typography variant="subtitle1">Telegram</Typography>
        </IconButton>

        <IconButton
          disabled={!state.botsUrls.urlWhatsApp}
          aria-label="filter-list"
          onClick={() => handleOpenLink(state.botsUrls.urlWhatsApp)}
        >
          <WhatsAppIcon />
          <Typography variant="subtitle1">WhatsApp</Typography>
        </IconButton>
        <IconButton
          disabled={true}
          aria-label="filter-list"
          onClick={() => handleOpenLink(state.botsUrls.urlTwitter)}
        >
          <TwitterIcon />
          <Typography variant="subtitle1">Twitter</Typography>
        </IconButton>
        <IconButton
          disabled={true}
          aria-label="filter-list"
          onClick={() => handleOpenLink(state.botsUrls.urlFacebook)}
        >
          <FacebookIcon />
          <Typography variant="subtitle1">Messenger</Typography>
        </IconButton>
        <IconButton
          disabled={true}
          aria-label="filter-list"
          onClick={() => handleOpenLink(state.botsUrls.urlFacebook)}
        >
          <PhoneIcon />
          <Typography variant="subtitle1">URA</Typography>
        </IconButton>
      </ListContainer>
    </Menu>
  );
}
