import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Info from 'components/inputs/Material/Info/index';
import MessageForUser from 'components/inputs/Material/MessageForUser/index';
import { FormFieldsContainer } from '../Shared/styles';

export default function Welcome() {
  return (
    <>
      <FormFieldsContainer>
        <Info fieldsLock={['name', 'description']} />
        {/* <Sentences /> */}
        <MessageForUser />
        {/* <TypeOfMessage /> */}
      </FormFieldsContainer>
      <FooterButtons showLogicButton />
    </>
  );
}
