import { Grid, withTheme } from '@material-ui/core';
import MttButton from 'components/Material/MttButton/MttButton';
import styled from 'styled-components';

export const ButtonContainer = withTheme(styled(Grid)`
  padding: 12px 24px;
  display: flex;
  justify-content: flex-end;

  background-color: ${props => props.theme.palette.primary.main};

  .MuiCircularProgress-colorPrimary {
    color: #f8f8f24f;
    margin-left: 8px;
  }
`);

export const Button = styled(MttButton)`
  height: 50px;
`;

export const ProjectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
