import { IAppContextActions } from "contexts/App/types";
import { json } from "services/RequestService";
import { IBaseResponse } from "services/RequestService/Response";
import {
  ContentGroupSchema,
  ICreateContentGroupRequest,
  ICreateContentGroupResponse,
  IGetContentGroupListResponse,
  IGetDataSourceListResponse,
  IGetPreSignedURLRequest,
  IGetPreSignedURLResponse,
  IUpdateContentGroupRequest
} from "./types";

export const CreateContentGroup = async (
  request: ICreateContentGroupRequest,
  bot_name: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ICreateContentGroupResponse>> => {
  const action = `bots/${bot_name}/content-group`;
  return json<ICreateContentGroupResponse>('POST', action, dispatch, request);
};

export const UpdateContentGroup = async (
  request: IUpdateContentGroupRequest,
  bot_name: string,
  contentGroupId: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ICreateContentGroupResponse>> => {
  const action = `bots/${bot_name}/content-group/${contentGroupId}`;
  return json<ICreateContentGroupResponse>('PUT', action, dispatch, request);
};

export const DeleteContentGroup = async (
  bot_name: string,
  contentGroupId: string,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `bots/${bot_name}/content-group/${contentGroupId}`;
  return json('DELETE', action, dispatch);
};

export const GetPreSignedURL = async (
  request: IGetPreSignedURLRequest,
  bot_name: string,
  contentGroupId: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetPreSignedURLResponse>> => {
  const action = `bots/${bot_name}/${contentGroupId}/upload`;
  return json<IGetPreSignedURLResponse>('POST', action, dispatch, request);
};

export const GetContentGroupById = async (
  contentGroupId: string,
  bot_name: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<ContentGroupSchema>> => {
  const action = `bots/${bot_name}/content-group/${contentGroupId}`;
  return json<ContentGroupSchema>('GET', action, dispatch);
};

export const GetContentGroupList = async (
  bot_name: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetContentGroupListResponse>> => {
  const action = `bots/${bot_name}/content-groups`;
  return json<IGetContentGroupListResponse>('GET', action, dispatch);
};

export const GetDataSourceList = async (
  bot_name: string,
  contentGroupId: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetDataSourceListResponse>> => {
  const action = `bots/${bot_name}/${contentGroupId}/documents`;
  return json<IGetDataSourceListResponse>('GET', action, dispatch);
};

export const DeleteDataSource = async (
  bot_name: string,
  dataSourceId: string,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `bots/${bot_name}/documents/${dataSourceId}`;
  return json('DELETE', action, dispatch);
};