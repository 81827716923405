import {
  IUserSentence,
  IUserSentenceParameter,
} from 'components/inputs/Material/Sentences/types';
import { Variable } from 'models/DataFlow';
import { arrayClone } from '../ArrayFunctions';
import { REG_EXP_NOTATION, REG_EXP_SPAN } from './regExps';

export function mapperParametersFromSentencePieces(
  sentencePieces: RegExpMatchArray
) {
  return sentencePieces.map((piece) => mapperParameterFromSentencePiece(piece));
}

export function mapperParameterFromSentencePiece(
  piece: string
): IUserSentenceParameter {
  const parsedPiece = piece.split('](');
  const resolvedValuePiece = parsedPiece.shift() ?? '';
  const entityValues = parsedPiece.shift() ?? '';
  const entityPieces = entityValues.split(':');
  const parameterName = entityPieces.shift() ?? '';
  const entityName = entityPieces.shift() ?? '';

  return {
    name: parameterName,
    entity: entityName.replace(')', ''),
    resolvedValue: resolvedValuePiece.replace('[', ''),
  };
}

export function addVariableFromParameter(
  parameter: IUserSentenceParameter,
  variables: Variable[]
) {
  if (!variables.some((item) => item.vartype === parameter.entity)) {
    variables.push({
      name: parameter.name,
      vartype: parameter.entity,
      value: parameter.entity,
      questions: ['', '', ''],
      isList: false,
      required: false,
      defaultValue: '',
    });
  }
}

export function mapperDataFormInputsVariables(
  dataUserSentences: string[],
  dataVariables: Variable[]
) {
  const userSentences = arrayClone<string>(dataUserSentences);
  const variables = arrayClone<Variable>(dataVariables);

  userSentences.forEach((userSentence) => {
    const pieces = userSentence?.match(REG_EXP_NOTATION);
    if (Array.isArray(pieces)) {
      const parameters = mapperParametersFromSentencePieces(pieces);
      parameters.forEach((parameter) => {
        addVariableFromParameter(parameter, variables);
      });
    }
  });

  return variables;
}

export function createUserSentenceFromSentence(
  userSentence: string,
  variables: Variable[]
): IUserSentence {
  const pieces = userSentence?.match(REG_EXP_NOTATION);
  let parameters: IUserSentenceParameter[] = [];
  let replacedPhrase = userSentence;

  if (Array.isArray(pieces)) {
    parameters = pieces.map((piece) => {
      const parameter = mapperParameterFromSentencePiece(piece);
      addVariableFromParameter(parameter, variables);
      const colorIndex = variables.findIndex(
        (variable) => variable.vartype === parameter.entity
      );

      const toReplaced = `<span class="color${colorIndex}">${parameter.resolvedValue}</span>`;
      replacedPhrase = replacedPhrase.replace(piece, toReplaced);

      return parameter;
    });
  }

  return {
    phrase: userSentence,
    replacedPhrase: replacedPhrase,
    parameters: parameters,
  };
}

export function replaceSpanToNotation(
  editedPhrase: string,
  variables: Variable[]
) {
  let phrase = editedPhrase;

  const pieces = editedPhrase.match(REG_EXP_SPAN);

  if (Array.isArray(pieces)) {
    pieces.forEach((piece) => {
      let resolvedValue = piece.replace(/<span class="color[0-9]">/gi, '');

      resolvedValue = resolvedValue.replace(/<\/span>/gi, '');

      let colorPieces = piece.match(/color[0-9]/i);

      if (Array.isArray(colorPieces)) {
        const colorIndex = colorPieces[0].replace('color', '') ?? undefined;
        const variable = variables[parseInt(colorIndex)] ?? undefined;
        const pieceReplaced = `[${resolvedValue}](${variable?.name}:${variable?.vartype})`;
        phrase = phrase.replace(piece, pieceReplaced);
      }
    });
  }

  return phrase;
}

export function convertRemoteVariable(type: { id: string; name: string }) {
  if (type.id.substring(0, 1) !== '@') {
    const insertAt = `${type.id.slice(0, 0)}${'@'}${type.id.slice(0)}`;
    let filteredValue = insertAt;
    type.id = filteredValue;
  }
  return type;
}
