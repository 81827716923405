import styled from "styled-components";
import MttGrid from "components/Material/MttGrid/MttGrid";

export const StyledDeleteGrid = styled(MttGrid)<{size?: number}>`
  width: ${(props) => props.size ? `${props.size}px` : '40px'};
  height: ${(props) => props.size ? `${props.size}px` : '40px'};
  color: #ffffff;
  background-color: #6C50A4;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  :hover{
    background-color: #4b3872;
  }

  cursor: pointer;
`;