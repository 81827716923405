import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const SpainFlagIcon: React.FC<ISVGProps> = (props) => {
  const { size } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg2"
      viewBox="0 0 768 768"
      version="1.1"
      width={size || 20}
      height={size || 20}
    >
      <defs id="defs4">
        <clipPath id="clipPathFlag" clipPathUnits="userSpaceOnUse">
          <rect
            id="rect3918"
            fillOpacity=".67"
            ry="57"
            height="511"
            width="511"
            y=".5"
            x=".5"
          />
        </clipPath>
        <linearGradient id="linearGradient3852-0">
          <stop id="stop3854-6" stopColor="#dbe3db" offset="0" />
          <stop
            id="stop3856-1"
            stopColor="#dbe3db"
            stopOpacity="0"
            offset="1"
          />
        </linearGradient>
        <linearGradient
          id="linearGradient5122"
          x1="342.19"
          xlinkHref="#linearGradient3852-0"
          gradientUnits="userSpaceOnUse"
          y1="705.76"
          gradientTransform="matrix(1.1571 0 0 1.3167 -91.204 -253.99)"
          x2="358.08"
          y2="523.41"
        />
        <linearGradient
          id="linearGradient5125"
          x1="405.26"
          xlinkHref="#linearGradient3852-0"
          gradientUnits="userSpaceOnUse"
          y1="118.06"
          gradientTransform="matrix(.95303 0 0 .86647 11.575 -4.5016)"
          x2="405.75"
          y2="360.92"
        />
        <filter id="filter5740">
          <feGaussianBlur id="feGaussianBlur5742" stdDeviation="5.12" />
        </filter>
        <clipPath id="clipPath5796" clipPathUnits="userSpaceOnUse">
          <rect
            id="rect5798"
            ry="57"
            height="512"
            width="512"
            stroke="#00fa00"
            y="0"
            x="0"
            strokeWidth="20"
            fill="none"
          />
        </clipPath>
        <filter id="filter5912">
          <feGaussianBlur id="feGaussianBlur5914" stdDeviation="17.92" />
        </filter>
      </defs>
      <g id="template-button">
        &gt;
        <g id="00" transform="translate(90,90)">
          <rect
            id="shadow"
            ry="57"
            height="512"
            filter="url(#filter5912)"
            width="512"
            stroke="#a8a8a8"
            y="56"
            x="38"
            strokeWidth="5"
          />
        </g>
        <g
          id="01"
          transform="translate(128,128)"
          clipPath="url(#clipPathFlag)"
        >
          <rect
            id="mask"
            ry="57"
            height="512"
            width="512"
            y="0"
            x="0"
            fill="#00f"
          />
          <g id="flag" transform="matrix(.68753 0 0 1.0318 .053975 -.71635)">
            <rect
              id="rect171"
              fillRule="evenodd"
              transform="matrix(.74458 0 0 .74436 -.020050 .69434)"
              height="666.66"
              width="1e3"
              y="-.000031"
              x="0"
              strokeWidth="1pt"
              fill="#c00"
            />
            <rect
              id="rect403"
              fillRule="evenodd"
              transform="matrix(.74458 0 0 .74436 -2.6314 102.2)"
              height="333.34"
              width="1000.2"
              y="30.288"
              x="3.4286"
              strokeWidth="1pt"
              fill="#ff3"
            />
          </g>
        </g>
        <g id="02" transform="translate(0,38)">
          <path
            id="path3862-8"
            style={{ color: '#000000' }}
            fill="url(#linearGradient5125)"
            d="m214.21 111.21c-45.245 0.18809-68.716 7.7308-64.094 83.553l2.8252 46.345c47.474 5.5124 56.664 18.445 104.36 21.914 68.073 4.9513 67.937 5.4209 130.56-14.384 62.628-19.805 5.8248-65.247 207.32-57.82 4.6033 0.16968 17.314-3.0527 27.462-12.15 5.5817-46.524-2.7842-69.143-66.934-68.876z"
          />
          <path
            id="path3079-9"
            style={{ color: '#000000' }}
            fill="url(#linearGradient5122)"
            d="m531.03 495.17c-31.605 19.022-27.601 31.26-37.913 32.573-94.704 12.053-145.98-0.72701-165.8-12.061-59.588-34.078-68.468-13.014-100.22-16.574-20.115-2.2551-59.245 12.984-73.388 44.344 3.4722 27.608 18.111 32.098 44.281 31.986l356.06-1.5268c69.692-0.29885 56.335-8.4926 65.543-56.039-14.772-21.671-13.157 9.109-44.224-30.717z"
          />
          <rect
            id="rect5734"
            transform="translate(128,90)"
            clipPath="url(#clipPath5796)"
            ry="57"
            height="512"
            filter="url(#filter5740)"
            width="512"
            stroke="#000"
            y="0"
            x="0"
            strokeWidth="20"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
};

export default SpainFlagIcon;
