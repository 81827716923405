import { ItemDataFlow } from 'models/DataFlow';
import insertInPayloadStart from './inserInPayloadStart';

interface Flow {
  drawflow: { drawflow: { Home: { data: ItemDataFlow[] } } };
}

export default function tagsCompiler(flows: any) {
  flows.forEach((flow: Flow) => {
    const blocks: ItemDataFlow[] = flow.drawflow.drawflow.Home.data;

    Object.keys(blocks).forEach((block) => {
      const blockObj = blocks[Number(block)];
      if (blockObj.data.tags && blockObj.data.tags.length > 0) {
        const validTags = blockObj.data.tags?.filter((tag) => !!tag);
        if (!blockObj.data.dataBlockly) return;

        validTags.forEach((tag) => {
          const tagFunction = `tag({ name: '${tag}' });`;

          blockObj.data.dataBlockly!.compiledPayload = insertInPayloadStart(
            blockObj.data.dataBlockly!.compiledPayload,
            tagFunction
          );
        });
      }
    });
  });
}
