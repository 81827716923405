import React, { createContext, useContext, useReducer } from 'react';
import {
  Company,
  CompanyProject,
  ICompanyContext,
  initialState,
  Props,
} from './types';
import { companyReducer } from './companyReducer';

const CompanyContext = createContext<ICompanyContext>({} as ICompanyContext);

export function CompanyProvider({ children }: Props) {
  const [state, dispatch] = useReducer(companyReducer, initialState);

  function setCompany(company: Company, updateCopy = false) {
    dispatch({ type: 'setCompany', data: { company } });
    if (updateCopy)
      dispatch({ type: 'setCompanyCopy', data: { companyCopy: company } });
  }

  function setProject(project: CompanyProject, updateCopy = false) {
    dispatch({ type: 'setProject', data: { project } });
    if (updateCopy)
      dispatch({ type: 'setProjectCopy', data: { projectCopy: project } });
  }

  return (
    <CompanyContext.Provider
      value={{
        state,
        dispatch,
        setCompany,
        setProject,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}

export function useCompanies() {
  const context = useContext<ICompanyContext>(CompanyContext);
  return context;
}
