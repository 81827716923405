import React from 'react';
import { useForm } from 'contexts/Form/formContext';
import { SendUser } from 'models/DataFlow';
import * as S from './styles';

export default function MessageForUser() {
  const { state, dispatch } = useForm();
  const { dataForm } = state;

  const handleChangeInput = (
    key: keyof SendUser,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (dataForm) {
      const value = e.target.value;

      dataForm.sendUser = dataForm.sendUser
        ? dataForm.sendUser
        : { message: '', url: '' };

      dataForm.sendUser[key] = value;
      dispatch({
        type: 'updateForm',
        data: { dataForm: { ...dataForm } },
      });
    }
  };

  return (
    <S.Container>
      <S.TitleTypography />
      <S.HeaderContent>
        <S.HeaderOptions>
          <S.MessageInputContainer>
            <S.StyledMttTextField
              variant="outlined"
              label="Mensagem"
              fullWidth
              value={dataForm?.sendUser?.message || ''}
              onChange={(e) => handleChangeInput('message', e)}
              maxRows={3}
              multiline
            />
          </S.MessageInputContainer>
          <S.StyledMttTextField
            variant="outlined"
            label="URL da Imagem"
            fullWidth
            value={dataForm?.sendUser?.url || ''}
            onChange={(e) => handleChangeInput('url', e)}
          />
        </S.HeaderOptions>
      </S.HeaderContent>
    </S.Container>
  );
}
