import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IGetAdminDataResponse,
  IUpdateAdminDataRequest,
  IUpdateAdminDataResponse,
} from './types';

export const GetAdminData = async (
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetAdminDataResponse>> => {
  const action = 'admin';
  return await json<IGetAdminDataResponse>('GET', action, dispatch);
};

export const UpdateAdminData = async (
  request: IUpdateAdminDataRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IUpdateAdminDataResponse>> => {
  const action = 'admin';
  return await json<IUpdateAdminDataResponse>(
    'PATCH',
    action,
    dispatch,
    request
  );
};
