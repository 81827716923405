import { emptyFlow } from 'contexts/Flow/staticData';
import { INewGroup, newGroupValidation } from 'models/NewGroup';
import { UpdateGroup, SaveGroup } from 'services/FlowService';
import { DataFlow, ItemDataFlow } from 'models/DataFlow';
import { Props } from './types';
import { SaveFlexBlock } from 'services/FlexBlocksService';

export default async function CreateAppGroup({
  dataForm,
  state,
  toastNotification,
  dispatchApp,
  dispatch,
  getTranslation,
}: Props) {
  let newGroup: INewGroup = {
    name: '',
    description: '',
    unidBus: '',
  };

  const groupName = `flex-${dataForm?.name}`;
  newGroup = {
    name: groupName,
    description: dataForm?.description || '',
    unidBus: '',
  };
  const { isValid } = await newGroupValidation(newGroup);

  if (!isValid) return;

  let groups = state.groupsNames;
  const groupExist = !!groups.find(
    (g) => g.toLowerCase() === newGroup.name.toLowerCase()
  );

  if (groupExist) {
    newGroup.error = getTranslation('validations.groupAlreadyExists');
    return;
  }

  const bots = [...state.botsNames];
  const bot = state.botName;
  const drawflow = emptyFlow;

  const resultFlows = await SaveGroup(
    {
      bot_name: bot || '',
      group_name: newGroup.name,
      request: {
        groups: [
          {
            drawflow,
            groupId: newGroup.name,
            groupName: newGroup.name,
            bot: bot || '',
          },
        ],
      },
    },
    dispatchApp
  );

  if (resultFlows.Success) {
    const data: DataFlow = {};
    const blocks = state.editorFlow?.drawflow.drawflow.Home.data;
    const blocksKeys = Object.keys(blocks);
    const lastBlockId = Number(blocksKeys[blocksKeys.length - 1]);
    const currentIdPlusOne = lastBlockId + 1;

    const appBlock: ItemDataFlow = blocks[lastBlockId];

    appBlock.data.dataBlockly = {
      compiledPayload: `event('${groupName}_${dataForm.entryName}')`,
      lastVersion: 0,
      payload: '',
      xml: '',
    };

    data[currentIdPlusOne] = {
      id: currentIdPlusOne,
      name: 'dbclick',
      data: {
        dataBlockly: {
          compiledPayload: '',
          lastVersion: 0,
          payload: '// #PARSER#CONNECTOR#Saída#\n',
          xml: '',
        },
        name: `${dataForm.entryName}`,
        description: dataForm.description,
        intentType: 302,
        listType: 1,
        groupId: newGroup.name,
      },
      class: 'dbclick',
      html: '',
      typenode: false,
      inputs: {
        // input_1: {
        //   connections: [],
        // },
      },
      outputs: {
        output_1: {
          connections: [],
        },
      },
      pos_x: 0,
      pos_y: 0,
    } as ItemDataFlow;

    dataForm.outputs?.forEach((output, index) => {
      const currIndexPlusOne = index + 1;

      data[currentIdPlusOne + currIndexPlusOne] = {
        id: currentIdPlusOne + currIndexPlusOne,
        name: 'dbclick',
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '// #PARSER#CONNECTOR#Saída#',
            xml: '',
          },
          name: output.title,
          description: `Nome do App: ${newGroup.name}\nSaída #${currIndexPlusOne}`,
          intentType: 301,
          listType: 1,
          groupId: newGroup.name,
          block_id: output.block_id,
        },
        class: 'dbclick',
        html: '',
        typenode: false,
        inputs: {
          input_1: {
            connections: [],
          },
        },
        outputs: {},
        pos_x: 0,
        pos_y: currIndexPlusOne * 150,
      } as ItemDataFlow;
    });

    Promise.all([
      await UpdateGroup(
        {
          bot_name: bot || '',
          group_name: newGroup.name,
          blocks: { drawflow: { Home: { data } } },
        },
        dispatchApp
      ),
      await SaveFlexBlock(
        {
          bot_name: bot || '',
          block_name: dataForm.name,
          groups_used: [
            {
              group_name: dataForm.groupId,
              block_id: lastBlockId,
            },
          ],
        },
        dispatchApp
      ),
    ]);

    groups.push(newGroup.name);

    const principalIndex = groups.findIndex((group) => group === 'principal');

    const principal = groups[principalIndex];

    if (principalIndex >= 0) {
      groups.splice(principalIndex, 1);
      groups = [principal, ...groups];
    }

    dispatch({
      type: 'updateForm',
      data: {
        botsNames: [...bots],
        groupsNames: groups,
      },
    });
  } else {
    toastNotification(
      'error',
      resultFlows.Message || getTranslation('toast.error.saveGroup')
    );
  }
}
