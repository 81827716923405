import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Info from 'components/inputs/Material/Info/index';
import MessageForUser from 'components/inputs/Material/MessageForUser/';
import MultiVars from 'components/inputs/Material/MultiVariables';
import { FormFieldsContainer } from '../Shared/styles';

export default function Advanced() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <MessageForUser />
        <MultiVars
          showDelete={true}
          showQuestions={true}
          indexsRequired={[0]}
        />
      </FormFieldsContainer>
      <FooterButtons />
    </>
  );
}
