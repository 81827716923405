import * as yup from "yup";
import { TFormErrors } from "../contexts/Form/types";
import { IResultError } from "./ResultError";

export interface ToNewIntention {
  groupId: string;
  intentId: string;
}

export const toNewIntentionValidation = async (toNewIntention: ToNewIntention) => {
  const groupIdResult = await groupIdValidator(toNewIntention.groupId);
  const groupIsValid = groupIdResult.isValid;
  const intentIdResult = await intentIdValidator(toNewIntention.intentId);
  const intentIsValid = intentIdResult.isValid;
  const isValid = groupIsValid && intentIsValid;
  const errors = { ...groupIdResult.errors, ...intentIdResult.errors };
  return { isValid, errors };
};

const groupIdValidator = async (groupId: string) => {
  const schema = yup.object().shape({
    groupId: yup.string().required("*Grupo obrigatório."),
  });

  const promiseResult = new Promise<IResultError<TFormErrors>>((resolve) => {
    schema
      .validate({ groupId }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { 'linked.groupId': "" } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { 'linked.groupId': err.errors[0] } });
      });
  });
  return await promiseResult;
};

const intentIdValidator = async (intentId: string) => {
  const schema = yup.object().shape({
    intentId: yup.string().required("*Bloco obrigatório."),
  });

  const promiseResult = new Promise<IResultError<TFormErrors>>((resolve) => {
    schema
      .validate({ intentId }, { abortEarly: false })
      .then(() => {
        resolve({ isValid: true, errors: { 'linked.intentId': "" } });
      })
      .catch((err) => {
        resolve({ isValid: false, errors: { 'linked.intentId': err.errors[0] } });
      });
  });
  return await promiseResult;
};