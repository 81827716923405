import React, { useEffect } from 'react';
import { useEntity } from 'contexts/Entity/entityContext';
import { GridChildren } from 'views/Canvas/Builder/MainComponent/styles';
import NewVariable from '../NewVariable';
import VariableEntities from '../VariableEntities';
import { useFlow } from 'contexts/Flow/flowContext';
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close } from '@material-ui/icons';
import { ContainerDialog } from './styles';
import useTranslator from 'utils/hooks/Translator';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Main: React.FC = () => {
  const { getTranslation } = useTranslator();
  const { setBuilderElementToOpen } = useFintalkCloud();
  const { state } = useEntity();
  const { stopEditing } = useFlow();

  useEffect(() => {
    return () => {
      stopEditing();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderScreen = () => {
    switch (state.screen) {
      case 'list':
        return <VariableEntities />;
      case 'add':
        return <NewVariable />;
      default:
        return <VariableEntities />;
    }
  };

  return (
    <Dialog open TransitionComponent={Transition} fullWidth maxWidth="lg">
      <ContainerDialog>
        <AppBar>
          <Toolbar>
            <Typography variant="h6">
              {getTranslation('variables.title')}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setBuilderElementToOpen(undefined);
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ContainerDialog>
      <GridChildren>{renderScreen()}</GridChildren>
    </Dialog>
  );
};

export default Main;
